import { formatarCPF } from './formatadores';

export const decodificarToken = (token) => {
  if (token === null || token === 'undefined') {
    return null;
  }
  const payload = token.split('.')[1];
  const decodedData = window.atob(payload);
  return JSON.parse(decodedData);
};

export const possuiPermissoes = (permissoes) => {
  const data = decodificarToken(localStorage.getItem('ifs_auth'));
  if (data) {
    const tokenPermissions = data.roles;
    if (typeof permissoes === 'string') {
      return tokenPermissions.includes(permissoes);
    } else {
      return intersection(tokenPermissions, permissoes).length > 0
    }
  } else {
    return false;
  }
}

export const intersection = (a, b) => {
  return a.filter(item => -1 !== b.indexOf(item));
}

export const obterDadosUsuarioLogado = () => {
  const data = decodificarToken(localStorage.getItem('ifs_auth'));
  const dadosRetorno = {
    document: {
      label: 'CPF',
      number: formatarCPF(data.cpf)
    },
    firstName: data.nome,
    information: 'Informações do usuário de acordo com as definições do cliente.',
    gerid: obterDadosGerid(data.geridRoles),
    isUsuarioInternoDataprev: data.domains?.find(d => d === 'GOV:DATAPREV')
  };
  return dadosRetorno;
};

const obterDadosGerid = (geridRoles) => {
  const geridRole = geridRoles &&  Array.isArray(geridRoles) ? geridRoles[0] : undefined;
  if (!geridRole) {
    return undefined;
  }
  const infosGeridRole = geridRole.split('#'); 
  return {
    papel: infosGeridRole[0],
    abrangencia: infosGeridRole[1].replace(/\D/g,'') 
  }
}

export const obterUsuarioLogado = () => {
  const data = decodificarToken(localStorage.getItem('ifs_auth'));
  return {
    nome: data.nome,
    cpf: parseInt(data.cpf),
    matricula: data.employeeNumber
  };
};

export const isUsuarioLogado = () => {
  const atual = new Date();
  const token = localStorage.getItem('ifs_auth');
  let timeToExpire = localStorage.getItem('ifs_ts');

  if (timeToExpire) {
    timeToExpire = new Date(timeToExpire);
    if (token && timeToExpire > atual) {
      return true;
    }
  }
  return false;
};

export const unStoreToken = () => {
  localStorage.removeItem('ifs_auth');
  localStorage.removeItem('ifs_ts');
};

const textoUrl = () => {
  let vars = [];
  const queryString = {};
  let query = document.location.hash;
  if (query.search('#') !== -1) {
    query = query.split('#');
    vars = query[1].split('&');
  } else {
    vars = query.split('&');
  }
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[pair[0]] = decodeURIComponent(pair[1]);
    } else if (typeof queryString[pair[0]] === 'string') {
      const arr = [
        queryString[pair[0]],
        decodeURIComponent(pair[1])
      ];
      queryString[pair[0]] = arr;
    } else {
      queryString[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return queryString;
};

export const storeToken = async () => {
  const texto = textoUrl();
  const time = new Date();
  time.setSeconds(texto.expires_in);
  await localStorage.setItem('ifs_auth', texto.access_token);
  await localStorage.setItem('ifs_ts', time);
};
