import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BrButton from "@govbr/react-components/lib/BrButton";
import BrTable from "@govbr/react-components/lib/BrTable";

import { exibirDialogDetalharDocumMedOdont } from "../action";
import {
  obterNumeroComUf,
  obterTipoIdentificacao
} from "../utils";

import { formatarDateTimeISOToDataBr } from "../../../../../common/DataUtils";

const DocumMedOdontAnteriores = (props) => {
  const { documMedOdontAnteriores, isFormVisible, exibirDialogDetalharDocumMedOdont } = props;
  const existeDocumMedOdontAnteriores =
    documMedOdontAnteriores && documMedOdontAnteriores.length > 0;

  return (
    <>
      <div id="abaDocumMedOdontAnteriores">
        {!existeDocumMedOdontAnteriores && <p>Não existem documentos cadastrados.</p>}
        {existeDocumMedOdontAnteriores && (
          <BrTable
            id="tabelaDocumMedOdontAnteriores"
            title="Documentos Médicos Odontológicos perícia anterior"
            layout="fixed"
            columns={[
              { Header: "Data do documento", accessor: "data" },
              { Header: "Tipo de Identificação", accessor: "tipoIdentificacao" },
              { Header: "Número", accessor: "numero" },
              { Header: "Ações", accessor: "acao" }
            ]}
            data={documMedOdontAnteriores.map((item) => ({
              data: formatarDateTimeISOToDataBr(item.dataDocumento),
              tipoIdentificacao: obterTipoIdentificacao(
                item.idTipoIdentEmitente,
                props.tiposIdentificacaoEmitente
                ),
                numero: obterNumeroComUf(item, props.ufs),
                acao: (
                  <>
                  <BrButton
                    id="detalharDocumMedOdontAnterior"
                    circle
                    onClick={() => exibirDialogDetalharDocumMedOdont({...item, periciaAnterior: true})}
                    disabled={isFormVisible}
                    >
                    <i className={`fas fa-eye`} title="Detalhar" />
                  </BrButton>
                </>
              )
            }))}
            headerProps={{
              show: false,
              showDensityOptions: false,
              searchProp: {
                show: false
              }
            }}
            footerProps={{ show: false }}
            />
            )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  documMedOdontAnteriores: obterExamesLaudosAnteriores(state.pericia),
  isFormVisible: state.modulos.moduloExameLaudo.isFormVisible,
  tiposIdentificacaoEmitente:
    state.principal.dominioPericia.tiposIdentificacaoEmitente,
  ufs: state.principal.dominioSdc.ufs
});

const obterExamesLaudosAnteriores = pericia => {
  let retorno = [];

  pericia.examesLaudoAnteriores && pericia.examesLaudoAnteriores.forEach(exameLaudo => retorno.push(exameLaudo));
  if (retorno.length === 0) {
    return pericia.periciaAnterior?.examesLaudoAnteriores;
  }
  return retorno;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exibirDialogDetalharDocumMedOdont
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumMedOdontAnteriores);
