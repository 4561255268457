import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModuloFormulario from '../../../../components/ModuloFormulario';
import { obterMapRespostasGrupos, obterQualificadorFinalFuncoesCorpo, qualificadoresFuncoesCorpo } from '../../../../components/ModuloFormulario/utils';
import { obterFomularioFuncoesDoCorpo } from '../../templatePericiaUtil';
import JustificativaDominiosZerados from './JustificativaDominiosZerados';

export const isGruposComValorDominioZerado = (gruposFormulario, respostasGruposFormularios) => {
  return gruposFormulario && respostasGruposFormularios && gruposFormulario.every((grupo) => respostasGruposFormularios.get(grupo.id) && respostasGruposFormularios.get(grupo.id).qualificador === 0);
};

export class FuncoesDoCorpo extends Component {
  render() {
    let formulario = obterFomularioFuncoesDoCorpo(this.props.template);
    if (!formulario) {
      return null;
    }
    return (
      formulario.gruposFormulario &&
      <div id="formFuncoesDoCorpo" className="conteudoAbaSemHeaderSecao">
        <div>
          {
            <ModuloFormulario
              formulario={formulario}
              respostasPerguntas={this.props.respostasPerguntaForm}
              respostasGrupos={this.props.respostasGrupoForm}
              opcoesJustQualificadorDominioZero={this.props.opcoesJustQualificadorDominioZero}
              tiposQualificador={this.props.tiposQualificador}
              textoQualificadorFinal={`Qualificador Final de ${formulario.nome}`}
              validacao={this.props.validacao}
              qualificadores={qualificadoresFuncoesCorpo}
              handlerObterQualificadorFinal={obterQualificadorFinalFuncoesCorpo}
              infoSelecaoQualificador={'Qualifique de 0 a 4 as unidades de classificação consideradas para a qualificação final do domínio:'}
              isSomenteLeitura={this.props.isSomenteLeitura} />
          }
          {
            isGruposComValorDominioZerado(formulario.gruposFormulario, obterMapRespostasGrupos(this.props.respostasGrupoForm)) &&
            <JustificativaDominiosZerados />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  respostasPerguntaForm: state.pericia.respostasPerguntaForm,
  respostasGrupoForm: state.pericia.respostasGrupoForm,
  template: state.principal.template,
  opcoesJustQualificadorDominioZero: state.principal.dominioPericia.tiposJustQualifZero,
  tiposQualificador: state.principal.dominioPericia.tiposQualificador,
  validacao: state.principal.validacao.funcoesDoCorpo,
  isSomenteLeitura: state.principal.isSomenteLeitura
});

export default connect(mapStateToProps)(FuncoesDoCorpo);
