import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import logo from "../../../../img/armasnacionais.png";
import { formatarSimNao, formatarNB } from '../../../../common/formatadores';
import { formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import React from 'react';


const useStyles = makeStyles({
  container: {
    overflowY: 'scroll',
    height: '80vh',
    display: 'flex-wrap',
    alignItems: 'flex-start',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderBottom: '1px solid',
  },
  title: {
    alignItems: 'center',
  },
  logo: {
    height: '80%',
    width: '80%',
    paddingBottom: '5px',
  },
  info: {
    justifyContent: "flex-start",
    padding: '1px',
    borderBottom: '1px solid',
  },
  data: {
    borderBottom: '1px solid',
    marginBottom: '5px',
  },
  leftData: {
    borderRight: '1px solid',
  },
  middleData: {
    borderBottom: '1px solid',
  },
  footer: {
    border: '1px solid',
    borderCollapse: 'collapse',
    paddingLeft: '4px',
    boxSizing: 'border-box',
    fontSize: '0.6em',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8em',
    justifyContent: 'flex-end',
    paddingLeft: '2px',
  },
  value: {
    fontSize: '0.8em',
    paddingBottom: '5px',
  },
  textValue: {
    fontSize: '0.8em',
    paddingBottom: '7px',
  },
  typography: {
    fontWeight: 'bold'
  },
  divider: {
    color: 'black'
  }

});

const DetalheLaudo = (props) => {
  const classes = useStyles();
  const laudo = props.laudo;
  const avaliado = props.avaliado;

  return (

    <Grid container className={classes.container}>
      <Grid item container className={classes.header}>
        <Grid item container xs={2}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item container className={classes.title} xs={10}>
          <Grid item container justifyContent='center'>
            <Typography variant='subtitle2' className={classes.typography}>
              PMF Perícias
            </Typography>
            <Grid item container justifyContent='center'>
              <Typography variant='subtitle2' className={classes.typography}>
                Laudo Médico Pericial importado do SABI para Simples Conferência
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container className={classes.info} >
        <Grid item container xs={8}>
          <Grid item className={classes.label} xs={3}>
            Requerente:
          </Grid>
          <Grid item className={classes.value} xs={9}>
            {avaliado.nome}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={6}>
            NB:
          </Grid>
          <Grid item className={classes.value} xs={6}>
            {formatarNB(laudo.numeroBeneficio)}
          </Grid>
        </Grid>

        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={3}>
            Sexo:
          </Grid>
          <Grid item className={classes.value} xs={9}>
            {avaliado.sexo}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={3}>
            Nasc.:
          </Grid>
          <Grid item className={classes.value} xs={9}>
            {formatarDateTimeISOToDataBr(avaliado.dataNascimento)}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={6}>
            N Requer.:
          </Grid>
          <Grid item className={classes.value} xs={6}>
            {laudo.numeroRequerimento}
          </Grid>
        </Grid>

        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={4}>
            Est. Civil:
          </Grid>
          <Grid item className={classes.value} xs={8}>
            {avaliado.estadoCivil}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={3}>
            RG.:
          </Grid>
          <Grid item className={classes.value} xs={9}>
            {avaliado.identidade}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={6}>
            Emissão RG.:
          </Grid>
          <Grid item className={classes.value} xs={6}>

          </Grid>
        </Grid>

        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={3}>
            Ocupação:
          </Grid>
          <Grid item className={classes.value} xs={9}>

          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={5}>
            Data Exame:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.dataRealizacao}
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item className={classes.label} xs={6}>
            Ordem:
          </Grid>
          <Grid item className={classes.value} xs={6}>
            {laudo.codigoLaudo}.{laudo.ordemLaudo}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.data}>
        <Grid item container xs={6} className={classes.leftData}>
          <Grid item className={classes.label} xs={12}>
            Benefício:
          </Grid>
          <Grid item className={classes.textValue} xs={12}>
            {laudo.descricaoBeneficio}
          </Grid>

          <Grid item className={classes.label} xs={12}>
            História:
          </Grid>
          <Grid item className={classes.textValue} xs={12}>
            {laudo.historiaClinica}
          </Grid>

          <Grid item className={classes.label} xs={12}>
            Exame Físico:
          </Grid>
          <Grid item className={classes.textValue} xs={12}>
            {laudo.exameFisico}
          </Grid>

          <Grid item xs={12} className={classes.middleData}>
          </Grid>

          <Grid item className={classes.label} xs={4}>
            Ac. do Trabalho:
          </Grid>
          <Grid item className={classes.value} xs={2}>
            {formatarSimNao(laudo.acidenteTrabalho)}
          </Grid>

          <Grid item className={classes.label} xs={5}>
            Reab. Profissional:
          </Grid>
          <Grid item className={classes.value} xs={1}>
            {formatarSimNao(laudo.rp)}
          </Grid>

          <Grid item className={classes.label} xs={4}>
            Espécie de Nexo:
          </Grid>
          <Grid item className={classes.value} xs={8}>

          </Grid>

          <Grid item className={classes.label} xs={5}>
            Isenção de Carência:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {formatarSimNao(laudo.isencaoCarencia)}
          </Grid>

          <Grid item className={classes.label} xs={4}>
            Auxílio Acidente:
          </Grid>
          <Grid item className={classes.value} xs={2}>
            {laudo.auxilioAcidente !== undefined ? formatarSimNao(laudo.auxilioAcidente) : 'Não'}
          </Grid>

          <Grid item className={classes.label} xs={5}>
            Apos. por Invalidez:
          </Grid>
          <Grid item className={classes.value} xs={1}>
            {formatarSimNao(laudo.invalidez)}
          </Grid>

        </Grid>


        <Grid item container xs={6}>

          <Grid item className={classes.label} xs={5}>
            Início da Doença:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.dataInicioDoenca}
          </Grid>

          <Grid item className={classes.label} xs={5}>
            Início da Incapacidade:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.dataInicioIncapacidade}
          </Grid>

          <Grid item className={classes.label} xs={5}>
            CID:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.codigoCID}
          </Grid>

          <Grid item className={classes.label} xs={5}>
            Considerações:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.consideracoes}
          </Grid>

          {laudo.dataCessacaoBeneficio != null &&
            <Grid item className={classes.label} xs={5}>
              DCB:
            </Grid>}
          {laudo.dataCessacaoBeneficio != null &&
            <Grid item className={classes.value} xs={7}>
              {laudo.dataCessacaoBeneficio}
            </Grid>}

          {laudo.dataComprovacaoIncapacidade != null &&
            <Grid item className={classes.label} xs={5}>
              DCI:
            </Grid>}
          {laudo.dataComprovacaoIncapacidade != null &&
            <Grid item className={classes.value} xs={7}>
              {laudo.dataComprovacaoIncapacidade}
            </Grid>}

          <Grid item className={classes.label} xs={5}>
            Resultado:
          </Grid>
          <Grid item className={classes.value} xs={7}>
            {laudo.resultadoIncapacidade}
          </Grid>

        </Grid>
      </Grid>

      <Grid container className={classes.footer}>O INSS poderá rever a qualquer tempo as informações constantes deste extrato, conforme art. 19, § 3° do Decreto 3.048/99.</Grid>

    </Grid >
  )
}

export default DetalheLaudo