import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';

import { ocultarDialogExcluirDocumMedOdont } from './action';

import { excluirDocumMedOdont } from '../../action';

class DialogConfirmarExclusao extends React.Component {

  render() {
    const { ocultarDialogExcluirDocumMedOdont, excluirDocumMedOdont, isConfirmarExclusao, texto, documento } = this.props;
    return (
      <BrModal id="dialogConfirmarExclusao" visible={isConfirmarExclusao}
        title={`Excluir Documento apresentado`} closable={false} medium>

        {texto}

        <BrModalFooter>
          <BrButton secondary className="m-2" onClick={ocultarDialogExcluirDocumMedOdont}>Cancelar</BrButton>
          <BrButton primary className="m-2" onClick={() => excluirDocumMedOdont(documento)}>Confirmar</BrButton>
        </BrModalFooter>
      </BrModal>
    );
  }
}

const mapStateToProps = (state) => ({
  isConfirmarExclusao: state.modulos.moduloDocumMedOdont.isConfirmarExclusao
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogExcluirDocumMedOdont,
  excluirDocumMedOdont
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarExclusao);
