import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import HeaderSecao from '../../../../components/HeaderSecao';

import { ID_OUTRA_CAUSA_DEFICIENCIA } from './validacao';

import BrCard from '@govbr/react-components/lib/BrCard';
import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import { Grid } from "@material-ui/core";
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import {
  alterarCausasDeficienciaSelecionadas, alterarExisteRiscoSocial, alterarOutraCausaDeficiencia, alterarRiscoSocial
} from '../../action';

class ImpedimentosERiscoSocial extends React.Component {

  QTD_MAXIMA_CARACTERES = 2000;
  ID_OUTRA_CAUSA_DEFICIENCIA = 10;
  TAMANHO_MAXIMO_OUTRA_CAUSA_DEFICIENCIA = 50;

  tratarSelecaoCausaDeficiencia = (item, checked) => {
    const { causasDeficiencia, idModImpedRiscoSocial } = this.props.dados;
    let causasDeficienciaAtualizada = causasDeficiencia ? causasDeficiencia : [];
    const value = parseInt(item);

    if (checked) {//incluir
      causasDeficienciaAtualizada.push({ idModImpedRiscoSocial, idTipoCausaDeficiencia: value });
    } else { //remover
      causasDeficienciaAtualizada = causasDeficienciaAtualizada.filter(e => { return e.idTipoCausaDeficiencia !== value });
    }

    this.props.alterarCausasDeficienciaSelecionadas(causasDeficienciaAtualizada);
  }

  render() {
    const { causasDeficiencia, outraCausaDeficiencia, existeRiscoSocial, riscoSocial } = this.props.dados;
    const { alterarOutraCausaDeficiencia, alterarExisteRiscoSocial, alterarRiscoSocial } = this.props;
    const { idadeNaDer, validacao, tiposCausasDeficiencia, isSomenteLeitura } = this.props;

    const isOutraCausaDeficienciaSelecionada = causasDeficiencia
      && causasDeficiencia.filter(e => { return e.idTipoCausaDeficiencia === ID_OUTRA_CAUSA_DEFICIENCIA }).length > 0;

    const isMaior16Anos = idadeNaDer > 16;

    const caracteresRestantesRiscoSocial = calcularCaracteresRestantes(riscoSocial, this.QTD_MAXIMA_CARACTERES);

    const isCampoTextareaDesabilitado = isSomenteLeitura;

    return (
      <div id="abaImpedimentosERiscoSocial">
        <HeaderSecao texto="* Causa da deficiência" />
        <BrCard>
          <span>
            (**) -
            {
              isMaior16Anos
                ? 'Inclusive na condição de aprendiz ou estagiário'
                : 'Na condição de aprendiz, estagiário ou em decorrência de exploração do trabalho infanto-juvenil'
            }
          </span>
        </BrCard>

        {tiposCausasDeficiencia &&
          (<BrCheckbox id="causasDeficiencia" columns={2}
            items={tiposCausasDeficiencia.map(c => ({ label: c.descricao, value: c.id }))}
            values={causasDeficiencia ? causasDeficiencia.map(c => c.idTipoCausaDeficiencia) : []}
            onChange={this.tratarSelecaoCausaDeficiencia}
            erro={validacao && validacao.erroCausaDeficiencia}
            disabled={isSomenteLeitura}
          />)
        }

        {isOutraCausaDeficienciaSelecionada &&
          (
            <Grid container>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={6}>
                <BrInput value={outraCausaDeficiencia} label="* Especifique"
                  maxLength={this.TAMANHO_MAXIMO_OUTRA_CAUSA_DEFICIENCIA}
                  onChange={alterarOutraCausaDeficiencia}
                  erro={validacao && validacao.erroOutraCausaDeficiencia}
                  disabled={isSomenteLeitura} />
              </Grid>
            </Grid>
          )
        }

        <HeaderSecao texto={isMaior16Anos ? "Risco e Proteção Social" : "Risco Social"} />

        <BrCheckbox id="existeRiscoSocial"
          items={[{
            label: `CASO SEJAM OBSERVADOS INDÍCIOS DE RISCO SOCIAL QUE DEMANDEM ACOMPANHAMENTO PRIORITÁRIO (violência física e/ou psicológica; 
              abandono familiar; abusos e/ou exploração sexual crianças e/ou adolescentes fora da escola; exploração de trabalho infantil; 
              ausência de proteção social, familiar e/ou comunitária, dentre outros), ASSINALE E DESCREVA ABAIXO PARA POSTERIOR ENCAMINHAMENTO 
              PELO ASSISTENTE SOCIAL:`,
            value: "riscoSocial"
          }
          ]}
          values={existeRiscoSocial === 1 ? ["riscoSocial"] : []}
          onChange={(item, checked) => { checked ? alterarExisteRiscoSocial(1) : alterarExisteRiscoSocial(0) }}
          disabled={isSomenteLeitura}
        />

        {
          existeRiscoSocial === 1 &&
          (<>
            <div className="mt-3">
              <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea label="* Descreva:"
                    onChange={alterarRiscoSocial}
                    value={riscoSocial}
                    maxLength={this.QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresRestantesRiscoSocial}`}
                    erro={validacao ? validacao.erroRiscoSocial : null}
                    disabled={isCampoTextareaDesabilitado} />
                </Grid>
                {
                  !isCampoTextareaDesabilitado &&
                  <Grid item >
                    <ButtonSelecionarItem
                      id="selecionarItemDicionarioJustRiscoSocial"
                      onSelect={alterarRiscoSocial}
                      maxLength={caracteresRestantesRiscoSocial}
                      valorAtual={riscoSocial}/>
                  </Grid>
                }
              </Grid>
            </div>
          </>)
        }

      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { moduloImpedRiscoSocial, avaliado } = state.pericia;
  const { validacao, dominioPericia, isSomenteLeitura } = state.principal;
  return ({
    dados: moduloImpedRiscoSocial,
    idadeNaDer: avaliado.idade,
    validacao: validacao.impedimentosERiscoSocial,
    tiposCausasDeficiencia: dominioPericia.tiposCausasDeficiencia,
    isSomenteLeitura
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarCausasDeficienciaSelecionadas,
  alterarOutraCausaDeficiencia,
  alterarExisteRiscoSocial,
  alterarRiscoSocial
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImpedimentosERiscoSocial);