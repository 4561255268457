import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal'
import BrButton from '@govbr/react-components/lib/BrButton'

import { ocultarDialogExcluirItem, excluirItem } from './action';

const DialogConfirmarExclusao = (props) => {

  const { ocultarDialogExcluirItem, excluirItem, isConfirmarExclusao, itemSelecionado, isGestor } = props;
  return (
    <BrModal id="dialogConfirmarExclusaoItemDicionario" visible={isConfirmarExclusao}
      title={`Excluir item do dicionário`} closable={false} medium>

      <p>
        {`Confirma exclusão do item '${itemSelecionado?.chaveCampo}'?`}
      </p>

      <BrModalFooter>
        <BrButton secondary className="m-2" onClick={ocultarDialogExcluirItem}>Cancelar</BrButton>
        <BrButton primary className="m-2" onClick={() => excluirItem(itemSelecionado.id, isGestor)}>Confirmar</BrButton>
      </BrModalFooter>
    </BrModal>
  );
}

const mapStateToProps = (state) => ({
  isConfirmarExclusao: state.dicionario.isConfirmarExclusao,
  itemSelecionado: state.dicionario.itemSelecionado
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogExcluirItem,
  excluirItem
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarExclusao);
