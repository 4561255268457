import { obterListaCidPorCodigoOuNome, isCidsDoMesmoGrupo } from '../../../principal/action';
import { alterarCidPrincipal, novoCidSecundario } from '../../../pericia/action';
import { atualizarFatoresRiscoCid, atualizarBatimentoCidCnae } from '../NexoTecnico/action';
import { limparBatimentoCidAgravamento, limparBatimentoCidRestabelecimento } from '../RestabelecimentoBeneficio/action';

export const selecionarCidPrincipal = (value, existeModuloNexoNoTemplate, existeNexoTecnico,
  existeModuloRestabelecimentoNoTemplate, existeModuloIsencaoNoTemplate, existeDadosRestabelecimento, deveCarregarBatimentoGrupoPericiaAnterior, cidPericiaAnterior) => {

  return (dispatch) => {
    if (existeNexoTecnico || existeDadosRestabelecimento) {
      dispatch(exibirDialogAlterarCidPrincipal(value));
    } else {
      dispatch(alterarCidPrincipal(value, existeModuloNexoNoTemplate, existeModuloRestabelecimentoNoTemplate, existeModuloIsencaoNoTemplate));
      if (deveCarregarBatimentoGrupoPericiaAnterior) {
        dispatch(carregarCidPrincipalMesmoGrupoCidPericiaAnterior(value.codigo.trim(), cidPericiaAnterior.trim()));
      }
      dispatch(atualizarFatoresRiscoCid(undefined));
      dispatch(atualizarBatimentoCidCnae(undefined));
      dispatch(limparBatimentoCidAgravamento());
      dispatch(limparBatimentoCidRestabelecimento());
      dispatch(removerInputCidPrincipal());
      dispatch(removerCidSecundarioIgualCidPrincipal(value));
      dispatch({
        type: 'ATUALIZAR_DADOS_VALIDACAO',
        payload: { nexoTecnico: undefined }
      });
    }
  }
};

const removerInputCidPrincipal = () => ({
  type: 'REMOVER_INPUT_CID_PRINCIPAL'
});

const removerCidSecundarioIgualCidPrincipal = cidPrincipal => ({
  type: 'REMOVER_CID_SECUNDARIO_IGUAL_CID_PRINCIPAL',
  payload: cidPrincipal.codigo
});

export const selecionarCidSecundario = value => ({
  type: 'SELECIONAR_CID_SECUNDARIO',
  payload: value
});

export const consultarCidsPrincipais = value => {
  return (dispatch) => {
    dispatch(setCidPrincipalLoading(true));
    dispatch(obterListaCidPorCodigoOuNome(value, atualizarListaCidsPrincipais, habilitarAlteracaoCidPrincipal, `Principal '${value}'`));
  }
}

const setCidPrincipalLoading = value => ({
  type: 'CID_PRINCIPAL_LOADING',
  payload: value
});

export const setTimeoutConsultaCidPrincipal = (value, input) => ({
  type: 'TIMEOUT_CONSULTA_CID_PRINCIPAL',
  payload: value,
  input
});

const habilitarAlteracaoCidPrincipal = () => setCidPrincipalLoading(false);

const atualizarListaCidsPrincipais = data => ({
  type: 'ATUALIZAR_LISTA_CIDS_PRINCIPAIS',
  payload: data
});

export const consultarCidsSecundarios = value => {
  return (dispatch) => {
    dispatch(setCidSecundarioLoading(true));
    dispatch(obterListaCidPorCodigoOuNome(value, atualizarListaCidsSecundarios, habilitarAlteracaoCidSecundario, `Secundário '${value}'`));
  }
}

const setCidSecundarioLoading = value => ({
  type: 'CID_SECUNDARIO_LOADING',
  payload: value
});

export const setTimeoutConsultaCidSecundario = (value, input) => ({
  type: 'TIMEOUT_CONSULTA_CID_SECUNDARIO',
  payload: value,
  input
});

const habilitarAlteracaoCidSecundario = () => setCidSecundarioLoading(false);

const atualizarListaCidsSecundarios = data => ({
  type: 'ATUALIZAR_LISTA_CIDS_SECUNDARIOS',
  payload: data
});

export const incluirCidSecundario = (cid, nomeCid) => {
  return (dispatch) => {
    dispatch(novoCidSecundario(cid, nomeCid));
    dispatch(removerCidSecundarioSelecionado());
  }
}

const removerCidSecundarioSelecionado = () => ({
  type: 'REMOVER_CID_SECUNDARIO_SELECIONADO'
});

export const exibirDialogExcluirCid = cid => ({
  type: 'EXIBIR_DIALOG_EXCLUIR_CID_SECUNDARIO',
  payload: cid
});

export const ocultarDialogExcluirCid = () => ({
  type: 'OCULTAR_DIALOG_EXCLUIR_CID_SECUNDARIO'
});

export const exibirDialogAlterarCidPrincipal = novoValor => ({
  type: 'EXIBIR_DIALOG_ALTERAR_CID_PRINCIPAL',
  payload: novoValor
});

export const ocultarDialogAlterarCidPrincipal = () => ({
  type: 'OCULTAR_DIALOG_ALTERAR_CID_PRINCIPAL'
});

export const carregarCidPrincipalMesmoGrupoCidPericiaAnterior = (cidPrincipal, cidAnterior) =>
  isCidsDoMesmoGrupo(cidPrincipal, cidAnterior, atualizarCidPrincipalMesmoGrupoCidPericiaAnterior, removerCidPrincipalMesmoGrupoCidPericiaAnterior);

const atualizarCidPrincipalMesmoGrupoCidPericiaAnterior = (cidAnterior, value) => ({
  type: 'ATUALIZAR_CID_PRINCIPAL_MESMO_GRUPO_CID_ANTERIOR',
  payload: value
});

const removerCidPrincipalMesmoGrupoCidPericiaAnterior = () => atualizarCidPrincipalMesmoGrupoCidPericiaAnterior(undefined);