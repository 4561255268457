import React, { useState } from "react";
import { connect } from "react-redux";

import { Grid } from '@material-ui/core';
import BrTabs from '@govbr/react-components/lib/BrTabs';
import BrTable from '@govbr/react-components/lib/BrTable';

import { formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import {
  obterDescricaoVinculo, createRecordVinculoCollapsed, createRecordBeneficioCollapsed, createRecordAtividadeCollapsed,
  PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
} from './util';

const RelacoesPrevidenciarias = ({ dadosBeneficiosAnteriores, dadosVinculos, dadosAtividades }) => {

  const [selectedTab, setSelectTab] = useState("tabVinculos");

  return (
    <div id="relacoesPrevidenciarias" className="conteudoAbaSemHeaderSecao">
      <Grid container>
        <Grid item xs={12}>
          <BrTabs
            value={selectedTab}
            onChange={value => setSelectTab(value)}
            label="Abas">
            <BrTabs.Tabs label="Teste de Tabs">
              <BrTabs.Tab id="tabVinculos" label="Vínculos" />
              <BrTabs.Tab id="tabBeneficios" label="Benefícios" />
              <BrTabs.Tab id="tabAtividades" label="Atividades" />
            </BrTabs.Tabs>

            <BrTabs.Content>
              <BrTabs.Panel contentFor="tabVinculos">
                {
                  dadosVinculos && dadosVinculos.length > 0 ?
                    <BrTable
                      id="tabelaVinculos"
                      layout="fixed"
                      columns={[{ Header: "Descrição", accessor: "descricao" }, { Header: "Inicio", accessor: "inicio" },
                      { Header: "Fim", accessor: "fim" }, { Header: "Última Remuneração", accessor: "ultimaRemuneracao" }]}
                      data={dadosVinculos.map(item => ({
                        descricao: obterDescricaoVinculo(item),
                        inicio: formatarDateTimeISOToDataBr(item.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        fim: formatarDateTimeISOToDataBr(item.dataFim) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        ultimaRemuneracao: (!item.dataFim && item.ultimaRemuneracao) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        collapse: createRecordVinculoCollapsed(item)
                      }))}
                      collapse={true}
                      headerProps={{
                        show: false,
                        showDensityOptions: false,
                        searchProp: {
                          show: false
                        }
                      }}
                      footerProps={{ show: false }}
                    />
                    : <p>Nenhum vínculo encontrado.</p>
                }
              </BrTabs.Panel>
              <BrTabs.Panel contentFor="tabBeneficios">
                {
                  dadosBeneficiosAnteriores && dadosBeneficiosAnteriores.length > 0 ?
                    <BrTable
                      id="tabelaBeneficiosAnteriores"
                      layout="fixed"
                      columns={[{ Header: "Espécie / NB", accessor: "descricao" }, 
                        { Header: "Início", accessor: "inicio" },
                        { Header: "Fim", accessor: "fim" },
                        { Header: "Motivo de Indeferimento/Cessação", accessor: "motivo" }]}
                      data={dadosBeneficiosAnteriores.map(item => ({
                        descricao: `${item.numeroEspecieBeneficio} / ${item.numeroBeneficio}`,
                        inicio: formatarDateTimeISOToDataBr(item.dataInicioBeneficio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        fim: formatarDateTimeISOToDataBr(item.dataCessacaoBeneficio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        motivo: item.descricaoMotivoIndeferimento 
                          ? item.descricaoMotivoIndeferimento
                          : (item.descricaoMotivoCessacao 
                            ? item.descricaoMotivoCessacao
                            : undefined),
                        collapse: createRecordBeneficioCollapsed(item)
                      }))}
                      collapse={true}
                      headerProps={{
                        show: false,
                        showDensityOptions: false,
                        searchProp: {
                          show: false
                        }
                      }}
                      footerProps={{ show: false }}
                    />
                    : <p>Nenhum benefício encontrado.</p>
                }
              </BrTabs.Panel>
              <BrTabs.Panel contentFor="tabAtividades">
                {
                  dadosAtividades && dadosAtividades.length > 0 ?
                    <BrTable
                      id="tabelaAtividades"
                      layout="fixed"
                      columns={[{ Header: "Ocupação", accessor: "ocupacao" }, { Header: "Início", accessor: "inicio" },
                      { Header: "Fim", accessor: "fim" }]}
                      data={dadosAtividades.map(item => ({
                        ocupacao: item.ocupacao,
                        inicio: formatarDateTimeISOToDataBr(item.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        fim: formatarDateTimeISOToDataBr(item.dataFim) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS,
                        collapse: createRecordAtividadeCollapsed(item)
                      }))}
                      collapse={true}
                      headerProps={{
                        show: false,
                        showDensityOptions: false,
                        searchProp: {
                          show: false
                        }
                      }}
                      footerProps={{ show: false }}
                    />
                    : <p>Nenhuma atividade encontrada</p>
                }
              </BrTabs.Panel>
            </BrTabs.Content>
          </BrTabs>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dadosBeneficiosAnteriores: state.pericia.beneficiosAnteriores,
  dadosVinculos: state.pericia.vinculosPericia,
  dadosAtividades: state.pericia.atividadesAvaliado,
});

export default connect(mapStateToProps)(RelacoesPrevidenciarias);