import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import { Grid } from '@material-ui/core';
import HeaderSecao from '../../../components/HeaderSecao';

import { adicionarDiasNaData, convertStringToDateWithTimezonePT_BR } from '../../../common/DataUtils';
import Concluir from "../../../components/Concluir";
import { validacaoCompletaPericia, atualizarPericiaValida } from "../action";
import {
  SIGLA_CAMPO_DATA_INICIO_REPOUSO,
  SIGLA_CAMPO_DESCRICAO_NAO_CONFORMACAO,
  SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO,
  SIGLA_CAMPO_PERGUNTA_ACIDENTE_TRABALHO_CONFORMACAO,
  SIGLA_CAMPO_PRAZO_REPOUSO,
  SIGLA_CAMPO_PRAZO_REPOUSO_DIAS,
  SIGLA_CAMPO_RESPOSTA_CONFORMACAO,
  SIGLA_CAMPO_DATA_ACIDENTE_TRABALHO_CONFORMACAO,
  tiposPericia
} from '../constants';
import { obterCampoGenericoPorSigla } from "../templatePericiaUtil";
import { verificaSeDocumentoAnexoObrigatorioInexistente } from "./DocumMedOdont/validacao";

const ConclusaoAtestmed = (props) => {

  useEffect(() => {
    props.validacaoCompletaPericia(props.pericia, true);
  });

  const {pericia, template, isSomenteLeitura} = props;

  const isPericiaAtestmedSABI = pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo;

  const cid = pericia.moduloCid?.cidPrincipal ?
    `${pericia.moduloCid?.cidPrincipal} - ${pericia.moduloCid?.nomeCidPrincipal}` : "";

  const conformacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_RESPOSTA_CONFORMACAO)?.valorTextoCampoGenerico;
  const prazoRepouso = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_PRAZO_REPOUSO)?.valorTextoCampoGenerico;
  const dataInicioRepouso = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DATA_INICIO_REPOUSO)?.valorTextoCampoGenerico;
  const prazoRepousoDias = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_PRAZO_REPOUSO_DIAS)?.valorTextoCampoGenerico;
  const motivoNaoConformacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO)?.valorTextoCampoGenerico;
  const justNaoConformacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DESCRICAO_NAO_CONFORMACAO)?.valorTextoCampoGenerico;
  const perguntaAcidenteTrabalho = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_PERGUNTA_ACIDENTE_TRABALHO_CONFORMACAO)?.valorTextoCampoGenerico;
  const dataAcidenteTrabalho = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DATA_ACIDENTE_TRABALHO_CONFORMACAO)?.valorTextoCampoGenerico;

  const campoPrazoRepouso = obterCampoGenericoPorSigla(SIGLA_CAMPO_PRAZO_REPOUSO, template)?.campoGenerico;
  const campoMotivoNaoConformacao = obterCampoGenericoPorSigla(SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO, template)?.campoGenerico;
  
  const labelConformacao = obterCampoGenericoPorSigla(SIGLA_CAMPO_RESPOSTA_CONFORMACAO, template)?.campoGenerico.nome;
  const labelPrazoRepouso = campoPrazoRepouso?.nome;
  const labelDataInicioRepouso = obterCampoGenericoPorSigla(SIGLA_CAMPO_DATA_INICIO_REPOUSO, template)?.campoGenerico.nome;
  const labelPrazoRepousoDias = obterCampoGenericoPorSigla(SIGLA_CAMPO_PRAZO_REPOUSO_DIAS, template)?.campoGenerico.nome;
  const labelJustNaoConformacao = obterCampoGenericoPorSigla(SIGLA_CAMPO_DESCRICAO_NAO_CONFORMACAO, template)?.campoGenerico.nome;
  const labelMotivoNaoConformacao = campoMotivoNaoConformacao?.nome;
  const labelPerguntaAcidenteTrabalho = obterCampoGenericoPorSigla(SIGLA_CAMPO_PERGUNTA_ACIDENTE_TRABALHO_CONFORMACAO, template)?.campoGenerico.nome;
  const labelDataAcidenteTrabalho = obterCampoGenericoPorSigla(SIGLA_CAMPO_DATA_ACIDENTE_TRABALHO_CONFORMACAO, template)?.campoGenerico.nome;
 
  const respostaConformacao = conformacao 
    ? conformacao === '1' ? "Sim" : "Não"
    : "";
  const isRespostaConformacaoSIM = conformacao === '1';
  const isRespostaConformacaoNAO = conformacao === '0';
  const isPrazoRepousoEmDias = isRespostaConformacaoSIM && prazoRepouso === '1'
  const isDataInicioRepouso = isRespostaConformacaoSIM && prazoRepouso && prazoRepouso !=='4';
  const isRespostaAcidenteSIM = isDataInicioRepouso && (perguntaAcidenteTrabalho ? perguntaAcidenteTrabalho === '1' : false);

  const respostaPrazoRepouso = campoPrazoRepouso?.valoresDominio.find(v => v.valor === prazoRepouso)?.descricao;
  const respostaMotivoNaoConformacao = campoMotivoNaoConformacao?.valoresDominio.find(v => v.valor === motivoNaoConformacao)?.descricao;

  const documentoAnexoObrigatorioInexistente = verificaSeDocumentoAnexoObrigatorioInexistente(pericia, template)

  let valorPrazoRepousoParaEnvio = undefined;
  
  switch(prazoRepouso) {
    case '1'://se existe prazo em dias preenchido pelo perito
      valorPrazoRepousoParaEnvio = prazoRepousoDias;
      break;
    case '2':
      valorPrazoRepousoParaEnvio = 90; //valor padrao 90 dias (documentacao nao registra prazo)
      break;
    case '3':
      valorPrazoRepousoParaEnvio = 180; //valor padrao 180 dias (documentacao com prazo indeterminado)
      break;
      default:
        break;
  }

  const dataInicioRepousoConvertida = convertStringToDateWithTimezonePT_BR(dataInicioRepouso);

  const dadosConclusaoAtestmed = isRespostaConformacaoSIM 
    ? 
    { 
      dataEmissaoAtestado: dataInicioRepousoConvertida,
      qtDiasRepouso: valorPrazoRepousoParaEnvio,
      dataFimRepouso: valorPrazoRepousoParaEnvio && dataInicioRepousoConvertida 
        ? adicionarDiasNaData(convertStringToDateWithTimezonePT_BR(dataInicioRepouso), parseInt(valorPrazoRepousoParaEnvio) - 1) 
        : undefined,
      idTipoIdentificacaoEmitente: 1, //tipo CRM
      numeroDocumento: 999999999, //valor fixo, definido por Darlan para todos os atestados
      nomeProfissional: "Soma de atestados",  //valor fixo, definido por Darlan para todos os atestados
      idUfDocumento: 53, //codigo para DF (SDC),  valor fixo definido por Darlan para todos os atestados 
      ufDocumento: "DF", //string para DF (SDC),  valor fixo definido por Darlan para todos os atestados 
      nomeAppParceira: isPericiaAtestmedSABI ? 'PMF_PERICIA' : undefined, //so deve ser enviado para ATESTMED do SABI
      tipoAtestado: isPericiaAtestmedSABI ? undefined : 0 //so deve ser enviado para ATESTMED do SIBE
    }
    : {
      nomeAppParceira: isPericiaAtestmedSABI ? 'PMF_PERICIA' : undefined, //so deve ser enviado para ATESTMED do SABI
      tipoAtestado: isPericiaAtestmedSABI ? undefined : 0 //so deve ser enviado para ATESTMED do SIBE
    };

  return (
    <>
      <div>
        {
          props.respostaConcluir && props.respostaConcluir.erros
          && (
            props.respostaConcluir.erros.map((e, index) =>
            (
              <BrMessage key={`erro_${index}`} error>
                {e}
              </BrMessage>)
            )
          )
        }
        {
          !isSomenteLeitura && documentoAnexoObrigatorioInexistente && 
            <BrMessage key="erroMsgConformSemAnexo" error>
              Para concluir a conformação, é necessário incluir um ou mais documentos na aba "Documentação Médica/Odontológica".
            </BrMessage>
        }
      </div>

      <HeaderSecao texto="Conformação do Documento Médico" />

      <Grid container spacing={1}>
      <Grid item xs={12}>
        <span htmlFor="conformacao">{labelConformacao} </span>
        <span id="conformacao" className="texto-informativo">{respostaConformacao}</span>
      </Grid>
      {
        isRespostaConformacaoSIM &&
        (<>
            <Grid item xs={12}>
              <span htmlFor="cid">CID Principal: </span>
              <span id="cid" className="texto-informativo">{cid}</span>
            </Grid>
            <Grid item xs={12}>
              <span htmlFor="prazoRepouso">{labelPrazoRepouso} </span>
              <span id="prazoRepouso" className="texto-informativo">{respostaPrazoRepouso}</span>
            </Grid>
        </>)
      }
      {  isDataInicioRepouso &&
            <Grid item xs={12}>
              <span htmlFor="dataInicioRepouso">{labelDataInicioRepouso}: </span>
              <span id="dataInicioRepouso" className="texto-informativo">{dataInicioRepouso}</span>
            </Grid>
      }
      {
        isPrazoRepousoEmDias &&
        (
          <Grid item xs={12}>
            <span htmlFor="prazoRepousoDias">{labelPrazoRepousoDias}: </span>
            <span id="prazoRepousoDias" className="texto-informativo">{prazoRepousoDias}</span>
          </Grid>
        )
      }
      {  isDataInicioRepouso && 
         labelPerguntaAcidenteTrabalho &&
            <Grid item xs={12}>
              <span htmlFor="perguntaAcidenteTrabalho">{labelPerguntaAcidenteTrabalho}: </span>
              <span id="perguntaAcidenteTrabalho" className="texto-informativo">{perguntaAcidenteTrabalho === '1' ? "Sim" : "Não"}</span>
            </Grid>
      }
      {  isRespostaAcidenteSIM &&
            <Grid item xs={12}>
              <span htmlFor="dataAcidenteTrabalho">{labelDataAcidenteTrabalho}: </span>
              <span id="dataAcidenteTrabalho" className="texto-informativo">{dataAcidenteTrabalho}</span>
            </Grid>
      }
      {
        isRespostaConformacaoNAO &&
        (
          <>
            <Grid item xs={12}>
              <span htmlFor="motivoNaoConformacao">{labelMotivoNaoConformacao} </span>
              <span id="motivoNaoConformacao" className="texto-informativo">{respostaMotivoNaoConformacao}</span>
            </Grid>
            <Grid item xs={12}>
              <span htmlFor="justNaoConformacao">{labelJustNaoConformacao}: </span>
              <p id="justNaoConformacao" className="texto-informativo">{justNaoConformacao}</p>
            </Grid>
            </>
        )
      }
      </Grid>
        
      <Concluir 
        dadosConclusaoAtestmed={dadosConclusaoAtestmed}/>
    </>
  );
}

const mapStateToProps = state => ({
  pericia: state.pericia,
  template: state.principal.template,
  respostaConcluir: state.pericia.respostaConcluir,
  isSomenteLeitura: state.principal.isSomenteLeitura
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validacaoCompletaPericia,
  atualizarPericiaValida
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoAtestmed);