export default (state = {}, action) => {
  switch (action.type) {
    case 'ATUALIZAR_PERICIA': {
      let moduloCid = action.payload.moduloCid ? JSON.parse(JSON.stringify(action.payload.moduloCid)) : {};
      return { ...state, moduloCid }
    }
    case 'ATUALIZAR_LISTA_CIDS_PRINCIPAIS':
      return { ...state, listaCidsPrincipais: action.payload, isLoadingCidPrincipal: false }
    case 'CID_PRINCIPAL_LOADING':
      return { ...state, isLoadingCidPrincipal: action.payload }
    case 'TIMEOUT_CONSULTA_CID_PRINCIPAL':
      return { ...state, timeoutConsultaCidPrincipal: action.payload, inputCidPrincipal: action.input }
    case 'REMOVER_INPUT_CID_PRINCIPAL':
      return { ...state, inputCidPrincipal: null, deveConfirmarAlteracaoCidPrincipal: null, novoValorCidPrincipal: '' }
    case 'REMOVER_CID_SECUNDARIO_IGUAL_CID_PRINCIPAL': {
      const isCidSecundarioValido = state.cidSecundarioSelecionado !== action.payload;
      const cidSecundarioValido = isCidSecundarioValido ? state.cidSecundarioSelecionado : null;

      return { ...state, cidSecundarioSelecionado: cidSecundarioValido }
    }
    case 'ATUALIZAR_LISTA_CIDS_SECUNDARIOS':
      return { ...state, listaCidsSecundarios: action.payload, isLoadingCidSecundario: false }
    case 'CID_SECUNDARIO_LOADING':
      return { ...state, isLoadingCidSecundario: action.payload }
    case 'TIMEOUT_CONSULTA_CID_SECUNDARIO':
      return { ...state, timeoutConsultaCidSecundario: action.payload, inputCidSecundario: action.input }
    case 'SELECIONAR_CID_SECUNDARIO': {
      const opcaoSelecionada = action.payload;
      const cid = opcaoSelecionada ? opcaoSelecionada.codigo : null;
      const nome = opcaoSelecionada ? opcaoSelecionada.nome : null;

      return { ...state, cidSecundarioSelecionado: cid, nomeCidSecundarioSelecionado: nome, inputCidSecundario: null }
    }
    case 'REMOVER_CID_SECUNDARIO_SELECIONADO':
      return { ...state, cidSecundarioSelecionado: null }
    case 'EXIBIR_DIALOG_EXCLUIR_CID_SECUNDARIO':
      return { ...state, cidExclusao: action.payload, isConfirmarExclusao: true }
    case 'OCULTAR_DIALOG_EXCLUIR_CID_SECUNDARIO':
      return { ...state, isConfirmarExclusao: false }
    case 'EXIBIR_DIALOG_ALTERAR_CID_PRINCIPAL':
      return { ...state, deveConfirmarAlteracaoCidPrincipal: true, novoValorCidPrincipal: action.payload }
    case 'OCULTAR_DIALOG_ALTERAR_CID_PRINCIPAL':
      return { ...state, deveConfirmarAlteracaoCidPrincipal: false, novoValorCidPrincipal: '', inputCidPrincipal: null }
    case 'ATUALIZAR_CID_PRINCIPAL_MESMO_GRUPO_CID_ANTERIOR':
      return { ...state, isCidPricipalMesmoGrupo: action.payload }
    default:
      return state;
  }
};
