import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal from '@govbr/react-components/lib/BrModal'

import { ocultarDialogDetalheComunicacao } from './action'
import { exibirJSONFormatado } from '../../common/JSONUtils';

const DialogDetalhesComunicacao = (props) => {

    const { isDetalharComunicacao, comunicacaoSelecionada } = props.dados;

    return (
      <BrModal id="dialogDetalhesComunicacao" visible={isDetalharComunicacao} 
        large style={{ width: "90%", maxHeight: '90%', overflowY: 'auto' }}
        title={`Detalhes Comunicação ${comunicacaoSelecionada?.id}`} onClose={props.ocultarDialogDetalheComunicacao}>
        <div> 
          <pre>
            {comunicacaoSelecionada?.corpoRequisicao 
              ? exibirJSONFormatado(JSON.parse(comunicacaoSelecionada?.corpoRequisicao))
              : 'Sem dados'}
          </pre>
        </div>

      </BrModal>
    );
}

const mapStateToProps = (state) => ({
  dados: state.comunicacoesExternas
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogDetalheComunicacao
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogDetalhesComunicacao);
