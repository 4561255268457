import { apenasNumero, formatarCPF } from "../../common/formatadores";
import { deleteSeguroPromise, getSeguroPromise, postSeguroPromise, putSeguroPromise } from "../../common/requests";
import { displayLoader, errorMessage, successMessage } from "../principal/action";

const MSG_CAMPO_OBRIGATORIO = "Campo obrigatório";

export const alterarTituloInclusaoPerito = value => ({
  type: 'ALTERAR_TITULO_INCLUSAO_PERITO',
  payload: value
});

export const alterarTituloInclusaoGestor = value => ({
  type: 'ALTERAR_TITULO_INCLUSAO_GESTOR',
  payload: value
});

export const alterarConteudoInclusaoPerito = value => ({
  type: 'ALTERAR_CONTEUDO_INCLUSAO_PERITO',
  payload: value
});

export const alterarConteudoInclusaoGestor = value => ({
  type: 'ALTERAR_CONTEUDO_INCLUSAO_GESTOR',
  payload: value
});

export const incluirItemDicionarioPerito = (titulo, conteudo, cpfPerito) => {
  return (dispatch) => {
    dispatch(displayLoader(true));
    const dadosValidacao = obterDadosValidacao(titulo, conteudo);
    dispatch(atualizarDadosValidacaoPerito(dadosValidacao));
    if (dadosValidacao.isValida) {
      const novoItem = {
        chaveCampo: titulo,
        valorCampo: conteudo,
        cpfPerito: apenasNumero(cpfPerito)
      };

      postSeguroPromise(`pmf-realizar-pericias-service/biblioteca/item/`, novoItem)
      .then((response) => {
        dispatch(atualizarItensDicionarioPeritoAposInclusao(response.data));
        dispatch(successMessage("Item incluído com sucesso"));
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Incluir item dicionário'));
      });
      dispatch(limparDadosInclusaoPerito());
      return;
    }
    dispatch(displayLoader(false));
  }
}

export const incluirItemDicionarioGestor = (titulo, conteudo) => {
  return (dispatch) => {
    dispatch(displayLoader(true));
    const dadosValidacao = obterDadosValidacao(titulo, conteudo);
    dispatch(atualizarDadosValidacaoGestor(dadosValidacao));
    if (dadosValidacao.isValida) {
      const novoItem = {
        chaveCampo: titulo,
        valorCampo: conteudo,
        cpfPerito: 0
      };
      postSeguroPromise(`pmf-realizar-pericias-service/biblioteca/gestao/item/`, novoItem)
      .then((response) => {
        dispatch(atualizarItensDicionarioGestorAposInclusao(response.data));
        dispatch(successMessage("Item incluído com sucesso"));
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Incluir item dicionário'));
      });
      dispatch(limparDadosInclusaoGestor());
      return;
    }
    dispatch(displayLoader(false));
  }
}

const obterDadosValidacao = (titulo, conteudo) => {
  const erroTitulo = (!titulo || titulo.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;
  const erroConteudo = (!conteudo || conteudo.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;

  return {
    isValida: !erroTitulo && !erroConteudo,
    erroTitulo,
    erroConteudo
  }
}

const limparDadosInclusaoGestor = () =>({
  type: 'LIMPAR_DADOS_INCLUSAO_GESTOR'
});

const limparDadosInclusaoPerito = () =>({
  type: 'LIMPAR_DADOS_INCLUSAO_PERITO'
});

const atualizarDadosValidacaoGestor = dadosValidacao =>({
  type: 'ATUALIZAR_DADOS_VALIDACAO_GESTOR',
  payload: dadosValidacao
});

const atualizarDadosValidacaoPerito = dadosValidacao =>({
  type: 'ATUALIZAR_DADOS_VALIDACAO_PERITO',
  payload: dadosValidacao
});

export const exibirDialogExcluirItem = item => ({
  type: 'EXIBIR_DIALOG_EXCLUIR_ITEM',
  payload: item
});

export const ocultarDialogExcluirItem = () => ({
  type: 'OCULTAR_DIALOG_EXCLUIR_ITEM'
});

export const excluirItem = (idItem, isGestor) => {
  return (dispatch) => {
    dispatch(displayLoader(true));
    if(isGestor){
      dispatch(excluirItemGestor(idItem));
    } else{
      dispatch(excluirItemPerito(idItem));
    }
    
    dispatch(ocultarDialogExcluirItem());
  }
}

const excluirItemPerito = (idItem) => {
  return (dispatch) => {
    deleteSeguroPromise(`pmf-realizar-pericias-service/biblioteca/item/${idItem}/excluir`)
      .then((response) => {
        dispatch(atualizarItensDicionarioPeritoAposExclusao(idItem));
        dispatch(successMessage("Item excluído com sucesso"));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Excluir item dicionário'));
        return 0;
      });
  }
}

const excluirItemGestor = (idItem) => {
  return (dispatch) => {
    deleteSeguroPromise(`pmf-realizar-pericias-service/biblioteca/gestao/item/${idItem}/excluir`)
      .then((response) => {
        dispatch(atualizarItensDicionarioGestorAposExclusao(idItem));
        dispatch(successMessage("Item excluído com sucesso"));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Excluir item dicionário'));
        return 0;
      });
  }
}

export const exibirDialogAlterarItem = item => ({
  type: 'EXIBIR_DIALOG_ALTERAR_ITEM',
  payload: item
});

export const ocultarDialogAlterarItem = () => ({
  type: 'OCULTAR_DIALOG_ALTERAR_ITEM'
});

export const alterarItem = (item, isGestor) => {
  return (dispatch) => {
    dispatch(displayLoader(true));
    const dadosValidacao = obterDadosValidacao(item.chaveCampo, item.valorCampo);
    dispatch(atualizarDadosValidacaoItemSelecionado(dadosValidacao));
    if (dadosValidacao.isValida) {
      if(isGestor){
        dispatch(alterarItemGestor(item));
      } else{
        dispatch(alterarItemPerito(item));
      }
      dispatch(ocultarDialogAlterarItem());
      return;
    }
    dispatch(displayLoader(false));
  }
}

const alterarItemPerito = (item) => {
  return (dispatch) => {
    putSeguroPromise(`pmf-realizar-pericias-service/biblioteca/item/`, item)
      .then((response) => {
        dispatch(atualizarItensDicionarioPeritoAposAlteracao(response.data));
        dispatch(successMessage("Item alterado com sucesso"));
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Alterar item dicionário'));
      });
  }
}

const alterarItemGestor = (item) => {
  return (dispatch) => {
    putSeguroPromise(`pmf-realizar-pericias-service/biblioteca/gestao/item/`, item)
      .then((response) => {
        dispatch(atualizarItensDicionarioGestorAposAlteracao(response.data));
        dispatch(successMessage("Item alterado com sucesso"));
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Alterar item dicionário'));
      });
  }
}

export const alterarTituloItemAlteracao = value => ({
  type: 'ALTERAR_TITULO_ITEM_ALTERACAO',
  payload: value
});

export const alterarConteudoItemAlteracao = value => ({
  type: 'ALTERAR_CONTEUDO_ITEM_ALTERACAO',
  payload: value
});

const atualizarDadosValidacaoItemSelecionado = dadosValidacao =>({
  type: 'ATUALIZAR_DADOS_VALIDACAO_ITEM_SELECIONADO',
  payload: dadosValidacao
});

const atualizarItensDicionarioPerito = listaItens =>({
  type: 'ATUALIZAR_ITENS_DICIONARIO_PERITO',
  payload: listaItens
});

const atualizarItensDicionarioGestor = listaItens =>({
  type: 'ATUALIZAR_ITENS_DICIONARIO_GESTOR',
  payload: listaItens
});

const atualizarItensDicionarioPeritoAposInclusao = item =>({
  type: 'INCLUIR_ITEM_DICIONARIO_PERITO',
  payload: item
});

const atualizarItensDicionarioGestorAposInclusao = item =>({
  type: 'INCLUIR_ITEM_DICIONARIO_GESTOR',
  payload: item
});

const atualizarItensDicionarioPeritoAposAlteracao = item =>({
  type: 'ALTERAR_ITEM_DICIONARIO_PERITO',
  payload: item
});

const atualizarItensDicionarioGestorAposAlteracao = item =>({
  type: 'ALTERAR_ITEM_DICIONARIO_GESTOR',
  payload: item
});

const atualizarItensDicionarioPeritoAposExclusao = idItem =>({
  type: 'EXCLUIR_ITEM_DICIONARIO_PERITO',
  payload: idItem
});

const atualizarItensDicionarioGestorAposExclusao = idItem =>({
  type: 'EXCLUIR_ITEM_DICIONARIO_GESTOR',
  payload: idItem
});

export const buscarValoresBibliotecaPerito = (cpfPerito) => {
  return (dispatch) => {
    dispatch(displayLoader(true));

    getSeguroPromise(`pmf-realizar-pericias-service/biblioteca/perito/${apenasNumero(cpfPerito)}`)
      .then((response) => {
        dispatch(displayLoader(false));
        
        const listaCompletaItens = response.data && response.data.length > 0 ? response.data : [];
        
        dispatch(atualizarItensDicionarioPerito(listaCompletaItens?.filter(i => formatarCPF(i.cpfPerito) === cpfPerito)));
        dispatch(atualizarItensDicionarioGestor(listaCompletaItens?.filter(i => i.cpfPerito === 0)));
      })
      .catch((falha) => dispatch(errorMessage(falha, 'Consulta Dicionário Perito')));

  }
}

export const exibirDialogSelecionarItem = (onSelect, onSelectParams, maxLength, valorAtual) => ({
  type: 'EXIBIR_DIALOG_SELECIONAR_ITEM',
  payload: {
    onSelect: onSelect,
    onSelectParams: onSelectParams,
    maxLength: maxLength,
    valorAtual: valorAtual
  }
});

export const ocultarDialogSelecionarItem = () => ({
  type: 'OCULTAR_DIALOG_SELECIONAR_ITEM'
});