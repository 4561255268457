import React from "react";
import { connect } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import {  Grid } from '@material-ui/core';

import { decodeHtml } from '../../../../common/StringUtils';
import "./style.css";
import logo from "../../../../img/logo_pmf.png";

const useStyles = makeStyles({
  container: {
    overflowY: 'scroll',
    height: '80vh',
    display: 'flex-wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titulo: {
    fontWeight: 'bold'
  },
  modulo: {
    marginBottom: '20px',
    pageBreakInside: 'avoid'
  }
  
});

const DetalheLaudoPMF = (props) => {	
  const classes = useStyles();
  const htmlTratado = props.html?.replace('src="img/logo_pmf.png"',`src="${logo}"`);
  return (
    <Grid container className={classes.container}>
      <div dangerouslySetInnerHTML={{ __html: decodeHtml(htmlTratado) }} />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  html: state.modulos.moduloProntuario?.html
});

export default connect(mapStateToProps)(DetalheLaudoPMF);
