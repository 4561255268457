import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import { Grid } from "@material-ui/core";

import { calcularCaracteresRestantes } from '../../../../common/Utils';

import {
  alterarDataDocumMedOdont,
  alterarDescricaoLaudoDocumMedOdont,
  alterarNomeProfissionalDocumMedOdont,
  alterarNumeroDocumMedOdont,
  alterarTipoEmitenteDocumMedOdont,
  alterarUfDocumMedOdont,
  buscarDadosDeMedico,
  limparDadosDeMedido,
  ocultarDialogDocumMedOdont
} from './action';

import { MSG_CAMPOS_OBRIGATORIO } from '../../../../common/Messages';
import { alterarDocumMedOdont, incluirDocumMedOdont } from '../../action';
import { TIPO_IDENT_EMITENTE_ATESTMED_CRM, TIPO_IDENT_EMITENTE_ATESTMED_OUTROS, TIPO_IDENT_EMITENTE_ATESTMED_RMS, tiposPericia } from '../../constants';

class DialogDadosDocumMedOdont extends React.Component {
  
  QTD_MAXIMA_CARACTERES = 500;

  OPCAO_VAZIA = { label: '', value: null };

  state = {
    timeoutIdHandleChangeAlterarNumeroDocumMedOdont: -1,
    idUFDocumentoSelecionado: -1
  }

  handleChangeEmitenteDocumMedOdont = (newValue) => {
    const { numeroDocumento, idUFDocumento } = this.props.config?.documentoSelecionado || {};
    this.props.alterarTipoEmitenteDocumMedOdont(newValue);
    this.limparDadosDeMedico();
    if(newValue === TIPO_IDENT_EMITENTE_ATESTMED_CRM && numeroDocumento && idUFDocumento) {
      this.buscarDadosDeMedico(numeroDocumento, idUFDocumento);
    } else {
      if(newValue === TIPO_IDENT_EMITENTE_ATESTMED_RMS) {
        this.handleChangeAlterarUfDocumMedOdont(null);
      }
    }
  };

  handleChangeAlterarNumeroDocumMedOdont = (newValue) => {
    const { idTipoIdentEmitente, idUFDocumento } = this.props.config?.documentoSelecionado || {};
    this.props.alterarNumeroDocumMedOdont(newValue);
    this.limparDadosDeMedico();
    if(idTipoIdentEmitente === TIPO_IDENT_EMITENTE_ATESTMED_CRM && newValue && idUFDocumento) {
      clearTimeout(this.state.timeoutIdHandleChangeAlterarNumeroDocumMedOdont);
      const newTimeoutId = setTimeout(() => this.buscarDadosDeMedico(newValue, idUFDocumento), 2000);
      this.setState({...this.state, timeoutIdHandleChangeAlterarNumeroDocumMedOdont: newTimeoutId});
    } 
  }

  handleChangeAlterarUfDocumMedOdont = (newValue) => {
    const { idTipoIdentEmitente, numeroDocumento } = this.props.config?.documentoSelecionado || {};
    this.props.alterarUfDocumMedOdont(newValue);
    this.limparDadosDeMedico();
    if(idTipoIdentEmitente === TIPO_IDENT_EMITENTE_ATESTMED_CRM && numeroDocumento && newValue && newValue !== this.state.idUFDocumentoSelecionado) {
      this.buscarDadosDeMedico(numeroDocumento, newValue);
      this.setState({...this.state, idUFDocumentoSelecionado: newValue})
    }
  }

  buscarDadosDeMedico(numeroDocumento, UfDocumento) {
    this.props.buscarDadosDeMedico(numeroDocumento, UfDocumento);
  }

  limparDadosDeMedico() {
    this.props.limparDadosDeMedido();
  }

  render() {
    const { alterarDataDocumMedOdont, alterarNomeProfissionalDocumMedOdont, alterarDescricaoLaudoDocumMedOdont,
      incluirDocumMedOdont, alterarDocumMedOdont, ocultarDialogDocumMedOdont, ufs } = this.props;

    const { tiposIdentEmitentePericiaBI, tiposIdentificacaoEmitente } = this.props.dominioPericia;

    const { isFormVisible, isFormDisabled, isEdit, validacao, documentoSelecionado } = this.props.config;

    const { dataDocumento, idTipoIdentEmitente, numeroDocumento, idUFDocumento, nomeProfissional,
      descricaoLaudo, periciaAnterior } = documentoSelecionado;

    //preenchendo combos de selecao com lista de opcoes    

    /** UF */
    const ufsOrdemAlfabetica = ufs ? ufs.sort((a, b) => a.siglaUf.localeCompare(b.siglaUf)).map(t => ({ label: t.siglaUf, value: t.codigoUfIbge })) : [];
    ufsOrdemAlfabetica.unshift(this.OPCAO_VAZIA);

    const tipoIdentEmitenteOutros = tiposIdentEmitentePericiaBI
           ?.filter(t => t.id === TIPO_IDENT_EMITENTE_ATESTMED_OUTROS)
           ?.map(t => ({ label: t.descricao, value: t.id }))[0];
      
    const opcoesIdentificacaoEmitente = !periciaAnterior 
        ? tiposIdentEmitentePericiaBI
            ?.filter(t => t.id !== TIPO_IDENT_EMITENTE_ATESTMED_OUTROS)
            ?.map(t => ({ label: t.descricao, value: t.id }))
        : tiposIdentificacaoEmitente
            ?.filter(t => t.id !== TIPO_IDENT_EMITENTE_ATESTMED_OUTROS)
            ?.map(t => ({ label: t.descricao, value: t.id }));
            
    opcoesIdentificacaoEmitente.unshift(this.OPCAO_VAZIA);
    opcoesIdentificacaoEmitente.push(tipoIdentEmitenteOutros);

    //variaveis auxiliares de tela
    let tipoDocumentoSelecionado = "Documento";
    if (idTipoIdentEmitente) {
      if (periciaAnterior && tiposIdentificacaoEmitente) {
        tipoDocumentoSelecionado = tiposIdentificacaoEmitente.find(t => t.id === idTipoIdentEmitente)?.descricao
      } else if (!periciaAnterior && tiposIdentEmitentePericiaBI) {
        tipoDocumentoSelecionado = tiposIdentEmitentePericiaBI.find(t => t.id === idTipoIdentEmitente)?.descricao
      }
    }

    const tipoEmitenteDocumento = " Profissional";
    const acao = isEdit ? 'Alterar' : (isFormDisabled ? 'Detalhar' : 'Incluir');

    const caracteresRestantes = calcularCaracteresRestantes(descricaoLaudo, this.QTD_MAXIMA_CARACTERES);

    return (
      <BrModal id="dialogIncluirDocumMedOdont" visible={isFormVisible} style={{ width: "90%", maxHeight: '90%', overflowY: 'auto' }}
        title={`${acao} documento apresentado`} closable={isFormDisabled} onClose={ocultarDialogDocumMedOdont}>
        {
          this.props.config.documentoSelecionado?.mensagemErro &&
          (<BrMessage error>
            <p>{this.props.config.documentoSelecionado?.mensagemErro}</p>
          </BrMessage>
          )
        }
        <Grid container spacing={1}>
          <Grid item sm={2}>
            <BrDatepicker id="dataDocumento" label="* Data do documento"
              date={dataDocumento ? dataDocumento : null} onChange={alterarDataDocumMedOdont}
              disableFuture
              allowInput
              disabled={isFormDisabled}
              erro={validacao?.erroDataDocumento} />
          </Grid>
          <Grid item sm={3}>
            <BrSelect id="emitenteDocumMedOdont" label="* Tipo de Ident. do Emitente"
              items={opcoesIdentificacaoEmitente}
              value={idTipoIdentEmitente !== null && opcoesIdentificacaoEmitente
                ? opcoesIdentificacaoEmitente.find(o => o.value === idTipoIdentEmitente)
                : this.OPCAO_VAZIA}
              onChange={this.handleChangeEmitenteDocumMedOdont}
              disabled={isFormDisabled || this.props.config.isCargaDadosDoMedico}
              erro={validacao?.erroTipoEmitente} />
          </Grid>
          <Grid item sm={5}>
            <BrInput id="numeroDocumento" label={`* ${tipoDocumentoSelecionado} ${tipoEmitenteDocumento}`} mode="number"
              value={numeroDocumento}
              onChange={this.handleChangeAlterarNumeroDocumMedOdont}
              disabled={isFormDisabled || this.props.config.isCargaDadosDoMedico}
              erro={validacao?.erroNumeroDocumento}
              maxLength={15} />
          </Grid>
          { idTipoIdentEmitente !== TIPO_IDENT_EMITENTE_ATESTMED_RMS
                && idTipoIdentEmitente !==TIPO_IDENT_EMITENTE_ATESTMED_OUTROS && 
            <Grid item sm={2}>
              <BrSelect id="ufEmitenteDocumMedOdont" label="* UF"
                items={ufsOrdemAlfabetica}
                value={idUFDocumento && ufsOrdemAlfabetica
                  ? ufsOrdemAlfabetica.find(u => u.value === idUFDocumento)
                  : this.OPCAO_VAZIA}
                onChange={this.handleChangeAlterarUfDocumMedOdont}
                disabled={isFormDisabled || this.props.config.isCargaDadosDoMedico}
                erro={validacao?.erroUf} />
            </Grid>
          }
          <Grid item sm={12}>
            <BrInput id="nomeProfissional" label={`* Nome  ${tipoEmitenteDocumento}`}
              value={nomeProfissional}
              onChange={alterarNomeProfissionalDocumMedOdont}
              disabled={isFormDisabled || this.props.config.documentoSelecionado?.dadosDoMedico?.nome}
              erro={validacao?.erroNomeProfissional}
              maxLength={200} />
          </Grid>
          <Grid item sm={12} hidden={!this.props.config.documentoSelecionado?.dadosDoMedico}>
            <Grid container spacing={1} style={{backgroundColor: "#f4f4f4", padding: "10px", margin: 0, width: "auto"}}>
              <Grid item xs={12}>
                <span htmlFor="crm">CRM: </span>
                <span id="crm" className="texto-informativo">{this.props.config.documentoSelecionado?.dadosDoMedico?.crm}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="estado">Estado: </span>
                <span id="estado" className="texto-informativo">{this.props.config.documentoSelecionado?.dadosDoMedico?.estado}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="nome">Nome: </span>
                <span id="nome" className="texto-informativo">{this.props.config.documentoSelecionado?.dadosDoMedico?.nome}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="origem">Tipo de Inscrição: </span>
                <span id="origem" className="texto-informativo">{this.props.config.documentoSelecionado?.dadosDoMedico?.tipoInscricao}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="status">Status: </span>
                <span id="status" className="texto-informativo">{this.props.config.documentoSelecionado?.dadosDoMedico?.status}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="especialidades">Especialidades: </span>
                <span id="especialidades" className="texto-informativo">
                  {this.props.config.documentoSelecionado?.dadosDoMedico?.especialidades 
                    || "Médico Assistente sem especialidade registrada"}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea id="descricaoLaudo" label="Descrição"
                    onChange={alterarDescricaoLaudoDocumMedOdont}
                    value={descricaoLaudo}
                    maxLength={this.QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresRestantes}`}
                    disabled={isFormDisabled} />
                </Grid>
                 {
                  !isFormDisabled && 
                 <Grid item>
                     <ButtonSelecionarItem
                          id="selecionarItemDicionarioDescricaoDocumento"
                          onSelect={alterarDescricaoLaudoDocumMedOdont}
                          maxLength={caracteresRestantes}
                          valorAtual={descricaoLaudo}/>
                 </Grid>
                 }
               </Grid>
          </Grid>
        </Grid>

        <p>{MSG_CAMPOS_OBRIGATORIO}</p>
        {
          !isFormDisabled &&
          <BrModalFooter>
            <BrButton secondary className="m-2" onClick={ocultarDialogDocumMedOdont}>Cancelar</BrButton>
            <BrButton primary className="m-2"
              onClick={() => isEdit ? alterarDocumMedOdont(documentoSelecionado) : incluirDocumMedOdont(documentoSelecionado)}
              disabled={this.props.config.isCargaDadosDoMedico}>
              Confirmar
            </BrButton>
          </BrModalFooter>
        }
        <BrModal small visible={this.props.config.isCargaDadosDoMedico} closable={false}>
          Carregando dados de médico...
        </BrModal>
      </BrModal >
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.modulos.moduloDocumMedOdont,
  dominioPericia: state.principal.dominioPericia,
  ufs: state.principal.dominioSdc.ufs,
  principal: state.principal,
  isTipoPericiaAtestmed: (state.pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SIBE.tipo) || (state.pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarDataDocumMedOdont,
  alterarTipoEmitenteDocumMedOdont,
  alterarUfDocumMedOdont,
  alterarNumeroDocumMedOdont,
  alterarNomeProfissionalDocumMedOdont,
  alterarDescricaoLaudoDocumMedOdont,
  incluirDocumMedOdont,
  alterarDocumMedOdont,
  ocultarDialogDocumMedOdont,
  buscarDadosDeMedico,
  limparDadosDeMedido
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogDadosDocumMedOdont);
