import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrTabs from '@govbr/react-components/lib/BrTabs';
import ComunicAcidTrab from "./ComunicAcidTrab";
import PericiasAnteriores from "./PericiasAnteriores";

const PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS = '---';

export const preencherCamposVazios = (valor) => {
  return valor ? valor : PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS;
}

const Prontuario = ({ periciasAnteriores, comunicadosAcidTrab, numeroBeneficioAtual, avaliado }) => {

  const periciasAnterioresDeOutrosBenef = (numeroBeneficioAtual && periciasAnteriores &&
    periciasAnteriores.filter(pericia => pericia.numeroBeneficio !== numeroBeneficioAtual)) || periciasAnteriores;

  const periciasAnterioresBenefAtual = numeroBeneficioAtual && periciasAnteriores &&
    periciasAnteriores.filter(pericia => pericia.numeroBeneficio === numeroBeneficioAtual);

  const possuiPerAntBenefAtual = periciasAnterioresBenefAtual && periciasAnterioresBenefAtual.length > 0;

  const [selectedTab, setSelectTab] = useState(possuiPerAntBenefAtual ? "tabLaudosBenefAtual" : "tabLaudos");

  return (
    <div id="prontuario" className="conteudoAbaSemHeaderSecao">
      <BrTabs
        value={selectedTab}
        onChange={value => setSelectTab(value)}
        label="Prontuário"
      >
        <BrTabs.Tabs label="Tabs">
          {possuiPerAntBenefAtual && <BrTabs.Tab id="tabLaudosBenefAtual" label="Perícia(s) do Benefício Atual" />}
          <BrTabs.Tab id="tabLaudos" label="Perícia(s) do(s) Benefício(s) Anterior(es)" />
          <BrTabs.Tab id="tabCats" label="Comunicado(s) de Acidente de Trabalho" />
        </BrTabs.Tabs>

        <BrTabs.Content>
          {periciasAnterioresBenefAtual && periciasAnterioresBenefAtual.length > 0 &&
            <BrTabs.Panel contentFor="tabLaudosBenefAtual">
              <PericiasAnteriores periciasAnteriores={periciasAnterioresBenefAtual} avaliado={avaliado} />
            </BrTabs.Panel>}
          <BrTabs.Panel contentFor="tabLaudos">
            <PericiasAnteriores periciasAnteriores={periciasAnterioresDeOutrosBenef} avaliado={avaliado} />
          </BrTabs.Panel>
          <BrTabs.Panel contentFor="tabCats">
            <ComunicAcidTrab comunicadosAcidTrab={comunicadosAcidTrab} />
          </BrTabs.Panel>
        </BrTabs.Content>
      </BrTabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  periciasAnteriores: state.pericia.periciasAnteriores,
  comunicadosAcidTrab: state.pericia.comunicadosAcidTrab,
  numeroBeneficioAtual: state.pericia.numeroBeneficio,
  avaliado: state.pericia.avaliado
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Prontuario);
