const INITIAL_STATE = { }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ALTERAR_TITULO_INCLUSAO_PERITO':
      return { ...state, tituloItemPeritoInclusao: action.payload }
    case 'ALTERAR_CONTEUDO_INCLUSAO_PERITO':
      return { ...state, conteudoItemPeritoInclusao: action.payload }
    case 'ALTERAR_TITULO_INCLUSAO_GESTOR':
      return { ...state, tituloItemGestorInclusao: action.payload }
    case 'ALTERAR_CONTEUDO_INCLUSAO_GESTOR':
      return { ...state, conteudoItemGestorInclusao: action.payload }
    case 'LIMPAR_DADOS_INCLUSAO_PERITO':
      return { ...state, tituloItemPeritoInclusao: undefined, conteudoItemPeritoInclusao: undefined }
    case 'LIMPAR_DADOS_INCLUSAO_GESTOR':
      return { ...state, tituloItemGestorInclusao: undefined, conteudoItemGestorInclusao: undefined }
    case 'ATUALIZAR_DADOS_VALIDACAO_GESTOR':
      return { ...state, validacaoItemGestor: {...action.payload} }
    case 'ATUALIZAR_DADOS_VALIDACAO_PERITO':
      return { ...state, validacaoItemPerito: {...action.payload} }
    case 'EXIBIR_DIALOG_EXCLUIR_ITEM':
      return { ...state, itemSelecionado: action.payload, isConfirmarExclusao: true }
    case 'OCULTAR_DIALOG_EXCLUIR_ITEM':
      return { ...state, isConfirmarExclusao: false }
    case 'EXIBIR_DIALOG_ALTERAR_ITEM':
      return { ...state, itemSelecionado: action.payload, isAlteracao: true }
    case 'OCULTAR_DIALOG_ALTERAR_ITEM':
      return { ...state, isAlteracao: false }
    case 'ALTERAR_TITULO_ITEM_ALTERACAO':
      return { ...state, itemSelecionado: {...state.itemSelecionado, chaveCampo: action.payload} }
    case 'ALTERAR_CONTEUDO_ITEM_ALTERACAO':
      return { ...state, itemSelecionado: {...state.itemSelecionado, valorCampo: action.payload} }
    case 'ATUALIZAR_DADOS_VALIDACAO_ITEM_SELECIONADO':
      return { ...state, itemSelecionado: {...state.itemSelecionado, validacao: action.payload} }
    case 'ATUALIZAR_ITENS_DICIONARIO_PERITO':
      return { ...state, itensDicionarioPerito: action.payload }
    case 'ATUALIZAR_ITENS_DICIONARIO_GESTOR':
      return { ...state, itensDicionarioGestor: action.payload }
    case 'INCLUIR_ITEM_DICIONARIO_PERITO':{
      let listaItensPeritoAtualizada = state.itensDicionarioPerito || [];

      listaItensPeritoAtualizada.push(action.payload);

      return { ...state, itensDicionarioPerito: listaItensPeritoAtualizada }
    }
    case 'INCLUIR_ITEM_DICIONARIO_GESTOR':{
      let listaItensGestorAtualizada = state.itensDicionarioGestor || [];

      listaItensGestorAtualizada.push(action.payload);

      return { ...state, itensDicionarioGestor: listaItensGestorAtualizada }
    }
    case 'ALTERAR_ITEM_DICIONARIO_PERITO':{
      const itemAlterado = action.payload;
      
      let listaItensPeritoAtualizada = state.itensDicionarioPerito;
      const indexAlteracao = listaItensPeritoAtualizada.indexOf(listaItensPeritoAtualizada.find(i => i.id === itemAlterado.id));
      
      listaItensPeritoAtualizada[indexAlteracao] = itemAlterado;
      
      return { ...state, itensDicionarioPerito: listaItensPeritoAtualizada }
    }
    case 'ALTERAR_ITEM_DICIONARIO_GESTOR':{
      const itemAlterado = action.payload;
      
      let listaItensGestorAtualizada = state.itensDicionarioGestor;
      const indexAlteracao = listaItensGestorAtualizada.indexOf(listaItensGestorAtualizada.find(i => i.id === itemAlterado.id));
      
      listaItensGestorAtualizada[indexAlteracao] = itemAlterado;
      
      return { ...state, itensDicionarioGestor: listaItensGestorAtualizada }
    }
    case 'EXCLUIR_ITEM_DICIONARIO_PERITO':{
      const listaItensPeritoAtualizada = state.itensDicionarioPerito.filter(i => i.id !== action.payload);
      
      return { ...state, itensDicionarioPerito: listaItensPeritoAtualizada }
    }
    case 'EXCLUIR_ITEM_DICIONARIO_GESTOR':{
      const listaItensGestorAtualizada = state.itensDicionarioGestor.filter(i => i.id !== action.payload);
      
      return { ...state, itensDicionarioGestor: listaItensGestorAtualizada }
    }
    case 'EXIBIR_DIALOG_SELECIONAR_ITEM':{
      const {onSelectParams, onSelect, maxLength, valorAtual} = action.payload;
      return { ...state, isSelecaoDicionario: true, onSelect: onSelect, onSelectParams: onSelectParams, maxLength: maxLength, valorAtual: valorAtual }
    }
      case 'OCULTAR_DIALOG_SELECIONAR_ITEM':
      return { ...state, isSelecaoDicionario: false }
    default:
      return state;
  }
}