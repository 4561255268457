import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { alterarComplementoDocumentoAcompanhante, alterarComplementoDocumentoAvaliado, alterarNomeAcompanhante, alterarNumeroDocumentoAcompanhante, alterarNumeroDocumentoAvaliado, alterarTipoDocumentoAcompanhante, alterarTipoDocumentoAvaliado } from '../../action';

import HeaderSecao from '../../../../components/HeaderSecao';

import BrInput from '@govbr/react-components/lib/BrInput';
import BrSelect from '@govbr/react-components/lib/BrSelect';

import { Grid } from "@material-ui/core";
import { apenasAlfanumerico, letrasComAcentuacaoEspacoHifen } from '../../../../common/formatadores';

const TIPO_DOCUMENTO_PASSAPORTE = 3;
class Documentos extends React.Component {
  OPCAO_VAZIA = { label: 'Selecione uma opção', value: null };

  render() {
    const { documentos, tiposDocumento, validacao, isSomenteLeitura,
      alterarTipoDocumentoAvaliado, alterarTipoDocumentoAcompanhante, alterarNumeroDocumentoAvaliado,
      alterarComplementoDocumentoAvaliado, alterarNumeroDocumentoAcompanhante, alterarComplementoDocumentoAcompanhante,
      alterarNomeAcompanhante } = this.props;

    const opcoesDocumentos = tiposDocumento ? tiposDocumento.map(t => ({ label: t.nome, value: t.codigo })) : [];
    opcoesDocumentos.unshift(this.OPCAO_VAZIA);

    const isDocumentoPassaporte = documentos.tipoDocumentoCivilAvaliado && documentos.tipoDocumentoCivilAvaliado === TIPO_DOCUMENTO_PASSAPORTE;
    const isDocumentoAcompanhantePassaporte = documentos.tipoDocumentoCivilAcompanhante && documentos.tipoDocumentoCivilAcompanhante === TIPO_DOCUMENTO_PASSAPORTE;

    return (
      <div id="abaDocumentos">
        <HeaderSecao texto="Documentos do Avaliado" />

        <Grid container spacing={3}>
          <Grid item sm={4}>
            <BrSelect id="tipoDocumentoAvaliado" label="* Tipo do Documento"
              items={opcoesDocumentos}
              value={documentos.tipoDocumentoCivilAvaliado && tiposDocumento
                ? opcoesDocumentos.find(o => o.value === documentos.tipoDocumentoCivilAvaliado)
                : this.OPCAO_VAZIA}
              onChange={alterarTipoDocumentoAvaliado}
              erro={validacao ? validacao.erroTipoDocAvaliado : null}
              disabled={isSomenteLeitura} />
          </Grid>
          <Grid item sm={4}>
            {
              isDocumentoPassaporte
                ? <BrInput id="numeroDocumentoAvaliado" label="* Número do Documento"
                  value={documentos.numeroDocAvaliado}
                  onChange={alterarNumeroDocumentoAvaliado}
                  filter={apenasAlfanumerico}
                  erro={validacao ? validacao.erroNumeroDocAvaliado : null}
                  disabled={isSomenteLeitura}
                  maxLength={32} />
                : <BrInput id="numeroDocumentoAvaliado" mode="number" label="* Número do Documento"
                  value={documentos.numeroDocAvaliado}
                  onChange={alterarNumeroDocumentoAvaliado}
                  erro={validacao ? validacao.erroNumeroDocAvaliado : null}
                  disabled={isSomenteLeitura}
                  maxLength={32} />
            }
          </Grid>
          <Grid item sm={4}>
            <BrInput id="complementoDocumentoAvaliado" label="Complemento"
              value={documentos.complementoDocAvaliado}
              onChange={alterarComplementoDocumentoAvaliado}
              disabled={isSomenteLeitura}
              maxLength={100} />
          </Grid>
        </Grid>

        <HeaderSecao texto="Documentos do Acompanhante/Responsável pelas Informações" />

        <Grid container spacing={3} className="mb-2">
          <Grid item sm={12}>
            <BrInput id="nomeAcompanhante" label="** Nome"
              value={documentos.nomeAcompanhante}
              onChange={alterarNomeAcompanhante}
              filter={letrasComAcentuacaoEspacoHifen}
              erro={validacao ? validacao.erroNomeAcompanhante : null}
              disabled={isSomenteLeitura}
              maxLength={100} />
          </Grid>
          <Grid item sm={4}>
            <BrSelect id="tipoDocumentoAcompanhante" label="** Tipo do Documento"
              items={opcoesDocumentos}
              value={documentos.tipoDocumentoCivilAcompanhante && tiposDocumento
                ? opcoesDocumentos.find(o => o.value === documentos.tipoDocumentoCivilAcompanhante)
                : this.OPCAO_VAZIA}
              onChange={alterarTipoDocumentoAcompanhante}
              erro={validacao ? validacao.erroTipoDocAcompanhante : null}
              disabled={isSomenteLeitura} />
          </Grid>
          <Grid item sm={4}>
            {
              isDocumentoAcompanhantePassaporte
                ? <BrInput id="numeroDocumentoAcompanhante" label="** Número do Documento"
                  value={documentos.numeroDocAcompanhante}
                  onChange={alterarNumeroDocumentoAcompanhante}
                  filter={apenasAlfanumerico}
                  erro={validacao ? validacao.erroNumeroDocAcompanhante : null}
                  disabled={isSomenteLeitura}
                  maxLength={32} />
                : <BrInput id="numeroDocumentoAcompanhante" mode="number" label="** Número do Documento"
                  value={documentos.numeroDocAcompanhante}
                  onChange={alterarNumeroDocumentoAcompanhante}
                  erro={validacao ? validacao.erroNumeroDocAcompanhante : null}
                  disabled={isSomenteLeitura}
                  maxLength={32} />
            }

          </Grid>
          <Grid item sm={4}>
            <BrInput id="complementoDocumentoAcompanhante" label="Complemento"
              value={documentos.complementoDocAcompanhante}
              onChange={alterarComplementoDocumentoAcompanhante}
              disabled={isSomenteLeitura}
              maxLength={100} />
          </Grid>
        </Grid>
        <p className="label" style={{marginTop: "var(--spacing-scale-2x)"}}>(**) Campos obrigatórios se documento do acompanhante preenchido</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dominioSdc, validacao, isSomenteLeitura } = state.principal;
  return ({
    documentos: state.pericia.moduloDocApresentado,
    tiposDocumento: dominioSdc.tiposDocCivil,
    validacao: validacao.documentos,
    isSomenteLeitura
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarTipoDocumentoAvaliado,
  alterarNumeroDocumentoAvaliado,
  alterarComplementoDocumentoAvaliado,
  alterarTipoDocumentoAcompanhante,
  alterarNumeroDocumentoAcompanhante,
  alterarComplementoDocumentoAcompanhante,
  alterarNomeAcompanhante
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Documentos);