import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BrButton from "@govbr/react-components/lib/BrButton";
import BrTable from "@govbr/react-components/lib/BrTable";

import { exibirDialogDetalharExame } from "../action";
import {
  obterTipoIdentificacao,
  obterTipoExameLaudo,
  obterNumeroComUf,
} from "../utils";

import { formatarDateTimeISOToDataBr } from "../../../../../common/DataUtils";

const ExamesLaudosAnteriores = (props) => {
  const { examesAnteriores, isFormVisible, exibirDialogDetalharExame } = props;
  const existeExamesAnteriores =
    examesAnteriores && examesAnteriores.length > 0;

  return (
    <div id="abaExamesELaudosAnteriores" className="conteudoAbaSemHeaderSecao">
      {!existeExamesAnteriores && <p>Não existem exames cadastrados.</p>}
      {existeExamesAnteriores && (
        <BrTable
          id="tabelaExamesLaudosAnteriores"
          title="Exames e Laudos perícia anterior"
          layout="fixed"
          columns={[
            { Header: "Data do documento", accessor: "data" },
            {
              Header: "Tipo",
              accessor: "tipoExameLaudo",
              alignItems: "flex-end",
            },
            { Header: "Tipo de Identificação", accessor: "tipoIdentificacao" },
            { Header: "Número", accessor: "numero" },
            { Header: "Ações", accessor: "acao" },
          ]}
          data={examesAnteriores.map((item) => ({
            data: formatarDateTimeISOToDataBr(item.dataDocumento),
            tipoExameLaudo: obterTipoExameLaudo(
              item.idTipoExameLaudo,
              props.tiposExameLaudo
            ),
            tipoIdentificacao: obterTipoIdentificacao(
              item.idTipoIdentEmitente,
              props.tiposIdentificacaoEmitente
            ),
            numero: obterNumeroComUf(item, props.ufs),
            acao: (
              <>
                <BrButton
                  id="detalharExameLaudoAnterior"
                  circle
                  onClick={() => exibirDialogDetalharExame(item)}
                  disabled={isFormVisible}
                >
                  <i className={`fas fa-info`} title="Detalhar" />
                </BrButton>
              </>
            ),
          }))}
          headerProps={{
            show: false,
            showDensityOptions: false,
            searchProp: {
              show: false,
            },
          }}
          footerProps={{ show: false }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  examesAnteriores: state.pericia.periciaAnterior?.examesLaudoAnteriores,
  isFormVisible: state.modulos.moduloExameLaudo.isFormVisible,
  tiposIdentificacaoEmitente:
    state.principal.dominioPericia.tiposIdentificacaoEmitente,
  tiposExameLaudo: state.principal.dominioPericia.tiposExameLaudo,
  ufs: state.principal.dominioSdc.ufs,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exibirDialogDetalharExame,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamesLaudosAnteriores);
