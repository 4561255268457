import { obterFomularioFuncoesDoCorpo } from '../../templatePericiaUtil';
import { isGruposComValorDominioZerado } from '../FuncoesDoCorpo'
import { obterMapRespostasGrupos, obterMapRespostasPerguntas } from '../../../../components/ModuloFormulario/utils'
import { MSG_CAMPO_OBRIGATORIO } from '../../../../common/Messages';
import { validarValorMinimo } from '../../../../common/ValidationUtil';

export const verificarSeCamposPreenchidos = (pericia, getState) => {
  const respostasGrupoForm = pericia?.respostasGrupoForm;
  const respostasPerguntaForm = pericia?.respostasPerguntaForm;

  const formFuncoesDoCorpo = obterFomularioFuncoesDoCorpo(getState().principal.template);
  const gruposFuncoesCorpoComResposta = formFuncoesDoCorpo && formFuncoesDoCorpo.gruposFormulario.filter(g =>
    respostasGrupoForm.find((res) => res.idGrupoFormulario === g.id));
  const perguntasFuncoesCorpoComResposta = respostasPerguntaForm && respostasPerguntaForm.filter(r =>
    formFuncoesDoCorpo.gruposFormulario.find(grupo => {
      const idsPerguntasGrupo = grupo.gruposFormPerguntas.map(pergunta => pergunta.id);
      return idsPerguntasGrupo.includes(r.idPerguntaFormulario);
    })
  );
  const deveValidarFuncoesDoCorpo = (gruposFuncoesCorpoComResposta && gruposFuncoesCorpoComResposta.length > 0)
    || (perguntasFuncoesCorpoComResposta && perguntasFuncoesCorpoComResposta.length > 0);

  return deveValidarFuncoesDoCorpo;
}

export const validar = (pericia, getState) => {
  const QTD_MINIMA_CARACTERES = 50;
  const justificativaDominiosFuncoesCorpoComValorZero = pericia?.resultadoParcialBPC?.justificativaDominiosFuncoesCorpoComValorZero?.trim();
  let respostasPerguntas = pericia.respostasPerguntaForm;
  let respostasGrupos = pericia.respostasGrupoForm;
  let mapRespostasPerguntas = obterMapRespostasPerguntas(respostasPerguntas);
  let mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupos);
  let formFuncoesDoCorpo = obterFomularioFuncoesDoCorpo(getState().principal.template);

  let gruposComErroJustificativaObrigatoria = formFuncoesDoCorpo.gruposFormulario.filter((grupoFormulario) => respostasGrupos.find((res) => res.idGrupoFormulario === grupoFormulario.id
    && res.qualificador === 0 && res.justQualifZero === undefined));

  let gruposSemQtdRespostasRequeridas = formFuncoesDoCorpo.gruposFormulario.filter((grupoFormulario) => {
    let perguntasHabilitadasComResposta = grupoFormulario.gruposFormPerguntas &&
      grupoFormulario.gruposFormPerguntas.filter((pergunta) => pergunta.habilitado &&
        mapRespostasPerguntas.get(pergunta.id) && mapRespostasPerguntas.get(pergunta.id).qualificador !== undefined);
    return grupoFormulario.qtdeRespostasRequeridas && grupoFormulario.qtdeRespostasRequeridas > 0 && perguntasHabilitadasComResposta.length < grupoFormulario.qtdeRespostasRequeridas;
  });

  let gruposComDominioSemResposta = formFuncoesDoCorpo.gruposFormulario.filter((grupoFormulario) => !mapRespostasGrupos.get(grupoFormulario.id) || mapRespostasGrupos.get(grupoFormulario.id).qualificador === undefined);
  gruposComDominioSemResposta = gruposComDominioSemResposta.filter(g => gruposSemQtdRespostasRequeridas.findIndex(g2 => g2.id === g.id) === -1);

  const todosGruposComValorDominioZerado = isGruposComValorDominioZerado(formFuncoesDoCorpo.gruposFormulario, obterMapRespostasGrupos(respostasGrupos));
  let erroJustificativaObrigatoriaGruposComValorDominioZerado = todosGruposComValorDominioZerado
    && (!pericia.resultadoParcialBPC || !pericia.resultadoParcialBPC.justificativaDominiosFuncoesCorpoComValorZero);

  let erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima = todosGruposComValorDominioZerado 
    && !validarValorMinimo(justificativaDominiosFuncoesCorpoComValorZero?.length, parseInt(QTD_MINIMA_CARACTERES));


  return {
    isValida: gruposComErroJustificativaObrigatoria.length === 0 &&
      gruposSemQtdRespostasRequeridas.length === 0 &&
      gruposComDominioSemResposta.length === 0 &&
      !erroJustificativaObrigatoriaGruposComValorDominioZerado && !erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima,
      erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima: erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima &&
       `O campo deve ser preenchido com no mínimo ${QTD_MINIMA_CARACTERES} caracteres`,
    gruposComErroJustificativa: gruposComErroJustificativaObrigatoria.map((g) => ({
      id: g.id,
      ordem: g.ordemExibicao,
      erro: `Justificativa obrigatória! Foi atribuído qualificador igual '0' ao domínio ${g.indice} - ${g.dominio}`
    })),
    erroJustificativaObrigatoriaGruposComValorDominioZerado: erroJustificativaObrigatoriaGruposComValorDominioZerado && MSG_CAMPO_OBRIGATORIO,
    gruposComErroDominio: [
      ...gruposSemQtdRespostasRequeridas.map((g) => ({
        id: g.id,
        ordem: g.ordemExibicao,
        erro: `Informe pelo menos ${g.qtdeRespostasRequeridas} unidade(s) de classificação para o domínio <strong>${g.indice} - ${g.dominio}</strong>`
      })),
      ...gruposComDominioSemResposta.map((g) => ({
        id: g.id,
        ordem: g.ordemExibicao,
        erro: `Informe o qualificador para o domínio <strong>${g.indice} - ${g.dominio}</strong>`
      }))
    ]
  };
}