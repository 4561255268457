import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';

const MensagemAcidenteTrabalho = ({comunicadosAcidTrab, }) => {

  return (
    (comunicadosAcidTrab && comunicadosAcidTrab.length > 0) 
    ? 
      <div style={{marginTop: '16px'}}>
      <BrMessage warning>
        Existe um ou mais CATs informados para este segurado.
      </BrMessage>
      </div>
    : 
      null
  );
}

const mapStateToProps = (state) => {
  return ({comunicadosAcidTrab: state.pericia.comunicadosAcidTrab});
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(MensagemAcidenteTrabalho);
