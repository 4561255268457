import React from 'react';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import { connect } from "react-redux";

const MensagemTelemedicinaExameFisico = (props) => {

  return (
    <>
    {
      props.realizadaPorTelemedicina &&
      <div style={{marginTop: '16px'}}>
        <BrMessage warning>
        Exame físico com uso de tecnologia de Telemedicina conforme Lei n° 14.724/2023 e Portaria MPS n° 674/2024
        </BrMessage>
      </div>
    }
    </>
  );
}

const mapStateToProps = (state) => ({
  realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina
});


export default connect(mapStateToProps) (MensagemTelemedicinaExameFisico);
