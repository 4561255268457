import React from "react";
import { Provider } from "react-redux";

import store from "./store";

import {
  storeToken,
  obterDadosUsuarioLogado,
  isUsuarioLogado,
  unStoreToken
} from "./common/AuthManager";
import App from "./features/App";
import { FRONTEND_INDEX, AUTH_PATH_2, AUTH_PATH, AUTH_PATH_LOGOUT_OUT } from "./common/constants";
import Loading from "./components/loading/Loading";

const root = () => {
  if (isUsuarioLogado()) {
    let userData = obterDadosUsuarioLogado();
    store.getState().principal.userData = userData;
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }

  if (
    document.URL.search("access_denied") !== -1 ||
    document.location.hash.search("access_denied") !== -1
  ) {
    unStoreToken();
    window.open(AUTH_PATH_LOGOUT_OUT, "_self");
    return;
  }

  if (
    document.URL.search("access_token") !== -1 ||
    document.location.hash.search("access_token") !== -1
  ) {
    storeToken();
    store.getState().principal.userData = obterDadosUsuarioLogado();

    let url = document.location.href.split('#')[0];  
    setTimeout(function () {
      window.open(url, "_self");
    }, 1000);
    return <Loading />;
  }

  let urlAuth = AUTH_PATH;
  if (document.location.pathname === '/pericia') {
    // eslint-disable-next-line no-undef
    const params = new URLSearchParams(document.location.search);
    let hash = params.get('hash');
    let token = params.get('token');
    if (hash && token) { 
      urlAuth = `${AUTH_PATH_2}&redirect_uri=` + encodeURIComponent(`${FRONTEND_INDEX}pericia?hash=${hash}&token=${token}`);
    }
  }

  //if (document.)
  setTimeout(function () { window.open(urlAuth, '_self'); }, 1000);
  return <Loading />


};

export default root;
