import { padLeft } from './StringUtils';

const MASCARA_TEL_CELULAR = "(##) #####-####";
const MASCARA_TEL_CELULAR_DDI = "+## ## #####-####";
const MASCARA_TEL_CELULAR_DDI_CODIGO_LOCAL = "+## ### #####-####";
const MASCARA_TEL_FIXO = "(##) ####-####";
const MASCARA_TEL_FIXO_DDI = "+## ## ####-####";
export const MASCARA_CNPJ = "##.###.###/####-##";
export const MASCARA_CEI = "##.###.#####/##";
export const MASCARA_CPF = "###.###.###-##";
export const MASCARA_NB = "###.###.###-#";

export const formatarCNPJ = (cnpj) => {
  if (!cnpj) {
    return ''
  }
  return padLeft(cnpj, '0', 14).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export const formatarCei = (cei) => format(MASCARA_CEI, cei);

export function format(mask, value) {
  if (value) {
    let isCharMascara;
    let posicaoCampo = 0;
    let valorFormatado = '';
    const campoSoNumeros = removeMask(String(value));
    let tamanhoMascara = mask ? mask.length : 0;
    const regex = new RegExp('-|\\.|\\/|\\(|\\)| |\\+');

    for (let i = 0; i < tamanhoMascara; i++) {
      isCharMascara = regex.test(mask.charAt(i));

      if (isCharMascara) {
        valorFormatado += mask.charAt(i);
      } else {
        valorFormatado += campoSoNumeros.charAt(posicaoCampo);
        posicaoCampo++;
      }
    }

    return valorFormatado;
  }
  return '';
}

export const formatarCPF = (numeroCpf) => {

  if (numeroCpf) {
    let cpfSoNumeros = String(numeroCpf).replace(/\D/g, "");
    let strCpf = padLeft(cpfSoNumeros, '0', 11);
    let cpfFormatado = strCpf.slice(-2);
    cpfFormatado = strCpf.slice(-5, -2) + '-' + cpfFormatado;
    cpfFormatado = strCpf.slice(-8, -5) + '.' + cpfFormatado;
    cpfFormatado = strCpf.slice(-11, -8) + '.' + cpfFormatado;
    return cpfFormatado;
  }
  return numeroCpf;
}

export const formatarTelefone = (tel) => {
  if (tel !== undefined) {
    const telSemFormatacao = String(tel).replace(/\D/g, "");
    if (telSemFormatacao.length === 10) {
      return format(MASCARA_TEL_FIXO, telSemFormatacao);
    } else if (telSemFormatacao.length === 11) {
      return format(MASCARA_TEL_CELULAR, telSemFormatacao);
    } else if (telSemFormatacao.length === 12) {
      return format(MASCARA_TEL_FIXO_DDI, telSemFormatacao);
    } else if (telSemFormatacao.length === 13) {
      return format(MASCARA_TEL_CELULAR_DDI, telSemFormatacao);
    } else if (telSemFormatacao.length === 14) {
      return format(MASCARA_TEL_CELULAR_DDI_CODIGO_LOCAL, telSemFormatacao);
    } else {
      return telSemFormatacao;
    }
  }
}

export const formatarNIT = value => {
  value = String(value).replace(/\D/g, "")
  value = String(value).replace(/(\d{3})(\d)/, "$1.$2")
  value = String(value).replace(/(\d{5})(\d)/, "$1.$2")
  value = String(value).replace(/(\d{2})(\d{1,2})$/, "$1-$2")
  return value;
}

export const formatarNB = nb => {
  nb = padLeft(nb?.toString(), '0', 10);
  let nbFormatado = nb.slice(-1);
  nbFormatado = nb.slice(-4, -1) + '-' + nbFormatado;
  nbFormatado = nb.slice(-7, -4) + '.' + nbFormatado;
  nbFormatado = nb.slice(-10, -7) + '.' + nbFormatado;

  return nbFormatado;
};

export const formatarNumeroComunicAcidTrab = numeroCat => {
  let numeroCatString = String(numeroCat);
  numeroCatString = numeroCatString.replace(/(\d{4})(\d)/, "$1-$2")
  numeroCatString = numeroCatString.replace(/(\d{6})(\d)/, "$1-$2")
  numeroCatString = numeroCatString.replace(/(\d{1})(\d{1,2})$/, "$1/$2")

  return numeroCatString;
};

/* eslint-disable no-new-wrappers */
export const removeNumeros = value => value ? new String(value).replace(/[0-9]/g, '') : value;

export const removeMask = value => value ? String(value).replace(/[^0-9]/g, '') : value;

export const removeMaskMatricula = value => value ? String(value).replace(/[^0-9X]/g, '') : value

export const apenasAlfanumerico = value => value ? String(value).replace(/\W/g, '') : value;

export const letrasComAcentuacaoEspacoHifen = value => value.replace(/[^a-zA-ZÀ-ú -]/g, '');

export const apenasNumero = value => removeMask(value);

export const formatarSimNao = value => "SIM" === value ? "Sim" : "NAO" === value ? "Não" : undefined;

export const formatarSimNaoBooleano = value => 1 === value ? "Sim" : 0 === value ? "Não" : undefined;





