import { TIPO_IDENT_EMITENTE_ATESTMED_CRM } from '../../constants';

const INITIAL_STATE = {
  documentoSelecionado: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EXIBIR_DIALOG_INCLUIR_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: {idTipoIdentEmitente:TIPO_IDENT_EMITENTE_ATESTMED_CRM}, isFormVisible: true, isFormDisabled: false, isEdit: false, validacao: null }
    case 'OCULTAR_DIALOG_DOCUM_MED_ODONT':
      return { ...state, isFormVisible: false, inputCid: null, listaCids: null }
    case 'EXIBIR_DIALOG_DETALHAR_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: action.payload, isFormVisible: true, isFormDisabled: true, isEdit: false, validacao: null }
    case 'EXIBIR_DIALOG_ALTERAR_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: action.payload, isFormVisible: true, isFormDisabled: false, isEdit: true, validacao: null }
    case 'EXIBIR_DIALOG_EXCLUIR_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: action.payload, isConfirmarExclusao: true }
    case 'OCULTAR_DIALOG_EXCLUIR_DOCUM_MED_ODONT':
      return { ...state, isConfirmarExclusao: false }
    case 'ALTERAR_TIPO_EMITENTE_DOCUM_MED_ODONT': {
      const tipoIdentEmitente = action.payload;
      return {
        ...state, documentoSelecionado: {
          ...state.documentoSelecionado,
          idTipoIdentEmitente: tipoIdentEmitente,
          idUFDocumento: state.documentoSelecionado.idUFDocumento
        }
      }
    }
    case 'ALTERAR_OUTRO_TIPO_EMITENTE_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, outroTipoIdentEmitente: action.payload } }
    case 'ALTERAR_DATA_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, dataDocumento: action.payload } }
    case 'ALTERAR_UF_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, idUFDocumento: action.payload } }
    case 'ALTERAR_NUMERO_DOCUMENTO_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, numeroDocumento: action.payload } }
    case 'ALTERAR_NOME_PROFISSIONAL_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, nomeProfissional: action.payload } }
    case 'ALTERAR_DESCRICAO_LAUDO_DOCUM_MED_ODONT':
      return { ...state, documentoSelecionado: { ...state.documentoSelecionado, descricaoLaudo: action.payload } }
    case 'ATUALIZAR_DADOS_VALIDACAO_DOCUM_MED_ODONT':
      return { ...state, validacao: action.payload }
    case 'LIMPAR_MSG_ERRO_VALIDACAO_NOME_PROFISSIONAL':
      return { ...state, validacao: {...state.validacao, erroNomeProfissional: ''} }
    case 'CARREGAR_DADOS_DE_MEDICO':
      return {...state, isCargaDadosDoMedico: action.payload}
    case 'ADICIONAR_DADOS_DE_MEDICO_ENCONTRADO':
      return {...state, documentoSelecionado: {...state.documentoSelecionado, dadosDoMedico: action.payload}}
    case 'ADICIONAR_MENSAGEM_ERRO':
      return {...state, documentoSelecionado: {...state.documentoSelecionado, mensagemErro: action.payload}}
    default:
      return state;
  }
};
