import React from 'react';

const Qualificadores = (props) => {
  const { qualificadores } = { ...props };
  if (!qualificadores) {
    return null;
  }

  return (
    <div className="card container-qualificadores">
      <h5 className="titulo">Qualificadores a serem usados</h5>
      <ul className="lista-qualificadores">
        {qualificadores.map((qualificador) => <li key={`qualificador_${qualificador.id}`}>{`${qualificador.id} = ${qualificador.descricao} (${qualificador.valorInicio} a ${qualificador.valorFinal}%)`}</li>)}
      </ul>
    </div>
  );
};


export default Qualificadores;