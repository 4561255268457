import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import { Grid } from '@material-ui/core';
import { adicionarDiasNaData, compararDatasStringISOFormatSemHoras, convertStringToStringIsoWithTimezonePT_BR, converterStringIsoToDateZerandoHoras, nowDateTimeSringIsoSettedToTimezoneGmtMinus3 } from '../../../../../common/DataUtils';
import { calcularCaracteresRestantes } from '../../../../../common/Utils';
import { atualizarDadoValidacaoModulo } from '../../../../principal/action';
import { obterErroValidacaoDataMaximaRetroacaoDCB } from '../validacao';

import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrMessage from '@govbr/react-components/lib/BrMessage';

import { modulosEstaticos, tiposPericia } from '../../../constants';

import {
  alterarDCBGrauIncapacidade,
  alterarEnquadramentoAuxilioAcidente, alterarIndicadorAuxilioAcidente,
  alterarJustAuxAcidGrauIncapacidade, alterarSituacaoEnquadramentoAuxilioAcidente
} from '../../../action';

import ButtonSelecionarItem from '../../../../dicionario/ButtonSelecionarItem';

const AuxilioAcidente = props => {

  const {dados, listaSitEnquadAuxAcid, alterarSituacaoEnquadramentoAuxilioAcidente, template, dataCessacao, alterarDCBGrauIncapacidade} = props;
  const nomeModulo = modulosEstaticos.AUXILIO_ACIDENTE.nome;
  const isPericiaRBI = tiposPericia.INSS_BI_PRBI.tipo === template.tipoPericia;
  
  const handleChangeDCBAA = value => {
    let msgAlertaDCB = undefined;

    if (value && value.toString() !== 'Invalid Date' && template && !isPericiaRBI) {
      msgAlertaDCB = obterErroValidacaoDataMaximaRetroacaoDCB(value, template);
    }
    alterarDCBGrauIncapacidade(value);

    props.atualizarDadoValidacaoModulo(nomeModulo, { alertaDcbParcialIndicAuxAcid: msgAlertaDCB });
  }

  const handleChangeIndicaAA = value => {
    if (parseInt(value) === 1 && !dados.dataCessacaoBeneficio) {
      
      const dateDcbDoBeneficio = convertStringToStringIsoWithTimezonePT_BR(dataCessacao);
      const dre = nowDateTimeSringIsoSettedToTimezoneGmtMinus3();

      if(isPericiaRBI || compararDatasStringISOFormatSemHoras(dateDcbDoBeneficio, dre) === 1) {
        handleChangeDCBAA(converterStringIsoToDateZerandoHoras(dre));
      } else {
        handleChangeDCBAA(converterStringIsoToDateZerandoHoras(dateDcbDoBeneficio));
      }
      //TODO: atualizar validacao campo obrigatorio dcb
    }
    props.alterarIndicadorAuxilioAcidente(value);
  }

  useEffect(() => {
    const { enquadramentoAuxilioAcidente, situacaoEnquadramentoAuxilioAcidente} = dados;
    const listaSituacoesEnquadramentoSelecionado = listaSitEnquadAuxAcid
      .filter((tipo) => +tipo.tipoEnquadramento === enquadramentoAuxilioAcidente);
    
    //selecao automatica se enquadramento de AA quando existe apenas uma opcao
    if ( enquadramentoAuxilioAcidente && !situacaoEnquadramentoAuxilioAcidente && listaSituacoesEnquadramentoSelecionado?.length === 1) {
       alterarSituacaoEnquadramentoAuxilioAcidente(listaSituacoesEnquadramentoSelecionado[0].id);
    }
  }, [dados,listaSitEnquadAuxAcid, alterarSituacaoEnquadramentoAuxilioAcidente]);

  return (
    <>
      <Grid container spacing={2}>
        <ComponenteAuxilioAcidente {...props} handleChangeDCB={handleChangeDCBAA} handleChangeIndicacaoAA={handleChangeIndicaAA}
          isPericiaRBI={isPericiaRBI}/>
      </Grid>
    </>
  );
}

const ComponenteAuxilioAcidente = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justParcialComIndicAuxilioAcidente || "";
  const indAuxAcidente = props.dados.indAuxilioAcidente;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);
  const tiposSimNao = props.tiposSimNao;

  const OPCAO_VAZIA = { label: '', value: undefined };
  const listaEnquadAuxAcid = [OPCAO_VAZIA, ...props.listaEnquadAuxAcid].map(tipo => ({ label: tipo.tipo, value: tipo.id }));
  const listaSitEnquadAuxAcid = [
    OPCAO_VAZIA,
    ...(props.listaSitEnquadAuxAcid.filter(tipo => +tipo.tipoEnquadramento === props.dados.enquadramentoAuxilioAcidente))
  ].map(tipo => ({ label: tipo.situacao, value: tipo.id }));

  const {permiteAlteracaoCritica, isSomenteLeitura, isPericiaRBI} = props;

  const isCampoTextareaDesabilitado = isSomenteLeitura || !permiteAlteracaoCritica;

  return (
    <>
      {
        <>
          <Grid item sm={12}>
            <BrRadio
              id="indAuxAcidente"
              label="* Indicação de Auxílio Acidente - AA?"
              value={indAuxAcidente}
              items={tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
              onChange={props.handleChangeIndicacaoAA}
              erro={props.validacao?.erroIndAuxilioAcidente}
              disabled={isSomenteLeitura || !permiteAlteracaoCritica}
            />
          </Grid>
          {indAuxAcidente === 1 &&
            (<>
              <Grid item sm={6}>
                {props.validacao?.alertaDcbParcialIndicAuxAcid
                  && (
                    <BrMessage warning>
                      {props.validacao?.alertaDcbParcialIndicAuxAcid}
                    </BrMessage>
                  )}
                {!isPericiaRBI &&
                  <BrDatepicker
                    id='dataCessacaoBenefAuxAcid'
                    label="* Data de Cessação do Benefício (DCB)"
                    date={props.dados.dataCessacaoBeneficio || null}
                    onChange={props.handleChangeDCB}
                    maxDate={adicionarDiasNaData(new Date(), 0)}
                    allowInput
                    erro={props.validacao?.erroDcbAuxilioAcidente}
                    disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
                }
              </Grid>
              <Grid item sm={12}>
                <BrSelect
                  id="tpEnquadAuxAcid"
                  label="* Enquadramento de Auxílio Acidente - AA"
                  items={listaEnquadAuxAcid}
                  value={props.dados.enquadramentoAuxilioAcidente && props.listaEnquadAuxAcid?.length > 0 ? listaEnquadAuxAcid.find(tipo => tipo.value === props.dados.enquadramentoAuxilioAcidente) : OPCAO_VAZIA}
                  onChange={props.alterarEnquadramentoAuxilioAcidente}
                  erro={props.validacao?.erroEnquadramentoAuxilioAcidente}
                  disabled={isSomenteLeitura || !permiteAlteracaoCritica} />
              </Grid>
              <Grid item sm={12}>
                <BrSelect
                  id="tpSitEnquadAuxAcid"
                  label="* Situação"
                  items={listaSitEnquadAuxAcid}
                  value={props.dados.situacaoEnquadramentoAuxilioAcidente && props.listaSitEnquadAuxAcid?.length > 0 ? listaSitEnquadAuxAcid.find(tipo => tipo.value === props.dados.situacaoEnquadramentoAuxilioAcidente) : OPCAO_VAZIA}
                  onChange={props.alterarSituacaoEnquadramentoAuxilioAcidente}
                  erro={props.validacao?.erroSituacaoEnquadramentoAuxilioAcidente}
                  disabled={isSomenteLeitura || !props.dados.enquadramentoAuxilioAcidente || !permiteAlteracaoCritica} />
              </Grid>
              <Grid item sm={12}>
                <Grid container alignItems="center">
                    <Grid item xs>
                      <BrTextarea
                        id="justifParcialIndicAuxAcid"
                        label="* Justifique"
                        value={justificativa}
                        onChange={props.alterarJustAuxAcidGrauIncapacidade}
                        maxLength={QT_MAX_CARACT}
                        help={`Caracteres restantes: ${caracteresRestantes}`}
                        disabled={isCampoTextareaDesabilitado}
                        erro={props.validacao?.erroJustIndicAuxilioAcidente} />
                    </Grid>
                    {
                      !isCampoTextareaDesabilitado &&
                      <Grid item >
                        <ButtonSelecionarItem
                          id="selecionarItemDicionarioJustAuxAcidente"
                          onSelect={props.alterarJustAuxAcidGrauIncapacidade}
                          maxLength={caracteresRestantes}
                          valorAtual={justificativa}/>
                      </Grid>
                    }
                </Grid>
              </Grid>

            </>)
          }
        </>
      }
    </>
  );
}


const mapStateToProps = (state) => {
  const { dominioPericia, dominioPericiaBI, validacao, isSomenteLeitura, template } = state.principal;
  const {moduloGrauIncapacidade, permiteAlteracaoCritica, periciaAnterior} = state.pericia;
  return ({
    isSomenteLeitura: isSomenteLeitura,
    tiposSimNao: dominioPericia.tiposSimNao,
    dados: moduloGrauIncapacidade,
    permiteAlteracaoCritica: permiteAlteracaoCritica,
    dataCessacao: periciaAnterior?.dataCessacaoBeneficio,
    validacao: validacao.indAuxilioAcidente,
    listaEnquadAuxAcid: dominioPericiaBI?.tiposEnquadramentoAuxAcidente || [],
    listaSitEnquadAuxAcid: dominioPericiaBI?.tiposSituacaoEnquadramentoAuxAcidente || [],
    template: template
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustAuxAcidGrauIncapacidade,
  alterarEnquadramentoAuxilioAcidente,
  alterarSituacaoEnquadramentoAuxilioAcidente,
  alterarIndicadorAuxilioAcidente,
  alterarDCBGrauIncapacidade,
  atualizarDadoValidacaoModulo
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(AuxilioAcidente);
