import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {

  const moduloCid = pericia?.moduloCid;
  const cidPericiaAnterior = pericia?.periciaAnterior?.codigoCID;

  const deveValidarCIDs = !cidPericiaAnterior && moduloCid
    && (moduloCid.cidPrincipal
      || moduloCid.naoExisteCidSecundario != null
      || (moduloCid.cidsSecundarios && moduloCid.cidsSecundarios.length > 0));

  return deveValidarCIDs;

}

export const validar = (pericia, getState) => {
  const cidPericiaAnterior = pericia?.periciaAnterior?.codigoCID;
  if (cidPericiaAnterior) {
    //cid anterior preenchido, nao é necessario validar
    return {
      isValida: true,
      erroCidPrincipal: null,
      erroNaoExisteCidSecundario: null
    };
  }

  //cid anterior vazio, é necessario preencher e validar dados cid

  const cidPrincipal = pericia?.moduloCid?.cidPrincipal;
  const cidPrincipalDigitado = getState().modulos.moduloCids.inputCidPrincipal;
  const cidsSecundarios = pericia?.moduloCid?.cidsSecundarios;
  const naoExisteCidSecundario = pericia?.moduloCid?.naoExisteCidSecundario;

  const listaCidsSecundarios = cidsSecundarios && cidsSecundarios.length > 0
    ? cidsSecundarios.map(c => c.cidSecundario)
    : null;

  let erroCidPrincipal = cidPrincipal == null ? MSG_CAMPO_OBRIGATORIO : null;
  if (!erroCidPrincipal) {

    if (cidPrincipalDigitado && cidPrincipal !== cidPrincipalDigitado) {
      erroCidPrincipal = "Um valor de CID Principal válido deve ser selecionado."
    } else {
      erroCidPrincipal = cidPrincipal && listaCidsSecundarios && listaCidsSecundarios.includes(cidPrincipal)
        ? "O CID secundário não pode ser igual ao principal"
        : null;
    }
  }

  const erroRespostaObrigatoria = (!naoExisteCidSecundario && (!cidsSecundarios || cidsSecundarios.length === 0))
    ? "É obrigatório marcar opção se não existem CIDs secundários"
    : null;

  const erroOpcaoIncompativel = (naoExisteCidSecundario === 1 && cidsSecundarios && cidsSecundarios.length > 0)
    ? `Opção incompatível com ${cidsSecundarios.length} CID(s) secundário(s). Desmarque a opção ou exclua o(s) CID(s) secundário(s).`
    : null;

  return {
    isValida: !erroCidPrincipal && !erroRespostaObrigatoria && !erroOpcaoIncompativel,
    erroCidPrincipal: erroCidPrincipal,
    erroNaoExisteCidSecundario: erroRespostaObrigatoria ? erroRespostaObrigatoria : erroOpcaoIncompativel
  };
}