import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrTable from '@govbr/react-components/lib/BrTable';
import BrTooltip from '@govbr/react-components/lib/BrTooltip';

import { Box, Grid } from "@material-ui/core";

import { convertStringToDateWithTimezonePT_BR, formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import {
  obterBeneficiosParaAgravamentoLesao, obterUltimoBeneficioParaAgravamento,
  obterBeneficiosParaRestabelecimento, obterUltimoBeneficioParaRestabelecimento
} from './utils';
import {
  carregarBatimentoCidBeneficioAgravamento, carregarBatimentoCidBeneficioRestabelecimento,
  indicarBatimentosFinalizadosAgravamento, indicarBatimentosFinalizadosRestabelecimento,
  indicarNaoPossuiDIIRestabelecimentoOuAgravamento
} from './action';

import { errorMessage } from '../../../principal/action';
import { alterarExisteAgravamentoLesao, alterarExisteRestabelecimento, alterarValorCampoGenerico, alterarValorCampoGenericoData } from '../../action';
import { SIGLA_CAMPO_DID, SIGLA_CAMPO_DII, SIGLA_CAMPO_JDID, SIGLA_CAMPO_JDII, tiposParametrosModuloEstatico } from '../../constants';
import { obterCampoGenericoPorSigla, obterValorParametroModuloEstatico } from '../../templatePericiaUtil';

const CAMPOS_SEM_DADOS_ENCONTRADOS = '--';
const MSG_DATA_ALTERADA_AUTOMATICAMENTE_AD = "Data preenchida automaticamente pelo sistema com dados do benefício selecionado para restabelecimento";
const MSG_DATA_ALTERADA_AUTOMATICAMENTE_AA = "Data preenchida automaticamente pelo sistema com dados do benefício selecionado para agravamento de lesão";

const INFO_AGRAVAMENTO = "É considerado agravamento da lesão quando configurada incapacidade temporária atual em "
  + "função da mesma condição clínica geradora do auxílio-acidente ativo. No caso de habilitada a resposta SIM, "
  + "o auxílio-acidente será suspenso pelo período de manutenção do auxílio por incapacidade temporária. "
  + "Neste caso, o sistema assumirá automaticamente a mesma DID do Auxílio-Acidente anteriormente concedido";

const INFO_RESTABELECIMENTO = "A resposta afirmativa (SIM) caracterizará que a incapacidade temporária atual"
  + " decorre do mesmo motivo gerador do benefício anteriormente cessado. Neste caso, o sistema assumirá automaticamente "
  + "a mesma DID e DII do benefício anterior restabelecendo-o. Em caso de dias trabalhados no período de 60 dias anteriores a DER, "
  + "ocorrerá ajuste financeiro pelo INSS."

const RestabelecimentoBeneficio = props => {
  const { pericia, batimentos, tiposSimNao, validacao, isSomenteLeitura, template, errorMessage, abas, idAbaSelecionada,
    alterarValorCampoGenericoData, alterarValorCampoGenerico,
    carregarBatimentoCidBeneficioAgravamento, carregarBatimentoCidBeneficioRestabelecimento,
    indicarBatimentosFinalizadosAgravamento, indicarBatimentosFinalizadosRestabelecimento } = props;

  const { periciasAnteriores, beneficiosAnteriores, dataReferencia, moduloRestabelecimentoBeneficio, moduloCid, numeroBeneficio } = pericia;
  const { existeAgravamento, existeRestabelecimento } = moduloRestabelecimentoBeneficio;

  const nomeModulo = abas?.find(a => a.id === idAbaSelecionada)?.path; //DID, DII e modulo restabelecimento SEMPRE estao na mesma aba

  const [listaAgravamento, setListaAgravamento] = useState([]);
  const [listaRestabelecimento, setListaRestabelecimento] = useState([]);
  const [deveAvaliarAgravamento, setDeveAvaliarAgravamento] = useState(false);
  const [deveAvaliarRestabelecimento, setDeveAvaliarRestabelecimento] = useState(false);
  const [existeCid, setExisteCid] = useState(false);
  const [cid, setCid] = useState(undefined);

  const agravamentoRef = useRef();
  const restabelecimentoRef = useRef();

  const [openTooltipAgravamento, setOpenTooltipAgravamento] = useState(false);
  const [openTooltipRestabelecimento, setOpenTooltipRestabelecimento] = useState(false);

  const periciasAnterioresComMesmoBeneficioRestabelecimento = Array.isArray(periciasAnteriores) ? periciasAnteriores.filter(p => p.numeroBeneficio === listaRestabelecimento[0]?.numeroBeneficio) : [];
  const periciasAnterioresComMesmoBeneficioAgravamento = Array.isArray(periciasAnteriores) ? periciasAnteriores.filter(p => p.numeroBeneficio === listaAgravamento[0]?.numeroBeneficio) : [];


  useEffect(() => {
    const cid = moduloCid?.cidPrincipal;
    const existeCid = cid !== undefined && cid !== null;

    setExisteCid(existeCid);
    setCid(cid);

    let listaAD = undefined;
    let listaAA = obterBeneficiosParaAgravamentoLesao(beneficiosAnteriores);

    const valorParametroPeriodoRestabelecimento = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_RESTABELECIMENTO.id);

    if (!valorParametroPeriodoRestabelecimento) {
      const msg = `É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_RESTABELECIMENTO.nome}' no template da perícia.`;
      errorMessage({ message: msg }, "Restabelecimento");
    } else {
      listaAD = obterBeneficiosParaRestabelecimento(beneficiosAnteriores, dataReferencia, valorParametroPeriodoRestabelecimento, numeroBeneficio);
    }
    const existeBatimentoCidAgravamento = batimentos?.batimentoCidAgravamento;
    const existeBatimentoCidRestabelecimento = batimentos?.batimentoCidRestabelecimento;

    if (existeCid && listaAA && existeBatimentoCidAgravamento === undefined && !batimentos?.batimentoCidAgravamentoFinalizado) {
      const cids = listaAA.map(b => b.codigoCID10).filter((val, id, array) => array.indexOf(val) === id);
      //carrega batimento cid para agravamento
      cids.forEach(cidNbAnterior => carregarBatimentoCidBeneficioAgravamento(cid, cidNbAnterior));
      indicarBatimentosFinalizadosAgravamento();
    }

    if (existeCid && listaAD && existeBatimentoCidRestabelecimento === undefined && !batimentos?.batimentoCidRestabelecimentoFinalizado) {
      const cids = listaAD.map(b => b.codigoCID10).filter((val, id, array) => array.indexOf(val) === id);
      //carrega batimento cid para restabelecimento
      cids.forEach(cidNbAnterior => carregarBatimentoCidBeneficioRestabelecimento(cid, cidNbAnterior));
      indicarBatimentosFinalizadosRestabelecimento();
    }

    if (!existeBatimentoCidAgravamento) {
      //cid principal e cid AA NAO sao do mesmo grupo, nao pode indicar agravamento
      listaAA = [];
    } else {
      //remove da lista beneficios sem batimento
      listaAA = listaAA.filter(b => batimentos.batimentoCidAgravamento[b.codigoCID10] === true);
    }

    if (!existeBatimentoCidRestabelecimento) {
      //cid principal e cid AD NAO sao do mesmo grupo, nao pode indicar restabelecimento
      listaAD = [];
    } else {
      //remove da lista beneficios sem batimento
      listaAD = listaAD.filter(b => batimentos.batimentoCidRestabelecimento[b.codigoCID10] === true);
    }

    setListaAgravamento(obterUltimoBeneficioParaAgravamento(listaAA));
    setListaRestabelecimento(obterUltimoBeneficioParaRestabelecimento(listaAD));

    setDeveAvaliarAgravamento(listaAA && listaAA.length > 0);
    setDeveAvaliarRestabelecimento(listaAD && listaAD.length > 0);

  }, [template, beneficiosAnteriores, dataReferencia, moduloCid, batimentos,
    errorMessage, carregarBatimentoCidBeneficioAgravamento, carregarBatimentoCidBeneficioRestabelecimento,
    indicarBatimentosFinalizadosAgravamento, indicarBatimentosFinalizadosRestabelecimento, numeroBeneficio]);

  const obterDataInicioDoencaBeneficioRestabelecimento = (beneficio) => {
    const dataInicioDoencaCorreta = beneficio.dataInicioDoenca;
    if (!dataInicioDoencaCorreta && periciasAnterioresComMesmoBeneficioRestabelecimento.length > 0) {
      return convertStringToDateWithTimezonePT_BR(periciasAnterioresComMesmoBeneficioRestabelecimento[0].dataInicioDoenca)
    }
    return dataInicioDoencaCorreta;
  }

  const obterDataInicioIncapacidadeBeneficioRestabelecimento = (beneficio) => {
    const dataInicioIncapacidadeCorreta = beneficio.dataInicioIncapacidade;
    if (!dataInicioIncapacidadeCorreta && periciasAnterioresComMesmoBeneficioRestabelecimento.length > 0) {
      return convertStringToDateWithTimezonePT_BR(periciasAnterioresComMesmoBeneficioRestabelecimento[0].dataInicioIncapacidade)
    }
    return dataInicioIncapacidadeCorreta;
  }

  const obterDataInicioDoencaBeneficioAgravamento = (beneficio) => {
    const dataInicioDoencaCorreta = beneficio.dataInicioDoenca;
    if (!dataInicioDoencaCorreta && periciasAnterioresComMesmoBeneficioAgravamento.length > 0) {
      return convertStringToDateWithTimezonePT_BR(periciasAnterioresComMesmoBeneficioAgravamento[0].dataInicioDoenca)
    }
    return dataInicioDoencaCorreta;
  }

  const tratarSelecaoRestabelecimento = value => {
    const beneficioRestabelecimento = listaRestabelecimento[0];
    const valor = parseInt(value);
    const idBeneficioRestabelecimento = valor === 1 ? beneficioRestabelecimento.id : undefined;

    //deve atualizar valor antes de realizar demais acoes, para exibir corretamente msg alerta DII
    props.alterarExisteRestabelecimento(valor, idBeneficioRestabelecimento);

    const dataInicioDoencaBeneficio = obterDataInicioDoencaBeneficioRestabelecimento(beneficioRestabelecimento);

    if (dataInicioDoencaBeneficio) {
      //so deve alterar DID automaticamente se campo preenchido no beneficio anterior
      const campoDID = obterCampoGenericoPorSigla(SIGLA_CAMPO_DID, template);
      const campoJDID = obterCampoGenericoPorSigla(SIGLA_CAMPO_JDID, template);
      const didBeneficioAnterior = dataInicioDoencaBeneficio;

      if (campoDID && campoJDID) {
        const idCampoDID = campoDID.campoGenerico.id;
        const idCampoJDID = campoJDID.campoGenerico.id;

        if (valor === 1) {
          //SIM para restabelecimento, DID = DID beneficio anterior e justificativa automatica
          props.indicarNaoPossuiDIIRestabelecimentoOuAgravamento(dataInicioDoencaBeneficio)
          alterarValorCampoGenericoData(idCampoDID, SIGLA_CAMPO_DID, didBeneficioAnterior, campoDID.campoGenerico, nomeModulo, true);
          alterarValorCampoGenerico(idCampoJDID, SIGLA_CAMPO_JDID, MSG_DATA_ALTERADA_AUTOMATICAMENTE_AD);
        } else {
          //NAO para restabelecimento
          if (!existeAgravamento) {
            //se nao tem agravamento, apaga DID e justificativa  para q seja preenchida
            alterarValorCampoGenericoData(idCampoDID, SIGLA_CAMPO_DID, null, campoDID.campoGenerico, nomeModulo, true);
            alterarValorCampoGenerico(idCampoJDID, SIGLA_CAMPO_JDID, null);
          } else {
            //existe agravamento
            const beneficioAgravamento = listaAgravamento[0];
            const didBeneficioAA_Anterior = obterDataInicioDoencaBeneficioAgravamento(beneficioAgravamento);

            if (didBeneficioAA_Anterior) {
              //existe DID para beneficio AA selecionado, deve usar DID do beneficio e justificativa automatica
              props.indicarNaoPossuiDIIRestabelecimentoOuAgravamento(dataInicioDoencaBeneficio)
              alterarValorCampoGenericoData(idCampoDID, SIGLA_CAMPO_DID, didBeneficioAA_Anterior, campoDID.campoGenerico, nomeModulo, true);
              alterarValorCampoGenerico(idCampoJDID, SIGLA_CAMPO_JDID, MSG_DATA_ALTERADA_AUTOMATICAMENTE_AA);
            }
          }
        }
      }
    }

    const dataInicioIncapacidadeBeneficio = obterDataInicioIncapacidadeBeneficioRestabelecimento(beneficioRestabelecimento);

    if (dataInicioIncapacidadeBeneficio) {
      //so deve alterar DII automaticamente se campo preenchido no beneficio anterior
      const campoDII = obterCampoGenericoPorSigla(SIGLA_CAMPO_DII, template);
      const campoJDII = obterCampoGenericoPorSigla(SIGLA_CAMPO_JDII, template);
      const diiBeneficioAnterior = dataInicioIncapacidadeBeneficio;

      if (campoDII && campoJDII) {
        const idCampoDII = campoDII.campoGenerico.id;
        const idCampoJDII = campoJDII.campoGenerico.id;

        if (valor === 1) {
          //SIM para restabelecimento, DII = DII  beneficio anterior e justificativa automatica
          alterarValorCampoGenericoData(idCampoDII, SIGLA_CAMPO_DII, diiBeneficioAnterior, campoDII.campoGenerico, nomeModulo, true);
          alterarValorCampoGenerico(idCampoJDII, SIGLA_CAMPO_JDII, MSG_DATA_ALTERADA_AUTOMATICAMENTE_AD);
        } else {
          //NAO para restabelecimento, apaga DII e justificativa  para q seja preenchida
          alterarValorCampoGenericoData(idCampoDII, SIGLA_CAMPO_DII, null, campoDII.campoGenerico, nomeModulo, true);
          alterarValorCampoGenerico(idCampoJDII, SIGLA_CAMPO_JDII, null);
        }
      }
    }
  }

  const tratarSelecaoAgravamento = value => {
    const beneficioAgravamento = listaAgravamento[0];
    const valor = parseInt(value);

    const dataInicioDoencaBeneficio = obterDataInicioDoencaBeneficioAgravamento(beneficioAgravamento);

    if (dataInicioDoencaBeneficio && !existeRestabelecimento) {
      //so deve alterar DID automaticamente se campo preenchido no beneficio anterior e nao existe restabelecimento
      const campoDID = obterCampoGenericoPorSigla(SIGLA_CAMPO_DID, template);
      const campoJDID = obterCampoGenericoPorSigla(SIGLA_CAMPO_JDID, template);
      const didBeneficioAnterior = dataInicioDoencaBeneficio;

      if (campoDID && campoJDID) {
        const idCampoDID = campoDID.campoGenerico.id;
        const idCampoJDID = campoJDID.campoGenerico.id;

        if (valor === 1) {
          //SIM para agravamento, DID = DID beneficio anterior e justificativa automatica
          props.indicarNaoPossuiDIIRestabelecimentoOuAgravamento(dataInicioDoencaBeneficio)
          alterarValorCampoGenericoData(idCampoDID, SIGLA_CAMPO_DID, didBeneficioAnterior, campoDID.campoGenerico, nomeModulo, true);
          alterarValorCampoGenerico(idCampoJDID, SIGLA_CAMPO_JDID, MSG_DATA_ALTERADA_AUTOMATICAMENTE_AA);
        } else {
          //NAO para agravamento, apaga DID e justificativa  para q seja preenchida
          alterarValorCampoGenericoData(idCampoDID, SIGLA_CAMPO_DID, null, campoDID.campoGenerico, nomeModulo, true);
          alterarValorCampoGenerico(idCampoJDID, SIGLA_CAMPO_JDID, null);
        }
      }
    }
    const idBeneficioAgravamento = valor === 1 ? beneficioAgravamento.id : undefined;
    props.alterarExisteAgravamentoLesao(valor, idBeneficioAgravamento);
  }

  return (
    <div id="moduloRestabelecimentoBeneficio">
      {
        !existeCid &&
        (
          <BrMessage warning>
            É necessário preencher o CID Principal em 'Diagnóstico' para avaliar possível indicação de agravamento/restabelecimento.
          </BrMessage>
        )
      }
      {
        existeCid && batimentos.erroConsultaBatimentoAgravamento &&
        (
          <BrMessage error>
            {`Não foi possível obter grupo de CID '${cid}' principal informado e CID '${batimentos.cidAAComErro}' de benefício anterior candidato a agravamento de lesão.`}
          </BrMessage>
        )
      }
      {
        existeCid && batimentos.erroConsultaBatimentoRestabelecimento &&
        (
          <BrMessage error>
            {`Não foi possível obter grupo de CID '${cid}' principal informado e CID '${batimentos.cidADComErro}' de benefício anterior candidato a restabelecimento.`}
          </BrMessage>
        )
      }
      {
        existeCid && !deveAvaliarRestabelecimento && !deveAvaliarAgravamento && (
          <BrMessage warning>
            {`Não existem benefícios elegíveis para Agravamento / Restabelecimento.`}
          </BrMessage>
        )
      }
      {
        deveAvaliarAgravamento &&
        <>
          <h6>Benefício encontrado para agravamento de lesão:</h6>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <BrTable
              id="tabelaBeneficiosAgravamento"
              layout="fixed"
              columns={[{ Header: "NB", accessor: "nb" }, { Header: "Espécie", accessor: "especie" },
              { Header: "DID", accessor: "did" }, { Header: "DII", accessor: "dii" },
              { Header: "CID", accessor: "cid" }, { Header: "DIB", accessor: "dib" },
              { Header: "Data Cessação", accessor: "dcb" }, { Header: "Situação", accessor: "situacao" }]}
              data={listaAgravamento.map(item => ({
                nb: item.numeroBeneficio, especie: item.numeroEspecieBeneficio, situacao: item.situacao,
                did: item.dataInicioDoenca ? formatarDateTimeISOToDataBr(item.dataInicioDoenca) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                dii: item.dataInicioIncapacidade ? formatarDateTimeISOToDataBr(item.dataInicioIncapacidade) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                cid: item.codigoCID10,
                dcb: item.dataCessacaoBeneficio ? formatarDateTimeISOToDataBr(item.dataCessacaoBeneficio) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                dib: formatarDateTimeISOToDataBr(item.dataInicioBeneficio)
              }))}
              headerProps={{
                show: false,
                showDensityOptions: false,
                searchProp: {
                  show: false
                }
              }}
              footerProps={{ show: false }}
            />
          </div>
          <Grid container direction="row">
            <Grid item>
              <BrRadio id="radioExisteAgravamento" orientation='horizontal'
                label="* Trata-se de agravamento de lesão?"
                value={existeAgravamento}
                items={tiposSimNao && tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={tratarSelecaoAgravamento}
                erro={validacao && validacao.erroRespostaAgravamentoObrigatoria}
                disabled={isSomenteLeitura} />
            </Grid>
            <Grid item>
              <Box ml={1} zIndex={1} position="absolute">
                <BrTooltip
                  id="infoAgravamento"
                  anchorEl={agravamentoRef.current}
                  open={openTooltipAgravamento}
                  onClose={() => setOpenTooltipAgravamento(false)}
                  top
                  info
                >
                  {INFO_AGRAVAMENTO}
                </BrTooltip>
                <i className="fas fa-info-circle" aria-hidden="true"
                  ref={agravamentoRef}
                  onClick={() => setOpenTooltipAgravamento(true)}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      }
      {
        deveAvaliarRestabelecimento &&
        <>
          <h6>Benefício encontrado para restabelecimento:</h6>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <BrTable
              id="tabelaBeneficiosRestabelecimento"
              layout="fixed"
              columns={[{ Header: "NB", accessor: "nb" }, { Header: "Espécie", accessor: "especie" },
              { Header: "DID", accessor: "did" }, { Header: "DII", accessor: "dii" },
              { Header: "CID", accessor: "cid" }, { Header: "DIB", accessor: "dib" },
              { Header: "Data Cessação", accessor: "dcb" }, { Header: "Situação", accessor: "situacao" }]}
              data={listaRestabelecimento.map(item => ({
                nb: item.numeroBeneficio, especie: item.numeroEspecieBeneficio, situacao: item.situacao,
                did: item.dataInicioDoenca ? formatarDateTimeISOToDataBr(item.dataInicioDoenca) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                dii: item.dataInicioIncapacidade ? formatarDateTimeISOToDataBr(item.dataInicioIncapacidade) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                cid: item.codigoCID10,
                dcb: item.dataCessacaoBeneficio ? formatarDateTimeISOToDataBr(item.dataCessacaoBeneficio) : CAMPOS_SEM_DADOS_ENCONTRADOS,
                dib: formatarDateTimeISOToDataBr(item.dataInicioBeneficio)
              }))}
              headerProps={{
                show: false,
                showDensityOptions: false,
                searchProp: {
                  show: false
                }
              }}
              footerProps={{ show: false }}
            />
          </div>
          <Grid container direction="row">
            <Grid item>
              <BrRadio id="radioExisteRestabelecimento"
                orientation='horizontal'
                label="* Trata-se de restabelecimento de benefício?"
                value={existeRestabelecimento}
                items={tiposSimNao && tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={tratarSelecaoRestabelecimento}
                erro={validacao && validacao.erroRespostaRestabelecimentoObrigatoria}
                disabled={isSomenteLeitura} />
            </Grid>
            <Grid item>
              <Box ml={1} zIndex={1} position="absolute">
                <BrTooltip
                  id="infoRestabelecimento"
                  anchorEl={restabelecimentoRef.current}
                  open={openTooltipRestabelecimento}
                  onClose={() => setOpenTooltipRestabelecimento(false)}
                  top
                  info
                >
                  {INFO_RESTABELECIMENTO}
                </BrTooltip>
                <i className="fas fa-info-circle" aria-hidden="true"
                  ref={restabelecimentoRef}
                  onClick={() => setOpenTooltipRestabelecimento(true)}
                />
              </Box>
            </Grid>
          </Grid>

        </>
      }
    </div >
  );
}

const mapStateToProps = (state) => {
  const { dominioPericia, validacao, isSomenteLeitura, template, idAbaSelecionada, abas } = state.principal;
  return ({
    pericia: state.pericia,
    batimentos: state.modulos.moduloRestabelecimentoBeneficio,
    tiposSimNao: dominioPericia?.tiposSimNao,
    validacao: validacao.restabelecimento,
    isSomenteLeitura: isSomenteLeitura,
    template: template,
    abas: abas,
    idAbaSelecionada: idAbaSelecionada
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarExisteRestabelecimento,
  alterarExisteAgravamentoLesao,
  alterarValorCampoGenericoData,
  alterarValorCampoGenerico,
  errorMessage,
  carregarBatimentoCidBeneficioAgravamento,
  carregarBatimentoCidBeneficioRestabelecimento,
  indicarBatimentosFinalizadosAgravamento,
  indicarBatimentosFinalizadosRestabelecimento,
  indicarNaoPossuiDIIRestabelecimentoOuAgravamento
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(RestabelecimentoBeneficio);