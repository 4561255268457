import { combineReducers } from 'redux';

import comunicacoesExternas from './features/comunicacao/reducer';
import painelGerencial from './features/painelGerencial/reducer';
import moduloCids from './features/pericia/modulos/CIDs/reducer';
import moduloDocumMedOdont from './features/pericia/modulos/DocumMedOdont/reducer';
import moduloExameLaudo from './features/pericia/modulos/ExamesELaudos/reducer';
import moduloNexoTecnico from './features/pericia/modulos/NexoTecnico/reducer';
import moduloProntuario from './features/pericia/modulos/Prontuario/reducer';
import moduloRestabelecimentoBeneficio from './features/pericia/modulos/RestabelecimentoBeneficio/reducer';
import moduloSima from './features/pericia/modulos/SIMA/reducer';
import pericia from './features/pericia/reducer';
import principal from './features/principal/reducer';
import dicionario from './features/dicionario/reducer';
import consultasAPI from './features/consultasAPI/reducer';

import { LOGOUT_USER } from './common/constants';

const INITIAL_STATE = undefined;

const appReducers = combineReducers({
  principal,
  pericia,
  modulos: combineReducers({
    moduloExameLaudo,
    moduloDocumMedOdont,
    moduloCids,
    moduloSima,
    moduloNexoTecnico,
    moduloRestabelecimentoBeneficio,
    moduloProntuario
  }),
  comunicacoesExternas,
  painelGerencial,
  consultasAPI,
  dicionario
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return appReducers(state, action);
  }
};

export default rootReducer;