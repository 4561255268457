import { SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO, 
  SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO, situacaoPericia, tiposPericia } from "../../constants";

export const verificaDeveAtualizarDataDCB = (template, pericia) => {
  const isPericiaRBI = tiposPericia.INSS_BI_PRBI.tipo === template.tipoPericia;
  const situacaoPericiaAtual = pericia?.situacao;
  const isPericiaPermiteEdicao = situacaoPericiaAtual
    && (situacaoPericia.CRIADA.id === situacaoPericiaAtual.id || situacaoPericia.EM_ABERTO.id === situacaoPericiaAtual.id);
    
  const respostaPerguntaIncapacidade = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO)?.valorTextoCampoGenerico;
  const respostaPerguntaInvalidez = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO)?.valorTextoCampoGenerico;
  
  return isPericiaRBI && isPericiaPermiteEdicao && (respostaPerguntaIncapacidade === '0' || respostaPerguntaInvalidez === '0');
}