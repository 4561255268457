import { IDS_TIPOS_EMITENTE_COM_UF, ID_TIPO_OUTRO_IDENT_EMITENTE } from './validacao';

const INITIAL_STATE = {
  exameLaudoSelecionado: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EXIBIR_DIALOG_INCLUIR':
      return { ...state, exameLaudoSelecionado: {}, isFormVisible: true, isFormDisabled: false, isEdit: false, validacao: null }
    case 'OCULTAR_DIALOG':
      return { ...state, isFormVisible: false, inputCid: null, listaCids: null }
    case 'EXIBIR_DIALOG_DETALHAR':
      return { ...state, exameLaudoSelecionado: action.payload, isFormVisible: true, isFormDisabled: true, isEdit: false, validacao: null }
    case 'EXIBIR_DIALOG_ALTERAR':
      return { ...state, exameLaudoSelecionado: action.payload, isFormVisible: true, isFormDisabled: false, isEdit: true, validacao: null }
    case 'EXIBIR_DIALOG_EXCLUIR':
      return { ...state, exameLaudoSelecionado: action.payload, isConfirmarExclusao: true }
    case 'OCULTAR_DIALOG_EXCLUIR':
      return { ...state, isConfirmarExclusao: false }
    case 'ALTERAR_TIPO_EMITENTE': {
      const tipoIdentEmitente = action.payload;
      const deveLimparUf = !IDS_TIPOS_EMITENTE_COM_UF.find(i => i === tipoIdentEmitente);
      const deveLimparDescricaoOutro = ID_TIPO_OUTRO_IDENT_EMITENTE !== tipoIdentEmitente;

      return {
        ...state, exameLaudoSelecionado: {
          ...state.exameLaudoSelecionado,
          idTipoIdentEmitente: tipoIdentEmitente,
          idUFDocumento: deveLimparUf ? undefined : state.exameLaudoSelecionado.idUFDocumento,
          outroTipoIdentEmitente: deveLimparDescricaoOutro ? undefined : state.exameLaudoSelecionado.outroTipoIdentEmitente
        }
      }
    }
    case 'ALTERAR_OUTRO_TIPO_EMITENTE':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, outroTipoIdentEmitente: action.payload } }
    case 'ALTERAR_DATA_DOCUMENTO':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, dataDocumento: action.payload } }
    case 'ALTERAR_UF':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, idUFDocumento: action.payload } }
    case 'ALTERAR_NUMERO_DOCUMENTO':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, numeroDocumento: action.payload } }
    case 'ALTERAR_NOME_PROFISSIONAL':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, nomeProfissional: action.payload } }
    case 'ALTERAR_DESCRICAO_LAUDO':
      return { ...state, exameLaudoSelecionado: { ...state.exameLaudoSelecionado, descricaoLaudo: action.payload } }
    case 'ALTERAR_TIPO_EXAME_LAUDO': {
      const deveLimparDadosIncompativeis = action.payload.isTipoExame;
      return {
        ...state,
        exameLaudoSelecionado: {
          ...state.exameLaudoSelecionado,
          idTipoExameLaudo: action.payload.value,
          idUFDocumento: deveLimparDadosIncompativeis ? undefined : state.exameLaudoSelecionado.idUFDocumento,
          numeroDocumento: deveLimparDadosIncompativeis ? '' : state.exameLaudoSelecionado.numeroDocumento,
          nomeProfissional: deveLimparDadosIncompativeis ? undefined : state.exameLaudoSelecionado.nomeProfissional,
          idTipoIdentEmitente: deveLimparDadosIncompativeis ? undefined : state.exameLaudoSelecionado.idTipoIdentEmitente,
          outroTipoIdentEmitente: deveLimparDadosIncompativeis ? undefined : state.exameLaudoSelecionado.outroTipoIdentEmitente
        }
      }
    }
    case 'ATUALIZAR_DADOS_VALIDACAO_EXAME_LAUDO':
      return { ...state, validacao: action.payload }
    default:
      return state;
  }
};
