import { obterMapRespostasGrupos } from "../../../../components/ModuloFormulario/utils";

export const obterNomeModulo = moduloTemplateGenerico => `formulario_generico_${moduloTemplateGenerico?.id}`;

export const verificarSeCamposPreenchidos = (moduloTemplateGenerico, pericia) => {
  const respostasGrupoForm = pericia?.respostasGrupoForm;
  const form = moduloTemplateGenerico.modulo.formulario;
  const gruposComResposta = form && form.gruposFormulario.filter(g =>
    respostasGrupoForm.find((res) => res.idGrupoFormulario === g.id));

  return gruposComResposta && gruposComResposta.length > 0;
}

export const validar = (moduloTemplateGenerico, pericia) => {
  const respostasGrupos = pericia.respostasGrupoForm;
  const mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupos);
  const formulario = moduloTemplateGenerico.modulo.formulario;

  const gruposSemResposta = formulario.gruposFormulario.filter((grupoFormulario) => !mapRespostasGrupos.get(grupoFormulario.id)
    || mapRespostasGrupos.get(grupoFormulario.id).qualificador === undefined);

  return {
    isValida: gruposSemResposta.length === 0,
    gruposComErroDominio: [
      ...(gruposSemResposta &&
        gruposSemResposta.map(g => (
          {
            id: g.id,
            ordem: g.ordemExibicao,
            erro: `Informe o qualificador para o domínio <strong>${g.nome}</strong>`
          })))
    ]
  };
}