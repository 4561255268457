import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TableFooter } from '@material-ui/core';
import ModuloFormularioResposta from './ModuloFormularioResposta';
import ModuloFormularioRespostaDependencia from './ModuloFormularioRespostaDependencia';
import ModuloFormularioRespostaGrupo from './ModuloFormularioRespostaGrupo';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import {
  alterarJustificativaGrupoFormulario,
  alterarRespostaPerguntaFormulario,
  alterarRespostaDependenciaPerguntaFormulario,
  alterarRespostaGrupoFormulario
} from '../../features/pericia/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decodeHtml } from '../../common/StringUtils';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrCard from '@govbr/react-components/lib/BrCard';

import './css/styles.css';

export const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

export const CelulaValorDominio = withStyles((theme) => ({
  head: {
    backgroundColor: '#E3E8E3',
    color: '#266B44',
    padding: 0
  }
}))(TableCell);

const CelulaValorDominioDependencia = withStyles((theme) => ({
  head: {
    backgroundColor: '#7A887B',
    color: '#FFF',
    padding: 0
  }
}))(TableCell);

export const CelulaCabecalhoDominio = withStyles((theme) => ({
  root: {
    backgroundColor: '#E3E8E3',
    color: '#266B44',
    width: '65%',
    fontWeight: 'bold',
    fontSize: '1em'
  }
}))(TableCell);

const CelulaPergunta = withStyles((theme) => ({
  root: {
    fontSize: '0.8em'
  }
}))(TableCell);

export const CelulaResposta = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(TableCell);

const RESPOSTAS = ["0", "1", "2", "3", "4"];

const RESPOSTAS_DEPENDENCIA = ["T", "P"];

const isRespostaGrupoDisabled = (perguntasGrupo, respostasPerguntas, qtdeRespostasRequeridas) => {
  let qtdPerguntasRespondidas = perguntasGrupo.filter((pergunta) => pergunta.habilitado && respostasPerguntas.get(pergunta.id) !== undefined).length;
  if (qtdeRespostasRequeridas === undefined) { // Todas as perguntas do grupo são requeridas
    let qtdPerguntasHabilitadas = perguntasGrupo.filter((pergunta) => pergunta.habilitado).length;
    return qtdPerguntasHabilitadas === 0 || qtdPerguntasRespondidas < qtdPerguntasHabilitadas;
  } else if (qtdeRespostasRequeridas > 0) {
    return qtdPerguntasRespondidas < qtdeRespostasRequeridas;
  }
}


const ModuloFormularioGrupo = (props) => {
  const { exibirDependencia, infoSelecaoQualificador, exibirJustQualificadorZero, qtdeRespostasRequeridas,
    id, perguntas, alterarRespostaGrupoFormulario, respostaGrupo, isSomenteLeitura, alterarJustificativaGrupoFormulario } = { ...props };
   
  const classes = useStyles();
  const qualificadorGrupo = props.respostaGrupo ? props.respostaGrupo.qualificador : undefined;
  const justQualificadorZeroGrupo = props.respostaGrupo ? props.respostaGrupo.justQualifZero : undefined;

  useEffect(() => {
    if (exibirJustQualificadorZero && qualificadorGrupo === 0 && justQualificadorZeroGrupo === undefined){
      alterarJustificativaGrupoFormulario(id,1);
    }
  }, [exibirJustQualificadorZero, qualificadorGrupo,justQualificadorZeroGrupo,id,alterarJustificativaGrupoFormulario]);


  const handlerSelecionarRespostaPergunta = (idPergunta, valor) => {
    props.alterarRespostaPerguntaFormulario(idPergunta, valor);
    if (valor === undefined) {
      // eslint-disable-next-line no-undef
      let mapRespostasPerguntas = new Map(props.mapRespostasPerguntas);
      mapRespostasPerguntas.set(idPergunta, undefined);
      if (isRespostaGrupoDisabled(props.perguntas, mapRespostasPerguntas, qtdeRespostasRequeridas)) {
        props.alterarRespostaGrupoFormulario(props.id, undefined);
      }
    }
  }

  const handlerSelecionarRespostaGrupo = (idGrupo, valor) => {
    props.alterarRespostaGrupoFormulario(idGrupo, valor);
    if (exibirJustQualificadorZero && (valor === undefined || valor > 0)) {
      props.alterarJustificativaGrupoFormulario(idGrupo, undefined);
    }
  }

  const handlerSelecionarRespostaDependencia = (idPergunta, valor) => {
    props.alterarRespostaDependenciaPerguntaFormulario(idPergunta, valor);
  }

  // Parei aqui

  useEffect(() => {
    let isExistePerguntaHabilitada = perguntas.filter((pergunta) => pergunta.habilitado).length > 0;
    if (!isExistePerguntaHabilitada && (!respostaGrupo || !respostaGrupo.isAtribuidoAutomatico)) {
      alterarRespostaGrupoFormulario(id, '4', true)
    }
  }, [id, perguntas, alterarRespostaGrupoFormulario, respostaGrupo])

  return (
    <div>
      <div className="descricao-grupo-formulario">
        <div>
          <span className="texto-informativo">{props.indice} - {props.nome} - {props.dominio}:</span> {props.descricao}
        </div>
        {
          props.indicador &&
          <div>
            <span className="texto-informativo">Indicadores =</span> {props.indicador}
          </div>
        }
      </div>
      <div className="grupo-perguntas">
        <TableContainer style={{ marginBottom: '20px' }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <CelulaCabecalhoDominio>{infoSelecaoQualificador}</CelulaCabecalhoDominio>
                <CelulaValorDominio style={{ width: '25%' }}>
                  <div className="valores-dominio">
                    {RESPOSTAS.map((resp) => <span key={resp}>{resp}</span>)}
                  </div>
                </CelulaValorDominio>
                {
                  exibirDependencia &&
                  <CelulaValorDominioDependencia>
                    <div className="valores-dominio">
                      {RESPOSTAS_DEPENDENCIA.map((resp) => <span key={resp}>{resp}</span>)}
                    </div>
                  </CelulaValorDominioDependencia>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {props.perguntas.map((grupoFormPergunta) =>
                <TableRow key={grupoFormPergunta.id}>
                  <CelulaPergunta>
                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(`${grupoFormPergunta.pergunta.numero}. ${grupoFormPergunta.pergunta.descricao}`) }} />
                  </CelulaPergunta>
                  <CelulaResposta>
                    <ModuloFormularioResposta
                      idPergunta={grupoFormPergunta.id}
                      valor={props.mapRespostasPerguntas.get(grupoFormPergunta.id) && props.mapRespostasPerguntas.get(grupoFormPergunta.id).qualificador}
                      respostas={RESPOSTAS}
                      permiteRespostaAutomatica={true}
                      disabled={!grupoFormPergunta.habilitado || isSomenteLeitura}
                      handlerSelecionarResposta={handlerSelecionarRespostaPergunta} />
                  </CelulaResposta>
                  {
                    exibirDependencia &&
                    <CelulaResposta>
                      <ModuloFormularioRespostaDependencia
                        idPergunta={grupoFormPergunta.id}
                        valor={props.mapRespostasPerguntas.get(grupoFormPergunta.id) && props.mapRespostasPerguntas.get(grupoFormPergunta.id).dependenciaTerceiros}
                        respostas={RESPOSTAS_DEPENDENCIA}
                        disabled={!grupoFormPergunta.habilitado || !props.mapRespostasPerguntas.get(grupoFormPergunta.id) || isSomenteLeitura}
                        handlerSelecionarResposta={handlerSelecionarRespostaDependencia} />
                    </CelulaResposta>
                  }
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow ref={props.refGrupoForm}>
                <CelulaCabecalhoDominio>
                  * Qualificador do dominio {props.indice} - {props.dominio}
                </CelulaCabecalhoDominio>
                <CelulaResposta>
                  <ModuloFormularioRespostaGrupo
                    idGrupo={props.id}
                    valor={qualificadorGrupo}
                    respostas={RESPOSTAS}
                    permiteRespostaAutomatica={true}
                    handlerSelecionarResposta={handlerSelecionarRespostaGrupo}
                    disabled={isRespostaGrupoDisabled(props.perguntas, props.mapRespostasPerguntas, qtdeRespostasRequeridas) || isSomenteLeitura} />
                </CelulaResposta>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {
          props.observacao &&
          <BrCard>
            <div style={{ fontSize: '0.8em', textAlign: 'justify' }}>{props.observacao}</div>
          </BrCard>
        }
        {
          respostaGrupo &&
          respostaGrupo.isAtribuidoAutomatico &&
          <BrMessage info>O domínio <strong>{props.indice} - {props.dominio}</strong> foi qualificado automaticamente pelo sistema</BrMessage>
        }
        {
          props.validacao.validacaoGrupo &&
          <BrMessage error><div dangerouslySetInnerHTML={{ __html: props.validacao.validacaoGrupo }} /></BrMessage>
        }
        {
          exibirJustQualificadorZero &&
          qualificadorGrupo === 0 &&
          <BrRadio id={`justQualifZero_${props.indice}`} label="* Justifique, caso seja atribuído qualificador '0' a este domínio."
            value={justQualificadorZeroGrupo}
            items={props.opcoesJustQualificadorDominioZero.map(i => ({ label: i.descricao, value: i.id }))}
            onChange={v => props.alterarJustificativaGrupoFormulario(props.id, v)}
            erro={props.validacao.validacaoGrupoJustificativa}
            disabled={isSomenteLeitura}
          />

        }
      </div>
    </div >
  )
}

ModuloFormularioGrupo.propTypes = {
  id: PropTypes.number.isRequired,
  indice: PropTypes.string.isRequired,
  nome: PropTypes.string.isRequired,
  dominio: PropTypes.string.isRequired,
  descricao: PropTypes.string.isRequired,
  perguntas: PropTypes.array
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustificativaGrupoFormulario,
  alterarRespostaPerguntaFormulario,
  alterarRespostaGrupoFormulario,
  alterarRespostaDependenciaPerguntaFormulario
}, dispatch);

export default connect(null, mapDispatchToProps)(ModuloFormularioGrupo);
