
import { formatarCei, formatarCNPJ } from '../../../../common/formatadores';
import { ID_TIPO_EMITENTE_CEI, ID_TIPO_EMITENTE_CNPJ } from './validacao';

export const obterTipoIdentificacao = (idTipoIdentEmitente,tiposIdentificacaoEmitente)  => {
    const codigo = idTipoIdentEmitente !== undefined ? idTipoIdentEmitente : -1;
    const tipoEmitente = tiposIdentificacaoEmitente ? tiposIdentificacaoEmitente.find(t => t.id === codigo) : null;
    return tipoEmitente ? tipoEmitente.descricao : '';
  }

 export const obterTipoExameLaudo = (idTipoExameLaudo, tiposExameLaudo) => {
    const codigo = idTipoExameLaudo !== undefined ? idTipoExameLaudo : -1;
    const tipoExame = tiposExameLaudo ? tiposExameLaudo.find(t => t.id === codigo) : null;
    return tipoExame ? tipoExame.descricao : '';
  }

  export const obterNumeroComUf = (item, ufs) => {
    const codigoUf = item ? item.idUFDocumento : -1;
    const uf = ufs ? ufs.find(u => u.codigoUfIbge === codigoUf) : null;

    const siglaUf = uf ? uf.siglaUf : '';
    const numero = item.numeroDocumento;

    const isCnpj = item.idTipoIdentEmitente && item.idTipoIdentEmitente === ID_TIPO_EMITENTE_CNPJ;
    const isCei = item.idTipoIdentEmitente && item.idTipoIdentEmitente === ID_TIPO_EMITENTE_CEI;

    const numeroFormatado = isCnpj
      ? formatarCNPJ(numero)
      : (isCei ? formatarCei(numero) : numero)

    return siglaUf && numeroFormatado ? numeroFormatado.concat(` (${siglaUf})`) : numeroFormatado;
  }