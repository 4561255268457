import React from "react";
import { Grid } from '@material-ui/core';
import BrCard from '@govbr/react-components/lib/BrCard';

import TabelaSistemasCorpo from "./tabelaSistemasCorpo";


const DetalheSistemasCorpo = ({ exames }) => {

  return (
    <Grid item xs={12}>
        <BrCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span className="texto-informativo">{exames.nomeCampo}</span>
            </Grid>
            <Grid item xs={12}>
                <p className="ml-10">
                  Valor anterior: 
                  { (!exames.valorOrigem || exames.valorOrigem.length === 0) && 
                    <span className="texto-informativo ml-10 valor-anterior-revisao">---</span>
                  }
                </p>
                { exames.valorOrigem && exames.valorOrigem.length > 0 &&
                  <TabelaSistemasCorpo exames={exames.valorOrigem} isValorAtual={false}/>
                }
            </Grid>

            <Grid item xs={12} >
              <p className="ml-10">
                Valor atual: 
                { (!exames.valorAtual || exames.valorAtual.length === 0) && 
                  <span className="texto-informativo ml-10 valor-atual-revisao">---</span>
                }
              </p>
              { exames.valorAtual && exames.valorAtual.length > 0 &&
                <TabelaSistemasCorpo exames={exames.valorAtual} isValorAtual={true}/>
              }
            </Grid>
          </Grid>
        </BrCard>
      </Grid>
  );
};


export default DetalheSistemasCorpo;

