import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {

  const moduloCid = pericia?.moduloCid;

  return moduloCid && moduloCid.cidPrincipal;
}

export const validar = (pericia, getState) => {

  const cidPrincipal = pericia?.moduloCid?.cidPrincipal;
  const cidPrincipalDigitado = getState().modulos.moduloCids.inputCidPrincipal;

  let erroCidPrincipal = cidPrincipal == null ? MSG_CAMPO_OBRIGATORIO : null;

  if (!erroCidPrincipal) {
      erroCidPrincipal = (cidPrincipalDigitado && cidPrincipal !== cidPrincipalDigitado) 
        ? "Um valor de CID Principal válido deve ser selecionado."
        : null;
  }

  return {
    isValida: !erroCidPrincipal,
    erroCidPrincipal: erroCidPrincipal
  };
}