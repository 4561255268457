import { MSG_CAMPO_OBRIGATORIO } from "../../../../../common/Messages";
import { tipoSimNao, tiposPericia } from "../../../constants";

import {isData1EhDepoisData2, isData1EhAntesOuIgualData2, formatarDateTimeISOToDataBr} from '../../../../../common/DataUtils';

const camposIndicacaoAuxilioAcidente = pericia => {
  return {
    indAuxilioAcidente: pericia?.moduloGrauIncapacidade?.indAuxilioAcidente,
    dataCessacaoBeneficio: pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio,
    enquadramentoAuxilioAcidente: pericia?.moduloGrauIncapacidade?.enquadramentoAuxilioAcidente,
    situacaoEnquadramentoAuxilioAcidente: pericia?.moduloGrauIncapacidade?.situacaoEnquadramentoAuxilioAcidente,
    justParcialComIndicAuxilioAcidente: pericia?.moduloGrauIncapacidade?.justParcialComIndicAuxilioAcidente
  }
}

export const verificarSeCamposPreenchidos = (pericia, getState) => {

  const campos = camposIndicacaoAuxilioAcidente(pericia);
  const template = getState().principal?.template;
  const isPericiaRBI = tiposPericia.INSS_BI_PRBI.tipo === template?.tipoPericia;

  if (!campos.indAuxilioAcidente) { return false; }

  if (campos.indAuxilioAcidente === tipoSimNao.SIM) {
    if (!isPericiaRBI && !campos.dataCessacaoBeneficio) { return false; }
    if (!campos.enquadramentoAuxilioAcidente) { return false; }
    if (!campos.situacaoEnquadramentoAuxilioAcidente) { return false; }
    if (!campos.justParcialComIndicAuxilioAcidente) { return false; }
  }

  return true;
}

export const validar = (pericia, getState) => {

  const campos = camposIndicacaoAuxilioAcidente(pericia);
  const template = getState().principal?.template;
  const isPericiaRBI = tiposPericia.INSS_BI_PRBI.tipo === template?.tipoPericia;

  let invalidado = {
    isValida: true
  }

  if (campos.indAuxilioAcidente === undefined) {
    invalidado.erroIndAuxilioAcidente = MSG_CAMPO_OBRIGATORIO;
  }


  if (campos.indAuxilioAcidente === 1) {
    
    if (!campos.enquadramentoAuxilioAcidente) { invalidado.erroEnquadramentoAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.situacaoEnquadramentoAuxilioAcidente) { invalidado.erroSituacaoEnquadramentoAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.justParcialComIndicAuxilioAcidente || campos.justParcialComIndicAuxilioAcidente.trim().length === 0) { invalidado.erroJustIndicAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
    
    if (!isPericiaRBI) {
      if (!campos.dataCessacaoBeneficio) { invalidado.erroDcbAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
      else {
        const dcb = new Date(campos.dataCessacaoBeneficio);
        // validar DCB maior que data atual
        if(isData1EhDepoisData2(dcb,new Date())){
          invalidado.erroDcbAuxilioAcidente = "Não é possível informar 'Data de Cessação do Benefício' (DCB) maior que data atual.";
        } else{
          const dib = pericia.beneficiosAnteriores?.find(b => b.numeroBeneficio && b.numeroBeneficio === pericia.numeroBeneficio)?.dataInicioBeneficio;
          //validar DCB menor ou igual DIB
          if(isData1EhAntesOuIgualData2(dcb,new Date(dib))){
            invalidado.erroDcbAuxilioAcidente = `Não é possível informar 'Data de Cessação do Benefício' (DCB) menor ou igual a Data Início do Benefício (DIB) atual concedido, ${formatarDateTimeISOToDataBr(dib)}`;
          }
        }
      }
    }
  }

  if (Object.keys(invalidado).some(e => e.includes("erro"))) {
    invalidado = { ...invalidado, isValida: false }
  }

  return invalidado;

}

export const limparValidacao = () => {
  return {
    isValida: true
  }
}



