import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import Box from "@material-ui/core/Box";
import { formatarCPF } from '../../common/formatadores';
import { downloadPericia, downloadPericiaGenerico, validarEConcluirPericia } from '../../features/pericia/action';
import DialogConfirmar from '../DialogConfirmar';

class Concluir extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exibirModalConfirmarConclusao: false
    }
  }

  exibirModal = () => {
    this.setState({ ...this.state, exibirModalConfirmarConclusao: true })
  }

  ocultarModal = () => {
    this.setState({ ...this.state, exibirModalConfirmarConclusao: false })
  }

  render() {

    const { pericia, validacao, isSomenteLeitura,
      dadosResultadoParcial, dadosConclusaoAtestmed,
      validarEConcluirPericia, downloadPericia, downloadPericiaGenerico, isPericiaBPC, disabled } = this.props;
    const periciaComResultadoFinal = {
      ...pericia, 
      resultadoParcialBPC: { ...pericia.resultadoParcialBPC, ...dadosResultadoParcial }, //resultado calculado para BPC
      moduloConclusaoAtestmed: { ...pericia.moduloConclusaoAtestmed, ...dadosConclusaoAtestmed } //resultado calculado para ATESTMED
    };
    const isPericiaConcluida = pericia.situacao.id === 4 || pericia.situacao.id === 5;

    const nomeArquivo = "pericia_" + (pericia.avaliado?.nome ? pericia.avaliado.nome.replace(/\s/g,'_') : "sem_nome")
                        + (pericia.protocoloPMF ? `_protocolo_tarefa_PMF_${pericia.protocoloPMF}`:'');

    return (
      <Box display="flex" justifyContent="flex-end" p={2}>
        {isPericiaConcluida &&
          (
            <BrButton primary className="mr-2"
              id="btnImprimirPericia"
              onClick={() => isPericiaBPC ? downloadPericia(pericia.id, nomeArquivo) : downloadPericiaGenerico(pericia.id, nomeArquivo, pericia.idPericiaAnterior)}>
              Imprimir
            </BrButton>
          )
        }
        <BrButton id="btnConcluir" primary
          onClick={this.exibirModal}
          disabled={!validacao.isPericiaValida || isSomenteLeitura || disabled}>
          <i className={`fas fa-clipboard-check`} />
          Concluir
        </BrButton>
		
        <DialogConfirmar
          id="dlgConfirmarConclusaoPericia"
          texto={`Confirma a conclusão do serviço para cidadão(ã) ${pericia.avaliado.nome} (CPF: ${formatarCPF(pericia.avaliado.cpf)})?`}
          acaoConfimar={() => {
            validarEConcluirPericia(periciaComResultadoFinal);
            this.ocultarModal();
          }}
          acaoCancelar={this.ocultarModal}
          titulo="Confirmar Conclusão"
          exibir={this.state.exibirModalConfirmarConclusao}
        />

      </Box >
    );
  }
}

const mapStateToProps = (state) => ({
  pericia: state.pericia,
  isPericiaBPC: state.principal.isPericiaBPC,
  validacao: state.principal.validacao,
  isSomenteLeitura: state.principal.isSomenteLeitura
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validarEConcluirPericia,
  downloadPericia,
  downloadPericiaGenerico
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Concluir);