import { tipoSimNao } from "../../constants";
import {isValorRegraExibicaoIgualValorCampo, obterValorCampoEstaticoRegraExibicao} from '../../../../common/CampoGenericoUtils';

export default function tratarInputEmCampoGenerico(idCampoGenericoAlterado, valorInserido, valoresCamposGenericosDaPericia, 
    modulosTemplatePericia, pericia) {
    const modulosTemplatePericiaTratados = [...modulosTemplatePericia];
    modulosTemplatePericiaTratados.forEach(moduloTemplate => 
        tratarPorModulo(idCampoGenericoAlterado, valorInserido, valoresCamposGenericosDaPericia, moduloTemplate.modulo, pericia));
    return modulosTemplatePericiaTratados;
}

function tratarPorModulo(idCampoGenerico, valor, valoresCamposGenericos, modulo, pericia) {
    if (modulo.tipoModuloEstatico === undefined) {
        modulo.camposGenericos.forEach(campoGenerico => tratarPorCampoGenerico(idCampoGenerico, valor, valoresCamposGenericos, campoGenerico, pericia));
        modulo.secoes.forEach(secao => tratarPorSecao(idCampoGenerico, valor, valoresCamposGenericos, secao, pericia));
    }
}

function tratarPorCampoGenerico(idCampoGenerico, valor, valoresCamposGenericos, campoGenerico, pericia) {
    const regraExibicaoAssociada = campoGenerico.regrasDependenciaExibicao?.find(regraExibicao => {
        return regraExibicao.campoGenericoModuloOrigem?.campoGenerico.id === idCampoGenerico
            || regraExibicao.secaoModuloCampoGenericoOrigem?.campoGenerico.id === idCampoGenerico
    })

    const naoPossuiRegrasExibicao = !campoGenerico.regrasDependenciaExibicao;

    if (naoPossuiRegrasExibicao || !regraExibicaoAssociada) {
        return;
    }

    const quantRegrasDoCampo = campoGenerico.regrasDependenciaExibicao?.length || 0;

    const possuiApenasUmaRegra = quantRegrasDoCampo === 1;

    const resultadoRegraAssociada = isValorRegraExibicaoIgualValorCampo(regraExibicaoAssociada?.valor, valor);

    if (possuiApenasUmaRegra) {
        campoGenerico.visivel = resultadoRegraAssociada ? tipoSimNao.SIM : tipoSimNao.NAO;
        return;
    }

    const resultadoDemaisRegras = possuiDemaisRegrasAtendidas(campoGenerico.regrasDependenciaExibicao,idCampoGenerico,valoresCamposGenericos,pericia);

    campoGenerico.visivel = resultadoRegraAssociada && resultadoDemaisRegras ? tipoSimNao.SIM : tipoSimNao.NAO;
}

function tratarPorSecao(idCampoGenerico, valor, valoresCamposGenericos, secao, pericia) {
    const regraExibicaoAssociada = secao.regrasDependenciaExibicao?.find(regraExibicao => {
        return regraExibicao.campoGenericoModuloOrigem?.campoGenerico.id === idCampoGenerico
            || regraExibicao.secaoModuloCampoGenericoOrigem?.campoGenerico.id === idCampoGenerico
    });

    const naoPossuiRegrasExibicao = !secao.regrasDependenciaExibicao;

    if (naoPossuiRegrasExibicao || !regraExibicaoAssociada) {
        return;
    }

    const quantRegrasDaSecao = secao.regrasDependenciaExibicao?.length || 0;

    const possuiApenasUmaRegra = quantRegrasDaSecao === 1;

    const resultadoRegraAssociada =  isValorRegraExibicaoIgualValorCampo(regraExibicaoAssociada?.valor, valor);

    var resultadoRegrasDaSecao = possuiApenasUmaRegra 
        ? resultadoRegraAssociada 
        : resultadoRegraAssociada && possuiDemaisRegrasAtendidas(secao.regrasDependenciaExibicao,idCampoGenerico, valoresCamposGenericos, pericia);

    if (secao.moduloEstaticoFilho?.tipoModuloEstatico) {
        //modulo estatico filho depende apenas das regras de exibicao da secao
        secao.moduloEstaticoFilho.visivel = resultadoRegrasDaSecao ? tipoSimNao.SIM : tipoSimNao.NAO;
    } else {
        //verificar se alem das regras da secao tambem existe regras de exibicao especificas para cada campo generico
        secao.secoesModulosCamposGenericos.forEach(campoGenericoSecao => 
            trataCampoGenericoDaSecao(idCampoGenerico, valoresCamposGenericos, resultadoRegrasDaSecao, campoGenericoSecao, pericia));
    }
}

function trataCampoGenericoDaSecao(idCampoGenerico, valoresCamposGenericos, resultadoRegrasDaSecao, campoGenericoSecao, pericia) {

    // se nao existem mais regras de exibicao, considera que regras estao atendidas e campo estara visivel 
    const demaisRegrasAtendidas = !campoGenericoSecao.regrasDependenciaExibicao 
    //se existem regras especificas para o campo generico, verificar se sao todas atendidas
        || possuiDemaisRegrasAtendidas(campoGenericoSecao.regrasDependenciaExibicao,idCampoGenerico, valoresCamposGenericos, pericia);

    campoGenericoSecao.campoGenerico.visivel = resultadoRegrasDaSecao && demaisRegrasAtendidas ? tipoSimNao.SIM : tipoSimNao.NAO;
}

const possuiDemaisRegrasAtendidas = (regrasDependenciaExibicao,idCampoGenerico, valoresCamposGenericos, pericia)  => 
    regrasDependenciaExibicao?.filter(regra =>
        regra.campoGenericoModuloOrigem?.campoGenerico.id !== idCampoGenerico
        && regra.secaoModuloCampoGenericoOrigem?.campoGenerico.id !== idCampoGenerico
    ).every(regra => {
        const campoRegra = regra.campoGenericoModuloOrigem ? regra.campoGenericoModuloOrigem : regra.secaoModuloCampoGenericoOrigem;
        if (campoRegra) {
            const valorCampo = campoRegra && valoresCamposGenericos.find(v => v.idCampoGenerico === campoRegra.campoGenerico.id);
            return isValorRegraExibicaoIgualValorCampo(regra.valor, valorCampo?.valorTextoCampoGenerico);
        } else if (regra.tipoCampoExibicaoPericia) {
            return isValorRegraExibicaoIgualValorCampo(regra.valor, obterValorCampoEstaticoRegraExibicao(regra.tipoCampoExibicaoPericia, pericia));
        }
        return true;
    });

