const INITIAL_STATE = {
  dadosRetorno: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EXIBIR_DIALOG_SOLICITACAO_SIMA':
      return { ...state, isFormSolicitacaoVisible: action.payload }
    case 'EXIBIR_DIALOG_RETORNO_SIMA':
      return { ...state, isFormRetornoVisible: action.payload }
    case 'INPUT_TIPO_SOLICITACAO_EXIGENCIA':
      return { ...state, tipoSolicitacaoExigencia: action.payload }
    case 'INPUT_JUSTIFICATIVA_SIMA':
      return { ...state, justificativa: action.payload }
    case 'INPUT_NOME_PROFISSIONAL_SIMA':
      return { ...state, dadosRetorno: { ...state.dadosRetorno, nomeProfissional: action.payload } }
    case 'INPUT_CRM_PROFISSIONAL_SIMA':
      return { ...state, dadosRetorno: { ...state.dadosRetorno, crmProfissional: action.payload } }
    case 'INPUT_UF_SIMA':
      return { ...state, dadosRetorno: { ...state.dadosRetorno, idUFCrmProfissional: action.payload } }
    case 'INPUT_DATA_DOCUMENTO_SIMA':
      return { ...state, dadosRetorno: { ...state.dadosRetorno, dataDocumento: action.payload } }
    case 'INPUT_RESULTADO_SIMA':
      return { ...state, dadosRetorno: { ...state.dadosRetorno, resultadoSima: action.payload } }
    case 'CANCELAR_RETORNO_SIMA':
        return { ...state, dadosRetorno: {} }  
    case 'ATUALIZAR_PERICIA':
          return { ...state, dadosRetorno: { ...action.payload.moduloSima } }        
    default:
      return state;
  }
}