import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import HeaderSecao from '../../components/HeaderSecao';

import BrTabs from '@govbr/react-components/lib/BrTabs';

import { limparMensagem, limparTemplate } from '../principal/action';
import { limparDadosPericia } from '../pericia/action';
import { buscarValoresBibliotecaPerito } from './action';

import DicionarioPerito from "./DicionarioPerito";
import DicionarioGeral from "./DicionarioGestor";

const Dicionario = (props) => {

  useEffect(() => {
    //remover qualquer mensagem de erro/sucesso que possa existir
    props.limparMensagem();
    //remover dados pericia, retirando mensagens de alerta da tela
    props.limparDadosPericia();
    props.limparTemplate(); 
    //consultar lista de itens do dicionario
    props.buscarValoresBibliotecaPerito(props.cpfPerito);
    // eslint-disable-next-line
  }, []);//deve executar apenas a primeira vez que o componente for renderizado

  const [selectedTab, setSelectTab] = useState("dicionarioPerito");

  return (
    <div id="tela-dicionario-dados" className="col">

      <HeaderSecao texto="Dicionário PMF: Cadastro de Conteúdos para Perícia" />

      <p>Permite o registro e gerenciamento de conteúdos para referência durante a realização de pericias médicas. 
        Os textos cadastrados serão exibidos durante análise médica, oferecendo aos peritos uma seleção abrangente de informações. 
        Adicione, atualize ou exclua textos conforme necessário para enriquecer a base de conhecimento utilizada durante a fase de realizaçáo das perícias médicas.
      </p>

      <BrTabs
        value={selectedTab}
        onChange={value => setSelectTab(value)}
        label="Dicionário de Dados"
      >
        <BrTabs.Tabs label="Tabs">
          <BrTabs.Tab id="dicionarioPerito" label="Meu Dicionário" />
          <BrTabs.Tab id="dicionarioGestor" label="Dicionário PMF" />
        </BrTabs.Tabs>

        <BrTabs.Content>
          <BrTabs.Panel contentFor="dicionarioPerito">
            <DicionarioPerito/>
          </BrTabs.Panel>
          <BrTabs.Panel contentFor="dicionarioGestor">
            <DicionarioGeral/>
          </BrTabs.Panel>
        </BrTabs.Content>
      </BrTabs>
    </div>
  )
}

const mapStateToProps = (state) => ({
  cpfPerito: state.principal.userData.document.number
})

const mapDispatchToProps = {
  limparMensagem,
  limparDadosPericia,
  buscarValoresBibliotecaPerito,
  limparTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(Dicionario);