import { formatarDateTimeISOToDataBr } from '../../common/DataUtils';
import { verificaCidEmGrupo } from '../../common/Utils';

const INITIAL_STATE = {
  moduloDocApresentado: {},
  moduloExameLaudo: {},
  moduloExameFisico: {},
  moduloCid: {},
  moduloEstruturaPrognostico: {},
  moduloImpedRiscoSocial: {},
  avaliado: {},
  resultadoParcialBPC: {},
  moduloSima: {},
  moduloNexoTecnico: {},
  moduloIsencaoCarencia: {},
  respostasPerguntaForm: [],
  respostasGrupoForm: [],
  valoresCamposGenericos: [],
  moduloAvaliacaoFisica: {},
  moduloGrauIncapacidade: {},
  moduloConclusaoPericia: {},
  moduloRestabelecimentoBeneficio: {},
  examesSistemaCorpo: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ATUALIZAR_PERICIA': {
      //tratando modulos cujos dados podem ter sido removidos da pericia
      let moduloNexoTecnico = action.payload.moduloNexoTecnico ? { ...action.payload.moduloNexoTecnico } : {};
      let moduloGrauIncapacidade = action.payload.moduloGrauIncapacidade ? { ...action.payload.moduloGrauIncapacidade } : {};
      let moduloDocApresentado = action.payload.moduloDocApresentado ? { ...action.payload.moduloDocApresentado } : {};
      let moduloExameLaudo = action.payload.moduloExameLaudo ? { ...action.payload.moduloExameLaudo } : {};
      let moduloExameFisico = action.payload.moduloExameFisico ? { ...action.payload.moduloExameFisico } : {};
      let moduloCid = action.payload.moduloCid ? { ...action.payload.moduloCid } : {};
      let moduloEstruturaPrognostico = action.payload.moduloEstruturaPrognostico ? { ...action.payload.moduloEstruturaPrognostico } : {};
      let moduloImpedRiscoSocial = action.payload.moduloImpedRiscoSocial ? { ...action.payload.moduloImpedRiscoSocial } : {};
      let moduloIsencaoCarencia = action.payload.moduloIsencaoCarencia ? { ...action.payload.moduloIsencaoCarencia } : {};
      let moduloAvaliacaoFisica = action.payload.moduloAvaliacaoFisica ? { ...action.payload.moduloAvaliacaoFisica } : {};
      let moduloRestabelecimentoBeneficio = action.payload.moduloRestabelecimentoBeneficio ? { ...action.payload.moduloRestabelecimentoBeneficio } : {};
      return {
        ...state,
        moduloNexoTecnico, moduloGrauIncapacidade, moduloAvaliacaoFisica, moduloExameLaudo, moduloIsencaoCarencia, moduloCid,
        moduloExameFisico, moduloDocApresentado, moduloEstruturaPrognostico, moduloImpedRiscoSocial, moduloRestabelecimentoBeneficio,
        ...action.payload
      }
    }
    case 'LIMPAR_DADOS_PERICIA': 
      return INITIAL_STATE;
    case 'ATUALIZAR_DADOS_SIMA':
      return { ...state, moduloSima: action.payload.moduloSima, ...action.payload.pericia }
    case 'ATUALIZAR_RESPOSTA_CONCLUIR_PERICIA_SUCESSO': {
      let periciaTO = action.payload.periciaTO;
      return { ...state, situacao: periciaTO.situacao }
    }
    case 'LIMPAR_RESPOSTA_CONCLUIR_PERICIA_ERRO': {
      return { ...state, respostaConcluir: null }
    }
    case 'ATUALIZAR_RESPOSTA_CONCLUIR_PERICIA_ERRO': {
      if (action.payload.periciaTO) {
        const pericia = action.payload.periciaTO;
        const respostaConcluir = { erros: action.payload.erros }
        //tratando modulos cujos dados podem ter sido removidos da pericia apos salvar durante conclusao
        const moduloNexoTecnico = pericia && pericia.moduloNexoTecnico ? { ...pericia.moduloNexoTecnico } : {};
        const moduloGrauIncapacidade = pericia && pericia.moduloGrauIncapacidade ? { ...pericia.moduloGrauIncapacidade } : {};
        const moduloDocApresentado = pericia && pericia.moduloDocApresentado ? { ...pericia.moduloDocApresentado } : {};
        const moduloExameLaudo = pericia && pericia.moduloExameLaudo ? { ...pericia.moduloExameLaudo } : {};
        const moduloExameFisico = pericia && pericia.moduloExameFisico ? { ...pericia.moduloExameFisico } : {};
        const moduloCid = pericia && pericia.moduloCid ? { ...pericia.moduloCid } : {};
        const moduloEstruturaPrognostico = pericia && pericia.moduloEstruturaPrognostico ? { ...pericia.moduloEstruturaPrognostico } : {};
        const moduloImpedRiscoSocial = pericia && pericia.moduloImpedRiscoSocial ? { ...pericia.moduloImpedRiscoSocial } : {};
        const moduloIsencaoCarencia = pericia && pericia.moduloIsencaoCarencia ? { ...pericia.moduloIsencaoCarencia } : {};
        const moduloAvaliacaoFisica = pericia && pericia.moduloAvaliacaoFisica ? { ...pericia.moduloAvaliacaoFisica } : {};
        const moduloRestabelecimentoBeneficio = pericia && pericia.moduloRestabelecimentoBeneficio ? { ...pericia.moduloRestabelecimentoBeneficio } : {};
        return {
          ...state,
          moduloNexoTecnico, moduloGrauIncapacidade, moduloAvaliacaoFisica, moduloExameLaudo, moduloIsencaoCarencia, moduloCid,
          moduloExameFisico, moduloDocApresentado, moduloEstruturaPrognostico, moduloImpedRiscoSocial, moduloRestabelecimentoBeneficio,
          ...pericia, respostaConcluir
        }
      } else {
        const respostaConcluir = { erros: action.payload.erros }
        return {
          ...state,
          respostaConcluir
        }
      }
    }
    case 'INPUT_TIPO_DOCUMENTO_AVALIADO':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, tipoDocumentoCivilAvaliado: action.payload, numeroDocAvaliado: undefined } }
    case 'INPUT_NUMERO_DOCUMENTO_AVALIADO':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, numeroDocAvaliado: action.payload } }
    case 'INPUT_COMPLEMENTO_DOCUMENTO_AVALIADO':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, complementoDocAvaliado: action.payload } }
    case 'INPUT_TIPO_DOCUMENTO_ACOMPANHANTE':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, tipoDocumentoCivilAcompanhante: action.payload, numeroDocAcompanhante: undefined } }
    case 'INPUT_NUMERO_DOCUMENTO_ACOMPANHANTE':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, numeroDocAcompanhante: action.payload } }
    case 'INPUT_COMPLEMENTO_DOCUMENTO_ACOMPANHANTE':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, complementoDocAcompanhante: action.payload } }
    case 'INPUT_NOME_ACOMPANHANTE':
      return { ...state, moduloDocApresentado: { ...state.moduloDocApresentado, nomeAcompanhante: action.payload } }
    case 'INPUT_HISTORIA_CLINICA':
      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, historiaClinica: action.payload } }
    case 'INPUT_HISTORIA_CLINICA_DOCUM_MED_ODONT':
      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, historiaClinica: action.payload } }
    case 'INPUT_NAO_EXISTE_EXAME_LAUDO':
      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, naoExisteExameLaudo: action.payload } }
    case 'INCLUIR_EXAME_LAUDO': {
      const listaExameLaudo = state.moduloExameLaudo.examesLaudo || [];
      const novoExameLaudo = { ...action.payload, idModuloExameLaudo: state.moduloExameLaudo.idModExameLaudo, idTemp: listaExameLaudo.length + 1 };

      listaExameLaudo.push(novoExameLaudo);

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudo } };
    }
    case 'ALTERAR_EXAME_LAUDO': {
      const exameAlterado = action.payload;
      const listaExameLaudo = state.moduloExameLaudo.examesLaudo;
      const indexAlteracao = exameAlterado.idExameLaudo
        ? listaExameLaudo.indexOf(listaExameLaudo.find(i => i.idExameLaudo === exameAlterado.idExameLaudo)) // exame ja existe no BD
        : listaExameLaudo.indexOf(listaExameLaudo.find(i => i.idTemp === exameAlterado.idTemp)); //exame existe apenas no state, idTemp = index da lista

      listaExameLaudo[indexAlteracao] = exameAlterado;

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudo } };
    }
    case 'EXCLUIR_EXAME_LAUDO': {
      const exameAExcluir = action.payload;
      const listaExameLaudo = exameAExcluir.idExameLaudo
        ? state.moduloExameLaudo.examesLaudo.filter(e => e.idExameLaudo !== exameAExcluir.idExameLaudo)
        : state.moduloExameLaudo.examesLaudo.filter(e => e.idTemp !== exameAExcluir.idTemp);

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudo } };
    }
    case 'INCLUIR_DOCUM_MED_ODONT': {
      const listaExameLaudo = state.moduloExameLaudo.examesLaudo || [];
      const novoExameLaudo = { ...action.payload, idModuloExameLaudo: state.moduloExameLaudo.idModExameLaudo, idTemp: listaExameLaudo.length + 1 };

      listaExameLaudo.push(novoExameLaudo);
      const listaExameLaudoOrdenado = listaExameLaudo.sort((exameA, exameB) => new Date(exameB.dataDocumento) - new Date(exameA.dataDocumento));

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudoOrdenado } };
    }
    case 'ALTERAR_DOCUM_MED_ODONT': {
      const exameAlterado = action.payload;
      const listaExameLaudo = state.moduloExameLaudo.examesLaudo;
      const indexAlteracao = exameAlterado.idExameLaudo
        ? listaExameLaudo.indexOf(listaExameLaudo.find(i => i.idExameLaudo === exameAlterado.idExameLaudo)) // exame ja existe no BD
        : listaExameLaudo.indexOf(listaExameLaudo.find(i => i.idTemp === exameAlterado.idTemp)); //exame existe apenas no state, idTemp = index da lista

      listaExameLaudo[indexAlteracao] = exameAlterado;
      const listaExameLaudoOrdenado = listaExameLaudo.sort((exameA, exameB) => new Date(exameB.dataDocumento) - new Date(exameA.dataDocumento));

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudoOrdenado } };
    }
    case 'EXCLUIR_DOCUM_MED_ODONT': {
      const exameAExcluir = action.payload;
      const listaExameLaudo = exameAExcluir.idExameLaudo
        ? state.moduloExameLaudo.examesLaudo.filter(e => e.idExameLaudo !== exameAExcluir.idExameLaudo)
        : state.moduloExameLaudo.examesLaudo.filter(e => e.idTemp !== exameAExcluir.idTemp);

      const listaExameLaudoOrdenado = listaExameLaudo.sort((exameA, exameB) => new Date(exameB.dataDocumento) - new Date(exameA.dataDocumento));

      return { ...state, moduloExameLaudo: { ...state.moduloExameLaudo, examesLaudo: listaExameLaudoOrdenado } };
    }
    case 'INPUT_PRESSAO_MAXIMA':
      return { ...state, moduloExameFisico: { ...state.moduloExameFisico, pressaoMaxima: action.payload } }
    case 'INPUT_PRESSAO_MINIMA':
      return { ...state, moduloExameFisico: { ...state.moduloExameFisico, pressaoMinima: action.payload } }
    case 'INPUT_PESO':
      return { ...state, moduloExameFisico: { ...state.moduloExameFisico, peso: action.payload } }
    case 'INPUT_ALTURA':
      return { ...state, moduloExameFisico: { ...state.moduloExameFisico, altura: action.payload } }
    case 'INPUT_DESCRICAO_EXAME_FISICO':
      return { ...state, moduloExameFisico: { ...state.moduloExameFisico, descricaoExameFisico: action.payload } }
    case 'INPUT_CID_PRINCIPAL': {
      const opcaoSelecionada = action.payload;
      const cid = opcaoSelecionada ? opcaoSelecionada.codigo : null;
      const nome = opcaoSelecionada ? opcaoSelecionada.nome : null;

      //deve verificar para nao incluir erroneamente dados Nexo para pericias que nao tem este modulo no template
      const existeModuloNexoTecnico = action.existeModuloNexoNoTemplate;
      //deve verificar para nao incluir erroneamente dados Restabelecimento para pericias que nao tem este modulo no template
      const existeModuloRestabelecimento = action.existeModuloRestabelecimentoNoTemplate;
      //deve verificar para nao incluir erroneamente dados Isencao Carencia para pericias que nao tem este modulo no template
      const existeModuloIsencaoNoTemplate = action.existeModuloIsencaoNoTemplate;

      const vinculosSemRelacionamentoNexo = state.vinculosPericia;
      if (vinculosSemRelacionamentoNexo) {
        vinculosSemRelacionamentoNexo.forEach(v => {
          v.possuiNexoTecnico = null;
          v.tipoNexoTecnico = null;
        });
      }
      
      const cidAnteriorEmGrupoO = state.moduloCid && verificaCidEmGrupo(state.moduloCid.cidPrincipal, "O");
      const novoCidEmGrupoO = verificaCidEmGrupo(cid, "O");

      let moduloIsencaoCarencia = {
        id: state.moduloIsencaoCarencia?.id,
        isentaCarencia: state.moduloIsencaoCarencia?.isentaCarencia
      };

      if (cidAnteriorEmGrupoO && !novoCidEmGrupoO) {
        moduloIsencaoCarencia = {
          ...state.moduloIsencaoCarencia, 
          isentaCarenciaPorGestacaoRisco: undefined, 
          justificativaGestacaoAltoRisco: undefined
        }
      } else if (!cidAnteriorEmGrupoO && novoCidEmGrupoO) {
        moduloIsencaoCarencia = {
          ...state.moduloIsencaoCarencia, 
          isentaCarencia: 0,
          tipoDoencaIsencao: undefined,
          isentaCarenciaPorGestacaoRisco: 0, 
          justificativaGestacaoAltoRisco: undefined
        }
      }

      let atualizacaoDoState = {
        ...state,
        moduloCid: { ...state.moduloCid, cidPrincipal: cid, nomeCidPrincipal: nome },
        moduloNexoTecnico: existeModuloNexoTecnico ? { id: state.moduloNexoTecnico.id, fatoresRiscoProfissional: [] } : {},
        moduloRestabelecimentoBeneficio: existeModuloRestabelecimento ? { id: state.moduloRestabelecimentoBeneficio.id } : {},
        moduloIsencaoCarencia: existeModuloIsencaoNoTemplate ? moduloIsencaoCarencia : {},
        vinculosPericia: vinculosSemRelacionamentoNexo
      }

      return atualizacaoDoState
    }
    case 'INPUT_NAO_EXISTE_CID_SECUNDARIO':
      return { ...state, moduloCid: { ...state.moduloCid, naoExisteCidSecundario: action.payload } }
    case 'INCLUIR_CID_SECUNDARIO': {
      const listaCidsSecundarios = state.moduloCid.cidsSecundarios || [];
      const novoCidSecundario = { cidSecundario: action.payload, nomeCidSecundario: action.nomeNovoCid, idModuloCID: state.moduloCid.idModCid };

      listaCidsSecundarios.push(novoCidSecundario);

      return { ...state, moduloCid: { ...state.moduloCid, cidsSecundarios: listaCidsSecundarios } };
    }
    case 'EXCLUIR_CID_SECUNDARIO': {
      const listaCidsSecundarios = state.moduloCid.cidsSecundarios.filter(c => c.cidSecundario !== action.payload);

      return { ...state, moduloCid: { ...state.moduloCid, cidsSecundarios: listaCidsSecundarios } };
    }
    case 'ALTERAR_RESPOSTA_PERGUNTA_FORM': {
      let index = state.respostasPerguntaForm.findIndex((item) => item.idPerguntaFormulario === action.payload.idPerguntaFormulario);
      let novasRespostas = state.respostasPerguntaForm.slice();
      if (action.payload.qualificador !== undefined) {
        let resposta = { ...action.payload };
        (index !== -1) ? novasRespostas[index] = { ...novasRespostas[index], ...resposta } : novasRespostas.push(resposta);
      } else {
        novasRespostas.splice(index, 1);
      }
      return { ...state, respostasPerguntaForm: novasRespostas }
    }
    case 'ALTERAR_RESPOSTA_DEPENDENCIA_PERGUNTA_FORM': {
      let index = state.respostasPerguntaForm.findIndex((item) => item.idPerguntaFormulario === action.payload.idPerguntaFormulario);
      let novasRespostas = state.respostasPerguntaForm.slice();
      let resposta = { ...action.payload };
      if (index !== -1) {
        novasRespostas[index] = { ...novasRespostas[index], ...resposta };
      }
      return { ...state, respostasPerguntaForm: novasRespostas }
    }

    case 'ALTERAR_RESPOSTA_GRUPO_FORM': {
      let index = state.respostasGrupoForm.findIndex((item) => item.idGrupoFormulario === action.payload.idGrupoFormulario);
      // let respostaGrupoAnterior = novasRespostas[index];
      let novasRespostas = state.respostasGrupoForm.slice();

      let resposta = { ...action.payload };
      (index !== -1) ? novasRespostas[index] = { ...novasRespostas[index], ...resposta } : novasRespostas.push(resposta);

      return { ...state, respostasGrupoForm: novasRespostas }
    }
    case 'ALTERAR_JUSTIFICATIVA_GRUPO_FORM': {
      let index = state.respostasGrupoForm.findIndex((item) => item.idGrupoFormulario === action.payload.idGrupoFormulario);
      let novasRespostas = state.respostasGrupoForm.slice();
      let resposta = { ...action.payload };
      if (index !== -1) {
        novasRespostas[index] = { ...novasRespostas[index], ...resposta }
      }
      return { ...state, respostasGrupoForm: novasRespostas }
    }
    case 'INPUT_ALTERACAO_ESTRUTURA_CORPO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, alteracaoEstruturaCorpo: action.payload } }
    case 'INPUT_SELECIONAR_ESTRUTURA_CORPO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, estruturasCorpo: action.payload } }
    case 'INPUT_DESCRICAO_ESTRUTURA_CORPO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, descricaoAltEstCorpo: action.payload } }
    case 'INPUT_PROGNOSTICO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, tipoProgDesfavoravel: action.payload } }
    case 'INPUT_DESCRICAO_PROGNOSTICO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, descricaoProgDesfavoravel: action.payload } }
    case 'INPUT_DURACAO_ALTERACOES':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, idTipoDuracaoAltConstatada: action.payload } }
    case 'INPUT_IMPEDIMENTO_LONGO_PRAZO':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, idTipoImpedimentoLongoPrazo: action.payload } }
    case 'INPUT_DESCRICAO_DURACAO_ALTERACOES':
      return { ...state, moduloEstruturaPrognostico: { ...state.moduloEstruturaPrognostico, descricaoAltConstada: action.payload } }
    case 'INPUT_CAUSAS_DEFICIENCIA':
      return { ...state, moduloImpedRiscoSocial: { ...state.moduloImpedRiscoSocial, causasDeficiencia: action.payload } }
    case 'INPUT_OUTRA_CAUSA_DEFICIENCIA':
      return { ...state, moduloImpedRiscoSocial: { ...state.moduloImpedRiscoSocial, outraCausaDeficiencia: action.payload } }
    case 'INPUT_EXISTE_RISCO_SOCIAL':
      return { ...state, moduloImpedRiscoSocial: { ...state.moduloImpedRiscoSocial, existeRiscoSocial: action.payload } }
    case 'INPUT_RISCO_SOCIAL':
      return { ...state, moduloImpedRiscoSocial: { ...state.moduloImpedRiscoSocial, riscoSocial: action.payload } }
    case 'ALTERAR_JUSTIF_DOMINIO_FUNC_CORPO_ZERADO':
      return { ...state, resultadoParcialBPC: { ...state.resultadoParcialBPC, justificativaDominiosFuncoesCorpoComValorZero: action.payload } }
    case 'ALTERAR_JUSTIF_DOMINIO_ATIVIDADES_PARTICIPACAO_ZERADO':
      return { ...state, resultadoParcialBPC: { ...state.resultadoParcialBPC, justificativaDominiosAtividadeParticipacaoValorZero: action.payload } }  
    case 'INPUT_OBSERVACAO_AVALIADOR':
      return { ...state, resultadoParcialBPC: { ...state.resultadoParcialBPC, observacoesAvaliador: action.payload } }
    case 'INPUT_JUSTIFICATIVA_CONCLUSAO':
      return { ...state, moduloConclusaoPericia: { ...state.moduloConclusaoPericia, justificativaConclusao: action.payload } }
    case 'INPUT_GRAU_DEPENDENCIA':
      return { ...state, moduloConclusaoPericia: { ...state.moduloConclusaoPericia, tipoGrauDependencia: action.payload } }
    case 'INPUT_CAMPO_GENERICO': {
      const { idCampoGenerico, siglaCampo, valor } = action.payload;
      const valorNulo = !valor || valor.trim().length === 0;
      const index = state.valoresCamposGenericos.findIndex((item) => item.idCampoGenerico === idCampoGenerico);
      let novosValores = state.valoresCamposGenericos.slice();

      if (index !== -1) {
        if (valorNulo) { //deve remover campo da lista, pois esta com valor nulo
          novosValores.splice(index, 1);
        } else { //deve atualizar valor do campo que ja existe na lista
          novosValores[index] = { ...novosValores[index], valorTextoCampoGenerico: valor };
        }
      } else if (!valorNulo) { //deve incluir valor do campo que ainda nao existia na lista
        novosValores.push({ idCampoGenerico: idCampoGenerico, siglaCampoGenerico: siglaCampo, valorTextoCampoGenerico: valor });
      }

      return { ...state, valoresCamposGenericos: novosValores }
    }
    case 'INPUT_CAMPO_GENERICO_DATA': {
      let { idCampoGenerico, siglaCampo, valor } = action.payload;
      const valorNulo = !valor;
      const index = state.valoresCamposGenericos.findIndex((item) => item.idCampoGenerico === idCampoGenerico);
      let novosValores = state.valoresCamposGenericos.slice();
      valor = !valorNulo ? formatarDateTimeISOToDataBr(valor).toString() : valor;

      if (index !== -1) {
        if (valorNulo) { //deve remover campo da lista, pois esta com valor nulo
          novosValores.splice(index, 1);
        } else { //deve atualizar valor do campo que ja existe na lista
          novosValores[index] = { ...novosValores[index], valorTextoCampoGenerico: valor };
        }
      } else if (!valorNulo) { //deve incluir valor do campo que ainda nao existia na lista
        novosValores.push({ idCampoGenerico: idCampoGenerico, siglaCampoGenerico: siglaCampo, valorTextoCampoGenerico: valor });
      }
      return { ...state, valoresCamposGenericos: novosValores }
    }
    case 'INPUT_ALTERACAO_ISENCAO_CARENCIA_GESTACAO_RISCO': {
      let valoresModuloIsencaoCarencia = {
        ...state.moduloIsencaoCarencia, 
        isentaCarenciaPorGestacaoRisco: action.payload
      }
      if (action.payload === 1) {
        valoresModuloIsencaoCarencia = {
          ...valoresModuloIsencaoCarencia,
          isentaCarencia: 1,
          tipoDoencaIsencao: undefined
        }
      } else {
        valoresModuloIsencaoCarencia = {
          ...valoresModuloIsencaoCarencia,
          isentaCarencia: 0,
          tipoDoencaIsencao: undefined
        }
      }
      return { ...state, 
        moduloIsencaoCarencia: valoresModuloIsencaoCarencia 
      }
    }
    case 'INPUT_ALTERACAO_ISENCAO_CARENCIA_JUSTIFICATIVA_GESTACAO_RISCO': {
      return { ...state, moduloIsencaoCarencia: { ...state.moduloIsencaoCarencia, justificativaGestacaoAltoRisco: action.payload } }
    }
    case 'INPUT_ALTERACAO_ISENCAO_CARENCIA': {
      return { ...state, moduloIsencaoCarencia: { ...state.moduloIsencaoCarencia, isentaCarencia: action.payload } }
    }
    case 'INPUT_ALTERACAO_DOENCA_ISENCAO': {
      return { ...state, moduloIsencaoCarencia: { ...state.moduloIsencaoCarencia, tipoDoencaIsencao: action.payload } }
    }
    case 'INPUT_ALTERACAO_EXISTE_NEXO_PROFISSIONAL': {
      const alteraIsencaoCarencia = action.payload === 1;
      
      const cidPrincipalEmGrupoO = state.moduloCid && verificaCidEmGrupo(state.moduloCid.cidPrincipal, "O");
      let alteracaoModuloIsencaoCarencia = { ...state.moduloIsencaoCarencia }

      if (alteraIsencaoCarencia) { //SIM para nexo isenta carencia
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, isentaCarencia: 1};
      }

      if (cidPrincipalEmGrupoO && alteraIsencaoCarencia) {
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, 
          isentaCarenciaPorGestacaoRisco: undefined, 
          justificativaGestacaoAltoRisco: undefined
        }
      }

      return {
        ...state,
        moduloNexoTecnico: { ...state.moduloNexoTecnico, existeNexoProfissional: action.payload },
        moduloIsencaoCarencia: alteracaoModuloIsencaoCarencia
      }
    }
    case 'INPUT_FATORES_RISCO_PROFISSIONAL':
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, fatoresRiscoProfissional: action.payload } }
    case 'INPUT_TIPO_DOENCA_NEXO_PROFISSIONAL':
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, tipoDoencaNexoProfissional: action.payload } }
    case 'INPUT_FUNDAMENTACAO_NEXO_PROFISSIONAL': {
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, fundamentacaoNexoProfissional: action.payload } }
    }
    case 'INPUT_JUSTIF_NEGACAO_NEXO_PROFISSIONAL': {
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, justifNegacaoNexoProfissional: action.payload } }
    }
    case 'INPUT_FUNDAMENTACAO_NEXO_INDIVIDUAL': {
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, fundamentacaoNexoIndividual: action.payload } }
    }
    case 'INPUT_ALTERACAO_EXISTE_NEXO_INDIVIDUAL': {
      const alteraIsencaoCarencia = action.payload === 1;
      
      const cidPrincipalEmGrupoO = state.moduloCid && verificaCidEmGrupo(state.moduloCid.cidPrincipal, "O");
      let alteracaoModuloIsencaoCarencia = { ...state.moduloIsencaoCarencia }

      if (alteraIsencaoCarencia) { //SIM para nexo isenta carencia
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, isentaCarencia: 1};
      }

      if (cidPrincipalEmGrupoO && alteraIsencaoCarencia) {
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, 
          isentaCarenciaPorGestacaoRisco: undefined, 
          justificativaGestacaoAltoRisco: undefined
        }
      }

      return {
        ...state,
        moduloNexoTecnico: { ...state.moduloNexoTecnico, existeNexoIndividual: action.payload },
        moduloIsencaoCarencia: alteracaoModuloIsencaoCarencia
      }
    }
    case 'INPUT_TIPO_NEXO_INDIVIDUAL': {
      return { ...state, moduloNexoTecnico: { ...state.moduloNexoTecnico, tipoNexoIndividual: action.payload } }
    }
    case 'INPUT_EXISTE_NTEP': {
      const alteraIsencaoCarencia = action.payload === 1;

      const cidPrincipalEmGrupoO = state.moduloCid && verificaCidEmGrupo(state.moduloCid.cidPrincipal, "O");
      let alteracaoModuloIsencaoCarencia = { ...state.moduloIsencaoCarencia }

      if (alteraIsencaoCarencia) { //SIM para nexo isenta carencia
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, isentaCarencia: 1};
      }

      if (cidPrincipalEmGrupoO && alteraIsencaoCarencia) {
        alteracaoModuloIsencaoCarencia = {...alteracaoModuloIsencaoCarencia, 
          isentaCarenciaPorGestacaoRisco: undefined, 
          justificativaGestacaoAltoRisco: undefined
        }
      }

      return {
        ...state,
        moduloNexoTecnico: { ...state.moduloNexoTecnico, existeNtep: action.payload },
        moduloIsencaoCarencia: alteracaoModuloIsencaoCarencia
      }
    }
    case 'INPUT_MOTIVO_NAO_FIXACAO_NTEP': {
      const { idVinculo, value } = action.payload;
      const index = state.vinculosPericia.findIndex(v => v.id === parseInt(idVinculo));
      let vinculosAtualizados = state.vinculosPericia.slice();

      if (index !== -1) {
        vinculosAtualizados[index] = {
          ...vinculosAtualizados[index],
          descaracterizacaoNtep: { ...vinculosAtualizados[index].descaracterizacaoNtep, motivoNaoFixacaoNtep: parseInt(value) }
        }
      }

      return { ...state, vinculosPericia: vinculosAtualizados }
    }
    case 'INPUT_FUNDAMENTACAO_NAO_FIXACAO_NTEP': {
      const { idVinculo, value } = action.payload;
      const index = state.vinculosPericia.findIndex(v => v.id === parseInt(idVinculo));
      let vinculosAtualizados = state.vinculosPericia.slice();

      if (index !== -1) {
        vinculosAtualizados[index] = {
          ...vinculosAtualizados[index],
          descaracterizacaoNtep: { ...vinculosAtualizados[index].descaracterizacaoNtep, fundamentacaoNaoFixacaoNtep: value }
        }
      }

      return { ...state, vinculosPericia: vinculosAtualizados }
    }
    case 'INPUT_INCAPACIDADE_VINCULO': {
      const { item, value } = action.payload;
      let vinculosPericia = state.vinculosPericia;
      let moduloNexoTecnico = state.moduloNexoTecnico;

      vinculosPericia.forEach(v => {
        if (v.id === parseInt(item)) {
          v.possuiIncapacidade = value;

          //limpar dados NTEP, se houver modulo no template
          if (action.deveLimparDadosNtep) {
            v.tipoNexoTecnico = undefined;
            v.possuiNexoTecnico = undefined;
            v.descaracterizacaoNtep = undefined;

            moduloNexoTecnico = { ...moduloNexoTecnico, existeNtep: undefined };
          }
          return true;
        }
      });
      return { ...state, vinculosPericia, moduloNexoTecnico: moduloNexoTecnico };
    }
    case 'INPUT_INCAPACIDADE_SEM_VINCULOS_RELACIONADOS': {
      return { ...state, incapacidadeSemVinculos: action.payload}
    }
    case 'INPUT_NEXO_VINCULO': {
      const { item, checked, tipoNexo } = action.payload;
      const value = checked ? 1 : 0;
      const index = state.vinculosPericia.findIndex(v => v.id === parseInt(item));
      let vinculosAtualizados = state.vinculosPericia.slice();
      if (index !== -1) {
        vinculosAtualizados[index] = {
          ...vinculosAtualizados[index],
          possuiNexoTecnico: value,
          tipoNexoTecnico: (value === 1 ? parseInt(tipoNexo) : null) //so deve preencher tipo se existe nexo
        }
      }
      return { ...state, vinculosPericia: vinculosAtualizados }
    }
    case 'EXIBIR_DIALOG_ALTERAR_DID_OU_DII':
      return { ...state, deveConfirmarAlteracaoDIDOuDII: true, validacaoDIDOuDIINexo: action.payload }
    case 'OCULTAR_DIALOG_ALTERAR_DID_OU_DII':
      return { ...state, deveConfirmarAlteracaoDIDOuDII: false, validacaoDIDOuDIINexo: undefined }
    case 'LIMPAR_VINCULOS_NEXO_TECNICO': {
      const vinculosSemRelacionamentoNexo = state.vinculosPericia;
      vinculosSemRelacionamentoNexo.forEach(v => {
        v.possuiNexoTecnico = null;
        v.tipoNexoTecnico = null;
      });

      return {
        ...state,
        moduloNexoTecnico: { id: state.moduloNexoTecnico.id, fatoresRiscoProfissional: [] },
        vinculosPericia: vinculosSemRelacionamentoNexo
      }
    }
    case 'INPUT_ALTERACAO_SISTEMA_EXAMINADO': {
      const { idSistema, value, descricaoPadrao } = action.payload;
      const index = state.examesSistemaCorpo?.findIndex(v => v.idSistema === parseInt(idSistema));
      let examesAtualizados = state.examesSistemaCorpo.slice();

      if (index !== -1) {
        const deveIncluirDescricaoPadrao = value === 1 && !examesAtualizados[index].descricao;
        //deve atualizar valor do exame que ja existe na lista
        examesAtualizados[index] = {
          ...examesAtualizados[index],
          examinado: value,
          descricao: deveIncluirDescricaoPadrao ? descricaoPadrao : examesAtualizados[index].descricao
        };
      } else { //deve incluir valor do exame que ainda nao existia na lista
        examesAtualizados.push({
          idSistema: idSistema,
          examinado: value,
          descricao: value === 1 ? descricaoPadrao : undefined
        });
      }

      return { ...state, examesSistemaCorpo: examesAtualizados }
    }
    case 'INPUT_DESCRICAO_SISTEMA_EXAMINADO': {

      const { idSistema, value } = action.payload;
      const index = state.examesSistemaCorpo?.findIndex(v => v.idSistema === parseInt(idSistema));
      let examesAtualizados = state.examesSistemaCorpo.slice();

      if (index !== -1) {
        //deve atualizar descricao do exame que ja existe na lista
        examesAtualizados[index] = { ...examesAtualizados[index], descricao: value };
      } else { //deve incluir descricao do exame que ainda nao existia na lista
        examesAtualizados.push({ idSistema: idSistema, descricao: value });
      }

      return { ...state, examesSistemaCorpo: examesAtualizados }
    }
    case 'INPUT_TIPO_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, tipoGrauIncapacidade: action.payload }
      }
    }
    case 'INPUT_DCB_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, 
          dataCessacaoBeneficio: action.payload.value, 
          alterouDataCessacaoBeneficioAutomaticamente: action.payload.alteracaoAutomatica }
      }
    }
    case 'INPUT_INDICADOR_AUX_ACIDENTE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, indAuxilioAcidente: parseInt(action.payload), enquadramentoAuxilioAcidente: null, situacaoEnquadramentoAuxilioAcidente: null, justParcialComIndicAuxilioAcidente: null }
      }
    }
    case 'INPUT_JUST_AUX_ACID_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, justParcialComIndicAuxilioAcidente: action.payload }
      }
    }
    case 'INPUT_JUST_DCI_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, justTotalComIndicReabProfissional: action.payload }
      }
    }
    case 'INPUT_JUST_LI_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, justTotalSemIndicReabProfissional: action.payload }
      }
    }
    case 'INPUT_ISENCAO_IR_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: {
          ...state.moduloGrauIncapacidade,
          doencaIsentaImpostoRenda: +action.payload,
          tipoIsencaoImpostoRenda: +action.payload === 0 ? null : state.moduloGrauIncapacidade.tipoIsencaoImpostoRenda
        }
      }
    }
    case 'INPUT_MAJORACAO_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: {
          ...state.moduloGrauIncapacidade,
          majoracao25: +action.payload,
          tipoMajoracao25: +action.payload === 0 ? null : state.moduloGrauIncapacidade.tipoMajoracao25
        }
      }
    }
    case 'INPUT_PASSIVEL_REAVALIACAO_2_ANOS_GRAU_INCAPACIDADE': {
      return {
        ...state,
        moduloGrauIncapacidade: {
          ...state.moduloGrauIncapacidade,
          passivelReavaliacao2anos: +action.payload
        }
      }
    }
    case 'INPUT_DCI': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, dataComprovacaoIncapacidade: action.payload }
      }
    }
    case 'INPUT_TIPO_ENQUAD_AUX_ACID': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, enquadramentoAuxilioAcidente: action.payload, situacaoEnquadramentoAuxilioAcidente: null }
      }
    }
    case 'INPUT_TIPO_SIT_ENQUAD_AUX_ACID': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, situacaoEnquadramentoAuxilioAcidente: action.payload }
      }
    }
    case 'INPUT_TIPO_MOTIVO_ISENCAO_IR': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, tipoIsencaoImpostoRenda: action.payload }
      }
    }
    case 'INPUT_TIPO_MOTIVO_MAJORACAO_25': {
      return {
        ...state,
        moduloGrauIncapacidade: { ...state.moduloGrauIncapacidade, tipoMajoracao25: action.payload }
      }
    }
    case 'INPUT_EXISTE_AGRAVAMENTO_LESAO': {
      return {
        ...state,
        moduloRestabelecimentoBeneficio: {
          ...state.moduloRestabelecimentoBeneficio,
          existeAgravamento: action.payload,
          idBeneficioAgravamento: action.idBeneficio
        }
      }
    }
    case 'INPUT_EXISTE_RESTABELECIMENTO': {
      return {
        ...state,
        moduloRestabelecimentoBeneficio: {
          ...state.moduloRestabelecimentoBeneficio,
          existeRestabelecimento: action.payload,
          idBeneficioRestabelecimento: action.idBeneficio
        }
      }
    }
    case 'ATUALIZAR_DADOS_CONCLUSAO_REVISAO': {
      return {
        ...state,
        dadosConclusaoRevisao: action.payload
      }
    }
    default:
      return state;
  }
};
