import { obterMapRespostasGrupos, obterMapRespostasPerguntas } from "../../../../components/ModuloFormulario/utils";
import { obterFomularioAtividadesEParticipacao } from "../../templatePericiaUtil";
import { isGruposComValorDominioZerado } from '../AtividadesEParticipacao'
import { MSG_CAMPO_OBRIGATORIO } from '../../../../common/Messages';
import { validarValorMinimo } from '../../../../common/ValidationUtil';


export const verificarSeCamposPreenchidos = (pericia, getState) => {
  const respostasPerguntaForm = pericia?.respostasPerguntaForm;
  const respostasGrupoForm = pericia?.respostasGrupoForm;
  const formAtividadesParticipacao = obterFomularioAtividadesEParticipacao(getState().principal.template);
  const gruposAtividadesParticipacaoComResposta = formAtividadesParticipacao && formAtividadesParticipacao.gruposFormulario.filter(g =>
    respostasGrupoForm.find((res) => res.idGrupoFormulario === g.id));
  const perguntasAtividadesParticipacaoComResposta = respostasPerguntaForm && respostasPerguntaForm.filter(r =>
    formAtividadesParticipacao.gruposFormulario.find(grupo => {
      const idsPerguntasGrupo = grupo.gruposFormPerguntas.map(pergunta => pergunta.id);
      return idsPerguntasGrupo.includes(r.idPerguntaFormulario);
    })
  );
  const deveValidarAtividadesEParticipacao = (gruposAtividadesParticipacaoComResposta && gruposAtividadesParticipacaoComResposta.length > 0)
    || (perguntasAtividadesParticipacaoComResposta && perguntasAtividadesParticipacaoComResposta.length > 0);

  return deveValidarAtividadesEParticipacao;
}

export const validar = (pericia, getState) => {
  const QTD_MINIMA_CARACTERES = 50;
  const justificativaDominiosAtividadeParticipacaoValorZero = pericia?.resultadoParcialBPC?.justificativaDominiosAtividadeParticipacaoValorZero?.trim();
  let respostasPerguntas = pericia.respostasPerguntaForm;
  let respostasGrupos = pericia.respostasGrupoForm;
  let mapRespostasPerguntas = obterMapRespostasPerguntas(respostasPerguntas);
  let mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupos);

  let formulario = obterFomularioAtividadesEParticipacao(getState().principal.template);

  let gruposComPerguntasSemRespostas = formulario.gruposFormulario.filter((grupoFormulario) => {
    let perguntasHabilitadasSemResposta = grupoFormulario.gruposFormPerguntas &&
      grupoFormulario.gruposFormPerguntas.filter((pergunta) => pergunta.habilitado &&
        (!mapRespostasPerguntas.get(pergunta.id) || mapRespostasPerguntas.get(pergunta.id).qualificador === undefined));
    return perguntasHabilitadasSemResposta.length > 0;
  });

  let gruposComDominioSemResposta = formulario.gruposFormulario.filter((grupoFormulario) => !mapRespostasGrupos.get(grupoFormulario.id) || mapRespostasGrupos.get(grupoFormulario.id).qualificador === undefined);
  gruposComDominioSemResposta = gruposComDominioSemResposta.filter(g => gruposComPerguntasSemRespostas.findIndex(g2 => g2.id === g.id) === -1);

  const todosGruposComValorDominioZerado = isGruposComValorDominioZerado(formulario.gruposFormulario, obterMapRespostasGrupos(respostasGrupos))
  let erroJustificativaObrigatoriaGruposComValorDominioZerado = todosGruposComValorDominioZerado
    && (!pericia.resultadoParcialBPC || !pericia.resultadoParcialBPC.justificativaDominiosAtividadeParticipacaoValorZero);
  
  let erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima = todosGruposComValorDominioZerado 
    && !validarValorMinimo(justificativaDominiosAtividadeParticipacaoValorZero?.length, parseInt(QTD_MINIMA_CARACTERES));

  return {
    isValida: gruposComPerguntasSemRespostas.length === 0 &&
      gruposComDominioSemResposta.length === 0 &&
      !erroJustificativaObrigatoriaGruposComValorDominioZerado && !erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima,
    erroJustificativaObrigatoriaGruposComValorDominioZerado: erroJustificativaObrigatoriaGruposComValorDominioZerado && MSG_CAMPO_OBRIGATORIO, 
    erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima: erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima &&
     `O campo deve ser preenchido com no mínimo ${QTD_MINIMA_CARACTERES} caracteres`,
    gruposComErroDominio: [
      ...(gruposComDominioSemResposta &&
        gruposComDominioSemResposta.map(g => (
          {
            id: g.id,
            ordem: g.ordemExibicao,
            erro: `Informe o qualificador para o domínio <strong>${g.indice} - ${g.dominio}</strong>`
          }))),
      ...(gruposComPerguntasSemRespostas &&
        gruposComPerguntasSemRespostas.map(g => (
          {
            id: g.id,
            ordem: g.ordemExibicao,
            erro: `Qualifique todas as unidades de classificação para o domínio <strong>${g.indice} - ${g.dominio}</strong>`
          }
        )))
    ]
  };
}
