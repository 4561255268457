
import React from 'react';

import { Button } from '@material-ui/core';

const styleButton = {
  backgroundColor: '#E3E8E3',
  borderRadius: 'unset',
  minHeight: '57px',
  padding: '0px'
}

const styleButtonSelected = {
  backgroundColor: '#7BAE94'
}

const styleButtonDisabled = {
  cursor: 'not-allowed',
  opacity: '0.8'
}

const ButtonCheck = (props) => (
  <Button
    style={{
      ...styleButton,
      ...(props.disabled) ? styleButtonDisabled : {},
      ...(props.selected) ? styleButtonSelected : {}
    }}
    disableElevation
    fullWidth
    disabled={props.disabled}
    onClick={() => props.handleChange()}
  >
    {props.selected ?
      <span style={{ color: 'white', fontWeight: 'bold' }}>Não</span> :
      <span style={{ color: 'white' }}>Não</span>
    }
  </Button>
);

export default ButtonCheck;

