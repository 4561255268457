  
const INITIAL_STATE = {
    filtro: {}
  }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LIMPAR_CAMPOS_CONSULTA_TEMPLATE':
            return INITIAL_STATE;
        case 'ALTERAR_TIPO_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, tipoConsulta: action.payload}}
        case 'ALTERAR_ID_PERICIA_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, idPericia: action.payload}}
        case 'ALTERAR_ID_TEMPLATE_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, idTemplate: action.payload}}
        case 'ALTERAR_ID_TEMPLATE_ORIGEM_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, idTemplateOrigem: action.payload}}
        case 'ALTERAR_TIPO_PERICIA_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, tipoPericia: action.payload}}
        case 'ALTERAR_DATA_REFERENCIA_CONSULTA_TEMPLATE':
            return {...state, filtro: {...state.filtro, dataReferencia: action.payload}}
        case 'ATUALIZAR_DADOS_VALIDACAO_CONSULTA_TEMPLATE':
            return {...state, validacao: {...action.payload} }
        case 'ATUALIZAR_DADOS_CONSULTA_TEMPLATE':
            return {...state, template: {...action.payload} }
        case 'EXIBIR_DIALOG_TEMPLATE':
            return {...state, isExibirTemplate: true }
        case 'OCULTAR_DIALOG_TEMPLATE':
            return {...state, isExibirTemplate: false }
        default:
            return state;
        }
};
      