export default (state = {}, action) => {
  switch (action.type) {
    case 'CARREGAR_HTML_RELATORIO_PERICIA': {  
      return { ...state, html: action.payload }
    }
    case 'LIMPAR_HTML_RELATORIO_PERICIA': {
        return { ...state, html: null }
      }        
    default:
      return state;
  }
};