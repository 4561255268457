import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {

  const moduloDocApresentado = pericia?.moduloDocApresentado;

  const deveValidarDocumentosApresentados = moduloDocApresentado
    && (moduloDocApresentado.tipoDocumentoCivilAvaliado || moduloDocApresentado.numeroDocAvaliado || moduloDocApresentado.complementoDocAvaliado
      || moduloDocApresentado.nomeAcompanhante || moduloDocApresentado.tipoDocumentoCivilAcompanhante || moduloDocApresentado.numeroDocAcompanhante
      || moduloDocApresentado.complementoDocAcompanhante);

  return deveValidarDocumentosApresentados;
}

export const validar = pericia => {

  const moduloDocApresentado = pericia?.moduloDocApresentado;

  const { tipoDocumentoCivilAvaliado, numeroDocAvaliado,
    tipoDocumentoCivilAcompanhante, numeroDocAcompanhante, nomeAcompanhante, complementoDocAcompanhante } = moduloDocApresentado;

  const erroTipoDocAvaliadoObrigatorio = tipoDocumentoCivilAvaliado ? null : MSG_CAMPO_OBRIGATORIO;

  const erroNumeroDocAvaliado = numeroDocAvaliado ? null : MSG_CAMPO_OBRIGATORIO;
  const erroNumeroDocAcompanhante = tipoDocumentoCivilAcompanhante && !numeroDocAcompanhante ? MSG_CAMPO_OBRIGATORIO : null;
  const erroTipoDocAcompanhante = numeroDocAcompanhante && !tipoDocumentoCivilAcompanhante ? MSG_CAMPO_OBRIGATORIO : null;
  const erroNomeAcompanhante = (numeroDocAcompanhante || tipoDocumentoCivilAcompanhante || complementoDocAcompanhante)
    && (!nomeAcompanhante || nomeAcompanhante.trim().length === 0)
    ? "Campo obrigatório se documento do acompanhante preenchido (Tipo, Número ou Complemento)"
    : null;

  return {
    isValida: !erroTipoDocAvaliadoObrigatorio && !erroNumeroDocAvaliado
      && !erroNumeroDocAcompanhante && !erroTipoDocAcompanhante && !erroNomeAcompanhante,
    erroTipoDocAvaliado: erroTipoDocAvaliadoObrigatorio,
    erroNumeroDocAvaliado,
    erroNumeroDocAcompanhante,
    erroTipoDocAcompanhante,
    erroNomeAcompanhante
  };
};