import React from "react";
import { Grid } from '@material-ui/core';

import TabelaVinculosNTEP from "./tabelaVinculosNTEP";
import BrCard from '@govbr/react-components/lib/BrCard';

const DetalheNTEP = ({ vinculos }) => {

  return (
    <>
      <Grid item xs={12} >
        <BrCard>
          <Grid container spacing={1}>
              <Grid item xs={12}>
                <span className="texto-informativo">{vinculos.nomeCampo}</span>
              </Grid>
              <Grid item xs={12}>
                <p className="ml-10">
                  Antes da revisão: 
                  {(!vinculos.valorOrigem ||
                    vinculos.valorOrigem.length === 0) &&
                      <span className="texto-informativo ml-10 valor-anterior-revisao">---</span>
                  }
                </p>

                {(vinculos.valorOrigem &&
                  vinculos.valorOrigem.length > 0) &&
                  <TabelaVinculosNTEP vinculos={vinculos.valorOrigem} isValorAtual={false}/>
                }

              </Grid>

              <Grid item xs={12} >
                <p className="ml-10">
                  Após revisão: 
                  {(!vinculos.valorAtual ||
                    vinculos.valorAtual.length === 0) &&
                      <span className="texto-informativo ml-10 valor-atual-revisao">---</span>
                  }
                </p>

                {(vinculos.valorAtual &&
                  vinculos.valorAtual.length > 0) &&
                  <TabelaVinculosNTEP vinculos={vinculos.valorAtual} isValorAtual={true}/>
                }

              </Grid>
          </Grid>
        </BrCard>
      </Grid>

    </>);
};


export default DetalheNTEP;

