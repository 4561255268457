import {
  convertStringToDate,
  formatarDateTimeISOToDataBr, isData1EhAntesOuIgualData2, isData1EhDepoisData2, isData1EhDepoisOuIgualData2
} from "../../../../common/DataUtils";
import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";
import { SIGLA_CAMPO_DII, tipoSimNao, tiposParametrosModuloEstatico } from '../../constants';
import { obterDataParametroPeriodo, obterValorParametroModuloEstatico } from '../../templatePericiaUtil';
import { obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE, obtemSituacaoBeneficioAnteriorNaoIndicaDCA } from "../GrauIncapacidade/validacao";
import { TIPOS_GRAU_INCAPACIDADE_RBI, TIPOS_GRAU_INVALIDEZ_RBI } from "./utils";

const camposModuloGrauIncapacidade = pericia => {
  return {
    tipoGrauIncapacidade: pericia?.moduloGrauIncapacidade?.tipoGrauIncapacidade,
    dataCessacaoBeneficio: pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio,
    justTotalComIndicReabProfissional: pericia?.moduloGrauIncapacidade?.justTotalComIndicReabProfissional,
    justTotalSemIndicReabProfissional: pericia?.moduloGrauIncapacidade?.justTotalSemIndicReabProfissional,
    majoracao25: pericia?.moduloGrauIncapacidade?.majoracao25,
    passivelReavaliacao2anos: pericia?.moduloGrauIncapacidade?.passivelReavaliacao2anos,
    tipoMajoracao25: pericia?.moduloGrauIncapacidade?.tipoMajoracao25,
    dataComprovacaoIncapacidade: pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade,
    drePericiaAtual: new Date(),
  }
}

export const verificarSeCamposPreenchidos = pericia => {

  const campos = camposModuloGrauIncapacidade(pericia);

  if (!campos.tipoGrauIncapacidade) { return false; }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.TEMPORARIA_COM_RECUPERACAO === campos.tipoGrauIncapacidade) {
    if (!campos.dataCessacaoBeneficio) { return false; }
  }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade
    || TIPOS_GRAU_INVALIDEZ_RBI.ELEGIVEL_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    if (!campos.dataComprovacaoIncapacidade) { return false; }
    if (!campos.justTotalComIndicReabProfissional) { return false; }
  }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    if (!campos.majoracao25) { return false; }
    if (campos.tipoMajoracao25 === undefined) { return false; }
    if (!campos.justTotalSemIndicReabProfissional) { return false; }
  }

  if (TIPOS_GRAU_INVALIDEZ_RBI.MANTEM_INVALIDEZ=== campos.tipoGrauIncapacidade) {
    if (!campos.passivelReavaliacao2anos) { return false; }
    if (!campos.majoracao25) { return false; }
    if (campos.tipoMajoracao25 === undefined) { return false; }
    if (!campos.justTotalSemIndicReabProfissional) { return false; }
  }

  return true;
}

export const validar = (pericia, getState) => {


  const campos = camposModuloGrauIncapacidade(pericia);
  const template = getState().principal.template;
  
  let invalidado = {
    isValida: true
  }

  if (!campos.tipoGrauIncapacidade) {
    invalidado.erroTipoGrauIncapacidade = MSG_CAMPO_OBRIGATORIO;
  }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.TEMPORARIA_COM_RECUPERACAO === campos.tipoGrauIncapacidade) {
    const dataCessacaoBeneficio = convertStringToDate(formatarDateTimeISOToDataBr(campos.dataCessacaoBeneficio));

    if (!dataCessacaoBeneficio || isNaN(dataCessacaoBeneficio)) { invalidado.erroDcbTempComRecupe = MSG_CAMPO_OBRIGATORIO; }
    else {

      if (!isData1EhDepoisData2(dataCessacaoBeneficio, campos.drePericiaAtual)) {
        const beneficioAnteriorPossuiDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);
        const beneficioAnteriorNaoIndicaDCA = obtemSituacaoBeneficioAnteriorNaoIndicaDCA(pericia);
        if (beneficioAnteriorPossuiDCAIgualOuMaiorDRE || beneficioAnteriorNaoIndicaDCA) {
          invalidado.erroDcbTempComRecupe = "A Data de Cessação do Benefício (DCB) deve ser maior que a Data da Realização do Exame (DRE).";
        } else {
          const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
          let dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);
  
          if (!dii && pericia.numeroBeneficio) {
            const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
            dii = convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataInicioIncapacidade));
          }
  
          if (dii && !isData1EhDepoisOuIgualData2(dataCessacaoBeneficio, dii)) {
            invalidado.erroDcbTempComRecupe = `A Data Limite da Elegibilidade deve ser maior ou igual à Data de Início da Incapacidade (DII).`;
          }
        }
      } else {

        const valorParametroMaxDCB = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_MAX_DCB_RBI.id);
        if (!valorParametroMaxDCB) {
          throw new Error(`É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_MAX_DCB_RBI.nome}' no template da perícia.`)
        } else {
          const dataMaximaDCB = obterDataParametroPeriodo(valorParametroMaxDCB, campos.drePericiaAtual, false);

          if (!isData1EhAntesOuIgualData2(dataCessacaoBeneficio, dataMaximaDCB)) {
            invalidado.erroDcbTempComRecupe = `Não é possível informar Data de Cessação do Benefício (DCB) com mais de ${obterDescricaoParametroPeriodo(valorParametroMaxDCB)} da data atual.`;
          } 

          const erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef = obterErroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef(pericia);
          if(erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef) {
            invalidado.erroDcbTempComRecupe = erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef;
          }
        }
      }
    }
  }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade
    || TIPOS_GRAU_INVALIDEZ_RBI.ELEGIVEL_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    const dataComprovacaoIncapacidade = convertStringToDate(formatarDateTimeISOToDataBr(campos.dataComprovacaoIncapacidade));

    if (!dataComprovacaoIncapacidade || isNaN(dataComprovacaoIncapacidade)) {
      invalidado.erroDataComprovacaoIncapacidade = MSG_CAMPO_OBRIGATORIO;
    } else {

      if (!isData1EhDepoisOuIgualData2(dataComprovacaoIncapacidade, campos.drePericiaAtual)) {
        const beneficioAnteriorPossuiDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);
        const beneficioAnteriorNaoIndicaDCA = obtemSituacaoBeneficioAnteriorNaoIndicaDCA(pericia);
        if (beneficioAnteriorPossuiDCAIgualOuMaiorDRE || beneficioAnteriorNaoIndicaDCA) {
          invalidado.erroDataComprovacaoIncapacidade = "Não é possível informar Data Limite de Elegibilidade menor que a Data da Realização do Exame (DRE).";
        } else {
          const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
          let dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);
  
          if (!dii && pericia.numeroBeneficio) {
            const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
            dii = convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataInicioIncapacidade));
          }
  
          if (dii && !isData1EhDepoisOuIgualData2(dataComprovacaoIncapacidade, dii)) {
            invalidado.erroDataComprovacaoIncapacidade = `A Data Limite da Elegibilidade deve ser maior ou igual à Data de Início da Incapacidade (DII).`;
          }
        }
      } else {

        const valorParametroMaxDCI = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_MAX_DCI_RBI.id);
        if (!valorParametroMaxDCI) {
          throw new Error(`É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_MAX_DCI_RBI.nome}' no template da perícia.`)
        } else {
          const dataMaximaDCI = obterDataParametroPeriodo(valorParametroMaxDCI, new Date(), false);

          if (!isData1EhAntesOuIgualData2(dataComprovacaoIncapacidade, dataMaximaDCI)) {
            invalidado.erroDataComprovacaoIncapacidade = `Não é possível informar Data Limite da Elegibilidade com mais de ${obterDescricaoParametroPeriodo(valorParametroMaxDCI)} da data atual.`;
          }

          const erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef = obterErroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef(pericia);
          if(erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef) {
            invalidado.erroDataComprovacaoIncapacidade = erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef;
          }

        }
      }
    }

    if (!campos.justTotalComIndicReabProfissional || campos.justTotalComIndicReabProfissional.trim().length === 0) { invalidado.erroJustTotalComIndicReabProfissional = MSG_CAMPO_OBRIGATORIO; }
  }

  if (TIPOS_GRAU_INCAPACIDADE_RBI.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade
    || TIPOS_GRAU_INVALIDEZ_RBI.MANTEM_INVALIDEZ === campos.tipoGrauIncapacidade) {
    if (TIPOS_GRAU_INVALIDEZ_RBI.MANTEM_INVALIDEZ === campos.tipoGrauIncapacidade && campos.passivelReavaliacao2anos === undefined) { 
        invalidado.erroPassivelReavaliacao2anos = MSG_CAMPO_OBRIGATORIO; 
    }
    if (campos.majoracao25 === undefined) { invalidado.erroMajoracao25 = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.justTotalSemIndicReabProfissional || campos.justTotalSemIndicReabProfissional.trim().length === 0) { invalidado.erroJustTotalSemIndicReabProfissional = MSG_CAMPO_OBRIGATORIO; }
    if (campos.majoracao25 === 1 && !campos.tipoMajoracao25) { invalidado.erroTipoMajoracao25 = MSG_CAMPO_OBRIGATORIO; }
  }

  if (Object.keys(invalidado).some(e => e.includes("erro"))) {
    invalidado = { ...invalidado, isValida: false }
  }

  return invalidado;

}

const obterDescricaoParametroPeriodo = valorParametro => {
  const regex = /[A-Z]/g;
  let indicePeriodo = valorParametro.search(regex);
  let periodo = indicePeriodo >= 0 ? valorParametro.substring(indicePeriodo) : undefined;
  let valorCalculo = periodo ? valorParametro.substring(0, indicePeriodo) : valorParametro;
  let descricao;

  switch (periodo) {
    case 'D':
      descricao = `${valorCalculo} dias`;
      break;
    case 'M':
      descricao = `${valorCalculo} meses`;
      break;
    case 'A':
      descricao = `${valorCalculo} anos`;
      break;
    default:
      break
  }
  return descricao;
}

function obtemDcbBeneficioSeNaoDCA(pericia) {
  if (pericia?.numeroBeneficio) {
    const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
    if (!beneficioAnterior?.indicaDCA || tipoSimNao.NAO.descricao === beneficioAnterior?.indicaDCA) {
      return convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataCessacaoBeneficio));
    }
  }
}

const obterErroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef = pericia => {
  const dcbDaPericia = pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio 
    && convertStringToDate(formatarDateTimeISOToDataBr(
        pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio
      ));
  const dcbDoBenef = obtemDcbBeneficioSeNaoDCA(pericia);

  if(dcbDaPericia && dcbDoBenef && isData1EhAntesOuIgualData2(dcbDaPericia, dcbDoBenef)) {
    return `A 'Data de Cessação do Benefício' (DCB) informada deve ser maior que a data de cessação do benefício atual concedido, ${formatarDateTimeISOToDataBr(dcbDoBenef)}`;
  }
  return null;
}

const obterErroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef = pericia => {
  const dciDaPericia = pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade 
    && convertStringToDate(formatarDateTimeISOToDataBr(
        pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade
      ));
  const dcbDoBenef = obtemDcbBeneficioSeNaoDCA(pericia);

  if(dciDaPericia && dcbDoBenef && isData1EhAntesOuIgualData2(dciDaPericia, dcbDoBenef)) {
    return `A 'Data Limite da Elegibilidade' informada deve ser maior que a data de cessação do benefício atual concedido, ${formatarDateTimeISOToDataBr(dcbDoBenef)}`;
  }
  return null;
}
