import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";

import BrButton from '@govbr/react-components/lib/BrButton';

import {exibirDialogSelecionarItem} from './action';

const ButtonSelecionarItem = props => {

    return (
        <BrButton
            id={props.id}
            circle
            onClick={() => props.exibirDialogSelecionarItem(props.onSelect, props.onSelectProps, props.maxLength, props.valorAtual)}
            >
            <i className={`fas fa-book`} title="Preencher com texto padrão dicionário" />
        </BrButton>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    exibirDialogSelecionarItem
}, dispatch);

ButtonSelecionarItem.propTypes = {
    /** Identificador único do button */
    id: PropTypes.string.isRequired,
    /* Callback acionado para atualizar campo com texto selecionado da biblioteca*/
    onSelect: PropTypes.func.isRequired,
    /* Parametros adicionais a ser passados no callback osSelect, quando necessario*/
    onSelectProps: PropTypes.shape({
        /* id de campo generico ou campo em lista de valores */
        id: PropTypes.number.isRequired,
        /* sigla de campo generico*/
        sigla: PropTypes.string
      }),
    /* determina tamanho maximo da palavra de acordo com o espaço restante no campo, para desabilitar copia de texto que ultrapassa esse tamanho */
    maxLength: PropTypes.number.isRequired,
    /* texto presente no campo que será concatenado com o valor do item do dicionario.*/
    valorAtual: PropTypes.string
}

export default connect(null, mapDispatchToProps)(ButtonSelecionarItem);