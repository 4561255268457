import { solicitarSima, retornarSima } from '../../action';
import { errorMessage, displayLoader } from "../../../principal/action";
import { obterDadosValidacaoSima, validarRetornoSima } from '../../validacaoCampos';
import { tiposSolicitacaoExigencia } from '../../constants';
import { getSeguroPdf } from '../../../../common/requests'

import { removeMask } from '../../../../common/formatadores';
import { obterUsuarioLogado } from '../../../../common/AuthManager';

export const exibirDialogSolicitarSima = value => ({
  type: 'EXIBIR_DIALOG_SOLICITACAO_SIMA',
  payload: value
});

export const exibirDialogRetornoSima = value => ({
  type: 'EXIBIR_DIALOG_RETORNO_SIMA',
  payload: value
});

export const alterarJustificativaSima = value => ({
  type: 'INPUT_JUSTIFICATIVA_SIMA',
  payload: value
});

export const alterarTipoSolicitacao = value => ({
  type: 'INPUT_TIPO_SOLICITACAO_EXIGENCIA',
  payload: value
});

export const cancelarSolicitacaoSima = () => {
  return dispatch => {
    dispatch(alterarJustificativaSima(''));
    dispatch({
      type: 'ATUALIZAR_DADOS_VALIDACAO',
      payload: { sima: {} }
    });
    dispatch(exibirDialogSolicitarSima(false));
  }
};
export const cancelarRetornoSima = (isPendenteSima) => {
  return dispatch => {
    if (isPendenteSima) {
      dispatch({
        type: 'CANCELAR_RETORNO_SIMA'
      });
      dispatch({
        type: 'ATUALIZAR_DADOS_VALIDACAO',
        payload: { retornoSima: undefined }
      });
    }
    dispatch(exibirDialogRetornoSima(false));
  }
};

export const validarESolicitarSima = (justificativa, idPericia, tipoSolicitacaoExigencia) => {
  return async dispatch => {

    const resultado = obterDadosValidacaoSima(justificativa, tipoSolicitacaoExigencia);
    if (resultado) {
      dispatch({
        type: 'ATUALIZAR_DADOS_VALIDACAO',
        payload: { sima: { ...resultado } }
      });

      if (resultado.isValida) {
        let usuarioLogado = obterUsuarioLogado();
        const dadosSolicitacao = {
          idPericia: idPericia,
          cpf: usuarioLogado.cpf,
          nome: usuarioLogado.nome,
          matricula: usuarioLogado.matricula,
          justificativa,
          tipoSolicitacaoExigencia: tipoSolicitacaoExigencia
        };
        dispatch(exibirDialogSolicitarSima(false));
        let sucesso = await dispatch(solicitarSima(dadosSolicitacao));
        if (sucesso) {
          dispatch(downloadSima(idPericia, tipoSolicitacaoExigencia));
        }
      }

    } else {
      dispatch(displayLoader(false));
      dispatch(errorMessage({ message: "Não foi possivel obter dados para solicitação." }, 'Solicitar Dados Exigência'));
    }
  }
};

export const validarERegistrarRetornoSima = (dadosRetorno, idPericia, cpfUsuarioLogado, tipoSolicitacao) => {
  return (dispatch) => {
    const resultado = validarRetornoSima(dadosRetorno, tipoSolicitacao);
    dispatch({
      type: 'ATUALIZAR_DADOS_VALIDACAO',
      payload: { retornoSima: { ...resultado } }
    });
    if (resultado.isValida) {
      const dadosSolicitacao = {
        idPericia: idPericia,
        cpf: removeMask(cpfUsuarioLogado),
        ...dadosRetorno
      };
      dispatch(exibirDialogRetornoSima(false));
      dispatch(retornarSima(dadosSolicitacao));
    }
  }

}

export const alterarNomeProfissional = value => ({
  type: 'INPUT_NOME_PROFISSIONAL_SIMA',
  payload: value
});

export const alterarCrmProfissional = value => ({
  type: 'INPUT_CRM_PROFISSIONAL_SIMA',
  payload: value
});

export const alterarUf = value => ({
  type: 'INPUT_UF_SIMA',
  payload: parseInt(value)
});

export const alterarDataDocumento = value => ({
  type: 'INPUT_DATA_DOCUMENTO_SIMA',
  payload: value
});

export const alterarResultado = value => ({
  type: 'INPUT_RESULTADO_SIMA',
  payload: value
});


export const atualizarRetornoSIMA = (moduloSima) => ({
  type: 'ATUALIZAR_RETORNO_SIMA',
  payload: moduloSima
});


export const downloadSima = (idPericia, tipoSolicitacao) => {
  return dispatch => {
    if(parseInt(tipoSolicitacao) === tiposSolicitacaoExigencia.OUTRAS.id){
      dispatch(displayLoader(true));
      getSeguroPdf(dispatch, `pmf-realizar-pericias-service/pericia/${idPericia}/download-outras-exigencias-pdf`, sucessoDownloadSima, erroDownloadSima, "OutrasExigencias.pdf");
    } else {
      dispatch(displayLoader(true));
      getSeguroPdf(dispatch, `pmf-realizar-pericias-service/pericia/${idPericia}/download-sima-pdf`, sucessoDownloadSima, erroDownloadSima, "sima.pdf");
    }
  };
};

const sucessoDownloadSima = () => ({
  type: 'DOWNLOAD_SIMA_SUCESSO'
})

const erroDownloadSima = (error) => ([
  {
    type: 'DOWNLOAD_SIMA_FALHA'
  },
  errorMessage(error, 'Imprimir Dados da Exigência')
]);

