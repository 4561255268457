import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

import { tiposParametrosModuloEstatico } from '../../constants';
import { obterValorParametroModuloEstatico } from '../../templatePericiaUtil';
import { obterBeneficiosParaAgravamentoLesao, obterBeneficiosParaRestabelecimento } from './utils';

export const verificarSeCamposPreenchidos = pericia => {
  const moduloRestabelecimentoBeneficio = pericia?.moduloRestabelecimentoBeneficio;

  return moduloRestabelecimentoBeneficio && (moduloRestabelecimentoBeneficio.existeAgravamento !== undefined
    || moduloRestabelecimentoBeneficio.existeRestabelecimento !== undefined);
}

export const validar = (pericia, getState) => {

  const moduloRestabelecimentoBeneficio = pericia?.moduloRestabelecimentoBeneficio;

  const template = getState().principal.template;
  const valorParametroPeriodoRestabelecimento = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_RESTABELECIMENTO.id);

  const { batimentoCidAgravamento, batimentoCidRestabelecimento } = getState().modulos.moduloRestabelecimentoBeneficio;
  const listaAA = obterBeneficiosParaAgravamentoLesao(pericia?.beneficiosAnteriores);
  const listaAD = obterBeneficiosParaRestabelecimento(pericia?.beneficiosAnteriores, pericia?.dataReferencia, valorParametroPeriodoRestabelecimento, pericia?.numeroBeneficio);

  const deveValidarAgravamento = listaAA && listaAA.length > 0 && batimentoCidAgravamento && Object.values(batimentoCidAgravamento)[0];
  const deveValidarRestabelecimento = listaAD && listaAD.length > 0 && batimentoCidRestabelecimento && Object.values(batimentoCidRestabelecimento)[0];

  const erroRespostaAgravamentoObrigatoria = (deveValidarAgravamento && moduloRestabelecimentoBeneficio?.existeAgravamento === undefined)
    ? MSG_CAMPO_OBRIGATORIO
    : null;
  const erroRespostaRestabelecimentoObrigatoria = (deveValidarRestabelecimento && moduloRestabelecimentoBeneficio?.existeRestabelecimento === undefined)
    ? MSG_CAMPO_OBRIGATORIO
    : null;

  return {
    isValida: !erroRespostaRestabelecimentoObrigatoria && !erroRespostaAgravamentoObrigatoria,
    erroRespostaAgravamentoObrigatoria,
    erroRespostaRestabelecimentoObrigatoria
  };
}