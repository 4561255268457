import React from "react";

const TituloAba = (props) => {

  return (
    <h5>
      {props.label}
    </h5>
  );
}

export default TituloAba;