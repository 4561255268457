import { SIGLA_CAMPO_DII, tipoCampoExibicaoPericia, tipoCampoValidacaoPericia, tipoOperadorValidacaoCampoGenerico, tipoRegraValidacaoCampoGenerico, 
  tiposCampoGenerico, tipoSimNao } from '../features/pericia/constants';
import { converterDataFormatadaParaISOBr, convertStringToDate } from './DataUtils';
import { somarOuSubtrair } from './Utils';


const operarValidacaoCampoGenerico = (tipoOperacao, nomeCampo, valorCampo, valorCampoOperacao, regra) => {
  let resultado = undefined;
  let nomeValorReferencia = valorCampoOperacao;

   if (regra.campoGenericoReferencia) {
      nomeValorReferencia = regra.campoGenericoReferencia.nome;
  } else if (regra.tipoCampoValidacaoPericia) {
      nomeValorReferencia = regra.tipoCampoValidacaoPericia.descricao;
  }

  switch (tipoOperacao) {
    case tipoOperadorValidacaoCampoGenerico.IGUAL.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo === valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser igual a '" + nomeValorReferencia + "'."
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MAIOR_QUE.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo > valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser maior que '" + nomeValorReferencia + "'."
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MAIOR_OU_IGUAL_QUE.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo >= valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser maior ou igual que '" + nomeValorReferencia + "'."
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MENOR_QUE.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo < valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser menor que '" + nomeValorReferencia + "'."
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MENOR_OU_IGUAL_QUE.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo <= valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser menor ou igual que '" + nomeValorReferencia + "'."
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MENOR_QUE_DATA_ATUAL_MAIS_VALOR_FIXO.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo < valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser menor que a data atual acrescida de '" + nomeValorReferencia + "'.";
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    case tipoOperadorValidacaoCampoGenerico.MAIOR_QUE_DATA_ATUAL_MENOS_VALOR_FIXO.tipo:
      resultado = valorCampo && valorCampoOperacao ? valorCampo > valorCampoOperacao : false;
      if (!resultado) {
        let msg = regra.mensagemRegra ? regra.mensagemRegra : "'" + nomeCampo + "' deve ser maior que a data atual decrescida de '" + nomeValorReferencia + "'.";
        return { valido: false, msg: msg, tipo: regra.tipoMensagem }
      }
      break;
    default:
      break;
  }
  return { valido: true }
}

const obterValorCampoEstatico = (tipoCampo, pericia) => {
  switch (tipoCampo) {
    case tipoCampoValidacaoPericia.DATA_NASCIMENTO_AVALIADO:
      return pericia && pericia.avaliado ? converterDataFormatadaParaISOBr(pericia.avaliado.dataNascimento) : undefined;
    case tipoCampoValidacaoPericia.DATA_REFERENCIA:
      return pericia ? converterDataFormatadaParaISOBr(pericia.dataReferencia) : undefined;
    case tipoCampoValidacaoPericia.INDICADOR_RESTABELECIMENTO_BENEFICIO:
      return pericia?.moduloRestabelecimentoBeneficio?.existeRestabelecimento;
    case tipoCampoValidacaoPericia.DATA_REALIZACAO_PERICIA:
      return pericia?.periciaOrigemRevisao //se revisao, deve usar data conclusao da origem
            ? (pericia?.periciaOrigemRevisao.dataAlteracao ? converterDataFormatadaParaISOBr(pericia?.periciaOrigemRevisao.dataAlteracao) : undefined)
            : new Date();  //demais pericias, deve usar data atual 
    default:
      return undefined;
  }
}

export const obterValorCampoEstaticoRegraExibicao = (tipoCampo, pericia) => {
  switch (tipoCampo) {
    case tipoCampoExibicaoPericia.NUMERO_ESPECIE_BENEFICIO:
      return pericia && pericia.beneficiosAnteriores 
        ? pericia.beneficiosAnteriores.find(b => b.numeroBeneficio === pericia.numeroBeneficio)?.numeroEspecieBeneficio.toString()
        : undefined;
    default:
      return undefined;
  }
}

const obterValorFixo = (regra, dataReferenciaOpcional) => {
  let valorCampo = regra.valorCampo;
  const operador = Object.values(tipoOperadorValidacaoCampoGenerico).find(t => t.tipo === regra.tipoOperador)?.operador;
  const regex = /[A-Z]/g;
  let indicePeriodo = valorCampo.search(regex);
  let periodo = indicePeriodo >= 0 ? valorCampo.substring(indicePeriodo) : undefined;

  if(periodo){
    let valorCalculo = periodo ? valorCampo.substring(0, indicePeriodo) : valorCampo;
    let data = dataReferenciaOpcional ? dataReferenciaOpcional : new Date();
    let resultado = undefined;
    switch (periodo) {
      case 'D':
        resultado = somarOuSubtrair(data.getDate(), valorCalculo, operador);
        data.setDate(resultado);
        return converterDataFormatadaParaISOBr(data);
      case 'M':
        resultado = somarOuSubtrair(data.getMonth(), valorCalculo, operador);
        data.setMonth(resultado);
        return converterDataFormatadaParaISOBr(data);
      case 'A':
        resultado = somarOuSubtrair(data.getFullYear(), valorCalculo, operador);
        data.setFullYear(resultado);
        return converterDataFormatadaParaISOBr(data);
      default:
        break;
    }
  }

  return valorCampo;
}


const tratarCampoPorTipo = (tipo, valorCampo) => {
  switch (tipo) {
    case tiposCampoGenerico.DATA:
      return convertStringToDate(valorCampo);
    case tiposCampoGenerico.TEXTO:
      return valorCampo;
    default:
      break;
  }
}

export const verificarRegraValidacaoCampoGenerico = (nomeCampo, valorCampo, pericia, regra) => {
  const valoresGenericos = pericia.valoresCamposGenericos
  const retorno = { valido: true }

  switch (regra.tipoRegra) {
    case tipoRegraValidacaoCampoGenerico.CAMPO_GENERICO_CAMPO_GENERICO: {
        const valorCampoReferencia = valoresGenericos ? tratarCampoPorTipo(regra.campoGenericoReferencia.tipo, valoresGenericos.find(v => v.idCampoGenerico === regra.campoGenericoReferencia.id)?.valorTextoCampoGenerico)
          : undefined;
        if (valorCampo && valorCampoReferencia) {
          return operarValidacaoCampoGenerico(regra.tipoOperador, nomeCampo, valorCampo, valorCampoReferencia, regra);
        }
        break;
    }
    case tipoRegraValidacaoCampoGenerico.CAMPO_GENERICO_CAMPO_ESTATICO: {
        const valorCampoEstatico = obterValorCampoEstatico(regra.tipoCampoValidacaoPericia, pericia);
        if (valorCampo && valorCampoEstatico) {
          return operarValidacaoCampoGenerico(regra.tipoOperador, nomeCampo, valorCampo, valorCampoEstatico, regra);
        }
        break;
    }
    case tipoRegraValidacaoCampoGenerico.CAMPO_GENERICO_VALOR_FIXO: {
        const valorCampoEstatico = obterValorCampoEstatico(regra.tipoCampoValidacaoPericia, pericia);
        const valorCampoFixo = obterValorFixo(regra, valorCampoEstatico);

        if (valorCampo && valorCampoFixo) {
          return operarValidacaoCampoGenerico(regra.tipoOperador, nomeCampo, valorCampo, valorCampoFixo, regra);
        }
        break;
    }
    case tipoRegraValidacaoCampoGenerico.CAMPO_ESTATICO_VALOR_FIXO: {
      const valorCampoEstatico = obterValorCampoEstatico(regra.tipoCampoValidacaoPericia, pericia);
      const valorFixo = obterValorFixo(regra);

      if (valorCampoEstatico && valorFixo) {
        return operarValidacaoCampoGenerico(regra.tipoOperador, nomeCampo, valorCampoEstatico, valorFixo, regra);
      }
      break;
    }
    default:
      break;
  }
  return retorno;
}

export const isDii = (sigla) => {
  return (sigla === SIGLA_CAMPO_DII);
}

export const isValorRegraExibicaoIgualValorCampo = (valorRegra, valorCampo) => {
  const regexArray = new RegExp("\\[.*;*\\]");
  
    if(regexArray.test(valorRegra)){
      //tratando regra com array de valores
      let arrayValores = valorRegra?.substring(1,valorRegra?.length -1)
                                      .split(';')
                                      .map( v => v.trim());
      
      //verifica se array contem valor campo generico
      return arrayValores?.includes(valorCampo);
    }
    return valorCampo === valorRegra;
}

const validarRegrasDependenciaExibicao = (campoGenericoOuSecao, valoresCamposGenericos, pericia) => {
  const regrasAtendidas = campoGenericoOuSecao.regrasDependenciaExibicao?.every(regra => {
    const campoRegra = regra.campoGenericoModuloOrigem ? regra.campoGenericoModuloOrigem : regra.secaoModuloCampoGenericoOrigem;
    if (campoRegra) {
      const valorCampo = campoRegra && valoresCamposGenericos.find(v => v.idCampoGenerico === campoRegra.campoGenerico.id);
      return isValorRegraExibicaoIgualValorCampo(regra.valor, valorCampo?.valorTextoCampoGenerico);
    } else if (regra.tipoCampoExibicaoPericia) {
      return isValorRegraExibicaoIgualValorCampo(regra.valor, obterValorCampoEstaticoRegraExibicao(regra.tipoCampoExibicaoPericia, pericia));
    }
    return true;
  });
  return (!campoGenericoOuSecao.regrasDependenciaExibicao || regrasAtendidas) ? tipoSimNao.SIM : tipoSimNao.NAO;
}

export const tratarExibicaoInicialCamposGenericos = (template, valoresCamposGenericos, pericia) => {
  template.modulosTemplatePericia.forEach(m => {
    if (m.modulo.tipoModuloEstatico === undefined) {
      m.modulo.camposGenericos.forEach(c => {
        if (c.regrasDependenciaExibicao.length === 0) {
          c.visivel = tipoSimNao.SIM;
        } else {
          c.visivel = validarRegrasDependenciaExibicao(c, valoresCamposGenericos, pericia);
        }
      });

      m.modulo.secoes.forEach(s => {
        if (s.regrasDependenciaExibicao.length === 0) {
          s.secoesModulosCamposGenericos.forEach(c => c.campoGenerico.visivel = tipoSimNao.SIM);
          if (s.moduloEstaticoFilho && s.moduloEstaticoFilho.tipoModuloEstatico !== undefined) {
            s.moduloEstaticoFilho.visivel = tipoSimNao.SIM;
          }
        } else {
          if (s.moduloEstaticoFilho && s.moduloEstaticoFilho.tipoModuloEstatico !== undefined) {
            s.moduloEstaticoFilho.visivel = validarRegrasDependenciaExibicao(s, valoresCamposGenericos, pericia)
          } else {
            s.secoesModulosCamposGenericos.forEach(c => c.campoGenerico.visivel = 
              validarRegrasDependenciaExibicao(s, valoresCamposGenericos, pericia));
          }
        }
      });
    }
  });
  return template;
}
