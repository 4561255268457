import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import CIDs from "../CIDs";
import HeaderSecao from '../../../../components/HeaderSecao';
import { SIGLA_CAMPO_CID_ALTERADO } from '../../constants';
import { alterarCampoGenericoEAtualizarMensagemValidacao } from '../../action';
import { obterCampoGenericoPorSigla } from '../../templatePericiaUtil';

const DiagnosticoPericiaAnterior = props => {
    const { pericia, template, abas, idAbaSelecionada, validacao, alterarCampoGenericoEAtualizarMensagemValidacao } = props;
    const {periciaAnterior, valoresCamposGenericos} = pericia;

    
    useEffect(() => {
        const nomeModulo = abas?.find(a => a.id === idAbaSelecionada)?.path; //aba que contem o modulo
        const validacaoModulo = validacao ? validacao[nomeModulo] : undefined; //mensagens de validacao do modulo
    
        const campoGenericoCidAlterado = obterCampoGenericoPorSigla(SIGLA_CAMPO_CID_ALTERADO, template);
        const valorCampoCidAlterado = valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO);
        const existeCidPericiaAnterior = periciaAnterior?.codigoCID;

        if (!existeCidPericiaAnterior && campoGenericoCidAlterado && !valorCampoCidAlterado) {
            //preencher de forma automatica campo generico altera CID com valor "NAO" (PMC Judicial)
            const idCampo = campoGenericoCidAlterado.campoGenerico.id;
            const siglaCampo = campoGenericoCidAlterado.campoGenerico.sigla;
            alterarCampoGenericoEAtualizarMensagemValidacao(idCampo,siglaCampo, "0", valoresCamposGenericos, validacaoModulo, nomeModulo);
        }
        // eslint-disable-next-line
      }, []);//deve executar apenas a primeira vez que o componente for renderizado
    

    return (
        <>
            <Dados periciaAnterior={periciaAnterior} />
        </>
    );
}

const Dados = ({ periciaAnterior }) => {

    const existeCidAnterior = periciaAnterior?.codigoCID ? periciaAnterior?.codigoCID : false;
    const existeCidSecundarioAnterior = periciaAnterior?.codigoCIDSecundario ? periciaAnterior?.codigoCIDSecundario : false;
    const existeIsencaoCarencia = periciaAnterior?.isencaoCarencia != null ? true : false;
    const cidSecundario = (periciaAnterior => {
        if (periciaAnterior?.codigoCIDSecundario) {
            return periciaAnterior.descricaoCIDSecundario ?
                `${periciaAnterior.codigoCIDSecundario} - ${periciaAnterior.descricaoCIDSecundario}` :
                periciaAnterior.codigoCIDSecundario;
        }
        return "CID Secundário não informado";
    })(periciaAnterior);
    const isencaoCarencia = (periciaAnterior => {
        if (periciaAnterior?.isencaoCarencia === 1) {
            return "SIM";
        }
        return "NÃO";
    })(periciaAnterior);
    return (
        <>
            <Grid item >
                {existeCidAnterior &&
                    (<Grid item>
                        <Grid item xs={12}>
                            <span htmlFor="nome">CID Principal informado na perícia anterior: </span>
                        </Grid>
                        <Grid item xs={12}>
                            <span id="nome" className="texto-informativo">{periciaAnterior.descricaoCID ?
                                `${periciaAnterior.codigoCID} - ${periciaAnterior.descricaoCID}` :
                                periciaAnterior.codigoCID}</span>
                        </Grid>
                        <br />
                        {existeCidSecundarioAnterior &&
                            (<Grid container item>
                                <Grid item xs={12}>
                                    <span htmlFor="nome">CID Secundário informado na perícia anterior: </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span id="nome" className="texto-informativo">{cidSecundario}</span>
                                </Grid>
                            </Grid>)}
                        <br />
                        {existeIsencaoCarencia &&
                            (<Grid container item>
                                <Grid item xs={12}>
                                    <span htmlFor="nome">Isenção de Carência informado na pericia anterior: </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span id="nome" className="texto-informativo">{isencaoCarencia}</span>
                                </Grid>
                            </Grid>)}
                    </Grid>)}
                {!existeCidAnterior &&
                    (<Grid item >
                        <Grid item>
                            <Grid item xs={12}>
                                <span htmlFor="nome">Benefício concedido sem CID</span>
                            </Grid>
                        </Grid>
                        <Grid key={'secaoIDinformarCid'} item >
                            <Grid item sm={12}>
                                <HeaderSecao texto='Informar CID' />
                            </Grid>
                            <Grid item sm={12} >
                                <CIDs />
                            </Grid>
                        </Grid>
                    </Grid>)}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    const { template, abas, idAbaSelecionada, validacao } = state.principal;
    return ({
        pericia: state.pericia,
        template: template,
        idAbaSelecionada: idAbaSelecionada,
        abas: abas,
        validacao: validacao
    });
}

const mapDispatchToProps = dispatch => bindActionCreators({
    alterarCampoGenericoEAtualizarMensagemValidacao
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticoPericiaAnterior);