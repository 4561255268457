import BrTooltip from '@govbr/react-components/lib/BrTooltip';
import React from "react";

const INFO_NEXO_PROFISSIONAL = "O nexo estabelecido pela associação do agravo com os agentes etiológicos "
  + "ou fatores de risco presentes nas atividades econômicas dos empregadores e constantes das listas A e B do Anexo II do RPS. "
  + "Manual de Acidente do Trabalho, p. 12.";

const INFO_DOENCA_PROFISSIONAL =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      Doença produzida ou desencadeada pelo exercício do trabalho peculiar a determinada atividade cujos trabalhadores
      tenham sido expostos, ainda que parcial ou indiretamente. <br />
      <br />
      <u>Manual de Acidente do Trabalho</u>, p. 13. <br />
      <br />
      O trabalho é condição necessária para a doença, conforme exposição aos agentes da lista A do Anexo II do Dec. 3.048/99.
    </span>
  </BrTooltip.PopoverBody>;

const INFO_DOENCA_TRABALHO =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      Doença adquirida [ou desencadeada] em função das condições especiais em que o trabalho é realizado. <br />
      <br />
      <u>Manual de Acidente do Trabalho</u>, p. 13. <br />
      <br />
      Doenças em que o trabalho é um fator de risco, contributivo, conforme exposição aos agentes da lista B do Anexo II do Dec. 3.048/99.
    </span>
  </BrTooltip.PopoverBody>;

const INFO_VINCULOS_NEXO_PROFISSIONAL =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      Em qual(is) do(s) vínculo(s) abaixo o segurado esteve exposto ao(s) agente(s) das listas A e/ou B do Anexo II do Dec. 3.048/99?
    </span>
  </BrTooltip.PopoverBody>;

const INFO_ACIDENTE_TIPICO =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      Quando o acontecimento súbito ou a contingência imprevista causou dano à saúde do trabalhador
      e ocorreu durante o desempenho da atividade profissional ou por circunstâncias a ela ligadas.<br />
      <br />
      <u>Manual de Acidente do Trabalho</u>, p. 13. <br />
      <br />
      Enquadram-se também os acidentes ocorridos fora das dependências da empresa, nas situações:
      (Lei nº 8.213/91, art. 21, IV, alíneas a, b, c) <br />
      <br />
      a) na execução de ordem ou na realização de serviço sob a autoridade da empresa; <br />
      <br />
      b) na prestação espontânea de qualquer serviço à empresa para lhe evitar prejuízo ou proporcionar proveito; <br />
      <br />
      c) em viagem a serviço da empresa, inclusive para estudo quando financiada por esta dentro de seus planos
      para melhor capacitação da mão-de-obra, independentemente do meio de locomoção utilizado,
      inclusive veículo de propriedade do segurado; <br />
    </span>
  </BrTooltip.PopoverBody>;

const INFO_ACIDENTE_TRAJETO =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      O ocorrido “no percurso da residência para o local de trabalho ou deste para aquela, qualquer que seja o meio de locomoção,
      inclusive veículo de propriedade do segurado.” <br />
      <u>Lei nº 8.213/91, art.21, IV, alínea d.</u>
    </span>
  </BrTooltip.PopoverBody>;

const INFO_DOENCA_EQUIPARADA =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      É o agravo decorrente das condições especiais em que o trabalho é realizado e que não esteja previsto nas listas A e B do RPS.
      <br />
      <u>Lei nº 8.213/91, art. 20, § 2º.</u>
    </span>
  </BrTooltip.PopoverBody>;

const INFO_NEXO_EPIDEMIOLOGICO =
  <BrTooltip.PopoverBody>
    <span className="subtext">
      É  o reconhecimento, no âmbito do INSS, das incapacidades decorrentes de significância estatística entre diversos tipos de doenças
      e uma determinada atividade econômica, significando o excesso de risco em cada área econômica, constituindo-se em uma presunção do tipo relativa,
      uma vez que admite prova em sentido contrário. <br />
      <br />
      <u>Manual de Acidente do Trabalho, p. 14.</u><br />
      <br />
      A perícia médica do INSS [Federal] deixará de aplicar o disposto neste artigo quando demonstrada a inexistência do nexo de que trata o caput deste artigo.<br />
      <br />
      <u>Lei nº 8.213/91, art. 21-A, § 1º</u>
    </span>
  </BrTooltip.PopoverBody>;

export {
    INFO_NEXO_PROFISSIONAL, INFO_DOENCA_PROFISSIONAL, INFO_DOENCA_TRABALHO, INFO_VINCULOS_NEXO_PROFISSIONAL,
    INFO_ACIDENTE_TIPICO, INFO_ACIDENTE_TRAJETO, INFO_DOENCA_EQUIPARADA,
    INFO_NEXO_EPIDEMIOLOGICO
};
