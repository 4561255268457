import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';

import { obterlabelVinculo } from '../../../../common/PericiaBiUtil';
import { redirecionarParaAba } from '../../../principal/action';
import { marcarIncapacidadeSemVinculos, marcarIncapacidadeVinculo } from '../../action';
import { tipoSimNao, tiposNexoTecnico } from '../../constants';

const VinculosIncapacidade = props => {

  const [vinculosFiltrados, setVinculosFiltrados] = useState([]);
  const [deveConfirmarAlteracaoVinculo, setDeveConfirmarAlteracaoVinculo] = useState(false);
  const [deveConfirmarAlteracoesVinculos, setDeveConfirmarAlteracoesVinculos] = useState(false);
  const [idVinculoSelecionado, setIdVinculoSelecionado] = useState(undefined);
  const [tipoSelecao, setTipoSelecao] = useState(undefined);

  const { 
    vinculos, 
    incapacidadeSemVinculos,
    isSomenteLeitura, 
    validacao, 
    abas, 
    marcarIncapacidadeVinculo, 
    marcarIncapacidadeSemVinculos,
    redirecionarParaAba 
  } = props;

  const deveLimparDadosNtep = idVinculo => {
    const tipoNexo = vinculos.find(v => v.id === parseInt(idVinculo))?.tipoNexoTecnico;

    return props.existeModuloNexoNoTemplate
      && tipoNexo !== tiposNexoTecnico.PROFISSIONAL.id //nao tem nexo profissional
      && tipoNexo !== tiposNexoTecnico.INDIVIDUAL.id; // nao tem nexo individual
  }

  const isVinculoPossuiBatimentoCNAE = idVinculo => {
    const { existeModuloNexoNoTemplate, batimentoCidCnae } = props;
    const cnaeVinculo = vinculosFiltrados?.find(v => v.id === parseInt(idVinculo))?.codigoCnae;
    const cnaesComBatimento = batimentoCidCnae?.map(b => b.ntepId.codigoCNAE20);

    return existeModuloNexoNoTemplate
      && batimentoCidCnae?.length > 0
      && cnaeVinculo
      && cnaesComBatimento.includes(cnaeVinculo)
  }

  const tratarMarcacaoVinculo = (idVinculo, checked) => {
    //exibir alerta para vinculos q tem batimento NTEP
    if (isVinculoPossuiBatimentoCNAE(idVinculo)) { //vinculo com batimento CNAE
      setDeveConfirmarAlteracaoVinculo(true);
      setIdVinculoSelecionado(idVinculo);
      setTipoSelecao(checked);
    } else {
      selecionarIncapacidadeVinculo(idVinculo, checked);
    }
  }

  const selecionarIncapacidadeVinculo = (idVinculo, checked) => checked
    ? marcarIncapacidadeVinculo(idVinculo, 1, deveLimparDadosNtep(idVinculo))
    : marcarIncapacidadeVinculo(idVinculo, 0, deveLimparDadosNtep(idVinculo));

  const confirmarAlteracaoVinculo = () => {
    setDeveConfirmarAlteracaoVinculo(false);
    selecionarIncapacidadeVinculo(idVinculoSelecionado, tipoSelecao);
    setIdVinculoSelecionado(undefined);
    setTipoSelecao(undefined);
    redirecionarParaAba('nexoTecnico', abas); //deve redirecionar automaticamente para aba nexo, é necessário refazer batimentos NTEP
  }

  const cancelarAlteracaoVinculo = () => {
    setDeveConfirmarAlteracaoVinculo(false);
    setIdVinculoSelecionado(undefined);
    setTipoSelecao(undefined);
  }

  useEffect(() => {
    const vinculosFiltrados = vinculos
      && vinculos.filter(vinculo => vinculo.ativo);

    setVinculosFiltrados(vinculosFiltrados);
  }, [vinculos]);

  let marcarTodosOsVinculos = useRef(undefined);

  const setIncapacidadeSemVinculos = value => {
    marcarIncapacidadeSemVinculos(value ? tipoSimNao.SIM.id : null);
  }

  const alterarSelecoesDosVinculosAlterados = value => {
    marcarTodosOsVinculos.current = !value;
    if(value) {
      const desmarcar = tipoSimNao.NAO.id;
      let houveMudancaEmAlgumVinculoComBat = vinculosFiltrados.find(v => v.possuiIncapacidade !== desmarcar && isVinculoPossuiBatimentoCNAE(v.id));
      if(houveMudancaEmAlgumVinculoComBat) {
        setDeveConfirmarAlteracoesVinculos(true);
        return;
      }
      const vincAlterados = vinculosFiltrados.map(v => {
        v.possuiIncapacidade = desmarcar;
        return v;
      });
      setVinculosFiltrados(vincAlterados);
    }
    setIncapacidadeSemVinculos(value);
    marcarTodosOsVinculos.current = undefined;
  }

  const adicionarSelecaoEmTodosVinculos = () => {
    marcarTodosOsVinculos.current = true;
    const marcar = tipoSimNao.SIM.id;
    let houveMudancaEmAlgumVinculoComBat = vinculosFiltrados.find(v => v.possuiIncapacidade !== marcar && isVinculoPossuiBatimentoCNAE(v.id));
    if(houveMudancaEmAlgumVinculoComBat) {
      setDeveConfirmarAlteracoesVinculos(true);
      return;
    }
    const vincAlterados = vinculosFiltrados.map(v => {
      v.possuiIncapacidade = marcar;
      return v;
    });
    setVinculosFiltrados(vincAlterados);
    setIncapacidadeSemVinculos(false);
    marcarTodosOsVinculos.current = undefined;
  }

  const cancelarAlteracoesVinculos = () => {
    setDeveConfirmarAlteracoesVinculos(false);
    marcarTodosOsVinculos.current = undefined;
  }

  const confirmarAlteracoesVinculos = () => {
    setDeveConfirmarAlteracoesVinculos(false);
    const vincAlterados = vinculosFiltrados.map(v => {
      v.possuiIncapacidade = marcarTodosOsVinculos.current ? tipoSimNao.SIM.id : tipoSimNao.NAO.id;
      return v;
    });
    setVinculosFiltrados(vincAlterados);
    setIncapacidadeSemVinculos(marcarTodosOsVinculos.current ? tipoSimNao.NAO.id : tipoSimNao.SIM.id);
    marcarTodosOsVinculos.current = undefined;
    redirecionarParaAba('nexoTecnico', abas);
  }

  return (
    <>
      {vinculosFiltrados && vinculosFiltrados.length > 0 ?
        <div id="moduloVinculosIncapacidade" className="conteudoVinculosIncapacidade">
          
          <div style={{paddingBottom: "1em"}}>
            <BrCheckbox id="nenhumDosApresentados" 
              items={[{ label: "Nenhum dos vínculos apresentados", value: "nenhumDosVinculosApresentados" }]}
              values={tipoSimNao.SIM.id === incapacidadeSemVinculos ? ["nenhumDosVinculosApresentados"] : []}
              onChange={(item, checked) => alterarSelecoesDosVinculosAlterados(checked)}
              disabled={isSomenteLeitura} 
              style={{paddingBottom: "2em"}}
              erro={validacao && validacao.erroVinculoIncapacidadeObrigatorio}/>
            <BrButton
              secondary
              className="mr-2"
              onClick={adicionarSelecaoEmTodosVinculos}
              disabled={isSomenteLeitura}
            >
              <i className={"fas fa-check-double"} />
              &nbsp;&nbsp;Marcar Todos
            </BrButton>
          </div>
          
          <BrCheckbox id="vinculosIncapacidade" orientation='horizontal'
            values={vinculosFiltrados ? vinculosFiltrados.map(v => v.possuiIncapacidade === 1 ? v.id : []) : []}
            items={vinculosFiltrados.map(v => ({
              label: obterlabelVinculo(v),
              value: v.id
            }))}
            onChange={(item, checked) => {
              tratarMarcacaoVinculo(item, checked);
              setIncapacidadeSemVinculos(false);
            }}
            disabled={isSomenteLeitura}/>
        </div>
        : <p className="label">&nbsp;&nbsp;&nbsp;Nenhum vínculo encontrado.</p>
      }

      <BrModal id="dialogConfirmarAlteracaoVinculoIncapacidade" visible={deveConfirmarAlteracaoVinculo}
        title={`Alterar Vinculo com Incapacidade`} closable={false} medium>
        <p>
          A alteração na seleção de vínculos <b>poderá impactar</b> os dados preenchidos na avaliação do Nexo Técnico Epidemiológico (NTEP) realizada.
        </p>
        <p>
          Você será redirecionado automaticamente para aba Nexo Técnico e os dados já preenchidos deverão ser reavaliados.
        </p>
        Deseja continuar?

        <BrModalFooter>
          <BrButton secondary className="m-2" onClick={cancelarAlteracaoVinculo}>Cancelar</BrButton>
          <BrButton primary className="m-2" onClick={confirmarAlteracaoVinculo}>Confirmar</BrButton>
        </BrModalFooter>
      </BrModal >

      <BrModal id="dialogConfirmarAlteracoesVinculosIncapacidade" visible={deveConfirmarAlteracoesVinculos}
        title={`Alterar Vinculos com Incapacidade`} closable={false} medium>
        <p>
          As alterações nas seleções de vínculos <b>poderão impactar</b> os dados preenchidos na avaliação do Nexo Técnico Epidemiológico (NTEP) realizada.
        </p>
        <p>
          Você será redirecionado automaticamente para aba Nexo Técnico e os dados já preenchidos deverão ser reavaliados.
        </p>
        Deseja continuar?

        <BrModalFooter>
          <BrButton secondary className="m-2" onClick={cancelarAlteracoesVinculos}>Cancelar</BrButton>
          <BrButton primary className="m-2" onClick={confirmarAlteracoesVinculos}>Confirmar</BrButton>
        </BrModalFooter>
      </BrModal >

    </>
  );
}

const mapStateToProps = (state) => {
  const { validacao, isSomenteLeitura, existeModuloNexoNoTemplate, abas } = state.principal;
  const { vinculosPericia, incapacidadeSemVinculos } = state.pericia;

  return ({
    vinculos: vinculosPericia,
    incapacidadeSemVinculos,
    validacao: validacao.vinculosIncapacidade,
    isSomenteLeitura: isSomenteLeitura,
    abas: abas,
    existeModuloNexoNoTemplate: existeModuloNexoNoTemplate,
    batimentoCidCnae: state.modulos.moduloNexoTecnico.batimentoCidCnae
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  marcarIncapacidadeVinculo,
  redirecionarParaAba,
  marcarIncapacidadeSemVinculos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VinculosIncapacidade);