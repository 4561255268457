import React from 'react';
import { connect } from 'react-redux';

import HeaderSecao from '../../components/HeaderSecao';
import BrButton from '@govbr/react-components/lib/BrButton';
import Box from "@material-ui/core/Box";
import BrInput from '@govbr/react-components/lib/BrInput';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import DialogConfirmarExclusao from './DialogConfirmarExclusao';
import DialogAlteracao from './DialogAlteracao';

import { MSG_CAMPOS_OBRIGATORIO } from '../../common/Messages';
import { calcularCaracteresRestantes } from '../../common/Utils';

 import {alterarTituloInclusaoPerito,alterarConteudoInclusaoPerito, exibirDialogExcluirItem, exibirDialogAlterarItem, incluirItemDicionarioPerito} from "./action";

const DicionarioPerito = (props) => {

  const QTD_MAXIMA_CARACTERES = 4000;

  const {tituloItemPeritoInclusao, conteudoItemPeritoInclusao, itensDicionarioPerito, itemSelecionado} = props.dados;
  const validacao = props.dados.validacaoItemPerito;

  const itensDicionarioOrdenadoPorNome =  itensDicionarioPerito?.sort((a, b) => a.chaveCampo.localeCompare(b.chaveCampo));

  const caracteresRestantes = calcularCaracteresRestantes(conteudoItemPeritoInclusao, QTD_MAXIMA_CARACTERES);

  return (
    <div id="dados-dicionario-perito">
      <HeaderSecao texto="Incluir no Dicionário" />

      <BrInput id="tituloItemPerito" label="* Título"
        value={tituloItemPeritoInclusao}
        onChange={props.alterarTituloInclusaoPerito}
        erro={validacao && validacao.erroTitulo}
        maxLength={100} />

      <BrTextarea id="conteudoItemPerito" label="* Conteúdo"
        value={conteudoItemPeritoInclusao}
        onChange={props.alterarConteudoInclusaoPerito}
        maxLength={QTD_MAXIMA_CARACTERES}
        help={`Caracteres restantes: ${caracteresRestantes}`}
        erro={validacao && validacao.erroConteudo} 
        />

      <p>{MSG_CAMPOS_OBRIGATORIO}</p>

      <Box display="flex"position="sticky" bgcolor="#fff" justifyContent="flex-end">
        
        <BrButton id="btnIncluir" primary onClick={() => props.incluirItemDicionarioPerito(tituloItemPeritoInclusao, conteudoItemPeritoInclusao, props.cpfPerito)}
        >
          <i className={"fas fa-plus"} />
          Incluir
        </BrButton>
      </Box>

      <HeaderSecao texto="Itens do Dicionário" />
      {itensDicionarioOrdenadoPorNome && itensDicionarioOrdenadoPorNome.length > 0 &&
        <div id="tabelaDicionarioPerito">
          <table>
            <thead>
              <tr>
                <th>Título</th>
                <th>Conteúdo</th>
                <th style={{ width: '50px' }}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {
                itensDicionarioOrdenadoPorNome?.map(i =>
                  <tr key={i.id}>
                    <td>
                      <b>{i.chaveCampo}</b>
                    </td>
                    <td >
                      {i.valorCampo}
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                    <Box display="flex">
                        <BrButton id="alterarDados" circle
                          onClick={() => props.exibirDialogAlterarItem(i)}>
                          <i className={`fas fa-pen`} title='Alterar Item' />
                        </BrButton>
                        
                        <BrButton id="excluirDados" circle
                          onClick={() => props.exibirDialogExcluirItem(i)}>
                          <i className={`fas fa-trash-alt`} title='Excluir Item' />
                        </BrButton>
                      </Box>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      }

      {
        itensDicionarioOrdenadoPorNome && itensDicionarioOrdenadoPorNome.length === 0
        && (
          <center className="texto-informativo red">
            Ainda não foram incluídos itens neste dicionário.
          </center>
        )
      }

      <DialogConfirmarExclusao
        item={itemSelecionado}
      />

      <DialogAlteracao />

    </div>
  )
}

const mapStateToProps = (state) => ({
  dados: state.dicionario, 
  cpfPerito: state.principal.userData.document.number
})

const mapDispatchToProps = {
  alterarTituloInclusaoPerito,
  alterarConteudoInclusaoPerito,
  incluirItemDicionarioPerito,
  exibirDialogExcluirItem,
  exibirDialogAlterarItem
}

export default connect(mapStateToProps, mapDispatchToProps)(DicionarioPerito);