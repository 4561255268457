import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import { Grid } from '@material-ui/core';

import Concluir from '../../../../components/Concluir';
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import { MSG_CAMPOS_OBRIGATORIO } from '../../../../common/Messages';
import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { alterarJustificativaConclusao, alterarResultadoGrauDependencia, validacaoCompletaPericia } from '../../action';
import { obterGrauDependencia, tiposGrauDependencia } from "./utils";

const ResultadoPericiaB88 = props => {
  const QTD_MAXIMA_CARACTERES = 4000;
  const QTD_QUALIFICADORES_FORMULARIO = 6;

  const { pericia, validacao, isSomenteLeitura, validacaoCompletaPericia, alterarResultadoGrauDependencia } = props;
  const { respostaConcluir, moduloConclusaoPericia } = pericia;

  const { justificativaConclusao, tipoGrauDependencia } = moduloConclusaoPericia;
  const caracteresRestantes = calcularCaracteresRestantes(justificativaConclusao, QTD_MAXIMA_CARACTERES);

  const isCampoTextareaDesabilitado = isSomenteLeitura;

  useEffect(() => {
    if (pericia) {
      validacaoCompletaPericia(pericia, true);

      const respostas = pericia.respostasGrupoForm;
      if (respostas && respostas.length === QTD_QUALIFICADORES_FORMULARIO) {
        //so deve calcular resultado final se formulario completamente preenchido
        alterarResultadoGrauDependencia(obterGrauDependencia(respostas));
      }
    }
    // eslint-disable-next-line
  }, []); //deve executar apenas a primeira vez que o componente for renderizado

  useEffect(() => {
    if (pericia) {
      //validacao de campo obrigatorio justificativa e atualizar variavel para habilitar/desabilitar botao Concluir
      validacaoCompletaPericia(pericia, true);
    }
  }, [pericia, validacaoCompletaPericia]);

  return (
    <div id="abaResultadoB88" className="conteudoAbaSemHeaderSecao">
      <div>
        {
          respostaConcluir && respostaConcluir.erros
          && (
            respostaConcluir.erros.map((e, index) =>
            (
              <BrMessage key={`erro_${index}`} error>
                {e}
              </BrMessage>)
            )
          )
        }
      </div>

      <p>
        Resultado da avaliação médico-pericial referente à dependência de terceiros para o desempenho de atividades básicas da vida diária.
      </p>
      <div>
        <label htmlFor="grauDependencia">Classificação: </label>
        <span id="grauDependencia">
          {tipoGrauDependencia && Object.values(tiposGrauDependencia).find(t => t.codigo === tipoGrauDependencia)?.descricao}
        </span>
      </div>

      <div>
        <br>
        </br>
          <Grid container alignItems="center">
            <Grid item xs>
              <BrTextarea id="justificativaConclusao"
                label="* Justificativa da Conclusão Médico Pericial"
                onChange={props.alterarJustificativaConclusao}
                value={justificativaConclusao}
                maxLength={QTD_MAXIMA_CARACTERES}
                help={`Caracteres restantes: ${caracteresRestantes}`}
                erro={validacao && validacao.mensagemErro}
                disabled={isCampoTextareaDesabilitado} />
             </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustConclusaoB88"
                    onSelect={props.alterarJustificativaConclusao}
                    maxLength={caracteresRestantes}
                    valorAtual={justificativaConclusao}/>
                </Grid>
              }
          </Grid>
      </div>
      {/* possui campos obrigatórios, porém foi necessário implementar texto '(*) campos obrigatórios' dentro do componente*/}
      <p className="label" style={{marginTop: "var(--spacing-scale-2x)"}}>{MSG_CAMPOS_OBRIGATORIO}</p>
      <Concluir />
    </div >
  );
}

const mapStateToProps = (state) => ({
  isSomenteLeitura: state.principal.isSomenteLeitura,
  validacao: state.principal.validacao.conclusaoB88,
  pericia: state.pericia
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustificativaConclusao,
  validacaoCompletaPericia,
  alterarResultadoGrauDependencia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoPericiaB88);