import React from "react";

import BrTable from "@govbr/react-components/lib/BrTable";
import { Grid } from "@material-ui/core";

import { formatarDateTimeISOToDataBr } from "../../../../common/DataUtils";

const TabelaExameLaudo = ({ exames, isValorAtual }) => {
  const infoClass = isValorAtual ? "valor-atual-revisao":"valor-anterior-revisao";

  return (
    <BrTable
      id="detalheTabelaExamesLaudos"
      layout="fixed"
      columns={[
        { Header: "Data do documento", accessor: "data" },
        {
          Header: "Tipo de Identificação",
          accessor: "tipoIdentificacao",
        },
        { Header: "Número", accessor: "numero" },
      ]}
      data={exames.map((item) => ({
        data: <span className={`texto-informativo ${infoClass}`}>{formatarDateTimeISOToDataBr(item.dataDocumento) || "---"}</span>,
        tipoIdentificacao: <span className={`texto-informativo ${infoClass}`}>{item.tipoEmitenteFormatado || "---"}</span>,
        numero: <span className={`texto-informativo ${infoClass}`}>{item.ufFormatada || "---"}</span>,
        collapse: 
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div>Nome Profissional / Pessoa Jurídica</div>
            <div className={`texto-informativo ${infoClass}`}>{item.nomeProfissional || "---"}</div>
          </Grid>
          <Grid item xs={12}>
            <div>Exame / Laudo</div>
            <div className={`texto-informativo ${infoClass}`}>{item.descricaoLaudo || "---"}</div>
          </Grid>
        </Grid>
      }))}
      collapse={true}
      headerProps={{
        show: false,
        showDensityOptions: false,
        searchProp: {
          show: false,
        },
      }}
      footerProps={{ show: false }}
    />);
}

export default TabelaExameLaudo;