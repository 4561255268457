import { getSeguroPromise } from "../../../../common/requests";
import {
    displayLoader, errorMessage
} from "../../../principal/action";

export const carregarHtmlRelatorioPericia = async (idPericia) => {
    return async (dispatch) => {
        try {
            dispatch(displayLoader(true));
            dispatch(limparHtmlRelatorioPericia());
            const response = await getSeguroPromise(`pmf-realizar-pericias-service/pericia/${idPericia}/laudo-pericia-html`);
            await dispatch({
                type: 'CARREGAR_HTML_RELATORIO_PERICIA',
                payload: response.data
            });
            dispatch(displayLoader(false));
        } catch (err) {
            dispatch(limparHtmlRelatorioPericia());
            dispatch(errorMessage(err, "Consulta relatório perícia html"));
            throw err;
        }
    };
}

export const limparHtmlRelatorioPericia = () => ({
    type: 'LIMPAR_HTML_RELATORIO_PERICIA'
});

