export const HOST_FRONTEND = window.__env.urlApp;
export const HOST_NEWDOO = window.__env.urlNewdoo;


/** CONFIGURAÇÕES GERAIS, NÃO ALTERAR */
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER = 'FETCH_USER';
export const BACKEND_INDEX = `${HOST_NEWDOO}/apis/`;

export const LOGIN_WITH = 'gerid';
export const CLIENT_ID = 'PMF_PERICIAS';
export const FRONTEND_INDEX = `${HOST_FRONTEND}/`;
export const AUTH_PATH = `${HOST_NEWDOO}/auth/oauth/authorize?client_id=${CLIENT_ID}&response_type=token&loginwith=${LOGIN_WITH}&redirect_uri=${FRONTEND_INDEX}`;
export const AUTH_PATH_2 = `${HOST_NEWDOO}/auth/oauth/authorize?client_id=${CLIENT_ID}&response_type=token&loginwith=${LOGIN_WITH}`;
export const AUTH_PATH_LOGOUT = `${HOST_NEWDOO}/auth/logoutConfirmation??client_id=${CLIENT_ID}&redirect_uri=${AUTH_PATH}`;

export const AUTH_PATH_LOGOUT_OUT = `${HOST_NEWDOO}/auth/logout?redirect_uri=${FRONTEND_INDEX}`;

export const TIMEOUT_MESSAGE = 5000;
