export default (state = {}, action) => {
  switch (action.type) {
    case 'ATUALIZAR_BATIMENTO_CID_AGRAVAMENTO': {
      const { cid, batimento } = action.payload;
      return { ...state, batimentoCidAgravamento: { ...state.batimentoCidAgravamento, [`${cid}`]: batimento } }
    }
    case 'ERRO_CONSULTA_BATIMENTO_CID_AGRAVAMENTO':
      return {
        ...state,
        erroConsultaBatimentoAgravamento: true,
        cidAAComErro: action.payload
      }
    case 'LIMPAR_BATIMENTO_CID_AGRAVAMENTO':
      return { ...state, batimentoCidAgravamento: undefined, batimentoCidAgravamentoFinalizado: undefined }
    case 'ATUALIZAR_BATIMENTO_CID_RESTABELECIMENTO': {
      const { cid, batimento } = action.payload;
      return { ...state, batimentoCidRestabelecimento: { ...state.batimentoCidRestabelecimento, [`${cid}`]: batimento } }
    }
    case 'ERRO_CONSULTA_BATIMENTO_CID_RESTABELECIMENTO':
      return {
        ...state,
        erroConsultaBatimentoRestabelecimento: true,
        cidADComErro: action.payload
      }
    case 'LIMPAR_BATIMENTO_CID_RESTABELECIMENTO':
      return { ...state, batimentoCidRestabelecimento: undefined, batimentoCidRestabelecimentoFinalizado: undefined }
    case 'FIM_BATIMENTO_CID_AGRAVAMENTO':
      return { ...state, batimentoCidAgravamentoFinalizado: true }
    case 'FIM_BATIMENTO_CID_RESTABELECIMENTO':
      return { ...state, batimentoCidRestabelecimentoFinalizado: true }
    case 'INDICAR_POSSUI_DII_RESTABELECIMENTO_AGRAVAMENTO':
      return { ...state, possuiDIDRestabelecimentoAgravamento: action.payload }
    default:
      return state;
  }
};