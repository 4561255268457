import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import { Grid } from '@material-ui/core';
import HeaderSecao from '../../../components/HeaderSecao';

import Concluir from "../../../components/Concluir";
import { validacaoCompletaPericia } from "../action";
import { SIGLA_CAMPO_DCB, SIGLA_CAMPO_DID, SIGLA_CAMPO_DII, SIGLA_CAMPO_INDICACAO_AA_RP_LI, SIGLA_CAMPO_PERICIA_PRESENCIAL } from '../constants';

const ConclusaoBIDocmed = (props) => {

  useEffect(() => {
    props.validacaoCompletaPericia(props.pericia, true);
  });

  return (
    <>
      <div>
        {
          props.respostaConcluir && props.respostaConcluir.erros
          && (
            props.respostaConcluir.erros.map((e, index) =>
            (
              <BrMessage key={`erro_${index}`} error>
                {e}
              </BrMessage>)
            )
          )
        }
      </div>

      <HeaderSecao texto="Dados Técnicos" />

      {indicacaoPericiaPresencial(props.pericia) ?
        telaPresencial(props.pericia) :
        telaNaoPresencial(props.pericia)}

      <Concluir />
    </>
  );
}

const indicacaoPericiaPresencial = (pericia) => {
  const valorPericiaPresencial = pericia.valoresCamposGenericos?.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_PRESENCIAL)?.valorTextoCampoGenerico;
  return valorPericiaPresencial !== undefined && valorPericiaPresencial === '1';
}

const indicacaoAARPLI = (pericia) => {
  const valorIndicacaoAARPLI = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_INDICACAO_AA_RP_LI)?.valorTextoCampoGenerico;
  return valorIndicacaoAARPLI !== undefined && valorIndicacaoAARPLI === '1' ? "Sim" : "Não";
}

const isentaCarencia = (pericia) => {
  const valorIsentaCarencia = pericia.moduloIsencaoCarencia?.isentaCarencia;
  return valorIsentaCarencia !== undefined && valorIsentaCarencia === 1 ? "Sim" : "Não";
}

const telaNaoPresencial = (pericia) => {
  const cid = pericia.moduloCid?.cidPrincipal ?
    `${pericia.moduloCid?.cidPrincipal} - ${pericia.moduloCid?.nomeCidPrincipal}` : "";
  const moduloNexoTecnico = pericia.moduloNexoTecnico?.existeNexoProfissional
    ? "Sim - Nexo Técnico Profissional"
    : (pericia.moduloNexoTecnico?.existeNexoIndividual
      ? "Sim - Nexo Técnico Individual"
      : (pericia.moduloNexoTecnico?.existeNtep
        ? "Sim - Nexo Técnico Epidemiológico"
        : "Não")
    );
  const did = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DID)?.valorTextoCampoGenerico;
  const dii = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DII)?.valorTextoCampoGenerico;
  const dcb = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DCB)?.valorTextoCampoGenerico;
  const isencaoCarencia = isentaCarencia(pericia);
  const capLaborativa = "NÃO há indicação de perícia presencial"

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <span htmlFor="cid">CID: </span>
        <span id="cid" className="texto-informativo">{cid}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="isencaoCarencia">Isenção Carência: </span>
        <span id="isencaoCarencia" className="texto-informativo">{isencaoCarencia}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="capacidadeLaborativa">Capacidade Laborativa: </span>
        <span id="capacidadeLaborativa" className="texto-informativo">{capLaborativa}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="moduloNexoTecnico">Nexo Técnico: </span>
        <span id="moduloNexoTecnico" className="texto-informativo">{moduloNexoTecnico}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="did">DID: </span>
        <span id="did" className="texto-informativo">{did}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="dii">DII: </span>
        <span id="dii" className="texto-informativo">{dii}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="dcb">DCB: </span>
        <span id="dcb" className="texto-informativo">{dcb}</span>
      </Grid>
    </Grid>
  );
}

const telaPresencial = (pericia) => {
  const cid = pericia.moduloCid?.cidPrincipal ?
    `${pericia.moduloCid?.cidPrincipal} - ${pericia.moduloCid?.nomeCidPrincipal}` : "";
  const isencaoCarencia = isentaCarencia(pericia);
  const capLaborativa = "Há indicação de perícia presencial"
  const indAaRpLi = indicacaoAARPLI(pericia);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <span htmlFor="cid">CID: </span>
        <span id="cid" className="texto-informativo">{cid}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="isencaoCarencia">Isenção Carência: </span>
        <span id="isencaoCarencia" className="texto-informativo">{isencaoCarencia}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="capacidadeLaborativa">Capacidade Laborativa: </span>
        <span id="capacidadeLaborativa" className="texto-informativo">{capLaborativa}</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="moduloNexoTecnico">Nexo Técnico: </span>
        <span id="moduloNexoTecnico" className="texto-informativo">Não</span>
      </Grid>
      <Grid item xs={12}>
        <span htmlFor="iaarpli">Indicação de AA, RP ou LI: </span>
        <span id="iaarpli" className="texto-informativo">{indAaRpLi}</span>
      </Grid>
    </Grid>
  );
}



const mapStateToProps = state => ({
  pericia: state.pericia,
  respostaConcluir: state.pericia.respostaConcluir
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validacaoCompletaPericia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoBIDocmed);