import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { validarESolicitarSima, cancelarSolicitacaoSima, alterarJustificativaSima, alterarTipoSolicitacao} from './action';

import HeaderSecao from '../../../../components/HeaderSecao';
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrButton from '@govbr/react-components/lib/BrButton';
import { Grid } from '@material-ui/core';
import BrRadio from '@govbr/react-components/lib/BrRadio';

import { MSG_CAMPOS_OBRIGATORIO } from '../../../../common/Messages';

import {tiposSolicitacaoExigencia} from '../../constants';

class SIMA extends React.Component {

  QTD_MAXIMA_CARACTERES = 2000;

  render() {
    const { idPericia, validacao, config, alterarTipoSolicitacao,
      alterarJustificativaSima, cancelarSolicitacaoSima, validarESolicitarSima,
      isSomenteLeitura, isSimaSolicitado } = this.props;
    const { isFormSolicitacaoVisible, justificativa, tipoSolicitacaoExigencia } = config;
    const caracteresRestantes = calcularCaracteresRestantes(justificativa, this.QTD_MAXIMA_CARACTERES);

    const isCampoTextareaDesabilitado = isSomenteLeitura || isSimaSolicitado;

    return (
      <BrModal id="dialogSima" large style={{ width: "50%" }}
        title={"Solicitação de Exigência"}
        visible={isFormSolicitacaoVisible} closable={false}>

        <BrRadio
            id="tipoSolicitacaoExigencia"
            label="* Para solicitar uma exigência, escolha a opção desejada abaixo:"
            value={tipoSolicitacaoExigencia}
            items={Object.values(tiposSolicitacaoExigencia).map(t => ({ label: t.descricao, value: t.id }))}
            onChange={alterarTipoSolicitacao}
            erro={validacao ? validacao.mensagemErroTipoSolicitacao : null}
            disabled={isSomenteLeitura}
          />
        
        <HeaderSecao texto="Dados da Solicitação" />

        <Grid container alignItems="center">
            <Grid item xs>
              <BrTextarea id="justificativa"
                label="* Justificativa"
                onChange={alterarJustificativaSima}
                value={justificativa}
                maxLength={this.QTD_MAXIMA_CARACTERES}
                help={`Caracteres restantes: ${caracteresRestantes}`}
                erro={validacao ? validacao.mensagemErroJustificativa : null}
                disabled={isCampoTextareaDesabilitado} />
             </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustSolicitaSIMA"
                    onSelect={alterarJustificativaSima}
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
          </Grid>
          <p>{MSG_CAMPOS_OBRIGATORIO}</p>

        <BrModalFooter>
          <BrButton secondary className="m-2" id="btnCancelarSolicitacaoSima"
            onClick={cancelarSolicitacaoSima}>
            Cancelar
          </BrButton>
          <BrButton primary className="m-2" id="btnSolicitarSima"
            onClick={() => validarESolicitarSima(justificativa, idPericia, tipoSolicitacaoExigencia)}
            disabled={isSomenteLeitura || isSimaSolicitado}>
            Solicitar
          </BrButton>
        </BrModalFooter>

      </BrModal>
    );
  }
}

const mapStateToProps = (state) => {
  const { isSomenteLeitura, isSimaSolicitado, userData, validacao } = state.principal;
  return ({
    idPericia: state.pericia.id,
    cpfUsuarioLogado: userData.document.number,
    validacao: validacao.sima,
    config: state.modulos.moduloSima,
    isSomenteLeitura,
    isSimaSolicitado
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustificativaSima,
  cancelarSolicitacaoSima,
  validarESolicitarSima,
  alterarTipoSolicitacao
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SIMA);