import React from "react";

const ListaValoresTexto = ({ lista, isValorAtual }) => {
  const infoClass = isValorAtual ? "valor-atual-revisao":"valor-anterior-revisao";

  return (
    <ul>
      {
        lista.map((i, index) => (<li key={index}><span className={`texto-informativo ${infoClass}`}>{i.valorTexto}</span></li>))
      }
    </ul>);
}

export default ListaValoresTexto;