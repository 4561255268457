import React from 'react';
import BrCard from '@govbr/react-components/lib/BrCard';

const QualificadorFinal = (props) => {
  const { qualificadorFinal, textoQualificadorFinal } = { ...props };
  if (!qualificadorFinal) {
    return null;
  }
  return (
    <BrCard>
      <h5 className="titulo-qualificador-final ">{textoQualificadorFinal}</h5>
      <p>{`${qualificadorFinal.sigla} - ${qualificadorFinal.descricao}`}</p>
    </BrCard>
  );
};

export default QualificadorFinal;
