import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { obterDominios } from './utils';

const DominioConsolidado = (props) => {
  const { gruposFormulario, valoresPorDominio } = { ...props };
  const dominios = obterDominios(gruposFormulario);

  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {
              dominios.map((dominio) =>
                <TableCell key={`dominio_${dominio}`} style={{ fontWeight: 'bold', fontSize: '0.8em', textAlign: 'center' }}>
                  {dominio}
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {
              dominios.map((dominio) =>
                <TableCell key={`valor_dominio_${dominio}`} style={{ textAlign: 'center' }}>
                  {valoresPorDominio.get(dominio)}
                </TableCell>
              )
            }
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

}


export default DominioConsolidado
