import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';


import { ocultarDialogAlterarCidPrincipal, selecionarCidPrincipal } from './action';

const OPCAO_VAZIA_CID_PRIMARIO = { nome: '', codigo: null };

const DialogConfirmarAlteracao = props => {

  const [cid, setCid] = useState(OPCAO_VAZIA_CID_PRIMARIO);
  const [cidAnterior, setCidAnterior] = useState(OPCAO_VAZIA_CID_PRIMARIO);

  const { moduloNexo, moduloRestabelecimentoBeneficio } = props;

  const existeNexoTecnicoPreenchido = moduloNexo && (moduloNexo.existeNexoProfissional !== undefined
    || moduloNexo.existeNexoIndividual !== undefined
    || moduloNexo.existeNtep !== undefined);
  const existeDadosRestabelecimento = moduloRestabelecimentoBeneficio?.existeRestabelecimento !== undefined
    || moduloRestabelecimentoBeneficio?.existeAgravamento !== undefined;

  useEffect(() => {
    const { listaCidsPrincipais, novoValorCidPrincipal } = props.config;
    const cidSelecionado = listaCidsPrincipais && novoValorCidPrincipal
      ? listaCidsPrincipais.find(c => c.codigo === novoValorCidPrincipal.codigo)
      : null;
    const { cidPrincipal, nomeCidPrincipal } = props.moduloCid;

    setCid(cidSelecionado ? cidSelecionado : OPCAO_VAZIA_CID_PRIMARIO);
    setCidAnterior(cidPrincipal
      ? { nome: `${cidPrincipal} - ${nomeCidPrincipal}`, codigo: cidPrincipal }
      : OPCAO_VAZIA_CID_PRIMARIO);
  }, [props.config, props.moduloCid]);

  return (
    <BrModal id="dialogConfirmarAlteracaoCid" visible={props.config.deveConfirmarAlteracaoCidPrincipal}
      title={`Alterar CID Principal`} closable={false} medium>
      {
        existeNexoTecnicoPreenchido &&
        <p>
          <b>​O Nexo Técnico será apagado</b> e deverá ser analisado conforme o novo CID informado.
        </p>
      }
      {
        existeDadosRestabelecimento &&
        <p>
          <b>A seleção de benefício para agravamento/restabelecimento será apagada</b> e deverá ser analisada conforme o novo CID informado.
        </p>
      }
      Confirma alteração do CID Principal
      {
        cid.codigo && (
          <>
            &nbsp;de
            <b> '{cidAnterior.nome}' </b>
            para
            <b> '{cid.codigo} - {cid.nome}' </b>
          </>
        )
      }

      <BrModalFooter>
        <BrButton secondary className="m-2" onClick={() => props.ocultarDialogAlterarCidPrincipal(true)}>Cancelar</BrButton>
        <BrButton primary className="m-2" onClick={() => props.selecionarCidPrincipal(cid, true, undefined, undefined, props.existeModuloIsencaoNoTemplate)}>Confirmar</BrButton>
      </BrModalFooter>
    </BrModal >
  );
}

const mapStateToProps = (state) => ({
  config: state.modulos.moduloCids,
  moduloCid: state.pericia.moduloCid,
  moduloNexo: state.pericia.moduloNexoTecnico,
  moduloRestabelecimentoBeneficio: state.pericia.moduloRestabelecimentoBeneficio,
  existeModuloIsencaoNoTemplate: state.principal.existeModuloIsencaoNoTemplate
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selecionarCidPrincipal,
  ocultarDialogAlterarCidPrincipal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarAlteracao);
