import React, { useEffect } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const styleButton = {
  backgroundColor: '#E3E8E3',
  borderRadius: 'unset',
  minWidth: '50px'
}

const styleButtonSelected = {
  backgroundColor: '#7BAE94'
}

const styleButtonDisabled = {
  cursor: 'not-allowed',
  opacity: '0.8'

}

const ModuloFormularioResposta = (props) => {
  const { idPergunta, valor, respostas, handlerSelecionarResposta, disabled } = props;

  const [selectedValue, setSelectedValue] = React.useState(valor != null ? valor.toString() : '');

  useEffect(() => {
    setSelectedValue(valor !== undefined ? valor.toString() : '')
  }, [valor])

  const handleChange = (value) => {
    let valor = value;
    if (selectedValue === value) {
      valor = undefined;
    }
    setSelectedValue(valor);
    handlerSelecionarResposta(idPergunta, valor)
  };

  return (
    <ButtonGroup
      style={{ height: '5.5em' }}
      disableElevation
      fullWidth
      aria-label="outlined primary button group"
      disabled={disabled}>
      {
        respostas.map((item) => (
          <Button
            key={item}
            style={{
              ...styleButton,
              ...(disabled) ? styleButtonDisabled : {},
              ...(selectedValue === item) ? styleButtonSelected : {}
            }}
            title={item}
            onClick={() => handleChange(item)}

          >
            { (selectedValue === item) ?
              <Icon style={{ color: '#fff' }} className="fa fa-check" /> :
              <span style={{ opacity: '0.5' }}>{item}</span>
            }
          </Button>
        ))
      }
    </ButtonGroup>
  )
}


export default ModuloFormularioResposta;

