import React from 'react'

export const PaginaNaoEncontrada = () => {
  return (
    <div style={{ minHeight: 200 }} >
      <h6 className="color-secondary-06">Bem-vindo ao PMF Perícias</h6>
    </div>
  )
}

export default PaginaNaoEncontrada;
