import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { possuiPermissoes } from '../../common/AuthManager';
import { consultarPericia, consultarPericiaPorToken } from './action';

export class LoaderPericia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      possuiPermissaoAcesso: true
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.search);
    let id = params.get('id'); 
    let hash = params.get('hash');
    let token = params.get('token');
    if (hash && token) {
      this.props.consultarPericiaPorToken(token, hash, this.props.somenteLeitura);
    } else if (id) {
      let possuiPermissaoAcesso = possuiPermissoes('ACEDIRPER');
      this.setState({ possuiPermissaoAcesso })
      if (possuiPermissaoAcesso) {
        this.props.consultarPericia(id, this.props.somenteLeitura);
      }
    }
  }

  render() {
    if (!this.state.possuiPermissaoAcesso) {
      return <Redirect to="/naoAutorizado" />
    }

    const {pericia, abas, template} = this.props;
    const periciaCarregada = pericia && pericia.id && template && template.id && abas;
    return (periciaCarregada && abas[0] && abas[0].path
       ? 
        this.props.somenteLeitura ? <Redirect to={`/consulta/pericia/detalhe/${abas[0].path}`} /> : <Redirect to={`/pericia/${abas[0].path}`} />
       :
      null     
    )
  }
}

const mapStateToProps = (state) => ({  
  pericia: state.pericia,
  template: state.principal.template,
  abas: state.principal.abas
})

const mapDispatchToProps = dispatch => bindActionCreators({
  consultarPericiaPorToken,
  consultarPericia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoaderPericia)
