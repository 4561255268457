import { errorMessageAbstract } from "../../../../common/Utils";
import { getSeguroPromise } from "../../../../common/requests";
import { TIPO_IDENT_EMITENTE_ATESTMED_CRM } from "../../constants";

export const exibirDialogIncluirDocumMedOdont = () => ({
  type: 'EXIBIR_DIALOG_INCLUIR_DOCUM_MED_ODONT'
});

export const ocultarDialogDocumMedOdont = () => ({
  type: 'OCULTAR_DIALOG_DOCUM_MED_ODONT'
});

export const exibirDialogDetalharDocumMedOdont = documMedOdont => {
  return dispatch => {
    if(!documMedOdont?.dadosDoMedico 
        && documMedOdont?.idTipoIdentEmitente === TIPO_IDENT_EMITENTE_ATESTMED_CRM) {
      dispatch(buscarDadosDeMedico(documMedOdont.numeroDocumento, documMedOdont.idUFDocumento));
    }
    dispatch({
      type: 'EXIBIR_DIALOG_DETALHAR_DOCUM_MED_ODONT',
      payload: documMedOdont
    });
  }
}

export const exibirDialogAlterarDocumMedOdont = documMedOdont => {
  return dispatch => {
    if(!documMedOdont?.dadosDoMedico 
        && documMedOdont?.idTipoIdentEmitente === TIPO_IDENT_EMITENTE_ATESTMED_CRM) {
      dispatch(buscarDadosDeMedico(documMedOdont.numeroDocumento, documMedOdont.idUFDocumento));
    }
    dispatch({
      type: 'EXIBIR_DIALOG_ALTERAR_DOCUM_MED_ODONT',
      payload: documMedOdont
    });
  }
};

export const exibirDialogExcluirDocumMedOdont = documMedOdont => ({
  type: 'EXIBIR_DIALOG_EXCLUIR_DOCUM_MED_ODONT',
  payload: documMedOdont
});

export const ocultarDialogExcluirDocumMedOdont = () => ({
  type: 'OCULTAR_DIALOG_EXCLUIR_DOCUM_MED_ODONT'
});

export const atualizarDadosValidacaoDocumMedOdont = dados => ({
  type: 'ATUALIZAR_DADOS_VALIDACAO_DOCUM_MED_ODONT',
  payload: dados
});

export const alterarDataDocumMedOdont = value => ({
  type: 'ALTERAR_DATA_DOCUM_MED_ODONT',
  payload: value
});

export const alterarTipoEmitenteDocumMedOdont = value => ({
  type: 'ALTERAR_TIPO_EMITENTE_DOCUM_MED_ODONT',
  payload: value
});


export const alterarOutroTipoEmitenteDocumMedOdont = value => ({
  type: 'ALTERAR_OUTRO_TIPO_EMITENTE_DOCUM_MED_ODONT',
  payload: value
});

export const alterarUfDocumMedOdont = value => ({
  type: 'ALTERAR_UF_DOCUM_MED_ODONT',
  payload: value
});

export const alterarNumeroDocumMedOdont = value => ({
  type: 'ALTERAR_NUMERO_DOCUMENTO_DOCUM_MED_ODONT',
  payload: value
});

export const alterarNomeProfissionalDocumMedOdont = value => ({
  type: 'ALTERAR_NOME_PROFISSIONAL_DOCUM_MED_ODONT',
  payload: value
});

export const alterarDescricaoLaudoDocumMedOdont = value => ({
  type: 'ALTERAR_DESCRICAO_LAUDO_DOCUM_MED_ODONT',
  payload: value
});

export const carregarDadosDeMedico = (isCargaDadosDoMedico) => ({
  type: 'CARREGAR_DADOS_DE_MEDICO',
  payload: isCargaDadosDoMedico
});

export const adicionarDadosDeMedicoEncontrado = (dadosDoMedico) => ({
  type: 'ADICIONAR_DADOS_DE_MEDICO_ENCONTRADO',
  payload: dadosDoMedico
});

export const adicionarMensagemErroDialogDocumMedOdont = (mensagem) => ({
  type: 'ADICIONAR_MENSAGEM_ERRO',
  payload: mensagem
});

export const buscarDadosDeMedico = (numeroDocumento, UfDocumento) => {
  return async (dispatch, getState) => {
    dispatch(adicionarMensagemErroDialogDocumMedOdont(null));
    dispatch(carregarDadosDeMedico(true));
    try {
      const UfEquivalente = getState().principal.dominioSdc.ufs.find(uf => uf.codigoUfIbge === UfDocumento)?.siglaUf;
      const response = await getSeguroPromise(`pmf-realizar-pericias-service/dados-medicos/obter-dado-medico`, { crm: numeroDocumento, estado: UfEquivalente });
      const dadosDeMedico = response.data;
      dispatch(adicionarDadosDeMedicoEncontrado(dadosDeMedico));
      dispatch(alterarNomeProfissionalDocumMedOdont(dadosDeMedico?.nome));
      dispatch(limparMsgErroNomeProfissional());
      if(!dadosDeMedico) {
        dispatch(adicionarMensagemErroDialogDocumMedOdont("Médico Assistente não encontrado"));
      }
      dispatch(carregarDadosDeMedico(false));
    } catch (error) {
      const mensagemErro = errorMessageAbstract(error, "Busca de dados de Médico");
      dispatch(adicionarMensagemErroDialogDocumMedOdont(mensagemErro));
      dispatch(carregarDadosDeMedico(false));
    }
  }
}

const limparMsgErroNomeProfissional = () => ({
  type: 'LIMPAR_MSG_ERRO_VALIDACAO_NOME_PROFISSIONAL'
});

export const limparDadosDeMedido = () => {
  return dispatch => {
    dispatch(adicionarMensagemErroDialogDocumMedOdont(null));
    dispatch(adicionarDadosDeMedicoEncontrado(null));
    dispatch(alterarNomeProfissionalDocumMedOdont(""))
  }
}