
export const obterMapRespostasGrupos = (respostasGrupos) => {
  // eslint-disable-next-line no-undef
  let mapRespostasGrupos = new Map();
  if (respostasGrupos) {
    respostasGrupos.forEach((resp) => {
      mapRespostasGrupos.set(resp.idGrupoFormulario, { ...resp })
    });
  }
  return mapRespostasGrupos;
}

export const obterMapRespostasPerguntas = (respostasPerguntas) => {
  // eslint-disable-next-line no-undef
  let mapRespostasPerguntas = new Map();
  if (respostasPerguntas) {
    respostasPerguntas.forEach((resp) => {
      mapRespostasPerguntas.set(resp.idPerguntaFormulario, { qualificador: resp.qualificador, dependenciaTerceiros: resp.dependenciaTerceiros })
    });
  }
  return mapRespostasPerguntas;
}

export const obterValoresPorDominios = (gruposFormulario, mapRespostasGrupos) => {
  // eslint-disable-next-line no-undef
  let valoresPorDominio = new Map();
  if (gruposFormulario) {
    gruposFormulario.forEach((grupoFormulario) => {
      let respostaGrupo = mapRespostasGrupos && mapRespostasGrupos.get(grupoFormulario.id);
      if (respostaGrupo && respostaGrupo.qualificador !== undefined &&
        (!valoresPorDominio.get(grupoFormulario.dominio) || respostaGrupo.qualificador > valoresPorDominio.get(grupoFormulario.dominio))) {
        valoresPorDominio.set(grupoFormulario.dominio, respostaGrupo.qualificador);
      }
    });
  }

  return valoresPorDominio;
}

export const obterDominios = (gruposFormulario) => {
  let dominios = [];
  if (gruposFormulario) {
    gruposFormulario.forEach((grupoFormulario) => {
      let dominioExiste = dominios.findIndex((item) => item === grupoFormulario.dominio) !== -1;
      if (!dominioExiste) {
        dominios.push(grupoFormulario.dominio);
      }
    });
  }
  return dominios;
}

export const qualificadoresAtividadesParticipacao = [
  { id: 0, sigla: 'N', descricao: 'Nenhuma Dificuldade', valorInicio: 0, valorFinal: 4 },
  { id: 1, sigla: 'L', descricao: 'Dificuldade Leve', valorInicio: 5, valorFinal: 24 },
  { id: 2, sigla: 'M', descricao: 'Dificuldade Moderada', valorInicio: 25, valorFinal: 49 },
  { id: 3, sigla: 'G', descricao: 'Dificuldade Grave', valorInicio: 50, valorFinal: 95 },
  { id: 4, sigla: 'C', descricao: 'Dificuldade Completa', valorInicio: 96, valorFinal: 100 }
];

export const obterQualificadorFinalAtividadesParticipacao = (valoresPorDominio) => {
  let percentualQualificador = undefined;
  let valores = [...valoresPorDominio.values()];

  let somatorioDominio = 0;
  if (valores.length > 0) {
      valores.forEach((valorDominio) => {
      somatorioDominio += valorDominio !== undefined ? valorDominio : 0;
    });
    percentualQualificador = somatorioDominio > 0 ? ((somatorioDominio * 5) - 1) : 0;
  }

  return percentualQualificador !== undefined && qualificadoresAtividadesParticipacao.find((qualificador) => percentualQualificador >= qualificador.valorInicio && percentualQualificador <= qualificador.valorFinal);
};


export const qualificadoresFuncoesCorpo = [
  { id: 0, sigla: 'N', descricao: 'Nenhuma Alteração', valorInicio: 0, valorFinal: 4 },
  { id: 1, sigla: 'L', descricao: 'Alteração Leve', valorInicio: 5, valorFinal: 24 },
  { id: 2, sigla: 'M', descricao: 'Alteração Moderada', valorInicio: 25, valorFinal: 49 },
  { id: 3, sigla: 'G', descricao: 'Alteração Grave', valorInicio: 50, valorFinal: 95 },
  { id: 4, sigla: 'C', descricao: 'Alteração Completa', valorInicio: 96, valorFinal: 100 }
];

export const obterQualificadorFinalFuncoesCorpo = (valoresPorDominio) => {
  let valor = undefined;
  [...valoresPorDominio.values()].forEach((valorDominio) => {
    if (valorDominio !== undefined && (valor === undefined || valorDominio > valor)) {
      valor = valorDominio;
    }
  });
  return valor !== undefined && qualificadoresFuncoesCorpo[valor];
};

export const obterQualificadorFuncoesCorpoElevado = (qualificador) => {
  if (qualificador && qualificador.sigla && qualificador.sigla !== 'C') {
    return qualificadoresFuncoesCorpo[qualificador.id + 1];
  }
  return qualificador;
}