export const tiposGrauDependencia = Object.freeze({
  TOTAL: {
    codigo: 'T', descricao: "Há enquadramento em dependência total."
  },
  PARCIAL: {
    codigo: 'P', descricao: "Há enquadramento em dependência parcial."
  },
  NENHUMA: {
    codigo: 'N', descricao: "Não há enquadramento."
  }
});

export const obterGrauDependencia = respostas => {
  const qtdQualificadores0 = respostas.filter(r => r.qualificador.toString() === "0").length;

  var grauDependencia;
  if (qtdQualificadores0 <= 1) {
    grauDependencia = tiposGrauDependencia.TOTAL.codigo;
  } else if (qtdQualificadores0 <= 4) {
    grauDependencia = tiposGrauDependencia.PARCIAL.codigo;
  } else {
    grauDependencia = tiposGrauDependencia.NENHUMA.codigo;
  }
  return grauDependencia;
}
