import React from "react";
import { Grid } from '@material-ui/core';

import TabelaExameLaudo from "./tabelaExameLaudo";
import BrCard from '@govbr/react-components/lib/BrCard';

const DetalheExameLaudo = ({ examesLaudos }) => {

  return (
    <>
      <Grid item xs={12}>
        <BrCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span className="texto-informativo">{examesLaudos.nomeCampo}</span>
            </Grid>
            <Grid item xs={12}>
              <p className="ml-10">
                Antes da revisão: 
                {(!examesLaudos.valorOrigem ||
                  examesLaudos.valorOrigem.length === 0) &&
                    <span className="texto-informativo ml-10 valor-anterior-revisao">Não apresentou exames e laudos</span>
                }
              </p>

              {(examesLaudos.valorOrigem &&
                examesLaudos.valorOrigem.length > 0) &&
                <TabelaExameLaudo exames={examesLaudos.valorOrigem} isValorAtual={false}/>
              }

            </Grid>

            <Grid item xs={12} >
                <p className="ml-10">
                  Após revisão: 
                  {(!examesLaudos.valorAtual ||
                    examesLaudos.valorAtual.length === 0) &&
                      <span className="texto-informativo ml-10 valor-atual-revisao">Não apresentou exames e laudos</span>
                  }
                </p>
              {(examesLaudos.valorAtual &&
                examesLaudos.valorAtual.length > 0) &&
                <TabelaExameLaudo exames={examesLaudos.valorAtual} isValorAtual={true}/>}

            </Grid>
          </Grid>
        </BrCard>
      </Grid>

    </>);
};


export default DetalheExameLaudo;

