/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import ModuloFormularioGrupo from '../ModuloFormulario/ModuloFormularioGrupo';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DominioConsolidado from './DominioConsolidado';
import Qualificadores from './Qualificadores';
import QualificadorFinal from './QualificadorFinal';
import { decodeHtml } from '../../common/StringUtils';

import { Grid } from "@material-ui/core";

import { obterMapRespostasGrupos, obterValoresPorDominios, obterMapRespostasPerguntas } from './utils';

const obterValidacaoGrupo = (validacao, grupoFormulario) => {
  let validacaoGrupoJustificativa = validacao &&
    validacao.gruposComErroJustificativa &&
    validacao.gruposComErroJustificativa.find((grupo) => grupo.id === grupoFormulario.id);
  let validacaoGrupoDominio = validacao &&
    validacao.gruposComErroDominio &&
    validacao.gruposComErroDominio.find((grupo) => grupo.id === grupoFormulario.id);
  return {
    validacaoGrupoJustificativa: validacaoGrupoJustificativa && validacaoGrupoJustificativa.erro,
    validacaoGrupo: validacaoGrupoDominio && validacaoGrupoDominio.erro
  }
}

const ModuloFormulario = props => {
  const { formulario, validacao, handlerObterQualificadorFinal,
    infoSelecaoQualificador, isSomenteLeitura } = { ...props }

  const [refsGruposForms] = useState(() => {
    let refsGruposForms = formulario && formulario.gruposFormulario && formulario.gruposFormulario.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
    return refsGruposForms;
  });


  useEffect(() => {
    let grupoComErros = []
    if (validacao && validacao.gruposComErroJustificativa) {
      grupoComErros = grupoComErros.concat(validacao.gruposComErroJustificativa);
    }
    if (validacao && validacao.gruposComErroDominio) {
      grupoComErros = grupoComErros.concat(validacao.gruposComErroDominio);
    }
    if (grupoComErros.length > 0) {
      let primeiroGrupoComErro = grupoComErros.sort((a, b) => a.ordem - b.ordem)[0];
      let refPrimeiroGrupoComErro = refsGruposForms[primeiroGrupoComErro.id];
      if (refPrimeiroGrupoComErro) {
        refPrimeiroGrupoComErro.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [validacao, refsGruposForms])

  if (!props.formulario) {
    return null;
  }

  const mapRespostasGrupos = obterMapRespostasGrupos(props.respostasGrupos);
  const mapRespostasPerguntas = obterMapRespostasPerguntas(props.respostasPerguntas);
  const valoresPorDominio = obterValoresPorDominios(props.formulario.gruposFormulario, mapRespostasGrupos);
  const qualificadorFinal = handlerObterQualificadorFinal(valoresPorDominio);
  const exibirDependencia = formulario.gruposFormulario && formulario.gruposFormulario.some(grupo => grupo.exibeDependencia === 'SIM');

  return (
    <div style={{ marginBottom: '30px' }}>
      <div className="container-qualificador-descricao">
        <Qualificadores qualificadores={props.qualificadores} />
        {
          props.formulario.descricao &&
          <div className="card descricao-formulario"
            dangerouslySetInnerHTML={{ __html: decodeHtml(props.formulario.descricao) }} />
        }
      </div>
      {
        exibirDependencia &&
        <div className="card mb-15">
          As colunas T e P, à direita dos qualificadores de cada domínio, destinam-se à sinalização de dependência Total ou Parcial de cuidados de terceiros,
          sempre que o avaliador julgar pertinente, com vistas a fornecer subsídios para políticas públicas de cuidados.
          Não representam o foco principal da avaliação, que deve estar centrado no desempenho para o exercício de atividades e participação social,
          conforme o grau de dificuldade estabelecido pelos cortes percentuais acima.
       </div>
      }
      {
        props.formulario.gruposFormulario &&
        props.formulario.gruposFormulario.map((grupoFormulario) => {

          return (
            <ModuloFormularioGrupo key={grupoFormulario.id}
              id={grupoFormulario.id}
              indicador={grupoFormulario.indicador}
              indice={grupoFormulario.indice}
              nome={grupoFormulario.nome}
              dominio={grupoFormulario.dominio}
              descricao={grupoFormulario.descricao}
              observacao={grupoFormulario.observacao}
              perguntas={grupoFormulario.gruposFormPerguntas}
              respostaGrupo={mapRespostasGrupos && mapRespostasGrupos.get(grupoFormulario.id)}
              mapRespostasPerguntas={mapRespostasPerguntas}
              opcoesJustQualificadorDominioZero={props.opcoesJustQualificadorDominioZero}
              validacao={obterValidacaoGrupo(validacao, grupoFormulario)}
              refGrupoForm={refsGruposForms[grupoFormulario.id]}
              exibirDependencia={grupoFormulario.exibeDependencia === 'SIM'}
              exibirJustQualificadorZero={grupoFormulario.exibeJustQualifZero === 'SIM'}
              qtdeRespostasRequeridas={grupoFormulario.qtdeRespostasRequeridas}
              infoSelecaoQualificador={infoSelecaoQualificador || 'Qualifique de 0 a 4 cada unidade de classificação abaixo:'}
              isSomenteLeitura={isSomenteLeitura} />
          )
        }
        )
      }
      <h5 className="titulo-qualificador-final">{props.formulario.nome}</h5>
      <TableContainer style={{ marginBottom: '20px' }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {
                props.formulario.gruposFormulario.map((grupoFormulario) =>
                  <TableCell key={grupoFormulario.id} style={{ padding: '0 5px', fontWeight: 'bold', fontSize: '0.8em', textAlign: 'center' }}>
                    {grupoFormulario.indice} - {grupoFormulario.dominio}
                  </TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {
                props.formulario.gruposFormulario.map((grupoFormulario) =>
                  <TableCell key={grupoFormulario.id} style={{ textAlign: 'center' }}>
                    {mapRespostasGrupos && mapRespostasGrupos.get(grupoFormulario.id) ? mapRespostasGrupos.get(grupoFormulario.id).qualificador : null}
                  </TableCell>
                )
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DominioConsolidado gruposFormulario={formulario.gruposFormulario} valoresPorDominio={valoresPorDominio} />
          </Grid>
          <Grid item xs={2}>
            {/* espacamento */}
          </Grid>
          <Grid item xs={4}>
            <QualificadorFinal qualificadorFinal={qualificadorFinal} textoQualificadorFinal={props.textoQualificadorFinal} />
          </Grid>
        </Grid>
      </div>

    </div>
  )
}

export default ModuloFormulario
