import history from '../../common/history';
import { salvarPericia } from '../pericia/action';
import { existeModuloEstaticoNoTemplate } from '../pericia/templatePericiaUtil';
import { obterDadosValidacaoCampos } from '../pericia/validacaoCampos';

import { obterUsuarioLogado, possuiPermissoes } from '../../common/AuthManager';
import { REAPER } from '../../common/permissoes';
import { getSeguro, getSeguroPromise } from "../../common/requests";
import { modulosEstaticos, tiposPericia } from '../pericia/constants';

import React from 'react';

import { errorMessageAbstract } from '../../common/Utils';
import AbaTemplate from '../principal/AbaTemplate';

export const atualizarTemplatePericia = template => {
  const abas = obterAbasTemplatePericia(template);
  const abaDefault = obterAbaDaUrl(abas);
  const existeModuloNexoNoTemplate = existeModuloEstaticoNoTemplate(template, modulosEstaticos.NEXO_TECNICO.codigo);
  const existeModuloRestabelecimentoNoTemplate = existeModuloEstaticoNoTemplate(template, modulosEstaticos.RESTABELECIMENTO_BENEFICIO.codigo);
  const existeModuloIsencaoNoTemplate = existeModuloEstaticoNoTemplate(template, modulosEstaticos.ISENCAO_CARENCIA.codigo);

  return (dispatch) => {
    dispatch({
      type: 'ATUALIZAR_TEMPLATE_PERICIA',
      payload: {
        template,
        existeModuloNexoNoTemplate,
        existeModuloRestabelecimentoNoTemplate,
        existeModuloIsencaoNoTemplate,
        isPericiaBPC: template.tipoPericia === tiposPericia.INSS_BPC_PI.tipo,
        isPericiaPermiteSima: template.permiteSima === "SIM"
      }
    });
    dispatch(atualizarListaAbas(abas));
    dispatch(atualizarAbaSelecionada(abaDefault));
  }
}

const obterAbaDaUrl = abas => {
  const currentLocation = history.location.pathname;
  const url = currentLocation.substring(currentLocation.lastIndexOf('/') + 1);

  const aba = abas.find(a => a.path === url) || abas[0];

  return aba.id;
}


export const redirecionarParaAba = (urlAba, abas) => {
  return (dispatch) => {
    const aba = abas.find(a => a.path === urlAba);

    if (aba) {

      dispatch(atualizarAbaSelecionada(aba.id));
      history.push(`/pericia/${urlAba}`);

    } else {
      dispatch(errorMessage({ message: "Não foi possível redirecionar para aba 'Nexo Técnico'" }, 'Redirect'));
    }
  }
}

const obterAbasTemplatePericia = template => {
  if (!template || !template.id || !template.modulosTemplatePericia) {
    return [];
  }
  return template.modulosTemplatePericia.map(moduloTemplate => {
    const idAba = moduloTemplate.id;
    const moduloEstatico = Object.values(modulosEstaticos).find(e => e.codigo === moduloTemplate.modulo.tipoModuloEstatico);

    if (moduloEstatico) {
      const nomeAba = moduloTemplate.tituloModulo ? moduloTemplate.tituloModulo : moduloTemplate.modulo.nome;
      return {
        id: moduloEstatico.codigo,
        label: nomeAba,
        path: moduloEstatico.nome,
        conteudo: <AbaTemplate label={nomeAba} moduloEstatico={moduloEstatico} />
      }
    }

    const nome = moduloTemplate.modulo?.formulario ? `formulario_generico_${idAba}` : `modulo_generico_${idAba}`; //TODO: usar hash?
    const labelAba = moduloTemplate.tituloModulo 
      ? moduloTemplate.tituloModulo 
      : (moduloTemplate?.modulo?.nome ? moduloTemplate?.modulo?.nome : `Modulo Genérico ${idAba}`);
    return {
      id: idAba,
      label: labelAba,
      path: nome,
      conteudo: <AbaTemplate nomeModuloGenerico={nome} label={labelAba} moduloTemplate={moduloTemplate} />
    }
  });
}

export const validarCamposAbaSelecionada = (abaSelecionada, pericia, abas) => {
  return async (dispatch, getState) => {
    try {
      const resultado = obterDadosValidacaoCampos(abaSelecionada, pericia, getState);
      if (resultado) {
        dispatch(atualizarDadosValidacaoAba(resultado.dados));
        let sucessoSalvarPericia = await dispatch(salvarPericia(pericia));
        if (sucessoSalvarPericia && resultado.deveProsseguir) {
          const proximaAba = abas.find(a => a.path === resultado.url);
          dispatch(atualizarAbaSelecionada(proximaAba ? proximaAba.id : 1));
          history.push(`/pericia/${resultado.url}`);
        }

      } else {
        dispatch(errorMessage({ message: "Nenhum resultado foi retornado" }, 'Validar campos'));
      }
    } catch (err) {
      dispatch(errorMessage(err, 'Validar'));
    }
  }
};

export const atualizarDadoValidacaoModulo = (nomeModulo, dadosValidacao) => ({
  type: 'ATUALIZAR_DADO_VALIDACAO_MODULO',
  payload: {
    modulo: nomeModulo,
    novaValidacao: dadosValidacao
  }
});

const atualizarDadosValidacaoAba = dados => ({
  type: 'ATUALIZAR_DADOS_VALIDACAO',
  payload: dados
});

export const atualizarDadosValidacaoCompleta = validacao => ({
  type: 'ATUALIZAR_VALIDACAO_COMPLETA',
  payload: validacao
});

export const buscarValoresDominioPericia = () => {
  return async (dispatch) => {
    try {
      const response = await getSeguroPromise(
        "pmf-realizar-pericias-service/dominio/pericia/"
      );
      dispatch(atualizarCamposDominioPericia(response.data));
    } catch (err) {
      dispatch(errorMessage(err, "Consulta dominios"));
      throw err;
    }
  };
};

export const buscarValoresDominioSDC = () => {
  return async (dispatch) => {
    try {
      const response = await getSeguroPromise(
        "pmf-realizar-pericias-service/dominio/sdc"
      );
      dispatch(atualizarCamposDominioSdc(response.data));
    } catch (err) {
      dispatch(errorMessage(err, "Consulta dominio SDC"));
      throw err;
    }
  };
};

export const buscarValoresDominioPericiaBI = () => {
  return async (dispatch) => {
    try {
      const response = await getSeguroPromise('pmf-realizar-pericias-service/dominio/periciaBI/');
      dispatch(atualizarCamposDominioPericiaBI(response.data));
    } catch (err) {
      dispatch(errorMessage(err, 'Consulta dominio Perícia BI'));
      throw err;
    }
  }
}

export const obterListaCidPorCodigoOuNome = (stringConsulta, acaoSucesso, acaoFalha, origemConsulta) => {
  const stringConsultaTratada = stringConsulta.replace(/\[|\]/g, ''); //removendo caracteres invalidos
  return (dispatch) => {
    getSeguroPromise(`pmf-realizar-pericias-service/cid/listaFiltrada/${stringConsultaTratada}`)
      .then((response) => {
        dispatch(acaoSucesso(response.data));
        dispatch(limparMensagem());
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, `Consulta CIDs ${origemConsulta}`));
        dispatch(acaoFalha());
      });
  };
};

export const obterFatoresRiscoCidPorCodigo = (cidPrincipal, acaoSucesso, acaoFalha) => {
  return (dispatch) => getSeguroPromise(`pmf-realizar-pericias-service/nexo/fatores-risco?codigoCID=${cidPrincipal}`)
    .then((response) => {
      dispatch(acaoSucesso(response.data));
      dispatch(limparMensagem());
    })
    .catch((falha) => {
      dispatch(errorMessage(falha, `Consulta fatores de risco CID ${cidPrincipal}`));
      dispatch(acaoFalha());
    });
};

export const obterBatimentoCidCnae = (cidPrincipal, listaCnaes, acaoSucesso, acaoFalha) => {
  return (dispatch) => getSeguroPromise(`pmf-realizar-pericias-service/nexo/ntep?codigoCID=${cidPrincipal}&codigosCNAE=${listaCnaes}`)
    .then((response) => {
      dispatch(acaoSucesso(response.data));
      dispatch(limparMensagem());
    })
    .catch((falha) => {
      dispatch(errorMessage(falha, `Consulta batimento CID ${cidPrincipal} x CNAE`));
      dispatch(acaoFalha());
    });
};

export const isCidsDoMesmoGrupo = (cidPrincipal, cidAnterior, acaoSucesso, acaoFalha) => {
  return (dispatch) => getSeguroPromise(`pmf-realizar-pericias-service/cid/verificar-cids-no-mesmo-grupo?codigoCid1=${cidPrincipal}&codigoCid2=${cidAnterior}`)
    .then((response) => {
      dispatch(acaoSucesso(cidAnterior, response.data));
      dispatch(limparMensagem());
    })
    .catch((falha) => {
      dispatch(errorMessage(falha, `Consulta CIDs ${cidPrincipal} e ${cidAnterior} do mesmo grupo`));
      dispatch(acaoFalha(cidAnterior));
    });
};

export const limparMensagem = () => ({
  type: 'PRINCIPAL_CLEAR_MESSAGE'
});

const atualizarCamposDominioPericia = (value) => ({
  type: 'ATUALIZAR_CAMPOS_DOMINIO_PERICIA',
  payload: value
});

const atualizarCamposDominioPericiaBI = (value) => ({
  type: 'ATUALIZAR_CAMPOS_DOMINIO_PERICIA_BI',
  payload: value
});

const atualizarCamposDominioSdc = (value) => ({
  type: 'ATUALIZAR_CAMPOS_DOMINIO_SDC',
  payload: value
});

export const atualizarAbaSelecionada = (value) => ({
  type: 'SELECIONAR_ABA',
  payload: value
});

const atualizarListaAbas = (value) => ({
  type: 'ATUALIZAR_LISTA_ABAS',
  payload: value
});

export const atualizarTipoAcessoDados = dadosAcesso => {
  return (dispatch, getState) => {
    let usuarioLogado = obterUsuarioLogado();
    let pericia = dadosAcesso.pericia;

    if (!pericia) {
      pericia = getState().pericia;
    }

    let isUsuarioLogadoResponsavel = usuarioLogado.cpf === pericia.cpfUsuarioToken && pericia.responsavel;
    let acessoPorToken = pericia.responsavel !== undefined;

    dispatch({
      type: 'ATUALIZAR_TIPO_ACESSO',
      payload: {
        situacao: dadosAcesso.situacao,
        simaSolicitado: dadosAcesso.simaSolicitado,
        acessoUsuarioResponsavel: isUsuarioLogadoResponsavel,
        acessoPorToken,
        isPossuiPermissaoRealizarPericia: possuiPermissoes(REAPER),
        isSomenteLeitura: dadosAcesso.isSomenteLeitura
      }
    })
  }
}

export const displayLoader = (visible) => ({
  type: 'PRINCIPAL_LOADING',
  payload: visible
});

export const errorMessage = (error, prefixo) => {
  return {
    type: 'PRINCIPAL_ERROR_MESSAGE',
    payload: errorMessageAbstract(error, prefixo)
  }
}

export const successMessage = message => {
  return {
    type: 'PRINCIPAL_SUCCESS_MESSAGE',
    payload: message
  }
}

export const consultaMessage = message => {
  return {
    type: 'PRINCIPAL_CONSULTA_MESSAGE',
    payload: message
  }
}

export const obterInfoAPI = () => {
  return (dispatch) => {
    getSeguro(dispatch, 'pmf-realizar-pericias-service/api/info', sucessoObterInfoAPI, erroObterInfoAPI);
  }
}

const sucessoObterInfoAPI = (data) => {
  return {
    type: 'OBTER_INFO_API_SUCESSO',
    payload: data
  }
}

const erroObterInfoAPI = (falha) => {
  return [
    dispatch => {
      dispatch(errorMessage(falha, 'Obter Info API'))
    },
    {
      type: 'OBTER_INFO_API_ERRO'
    }
  ]
}

export const obterInfoAPIManterPericia = () => {
  return (dispatch) => {
    getSeguro(dispatch, 'pmf-manter-pericias-service/api/info', sucessoObterInfoAPIManterPericia, erroObterInfoAPIManterPericia);
  }
}

const sucessoObterInfoAPIManterPericia = (data) => {
  return {
    type: 'OBTER_INFO_API_MANTER_SUCESSO',
    payload: data
  }
}

const erroObterInfoAPIManterPericia = (falha) => {
  return [
    dispatch => {
      dispatch(errorMessage(falha, 'Obter Info API Manter Perícia'))
    },
    {
      type: 'OBTER_INFO_API_MANTER_ERRO'
    }
  ]
}

export const limparTemplate = () => {
  return {
    type: 'LIMPAR_TEMPLATE'
  }
}