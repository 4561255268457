import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderSecao from '../../../../components/HeaderSecao';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTooltip from '@govbr/react-components/lib/BrTooltip';
import { Grid } from "@material-ui/core";
import BrInput from '@govbr/react-components/lib/BrInput';

import { alterarDoencaoIsencao, alterarIsencaoCarencia, 
  alterarIsencaoCarenciaGestacaoRisco, alterarJustificativaGestacaoAltoRisco } from '../../action';
import { calcularCaracteresRestantes, verificaCidEmGrupo } from '../../../../common/Utils';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

const QTD_MAXIMA_CARACTERES = 2000;
const OPCAO_VAZIA = { label: '', value: null };

const IsencaoCarencia = (props) => {

  const isencaoCarenciaRef = useRef();

  const [openTooltip, setOpenTooltip] = useState(false);
  const [opcoesIsencao, setopcoesIsencao] = useState([]);
  const [possuiIsencao, setPossuiIsencao] = useState(0);
  const [possuiCidGrupoO, setPossuiCidGrupoO] = useState(false)
  const [caracteresJustificativaRestantes, setCaracteresJustificativaRestantes] = useState(QTD_MAXIMA_CARACTERES);
  const [possuiIsencaoGestacaoRisco, setPossuiIsencaoGestacaoRisco] = useState(0);
  const [existeNexo, setExisteNexo] = useState(false);

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || existeNexo;

  const handleTooltipIsencaoCarencia = () => {
    setOpenTooltip(!openTooltip);
  };

  useEffect(() => {
    const moduloIsencaoCarencia = props.moduloIsencaoCarencia;

    const justificativa = props.moduloIsencaoCarencia ? props.moduloIsencaoCarencia.justificativaGestacaoAltoRisco : "";    
    setPossuiIsencao(moduloIsencaoCarencia != null && moduloIsencaoCarencia.isentaCarencia);
    setPossuiIsencaoGestacaoRisco(moduloIsencaoCarencia != null && moduloIsencaoCarencia.isentaCarenciaPorGestacaoRisco);
    setCaracteresJustificativaRestantes(calcularCaracteresRestantes(justificativa, QTD_MAXIMA_CARACTERES))
    setExisteNexo(props.moduloNexoTecnico
      && (props.moduloNexoTecnico.existeNexoProfissional === 1
        || props.moduloNexoTecnico.existeNexoIndividual === 1
        || props.moduloNexoTecnico.existeNtep === 1));

    const tiposDoencaIsencao = props.dominioPericia.tiposDoencaIsencao;
    const opcoesIsencao = tiposDoencaIsencao
      ? tiposDoencaIsencao.map(t => ({ label: t.descricao, value: t.id }))
      : [];
    opcoesIsencao.unshift(OPCAO_VAZIA);

    setPossuiCidGrupoO(props.moduloCid && verificaCidEmGrupo(props.moduloCid.cidPrincipal, "O"))

    setopcoesIsencao(opcoesIsencao);
  }, [props.moduloIsencaoCarencia, props.dominioPericia.tiposDoencaIsencao, props.moduloNexoTecnico, props.moduloCid]);

  return (
    <>
      <HeaderSecao texto="Isenção de Carência" />

      {
        existeNexo && (
          <BrMessage warning>
            O enquadramento do Nexo Técnico resulta em isenção de carência sem a necessidade de justificativa.
          </BrMessage>
        )
      }

      {
        possuiCidGrupoO && !existeNexo && 
        <>
          <Grid container spacing={2} direction="row">
            <Grid item>
              <BrRadio id="rdIsencaoCarenciaGestacaoRisco"
                label="Trata-se de gestação de alto risco para isenção de carência conforme determinação judicial na ACP nº5051528-83.2017.4.04.7100?"
                value={possuiIsencaoGestacaoRisco}
                items={props.dominioPericia.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={value => props.alterarIsencaoCarenciaGestacaoRisco(parseInt(value))}
                erro={props.validacao && props.validacao.erroRespostaIsencaoGestacaoRiscoObrigatoria}
                disabled={props.isSomenteLeitura || existeNexo}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
            <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea id="justificativaGestaoAltoRisco"
                    label="Justificativa"
                    onChange={props.alterarJustificativaGestacaoAltoRisco}
                    value={props.moduloIsencaoCarencia ? props.moduloIsencaoCarencia.justificativaGestacaoAltoRisco : ""}
                    maxLength={QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresJustificativaRestantes}`}
                    erro={props.validacao ? props.validacao.erroRespostaJustificativaGestacaoRiscoObrigatoria : null}
                    disabled={isCampoTextareaDesabilitado} />
                </Grid>
                    {
                      !isCampoTextareaDesabilitado &&
                      <Grid item >
                        <ButtonSelecionarItem
                          id="selecionarItemDicionarioJustGestaoAltoRisco"
                          onSelect={props.alterarJustificativaGestacaoAltoRisco}
                          maxLength={caracteresJustificativaRestantes}
                          valorAtual={props.moduloIsencaoCarencia?.justificativaGestacaoAltoRisco}/>
                      </Grid>
                    }
                </Grid>
            </Grid>
          </Grid>
        </>
      }

      {
        possuiIsencaoGestacaoRisco === 1 && !existeNexo && (
          <BrMessage warning>
            A isenção de carência foi automaticamente aplicada com base no CID informado, de acordo com a ACP de Gestação de Risco.
          </BrMessage>
        )
      }

      <Grid container spacing={2} direction="row">
        <Grid item>
          <BrRadio id="rdIsencaoCarencia"
            label="* Isenta carência por motivo de doença ou acidente de qualquer natureza conforme previsto na Lei nº 8.213/91?"
            value={possuiIsencao}
            items={props.dominioPericia.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
            onChange={value => props.alterarIsencaoCarencia(parseInt(value))}
            erro={props.validacao && props.validacao.erroRespostaIsencaoObrigatoria}
            disabled={props.isSomenteLeitura || existeNexo || possuiIsencaoGestacaoRisco}
          />
        </Grid>
        {!possuiCidGrupoO && 
          <Grid item>
            <BrTooltip
              id="infoIsencaoCarencia"
              anchorEl={isencaoCarenciaRef.current}
              open={openTooltip}
              onClose={handleTooltipIsencaoCarencia}
              top
              info
            >
              <BrTooltip.PopoverBody>
                <span className="subtext">
                  <u>Lei nº 8.213/91, art. 151:</u>
                  <br />
                  - Tuberculose ativa<br />
                  - Hanseníase<br />
                  - Transtorno mental grave, desde que esteja cursando com alienação mental<br />
                  - Neoplasia maligna<br />
                  - Cegueira<br />
                  - Paralisia irreversível e incapacitante<br />
                  - Cardiopatia grave<br />
                  - Doença de parkinson<br />
                  - Espondilite anquilosante<br />
                  - Nefropatia grave<br />
                  - Estado avançado da doença de paget (osteíte deformante)<br />
                  - Síndrome da deficiência imunológica adquirida (aids)<br />
                  - Contaminação por radiação, com base em conclusão da medicina especializada<br />
                  - Hepatopatia grave<br />
                  - Acidente de qualquer natureza ou causa<br />
                  - Esclerose múltipla<br />
                  - Acidente vascular encefálico (agudo)<br />
                  - Abdome agudo cirúrgico<br />
                </span>
              </BrTooltip.PopoverBody>
            </BrTooltip>
            <i className="fas fa-info-circle" aria-hidden="true"
              ref={isencaoCarenciaRef}
              onClick={handleTooltipIsencaoCarencia}
            />
          </Grid>
        }
      </Grid>

      {
        possuiIsencao === 1 && !existeNexo && 
        (
          possuiIsencaoGestacaoRisco 
          ? 
          <Grid item xs={6}>
            <BrInput id="tipoDoencaIsencaoGestaoRisco" 
              label="Selecione a condição legal:" 
              value={"Gravidez de Alto Risco - ACP nº5051528-83.2017.4.04.7100"} 
              disabled />
          </Grid>
          :
          <Grid item xs={6}>
            <BrSelect id="tipo" label="Selecione a doença:"
              items={opcoesIsencao}
              value={props.moduloIsencaoCarencia && props.moduloIsencaoCarencia.tipoDoencaIsencao
                ? opcoesIsencao.find(o => o.value === props.moduloIsencaoCarencia.tipoDoencaIsencao)
                : OPCAO_VAZIA}
              onChange={props.alterarDoencaoIsencao}
              erro={props.validacao && props.validacao.erroDoencaoIsencaoObrigatoria}
              disabled={props.isSomenteLeitura || existeNexo} />
          </Grid>
        )
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { dominioPericia, isSomenteLeitura, validacao } = state.principal;
  const { moduloIsencaoCarencia, moduloNexoTecnico, moduloCid } = state.pericia;
  return ({
    dominioPericia,
    isSomenteLeitura,
    validacao: validacao.isencaoCarencia,
    moduloIsencaoCarencia,
    moduloNexoTecnico,
    moduloCid
  });

};

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarIsencaoCarencia,
  alterarDoencaoIsencao,
  alterarIsencaoCarenciaGestacaoRisco,
  alterarJustificativaGestacaoAltoRisco
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(IsencaoCarencia);
