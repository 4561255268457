/**
 * Created by patrick.cunha@dataprev.gov.br
 */
import axios from 'axios';
import { OFFLINE_MESSAGE } from './Messages';
import { AUTH_PATH_LOGOUT_OUT, BACKEND_INDEX } from './constants';

import { displayLoader } from '../features/principal/action';

const prefixoRequest = BACKEND_INDEX;

const config = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const getHeadersNaoSeguro = () => {
  return {
    ...config
  };
};

const getHeaders = () => {
  const token = localStorage.getItem('ifs_auth');
  if (!token || token === '') {
    window.open(AUTH_PATH_LOGOUT_OUT, '_self');
  }
  return {
    ...config, Authorization: `Bearer ${token}`
  };
};

/* eslint-disable */
const resolveUrl = (url) => {
  if (typeof url === 'string') {
    return { path: prefixoRequest + url }
  }
  if (url.params) {
    url.path += url.path.indexOf('?') > -1 ? '&' : '?';
  }
  for (let key in url.params) {
    if (url.params.hasOwnProperty(key)) {
      if (Array.isArray(url.params[key])) {
        let a = url.params[key];
        for (let i = 0; i < a.length; i++) {
          url.path += key + '=' + a[i] + '&';
        }
      } else {
        url.path += key + '=' + url.params[key] + '&';
      }
    }
  }
  let { path } = url.path;
  if (url.path.substr(url.path.length - 1) == '&') {
    path = url.path.slice(0, -1);
  }
  return { path: prefixoRequest + path, params: url.params };
};

const getOfflineError = () => ({
  response: {
    data: {
      mensagem: OFFLINE_MESSAGE
    }
  }
})

/* ------- GET ------- */

export const getNaoSeguroPromise = (path, params) => {
  const uri = prefixoRequest + path;

  return axios.get(uri, { headers: getHeadersNaoSeguro(), params });
};

export const getSeguroPromise = (path, params) => {
  const uri = prefixoRequest + path;

  return axios.get(uri, { headers: getHeaders(), params });
};

export const getNaoSeguro = (dispatch, url, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  let head = getHeadersNaoSeguro();
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.get(urlObj.path, { headers: head })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

export const getSeguro = (dispatch, url, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  let head = getHeaders();
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.get(urlObj.path, { headers: head })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

// PDF

export const getNaoSeguroPdf = (dispatch, url, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  dispatch(setLoadingPage(true));
  return axios.get(urlObj.path, { headers: getHeadersNaoSeguro(), responseType: 'arraybuffer', params: urlObj.params })
    .then(response => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoSucesso(response.data))
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

export const getSeguroPdf = (dispatch, url, acaoSucesso, acaoFalha, nomeArquivo) => {
  const urlObj = resolveUrl(url);
  dispatch(setLoadingPage(true));
  return axios.get(urlObj.path, { headers: getHeaders(), responseType: 'blob', params: urlObj.params })
    .then(response => {
      dispatch(setLoadingPage(false));
      let sucesso = downloadArquivo(response.data, nomeArquivo)
      if (!sucesso) {
        return dispatch(acaoFalha("Falha ao realizar download!"))
      }
      return dispatch(acaoSucesso())
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

export const getNaoSeguroCache = (dispatch, path, acaoSucesso, acaoFalha) => {
  const url = prefixoRequest + path;
  const params = {
    method: 'GET',
    headers: getHeadersNaoSeguro()
  }
  dispatch(setLoadingPage(true));
  fetch(url, params)
    .then(response => {
      if (response.ok) {
        if ('serviceWorker' in navigator) {
          const result = response.clone();
          caches.open("meuinssec-requisicoes").then(cache => {
            cache.put(url, result);
          });
        }
        dispatch(setLoadingPage(false));
        return response.json();
      } else {
        dispatch(setLoadingPage(false));
        return Promise.reject(new Error(response.statusText))
      }
    })
    .then(result => {
      dispatch(setLoadingPage(false));
      dispatch(acaoSucesso(result));
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      dispatch(acaoFalha(falha))
    });
}

export const getSeguroCache = (dispatch, path, acaoSucesso, acaoFalha) => {
  const url = prefixoRequest + path;
  const params = {
    method: 'GET',
    headers: getHeaders()
  }
  dispatch(setLoadingPage(true));
  fetch(url, params)
    .then(response => {
      if (response.ok) {
        if ('serviceWorker' in navigator) {
          const result = response.clone();
          caches.open("meuinssec-requisicoes").then(cache => {
            cache.put(url, result);
          });
        }
        dispatch(setLoadingPage(false));
        return response.json();
      } else {
        dispatch(setLoadingPage(false));
        return Promise.reject(new Error(response.statusText))
      }
    })
    .then(result => {
      dispatch(setLoadingPage(false));
      dispatch(acaoSucesso(result));
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      dispatch(acaoFalha(falha))
    });
}


/* ------- POST ------- */

export const postNaoSeguro = (dispatch, path, data, acaoSucesso, acaoFalha) => {
  const uri = prefixoRequest + path;
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.post(uri, data, { headers: getHeadersNaoSeguro() })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

export const postSeguro = (dispatch, path, data, acaoSucesso, acaoFalha) => {
  const uri = prefixoRequest + path;
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.post(uri, data, { headers: getHeaders() })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

export const postNaoSeguroPromise = (path, data) => {
  const uri = prefixoRequest + path;

  return axios.post(uri, data, { headers: getHeadersNaoSeguro() });
};

export const postSeguroPromise = (path, data) => {
  const uri = prefixoRequest + path;

  return axios.post(uri, data, { headers: getHeaders() });
};

export const postNaoSeguroUploadProgress = (dispatch, path, data, acaoSucesso, acaoFalha, progressoCallback) => {
  const uri = prefixoRequest + path;
  const config = {
    headers: getHeadersNaoSeguro(),
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      progressoCallback(percentCompleted)
    }
  }
  dispatch(setLoadingPage(true));
  return axios.post(uri, data, config)
    .then(response => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoSucesso(response.data))
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

export const postUploadProgress = (dispatch, path, data, acaoSucesso, acaoFalha, progressoCallback) => {
  const uri = prefixoRequest + path;
  const config = {
    headers: getHeaders(),
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      progressoCallback(percentCompleted)
    }
  }
  dispatch(setLoadingPage(true));
  return axios.post(uri, data, config)
    .then(response => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoSucesso(response.data))
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

export const postNaoSeguroCache = (dispatch, path, data, acaoSucesso, acaoFalha) => {
  const url = prefixoRequest + path;
  const params = {
    method: 'POST',
    headers: getHeadersNaoSeguro(),
    body: JSON.stringify(data)
  }
  dispatch(setLoadingPage(true));
  fetch(url, params)
    .then(response => {
      if (response.ok) {
        if ('serviceWorker' in navigator) {
          const result = response.clone();
          caches.open("meuinssec-requisicoes").then(cache => {
            cache.put(url, result);
          });
        }
        dispatch(setLoadingPage(false));
        if (response.status === 204) return ''
        return response.json();
      } else {
        dispatch(setLoadingPage(false));
        return Promise.reject(new Error(response.statusText))
      }
    })
    .then(result => {
      dispatch(acaoSucesso(result));
    })
    .catch(falha => {
      dispatch(acaoFalha(falha))
    });
}

export const postSeguroCache = (dispatch, path, data, acaoSucesso, acaoFalha) => {
  const url = prefixoRequest + path;
  const params = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data)
  }
  dispatch(setLoadingPage(true));
  fetch(url, params)
    .then(response => {
      if (response.ok) {
        if ('serviceWorker' in navigator) {
          const result = response.clone();
          caches.open("meuinssec-requisicoes").then(cache => {
            cache.put(url, result);
          });
        }
        dispatch(setLoadingPage(false));
        if (response.status === 204) return ''
        return response.json();
      } else {
        dispatch(setLoadingPage(false));
        return Promise.reject(new Error(response.statusText))
      }
    })
    .then(result => {
      dispatch(acaoSucesso(result));
    })
    .catch(falha => {
      dispatch(acaoFalha(falha))
    });
}

/* ------- PUT ------- */

export const putNaoSeguroPromise = (path, data) => {
  const uri = prefixoRequest + path;

  return axios.put(uri, data, { headers: getHeadersNaoSeguro() });
};

export const putSeguroPromise = (path, data) => {
  const uri = prefixoRequest + path;

  return axios.put(uri, data, { headers: getHeaders() });
};

export const putNaoSeguro = (dispatch, url, data, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.put(urlObj.path, data, { headers: getHeadersNaoSeguro(), params: urlObj.params })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }

};

export const putSeguro = (dispatch, url, data, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  if (navigator.onLine) {
    dispatch(setLoadingPage(true));
    return axios.put(urlObj.path, data, { headers: getHeaders(), params: urlObj.params })
      .then(response => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        dispatch(setLoadingPage(false));
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }

};

export const putNaoSeguroBackgroud = (dispatch, url, data, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  if (navigator.onLine) {
    return axios.put(urlObj.path, data, { headers: getHeadersNaoSeguro(), params: urlObj.params })
      .then(response => {
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

export const putBackgroud = (dispatch, url, data, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  if (navigator.onLine) {
    return axios.put(urlObj.path, data, { headers: getHeaders(), params: urlObj.params })
      .then(response => {
        return dispatch(acaoSucesso(response.data))
      })
      .catch(falha => {
        return dispatch(acaoFalha(falha))
      });
  } else {
    return dispatch(acaoFalha(getOfflineError()))
  }
};

/* ------- DELETE ------- */

export const deleteNaoSeguroPromise = (path, params) => {
  const uri = prefixoRequest + path;

  return axios.delete(uri, { headers: getHeadersNaoSeguro(), params });
};

export const deleteSeguroPromise = (path, params) => {
  const uri = prefixoRequest + path;

  return axios.delete(uri, { headers: getHeaders(), params });
};

export const deleteNaoSeguro = (dispatch, url, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  dispatch(setLoadingPage(true));
  return axios.delete(urlObj.path, { headers: getHeadersNaoSeguro(), params: urlObj.params })
    .then(response => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoSucesso(response.data))
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

export const deleteSeguro = (dispatch, url, acaoSucesso, acaoFalha) => {
  const urlObj = resolveUrl(url);
  dispatch(setLoadingPage(true));
  return axios.delete(urlObj.path, { headers: getHeaders(), params: urlObj.params })
    .then(response => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoSucesso(response.data))
    })
    .catch(falha => {
      dispatch(setLoadingPage(false));
      return dispatch(acaoFalha(falha))
    });
};

const setLoadingPage = visible => displayLoader(visible)


export const downloadArquivo = (data, fileName) => {
  try {
      var objectUrl = URL.createObjectURL(data);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      a.remove();
      return true;
  } catch (e) {
      return false;
  }
}