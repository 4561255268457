import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import { Grid } from '@material-ui/core';
import HeaderSecao from '../../../../components/HeaderSecao';

import Concluir from "../../../../components/Concluir";
import {
  carregarBatimentosCidParaNexoERestabelecimento,
  downloadPericiaGenerico,
  obterDadosConclusaoRevisao,
  validacaoCompletaPericia
} from "../../action";

import DadosTecnicos from "../ConclusaoPericiaBI/DadosTecnicos";
import DetalheAlteracao from "./detalheAlteracao";

const ConclusaoPericiaRevisaoBI = (props) => {
  const {
    dadosConclusaoRevisao,
    pericia, 
    dominioPericia, 
    validacaoCompletaPericia, 
    isSomenteLeitura, 
    carregarBatimentosCidParaNexoERestabelecimento,
    obterDadosConclusaoRevisao
  } = props;

  useEffect(() => {
    validacaoCompletaPericia(pericia, true);
    //para pericia concluida, deve carregar batimentos necessarios para checar alteracoes da revisao
    if(isSomenteLeitura){
      carregarBatimentosCidParaNexoERestabelecimento(pericia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    obterDadosConclusaoRevisao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      <div>
        {
          (dadosConclusaoRevisao?.informacoesAlteradas?.length === 0 &&
            <BrMessage warning>
              Não houve alteração durante a revisão. Para concluir, é necessário modificar alguma informação.
            </BrMessage>
          )
        }
      </div>

      <div>
        {
          pericia?.respostaConcluir?.erros?.map((e, index) =>
          (
            <BrMessage key={`erro_${index}`} error>
              {e}
            </BrMessage>)
          )
        }
      </div>

      <HeaderSecao texto="Informações Revisadas" />

      <Grid container spacing={2}>
        {dadosConclusaoRevisao?.informacoesAlteradas?.map(i =>
            <DetalheAlteracao key={i.nomeCampo} alteracao={i} />
        )}
        {!(dadosConclusaoRevisao?.informacoesAlteradas?.length !== 0)  &&
          <div style={{marginLeft: "5px"}}>
            Nenhuma informação foi revisada.
          </div>
        }
      </Grid>

      <HeaderSecao texto="Resumo" />
      
      <DadosTecnicos pericia={pericia} tiposGrauIncapacidade={dominioPericia.tiposGrausIncapacidade}/>

      <Concluir disabled={dadosConclusaoRevisao?.informacoesAlteradas?.length === 0}/>
    </>
  );
}

const mapStateToProps = state => {
  return ({
    pericia: state.pericia,
    dominioPericia: state.principal.dominioPericia,
    dadosConclusaoRevisao: state.pericia.dadosConclusaoRevisao,
    isSomenteLeitura: state.principal.isSomenteLeitura
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  validacaoCompletaPericia, 
  downloadPericiaGenerico,
  carregarBatimentosCidParaNexoERestabelecimento,
  obterDadosConclusaoRevisao
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoPericiaRevisaoBI);