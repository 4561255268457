import React from 'react'
import { calcularCaracteresRestantes } from '../../../../common/Utils'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import BrTextarea from '@govbr/react-components/lib/BrTextarea'
import { Grid } from '@material-ui/core';

import { alterarJustificativaDominiosFuncoesCorpoComValorZero } from '../../action'

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

const QTD_MAXIMA_CARACTERES = 4000;

const erroHadler = (validacao) =>{
  let erro = null;
  if (validacao) {
    erro = validacao.erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima ?  validacao.erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima : erro;
    erro = validacao.erroJustificativaObrigatoriaGruposComValorDominioZerado ? validacao.erroJustificativaObrigatoriaGruposComValorDominioZerado : erro;
  }
  return erro;

}

const JustificativaDominiosZerados = (props) => {
  const label = `* "Não foram observadas alterações ou as alterações no domínio como um todo são mínimas (de 0 a 4%)" ou "Ausência de elementos de convicção para qualificar" em todos os domínios implica em qualificador final 0 (zero). Justifique: `;
  const isCampoTextareaDesabilitado = props.isSomenteLeitura;

  return (
    <Grid container alignItems="center">
        <Grid item xs>
          <BrTextarea id="justificativaAusenciaElementos"
          label={label}
          value={props.justificativaDominiosFuncoesCorpoComValorZero}
          maxLength={QTD_MAXIMA_CARACTERES}
          onChange={props.alterarJustificativaDominiosFuncoesCorpoComValorZero}
          help={`Caracteres restantes: ${calcularCaracteresRestantes(props.justificativaDominiosFuncoesCorpoComValorZero, QTD_MAXIMA_CARACTERES)}`}
          erro={erroHadler(props.validacao)} 
          disabled={isCampoTextareaDesabilitado}/>
        </Grid>
        {
          !isCampoTextareaDesabilitado &&
          <Grid item >
            <ButtonSelecionarItem
              id="selecionarItemDicionarioJustAusenciaElementos"
              onSelect={props.alterarJustificativaDominiosFuncoesCorpoComValorZero}
              maxLength={calcularCaracteresRestantes(props.justificativaDominiosFuncoesCorpoComValorZero, QTD_MAXIMA_CARACTERES)}
              valorAtual={props.justificativaDominiosFuncoesCorpoComValorZero}/>
          </Grid>
        }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  justificativaDominiosFuncoesCorpoComValorZero: state.pericia.resultadoParcialBPC.justificativaDominiosFuncoesCorpoComValorZero,
  validacao: state.principal.validacao.funcoesDoCorpo,
  isSomenteLeitura: state.principal.isSomenteLeitura
})

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustificativaDominiosFuncoesCorpoComValorZero
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(JustificativaDominiosZerados)
