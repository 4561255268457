import React, { Component } from 'react'
import { connect } from 'react-redux'
import { obterInfoAPI, obterInfoAPIManterPericia } from './principal/action'
import HeaderSecao from '../components/HeaderSecao'

export class PaginaInfoApp extends Component {

  componentDidMount() {
    if (!this.props.infoAPI) {
      this.props.obterInfoAPI();
    }
    if (!this.props.infoAPIManterPericia) {
      this.props.obterInfoAPIManterPericia();
    }
  }

  render() {
    const { infoAPI, infoAPIManterPericia } = this.props;
    return (
      <div className="col">
         <HeaderSecao texto="Informações" />
         { infoAPI && `${infoAPI.nome} - versão: ${infoAPI.versao}`}<br />
         { infoAPIManterPericia && `${infoAPIManterPericia.nome} - versão: ${infoAPIManterPericia.versao}`}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  infoAPI: state.principal.infoAPI,
  infoAPIManterPericia:  state.principal.infoAPIManterPericia
})

const mapDispatchToProps = {
  obterInfoAPI,
  obterInfoAPIManterPericia
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginaInfoApp)
