import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModuloFormulario from '../../../../components/ModuloFormulario';
import { obterMapRespostasGrupos, obterQualificadorFinalAtividadesParticipacao, qualificadoresAtividadesParticipacao } from '../../../../components/ModuloFormulario/utils';
import { obterFomularioAtividadesEParticipacao } from '../../templatePericiaUtil';
import JustificativaDominiosZerados from './JustificativaDominiosZerados';


export const isGruposComValorDominioZerado = (gruposFormulario, respostasGruposFormularios) => {
  return gruposFormulario && respostasGruposFormularios && gruposFormulario.every((grupo) => respostasGruposFormularios.get(grupo.id) && respostasGruposFormularios.get(grupo.id).qualificador === 0);
};
export class AtividadesEParticipacao extends Component {

  render() {
    const { template } = this.props;

    let formulario = obterFomularioAtividadesEParticipacao(template);

    if (!formulario) {
      return null;
    }
    return (

      formulario &&
      <div id="formAtividadesEParticipacao" className="conteudoAbaSemHeaderSecao">
        <div>
          {
          <ModuloFormulario
            formulario={formulario}
            respostasPerguntas={this.props.respostasPerguntaForm}
            respostasGrupos={this.props.respostasGrupoForm}
            opcoesJustQualificadorDominioZero={this.props.opcoesJustQualificadorDominioZero}
            tiposQualificador={this.props.tiposQualificador}
            textoQualificadorFinal={`Qualificador Parcial de ${formulario.nome}`}
            qualificadores={qualificadoresAtividadesParticipacao}
            handlerObterQualificadorFinal={obterQualificadorFinalAtividadesParticipacao}
            validacao={this.props.validacao}
            isSomenteLeitura={this.props.isSomenteLeitura} />
          }
          {
            isGruposComValorDominioZerado(formulario.gruposFormulario, obterMapRespostasGrupos(this.props.respostasGrupoForm)) &&
            <JustificativaDominiosZerados />
          }
        </div>
    </div>
      


    )
  }
}

const mapStateToProps = (state) => ({
  respostasPerguntaForm: state.pericia.respostasPerguntaForm,
  respostasGrupoForm: state.pericia.respostasGrupoForm,
  template: state.principal.template,
  opcoesJustQualificadorDominioZero: state.principal.dominioPericia.tiposJustQualifZero,
  tiposQualificador: state.principal.dominioPericia.tiposQualificador,
  validacao: state.principal.validacao.atividadesEParticipacao,
  isSomenteLeitura: state.principal.isSomenteLeitura
})

export default connect(mapStateToProps)(AtividadesEParticipacao)
