import React from 'react';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal'
import BrButton from '@govbr/react-components/lib/BrButton'

const DialogConfirmar = (props) => {
  const {id, texto, acaoCancelar, acaoConfimar, titulo, exibir} =  props; 
  const [isVisible, setVisible] = React.useState(exibir);

  React.useEffect(() => {
    setVisible(exibir);
  }, [exibir])

  const handleCancelar = () => {
    setVisible(false)
    if (acaoCancelar) {
      acaoCancelar();
    }
  };

  const handleConfirmar = () => {
    setVisible(false)
    if (acaoConfimar) {
      acaoConfimar();
    }
  };

  return (
    <BrModal 
      id={id} 
      visible={isVisible}
      title={titulo} 
      closable={true} 
      onClose={handleCancelar}
      medium>

      <p>{texto}</p>

      <BrModalFooter>
        <BrButton secondary className="m-2" onClick={handleCancelar}>Cancelar</BrButton>
        <BrButton primary className="m-2" onClick={handleConfirmar}>Confirmar</BrButton>
      </BrModalFooter>
    </BrModal>
  );
}

export default DialogConfirmar
