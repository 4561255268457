import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {
  return pericia?.moduloExameLaudo ? true : false;
}

export const validar = pericia => {
  const historiaClinica = pericia?.moduloExameLaudo?.historiaClinica;
  const isPreenchido = historiaClinica != null && historiaClinica.trim().length > 0;
  return {
    isValida: isPreenchido,
    mensagemErro: isPreenchido ? null : MSG_CAMPO_OBRIGATORIO
  };
};