import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal'
import BrButton from '@govbr/react-components/lib/BrButton'

import { ocultarDialogExcluirCid } from './action';

import { excluirCidSecundario } from '../../action'

class DialogConfirmarExclusao extends React.Component {

  render() {
    const { ocultarDialogExcluirCid, excluirCidSecundario, isConfirmarExclusao, cid } = this.props;
    return (
      <BrModal id="dialogConfirmarExclusaoCid" visible={isConfirmarExclusao}
        title={`Excluir CID Secundário`} closable={false} medium>

        { cid &&
          `Confirma exclusão do CID Secundário ${cid.cidSecundario} - ${cid.nomeCidSecundario}?`
        }

        <BrModalFooter>
          <BrButton secondary className="m-2" onClick={ocultarDialogExcluirCid}>Cancelar</BrButton>
          <BrButton primary className="m-2" onClick={() => excluirCidSecundario(cid.cidSecundario)}>Confirmar</BrButton>
        </BrModalFooter>
      </BrModal>
    );
  }
}

const mapStateToProps = (state) => ({
  isConfirmarExclusao: state.modulos.moduloCids.isConfirmarExclusao,
  cid: state.modulos.moduloCids.cidExclusao,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogExcluirCid,
  excluirCidSecundario
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarExclusao);
