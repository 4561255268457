import {
  convertStringToDate,
  formatarDateTimeISOToDataBr, isData1EhAntesData2, isData1EhAntesOuIgualData2, isData1EhDepoisOuIgualData2, zerarHorasDeData
} from "../../../../common/DataUtils";
import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";
import { SIGLA_CAMPO_DII, tipoSimNao, tiposParametrosModuloEstatico } from '../../constants';
import { obterDataParametroPeriodo, obterValorParametroModuloEstatico } from '../../templatePericiaUtil';
import { TIPOS_GRAU_INCAPACIDADE } from "./utils";

const camposModuloGrauIncapacidade = pericia => {
  return {
    tipoGrauIncapacidade: pericia?.moduloGrauIncapacidade?.tipoGrauIncapacidade,
    dataCessacaoBeneficio: pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio,
    enquadramentoAuxilioAcidente: pericia?.moduloGrauIncapacidade?.enquadramentoAuxilioAcidente,
    situacaoEnquadramentoAuxilioAcidente: pericia?.moduloGrauIncapacidade?.situacaoEnquadramentoAuxilioAcidente,
    justParcialComIndicAuxilioAcidente: pericia?.moduloGrauIncapacidade?.justParcialComIndicAuxilioAcidente,
    justTotalComIndicReabProfissional: pericia?.moduloGrauIncapacidade?.justTotalComIndicReabProfissional,
    justTotalSemIndicReabProfissional: pericia?.moduloGrauIncapacidade?.justTotalSemIndicReabProfissional,
    doencaIsentaImpostoRenda: pericia?.moduloGrauIncapacidade?.doencaIsentaImpostoRenda,
    tipoIsencaoImpostoRenda: pericia?.moduloGrauIncapacidade?.tipoIsencaoImpostoRenda,
    majoracao25: pericia?.moduloGrauIncapacidade?.majoracao25,
    tipoMajoracao25: pericia?.moduloGrauIncapacidade?.tipoMajoracao25,
    dataComprovacaoIncapacidade: pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade,
    drePericiaOrigemRevisao: pericia?.periciaOrigemRevisao?.dataAlteracao && convertStringToDate(formatarDateTimeISOToDataBr(pericia.periciaOrigemRevisao.dataAlteracao)),
    drePericiaAtual: new Date(),
    isPericiaRevisao: !!pericia?.idPericiaOrigemRevisao
  }
}

export const verificarSeCamposPreenchidos = pericia => {

  const campos = camposModuloGrauIncapacidade(pericia);

  if (!campos.tipoGrauIncapacidade) { return false; }

  if (TIPOS_GRAU_INCAPACIDADE.TEMPORARIA_COM_RECUPERACAO === campos.tipoGrauIncapacidade) {
    if (!campos.dataCessacaoBeneficio) { return false; }
  }

  if (TIPOS_GRAU_INCAPACIDADE.PARCIAL_COM_INDICACAO_AUXILIO_ACIDENTE === campos.tipoGrauIncapacidade) {
    if (!campos.dataCessacaoBeneficio) { return false; }
    if (!campos.enquadramentoAuxilioAcidente) { return false; }
    if (!campos.situacaoEnquadramentoAuxilioAcidente) { return false; }
    if (!campos.justParcialComIndicAuxilioAcidente) { return false; }
  }

  if (TIPOS_GRAU_INCAPACIDADE.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    if (!campos.dataComprovacaoIncapacidade) { return false; }
    if (!campos.justTotalComIndicReabProfissional) { return false; }
  }

  if (TIPOS_GRAU_INCAPACIDADE.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    if (!campos.doencaIsentaImpostoRenda) { return false; }
    if (campos.tipoIsencaoImpostoRenda === undefined) { return false; }
    if (!campos.majoracao25) { return false; }
    if (campos.tipoMajoracao25 === undefined) { return false; }
    if (!campos.justTotalSemIndicReabProfissional) { return false; }
  }

  return true;
}

export const validar = (pericia, getState) => {

  const campos = camposModuloGrauIncapacidade(pericia);
  const template = getState().principal.template;
  const descricaoTipoGrauIncapacidadeSelecionado = getState().principal.dominioPericia
    .tiposGrausIncapacidade.find(tipo => campos.tipoGrauIncapacidade && tipo.id === campos.tipoGrauIncapacidade)?.descricao;

  let invalidado = {
    isValida: true
  }

  if (!campos.tipoGrauIncapacidade) {
    invalidado.erroTipoGrauIncapacidade = MSG_CAMPO_OBRIGATORIO;
  }

  if (TIPOS_GRAU_INCAPACIDADE.TEMPORARIA_COM_RECUPERACAO === campos.tipoGrauIncapacidade) {
    const dataCessacaoBeneficio = convertStringToDate(formatarDateTimeISOToDataBr(campos.dataCessacaoBeneficio));

    if (!dataCessacaoBeneficio || isNaN(dataCessacaoBeneficio)) { invalidado.erroDcbTempComRecupe = MSG_CAMPO_OBRIGATORIO; }
    else {

      const valorParametroMaxDCB = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_MAX_DCB.id);
      if (!valorParametroMaxDCB) {
        throw new Error(`É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_MAX_DCB.nome}' no template da perícia.`)
      } else {
        const dataMaximaDCB = obterDataParametroPeriodo(valorParametroMaxDCB, campos.drePericiaAtual, false);

        if (!isData1EhAntesOuIgualData2(dataCessacaoBeneficio, dataMaximaDCB)) {
          invalidado.erroDcbTempComRecupe = `Não é possível informar Data de Cessação do Benefício (DCB) com mais de ${obterDescricaoParametroPeriodo(valorParametroMaxDCB)} da data atual.`;
        } else {
          const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
          let dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);

          if (!dii) {
            const beneficioAnteriorPossuiDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);
            if (!beneficioAnteriorPossuiDCAIgualOuMaiorDRE && pericia.numeroBeneficio) {
              const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
              dii = convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataInicioIncapacidade));
            }
          }

          if (dii && !isData1EhDepoisOuIgualData2(dataCessacaoBeneficio, dii)) {
            invalidado.erroDcbTempComRecupe = `A Data de Cessação do Benefício (DCB) deve ser maior ou igual à Data de Início da Incapacidade (DII).`;
          }
        }

        const erroValidacaoRetroacaoDCB = obterErroValidacaoDataMaximaRetroacaoDCB(dataCessacaoBeneficio, template);
        if (erroValidacaoRetroacaoDCB) {
          invalidado.alertaDcbTempComRecupe = erroValidacaoRetroacaoDCB;
        }

        const erroValidacaoDcbDaPerIndicaDCAMaiorIgualDRE = obterErroValidacaoDcbDaPerIndicaDCAMaiorIgualDRE(pericia);
        if (erroValidacaoDcbDaPerIndicaDCAMaiorIgualDRE) {
          invalidado.erroDcbTempComRecupe = erroValidacaoDcbDaPerIndicaDCAMaiorIgualDRE;
        } else {
          const erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef = obterErroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef(pericia);
          if(erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef) {
            invalidado.erroDcbTempComRecupe = erroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef;
          }
        }
      }
    }
  }

  if (TIPOS_GRAU_INCAPACIDADE.PARCIAL_COM_INDICACAO_AUXILIO_ACIDENTE === campos.tipoGrauIncapacidade) {

    const dataCessacaoBeneficio = convertStringToDate(formatarDateTimeISOToDataBr(campos.dataCessacaoBeneficio));

    const erroDcbParcialIndicAuxAcid = `Não é possível informar Data de Cessação do Benefício (DCB) maior que a `
      + `Data da Realização do Exame (DRE) para indicação de incapacidade ${descricaoTipoGrauIncapacidadeSelecionado}.`;

    const erroDcbParcialIndicAuxAcidPerOrigRev = `Não é possível informar Data de Cessação do Benefício (DCB) maior que a `
      + `Data da Realização do Exame (DRE) da Perícia Origem da Revisão para indicação de incapacidade ${descricaoTipoGrauIncapacidadeSelecionado}.`;

    if (!dataCessacaoBeneficio || isNaN(dataCessacaoBeneficio)) {
      invalidado.erroDcbParcialIndicAuxAcid = MSG_CAMPO_OBRIGATORIO;
    } else {
      if (campos.isPericiaRevisao
        && !isData1EhAntesOuIgualData2(dataCessacaoBeneficio, zerarHorasDeData(campos.drePericiaOrigemRevisao))) {
        invalidado.erroDcbParcialIndicAuxAcid = erroDcbParcialIndicAuxAcidPerOrigRev;
      }
      else if (!isData1EhAntesOuIgualData2(dataCessacaoBeneficio, zerarHorasDeData(campos.drePericiaAtual))) {
        invalidado.erroDcbParcialIndicAuxAcid = erroDcbParcialIndicAuxAcid;
      } else {

        const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
        const dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);

        if (dii && !isData1EhDepoisOuIgualData2(dataCessacaoBeneficio, dii)) {
          invalidado.erroDcbParcialIndicAuxAcid = `Não é possível informar Data de Cessação do Benefício (DCB) menor que DII (Data Início da Incapacidade).`;
        }
      }
      const erroValidacaoRetroacaoDCB = obterErroValidacaoDataMaximaRetroacaoDCB(dataCessacaoBeneficio, template);
      if (erroValidacaoRetroacaoDCB) {
        invalidado.alertaDcbParcialIndicAuxAcid = erroValidacaoRetroacaoDCB;
      }
    }

    if (!campos.enquadramentoAuxilioAcidente) { invalidado.erroEnquadramentoAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.situacaoEnquadramentoAuxilioAcidente) { invalidado.erroSituacaoEnquadramentoAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.justParcialComIndicAuxilioAcidente || campos.justParcialComIndicAuxilioAcidente.trim().length === 0) { invalidado.erroJustParcialComIndicAuxilioAcidente = MSG_CAMPO_OBRIGATORIO; }
  }

  if (TIPOS_GRAU_INCAPACIDADE.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    const dataComprovacaoIncapacidade = convertStringToDate(formatarDateTimeISOToDataBr(campos.dataComprovacaoIncapacidade));

    if (!dataComprovacaoIncapacidade || isNaN(dataComprovacaoIncapacidade)) {
      invalidado.erroDataComprovacaoIncapacidade = MSG_CAMPO_OBRIGATORIO;
    } else {

      if (!isData1EhDepoisOuIgualData2(dataComprovacaoIncapacidade, campos.drePericiaAtual)) {
        const beneficioAnteriorPossuiDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);
        const beneficioAnteriorNaoIndicaDCA = obtemSituacaoBeneficioAnteriorNaoIndicaDCA(pericia);
        if (beneficioAnteriorPossuiDCAIgualOuMaiorDRE || beneficioAnteriorNaoIndicaDCA) {
          invalidado.erroDataComprovacaoIncapacidade = "A Data Limite da Elegibilidade deve ser maior ou igual à Data da Realização do Exame (DRE).";
        } else {
          const valorCampoDii = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_DII);
          let dii = valorCampoDii && convertStringToDate(valorCampoDii.valorTextoCampoGenerico);
  
          if (!dii && pericia.numeroBeneficio) {
            const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
            dii = convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataInicioIncapacidade));
          }
  
          if (dii && !isData1EhDepoisOuIgualData2(dataComprovacaoIncapacidade, dii)) {
            invalidado.erroDataComprovacaoIncapacidade = `A Data Limite da Elegibilidade deve ser maior ou igual à Data de Início da Incapacidade (DII).`;
          }
        }
      } else {

        const valorParametroMaxDCI = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_MAX_DCI.id);
        if (!valorParametroMaxDCI) {
          throw new Error(`É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_MAX_DCI.nome}' no template da perícia.`)
        } else {
          const dataMaximaDCI = obterDataParametroPeriodo(valorParametroMaxDCI, new Date(), false);

          if (!isData1EhAntesOuIgualData2(dataComprovacaoIncapacidade, dataMaximaDCI)) {
            invalidado.erroDataComprovacaoIncapacidade = `Não é possível informar 'Data Limite da Elegibilidade' com mais de ${obterDescricaoParametroPeriodo(valorParametroMaxDCI)} da data atual.`;
          }

          const erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef = obterErroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef(pericia);
          if(erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef) {
            invalidado.erroDataComprovacaoIncapacidade = erroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef;
          }

        }
      }
    }

    if (!campos.justTotalComIndicReabProfissional || campos.justTotalComIndicReabProfissional.trim().length === 0) { invalidado.erroJustTotalComIndicReabProfissional = MSG_CAMPO_OBRIGATORIO; }
  }

  if (TIPOS_GRAU_INCAPACIDADE.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL === campos.tipoGrauIncapacidade) {
    if (campos.doencaIsentaImpostoRenda === undefined) { invalidado.erroDoencaIsentaImpostoRenda = MSG_CAMPO_OBRIGATORIO; }
    if (campos.majoracao25 === undefined) { invalidado.erroMajoracao25 = MSG_CAMPO_OBRIGATORIO; }
    if (!campos.justTotalSemIndicReabProfissional || campos.justTotalSemIndicReabProfissional.trim().length === 0) { invalidado.erroJustTotalSemIndicReabProfissional = MSG_CAMPO_OBRIGATORIO; }
    if (campos.doencaIsentaImpostoRenda === 1 && !campos.tipoIsencaoImpostoRenda) { invalidado.erroTipoIsencaoImpostoRenda = MSG_CAMPO_OBRIGATORIO; }
    if (campos.majoracao25 === 1 && !campos.tipoMajoracao25) { invalidado.erroTipoMajoracao25 = MSG_CAMPO_OBRIGATORIO; }
  }

  if (Object.keys(invalidado).some(e => e.includes("erro"))) {
    invalidado = { ...invalidado, isValida: false }
  }

  return invalidado;

}

export const obterErroValidacaoDataMaximaRetroacaoDCB = (dcb, template) => {
  let valorParametroMaxRetroacaoDCB = obterValorParametroModuloEstatico(template, tiposParametrosModuloEstatico.PERIODO_MAX_RETROACAO_DCB.id);
  if (!valorParametroMaxRetroacaoDCB) {
    throw new Error(`É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_MAX_RETROACAO_DCB.nome}' no template da perícia.`)
  } else {

    const dataMaximaRetroacaoDCB = obterDataParametroPeriodo(valorParametroMaxRetroacaoDCB, new Date(), true);

    if (isData1EhAntesOuIgualData2(dcb, dataMaximaRetroacaoDCB)) {
      return `A 'Data de Cessação do Benefício' (DCB) foi retroagida acima de ${obterDescricaoParametroPeriodo(valorParametroMaxRetroacaoDCB)} da data atual.`;
    }
  }
  return undefined;
}

const obterDescricaoParametroPeriodo = valorParametro => {
  const regex = /[A-Z]/g;
  let indicePeriodo = valorParametro.search(regex);
  let periodo = indicePeriodo >= 0 ? valorParametro.substring(indicePeriodo) : undefined;
  let valorCalculo = periodo ? valorParametro.substring(0, indicePeriodo) : valorParametro;
  let descricao;

  switch (periodo) {
    case 'D':
      descricao = `${valorCalculo} dias`;
      break;
    case 'M':
      descricao = `${valorCalculo} meses`;
      break;
    case 'A':
      descricao = `${valorCalculo} anos`;
      break;
    default:
      break
  }
  return descricao;
}

function obterErroValidacaoDcbDaPerIndicaDCAMaiorIgualDRE(pericia) {
  const dcbDaPericia = pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio 
    && convertStringToDate(formatarDateTimeISOToDataBr(
        pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio
      ));
  const beneficioAnteriorPossuiDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);

  if(beneficioAnteriorPossuiDCAIgualOuMaiorDRE && dcbDaPericia && isData1EhAntesData2(dcbDaPericia, new Date())) {
    return "A 'Data de Cessação do Benefício' (DCB) deve ser maior ou igual à Data da Realização do Exame (DRE)";
  }
  return null;
}

export function obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia) {
  if (pericia?.numeroBeneficio) {
    const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
    const dataCessacaoBeneficio = convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataCessacaoBeneficio));

    return beneficioAnterior?.indicaDCA 
      && tipoSimNao.SIM.descricao === beneficioAnterior?.indicaDCA
      && dataCessacaoBeneficio 
      && isData1EhAntesOuIgualData2(new Date(), dataCessacaoBeneficio);
  }
  return false;
}

export function obtemSituacaoBeneficioAnteriorNaoIndicaDCA(pericia) {
  if (pericia?.numeroBeneficio) {
    const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);
    return !beneficioAnterior?.indicaDCA || tipoSimNao.NAO.descricao === beneficioAnterior?.indicaDCA;
  }
  return true;
}

function obtemDcbBeneficioSeNaoDCA(pericia) {
  if (pericia?.numeroBeneficio) {
    const beneficioAnterior = pericia?.beneficiosAnteriores?.find(benef => benef.numeroBeneficio === pericia.numeroBeneficio);

    if (!beneficioAnterior?.indicaDCA || tipoSimNao.NAO.descricao === beneficioAnterior?.indicaDCA) {
      return convertStringToDate(formatarDateTimeISOToDataBr(beneficioAnterior?.dataCessacaoBeneficio));
    } 
  }
}

const obterErroValidacaoDcbDaPerMenorQueDcbOuDcaDoBenef = pericia => {
  const dcbDaPericia = pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio 
    && convertStringToDate(formatarDateTimeISOToDataBr(
        pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio
      ));
  const dcbDoBenef = obtemDcbBeneficioSeNaoDCA(pericia);
    
  if(dcbDaPericia && dcbDoBenef && isData1EhAntesOuIgualData2(dcbDaPericia, dcbDoBenef)) {
    return `A 'Data de Cessação do Benefício' (DCB) informada deve ser maior que a data de cessação do benefício atual concedido, ${formatarDateTimeISOToDataBr(dcbDoBenef)}`;
  }
  return null;
}

const obterErroValidacaoDciDaPerMenorQueDcbOuDcaDoBenef = pericia => {
  const dciDaPericia = pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade 
    && convertStringToDate(formatarDateTimeISOToDataBr(
        pericia?.moduloGrauIncapacidade?.dataComprovacaoIncapacidade
      ));
  const dcbDoBenef = obtemDcbBeneficioSeNaoDCA(pericia);

  if(dciDaPericia && dcbDoBenef && isData1EhAntesOuIgualData2(dciDaPericia, dcbDoBenef)) {
    return `A 'Data Limite da Elegibilidade' informada deve ser maior que a data de cessação do benefício atual concedido, ${formatarDateTimeISOToDataBr(dcbDoBenef)}`;
  }
  return null;
}
