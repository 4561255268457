export const tipoOperacao = Object.freeze({
  SOMA: "+", //
  SUBTRACAO: "-"
});


export const calcularCaracteresRestantes = (string, tamanhoMaximo) => {
  const tamanhoString = string ? string.length : 0;
  return tamanhoMaximo - tamanhoString;
}

export const clonarObjeto = (objeto) => {
  if (objeto && objeto !== undefined) {
    return JSON.parse(JSON.stringify(objeto));
  } else {
    return null;
  }
}

export const updateObjectInArray = (array, indexObj, newObj) => {
  return array.map((item, index) => {
    if (index !== indexObj) {
      return item
    }
    return {
      ...item,
      ...newObj
    }
  })
}

export const insertItemInArray = (array, item, index) => {
  let newArray = array.slice()
  let indexNewItem = (index !== null && index !== undefined) ? index : newArray.length - 1;
  newArray.splice(indexNewItem, 0, item)
  return newArray
}

export const removeItemFromArray = (array, index) => {
  let newArray = array.slice()
  newArray.splice(index, 1)
  return newArray
}

export const somarOuSubtrair = (valor1, valor2, operador) => {
  switch (operador) {
    case tipoOperacao.SOMA:
      return valor1 && valor2 ? parseInt(valor1) + parseInt(valor2) : undefined;
    case tipoOperacao.SUBTRACAO:
      return valor1 && valor2 ? parseInt(valor1) - parseInt(valor2) : undefined;
    default:
      return undefined;
  }
}

export const errorMessageAbstract = (error, prefixo) => {
  let mensagem;

  if (error.response) {
    switch (error.response.status) {
      case 400: 
      case 422:{
        mensagem = `${prefixo}: ${obterMensagemErro(error.response)
          }`;
        break;
      }
      case 401: {
        mensagem = `${prefixo}: Ação não autorizada.`;
        break;
      }
      case 403: {
        mensagem = `${prefixo}: Usuário não possui permissão para esta ação.`;
        break;
      }
      default: {
        mensagem = `${prefixo}: Ocorreu um erro na sua requisição. Tente novamente mais tarde.`;
        break;
      }
    }
  } else {
    mensagem = `${prefixo}: ${error.message} `;
  }

  return mensagem;
}

export const obterMensagemErro = response => {
  return response.data && typeof response.data === 'string'
    ? response.data
    : (response.data ? response.data.message : null);
}

export const verificaCidEmGrupo = (cid, grupo) => {
  return cid && typeof cid === "string" && cid.charAt(0) === grupo
}
