import { obterUsuarioLogado } from '../../common/AuthManager';
import { isDii, tratarExibicaoInicialCamposGenericos, verificarRegraValidacaoCampoGenerico } from '../../common/CampoGenericoUtils';
import { convertStringToDate, converterDataFormatadaParaISOBr, isData1EhIgualData2 } from '../../common/DataUtils';
import { checarAlteracoesRevisao } from '../../common/PericiaRevisaoUtil';
import { isTipoPericiaBI } from "../../common/PericiaUtils";
import { getSeguroPdf, getSeguroPromise, postSeguroPromise, putSeguroPromise } from "../../common/requests";
import {
  atualizarDadosValidacaoCompleta, atualizarTemplatePericia, atualizarTipoAcessoDados,

  buscarValoresDominioPericia, buscarValoresDominioPericiaBI, buscarValoresDominioSDC,



  displayLoader, errorMessage, successMessage
} from "../principal/action";
import {
  SIGLA_CAMPO_CID_ALTERADO, SIGLA_CAMPO_DII, modulosEstaticos, situacaoPericia,
  tipoMensagemValidacaoCampoGenerico, tiposParametrosModuloEstatico
} from "./constants";
import { carregarCidPrincipalMesmoGrupoCidPericiaAnterior, ocultarDialogExcluirCid } from './modulos/CIDs/action';
import {
  adicionarDadosDeMedicoEncontrado, adicionarMensagemErroDialogDocumMedOdont, atualizarDadosValidacaoDocumMedOdont,
  ocultarDialogDocumMedOdont, ocultarDialogExcluirDocumMedOdont
} from './modulos/DocumMedOdont/action';
import { obterDadosValidacao } from './modulos/DocumMedOdont/validacao';
import { atualizarDadosValidacaoExameLaudo, ocultarDialogExameLaudo, ocultarDialogExcluirExame } from './modulos/ExamesELaudos/action';
import { obterDadosValidacaoExameLaudo } from './modulos/ExamesELaudos/validacao';
import { consultarBatimentoCidCnae, consultarFatoresDeRiscoCid } from './modulos/NexoTecnico/action';
import { obterListaCnaesDeVinculos } from './modulos/NexoTecnico/utils';
import { carregarBatimentoCidBeneficioAgravamento, carregarBatimentoCidBeneficioRestabelecimento } from './modulos/RestabelecimentoBeneficio/action';
import { obterBeneficiosParaAgravamentoLesao, obterBeneficiosParaRestabelecimento } from './modulos/RestabelecimentoBeneficio/utils';
import { obterValorParametroModuloEstatico } from './templatePericiaUtil';
import { obterDadosValidacaoCompleta, obterUrlProximaAba } from './validacaoCampos';

export const consultarPericia = (idPericia, isSomenteLeitura) => {
  adicionarIdPericiaNoStorage(idPericia);
  return consultarPericiaAbstract(`pmf-realizar-pericias-service/pericia/${idPericia}`, isSomenteLeitura);
}

export const consultarPericiaPorToken = (token, hash, isSomenteLeitura) => {
  adicionarTokenPericiaNoStorage(token, hash);
  return consultarPericiaAbstract(`pmf-realizar-pericias-service/pericia/obter-por-token?token=${token}&hash=${hash}`, isSomenteLeitura);
}

const consultarPericiaAbstract = (path, isSomenteLeitura) => {
  return async (dispatch, getState) => {
    dispatch(displayLoader(true));
    try {
      const allResponses = await Promise.all([
        dispatch(buscarValoresDominioPericia()),
        dispatch(buscarValoresDominioSDC()),
        getSeguroPromise(path)
      ]);
      const response = allResponses[2];
      const pericia = response.data;
      const template = { ...pericia.template }
      delete pericia.template;
      isTipoPericiaBI(template)
        && await dispatch(buscarValoresDominioPericiaBI());
      dispatch(atualizarPericia(pericia, isSomenteLeitura));
      dispatch(atualizarTemplatePericia(template));
      tratarExibicaoInicialCamposGenericos(template, getState().pericia.valoresCamposGenericos, getState().pericia);
      dispatch(validacaoCompletaPericia(pericia, verificarSeAbaAtualEhConclusao(getState())));
    } catch (err) {
      dispatch(displayLoader(false));
      dispatch(errorMessage(err, "Consultar"));
      return;
    }
    dispatch(displayLoader(false));
  };
}

const verificarSeAbaAtualEhConclusao = (state) => {
  const idAba = state.principal.idAbaSelecionada;
  const template = state.principal.template;

  const moduloTemplateAtual = Object.values(modulosEstaticos).find(m => m.codigo === idAba) ?
    template.modulosTemplatePericia.find(m => m.modulo.tipoModuloEstatico === idAba) :
    template.modulosTemplatePericia.find(t => t.id === idAba);

  const existeProximaAba = obterUrlProximaAba(moduloTemplateAtual, template);

  // Se não existe próxima aba, então se trata da aba de conclusão
  return existeProximaAba ? false : true;
}

const adicionarIdPericiaNoStorage = (idPericia) => {
  window.sessionStorage.setItem('idPericia', idPericia)
  window.sessionStorage.removeItem('token');
  window.sessionStorage.removeItem('hash');
}

const adicionarTokenPericiaNoStorage = (token, hash) => {
  window.sessionStorage.setItem('token', token);
  window.sessionStorage.setItem('hash', hash);
  window.sessionStorage.removeItem('idPericia');
}

export const salvarPericia = pericia => {
  return async (dispatch, getState) => {
    dispatch(displayLoader(true));

    let usuarioLogado = obterUsuarioLogado();
    pericia.cpfUsuarioAlteracao = usuarioLogado.cpf;

    let moduloCidAnterior = getState().modulos.moduloCids.moduloCid;
    tratarModuloCid(pericia, moduloCidAnterior);
    return await putSeguroPromise(`pmf-realizar-pericias-service/pericia/`, pericia)
      .then((response) => {
        dispatch(atualizarPericia(response.data));
        dispatch(successMessage("Ação salvar realizada com sucesso"));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Salvar'));
        return 0;
      });
  };
};

export const solicitarSima = dadosSolicitacao => {
  return async dispatch => {
    dispatch(displayLoader(true));
    return await postSeguroPromise(`pmf-realizar-pericias-service/pericia/solicitar-sima`, dadosSolicitacao)
      .then((response) => {
        dispatch(solicitarSimaSucesso(response.data));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Solicitação de Exigência'));
        return 0;
      });
  };
};

export const retornarSima = dadosSolicitacao => {
  return async dispatch => {
    dispatch(displayLoader(true));
    return await postSeguroPromise(`pmf-realizar-pericias-service/pericia/retornar-sima`, dadosSolicitacao)
      .then((response) => {
        dispatch(retornarSimaSucesso(response.data));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Concluir Solicitação de Exigência'));
        return 0;
      });
  };
};

const solicitarSimaSucesso = moduloSima => {
  return dispatch => {
    dispatch(successMessage("Solicitação de Exigência Solicitada Com Sucesso"));
    dispatch(atualizarTipoAcessoDados({ situacao: 3, simaSolicitado: 1 }));
    dispatch(atualizarDadosSima(moduloSima));
  }
}

const atualizarDadosSima = moduloSima => {
  let { pericia, ...sima } = moduloSima;
  return ({
    type: 'ATUALIZAR_DADOS_SIMA',
    payload: { moduloSima: sima, pericia }
  })
}

const retornarSimaSucesso = moduloSima => {
  return dispatch => {
    dispatch(successMessage("Exigência Cumprida com Sucesso."));
    dispatch(atualizarTipoAcessoDados({ situacao: 1, simaSolicitado: 1 }));
    dispatch(atualizarDadosSima(moduloSima));
  }
}

export const validarEConcluirPericia = pericia => {
  return async dispatch => {
    dispatch(displayLoader(true));
    dispatch(limparRespostaConcluirPericiaErro());
    dispatch(validacaoCompletaPericia(pericia, true));
    let usuarioLogado = obterUsuarioLogado();
    pericia.cpfUsuarioAlteracao = usuarioLogado.cpf;
    try {
      const response = await postSeguroPromise(`pmf-realizar-pericias-service/pericia/concluir`, pericia);
      dispatch(atualizarRespostaConcluirPericiaSucesso(response.data));
      dispatch(successMessage("Ação concluir realizada com sucesso"));
      dispatch(atualizarTipoAcessoDados({ situacao: 4, simaSolicitado: pericia.simaSolicitado }));
    } catch (error) {
      dispatch(errorMessage(error, 'Concluir'));
      if (error.response && error.response.data) {
        dispatch(atualizarRespostaConcluirPericiaErro(error.response.data));
      }
    }
  }
};

export const carregarBatimentosCidParaNexoERestabelecimento = pericia => {
  return async (dispatch, getState) => {
    const { existeModuloNexoNoTemplate, existeModuloRestabelecimentoNoTemplate } = getState().principal;
    const cid = pericia?.moduloCid?.cidPrincipal;
    const periciaAnterior = pericia.periciaAnterior;

    const valorCampoCidAlterado = pericia.valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO);
    const cidAlterado = valorCampoCidAlterado && valorCampoCidAlterado.valorTextoCampoGenerico === '1';
    const deveCarregarBatimentoGrupoPericiaAnterior = cidAlterado && cid && periciaAnterior && periciaAnterior.codigoCID;

    if (deveCarregarBatimentoGrupoPericiaAnterior) {
      await dispatch(carregarCidPrincipalMesmoGrupoCidPericiaAnterior(cid.trim(), periciaAnterior.codigoCID.trim()));
    }

    const { fatoresRiscoCid, batimentoCidCnae } = getState().modulos.moduloNexoTecnico;
    const deveCarregarBatimentosNexo = existeModuloNexoNoTemplate
      && (!fatoresRiscoCid || !batimentoCidCnae)
      && cid;

    if (deveCarregarBatimentosNexo) {
      // //carregar batimentos de forma sincrona para usar na validacao
      await Promise.all([
        dispatch(consultarFatoresDeRiscoCid(cid)),
        dispatch(consultarBatimentoCidCnae(cid, obterListaCnaesDeVinculos(pericia?.vinculosPericia)))
      ]);
    }

    const { batimentoCidAgravamento, batimentoCidRestabelecimento } = getState().modulos.moduloRestabelecimentoBeneficio;
    const deveCarregarBatimentosRestabelecimento = existeModuloRestabelecimentoNoTemplate
      && (batimentoCidAgravamento === undefined || batimentoCidRestabelecimento === undefined)
      && cid;

    if (deveCarregarBatimentosRestabelecimento) {

      if (batimentoCidAgravamento === undefined) {
        const listaAA = obterBeneficiosParaAgravamentoLesao(pericia.beneficiosAnteriores);
        if (listaAA) {
          const cids = listaAA.map(b => b.codigoCID10).filter((val, id, array) => array.indexOf(val) === id);
          await Promise.all(cids.map(async (cidNbAnterior) => {
            //carregar batimentos de forma sincrona para usar na validacao
            await dispatch(carregarBatimentoCidBeneficioAgravamento(cid, cidNbAnterior));
          }));
        }
      }

      if (batimentoCidRestabelecimento === undefined) {
        //carregar parametro obrigatorio
        const valorParametroPeriodoRestabelecimento = obterValorParametroModuloEstatico(getState().principal.template,
          tiposParametrosModuloEstatico.PERIODO_RESTABELECIMENTO.id);

        if (!valorParametroPeriodoRestabelecimento) {
          const msg = `É necessário configurar parâmetro obrigatório '${tiposParametrosModuloEstatico.PERIODO_RESTABELECIMENTO.nome}' no template da perícia.`;
          dispatch(errorMessage({ message: msg }, "Restabelecimento"));
        } else {
          const listaAD = obterBeneficiosParaRestabelecimento(pericia.beneficiosAnteriores, pericia.dataReferencia, valorParametroPeriodoRestabelecimento, pericia.numeroBeneficio);
          if (listaAD) {

            const cids = listaAD.map(b => b.codigoCID10).filter((val, id, array) => array.indexOf(val) === id);
            await Promise.all(cids.map(async (cidNbAnterior) => {
              //carregar batimentos de forma sincrona para usar na validacao
              await dispatch(carregarBatimentoCidBeneficioRestabelecimento(cid, cidNbAnterior));
            }));
          }
        }
      }
    }

  }
}

export const validacaoCompletaPericia = (pericia, isValidacaoFinal) => {
  return async (dispatch, getState) => {

    let situacao = getState().pericia.situacao;
    if (situacao && situacao.id !== situacaoPericia.CRIADA.id && situacao.id !== situacaoPericia.EM_ABERTO.id) {
      return;
    }
    let periciaSomenteLeitura = getState().principal.isSomenteLeitura;
    if(periciaSomenteLeitura){
      return;
    }

    //aguardar carregamento de todos batimentos necessarios antes de iniciar validacao
    await dispatch(carregarBatimentosCidParaNexoERestabelecimento(pericia));

    try {
      const validacao = obterDadosValidacaoCompleta(pericia, getState, isValidacaoFinal);

      dispatch(atualizarDadosValidacaoCompleta(validacao));

      const isPericiaValida = validacao && Object.keys(validacao).length > 0
        && Object.values(validacao).reduce(
          (previous, current) => Boolean(previous) && Boolean(current?.isValida), true);

      dispatch(atualizarPericiaValida(isPericiaValida));

      return isPericiaValida;
    } catch (err) {
      dispatch(errorMessage(err, 'Validar'));
    }
  }
}

export const obterDadosConclusaoRevisao = () => {
  return (dispatch, getState) => {
    const dominios = {
      ufs: getState().principal.dominioSdc.ufs,
      tiposIdentificacaoEmitente: getState().principal.dominioPericia.tiposIdentificacaoEmitente,
      tiposExameLaudo: getState().principal.dominioPericia.tiposExameLaudo,
      tiposGrauIncapacidade: getState().principal.dominioPericia.tiposGrausIncapacidade,
      dominioSistemasCorpo: getState().principal.dominioPericiaBI.sistemasCorpoExameFisico,
      tiposDoencaIsencao: getState().principal.dominioPericia.tiposDoencaIsencao,
      listaEnquadAuxAcid: getState().principal.dominioPericiaBI?.tiposEnquadramentoAuxAcidente,
      listaSitEnquadAuxAcid: getState().principal.dominioPericiaBI?.tiposSituacaoEnquadramentoAuxAcidente,
      listaMotivosIsencaoIr: getState().principal.dominioPericiaBI?.tiposDoencaIsencaoIR,
      listaMotivosMajoracao25: getState().principal.dominioPericiaBI?.tiposMotivoMajoracao,
      tiposDoencaNexoProfissional: getState().principal.dominioPericia?.tiposDoencaNexoProfissional,
      tiposNexoIndividual: getState().principal.dominioPericia?.tiposNexoIndividual,
      fatoresRiscoCid: getState().modulos.moduloNexoTecnico?.fatoresRiscoCid,
      motivosNaoFixacaoNtep: getState().principal.dominioPericia?.motivosNaoFixacaoNtep
    }
    dispatch(atualizarDadosConclusaoRevisao(checarAlteracoesRevisao(getState().pericia, dominios)));
  }
}

const atualizarDadosConclusaoRevisao = value => ({
  type: 'ATUALIZAR_DADOS_CONCLUSAO_REVISAO',
  payload: value
})

export const atualizarPericiaValida = value => ({
  type: 'ATUALIZAR_PERICIA_VALIDA',
  payload: value
});

export const limparRespostaConcluirPericiaErro = () => ({
  type: 'LIMPAR_RESPOSTA_CONCLUIR_PERICIA_ERRO'
});

const atualizarRespostaConcluirPericiaErro = resposta => ({
  type: 'ATUALIZAR_RESPOSTA_CONCLUIR_PERICIA_ERRO',
  payload: resposta
});

const atualizarRespostaConcluirPericiaSucesso = resposta => ({
  type: 'ATUALIZAR_RESPOSTA_CONCLUIR_PERICIA_SUCESSO',
  payload: resposta
});

const atualizarPericia = (pericia, isSomenteLeitura) => {
  return (dispatch) => {
    dispatch(atualizarTipoAcessoDados({
      situacao: pericia.situacao.id,
      simaSolicitado: pericia.simaSolicitado,
      pericia,
      isSomenteLeitura
    }));
    dispatch({
      type: 'ATUALIZAR_PERICIA',
      payload: pericia
    });
  }
}

export const limparDadosPericia = () => ({
  type: 'LIMPAR_DADOS_PERICIA'
});

export const alterarTipoDocumentoAvaliado = (value) => ({
  type: 'INPUT_TIPO_DOCUMENTO_AVALIADO',
  payload: value
});

export const alterarNumeroDocumentoAvaliado = (value) => ({
  type: 'INPUT_NUMERO_DOCUMENTO_AVALIADO',
  payload: value
});

export const alterarComplementoDocumentoAvaliado = (value) => ({
  type: 'INPUT_COMPLEMENTO_DOCUMENTO_AVALIADO',
  payload: value
});

export const alterarTipoDocumentoAcompanhante = (value) => ({
  type: 'INPUT_TIPO_DOCUMENTO_ACOMPANHANTE',
  payload: value
});

export const alterarNumeroDocumentoAcompanhante = (value) => ({
  type: 'INPUT_NUMERO_DOCUMENTO_ACOMPANHANTE',
  payload: value
});

export const alterarComplementoDocumentoAcompanhante = (value) => ({
  type: 'INPUT_COMPLEMENTO_DOCUMENTO_ACOMPANHANTE',
  payload: value
});

export const alterarNomeAcompanhante = (value) => ({
  type: 'INPUT_NOME_ACOMPANHANTE',
  payload: value
});

export const alterarHistoriaClinica = value => ({
  type: 'INPUT_HISTORIA_CLINICA',
  payload: value
});

export const alterarPressaoMaxima = value => ({
  type: 'INPUT_PRESSAO_MAXIMA',
  payload: value
});

export const alterarPressaoMinima = value => ({
  type: 'INPUT_PRESSAO_MINIMA',
  payload: value
});

export const alterarPeso = value => ({
  type: 'INPUT_PESO',
  payload: value
});

export const alterarAltura = value => ({
  type: 'INPUT_ALTURA',
  payload: value
});

export const alterarDescricaoExameFisico = value => ({
  type: 'INPUT_DESCRICAO_EXAME_FISICO',
  payload: value
});

export const alterarCidPrincipal = (value, existeModuloNexoNoTemplate, existeModuloRestabelecimentoNoTemplate, existeModuloIsencaoNoTemplate) => ({
  type: 'INPUT_CID_PRINCIPAL',
  payload: value,
  existeModuloNexoNoTemplate,
  existeModuloRestabelecimentoNoTemplate,
  existeModuloIsencaoNoTemplate
});

export const alterarNaoExisteCidSecundario = value => ({
  type: 'INPUT_NAO_EXISTE_CID_SECUNDARIO',
  payload: value
});

export const novoCidSecundario = (novoCid, nomeNovoCid) => ({
  type: 'INCLUIR_CID_SECUNDARIO',
  payload: novoCid,
  nomeNovoCid
});

export const excluirCidSecundario = cidSecundario => {
  return (dispatch) => {
    dispatch(ocultarDialogExcluirCid());
    dispatch({
      type: 'EXCLUIR_CID_SECUNDARIO',
      payload: cidSecundario
    });
  }
};

export const alterarJustificativaDominiosFuncoesCorpoComValorZero = (valor) => ({
  type: 'ALTERAR_JUSTIF_DOMINIO_FUNC_CORPO_ZERADO',
  payload: valor
})

export const alterarJustificativaAtividadeParticipacaoComValorZero = (valor) => ({
  type: 'ALTERAR_JUSTIF_DOMINIO_ATIVIDADES_PARTICIPACAO_ZERADO',
  payload: valor
})

export const alterarRespostaPerguntaFormulario = (idPergunta, valorResposta) => {
  return (dispatch) => {
    dispatch({
      type: 'ALTERAR_RESPOSTA_PERGUNTA_FORM',
      payload: {
        idPerguntaFormulario: idPergunta,
        qualificador: valorResposta ? parseInt(valorResposta) : undefined
      }
    });

  }
};

export const alterarRespostaDependenciaPerguntaFormulario = (idPergunta, valorRespostaDependencia) => {
  return (dispatch) => {
    dispatch({
      type: 'ALTERAR_RESPOSTA_DEPENDENCIA_PERGUNTA_FORM',
      payload: {
        idPerguntaFormulario: idPergunta,
        dependenciaTerceiros: valorRespostaDependencia ? valorRespostaDependencia : undefined
      }
    });

  }
};

export const alterarRespostaGrupoFormulario = (idGrupo, valorResposta, isAtribuidoAutomatico = false) => {
  return (dispatch) => {
    dispatch({
      type: 'ALTERAR_RESPOSTA_GRUPO_FORM',
      payload: {
        idGrupoFormulario: idGrupo,
        qualificador: valorResposta ? parseInt(valorResposta) : undefined,
        isAtribuidoAutomatico
      }
    });
  }
}

export const alterarJustificativaGrupoFormulario = (idGrupo, codigoJustificativa) => ({
  type: 'ALTERAR_JUSTIFICATIVA_GRUPO_FORM',
  payload: { idGrupoFormulario: idGrupo, justQualifZero: codigoJustificativa !== undefined ? parseInt(codigoJustificativa) : undefined }
});

export const alterarRespostaEstrutura = value => ({
  type: 'INPUT_ALTERACAO_ESTRUTURA_CORPO',
  payload: value
});

export const alterarEstruturasSelecionadas = (value) => ({
  type: 'INPUT_SELECIONAR_ESTRUTURA_CORPO',
  payload: value
});

export const alterarDescricaoEstruturaCorpo = (value) => ({
  type: 'INPUT_DESCRICAO_ESTRUTURA_CORPO',
  payload: value
});

export const alterarPrognostico = (value) => ({
  type: 'INPUT_PROGNOSTICO',
  payload: value
});

export const alterarDescricaoPrognostico = (value) => ({
  type: 'INPUT_DESCRICAO_PROGNOSTICO',
  payload: value
});

export const alterarDuracao = (value) => ({
  type: 'INPUT_DURACAO_ALTERACOES',
  payload: value
});

export const alteraImpedimentoLongoPrazo = (value) => ({
  type: 'INPUT_IMPEDIMENTO_LONGO_PRAZO',
  payload: value
});

export const alterarDescricaoDuracao = (value) => ({
  type: 'INPUT_DESCRICAO_DURACAO_ALTERACOES',
  payload: value
});

export const alterarCausasDeficienciaSelecionadas = (value) => ({
  type: 'INPUT_CAUSAS_DEFICIENCIA',
  payload: value
});

export const alterarOutraCausaDeficiencia = (value) => ({
  type: 'INPUT_OUTRA_CAUSA_DEFICIENCIA',
  payload: value
});

export const alterarExisteRiscoSocial = (value) => ({
  type: 'INPUT_EXISTE_RISCO_SOCIAL',
  payload: value
});

export const alterarRiscoSocial = (value) => ({
  type: 'INPUT_RISCO_SOCIAL',
  payload: value
});

export const alterarObservacaoAvaliador = (value) => ({
  type: 'INPUT_OBSERVACAO_AVALIADOR',
  payload: value
});

export const alterarJustificativaConclusao = (value) => ({
  type: 'INPUT_JUSTIFICATIVA_CONCLUSAO',
  payload: value
});

export const alterarResultadoGrauDependencia = (value) => ({
  type: 'INPUT_GRAU_DEPENDENCIA',
  payload: value
});

export const alterarNaoExisteExameLaudo = (value) => ({
  type: 'INPUT_NAO_EXISTE_EXAME_LAUDO',
  payload: value
});

export const incluirExame = novoExameLaudo => {
  const dados = obterDadosValidacaoExameLaudo(novoExameLaudo);
  return (dispatch) => {
    dispatch(atualizarDadosValidacaoExameLaudo(dados));
    if (dados.isValida) {
      dispatch(ocultarDialogExameLaudo());
      dispatch({
        type: 'INCLUIR_EXAME_LAUDO',
        payload: novoExameLaudo
      });
    }
  }
};

export const alterarExame = exameLaudo => {
  const dados = obterDadosValidacaoExameLaudo(exameLaudo);
  return (dispatch) => {
    dispatch(atualizarDadosValidacaoExameLaudo(dados));
    if (dados.isValida) {
      dispatch(ocultarDialogExameLaudo());
      dispatch({
        type: 'ALTERAR_EXAME_LAUDO',
        payload: exameLaudo
      });
    }
  }
};

export const excluirExame = exameLaudo => {
  return (dispatch) => {
    dispatch(ocultarDialogExcluirExame());
    dispatch({
      type: 'EXCLUIR_EXAME_LAUDO',
      payload: exameLaudo
    });
  }
};

export const incluirDocumMedOdont = (novoDocumMedOdont) => {
  return (dispatch, getState) => {
    const dados = obterDadosValidacao(novoDocumMedOdont, getState().principal.dominioPericia.tiposIdentEmitentePericiaBI);
    dispatch(atualizarDadosValidacaoDocumMedOdont(dados));
    if (dados.isValida) {
      dispatch(ocultarDialogDocumMedOdont());
      dispatch(adicionarDadosDeMedicoEncontrado(null));
      dispatch({
        type: 'INCLUIR_DOCUM_MED_ODONT',
        payload: {...novoDocumMedOdont}
      });
    } else if (dados.erroDadosDoMedicoNaoEncontrado) {
      dispatch(dispatch(adicionarMensagemErroDialogDocumMedOdont(dados.erroDadosDoMedicoNaoEncontrado)))
    }
  }
};

export const alterarDocumMedOdont = (documMedOdont) => {
  return (dispatch, getState) => {
    const dados = obterDadosValidacao(documMedOdont, getState().principal.dominioPericia.tiposIdentEmitentePericiaBI);
    dispatch(atualizarDadosValidacaoDocumMedOdont(dados));
    if (dados.isValida) {
      dispatch(ocultarDialogDocumMedOdont());
      dispatch(adicionarDadosDeMedicoEncontrado(null));
      dispatch({
        type: 'ALTERAR_DOCUM_MED_ODONT',
        payload: {...documMedOdont}
      });
    } else if (dados.erroDadosDoMedicoNaoEncontrado) {
      dispatch(dispatch(adicionarMensagemErroDialogDocumMedOdont(dados.erroDadosDoMedicoNaoEncontrado)))
    }
  }
};

export const excluirDocumMedOdont = documMedOdont => {
  return (dispatch) => {
    dispatch(ocultarDialogExcluirDocumMedOdont());
    dispatch({
      type: 'EXCLUIR_DOCUM_MED_ODONT',
      payload: documMedOdont
    });
  }
};

export const downloadPericia = (idPericia, nomeArquivo) => {
  return dispatch => {
    dispatch(displayLoader(true));
    getSeguroPdf(dispatch, `pmf-realizar-pericias-service/pericia/${idPericia}/download-pdf`, sucessoDownloadPericia, erroDownloadPericia, `${nomeArquivo}.pdf`);
  };
};

export const downloadPericiaGenerico = (idPericia, nomeArquivo, idPericiaAnterior) => {
  return async dispatch => {
    dispatch(displayLoader(true));

    let sufixoNomeArquivo = '';
    if(idPericiaAnterior){
      //deve buscar protocolo PMF da pericia origem para incluir no nome do PDF

      await getSeguroPromise(`pmf-realizar-pericias-service/pericia/${idPericiaAnterior}/resumo`)
        .then((response) => {
          const resumoPericia = response.data;
          if(resumoPericia.protocoloPMF){
            sufixoNomeArquivo = `_protocolo_tarefa_PMF_origem_${resumoPericia.protocoloPMF}`;
          }
        })
        .catch((falha) => dispatch(errorMessage(falha, 'Buscar perícia origem')));
    }
    
    getSeguroPdf(dispatch, `pmf-realizar-pericias-service/pericia/${idPericia}/download-pdf-generico`, sucessoDownloadPericia, erroDownloadPericia, `${nomeArquivo+sufixoNomeArquivo}.pdf`);
  };
};

const sucessoDownloadPericia = () => ({
  type: 'DOWNLOAD_PERICIA_SUCESSO'
})

const erroDownloadPericia = (error) => ([
  {
    type: 'DOWNLOAD_PERICIA_FALHA'
  },
  errorMessage(error, 'Imprimir')
]);

export const imprimirAvaliacaoSocial = () => {
  return (dispatch, getState) => {
    dispatch(displayLoader(true));
    let pericia = getState().pericia;
    getSeguroPromise(`avaliacao-social-service/avaliacoes`, { cpf: pericia.avaliado.cpf })
      .then((response) => {
        dispatch(displayLoader(false));
        let avaliacoes = !response.data.aviso && response.data.avaliacoes;
        let avaliacaoPericia = avaliacoes && avaliacoes.find(avaliacao => avaliacao.protocoloTarefaPrincipal === String(pericia.protocoloINSS));
        if (!avaliacaoPericia) {
          throw new Error(`A avaliação social não foi encontrada.`)
        }
        if (avaliacaoPericia.id == null) {
          throw new Error(`A avaliação social não foi iniciada.`)
        }
        return getSeguroPdf(dispatch, `avaliacao-social-service/avaliacoes/${avaliacaoPericia.id}/pdf-avaliacao-detalhada`, sucessoDownloadAvaliacaoSocial, erroDownloadAvaliacaoSocial, "avaliacao.pdf");
      })
      .catch((falha) => dispatch(errorMessage(falha, 'Imprimir Avaliação Social')));

  }
}


const sucessoDownloadAvaliacaoSocial = () => ({
  type: 'DOWNLOAD_AVALIACAO_SOCIAL_SUCESSO'
})

const erroDownloadAvaliacaoSocial = (error) => ([
  {
    type: 'DOWNLOAD_AVALIACAO_SOCIAL_FALHA'
  },
  errorMessage(error, 'Imprimir Avaliação Social')
]);

/**
 * Tratar casos onde o cidSecundario foi removido e adicionado novamente. É necessário 
 * substituir pelo cidSecundario já gravado anteriormente,  que foi guardado no reducer moduloCid.
 * @param {*} pericia 
 * @param {*} moduloCidAnterior 
 */
const tratarModuloCid = (pericia, moduloCidAnterior) => {
  let cidsSecundarios = pericia.moduloCid && pericia.moduloCid.cidsSecundarios;
  if (!cidsSecundarios) {
    return;
  }
  let novosCidsSecundarios = [];
  cidsSecundarios.forEach(cidSecundario => {
    if (cidSecundario.idCidSecundario) {
      novosCidsSecundarios.push(cidSecundario);
    } else {
      let cidSecundarioAnterior = moduloCidAnterior && moduloCidAnterior.cidsSecundarios && moduloCidAnterior.cidsSecundarios.find(cidSecund => cidSecund.cidSecundario === cidSecundario.cidSecundario);
      cidSecundarioAnterior ? novosCidsSecundarios.push(cidSecundarioAnterior) : novosCidsSecundarios.push(cidSecundario);
    }
  });
  pericia.moduloCid.cidsSecundarios = novosCidsSecundarios;
}

export const alterarIsencaoCarencia = value => ({
  type: 'INPUT_ALTERACAO_ISENCAO_CARENCIA',
  payload: value
});

export const alterarIsencaoCarenciaGestacaoRisco = value => ({
  type: 'INPUT_ALTERACAO_ISENCAO_CARENCIA_GESTACAO_RISCO',
  payload: value
});

export const alterarJustificativaGestacaoAltoRisco = value => ({
  type: 'INPUT_ALTERACAO_ISENCAO_CARENCIA_JUSTIFICATIVA_GESTACAO_RISCO',
  payload: value
});

export const alterarDoencaoIsencao = value => ({
  type: 'INPUT_ALTERACAO_DOENCA_ISENCAO',
  payload: value
});

export const alterarValorCampoGenerico = (idCampoGenerico, siglaCampo, valor, valoresCamposGenericos, pericia) => ({
  type: 'INPUT_CAMPO_GENERICO',
  payload: {
    idCampoGenerico,
    siglaCampo,
    valor,
    valoresCamposGenericos,
    pericia
  }
});

export const alterarCampoGenericoEAtualizarMensagemValidacao = (idCampoGenerico, siglaCampo, valor, valoresCamposGenericos, validacao, nomeModulo) => {
  return (dispatch) => {
    //alterar valor campo generico
    dispatch(alterarValorCampoGenerico(idCampoGenerico, siglaCampo, valor, valoresCamposGenericos));

    //se existe mensagem de validacao obrigatoria do campo generico, remover!
    if(validacao && validacao[`erro_${idCampoGenerico}`]){
      const validacaoAtualizada = {
        [`${nomeModulo}`]: {
          ...validacao, 
          [`erro_${idCampoGenerico}`]: ''
        }
      };
      dispatch({
        type: 'ATUALIZAR_DADOS_VALIDACAO',
        payload: { ...validacaoAtualizada }
      });
    }
  }
}

const verificarRegrasValidacaoTipoAlertaCampo = (idCampoGenerico, campo, valorCampo, pericia) => {
  const nomeCampo = campo.nome;
  let retorno = { valido: true };

  if (valorCampo && valorCampo.toString() === 'Invalid Date') {
    return retorno;
  }

  if (campo.regrasValidacoes && campo.regrasValidacoes.length > 0) {
    campo.regrasValidacoes.forEach(regra => {
      if (regra.tipoMensagem === tipoMensagemValidacaoCampoGenerico.ALERTA) {
        const resultadoRegra = verificarRegraValidacaoCampoGenerico(nomeCampo, valorCampo, pericia, regra);
        //deve atualizar valor de msg de alerta, independente do resultado 
        //-----> se NAO HAVIA alerta e passou a ter, deve INCLUIR msg de alerta
        //-----> se HAVIA alerta antes e passou a nao ter, deve REMOVER msg de alerta
        let dados = { [`alerta_${idCampoGenerico}`]: resultadoRegra.msg };

        if(!resultadoRegra.valido){
          //se esta incluindo msg de alerta, deve remover qq msg de erro anteriormente existente
          dados = {...dados, [`erro_${idCampoGenerico}`]: ''};
        }

        retorno = { ...retorno, ...{ valido: resultadoRegra.valido, dados: dados } };
      }
    });
  }
  return retorno;
}

export const alterarValorCampoGenericoData = (idCampoGenerico, siglaCampo, valor, campo, nomeModulo, checaNexoTecnico) => {
  return (dispatch, getState) => {
    if (valor === null || valor.toString() !== 'Invalid Date') {
      const pericia = getState().pericia;

      const existeNexoTecnicoPreenchido = checaNexoTecnico ?
        pericia.moduloNexoTecnico && (pericia.moduloNexoTecnico.existeNexoProfissional !== undefined
          || pericia.moduloNexoTecnico.existeNexoIndividual !== undefined
          || pericia.moduloNexoTecnico.existeNtep !== undefined) : undefined;

      const dataDiiAnterior = convertStringToDate(pericia.valoresCamposGenericos?.find(valorCampoGen => valorCampoGen.siglaCampoGenerico === SIGLA_CAMPO_DII)?.valorTextoCampoGenerico || "");
      const dataNova = converterDataFormatadaParaISOBr(valor);
      
      if (isDii(siglaCampo) && dataNova && dataDiiAnterior && !isData1EhIgualData2(dataDiiAnterior, dataNova) && existeNexoTecnicoPreenchido) {
        dispatch(exibirDialogAlterarDIDOuDII(idCampoGenerico, siglaCampo, valor, campo, nomeModulo));
      } else {
        if (isDii(siglaCampo) && dataNova && dataDiiAnterior && !isData1EhIgualData2(dataDiiAnterior, dataNova)) {
          removerDadosNexo(dispatch);
        }
        const retorno = campo ? verificarRegrasValidacaoTipoAlertaCampo(idCampoGenerico, campo, valor, pericia) : undefined;
        if (retorno) {
          dispatch({
            type: 'ATUALIZAR_DADO_VALIDACAO_MODULO',
            payload: { modulo: nomeModulo, novaValidacao: retorno.dados}
          });
        }

        dispatch({
          type: 'INPUT_CAMPO_GENERICO_DATA',
          payload: {
            idCampoGenerico,
            siglaCampo,
            valor
          }
        });
      }
    }
  }
};

const removerDadosNexo = (dispatch) => {
  dispatch(ocultarDialogAlterarDIDOuDII());
  // limpar nexo
  dispatch(limparVinculosNexotecnico());
  dispatch(atualizarFatoresRiscoCid(undefined));
  dispatch(atualizarBatimentoCidCnae(undefined));
  dispatch({
    type: 'ATUALIZAR_DADOS_VALIDACAO',
    payload: { nexoTecnico: undefined }
  });
}

export const exibirDialogAlterarDIDOuDII = (idCampoGenerico, siglaCampo, valor, campo, nomeModulo) => ({
  type: 'EXIBIR_DIALOG_ALTERAR_DID_OU_DII',
  payload: {
    idCampoGenerico,
    siglaCampo,
    valor,
    campo,
    nomeModulo
  }
});

export const limparVinculosNexotecnico = () => ({
  type: 'LIMPAR_VINCULOS_NEXO_TECNICO'
});

export const atualizarFatoresRiscoCid = data => ({
  type: 'ATUALIZAR_FATORES_RISCO_CID',
  payload: data
});

export const atualizarBatimentoCidCnae = data => ({
  type: 'ATUALIZAR_BATIMENTO_CID_CNAE',
  payload: data
});


export const ocultarDialogAlterarDIDOuDII = () => ({
  type: 'OCULTAR_DIALOG_ALTERAR_DID_OU_DII'
});

export const alterarExisteNexoProfissional = value => ({
  type: 'INPUT_ALTERACAO_EXISTE_NEXO_PROFISSIONAL',
  payload: value
});

export const alterarFundamentacaoNexoProfissional = (value) => ({
  type: 'INPUT_FUNDAMENTACAO_NEXO_PROFISSIONAL',
  payload: value
});

export const alterarJustifNegacaoNexoProfissional = (value) => ({
  type: 'INPUT_JUSTIF_NEGACAO_NEXO_PROFISSIONAL',
  payload: value
});

export const alterarExisteNexoIndividual = value => ({
  type: 'INPUT_ALTERACAO_EXISTE_NEXO_INDIVIDUAL',
  payload: value
});

export const alterarFundamentacaoNexoIndividual = (value) => ({
  type: 'INPUT_FUNDAMENTACAO_NEXO_INDIVIDUAL',
  payload: value
});

export const alterarExisteNexoEpidemiologico = value => ({
  type: 'INPUT_EXISTE_NTEP',
  payload: value
});

export const alterarFundamentacaoNaoFixacaoNtep = (idVinculo, value) => ({
  type: 'INPUT_FUNDAMENTACAO_NAO_FIXACAO_NTEP',
  payload: {
    idVinculo: idVinculo,
    value: value
  }
});

export const marcarIncapacidadeVinculo = (item, value, deveLimparDadosNtep) => ({
  type: 'INPUT_INCAPACIDADE_VINCULO',
  payload: {
    item,
    value
  },
  deveLimparDadosNtep
});

export const marcarIncapacidadeSemVinculos = value => ({
  type: 'INPUT_INCAPACIDADE_SEM_VINCULOS_RELACIONADOS',
  payload: value
});

export const relacionarVinculoAoNexo = (item, checked, tipoNexo) => ({
  type: 'INPUT_NEXO_VINCULO',
  payload: {
    item,
    checked,
    tipoNexo
  }
});

export const alterarFatoresRiscoProfissionalSelecionados = value => ({
  type: 'INPUT_FATORES_RISCO_PROFISSIONAL',
  payload: value
});

export const alterarTipoDoencaNexoProfissional = value => ({
  type: 'INPUT_TIPO_DOENCA_NEXO_PROFISSIONAL',
  payload: value
});

export const alterarTipoNexoIndividual = value => ({
  type: 'INPUT_TIPO_NEXO_INDIVIDUAL',
  payload: value
});

export const alterarMotivoNaoFixacaoNtep = (idVinculo, value) => ({
  type: 'INPUT_MOTIVO_NAO_FIXACAO_NTEP',
  payload: {
    idVinculo: idVinculo,
    value: value
  }
});

export const registrarSistemaExaminado = (idSistema, value, descricaoPadrao) => ({
  type: 'INPUT_ALTERACAO_SISTEMA_EXAMINADO',
  payload: {
    idSistema: idSistema,
    value: value,
    descricaoPadrao: descricaoPadrao
  }
});
export const alterarDescricaoSistemaExaminado = (idSistema, value) => ({
  type: 'INPUT_DESCRICAO_SISTEMA_EXAMINADO',
  payload: {
    idSistema: idSistema,
    value: value
  }
});

export const alterarTipoGrauIncapacidade = value => ({
  type: 'INPUT_TIPO_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarDCBGrauIncapacidade = (value, alteracaoAutomatica) => ({
  type: 'INPUT_DCB_GRAU_INCAPACIDADE',
  payload: {
    value: value,
    alteracaoAutomatica: alteracaoAutomatica
  }
});

export const alterarIndicadorAuxilioAcidente = value => ({
  type: 'INPUT_INDICADOR_AUX_ACIDENTE',
  payload: value
});

export const alterarJustAuxAcidGrauIncapacidade = value => ({
  type: 'INPUT_JUST_AUX_ACID_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarJustDCIGrauIncapacidade = value => ({
  type: 'INPUT_JUST_DCI_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarJustLIGrauIncapacidade = value => ({
  type: 'INPUT_JUST_LI_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarIsencaoIRGrauIncapacidade = value => ({
  type: 'INPUT_ISENCAO_IR_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarMajoracaoGrauIncapacidade = value => ({
  type: 'INPUT_MAJORACAO_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarPassivelReavaliacao2anos = value => ({
  type: 'INPUT_PASSIVEL_REAVALIACAO_2_ANOS_GRAU_INCAPACIDADE',
  payload: value
});

export const alterarDataComprovIncapac = value => ({
  type: 'INPUT_DCI',
  payload: value
});

export const alterarEnquadramentoAuxilioAcidente = value => ({
  type: 'INPUT_TIPO_ENQUAD_AUX_ACID',
  payload: value
});

export const alterarSituacaoEnquadramentoAuxilioAcidente = value => ({
  type: 'INPUT_TIPO_SIT_ENQUAD_AUX_ACID',
  payload: value
});

export const alterarTipoIsencaoImpostoRenda = value => ({
  type: 'INPUT_TIPO_MOTIVO_ISENCAO_IR',
  payload: value
});

export const alterarTipoMajoracao25 = value => ({
  type: 'INPUT_TIPO_MOTIVO_MAJORACAO_25',
  payload: value
});

export const alterarExisteAgravamentoLesao = (value, idBeneficio) => ({
  type: 'INPUT_EXISTE_AGRAVAMENTO_LESAO',
  payload: value,
  idBeneficio
});

export const alterarExisteRestabelecimento = (value, idBeneficio) => ({
  type: 'INPUT_EXISTE_RESTABELECIMENTO',
  payload: value,
  idBeneficio
});