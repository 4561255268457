import { modulosEstaticos } from './constants';
import { tipoOperacao, somarOuSubtrair } from '../../common/Utils';
import { converterDataFormatadaParaISOBr } from "../../common/DataUtils";

export const obterFomularioFuncoesDoCorpo = function (template) {
  if (!template || !template.modulosTemplatePericia) {
    return undefined;
  }
  const codigoFormularioFuncoesCorpo = modulosEstaticos.FUNCOES_DO_CORPO.codigo;
  let moduloFormulario = template.modulosTemplatePericia.find((mod) => mod.modulo.tipoModuloEstatico === codigoFormularioFuncoesCorpo);
  return moduloFormulario ? moduloFormulario.modulo.formulario : undefined;
}

export const obterFomularioAtividadesEParticipacao = (template) => {
  if (!template || !template.modulosTemplatePericia) {
    return undefined;
  }
  const codigoFormularioAtividadesEParticipacao = modulosEstaticos.ATIVIDADES_E_PARTICIPACAO.codigo;
  let moduloFormulario = template.modulosTemplatePericia.find((mod) => mod.modulo.tipoModuloEstatico === codigoFormularioAtividadesEParticipacao);

  return moduloFormulario ? moduloFormulario.modulo.formulario : undefined;
}

export const obterGrupoFomularioPorFormulario = function (formulario, idGrupo) {
  if (!formulario || !formulario.gruposFormularios) {
    return undefined;
  }
  return formulario.gruposFormularios.find((grupo) => grupo.id === idGrupo);
}

export const obterValorParametroModuloEstatico = (template, idParametro) => {
  if (template && template.valoresParametrosModuloEstatico) {
    const valor = template.valoresParametrosModuloEstatico.find(v => idParametro === v.idParametroModuloEstatico);
    return valor != null ? valor.valorParametro : null;
  }
  return null;
}

export const existeModuloEstaticoNoTemplate = (template, codigoModulo) => {
  let existeModulo = false;

  if (template?.modulosTemplatePericia) {

    existeModulo = template.modulosTemplatePericia.some(m => existeModuloEstaticoNoModuloTemplate(m, codigoModulo));

    // se modulo nao encontrado no primeiro nivel, busca nos modulos filhos
    if (!existeModulo) {
      existeModulo = template.modulosTemplatePericia.some(m =>
        m.modulosTemplateFilhos && m.modulosTemplateFilhos.some(f => existeModuloEstaticoNoModuloTemplate(f, codigoModulo))
      );
    }
  }
  return existeModulo;
}

const existeModuloEstaticoNoModuloTemplate = (moduloTemplate, codigoModulo) => {
  return moduloTemplate.modulo.tipoModuloEstatico === codigoModulo //é modulo estatico buscado
    || (moduloTemplate.modulo.secoes
      && moduloTemplate.modulo.secoes.some(s => //contem secao com modulo estatico buscado
        s.moduloEstaticoFilho
        && s.moduloEstaticoFilho.tipoModuloEstatico === codigoModulo));
}

export const obterCampoGenericoPorSigla = (sigla, template) => {

  const moduloComCampo = template?.modulosTemplatePericia?.find(m =>
    //buscar campo no modulo
    m.modulo.camposGenericos?.find(c => c.campoGenerico.sigla === sigla)
    //buscar em campos em secoes do modulo
    || m.modulo.secoes?.find(s => s.secoesModulosCamposGenericos?.find(sc => sc.campoGenerico.sigla === sigla)));

  if (moduloComCampo) {
    //obter campo generico do modulo
    let campo = moduloComCampo.modulo.camposGenericos?.find(c => c.campoGenerico.sigla === sigla);

    if (!campo) {
      //obter secao que contem campo generico
      const secaoCampo = moduloComCampo.modulo.secoes?.find(s => s.secoesModulosCamposGenericos?.find(sc => sc.campoGenerico.sigla === sigla));
      //obter campo generico da secao
      campo = secaoCampo.secoesModulosCamposGenericos.find(sc => sc.campoGenerico.sigla === sigla);
    }
    return campo;
  }
  return undefined;
}

export const obterDataParametroPeriodo = (valorParametro, dataReferencia, isRetroacao) => {
  const data = new Date(dataReferencia.getTime());
  const regex = /[A-Z]/g;
  let indicePeriodo = valorParametro.search(regex);
  let periodo = indicePeriodo >= 0 ? valorParametro.substring(indicePeriodo) : undefined;
  let valorCalculo = periodo ? valorParametro.substring(0, indicePeriodo) : valorParametro;

  const operador = isRetroacao ? tipoOperacao.SUBTRACAO : tipoOperacao.SOMA;
  let resultado;

  switch (periodo) {
    case 'D':
      resultado = somarOuSubtrair(data.getDate(), valorCalculo, operador);
      data.setDate(resultado);
      return converterDataFormatadaParaISOBr(data);
    case 'M':
      resultado = somarOuSubtrair(data.getMonth(), valorCalculo, operador);
      data.setMonth(resultado);
      return converterDataFormatadaParaISOBr(data);
    case 'A':
      resultado = somarOuSubtrair(data.getFullYear(), valorCalculo, operador);
      data.setFullYear(resultado);
      return converterDataFormatadaParaISOBr(data);
    default:
      break
  }
  return undefined;
}