import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrCard from '@govbr/react-components/lib/BrCard';
import Box from "@material-ui/core/Box";

import { Link } from "react-router-dom";

import { formatarDateTimeISOToDataBr } from '../../common/DataUtils';
import { formatarCPF } from '../../common/formatadores';
import { isTipoPericiaBI } from "../../common/PericiaUtils";

import { limparRespostaConcluirPericiaErro } from '../pericia/action';
import { modulosEstaticos, tipoSimNao, tiposPericia } from '../pericia/constants';
import { obterUrlProximaAba } from '../pericia/validacaoCampos';
import { atualizarAbaSelecionada } from './action';
import { obtemFluxoRBI, tipoFluxoRBI } from '../pericia/modulos/GrauIncapacidadeRBI/utils';


class MenuContextual extends React.Component {

  constructor(props) {
    super(props);
    this.atualizarAbaSelecionada = this.atualizarAbaSelecionada.bind(this);
    this.limparRespostaConcluirPericiaErro = this.limparMensagensValidacaoBackendNaAbaConclusao.bind(this);
  }

  limparMensagensValidacaoBackendNaAbaConclusao(idAba) {
    const moduloTemplateAtual = Object.values(modulosEstaticos).find(m => m.codigo === idAba) ?
      this.props.template.modulosTemplatePericia.find(m => m.modulo.tipoModuloEstatico === idAba) :
      this.props.template.modulosTemplatePericia.find(t => t.id === idAba);

    const existeProximaAba = obterUrlProximaAba(moduloTemplateAtual, this.props.template);

    // Se não existe próxima aba, então se trata da aba de conclusão
    if (!existeProximaAba) { this.props.limparRespostaConcluirPericiaErro(); }
  }

  atualizarAbaSelecionada(idAba) {
    this.limparMensagensValidacaoBackendNaAbaConclusao(idAba);
    this.props.atualizarAbaSelecionada(idAba);
  }

  obterFasePericiaRBI(pericia) {
    return obtemFluxoRBI(pericia) === tipoFluxoRBI.FLUXO_BENEFICIOS_INCAPACIDADE 
      ? 'RBI (31/91)' 
      : obtemFluxoRBI(pericia) === tipoFluxoRBI.FLUXO_BENEFICIOS_INVALIDEZ  
        ? 'RBI (32/92)'
        : 'Indefinido';
  }

  obterFasePericia(tipoPericia, tipoPericiaOrigem, pericia) {
    return tipoPericia 
            ? tipoPericia !== tiposPericia.INSS_BI_REVISAO.tipo 
              ? tipoPericia === tiposPericia.INSS_BI_PRBI.tipo
                ? this.obterFasePericiaRBI(pericia)
                : tiposPericia[tipoPericia].fase 
              : 'Revisão de ' + tiposPericia[tipoPericiaOrigem].fase 
            : ' --- ';
  }

  render() {
    const { abas, idAbaSelecionada,  validacao,  isPericiaBPC, pericia, tipoPericiaOrigem, template } = this.props;
    const {avaliado,situacao,dataReferencia, tipoPericia, dataUltimoDiaTrabalhado, dutInformadaPorRequerente } = pericia;

    const isMaior16Anos = avaliado.idade > 16;
    const deveExibirDut = isTipoPericiaBI(template) && dataUltimoDiaTrabalhado;

    return (
      <div className="col-sm-4 col-md-3" id="container-navigation">
        <BrCard>
          <p className="texto-informativo">
            {avaliado.nome}
          </p>
          <p>
            <span>CPF: </span>
            <span id="cpfAvaliado" className="texto-informativo" >
              {formatarCPF(avaliado.cpf)}
            </span>
          </p>
          <p>
            <span>Fase: </span>
            <span className="texto-informativo" >{this.obterFasePericia(tipoPericia, tipoPericiaOrigem, pericia)}</span>
          </p>
          {isPericiaBPC &&
            <p>
              <span>Avaliado: </span>
              <span className="texto-informativo" >
                {isMaior16Anos ? "16 Anos ou Mais" : "Menor que 16 anos"}
              </span>
            </p>
          }
          <p>
            <span>DER: </span>
            <span id="der" className="texto-informativo" >
              {dataReferencia ? formatarDateTimeISOToDataBr(dataReferencia) : " --- "}
            </span>
          </p>
          { deveExibirDut &&
            <p>
              <span>DUT: </span>
              <span id="dut" className="texto-informativo" >
                {dataUltimoDiaTrabalhado ? formatarDateTimeISOToDataBr(dataUltimoDiaTrabalhado) : " --- "}
              </span>
            </p>
          }
          { deveExibirDut &&
            <p>
              <span>Origem da DUT: </span>
              <span id="origemDut" className="texto-informativo" >
                {dutInformadaPorRequerente !== null
                  ? (dutInformadaPorRequerente ? "Segurado" : "SIBE")
                  : " --- "}
              </span>
            </p>
          }
          <p>
            <span>Situação: </span>
            <span id="situacaoPericia" className="texto-informativo" >
              {situacao.descricao}
            </span>
          </p>
        </BrCard>
        <div className="br-menu auxiliary menu-principal">
          <div className="br-menu auxiliary menu-principal">
            <div className="menu-trigger">
              {/* TODO: fazer botao funcionar como template, exibindo apenas quando resolucao pequena e ocultando opcoes menu */}

              {/* <button className="br-button primary block" type="button" data-toggle="menu">
                Menu Auxiliar
                <i className="fas fa-angle-up" aria-hidden="true" />
              </button> */}
            </div>
            <div className="menu-container">
              <div className="menu-panel">
                <nav className="menu-body">
                  <div className="folders">
                    <div className="folder">
                      <ul>
                        {abas.map(data => {
                          let validacaoAba = validacao[data.path];
                          return (
                            <li key={`menu-opcao-${data.id}`}>
                              <Link className={`item ${data.id === idAbaSelecionada ? 'active' : null}`} to={data.path}
                                onClick={() => this.atualizarAbaSelecionada(data.id)}>
                                <div className="content">
                                  <Box display="flex">
                                    <Box flexGrow={1}>
                                      {data.label}
                                    </Box>
                                    {validacaoAba && validacaoAba.isValida !== undefined &&
                                      (<Box ml={1}>
                                        <i className={`fas ${validacaoAba.isValida ? 'fa-check-circle' : 'fa-times-circle color-danger'}`} />
                                      </Box>)}
                                  </Box>
                                </div>
                              </Link>
                            </li>
                          );
                        })
                        }
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="menu-scrim" data-dismiss="menu" tabIndex="0"></div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { abas, idAbaSelecionada, validacao, isPericiaBPC } = state.principal;
  return ({
    abas,
    idAbaSelecionada,
    validacao,
    dadosAvaliado: state.pericia.avaliado,
    situacao: state.pericia.situacao,
    tipoPericia: state.pericia.tipoPericia,
    tipoPericiaOrigem: tipoSimNao.SIM.descricao === state.principal.template.templateComposto ? state.pericia.tipoPericiaRaizDaCadeiaDeRevisoes : state.pericia.periciaOrigemRevisao?.tipoPericia,
    isPericiaBPC,
    pericia: state.pericia,
    template: state.principal.template
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  atualizarAbaSelecionada,
  limparRespostaConcluirPericiaErro,
  obterUrlProximaAba
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuContextual);