import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {
  return pericia.examesSistemaCorpo?.length > 0;
}

export const validar = pericia => {

  const examesSistemas = pericia?.examesSistemaCorpo;
  let errosExamesSistema = {};

  if (examesSistemas && examesSistemas.length > 0) {
    examesSistemas.forEach(e => {
      const isDescricaoSistemaPreenchido = e.descricao && e.descricao.trim().length > 0;

      if (e.examinado === 1 && !isDescricaoSistemaPreenchido) {
        errosExamesSistema = {
          ...errosExamesSistema,
          [`erroDescricaoObrigatoria_sistema_${e.idSistema}`]: MSG_CAMPO_OBRIGATORIO
        };
      }
    });
  }

  return {
    isValida: Object.values(errosExamesSistema).length <= 0,
    ...errosExamesSistema
  }

}