import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import Concluir from '../../../components/Concluir';

import DominioConsolidado from '../../../components/ModuloFormulario/DominioConsolidado';

import BrCard from '@govbr/react-components/lib/BrCard';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import { Grid } from "@material-ui/core";

import { calcularCaracteresRestantes } from '../../../common/Utils';
import { obterDominios, obterMapRespostasGrupos, obterQualificadorFinalAtividadesParticipacao, obterQualificadorFinalFuncoesCorpo, obterQualificadorFuncoesCorpoElevado, obterValoresPorDominios } from '../../../components/ModuloFormulario/utils';
import { obterFomularioAtividadesEParticipacao, obterFomularioFuncoesDoCorpo } from '../templatePericiaUtil';

import { validacaoCompletaPericia } from "../../pericia/action";
import { alterarObservacaoAvaliador } from '../action';

import ButtonSelecionarItem from '../../dicionario/ButtonSelecionarItem';

class ResultadoParcial extends React.Component {
  QTD_MAXIMA_CARACTERES = 4000;

  componentDidMount() {
    const { pericia, validacaoCompletaPericia } = this.props;
    if (pericia) {
      validacaoCompletaPericia(pericia, true);
    }
  }

  render() {
    const { respostaConcluir, template, resultadoParcial, respostasGrupoForm, moduloEstruturaPrognostico,
      alterarObservacaoAvaliador, isSomenteLeitura } = this.props;
    const { alteracaoEstruturaCorpo, tipoProgDesfavoravel } = moduloEstruturaPrognostico;

    const mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupoForm);
    const existeElevacaoQualificador = (alteracaoEstruturaCorpo && alteracaoEstruturaCorpo === 1)
      || (tipoProgDesfavoravel && tipoProgDesfavoravel === 2);
    const { observacoesAvaliador } = resultadoParcial;

    const caracteresRestantes = calcularCaracteresRestantes(observacoesAvaliador, this.QTD_MAXIMA_CARACTERES);
    let isResultadoFuncoesCorpoGraveOuCompleto, isResultadoAtividadesParticipacaoNenhumaOuLeve;

    /** formulario Funcoes do Corpo */
    const formularioFuncoesCorpo = obterFomularioFuncoesDoCorpo(template);
    const gruposFormFuncoesCorpo = formularioFuncoesCorpo && formularioFuncoesCorpo.gruposFormulario;

    let qualificadorFuncoesCorpo, valoresPorDominioFuncoesCorpo;

    if (formularioFuncoesCorpo) {

      valoresPorDominioFuncoesCorpo = obterValoresPorDominios(gruposFormFuncoesCorpo, mapRespostasGrupos);
      const dominios = obterDominios(gruposFormFuncoesCorpo);

      let qualificadorFinalFuncoesDoCorpo = obterQualificadorFinalFuncoesCorpo(valoresPorDominioFuncoesCorpo);
      if (existeElevacaoQualificador) {
        qualificadorFinalFuncoesDoCorpo = obterQualificadorFuncoesCorpoElevado(qualificadorFinalFuncoesDoCorpo);
      }
      const isQuestionarioFinalizado = valoresPorDominioFuncoesCorpo && valoresPorDominioFuncoesCorpo.size === dominios.length;
      isResultadoFuncoesCorpoGraveOuCompleto = isQuestionarioFinalizado
        && (qualificadorFinalFuncoesDoCorpo.sigla === 'G' || qualificadorFinalFuncoesDoCorpo.sigla === 'C');

      qualificadorFuncoesCorpo = isQuestionarioFinalizado && qualificadorFinalFuncoesDoCorpo;
    }

    /** formulario Atividades e Participacao */
    const formularioAtividadesParticipacao = obterFomularioAtividadesEParticipacao(template);
    const gruposFormAtividadesParticipacao = formularioAtividadesParticipacao && formularioAtividadesParticipacao.gruposFormulario;

    let qualificadorAtividadesParticipacao, valoresPorDominioAtividadesParticipacao;

    if (formularioAtividadesParticipacao) {

      valoresPorDominioAtividadesParticipacao = obterValoresPorDominios(gruposFormAtividadesParticipacao, mapRespostasGrupos);
      const dominios = obterDominios(gruposFormAtividadesParticipacao);

      const qualificadorFinalAtividadesParticipacao = obterQualificadorFinalAtividadesParticipacao(valoresPorDominioAtividadesParticipacao);
      const isQuestionarioFinalizado = valoresPorDominioAtividadesParticipacao && valoresPorDominioAtividadesParticipacao.size === dominios.length;
      isResultadoAtividadesParticipacaoNenhumaOuLeve = isQuestionarioFinalizado
        && (qualificadorFinalAtividadesParticipacao.sigla === 'N' || qualificadorFinalAtividadesParticipacao.sigla === 'L');

      qualificadorAtividadesParticipacao = isQuestionarioFinalizado && qualificadorFinalAtividadesParticipacao;
    }

    const resultadoImprovavel = isResultadoFuncoesCorpoGraveOuCompleto && isResultadoAtividadesParticipacaoNenhumaOuLeve;

    const dadosResultadoParcial = {
      tipoIncapacAtivParticipacao: qualificadorAtividadesParticipacao ? qualificadorAtividadesParticipacao.id : null,
      tipoIncapacFuncaoCorpo: qualificadorFuncoesCorpo ? qualificadorFuncoesCorpo.id : null,
      resultadoImprovavel,
      existeElevacaoQualificador
    };

    const isCampoTextareaDesabilitado = isSomenteLeitura;

    return (
      <div id="abaResultadoParcial" className="conteudoAbaSemHeaderSecao">
        <div>
          {
            respostaConcluir && respostaConcluir.erros
            && (
              respostaConcluir.erros.map((e, index) =>
              (
                <BrMessage key={`erro_${index}`} error>
                  {e}
                </BrMessage>)
              )
            )
          }
        </div>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <p>
              <label>Funções do Corpo</label>
            </p>
            <DominioConsolidado gruposFormulario={gruposFormFuncoesCorpo} valoresPorDominio={valoresPorDominioFuncoesCorpo} />
          </Grid>
          <Grid item xs={6}>
            <p>
              <label>Atividades e Participação</label>
            </p>
            <DominioConsolidado gruposFormulario={gruposFormAtividadesParticipacao} valoresPorDominio={valoresPorDominioAtividadesParticipacao} />
          </Grid>
        </Grid>

        <Grid container mb={2}>
          <Grid item xs={2}>
            {/* espacamento */}
          </Grid>
          <Grid item xs={8}>
            <BrCard style={{ width: "50%" }}>
              <p>
                <label>Resultado Parcial</label>
              </p>
              <p id="qualificadorFinalFuncoesCorpo">{`Funções do Corpo: ${qualificadorFuncoesCorpo
                ? ` ${qualificadorFuncoesCorpo.sigla} - ${qualificadorFuncoesCorpo.descricao}`
                : ''}`}
              </p>
              <p id="qualificadorParcialAtividadesParticipacao">{`Qualificador Parcial de Atividades e Participação: ${qualificadorAtividadesParticipacao
                ? ` ${qualificadorAtividadesParticipacao.sigla} - ${qualificadorAtividadesParticipacao.descricao}`
                : ''}`}
              </p>
            </BrCard>
          </Grid>
          <Grid item xs={2}>
            {/* espacamento */}
          </Grid>
        </Grid>

        {resultadoImprovavel
          && (
            <BrMessage id="resultadoImprovavel" warning>
              O resultado da avaliação de Funções do Corpo e Atividades e Participação - Parte Médica é improvável. Confirma a avaliação?
            </BrMessage>
          )
        }

        <Grid container alignItems="center">
          <Grid item xs>
            <BrTextarea id="observacoesAvaliador" label="Observações do Avaliador"
              onChange={alterarObservacaoAvaliador}
              value={observacoesAvaliador}
              maxLength={this.QTD_MAXIMA_CARACTERES}
              help={`Caracteres restantes: ${caracteresRestantes}`}
              disabled={isSomenteLeitura} />
          </Grid>
          {
            !isCampoTextareaDesabilitado &&
            <Grid item >
               <ButtonSelecionarItem
                  id="selecionarItemDicionarioConclusaoBPC"
                  onSelect={alterarObservacaoAvaliador}
                  maxLength={caracteresRestantes}
                  valorAtual={observacoesAvaliador}/>
            </Grid>
          }
        </Grid>

        <Concluir dadosResultadoParcial={dadosResultadoParcial} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { respostaConcluir, resultadoParcialBPC, respostasGrupoForm, moduloEstruturaPrognostico } = state.pericia;
  const { template, isSomenteLeitura } = state.principal;
  return ({
    respostaConcluir,
    resultadoParcial: resultadoParcialBPC,
    template,
    respostasGrupoForm,
    moduloEstruturaPrognostico,
    isSomenteLeitura,
    pericia: state.pericia
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarObservacaoAvaliador,
  validacaoCompletaPericia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoParcial);