import React from "react";
import { Grid } from '@material-ui/core';

import { formatarDateTimeISOToDataBr } from "../../../../common/DataUtils";
import { obterTextoResultadoCapacidadeLaborativa, obterTextoResultadoNexoTecnico } from "../../../../common/PericiaBiUtil";
import { formatarSimNaoBooleano } from "../../../../common/formatadores";
import { SIGLA_CAMPO_DID, SIGLA_CAMPO_DII, SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE, SIGLA_CAMPO_CID_ALTERADO, SIGLA_CAMPO_GENERICO_PRORROGA } from '../../constants';

const DadosTecnicos = (props) => {
  const pericia = props.pericia;

  const cid = pericia.moduloCid?.cidPrincipal ?
    `${pericia.moduloCid?.cidPrincipal} - ${pericia.moduloCid?.nomeCidPrincipal}` : "";
  const valorIsentaCarencia = pericia.moduloIsencaoCarencia?.isentaCarencia;
  const valorIsentaCarenciaGestacaoRisco = pericia.moduloIsencaoCarencia?.isentaCarenciaPorGestacaoRisco;
  const isencaoCarencia = formatarSimNaoBooleano(valorIsentaCarencia);
  const isencaoCarenciaGestacaoRisco = formatarSimNaoBooleano(valorIsentaCarenciaGestacaoRisco);

  const campoAlteracaoCid = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO)?.valorTextoCampoGenerico;
  const houveAlteracaoCid = campoAlteracaoCid && campoAlteracaoCid === "1";
  const cidMantido = pericia.periciaAnterior?.codigoCID 
                    ? pericia.periciaAnterior?.codigoCID 
                    : cid; //para PMC judicial, se nao existir CID anterior o mesmo sera preenchido na realizacao, mesmo com resposta Mantem CID

  const did = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DID)?.valorTextoCampoGenerico;
  const dii = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_DII)?.valorTextoCampoGenerico;

  const comprovacaoIncapacidade = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE)?.valorTextoCampoGenerico;
  const houveComprovacaoIncapacidade = comprovacaoIncapacidade && comprovacaoIncapacidade === "1";
  const prorrogacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_GENERICO_PRORROGA)?.valorTextoCampoGenerico;
  const houveProrrogacao = prorrogacao && prorrogacao === "1";

  const exibeDadosAuxAcidente = !houveAlteracaoCid && prorrogacao && !houveProrrogacao;
  const auxAcidente = formatarSimNaoBooleano(pericia.moduloGrauIncapacidade?.indAuxilioAcidente);

  const deveExibirDIDDIINexo = houveComprovacaoIncapacidade || houveAlteracaoCid;
  const deveExibirDadosGrauIncapacidade = houveComprovacaoIncapacidade || houveAlteracaoCid || houveProrrogacao;
  
  const capacidadeLaborativa = obterTextoResultadoCapacidadeLaborativa(comprovacaoIncapacidade, campoAlteracaoCid, prorrogacao);
  const tipoGrauIncapacidade = pericia.moduloGrauIncapacidade?.tipoGrauIncapacidade;
  const grauIncapacidade = tipoGrauIncapacidade && props.tiposGrauIncapacidade
    && props.tiposGrauIncapacidade.find(t => t.id === tipoGrauIncapacidade)?.descricao;

  const deveExibirDcb = (deveExibirDadosGrauIncapacidade && (tipoGrauIncapacidade === 1 || tipoGrauIncapacidade === 2))
                        || (exibeDadosAuxAcidente && pericia.moduloGrauIncapacidade?.indAuxilioAcidente === 1);


  const dcb = pericia.moduloGrauIncapacidade?.dataCessacaoBeneficio && formatarDateTimeISOToDataBr(pericia.moduloGrauIncapacidade?.dataCessacaoBeneficio);
  const dci = pericia.moduloGrauIncapacidade?.dataComprovacaoIncapacidade && formatarDateTimeISOToDataBr(pericia.moduloGrauIncapacidade?.dataComprovacaoIncapacidade);
  const valorMajoracao = pericia.moduloGrauIncapacidade?.majoracao25;
  const valorIsencaoIR = pericia.moduloGrauIncapacidade?.doencaIsentaImpostoRenda;

  const majoracao = formatarSimNaoBooleano(valorMajoracao);
  const isencaoIR = formatarSimNaoBooleano(valorIsencaoIR);

  const nexoTecnico = obterTextoResultadoNexoTecnico(pericia.moduloNexoTecnico);
  const deveExibirNexo = deveExibirDIDDIINexo && pericia.moduloNexoTecnico &&
    (pericia.moduloNexoTecnico.existeNexoProfissional != null
      || pericia.moduloNexoTecnico.existeNexoIndividual != null
      || pericia.moduloNexoTecnico.existeNtep != null);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <span htmlFor="cid">CID Principal: </span>
          <span id="cid" className="texto-informativo">
            {(campoAlteracaoCid && !houveAlteracaoCid)
              ? cidMantido
              : cid}
          </span>
        </Grid>
        {
          (!campoAlteracaoCid || houveAlteracaoCid) &&
            <>
              {isencaoCarenciaGestacaoRisco &&
                <>
                  <Grid item xs={12}>
                    <span htmlFor="isencaoCarenciaGestacaoRisco">Isenção de Carência por Gestação de Alto Risco: </span>
                    <span id="isencaoCarenciaGestacaoRisco" className="texto-informativo">
                      {isencaoCarenciaGestacaoRisco}
                    </span>
                  </Grid>
                </>
              }
              {(!valorIsentaCarenciaGestacaoRisco || valorIsentaCarenciaGestacaoRisco === 0) &&
                <Grid item xs={12}>
                  <span htmlFor="isencaoCarencia">Isenção Carência: </span>
                  <span id="isencaoCarencia" className="texto-informativo">
                    {isencaoCarencia}
                  </span>
                </Grid> 
              }
            </>
        }
        <Grid item xs={12}>
          <span htmlFor="capLaborativa">Capacidade Laborativa: </span>
          <span id="capLaborativa" className="texto-informativo">{capacidadeLaborativa}</span>
        </Grid>
        {
          deveExibirDIDDIINexo &&
          (
            <>
              <Grid item xs={12}>
                <span htmlFor="did">DID: </span>
                <span id="did" className="texto-informativo">{did}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="dii">DII: </span>
                <span id="dii" className="texto-informativo">{dii}</span>
              </Grid>
            </>
          )
        }
        {
          deveExibirDadosGrauIncapacidade &&
          (
            <Grid item xs={12}>
              <span htmlFor="grauIncapacidade">Classificação da Incapacidade: </span>
              <span id="grauIncapacidade" className="texto-informativo">{grauIncapacidade}</span>
            </Grid>
          )
        }
        {
          exibeDadosAuxAcidente &&
          (
            <Grid item xs={12}>
              <span htmlFor="indAuxAcidente">Indicação de Auxílio Acidente - AA? </span>
              <span id="indAuxAcidente" className="texto-informativo">{auxAcidente}</span>
            </Grid>
          )
        }
        {
           deveExibirDcb &&
          (
            <Grid item xs={12}>
              <span htmlFor="dcb">DCB: </span>
              <span id="dcb" className="texto-informativo">{dcb}</span>
            </Grid>
          )
        }
        {
          deveExibirDadosGrauIncapacidade && tipoGrauIncapacidade === 3 &&
          (
            <Grid item xs={12}>
              <span htmlFor="dci">DCI: </span>
              <span id="dci" className="texto-informativo">{dci}</span>
            </Grid>
          )
        }
        {
          deveExibirDadosGrauIncapacidade && tipoGrauIncapacidade === 4 &&
          (
            <>
              <Grid item xs={12}>
                <span htmlFor="isencaoIR">Isenção de IR: </span>
                <span id="isencaoIR" className="texto-informativo">{isencaoIR}</span>
              </Grid>
              <Grid item xs={12}>
                <span htmlFor="majoracao">Majoração 25%: </span>
                <span id="majoracao" className="texto-informativo">{majoracao}</span>
              </Grid>
            </>
          )
        }
        {
          deveExibirNexo &&
          (
            <Grid item xs={12}>
              <span htmlFor="moduloNexoTecnico">Nexo Técnico: </span>
              <span id="moduloNexoTecnico" className="texto-informativo">{nexoTecnico}</span>
            </Grid>
          )
        }
      </Grid>
    </>
  );
}

export default DadosTecnicos;