import React from 'react';
import './estilo.css'

class Loading extends React.Component {

  render() {
    return (
      <div id="preloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    )
  }

}

export default Loading;