export const obterTipoIdentificacao = (idTipoIdentEmitente,tiposIdentificacaoEmitente)  => {
    const codigo = idTipoIdentEmitente !== undefined ? idTipoIdentEmitente : -1;
    const tipoEmitente = tiposIdentificacaoEmitente ? tiposIdentificacaoEmitente.find(t => t.id === codigo) : null;
    return tipoEmitente ? tipoEmitente.descricao : '';
  }

 export const obterTipoDocumMedOdont = (idTipoDocumMedOdont, tiposDocumMedOdont) => {
    const codigo = idTipoDocumMedOdont !== undefined ? idTipoDocumMedOdont : -1;
    const tipoDocumMedOdont = tiposDocumMedOdont ? tiposDocumMedOdont.find(t => t.id === codigo) : null;
    return tipoDocumMedOdont ? tipoDocumMedOdont.descricao : '';
  }

  export const obterNumeroComUf = (item, ufs) => {
    const codigoUf = item ? item.idUFDocumento : -1;
    const uf = ufs ? ufs.find(u => u.codigoUfIbge === codigoUf) : null;

    const siglaUf = uf ? uf.siglaUf : '';
    const numero = item.numeroDocumento;

    return siglaUf && numero ? numero.concat(` (${siglaUf})`) : numero;
  }