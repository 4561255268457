import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { format } from "date-fns";

import Grid from "@material-ui/core/Grid";

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import {
  exibirDialogRetornoSima, cancelarRetornoSima, alterarNomeProfissional,
  validarERegistrarRetornoSima, alterarCrmProfissional, alterarUf,
  alterarDataDocumento, alterarResultado,
  downloadSima
} from './action';
import {tiposSolicitacaoExigencia} from '../../constants';

import HeaderSecao from '../../../../components/HeaderSecao';

import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrButton from '@govbr/react-components/lib/BrButton';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import { MSG_CAMPOS_OBRIGATORIO } from '../../../../common/Messages'

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

class RetornoSIMA extends React.Component {

  QTD_MAXIMA_CARACTERES = 2000;
  OPCAO_VAZIA = { label: '', value: null };


  render() {
    const {ufs, cpfUsuarioLogado, validacao, config, isPendenteSima, isUsuarioResponsavel,
      cancelarRetornoSima, validarERegistrarRetornoSima, alterarNomeProfissional, alterarCrmProfissional,
      alterarUf, alterarDataDocumento, alterarResultado, isSimaSolicitado, downloadSima, pericia } = this.props;
    const { isFormRetornoVisible, dadosRetorno } = config;
    
    const idPericia = pericia.id;
    const dados = pericia.moduloSima //cenario SIMA solicitado para pericia atual
    || pericia.periciaOrigemRevisao.moduloSima; //cenario revisao, SIMA solicitado para pericia origem
    const { justifSolicitacao, tipoSolicitacaoExigencia } = dados;
    
    const dataSimaSolicitada = dados.dataSolicitacao ? Date.parse(dados.dataSolicitacao) : null;
    const tipoSolicitacao = dados.tipoSolicitacaoExigencia 
      ?  Object.values(tiposSolicitacaoExigencia).find(t => t.id === tipoSolicitacaoExigencia)?.descricao
      : tiposSolicitacaoExigencia.SIMA.descricao;
    const caracteresRestantes = calcularCaracteresRestantes(dadosRetorno.resultadoSima, this.QTD_MAXIMA_CARACTERES);

    /** UF */
    const ufsOrdemAlfabetica = ufs ? ufs.sort((a, b) => a.siglaUf.localeCompare(b.siglaUf)).map(t => ({ label: t.siglaUf, value: t.codigoUfIbge })) : [];
    ufsOrdemAlfabetica.unshift(this.OPCAO_VAZIA);

    const isPericiaConcluida = pericia.situacao && pericia.situacao.id === 4;
    const isCampoTextareaDesabilitado = !isPendenteSima || !isUsuarioResponsavel;

    return (
      <BrModal id="dialogSima" large style={{ width: "50%", maxHeight: '90%', overflowY: 'scroll' }}
        title={"Solicitação de Exigência"}
        visible={isFormRetornoVisible}
        closable={true}
        onClose={() => cancelarRetornoSima(isPendenteSima)}>

        <HeaderSecao texto="Dados da Solicitação" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className="texto-informativo" htmlFor="dataSolicitacao"> Data: </span>
            {
              dataSimaSolicitada
              && (
                <span id="dataSolicitacao">
                  {`${format(dataSimaSolicitada, "dd/MM/yyyy")} às ${format(dataSimaSolicitada, "HH:mm:ss")}`}
                </span>
              )
            }
          </Grid>
          <Grid item xs={12}>
            <span className="texto-informativo" htmlFor="tipoSolicitacao"> Tipo de Solicitação: </span>
            <span id="tipoSolicitacao"> {tipoSolicitacao}</span>
          </Grid>
          <Grid item xs={12}>
            <BrTextarea id="justificativa"
              label="Justificativa"
              value={justifSolicitacao}
              disabled />
          </Grid>
        </Grid>

        <div id="dadosRetornoSima">
          <HeaderSecao texto="Dados do Cumprimento da Exigência" />
          <Grid container spacing={2}>
            { dados.tipoSolicitacaoExigencia !== tiposSolicitacaoExigencia.OUTRAS.id &&
              <>
                <Grid item xs={12}>
                  <BrInput id="nomeProfissionalSima"
                    label="* Nome Profissional"
                    value={dadosRetorno.nomeProfissional}
                    maxLength={200}
                    onChange={alterarNomeProfissional}
                    erro={validacao ? validacao.erroNomeProfissional : null}
                    disabled={!isPendenteSima || !isUsuarioResponsavel} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <BrInput id="crmProfissionalSima" label="* CRM Profissional" mode="number"
                        value={dadosRetorno.crmProfissional}
                        onChange={alterarCrmProfissional}
                        maxLength={8}
                        erro={validacao ? validacao.erroCrmProfissional : null}
                        disabled={!isPendenteSima || !isUsuarioResponsavel} />
                    </Grid>
                    <Grid item xs={6}>
                      <BrSelect id="ufCrmSima" label="* UF"
                        items={ufsOrdemAlfabetica}
                        value={dadosRetorno.idUFCrmProfissional && ufs
                          ? ufsOrdemAlfabetica.find(u => u.value === dadosRetorno.idUFCrmProfissional)
                          : this.OPCAO_VAZIA}
                        onChange={alterarUf}
                        disabled={!isPendenteSima || !isUsuarioResponsavel}
                        erro={validacao && validacao.erroUf} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <BrDatepicker id="dataDocumentoSima" label="* Data do documento"
                    date={dadosRetorno.dataDocumento ? dadosRetorno.dataDocumento : null}
                    onChange={alterarDataDocumento}
                    disableFuture
                    allowInput
                    disabled={!isPendenteSima || !isUsuarioResponsavel}
                    erro={validacao && validacao.erroDataDocumento} />
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea id="resultadoSima" label="* Resultado"
                    onChange={alterarResultado}
                    value={dadosRetorno.resultadoSima}
                    maxLength={this.QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresRestantes}`}
                    erro={validacao ? validacao.erroResultado : null}
                    disabled={isCampoTextareaDesabilitado} />
                 </Grid>
                  {
                    !isCampoTextareaDesabilitado &&
                    <Grid item >
                      <ButtonSelecionarItem
                        id="selecionarItemDicionarioResultadoSIMA"
                        onSelect={alterarResultado}
                        maxLength={caracteresRestantes}
                        valorAtual={dadosRetorno.resultadoSima}/>
                    </Grid>
                  }
              </Grid>
            </Grid>
          </Grid>
          <p>{MSG_CAMPOS_OBRIGATORIO}</p>

        </div>


        <BrModalFooter>
          <BrButton secondary className="m-2" id="btnCancelarRetornoSima"
            onClick={() => cancelarRetornoSima(isPendenteSima)}>
            Cancelar
          </BrButton>
          {isSimaSolicitado &&
            !isPericiaConcluida &&
            (
              <BrButton primary className="m-2"
                id="btnImprimirSima"
                onClick={() => downloadSima(idPericia, dados.tipoSolicitacaoExigencia)}>
                Imprimir Solicitação
              </BrButton>
            )
          }
          {isPendenteSima &&
            (
              <BrButton primary className="m-2"
                id="btnRetornoSima"
                onClick={() => validarERegistrarRetornoSima(dadosRetorno, idPericia, cpfUsuarioLogado, dados.tipoSolicitacaoExigencia)}
                disabled={!isUsuarioResponsavel}>
                Concluir Exigência
              </BrButton>
            )
          }
        </BrModalFooter>

      </BrModal >
    );
  }
}

const mapStateToProps = (state) => {
  const { isPendenteSima, isSomenteLeitura, isSimaSolicitado, isUsuarioResponsavel,
    userData, validacao } = state.principal;

  return ({
    pericia: state.pericia,
    cpfUsuarioLogado: userData.document.number,
    validacao: validacao.retornoSima,
    config: state.modulos.moduloSima,
    isPendenteSima,
    isSomenteLeitura,
    isSimaSolicitado,
    isUsuarioResponsavel,
    ufs: state.principal.dominioSdc.ufs,
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({
  exibirDialogRetornoSima,
  cancelarRetornoSima,
  validarERegistrarRetornoSima,
  alterarNomeProfissional,
  alterarCrmProfissional,
  alterarUf,
  alterarDataDocumento,
  alterarResultado,
  downloadSima
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RetornoSIMA);