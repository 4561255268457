import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import HeaderSecao from '../../components/HeaderSecao';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import BrButton from '@govbr/react-components/lib/BrButton';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrInputInterno from '../../components/BrInputInterno';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import { CSVLink } from 'react-csv';

import { possuiPermissoes } from '../../common/AuthManager';
import { MASCARA_CPF } from '../../common/formatadores';
import { REENVCOM } from '../../common/permissoes';
import { tiposPericia } from '../pericia/constants';
import TabelaComunicacoes from './TabelaComunicacoes';

import { limparDadosPericia } from '../pericia/action';
import { limparMensagem, limparTemplate } from '../principal/action';
import {
  alterarCpf,
  alterarDataFim, alterarDataInicio,
  alterarErro,
  alterarProtocoloPmf, alterarStatus, alterarTipo,
  alterarTipoPericia,
  alterarTipoFiltroMensagemErro,
  alterarFiltroMaxTentativas,
  limpar, pesquisar, pesquisarTodosItens, reenviarComunicacao, reenviarEmLote
} from './action';

import './styles.css';

export class ComunicacaoExterna extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buscaComunicacaoRealizada: true,
      listaReenvioLote: []
    }
    this.csvLinkRef = createRef(); // Criando referencia para controlar o Componente via código.
  }

  componentDidMount() {
    //remover qualquer mensagem de erro/sucesso que possa existir
    this.props.limparMensagem();
    //remover dados pericia, retirando mensagens de alerta da tela
    this.props.limparDadosPericia();
    this.props.limparTemplate(); 
  }

  componentDidUpdate(prevProps){
    
    if(prevProps.dados.listaCsv !== this.props.dados.listaCsv && this.props.dados.listaCsv){
      if (this.csvLinkRef.current) {
        this.csvLinkRef.current.link.click(); // clicando no componente CSVLink de forma "manual" para gerar o arquivo sempre que a listaCsv for atualizada e tiver valor
      }
    }
  }

  NO_TENTATIVAS_REENVIO_FILA = 10;  
  

  OPCAO_VAZIA = { label: '', value: null };

  tiposStatusComunicacao = [{ id: 1, descricao: "Não enviada" },
  { id: 2, descricao: "Pendente" },
  { id: 3, descricao: "Sucesso" },
  { id: 4, descricao: "Falha" },
  { id: 5, descricao: "Reenviada" }];

  idsStatusPermiteReenvio = [5];

  tiposComunicacao = [{ id: 1, descricao: "PMF_TAREFAS - Notificar conclusão" },
  { id: 2, descricao: "PMF_TAREFAS - Notificar solicitação SIMA" },
  { id: 3, descricao: "PMF_TAREFAS - Notificar retorno SIMA" },
  { id: 4, descricao: "SIBE - Notificar conclusão" },
  { id: 5, descricao: "SIBE BI - Notificar conclusão" },
  { id: 6, descricao: "SABI ATESTMED - Notificar conclusão" },
  { id: 7, descricao: "SIBE ATESTMED - Notificar conclusão" },
  { id: 8, descricao: "PMF_TAREFAS - Informar NB" },
  { id: 9, descricao: "PEP SERVICE - Informar Auditoria" }];

  obterDescricaoTipo = id => {
    const tipo = this.tiposComunicacao.find(t => t.id === parseInt(id));

    return tipo ? tipo.descricao : id;
  }

  obterDescricaoStatus = id => {
    const status = this.tiposStatusComunicacao.find(t => t.id === parseInt(id));

    return status ? status.descricao : id;
  }

  isPossivelReenvio = comunicacao => !this.idsStatusPermiteReenvio.includes(parseInt(comunicacao.statusComunicacaoExternaPericia))
    && !comunicacao.idComunicacaoReenviada
    && !comunicacao.pendenteReenvioFila;

  buscar = ({ pageIndex, pageSize }) => {
    if (this.state.buscaComunicacaoRealizada) {
      this.setState({ buscaComunicacaoRealizada: 0 })
      return;
    }

    let filtro = this.props.dados.filtro;
    filtro.pagina = pageIndex + 1;
    filtro.quantidadeItensPorPagina = pageSize;
    this.props.pesquisar(filtro)

  }

  pesquisar = () => {
    this.setState({ buscaComunicacaoRealizada: 1 });
    let filtro = this.props.dados.filtro;
    filtro.pagina = 1;
    filtro.quantidadeItensPorPagina = 50;
    this.props.pesquisar(filtro);
  }

  gerarDadosCsv = async () =>{
    let filtroComTodosItens =  {...this.props.dados.filtroCsv}; // filtroCsv é uma cópia do filtro utilizado na ultima pesquisa.
    filtroComTodosItens.quantidadeItensPorPagina = this.props.dados.listaFiltrada.quantidadeItens;
    filtroComTodosItens.pagina = 1;
    this.props.pesquisarTodosItens(filtroComTodosItens);
  }

  atualizarSelecionados = selected => {
    this.setState({
      listaReenvioLote: selected.map(s => s.id)
    });
  }

  executarAcao = () => {
    this.props.reenviarEmLote(this.state.listaReenvioLote, this.props.dados.filtro);
  }

  reenviarComunicacao = (id) => {
    this.props.reenviarComunicacao(id, this.props.dados.filtro);
  }

  removerQuebraLinhas = (dado) =>{
    return (typeof dado !== 'string') ? String(dado).replace(/[\n\r;]+/g,' ') : dado.replace(/[\n\r;]+/g,' '); 
  }

  isObjectOrJSON = (campo) => {
    return campo !== null && typeof campo === 'object';
  };

  filterErroHandler = () =>{
    this.props.alterarTipoFiltroMensagemErro(!(this.props.dados.filtro.excluirResultadoPesquisaComMensagemErro));
  }

  checkBoxMaxTentativasHandler = () => {
    
    !this.props.dados.filtro.filtrarComunicacoesComMaxTentativas && this.props.alterarStatus(4);

    this.props.alterarFiltroMaxTentativas(!(this.props.dados.filtro.filtrarComunicacoesComMaxTentativas))
  }

  normalizarCampos = (itens, campo) => {
    const possiveisCampos = Array.from(itens.reduce((novoCampo,comunicacao) =>{
      if (comunicacao[campo]){
        Object.keys(comunicacao[campo]).forEach(chave => novoCampo.add(chave));
      }
      return novoCampo;
    }, new Set()));


    const itensComNovosCampos = itens.map(item => {
      const novoItem = {...item};
      const corpoRequisicao = item[campo] || {};
      possiveisCampos.forEach(campo =>{
        if (!novoItem.hasOwnProperty(campo)){
          if(this.isObjectOrJSON(corpoRequisicao[campo])){
            novoItem[campo] = corpoRequisicao[campo] !== undefined ? JSON.stringify(corpoRequisicao[campo]) : "NULL";
          } else{
            novoItem[campo] = corpoRequisicao[campo] !== undefined ? corpoRequisicao[campo] : "NULL";
          }
        } 
      });
      return novoItem;
    });


    return {itensComNovosCampos, possiveisCampos};
    
  }

  gerarHeaderPadrao = () =>{
    return [
      { label: 'Identificador da Comunicação', key: 'idComunicacao' },
      { label: 'SIAPE', key: 'siape' },
      { label: 'Protocolo PMF', key: 'protocoloPmf' },
      { label: 'Protocolo INSS', key: 'protocoloINSS' },
      { label: 'CPF', key: 'cpf' },
      { label: 'Data da Comunicação', key: 'dataComunicacao' },
      { label: 'Tipo Perícia', key: 'tipoPericia' },
      { label: 'Tipo da Comunicação', key: 'tipoComunicacaoExternaPericia' }, 
      { label: 'Tentativas', key: 'numeroTentativas' },
      { label: 'Status da Comunicação', key: 'statusComunicacaoExternaPericia' },
      { label: 'Data do Reenvio', key: 'dataReenvio'},
      { label: 'Inicio da Perícia', key: 'dataInicioPericia'},
      { label: 'Conclusão da Perícia (DRE)', key: 'dataAlteracao'},
      { label: 'Identificador do Reenvio', key: 'idComunicacaoReenviada' },
      { label: 'Retorno', key: 'erro' }
    ];
  }

  gerarHeaderCsv = (listaItens) =>{
    const headerPadrao = this.gerarHeaderPadrao();

    const listaFormatada = listaItens.map((item) => {
      const itemFormatado = {...item}
      itemFormatado.corpoRequisicaoJson = item.corpoRequisicao ? JSON.parse(itemFormatado.corpoRequisicao) : "";
      return itemFormatado; 
    })

    const {possiveisCampos} = this.normalizarCampos(listaFormatada, "corpoRequisicaoJson");
    const newHeader = possiveisCampos ? possiveisCampos.map((campo)=>{return {label: campo, key: campo};}) : "";

    const header = [...headerPadrao , ...newHeader];
    return header;
  }
 
  formatarDataCsv = (listaItens) => {    

    const listaFormatada = listaItens.map((item) => {
      const itemFormatado = {...item};
      //renomeando campo id para idComunicacao para evitar conflitos com campos do corpo de requisicao
      itemFormatado.idComunicacao = item.id;
      delete itemFormatado.id;
      itemFormatado.tipoComunicacaoExternaPericia = itemFormatado.tipoComunicacaoExternaPericia ? this.obterDescricaoTipo(itemFormatado.tipoComunicacaoExternaPericia) : "NULL";
      itemFormatado.statusComunicacaoExternaPericia = itemFormatado.statusComunicacaoExternaPericia ? this.obterDescricaoStatus(itemFormatado.statusComunicacaoExternaPericia) : "NULL";
      itemFormatado.tipoPericia = itemFormatado.tipoPericia ? Object.values(tiposPericia).find(t => t.id === item.tipoPericia)?.fase : "NULL";
      itemFormatado.erro = itemFormatado.erro ? this.removerQuebraLinhas(itemFormatado.erro) : "NULL";
      itemFormatado.protocoloINSS = itemFormatado.protocoloINSS ? itemFormatado.protocoloINSS : "NULL";
      itemFormatado.protocoloPmf = itemFormatado.protocoloPmf ? itemFormatado.protocoloPmf : "NULL" ;
      itemFormatado.dataAlteracao = itemFormatado.dataAlteracao ? itemFormatado.dataAlteracao : "NULL";
      itemFormatado.dataInicioPericia = itemFormatado.dataInicioPericia ? itemFormatado.dataInicioPericia : "NULL";
      itemFormatado.cpf = itemFormatado.cpf ? itemFormatado.cpf : "NULL";
      itemFormatado.dataComunicacao = itemFormatado.dataComunicacao ? itemFormatado.dataComunicacao : "NULL";
      itemFormatado.dataReenvio = itemFormatado.dataReenvio ? itemFormatado.dataReenvio : "NULL";
      itemFormatado.idComunicacaoReenviada = itemFormatado.idComunicacaoReenviada ? itemFormatado.idComunicacaoReenviada : "NULL";
      itemFormatado.siape = itemFormatado.siape ? itemFormatado.siape : "NULL";
      itemFormatado.corpoRequisicaoJson = item.corpoRequisicao ? JSON.parse(itemFormatado.corpoRequisicao) : "";
      
      return itemFormatado; 
    })
    const {itensComNovosCampos} = this.normalizarCampos(listaFormatada, "corpoRequisicaoJson");  
    
    return itensComNovosCampos;

  }

  render() {
    const { listaFiltrada, filtro, validacao, listaCsv } = this.props.dados;
    const { status, tipo, protocoloPmf, dataInicio, dataFim, tipoPericia, erro , cpf} = filtro;
    const { alterarStatus, alterarTipo, alterarProtocoloPmf, alterarDataInicio, alterarDataFim,
      limpar, alterarErro, alterarTipoPericia, alterarCpf } = this.props;

    const temPermissaoReenvio = possuiPermissoes(REENVCOM);

    const opcoesStatus = this.tiposStatusComunicacao ? this.tiposStatusComunicacao.map(t => ({ label: t.descricao, value: t.id })) : [];
    opcoesStatus.unshift(this.OPCAO_VAZIA);

    const opcoesTipo = this.tiposComunicacao ? this.tiposComunicacao.map(t => ({ label: t.descricao, value: t.id })) : [];
    opcoesTipo.unshift(this.OPCAO_VAZIA);

    const opcoesTipoPericia = tiposPericia ? Object.values(tiposPericia)?.filter(t => t.isAtiva)?.map(t => ({ label: `${t.tipo} - ${t.descricao}`, value: t.id })) : [];
    opcoesTipoPericia.unshift(this.OPCAO_VAZIA);

    return (
      <div id="telaComunicacoesExternas" className="col table_header_zIndex_0">
        <HeaderSecao texto="Filtrar Comunicações Externas" />

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BrDatepicker id="dataInicio" label="Data Início"
              value={dataInicio ? dataInicio : null}
              onChange={alterarDataInicio}
              erro={validacao && validacao.erroDataInicio}
              disableFuture
              allowInput />
          </Grid>
          <Grid item xs={3}>
            <BrDatepicker id="dataFim" label="Data Fim"
              value={dataFim ? dataFim : null}
              onChange={alterarDataFim}
              erro={validacao && validacao.erroDataFim}
              disableFuture
              allowInput />
          </Grid>

          <Grid item xs={2}>
            <BrSelect id="status" label="Status da Comunicação"
              items={opcoesStatus}
              value={status && opcoesStatus
                ? opcoesStatus.find(s => s.value === status)
                : this.OPCAO_VAZIA}
                onChange={alterarStatus}
                disabled={filtro.filtrarComunicacoesComMaxTentativas}
                />            
          </Grid>
          <Grid item xs={4}>
            <Box paddingTop={2.6}>
              <BrCheckbox id="filtroMaxTentativas" 
                items={[{label: "Exibir apenas as comunicações que falharam e esgotaram as tentativas de reenvio automático.", value: "checkFiltroMaxTentativas" }]}
                onChange={this.checkBoxMaxTentativasHandler}
                values={filtro.filtrarComunicacoesComMaxTentativas ? ["checkFiltroMaxTentativas"] : []}/>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={6}>
            <BrSelect id="tipo" label="Tipo de Comunicação"
              items={opcoesTipo}
              value={tipo && opcoesTipo
                ? opcoesTipo.find(s => s.value === tipo)
                : this.OPCAO_VAZIA}
              onChange={alterarTipo} />
          </Grid>
          <Grid item xs={6}>
            <BrSelect id="tipoPericia" label="Tipo de Pericia"
                items={opcoesTipoPericia}
                value={tipoPericia && opcoesTipoPericia
                  ? opcoesTipoPericia.find(s => s.value === tipoPericia)
                  : this.OPCAO_VAZIA}
                onChange={alterarTipoPericia} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={3}>
            <BrInput id="cpf" label="CPF"
                mode="number" mask={MASCARA_CPF}
                value={cpf} 
                onChange={alterarCpf} /> 
          </Grid>
          <Grid item xs={3}>
            <BrInput id="protocoloPmf" label="Protocolo PMF" mode="number"
                value={protocoloPmf}
                onChange={alterarProtocoloPmf} />
          </Grid>
          <Grid item xs={6}>
            <BrInputInterno id="erro" label="Mensagem de Erro"
              value={erro}
              onChange={alterarErro}
              icon = {filtro.excluirResultadoPesquisaComMensagemErro ? "fas fa-minus-circle" : "fas fa-plus-circle"}
              iconHandler={this.filterErroHandler}
              iconTitle={"Incluir/Excluir da consulta"}
              help={filtro.excluirResultadoPesquisaComMensagemErro ? "(!) Comunicações com a mensagem de erro informada <strong>não</strong> serão exibidas." : ""}
              />
              
          </Grid>
        </Grid>

        <Box display="flex" p={2} position="sticky" top={0} bgcolor="#fff" mt={1} justifyContent="flex-end">

          

        {listaFiltrada && listaFiltrada.quantidadeItens > 0 &&   
          (
            <BrButton id="btnCsv" secondary onClick={this.gerarDadosCsv}>
              <i className={"fas fa-file-csv"} />
                Exportar CSV 
            </BrButton>
          )}

          {/*
          O componente CSVLink não pode aparecer na tela pois o seu click é realizado via código e não pela interação com o usuário!
          */}
          <CSVLink
            data={listaCsv && listaCsv.quantidadeItens > 0 ? this.formatarDataCsv(listaCsv.itens) : ''}
            ref={this.csvLinkRef} headers={listaCsv && listaCsv.quantidadeItens > 0 ? this.gerarHeaderCsv(listaCsv.itens) : this.gerarHeaderPadrao()}
            filename={'comunicacoes.csv'}
            separator={";"} >
            </CSVLink> 
       
          <BrButton id="btnLimpar" secondary className="mr-2 ml-2"
            onClick={limpar}>
            <i className="fas fa-eraser"></i>
            Limpar Campos
          </BrButton>
          <BrButton id="btnPesquisar" primary
            onClick={this.pesquisar}>
            <i className={"fas fa-search"} />
            Pesquisar
          </BrButton>          
        </Box>

        {listaFiltrada && listaFiltrada.quantidadeItens > 0
          && (<TabelaComunicacoes listaFiltrada={listaFiltrada}
            executarAcao={this.executarAcao}
            updateListaSelecionados={this.atualizarSelecionados}
            temPermissaoReenvio={temPermissaoReenvio}
            reenviarComunicacao={this.reenviarComunicacao}
            buscar={this.buscar}
            obterDescricaoTipo={this.obterDescricaoTipo}
            obterDescricaoStatus={this.obterDescricaoStatus}
            isPossivelReenvio={this.isPossivelReenvio}
          />)
        }

        {
          listaFiltrada && listaFiltrada.quantidadeItens === 0
          && (
            <center className="texto-informativo red">
              Nenhuma comunicação externa encontrada.
            </center>
          )
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dados: state.comunicacoesExternas
})

const mapDispatchToProps = {
  alterarProtocoloPmf,
  alterarStatus,
  alterarTipo,
  alterarDataInicio,
  alterarDataFim,
  alterarErro,
  alterarTipoPericia,
  alterarCpf,
  pesquisar,
  pesquisarTodosItens,
  limpar,
  reenviarComunicacao,
  reenviarEmLote,
  limparMensagem,
  limparDadosPericia,
  limparTemplate,
  alterarTipoFiltroMensagemErro,
  alterarFiltroMaxTentativas
}

export default connect(mapStateToProps, mapDispatchToProps)(ComunicacaoExterna)
