import { Grid } from "@material-ui/core";
import React, { useLayoutEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import HeaderSecao from '../../../../components/HeaderSecao';

import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrSelect from "@govbr/react-components/lib/BrSelect";

import { convertStringToDate } from '../../../../common/DataUtils';
import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { tiposCampoGenerico, tipoSimNao } from '../../constants';

import { modulosEstaticos } from '../../../pericia/constants';
import { alterarValorCampoGenerico, alterarValorCampoGenericoData } from '../../action';
import { verificarRegrasInativacao } from './GenericoUtil';
import {isValorRegraExibicaoIgualValorCampo, obterValorCampoEstaticoRegraExibicao} from '../../../../common/CampoGenericoUtils'

import DialogConfirmarAlteracaoDIDDII from './DialogConfirmarAlteracaoDIDDII';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

function Generico(props) {

  const OPCAO_VAZIA = { label: '', value: null };

  const { nomeModulo, moduloTemplate, isSomenteLeitura, pericia, alterarValorCampoGenerico, possuiDIDRestabelecimentoAgravamento } = props;

  useLayoutEffect(() => {
    const modulo = moduloTemplate.modulo;
    const valoresCamposGenericos = pericia.valoresCamposGenericos;

    modulo.camposGenericos.forEach(c => {
      const existeValorParaCampo = valoresCamposGenericos?.find(v => v.idCampoGenerico === c.campoGenerico.id);

      if (!existeValorParaCampo && c.campoGenerico.valorPadrao && c.campoGenerico.visivel?.id === 1) {
        //setar valor padrão para campo generico do modulo
        alterarValorCampoGenerico(c.campoGenerico.id, c.campoGenerico.sigla, c.campoGenerico.valorPadrao, valoresCamposGenericos, pericia);
      }
    });

    modulo.secoes.forEach(s => {
      if (s.secoesModulosCamposGenericos && s.secoesModulosCamposGenericos.length > 0) {
        s.secoesModulosCamposGenericos.forEach(c => {
          const existeValorParaCampo = valoresCamposGenericos?.find(v => v.idCampoGenerico === c.campoGenerico.id);

          if (!existeValorParaCampo && c.campoGenerico.valorPadrao && c.campoGenerico.visivel?.id === 1) {
            //setar valor padrão para campo generico da secao
            alterarValorCampoGenerico(c.campoGenerico.id, c.campoGenerico.sigla, c.campoGenerico.valorPadrao, valoresCamposGenericos, pericia);
          }
        });
      }
    });
  }, [moduloTemplate.modulo, pericia.valoresCamposGenericos, alterarValorCampoGenerico, pericia]);

  const modulosTemplatesFilhos = props.moduloTemplate.modulosTemplateFilhos;

  const validarRegrasDependenciaExibicao = (campoGenericoOuSecao, valoresCamposGenericos) => {
    const regrasAtendidas = campoGenericoOuSecao.regrasDependenciaExibicao?.every(regra => {
      const campoRegra = regra.campoGenericoModuloOrigem ? regra.campoGenericoModuloOrigem : regra.secaoModuloCampoGenericoOrigem;
      if (campoRegra) {
        const valorCampo = campoRegra && valoresCamposGenericos.find(v => v.idCampoGenerico === campoRegra.campoGenerico.id);
        return isValorRegraExibicaoIgualValorCampo(regra.valor, valorCampo?.valorTextoCampoGenerico);
      } else if (regra.tipoCampoExibicaoPericia) {
        return isValorRegraExibicaoIgualValorCampo(regra.valor, obterValorCampoEstaticoRegraExibicao(regra.tipoCampoExibicaoPericia, pericia));
      }
      return true;
    });
    return !campoGenericoOuSecao.regrasDependenciaExibicao || regrasAtendidas;
  }

  const renderCampoGenerico = (campo, pericia, validacao, isSomenteLeitura, alterarValorCampoGenerico, alterarValorCampoGenericoData, tiposSimNao) => {
    const idCampoGenerico = campo.id;
    const nomeCampo = `campo_generico_${idCampoGenerico}`;
    const labelCampo = campo.nome;
    const siglaCampo = campo.sigla;
    const tipoCampo = campo.tipo;
    const qtMaximaCaracteres = campo.qtMaximaCaracteres;
    const qtMinimaCaracteres =campo.qtMinimaCaracteres;
    const isCampoObrigatorio = campo.campoObrigatorio === tipoSimNao.SIM.descricao;
    //apenas para campo generico tipo combo
    const valoresDominio = campo.valoresDominio 
      ? campo.valoresDominio.map(t => ({ label: t.descricao, value: t.valor })).sort((a, b) => a.value.localeCompare(b.value)) 
      : [];
    valoresDominio.unshift(OPCAO_VAZIA);

    const valoresCamposGenericos = pericia.valoresCamposGenericos;
    const valorCampo = valoresCamposGenericos
        ? valoresCamposGenericos.find(v => v.idCampoGenerico === idCampoGenerico)?.valorTextoCampoGenerico
        : undefined;

    const caracteresRestantes = qtMaximaCaracteres ? calcularCaracteresRestantes(valorCampo, qtMaximaCaracteres) : undefined;
    const checaAlteracaoDIDOuDIINexo = true;
    const isCampoDesabilitado = isSomenteLeitura || verificarRegrasInativacao(pericia, siglaCampo, possuiDIDRestabelecimentoAgravamento);

    switch (tipoCampo) {
      case tiposCampoGenerico.TEXTO:
          return ( 
               <Grid container alignItems="center" key={nomeCampo}>
                 <Grid item xs>
                  <BrTextarea
                    id={nomeCampo}
                    label={labelCampo && `${isCampoObrigatorio ? '* ' : ''}${labelCampo}`}
                    value={valorCampo}
                    onChange={(value) => alterarValorCampoGenerico(idCampoGenerico, siglaCampo, value, valoresCamposGenericos, pericia)}
                    maxLength={qtMaximaCaracteres}
                    minLength={qtMinimaCaracteres}
                    help={caracteresRestantes !== undefined && `Caracteres restantes: ${caracteresRestantes}`}
                    disabled={isCampoDesabilitado}
                    erro={validacao ? validacao[`erro_${idCampoGenerico}`] : null}
                  />
                 </Grid>
                 {
                  !isCampoDesabilitado && 
                 <Grid item>
                    <ButtonSelecionarItem
                          id={`selecionarItemDicionario_${nomeCampo}`}
                          onSelect={alterarValorCampoGenerico}
                          onSelectProps={{id: idCampoGenerico, sigla: siglaCampo }}
                          maxLength={caracteresRestantes}
                          valorAtual={valorCampo}/>
                 </Grid>
                 }
               </Grid>)
      case tiposCampoGenerico.DATA:
          return <BrDatepicker
            id={nomeCampo}
            key={nomeCampo}
            label={labelCampo && `${isCampoObrigatorio ? '* ' : ''}${labelCampo}`}
            date={valorCampo ? convertStringToDate(valorCampo) : null}
            onChange={(value) => alterarValorCampoGenericoData(idCampoGenerico, siglaCampo, value, campo, nomeModulo, checaAlteracaoDIDOuDIINexo, pericia)}
            allowInput
            erro={validacao ? validacao[`erro_${idCampoGenerico}`] : null}
            disabled={isCampoDesabilitado}
          />
      case tiposCampoGenerico.BOOLEAN_SIM_NAO:
          return <BrRadio
            id={nomeCampo}
            key={nomeCampo}
            label={labelCampo && `${isCampoObrigatorio ? '* ' : ''}${labelCampo}`}
            value={valorCampo}
            items={tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
            onChange={(value) => alterarValorCampoGenerico(idCampoGenerico, siglaCampo, value, valoresCamposGenericos, pericia)}
            erro={validacao ? validacao[`erro_${idCampoGenerico}`] : null}
            disabled={isCampoDesabilitado}
          />
      case tiposCampoGenerico.NUMERICO:
        return <BrInput
          id={nomeCampo}
          key={nomeCampo}
          mode="number"
          allowNegative={false}
          label={labelCampo && `${isCampoObrigatorio ? '* ' : ''}${labelCampo}`}
          value={parseInt(valorCampo)}
          onChange={(value) => alterarValorCampoGenerico(idCampoGenerico, siglaCampo, value, valoresCamposGenericos, pericia)}
          maxLength={qtMaximaCaracteres}
          minLength={qtMinimaCaracteres}
          help={caracteresRestantes !== undefined && `Caracteres restantes: ${caracteresRestantes}`}
          disabled={isCampoDesabilitado}
          erro={validacao ? validacao[`erro_${idCampoGenerico}`] : null}
        />  
        case tiposCampoGenerico.COMBO:
          return <BrSelect
            id={nomeCampo}
            key={nomeCampo}
            label={labelCampo && `${isCampoObrigatorio ? '* ' : ''}${labelCampo}`}
            items={valoresDominio}
            value={valorCampo && valoresDominio
              ? valoresDominio.find(s => s.value === valorCampo)
              : OPCAO_VAZIA}
            onChange={(value) => alterarValorCampoGenerico(idCampoGenerico, siglaCampo, value, valoresCamposGenericos, pericia)}
            disabled={isCampoDesabilitado}
            erro={validacao ? validacao[`erro_${idCampoGenerico}`] : null}
          />  
      default:
        return null;
    }
  }

  const obterConteudoModuloGenerico = (modulo, nomeModulo, isSomenteLeitura) => {
    const { pericia, alterarValorCampoGenerico, alterarValorCampoGenericoData, tiposSimNao } = props;
    const validacao = props.validacao ? props.validacao[nomeModulo] : undefined;

    // conteudo campos genericos
    const conteudoCamposGenericos = modulo.camposGenericos.map(c => {

      if (!validarRegrasDependenciaExibicao(c, pericia.valoresCamposGenericos)) {
        return null;
      }

      return renderCampoGenerico(c.campoGenerico, pericia, validacao, isSomenteLeitura, alterarValorCampoGenerico, alterarValorCampoGenericoData, tiposSimNao);

    }).filter(campo => campo !== null);

    // conteudo secoes modulo
    const conteudoSecoesModulo = modulo.secoes.map((s, index) => {
      let conteudoSecao = undefined;

      if (s.moduloEstaticoFilho) {
        const moduloEstaticoSecao = Object.values(modulosEstaticos).find(e => e.codigo === s.moduloEstaticoFilho.tipoModuloEstatico);
        if (moduloEstaticoSecao) {
          conteudoSecao = [<Grid item sm={12}  key={moduloEstaticoSecao + `-` + index}>{moduloEstaticoSecao.implementacao}</Grid>]
        }
      } else if (s.secoesModulosCamposGenericos && s.secoesModulosCamposGenericos.length > 0) {
        conteudoSecao = s.secoesModulosCamposGenericos.map(m => {
          const idCampoGenerico = m.campoGenerico.id;
          const nomeCampo = `campo_generico_${idCampoGenerico}`;
          const tamanhoColuna = m.tamanhoColunaCampo ? m.tamanhoColunaCampo : 12;
          const alerta = validacao ? validacao[`alerta_${idCampoGenerico}`] : null;

          return <Grid item key={nomeCampo} sm={tamanhoColuna}>
            {alerta
              && (
                <BrMessage warning>
                  {alerta}
                </BrMessage>
              )}
            { renderCampoGenerico(m.campoGenerico, pericia, validacao, isSomenteLeitura, alterarValorCampoGenerico, alterarValorCampoGenericoData, tiposSimNao) }
          </Grid>
        });
      }

      return (
        validarRegrasDependenciaExibicao(s, pericia.valoresCamposGenericos)
        &&
        <Grid key={'secaoID' + s.id} container >
          {s.nome &&
            (<Grid item sm={12}>
              <HeaderSecao texto={s.nome} />
            </Grid>)
          }
          <Grid item sm={12}>
            <Grid container spacing={2}>{conteudoSecao}</Grid>
          </Grid>
        </Grid>);
    }).filter(secao => secao !== false);

    return (
      <>
        {
          (!modulosTemplatesFilhos || modulosTemplatesFilhos.length === 0)
          && (!conteudoCamposGenericos || conteudoCamposGenericos.length === 0)
          && (!conteudoSecoesModulo || conteudoSecoesModulo.length === 0)
          && <BrMessage warning>
            As informações anteriores ao Módulo {props.moduloTemplate.tituloModulo ? props.moduloTemplate.tituloModulo : props.moduloTemplate.modulo.nome} devem
            ter sido informadas antes do preenchimento desta tela.
          </BrMessage>
        }
        {
          conteudoCamposGenericos
        }
        {
          conteudoSecoesModulo
        }
      </>
    );
  }

  const moduloTemplateTemFilhos = moduloTemplate
    && moduloTemplate.modulosTemplateFilhos
    && moduloTemplate.modulosTemplateFilhos.length > 0;

  return (
    <div id={nomeModulo} className={!moduloTemplateTemFilhos ? 'conteudoAbaSemHeaderSecao' : undefined}>
      {
        moduloTemplate && (
          <>
            {moduloTemplateTemFilhos &&
              moduloTemplate.modulosTemplateFilhos.map((moduloFilho, index) => {
                const moduloEstatico = Object.values(modulosEstaticos).find(e => e.codigo === moduloFilho.modulo.tipoModuloEstatico);
                if (moduloEstatico) {
                  return <div key={moduloEstatico + `-` + index}>{moduloEstatico.implementacao}</div>
                }
                return <></>;
              })
            }
            {
              obterConteudoModuloGenerico(moduloTemplate.modulo, nomeModulo, isSomenteLeitura)
            }
            <DialogConfirmarAlteracaoDIDDII />
          </>
        )
      }
    </div >
  )

}

const mapStateToProps = (state) => ({
  validacao: state.principal.validacao,
  tiposSimNao: state.principal.dominioPericia.tiposSimNao,
  isSomenteLeitura: state.principal.isSomenteLeitura,
  pericia: state.pericia,
  possuiDIDRestabelecimentoAgravamento: state.modulos.moduloRestabelecimentoBeneficio.possuiDIDRestabelecimentoAgravamento
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarValorCampoGenerico,
  alterarValorCampoGenericoData
}, dispatch);

  export default connect(mapStateToProps, mapDispatchToProps)(Generico);