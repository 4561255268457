import React from 'react';
import ModuloFormularioSimplificado from '../../../../components/ModuloFormularioSimplificado';

const FormularioGenerico = (props) =>
  <ModuloFormularioSimplificado
    nomeModulo={props.nomeModulo}
    formulario={props.moduloTemplate.modulo.formulario} />

export default FormularioGenerico;


