import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";


import BrTable from '@govbr/react-components/lib/BrTable';
import BrButton from '@govbr/react-components/lib/BrButton';
import { format } from "date-fns";
import DialogDetalhesComunicacao from './DialogDetalhesComunicacao';
import {exibirDialogDetalheComunicacao} from './action';
import { tiposPericia } from '../pericia/constants';

const TabelaComunicacoes = React.memo(function TabelaComunicacoes(props) {
  const salvarCpfLocalmente = (cpfTitular) =>{
    localStorage.setItem('cpfTitular', cpfTitular);
  }
  const salvarProtocoloPmfLocalmente = (protocoloPmf) =>{
    localStorage.setItem('protocoloPmf', protocoloPmf);
  }

  return (
    <>
      <BrTable
        id="tabelaComunicacoesExternas"
        title="Comunicações Externas"
        layout="fixed"
        pagination={true}
        paginationProp={{
          remote: true,
          pageIndex: props.listaFiltrada.paginaAtual - 1,
          pageSize: props.listaFiltrada.quantidadeItensPorPagina,
          pageCount: props.listaFiltrada.quantidadePaginas,
          totalItems: props.listaFiltrada.quantidadeItens,
          pageSizeOptions: [50, 100, 500, 1000],
          fetchData: props.buscar
        }}
        columns={[{ Header: "Id", accessor: "botaoId" }, { Header: "Data Comunicação", accessor: "dataComunicacao" },
        { Header: "Protocolo PMF", accessor: "protocoloPmf" }, { Header: "CPF", accessor: "cpf" },
        { Header: "Tipo Pericia", accessor: "tipoPericia" }, { Header: "Tipo Comunicação", accessor: "tipoComunicacaoExterna" },
        { Header: "Status", accessor: "status" }, { Header: "Tentativas", accessor: "numeroTentativas" },
        { Header: "Erro", accessor: "erro" }, { Header: "Id Reenvio", accessor: "idReenvio" }, { Header: "Ação", accessor: "acao" }]}
        data={props.listaFiltrada.itens.map(item => ({
          id: item.id,
          botaoId: 
            <BrButton id="infoComunicaco"
              onClick={() => props.exibirDialogDetalheComunicacao(item)}>
                {item.id}
            </BrButton>,
          dataComunicacao: `${format(Date.parse(item.dataComunicacao), "dd/MM/yyyy HH:mm:ss")}`,
          protocoloPmf: 
            <BrButton id="consultarProtocoloPmfNoPainelGerencial" title="Consultar no painel gerencial" onClick={() => salvarProtocoloPmfLocalmente(item.protocoloPmf)}>
               <Link
                id="consultarPericiaNoPainelGerencial"
                to={`/consulta/pericia`}
                target="_blank">{item.protocoloPmf}
              </Link>
              </BrButton>,
          cpf:
            <BrButton id="consultarCpfNoPainelGerencial" title="Consultar no painel gerencial" onClick={() => salvarCpfLocalmente(item.cpf)}>
              <Link
                id="consultarPericiaNoPainelGerencial"
                to={`/consulta/pericia`}
                target="_blank">{item.cpf}
              </Link>
            </BrButton>,
          tipoPericia: item.tipoPericia ? Object.values(tiposPericia).find(t => t.id === item.tipoPericia)?.fase : "--",
          tipoComunicacaoExterna: props.obterDescricaoTipo(item.tipoComunicacaoExternaPericia),
          status: props.obterDescricaoStatus(item.statusComunicacaoExternaPericia),
          numeroTentativas: item.numeroTentativas,
          erro: item.erro, //existe customizacao de layout para essa coluna em /css/styles.css
          idReenvio: item.idComunicacaoReenviada,
          acao:
            props.temPermissaoReenvio && props.isPossivelReenvio(item)
            &&
            <BrButton id="reenviarComunicacao" circle
              onClick={() => props.reenviarComunicacao(item.id)}>
              <i className={`fas fa-redo`} title='Reenviar' />
            </BrButton>
        }))}
        checkbox={true}
        headerProps={{
          show: true,
          showDensityOptions: false,
          searchProp: {
            show: false
          },
          actionButtons: (
            <BrTable.ActionButton
              id="btnReenviarEmLote"
              label="Reenvio em lote"
              className="inverted"
              iconClass="fas fa-redo"
              onClick={() => props.executarAcao()}
              disabled={!props.temPermissaoReenvio}
            />
          )
        }}
        footerProps={{ show: false }}
        onSelectData={selected => props.updateListaSelecionados(selected)}

      />
      <DialogDetalhesComunicacao />
    </>
    );
}
);

const mapDispatchToProps = dispatch => bindActionCreators({
  exibirDialogDetalheComunicacao  
}, dispatch);

export default connect(null, mapDispatchToProps)(TabelaComunicacoes);