import tratarInputEmCampoGenerico from "../pericia/modulos/Generico/tratarInputEmCampoGenerico";
import {possuiPermissoes} from "../../common/AuthManager";
import {COEXPER} from "../../common/permissoes";

const INITIAL_STATE = {
  validacao: {},
  dominioPericia: {},
  dominioSdc: {},
  loader: false,
  userData: null,
  sizePerPage: 10,
  template: {},
  abas: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ATUALIZAR_TEMPLATE_PERICIA': {
      const dados = action.payload;
      return {
        ...state,
        template: { ...dados.template },
        existeModuloNexoNoTemplate: dados.existeModuloNexoNoTemplate,
        existeModuloRestabelecimentoNoTemplate: dados.existeModuloRestabelecimentoNoTemplate,
        existeModuloIsencaoNoTemplate: dados.existeModuloIsencaoNoTemplate,
        isPericiaBPC: dados.isPericiaBPC,
        isPericiaPermiteSima: dados.isPericiaPermiteSima
      };
    }
    case 'ATUALIZAR_TIPO_ACESSO': {
      const dados = action.payload;
      const possuiPermissaoAcessoDiretoPericia = possuiPermissoes(COEXPER);
      const isSomenteLeitura = dados.isSomenteLeitura || (!dados.isPossuiPermissaoRealizarPericia ||
        (dados.acessoPorToken && !dados.acessoUsuarioResponsavel) ||
        dados.situacao > 1);
      const isPendenteSima = dados.situacao === 3;
      const isSimaSolicitado = dados.simaSolicitado === 1;

      return {
        ...state, isSomenteLeitura, isPendenteSima, isSimaSolicitado,
        isUsuarioResponsavel: (!dados.acessoPorToken && dados.isPossuiPermissaoRealizarPericia && possuiPermissaoAcessoDiretoPericia)|| dados.acessoUsuarioResponsavel
      };
    }
    case 'ATUALIZAR_DADO_VALIDACAO_MODULO': {
      const { modulo, novaValidacao } = action.payload;
      const dadosValidacaoModulo = state.validacao[modulo];
      return {
        ...state, validacao: { ...state.validacao, [modulo]: { ...dadosValidacaoModulo, ...novaValidacao } }
      };
    }
    case 'ATUALIZAR_DADOS_VALIDACAO':
      return {
        ...state, validacao: { ...state.validacao, ...action.payload }
      };
    case 'ATUALIZAR_PERICIA_VALIDA':
      return {
        ...state, validacao: { ...state.validacao, isPericiaValida: action.payload }
      };
    case 'ATUALIZAR_VALIDACAO_COMPLETA':
      return {
        ...state, validacao: action.payload
      };
    case 'ATUALIZAR_CAMPOS_DOMINIO_PERICIA':
      return { ...state, dominioPericia: action.payload };
    case 'ATUALIZAR_CAMPOS_DOMINIO_PERICIA_BI':
      return { ...state, dominioPericiaBI: action.payload };
    case 'ATUALIZAR_CAMPOS_DOMINIO_SDC':
      return { ...state, dominioSdc: action.payload };
    case 'ATUALIZAR_LISTA_ABAS':
      return { ...state, abas: action.payload };
    case 'INPUT_CAMPO_GENERICO':
      {
        const { idCampoGenerico, valor, valoresCamposGenericos, pericia } = action.payload;
        const modulosTemplatePericiaTratados = tratarInputEmCampoGenerico(idCampoGenerico, valor, 
          valoresCamposGenericos, state.template.modulosTemplatePericia, pericia);
        return {
          ...state,
          template: {
            ...state.template,
            modulosTemplatePericia: [
              ...modulosTemplatePericiaTratados
            ]
          }
        };
      }
    case 'SELECIONAR_ABA':
      return { ...state, idAbaSelecionada: action.payload, mensagem: "" };
    case 'PRINCIPAL_LOADING':
      return { ...state, mensagem: null, loader: action.payload };
    case 'PRINCIPAL_ERROR_MESSAGE':
      return { ...state, mensagem: action.payload, tipoMensagem: { "error": true }, loader: false };
    case 'PRINCIPAL_CONSULTA_MESSAGE':
      return { ...state, mensagemConsulta: action.payload, tipoMensagem: { "success": true }, loader: false };
    case 'PRINCIPAL_SUCCESS_MESSAGE':
      return { ...state, mensagem: action.payload, tipoMensagem: { "success": true }, loader: false };
    case 'PRINCIPAL_CLEAR_MESSAGE':
      return { ...state, mensagem: null, mensagemConsulta:null };
    case 'OBTER_INFO_API_SUCESSO':
      return { ...state, infoAPI: action.payload };
    case 'OBTER_INFO_API_MANTER_SUCESSO':
      return { ...state, infoAPIManterPericia: action.payload };
    case 'LIMPAR_TEMPLATE':
      return { ...state, template: {} };
    default:
      return state;
  }
};
