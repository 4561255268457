
import React from 'react';



import CIDs from './modulos/CIDs';
import CIDPrincipal from './modulos/CidPrincipal';
import DadosCadastrais from "./modulos/DadosCadastrais";
import DiagnosticoPericiaAnterior from './modulos/DiagnosticoPericiaAnterior';
import Documentos from './modulos/Documentos';
import ExameFisico from './modulos/ExameFisico';
import ExamesELaudos from './modulos/ExamesELaudos';
import ExamesELaudosAnteriores from './modulos/ExamesELaudos/ExamesELaudosAnteriores';

import DocumMedOdont from './modulos/DocumMedOdont';
import DocumMedOdontAnteriores from './modulos/DocumMedOdont/DocumMedOdontAnteriores';

import GrauIncapacidade from './modulos/GrauIncapacidade';
import AuxilioAcidente from './modulos/GrauIncapacidade/AuxilioAcidente';
import HistoriaClinica from './modulos/HistoriaClinica';
import ManutencaoDataCessacao from './modulos/ManutencaoDataCessacao';

import EstruturaEPrognostico from './modulos/EstruturaEPrognostico';
import {
  DOMINIO_DURACAO_ALTERACAO_V1, DOMINIO_DURACAO_ALTERACAO_V2, PERGUNTA_DURACAO_ALTERACOES_V1, PERGUNTA_DURACAO_ALTERACOES_V2
} from './modulos/EstruturaEPrognostico/constants';

import AtividadesEParticipacao from './modulos/AtividadesEParticipacao';
import * as ValidacaoAtividadesEParticipacao from './modulos/AtividadesEParticipacao/validacao';
import AvaliacaoFisica from './modulos/AvaliacaoFisica';
import * as ValidacaoAvaliacaoFisica from './modulos/AvaliacaoFisica/validacao';
import * as ValidacaoCids from './modulos/CIDs/validacao';
import * as ValidacaoCidPrincipal from './modulos/CidPrincipal/validacao';
import ConclusaoAtestmed from './modulos/ConclusaoAtestmed';
import ConclusaoBIDocmed from './modulos/ConclusaoBIDocmed';
import ConclusaoPericiaBI from './modulos/ConclusaoPericiaBI';
import ConclusaoPericiaRevisaoBI from './modulos/ConclusaoPericiaRevisaoBI';
import ConclusaoPericiaRBI from './modulos/ConclusaoPericiaRBI';
import * as ValidacaoDiagnosticoAnteriorCids from './modulos/DiagnosticoPericiaAnterior/validacao';
import * as ValidacaoDocumMedOdont from './modulos/DocumMedOdont/validacao';
import * as ValidacaoDocumentos from './modulos/Documentos/validacao';
import * as ValidacaoEstruturaEPrognostico from './modulos/EstruturaEPrognostico/validacao';
import * as ValidacaoEstruturaEPrognostico_v2 from './modulos/EstruturaEPrognostico/validacao_v2';
import * as ValidacaoExameFisico from './modulos/ExameFisico/validacao';
import * as ValidacaoExamesLaudos from './modulos/ExamesELaudos/validacao';
import FuncoesDoCorpo from './modulos/FuncoesDoCorpo';
import * as ValidacaoFuncoesDoCorpo from './modulos/FuncoesDoCorpo/validacao';
import * as ValidacaoAuxilioAcidente from './modulos/GrauIncapacidade/AuxilioAcidente/validacao';
import * as ValidacaoGrauIncapacidade from './modulos/GrauIncapacidade/validacao';
import * as ValidacaoGrauIncapacidadeRBI from './modulos/GrauIncapacidadeRBI/validacao';
import * as ValidacaoHistoriaClinica from './modulos/HistoriaClinica/validacao';
import ImpedimentosERiscoSocial from './modulos/ImpedimentosERiscoSocial';
import * as ValidacaoImpedimentosERiscoSocial from './modulos/ImpedimentosERiscoSocial/validacao';
import IsencaoCarencia from './modulos/IsencaoCarencia';
import * as ValidacaoIsencaoCarencia from './modulos/IsencaoCarencia/validacao';
import MensagemAcidenteTrabalho from './modulos/MensagemAcidenteTrabalho';
import NexoTecnico from './modulos/NexoTecnico';
import * as ValidacaoNexoTecnico from './modulos/NexoTecnico/validacao';
import Prontuario from './modulos/Prontuario';
import RelacoesPrevidenciarias from './modulos/RelacoesPrevidenciarias';
import RestabelecimentoBeneficio from './modulos/RestabelecimentoBeneficio';
import * as ValidacaoRestabelecimento from './modulos/RestabelecimentoBeneficio/validacao';
import ResultadoParcialBPC from './modulos/ResultadoParcialBPC';
import ResultadoPericiaB88 from './modulos/ResultadoPericiaB88';
import * as ValidacaoResultadoPericiaB88 from './modulos/ResultadoPericiaB88/validacao';
import VinculosIncapacidade from './modulos/VinculosIncapacidade';
import * as ValidacaoVinculosIncapacidade from './modulos/VinculosIncapacidade/validacao';
import MensagemDataCessacaoRBI from './modulos/MensagemDataCessacaoRBI';
import GrauIncapacidadeRBI from './modulos/GrauIncapacidadeRBI';
import MensagemTelemedicina from './modulos/MensagemTelemedicina';
import MensagemTelemedicinaExameFisico from './modulos/MensagemTelemedicinaExameFisico';

/*
---------
ATRIBUTOS DO MODULO ESTATICO
---------
.codigo: deve ser igual a "tipoModuloEstatico" no template
.nome: deve ser único. Usado em:
    - url de redirecionamento quando modulo estático é o único na aba
    - chave para encontrar validações (state: principal.validacao[nome])
.implementação: layout de tela
.deveValidar: existem campos a ser validados no modulo. indica que o botão "Validar e Prosseguir" deve ser exibido para a aba que o contém.
.exibeMenuOpcoes: existem campos a ser salvos no modulo. indica que o menu superior, com botao salvar e SIMA (dependendo do template, se permitir) 
  deve ser exibido  para a aba que o contém.
*/


const modulosEstaticos = Object.freeze({
  DADOS_CADASTRAIS: {
    codigo: 1, nome: "dadosCadastrais", implementacao: <DadosCadastrais />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  DOCUMENTOS: { // possui campos obrigatórios, porém foi necessário implementar texto '(*) campos obrigatórios' dentro do componente
    codigo: 2, nome: "documentos", implementacao: <Documentos />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoDocumentos
  },
  HISTORIA_CLINICA: {
    codigo: 11, nome: "historiaClinica", implementacao: <HistoriaClinica />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoHistoriaClinica
  },
  EXAMES_LAUDOS: {
    codigo: 3, nome: "examesLaudos", implementacao: <ExamesELaudos />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoExamesLaudos
  },
  EXAME_FISICO: {
    codigo: 9, nome: "exameFisico", implementacao: <ExameFisico />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoExameFisico
  },
  CIDS: {
    codigo: 10, nome: "cids", implementacao: <CIDs />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoCids
  },
  ESTRUTURA_E_PROGNOSTICO: {
    codigo: 5, nome: "estruturaEPrognostico",
    implementacao:
      <EstruturaEPrognostico
        textoPerguntaDuracaoAlteracoes={PERGUNTA_DURACAO_ALTERACOES_V1}
        dominioDuracaoAlteracao={DOMINIO_DURACAO_ALTERACAO_V1}
      />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoEstruturaEPrognostico
  },
  ESTRUTURA_E_PROGNOSTICO_v2: {
    codigo: 18, nome: "estruturaEPrognostico",
    implementacao:
      <EstruturaEPrognostico
        textoPerguntaDuracaoAlteracoes={PERGUNTA_DURACAO_ALTERACOES_V2}
        dominioDuracaoAlteracao={DOMINIO_DURACAO_ALTERACAO_V2}
      />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoEstruturaEPrognostico_v2
  },
  FUNCOES_DO_CORPO: {
    codigo: 4, nome: "funcoesDoCorpo", implementacao: <FuncoesDoCorpo />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoFuncoesDoCorpo
  },
  IMPEDIMENTOS_E_RISCO_SOCIAL: {
    codigo: 7, nome: "impedimentosERiscoSocial", implementacao: <ImpedimentosERiscoSocial />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoImpedimentosERiscoSocial
  },
  ATIVIDADES_E_PARTICIPACAO: {
    codigo: 6, nome: "atividadesEParticipacao", implementacao: <AtividadesEParticipacao />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoAtividadesEParticipacao
  },
  RESULTADO_PARCIAL_BPC: {
    codigo: 8, nome: "resultadoParcialBpc", implementacao: <ResultadoParcialBPC />,
    deveValidar: false, exibeMenuOpcoes: true
  },
  ISENCAO_CARENCIA: {
    codigo: 13, nome: "isencaoCarencia", implementacao: <IsencaoCarencia />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoIsencaoCarencia
  },
  RELACOES_PREVIDENCIARIAS: {
    codigo: 12, nome: "relacoesPrevidenciarias", implementacao: <RelacoesPrevidenciarias />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  PRONTUARIO: {
    codigo: 14, nome: "prontuario", implementacao: <Prontuario />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  NEXO_TECNICO: {
    codigo: 15, nome: "nexoTecnico", implementacao: <NexoTecnico />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoNexoTecnico
  },
  CONCLUSAO_BI_DOCMED: {
    codigo: 16, nome: 'conclusaoBIDocmed', implementacao: <ConclusaoBIDocmed />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  VINCULOS_INCAPACIDADE: {
    codigo: 17, nome: 'vinculosIncapacidade', implementacao: <VinculosIncapacidade />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoVinculosIncapacidade
  },
  AVALIACAO_FISICA: {
    codigo: 19, nome: 'avaliacaoFisica', implementacao: <AvaliacaoFisica />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoAvaliacaoFisica
  },
  GRAU_INCAPACIDADE: {
    codigo: 20, nome: 'grauIncapacidade', implementacao: <GrauIncapacidade />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoGrauIncapacidade
  },
  CONCLUSAO_BI: {
    codigo: 21, nome: 'conclusaoPericiaBI', implementacao: <ConclusaoPericiaBI />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  CONCLUSAO_B88: {
    codigo: 22, nome: "conclusaoB88", implementacao: <ResultadoPericiaB88 />,
    deveValidar: false, exibeMenuOpcoes: false, ...ValidacaoResultadoPericiaB88
  },
  CONCLUSAO_REVISAO: {
    codigo: 23, nome: "conclusaoRevisao", implementacao: <ConclusaoPericiaRevisaoBI />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  RESTABELECIMENTO_BENEFICIO: {
    codigo: 24, nome: "restabelecimento", implementacao: <RestabelecimentoBeneficio />,
    deveValidar: true, exibeMenuOpcoes: false, ...ValidacaoRestabelecimento
  },
  MANUTENCAO_DT_CESSACAO: {
    codigo: 25, nome: "manutDataCessacao", implementacao: <ManutencaoDataCessacao />,
    deveValidar: false, exibeMenuOpcoes: true
  },
  DADOS_DIAGNOSTICO_PERICIA_ANTERIOR: {
    codigo: 26, nome: "dadosDiagnosticoPericiaAnterior", implementacao: <DiagnosticoPericiaAnterior />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoDiagnosticoAnteriorCids
  },
  AUXILIO_ACIDENTE: {
    codigo: 27, nome: "indAuxilioAcidente", implementacao: <AuxilioAcidente />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoAuxilioAcidente
  },
  EXAMES_LAUDOS_ANTERIORES: {
    codigo: 28, nome: "examesLaudosAnteriores", implementacao: <ExamesELaudosAnteriores />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  CONCLUSAO_ATESTMED_SABI: {
    codigo: 29, nome: "conclusaoAtestmedSabi", implementacao: <ConclusaoAtestmed />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  DOCUMENTACAO_MEDICA_ODONTOLOGICA: {
    codigo: 30, nome: "documentacaoMedicaOdontologica", implementacao: <DocumMedOdont />,
    deveValidar: true, exibeMenuOpcoes: true, ...ValidacaoDocumMedOdont // TODO avaliar botão "validar e prosseguir" pelo fato de não estar validando nada aqui! (demandado por Eduardo)
  },
  DOCUMENTACAO_MEDICA_ODONTOLOGICA_ANTERIOR: {
    codigo: 31, nome: "documentacaoMedicaOdontologicaAnterior", implementacao: <DocumMedOdontAnteriores />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  CID_PRINCIPAL: {
    codigo: 32, nome: "cidPrincipal", implementacao: <CIDPrincipal />,
    deveValidar: false, exibeMenuOpcoes: false, ...ValidacaoCidPrincipal
  },
  MENSAGEM_ACIDENTE_TRABALHO: {
    codigo: 33, nome: "mensagemAcidenteTrabalho", implementacao: <MensagemAcidenteTrabalho />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  CONCLUSAO_ATESTMED_SIBE: {
    codigo: 34, nome: "conclusaoAtestmedSibe", implementacao: <ConclusaoAtestmed />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  CONCLUSAO_PERICIA_RBI: {
    codigo: 35, nome: "conclusaoPericiaRBI", implementacao: <ConclusaoPericiaRBI />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  MENSAGEM_DATA_CESSACAO_RBI: {
    codigo: 36, nome: "mensagemDataCessacaoRBI", implementacao: <MensagemDataCessacaoRBI />,
    deveValidar: false, exibeMenuOpcoes: false
  },
  GRAU_INCAPACIDADE_RBI: {
    codigo: 37, nome: "grauIncapacidadeRBI", implementacao: <GrauIncapacidadeRBI />,
    deveValidar: false, exibeMenuOpcoes: false, ...ValidacaoGrauIncapacidadeRBI
  },MENSAGEM_TELEMEDICINA: {
    codigo: 38, nome: "mensagemTelemedicina", implementacao: <MensagemTelemedicina />,
    deveValidar: false, exibeMenuOpcoes: false
  },MENSAGEM_TELEMEDICINA_EXAME_FISICO: {
    codigo: 39, nome: "mensagemTelemedicinaExameFisico", implementacao: <MensagemTelemedicinaExameFisico />,
    deveValidar: false, exibeMenuOpcoes: false
  }
});

const TIPO_IDENT_EMITENTE_ATESTMED_CRM = 1;
const TIPO_IDENT_EMITENTE_ATESTMED_RMS = 8;
const TIPO_IDENT_EMITENTE_ATESTMED_OUTROS = 0;


const tiposPericia = Object.freeze({
  INSS_BPC_PI: { id: 1, tipo: 'INSS_BPC_PI', descricao: 'Avaliação Médico Pericial BPC/LOAS', fase: 'BPC', isAtiva: true },
  INSS_BI_PI_DOCMED: { id: 2, tipo: 'INSS_BI_PI_DOCMED', descricao: 'Avaliação Médico Pericial BI - DOCMED', fase: 'DOCMED', isAtiva: false },
  INSS_BI_PI: { id: 3, tipo: 'INSS_BI_PI', descricao: 'Avaliação Médico Pericial BI - Perícia Inicial', fase: 'Inicial', isAtiva: true },
  INSS_BPC_B88: { id: 4, tipo: 'INSS_BPC_B88', descricao: 'Avaliação Médico Pericial BPC/LOAS - Perícia Médica da Pessoa Idosa', fase: 'B88', isAtiva: false },
  INSS_BI_PMC: { id: 5, tipo: 'INSS_BI_PMC', descricao: 'Avaliação Médico Pericial BI - Perícia Médica Conclusiva', fase: 'PMC', isAtiva: true },
  INSS_BI_PMRES: { id: 6, tipo: 'INSS_BI_PMRES', descricao: 'Avaliação Médico Pericial BI - Perícia Médica Resolutiva', fase: 'PMRES', isAtiva: true },
  INSS_BI_REVISAO: { id: 7, tipo: 'INSS_BI_REVISAO', descricao: 'Avaliação Médico pericial BI - Perícia de Revisão', fase: 'Revisão', isAtiva: true },
  INSS_BI_ATESTMED_SABI: { id: 8, tipo: 'INSS_BI_ATESTMED_SABI', descricao: 'Conformação de Documentação Médica/Odontológica - ATESTMED', fase: 'ATESTMED', isAtiva: true },
  INSS_BI_ATESTMED_SIBE: { id: 9, tipo: 'INSS_BI_ATESTMED_SIBE', descricao: 'Conformação de Documentação Médica/Odontológica - ATESTMED', fase: 'ATESTMED', isAtiva: true },
  INSS_BI_PRBI: { id: 10, tipo: 'INSS_BI_PRBI', descricao: 'Avaliação Médico Pericial BI - Perícia de Revisão de Benefício BI', fase: 'REVISAO DE BENEFICIO', isAtiva: true }
});

const tiposSolicitacaoExigencia = Object.freeze({
  SIMA: { descricao: "SIMA", id: 1 },
  OUTRAS: { descricao: "Outras Exigências", id: 2 }
});

const tiposCampoGenerico = Object.freeze({
  TEXTO: "TEXTO",
  DATA: "DATA",
  BOOLEAN_SIM_NAO: "BOOLEAN_SIM_NAO",
  NUMERICO: "NUMERICO", 
  COMBO: "COMBO"
});

const tipoSimNao = Object.freeze({
  SIM: { descricao: "SIM", id: 1 },
  NAO: { descricao: "NAO", id: 0 }
});

const situacaoPericia = Object.freeze({
  CRIADA: { id: 0, descricao: 'Criada' },
  EM_ABERTO: { id: 1, descricao: 'Em aberto' },
  CANCELADA: { id: 2, descricao: 'Cancelada' },
  PENDENTE_SIMA: { id: 3, descricao: 'Pendende de Exigência' },
  CONCLUIDA: { id: 4, descricao: 'Concluida' },
  REVISADA: { id: 5, descricao: 'Revisada' }
});

const tipoCampoValidacaoPericia = Object.freeze({
  DATA_NASCIMENTO_AVALIADO: "DATA_NASCIMENTO_AVALIADO", //
  DATA_REFERENCIA: "DATA_REFERENCIA",
  INDICADOR_RESTABELECIMENTO_BENEFICIO: "INDICADOR_RESTABELECIMENTO_BENEFICIO",
  DATA_REALIZACAO_PERICIA: "DATA_REALIZACAO_PERICIA"
});

const tipoCampoExibicaoPericia = Object.freeze({
  NUMERO_ESPECIE_BENEFICIO: "NUMERO_ESPECIE_BENEFICIO"
});

const tipoMensagemValidacaoCampoGenerico = Object.freeze({
  ALERTA: "ALERTA", //
  ERRO: "ERRO"
});

const tipoOperadorValidacaoCampoGenerico = Object.freeze({
  MAIOR_QUE: { tipo: "MAIOR_QUE" }, //
  MAIOR_OU_IGUAL_QUE: { tipo: "MAIOR_OU_IGUAL_QUE" }, //
  MENOR_QUE: { tipo: "MENOR_QUE" }, //
  MENOR_OU_IGUAL_QUE: { tipo: "MENOR_OU_IGUAL_QUE" }, //
  IGUAL: { tipo: "IGUAL" }, //
  MENOR_QUE_DATA_ATUAL_MAIS_VALOR_FIXO: { tipo: "MENOR_QUE_DATA_ATUAL_MAIS_VALOR_FIXO", operador: "+" }, //
  MAIOR_QUE_DATA_ATUAL_MENOS_VALOR_FIXO: { tipo: "MAIOR_QUE_DATA_ATUAL_MENOS_VALOR_FIXO", operador: "-" }
});

const tipoRegraValidacaoCampoGenerico = Object.freeze({
  CAMPO_GENERICO_CAMPO_GENERICO: "CAMPO_GENERICO_CAMPO_GENERICO", //
  CAMPO_GENERICO_CAMPO_ESTATICO: "CAMPO_GENERICO_CAMPO_ESTATICO", //
  CAMPO_GENERICO_VALOR_FIXO: "CAMPO_GENERICO_VALOR_FIXO",
  CAMPO_ESTATICO_VALOR_FIXO: "CAMPO_ESTATICO_VALOR_FIXO"
});

const tiposNexoTecnico = Object.freeze({
  PROFISSIONAL: { tipo: 'PROFISSIONAL', id: 1 },
  INDIVIDUAL: { tipo: 'INDIVIDUAL', id: 2 },
  EPIDEMIOLOGICO: { tipo: 'EPIDEMIOLOGICO', id: 3 }
});

const tiposParametrosModuloEstatico = Object.freeze({
  PERIODO_MAX_DCB: { id: 1, nome: "limiteMaxDCB" }, //
  PERIODO_MAX_DCI: { id: 2, nome: "limiteMaxDCI" }, //
  PERIODO_MAX_RETROACAO_DCB: { id: 3, nome: "limiteRetroacaoDCB" },//
  PERIODO_RESTABELECIMENTO: { id: 4, nome: "periodoRestabelecimento" },
  MOTIVO_NAO_CONFORMACAO_SEM_DOCUMENTO_ATESTMED_SABI: { id: 7, nome: "motivosNaoConformacaoSemDocumentoAtestmedSABI" },
  MOTIVO_NAO_CONFORMACAO_SEM_DOCUMENTO_ATESTMED_SIBE: { id: 8, nome: "motivosNaoConformacaoSemDocumentoAtestmedSIBE" },
  PERIODO_MAX_DCB_RBI: { id: 9, nome: "limiteMaxDCB" }, //
  PERIODO_MAX_DCI_RBI: { id: 10, nome: "limiteMaxDCI" } //
});

const SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS = 'CMP';
const SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS_2 = 'CMP2';
const SIGLA_CAMPO_DID = 'DID';
const SIGLA_CAMPO_JDID = 'JDID';
const SIGLA_CAMPO_DII = 'DII';
const SIGLA_CAMPO_JDII = 'JDII';
const SIGLA_CAMPO_DCB = 'DCB';
const SIGLA_CAMPO_PERICIA_PRESENCIAL = 'IPP';
const SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE = 'COMPINC';
const SIGLA_CAMPO_INDICACAO_AA_RP_LI = 'IAARPLI';
const SIGLA_CAMPO_GENERICO_PRORROGA = 'PRORRINC';
const SIGLA_CAMPO_CID_ALTERADO = 'DCIDALT';
const SIGLA_CAMPO_JUSTIFICATIVA_DID = 'JDID';
const SIGLA_CAMPO_JUSTIFICATIVA_DII = 'JDII';
const SIGLA_CAMPO_JUSTIFICATIVA_REVISAO = 'JUSTREV';
const SIGLA_CAMPO_RESPOSTA_CONFORMACAO = 'CONFATME';
const SIGLA_CAMPO_PRAZO_REPOUSO = 'PRAAFAST';
const SIGLA_CAMPO_DATA_INICIO_REPOUSO = 'DTINIREP';
const SIGLA_CAMPO_PRAZO_REPOUSO_DIAS = 'PRAREPDI';
const SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO = 'MOTNCONF';
const SIGLA_CAMPO_DESCRICAO_NAO_CONFORMACAO = 'DESNCONF';
const SIGLA_CAMPO_HISTORIA_CLINICA = 'HCPIBI';
const SIGLA_CAMPO_ECTOSCOPIA = 'EFASPGER';
const SIGLA_CAMPO_PERGUNTA_ACIDENTE_TRABALHO_CONFORMACAO = 'CONFACTR';
const SIGLA_CAMPO_DATA_ACIDENTE_TRABALHO_CONFORMACAO = 'CODTACTR';
const SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO = 'CMINCRBI';
const SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO = 'CMINVRBI';

const DATA_REFERENCIA_EMPREGADO_DOMESTICO = new Date('06/02/2015'); //---> react date format deve ser MM/DD/YYYY

const ID_FILIACAO_EMPREGADO_DOMESTICO = 3;
//                                        1=Empregado, 2=Trabalhador Avulso, 11=Segurado Especial
const IDS_TIPOS_FILIACAO_NEXO_TECNICO = [1, ID_FILIACAO_EMPREGADO_DOMESTICO, 2, 11];

export {
  DATA_REFERENCIA_EMPREGADO_DOMESTICO, //
  IDS_TIPOS_FILIACAO_NEXO_TECNICO, //
  ID_FILIACAO_EMPREGADO_DOMESTICO, //
  SIGLA_CAMPO_CID_ALTERADO, //
  SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS, //
  SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS_2, //
  SIGLA_CAMPO_DATA_ACIDENTE_TRABALHO_CONFORMACAO, //
  SIGLA_CAMPO_DATA_INICIO_REPOUSO, //
  SIGLA_CAMPO_DCB, //
  SIGLA_CAMPO_DESCRICAO_NAO_CONFORMACAO, //
  SIGLA_CAMPO_DID, //
  SIGLA_CAMPO_DII, //
  SIGLA_CAMPO_ECTOSCOPIA,
  SIGLA_CAMPO_GENERICO_PRORROGA, // 
  SIGLA_CAMPO_HISTORIA_CLINICA, //
  SIGLA_CAMPO_INDICACAO_AA_RP_LI, //
  SIGLA_CAMPO_JDID, //
  SIGLA_CAMPO_JDII, //
  SIGLA_CAMPO_JUSTIFICATIVA_DID, //
  SIGLA_CAMPO_JUSTIFICATIVA_DII, //
  SIGLA_CAMPO_JUSTIFICATIVA_REVISAO, //
  SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO, // 
  SIGLA_CAMPO_PERGUNTA_ACIDENTE_TRABALHO_CONFORMACAO, // 
  SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE, //
  SIGLA_CAMPO_PERICIA_PRESENCIAL, // 
  SIGLA_CAMPO_PRAZO_REPOUSO, // 
  SIGLA_CAMPO_PRAZO_REPOUSO_DIAS, // 
  SIGLA_CAMPO_RESPOSTA_CONFORMACAO, // 
  SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO,//
  SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO,//
  TIPO_IDENT_EMITENTE_ATESTMED_CRM, //
  TIPO_IDENT_EMITENTE_ATESTMED_OUTROS, // 
  TIPO_IDENT_EMITENTE_ATESTMED_RMS, // 
  modulosEstaticos, // 
  situacaoPericia, // 
  tipoCampoValidacaoPericia, //
  tipoCampoExibicaoPericia, //
  tipoMensagemValidacaoCampoGenerico, //
  tipoOperadorValidacaoCampoGenerico, //
  tipoRegraValidacaoCampoGenerico, // 
  tipoSimNao, // 
  tiposCampoGenerico, // 
  tiposNexoTecnico, //
  tiposParametrosModuloEstatico, //
  tiposPericia,
  tiposSolicitacaoExigencia
};

