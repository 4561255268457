import { tiposPericia } from "../features/pericia/constants";

export const isTipoPericiaBI = template => {
  return template?.tipoPericia === tiposPericia.INSS_BI_PI.tipo
    || template?.tipoPericia === tiposPericia.INSS_BI_PMC.tipo
    || template?.tipoPericia === tiposPericia.INSS_BI_PMRES.tipo
    || template?.tipoPericia === tiposPericia.INSS_BI_REVISAO.tipo
    || template?.tipoPericia === tiposPericia.INSS_BI_PRBI.tipo;
}


export const obterValorCampoGenericoPorSigla = (valoresCamposGenericos, sigla) => {
  return valoresCamposGenericos.find(v => v.siglaCampoGenerico === sigla);
}