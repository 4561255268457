import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BrCard from '@govbr/react-components/lib/BrCard';
import { formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import {
  alterarDCBGrauIncapacidade
} from '../../action';
import { verificaDeveAtualizarDataDCB } from './utils';

const MensagemDataCessacaoRBI = props => {

  const {template, pericia, alterarDCBGrauIncapacidade} = props;
  const deveAtualizarDataDCB = verificaDeveAtualizarDataDCB(template, pericia);
  const dataCessacaoBeneficioPericia = pericia?.moduloGrauIncapacidade?.dataCessacaoBeneficio;

  useEffect(() => {
    if (deveAtualizarDataDCB) {
      alterarDCBGrauIncapacidade(new Date(), true);
    }
  }, [deveAtualizarDataDCB, alterarDCBGrauIncapacidade]);

  return (
    <div id="idMensagemDataCessacaoRBI">
      <BrCard>
        <span htmlFor="dataCessacao">O benefício será cessado em: <b>{formatarDateTimeISOToDataBr(dataCessacaoBeneficioPericia)} (DRE)</b></span>
      </BrCard>
    </div>
  );
}

const mapStateToProps = (state) => {
  return ({
    template: state.principal?.template,
    pericia: state.pericia
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarDCBGrauIncapacidade
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MensagemDataCessacaoRBI)