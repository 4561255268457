import { MSG_CAMPO_OBRIGATORIO } from './Messages'
export const validacaoMatricula = (matricula) => {
    let valido = false
    let soma = 0
    let div = ""
    const multi = [ 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const multi2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    matricula = matricula.replace("-", "").split(".").join("")

    if(matricula !== "00000000000000000000000000000000" && matricula !== null && matricula !== ""){
        for (let i = 0; i < 30; i++) {
            soma = soma + parseInt(matricula[i]) * multi[i];
        }
        let resto = soma % 11
        div += resto === 10 ? "1" : resto.toString()

        soma = 0;

        for (let i = 0; i < 31; i++) {
            soma = soma + parseInt(matricula[i]) * multi2[i];
        }
        resto = soma % 11
        div += resto === 10 ? "1" : resto.toString()

        if (matricula.substring(30, 32) === div) {
            valido = true;
        }
    }
    return valido
}

export const validarCampoObrigatorio  = (campo, msg = MSG_CAMPO_OBRIGATORIO) => {
    return !campo || (campo.typeof === 'string' &&  campo.trim().length === 0) ? { valido: false, msg } : {valido: true};
}

export const validarValorMaximo = (value, maxValue) => {
    return (value <= maxValue);
}

export const validarValorMinimo = (value, minValue) => {
    return (value >= minValue);
}