import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal from '@govbr/react-components/lib/BrModal'

import { ocultarDialogTemplate } from './action'
import { exibirJSONFormatado } from '../../common/JSONUtils';

const DialogTemplate = (props) => {

    const { isExibirTemplate, template } = props.dados;

    return (
      <BrModal id="dialogDetalhesTemplate" visible={isExibirTemplate} 
        large style={{ width: "90%", maxHeight: '90%', overflowY: 'scroll' }}
        title={`Detalhes Template ${template?.id}`} onClose={props.ocultarDialogTemplate}>
        <div> 
          <pre>
            {template 
              ? exibirJSONFormatado(template)
              : 'Sem dados'}
          </pre>
        </div>

      </BrModal>
    );
}

const mapStateToProps = (state) => ({
  dados: state.consultasAPI
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogTemplate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogTemplate);
