import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";
import { verificaCidEmGrupo } from "../../../../common/Utils";

export const verificarSeCamposPreenchidos = (pericia) => {
  const cidPrincipalEmGrupoO = pericia.moduloCid && verificaCidEmGrupo(pericia.moduloCid.cidPrincipal, "O");
  return pericia.moduloIsencaoCarencia 
    && (pericia.moduloIsencaoCarencia.isentaCarencia !== null 
    || (cidPrincipalEmGrupoO && pericia.moduloIsencaoCarencia.isentaCarenciaPorGestacaoRisco !== null));
}

export const validar = (pericia) => {
 
  const moduloIsencaoCarencia = pericia?.moduloIsencaoCarencia;
  const existeNexo = (pericia?.moduloNexoTecnico?.existeNexoProfissional !== undefined && pericia?.moduloNexoTecnico?.existeNexoProfissional === 1)
    || (pericia?.moduloNexoTecnico?.existeNexoIndividual !== undefined && pericia?.moduloNexoTecnico?.existeNexoIndividual === 1)
    || (pericia?.moduloNexoTecnico?.existeNtep !== undefined && pericia?.moduloNexoTecnico?.existeNtep === 1);

  if (existeNexo) { //nao deve validar se existe Nexo
    return {
      isValida: true
    };
  }

  const cidPrincipalEmGrupoO = pericia.moduloCid && verificaCidEmGrupo(pericia.moduloCid.cidPrincipal, "O");

  if (!moduloIsencaoCarencia || Object.keys(moduloIsencaoCarencia).length === 0) {
    let validacao = {
      isValida: false,
      erroRespostaIsencaoObrigatoria: MSG_CAMPO_OBRIGATORIO,
      erroDoencaoIsencaoObrigatoria: null
    }
    if (cidPrincipalEmGrupoO) {
      validacao = {
        ...validacao,
        erroRespostaIsencaoGestacaoRiscoObrigatoria: MSG_CAMPO_OBRIGATORIO,
        erroRespostaJustificativaGestacaoRiscoObrigatoria: MSG_CAMPO_OBRIGATORIO
      }
    }
    return validacao
  }

  const { isentaCarencia, tipoDoencaIsencao, isentaCarenciaPorGestacaoRisco, justificativaGestacaoAltoRisco } = moduloIsencaoCarencia;

  const erroRespostaIsencaoGestaoRiscoObrigatoria = isentaCarenciaPorGestacaoRisco === undefined || isentaCarenciaPorGestacaoRisco === null 
    ? MSG_CAMPO_OBRIGATORIO : null;
  const erroRespostaJustificativaGestacaoRiscoObrigatoria = 
    (!justificativaGestacaoAltoRisco || (justificativaGestacaoAltoRisco instanceof String && justificativaGestacaoAltoRisco.trim().length === 0)) 
    ? MSG_CAMPO_OBRIGATORIO 
    : null;
  const erroRespostaIsencaoObrigatoria = isentaCarencia === null ? MSG_CAMPO_OBRIGATORIO : null;
  const erroDoencaoIsencaoObrigatoria = isentaCarencia === 1 
    && !isentaCarenciaPorGestacaoRisco 
    && (tipoDoencaIsencao === undefined || tipoDoencaIsencao === null) ? MSG_CAMPO_OBRIGATORIO : null;

  let validacao = {
    isValida: (!erroRespostaIsencaoObrigatoria 
      && !erroDoencaoIsencaoObrigatoria),
    erroRespostaIsencaoObrigatoria: erroRespostaIsencaoObrigatoria,
    erroDoencaoIsencaoObrigatoria: erroDoencaoIsencaoObrigatoria
  }

  if (cidPrincipalEmGrupoO) {
    validacao = {
      ...validacao,
      isValida: 
        validacao.isValida 
        && !erroRespostaIsencaoGestaoRiscoObrigatoria 
        && !erroRespostaJustificativaGestacaoRiscoObrigatoria,
      erroRespostaIsencaoGestacaoRiscoObrigatoria: erroRespostaIsencaoGestaoRiscoObrigatoria,
      erroRespostaJustificativaGestacaoRiscoObrigatoria: erroRespostaJustificativaGestacaoRiscoObrigatoria
    }
  }

  return validacao

}