import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import Box from "@material-ui/core/Box";
import DialogSima from '../../features/pericia/modulos/SIMA';

import { imprimirAvaliacaoSocial, salvarPericia } from '../../features/pericia/action';
import { exibirDialogRetornoSima, exibirDialogSolicitarSima } from '../../features/pericia/modulos/SIMA/action';

class MenuOpcoes extends React.Component {

  render = () => {
    const { pericia, isPendenteSima, isSimaSolicitado, isSomenteLeitura,
      exibirDialogSolicitarSima, exibirDialogRetornoSima, imprimirAvaliacaoSocial, salvarPericia, isPericiaBPC, isPericiaPermiteSima } = this.props;

    const isPericiaConcluida = pericia.situacao.id === 4 || pericia.situacao.id === 5
    const exibeBotaoSolicitarSima = isPericiaPermiteSima && !isSimaSolicitado && !isPericiaConcluida
    const exibeBotaoRetornoSima = (isPericiaPermiteSima && isSimaSolicitado) //SIMA para pericia atual
      || (pericia.periciaOrigemRevisao && pericia.periciaOrigemRevisao.simaSolicitado === 1); //SIMA para pericia origem (revisao)

    return (
      <>
        <Box display="flex" p={2} position="sticky" top={0} bgcolor="#fff" zIndex="2" mt={1} justifyContent="flex-end">
          {isPericiaBPC &&
            <BrButton id="btnDownloadAvaliacaoSocial" secondary className="mr-2"
              onClick={imprimirAvaliacaoSocial}>
              Avaliação Social
            </BrButton>
          }
          {exibeBotaoSolicitarSima &&
            <BrButton id="btnSima" secondary className="mr-2"
              onClick={() => exibirDialogSolicitarSima(true)}>
              <i className={"fas fa-user-md"} />
              Solicitar Exigência
            </BrButton>
          }
          {exibeBotaoRetornoSima &&
            <BrButton id="btnSima" secondary className="mr-2"
              onClick={() => exibirDialogRetornoSima(true)}>
              <i className={"fas fa-user-md"} />
              {isPendenteSima ? "Cumprir Exigência" : "Dados Exigência"}
            </BrButton>
          }
          <BrButton id="btnSalvarPericia" secondary
            onClick={() => salvarPericia(pericia)}
            disabled={isSomenteLeitura}>
            <i className={"fas fa-save"} />
            Salvar Progresso
          </BrButton>
        </Box>

        <DialogSima />
      </>
    );
  }


}

const mapStateToProps = (state) => {
  const { isPendenteSima, isSimaSolicitado, isSomenteLeitura, isPericiaBPC, isPericiaPermiteSima } = state.principal;
  return ({
    pericia: state.pericia,
    isPendenteSima,
    isSimaSolicitado,
    isSomenteLeitura,
    isPericiaBPC,
    isPericiaPermiteSima
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  salvarPericia,
  exibirDialogSolicitarSima,
  exibirDialogRetornoSima,
  imprimirAvaliacaoSocial
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuOpcoes);