import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {
  const moduloExameFisico = pericia?.moduloExameFisico;
  const deveValidarExameFisico = moduloExameFisico
    && (moduloExameFisico.peso || moduloExameFisico.altura || moduloExameFisico.pressaoMaxima
      || moduloExameFisico.pressaoMinima || moduloExameFisico.descricaoExameFisico);

  return deveValidarExameFisico;
}

export const validar = pericia => {

  const moduloExameFisico = pericia?.moduloExameFisico;

  const { pressaoMinima, pressaoMaxima, altura, descricaoExameFisico } = moduloExameFisico;
  let erroPressaoMinima, erroPressaoMaxima, erroDescricao, erroAltura;

  if (pressaoMaxima) {
    if (!pressaoMinima) {
      erroPressaoMinima = "Campo obrigatório quando pressão arterial máxima preenchida";
    } else if (Number(pressaoMaxima) <= Number(pressaoMinima)) {
      erroPressaoMaxima = "Pressao máxima deve ser maior que pressão arterial mínima";
    }
  } else if (pressaoMinima) {
    erroPressaoMaxima = "Campo obrigatório quando pressão arterial mínima preenchida";
  }

  if (pressaoMinima && Number(pressaoMinima < 10)) {
    erroPressaoMinima = "Campo deve ter pelo menos 2 dígitos";
  }
  if (pressaoMaxima && Number(pressaoMaxima < 10)) {
    erroPressaoMaxima = "Campo deve ter pelo menos 2 dígitos";
  }

  if (altura && (parseFloat(altura) < 0.1 || parseFloat(altura) > 3)) {
    erroAltura = "Campo deve ser maior que 0,1m e menor ou igual a 3m";
  }

  const isDescricaoPreenchido = descricaoExameFisico != null && descricaoExameFisico.trim().length > 0;

  erroDescricao = !isDescricaoPreenchido ? MSG_CAMPO_OBRIGATORIO : null;

  return {
    isValida: !erroPressaoMaxima && !erroPressaoMinima && !erroDescricao && !erroAltura,
    erroPressaoMaxima,
    erroPressaoMinima,
    erroAltura,
    erroDescricao
  };
};