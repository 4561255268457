import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import Concluir from "../../../components/Concluir";
import HeaderSecao from '../../../components/HeaderSecao';
import { Grid } from '@material-ui/core';

import { formatarDateTimeISOToDataBr } from "../../../common/DataUtils";
import { obterTextoResultadoCapacidadeLaborativaRBI } from "../../../common/PericiaBiUtil";
import { downloadPericiaGenerico, validacaoCompletaPericia, alterarDCBGrauIncapacidade } from "../action";
import { formatarSimNaoBooleano } from "../../../common/formatadores";

import {
  SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO, SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO
 } from '../constants';
import { obtemFluxoRBI, tipoFluxoRBI } from "./GrauIncapacidadeRBI/utils";
import { verificaDeveAtualizarDataDCB } from "./MensagemDataCessacaoRBI/utils";

const ConclusaoPericiaRBI = (props) => {
  const pericia = props.pericia;
  const moduloGrauIncapacidade = pericia.moduloGrauIncapacidade;

  const cidPericia = pericia.moduloCid?.cidPrincipal ?
    `${pericia.moduloCid?.cidPrincipal} - ${pericia.moduloCid?.nomeCidPrincipal}` : "";

  const cidBeneficio = pericia.periciaAnterior?.codigoCID;
  const valorCid = cidBeneficio || cidPericia;

  const respostaPerguntaIncapacidade = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_COMPROVACAO_INCAPACIDADE_MANUTENCAO_BENEFICIO)?.valorTextoCampoGenerico;
  const respostaPerguntaInvalidez = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_COMPROVACAO_INVALIDEZ_MANUTENCAO_BENEFICIO)?.valorTextoCampoGenerico;
  
  const isFluxo31_91 = obtemFluxoRBI(pericia) === tipoFluxoRBI.FLUXO_BENEFICIOS_INCAPACIDADE;

  const exibeDadosAuxAcidente = isFluxo31_91 && respostaPerguntaIncapacidade === '0';
  const auxAcidente = formatarSimNaoBooleano(moduloGrauIncapacidade?.indAuxilioAcidente);

  const deveExibirDadosGrauIncapacidade = respostaPerguntaIncapacidade === '1' || respostaPerguntaInvalidez === '1';
  
  const tipoGrauIncapacidade = moduloGrauIncapacidade?.tipoGrauIncapacidade;
  const grauIncapacidade = isFluxo31_91
    ? tipoGrauIncapacidade && props.tiposGrauIncapacidade && props.tiposGrauIncapacidade.find(t => t.id === tipoGrauIncapacidade)?.descricao
    : tipoGrauIncapacidade && props.tiposGrauInvalidez && props.tiposGrauInvalidez.find(t => t.id === tipoGrauIncapacidade)?.descricao;

  const capacidadeLaborativa = obterTextoResultadoCapacidadeLaborativaRBI(respostaPerguntaIncapacidade, respostaPerguntaInvalidez, isFluxo31_91);

  const deveExibirDcb = (deveExibirDadosGrauIncapacidade && tipoGrauIncapacidade === 1) //existe incapacidade com DCB
                        || respostaPerguntaIncapacidade === '0' //nao existe incapacidade, com ou sem AA, sera cessado da DRE -> DCB
                        || respostaPerguntaInvalidez === '0'; //nao existe invalidez, sera cessado da DRE -> DCB

  const dcb = moduloGrauIncapacidade?.dataCessacaoBeneficio && formatarDateTimeISOToDataBr(moduloGrauIncapacidade?.dataCessacaoBeneficio);
  const dci = moduloGrauIncapacidade?.dataComprovacaoIncapacidade && formatarDateTimeISOToDataBr(moduloGrauIncapacidade?.dataComprovacaoIncapacidade);
 
  const majoracao = formatarSimNaoBooleano( moduloGrauIncapacidade?.majoracao25);
  const reavaliacao = formatarSimNaoBooleano( moduloGrauIncapacidade?.passivelReavaliacao2anos);

  const deveAtualizarDataDCB = verificaDeveAtualizarDataDCB(props.template, pericia);
  const {alterarDCBGrauIncapacidade} = props

  useEffect(() => {
    if (deveAtualizarDataDCB) {
      alterarDCBGrauIncapacidade(new Date(), true);
    }
  }, [deveAtualizarDataDCB, alterarDCBGrauIncapacidade]);

  useEffect(() => {
    props.validacaoCompletaPericia(props.pericia, true);
  });

  return (
    <>
      <div>
        {
          props.respostaConcluir && props.respostaConcluir.erros
          && (
            props.respostaConcluir.erros.map((e, index) =>
            (
              <BrMessage key={`erro_${index}`} error>
                {e}
              </BrMessage>)
            )
          )
        }
      </div>


      <HeaderSecao texto="Dados Técnicos" />
      
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <span htmlFor="cid">CID Principal: </span>
          <span id="cid" className="texto-informativo">
            {valorCid}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span htmlFor="capLaborativa">Capacidade Laborativa: </span>
          <span id="capLaborativa" className="texto-informativo">{capacidadeLaborativa}</span>
        </Grid>
        {
          deveExibirDadosGrauIncapacidade &&
          (
            <Grid item xs={12}>
              <span htmlFor="grauIncapacidade">
                {isFluxo31_91 
                  ? "Classificação da Incapacidade: "
                  : "Classificação da Invalidez: "
                }
              </span>
              <span id="grauIncapacidade" className="texto-informativo">{grauIncapacidade}</span>
            </Grid>
          )
        }
        {
          exibeDadosAuxAcidente &&
          (
            <Grid item xs={12}>
              <span htmlFor="indAuxAcidente">Indicação de Auxílio Acidente - AA? </span>
              <span id="indAuxAcidente" className="texto-informativo">{auxAcidente}</span>
            </Grid>
          )
        }
        {
           deveExibirDcb &&
          (
            <Grid item xs={12}>
              <span htmlFor="dcb">DCB: </span>
              <span id="dcb" className="texto-informativo">{dcb}</span>
            </Grid>
          )
        }
        {
          deveExibirDadosGrauIncapacidade && (tipoGrauIncapacidade === 3 || tipoGrauIncapacidade === 6) &&
          (
            <Grid item xs={12}>
              <span htmlFor="dci">DCI: </span>
              <span id="dci" className="texto-informativo">{dci}</span>
            </Grid>
          )
        }
        {
          deveExibirDadosGrauIncapacidade && (tipoGrauIncapacidade === 5) && 
            <Grid item xs={12}>
              <span htmlFor="reavaliacao">Passível de reavaliação em 2 anos: </span>
              <span id="reavaliacao" className="texto-informativo">{reavaliacao}</span>
            </Grid>
        }
        {
          deveExibirDadosGrauIncapacidade && (tipoGrauIncapacidade === 4 || tipoGrauIncapacidade === 5) && 
            <Grid item xs={12}>
              <span htmlFor="majoracao">Majoração 25%: </span>
              <span id="majoracao" className="texto-informativo">{majoracao}</span>
            </Grid>
        }
      </Grid>
      
      <Concluir />
    </>
  );
}

const mapStateToProps = state => ({
  pericia: state.pericia,
  template: state.principal?.template,
  respostaConcluir: state.pericia.respostaConcluir,
  tiposGrauIncapacidade: state.principal.dominioPericia.tiposGrausIncapacidade,
  tiposGrauInvalidez: state.principal.dominioPericia.tiposGrausInvalidez
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validacaoCompletaPericia, downloadPericiaGenerico, alterarDCBGrauIncapacidade
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoPericiaRBI);