import { compararDatasSemHoras, converterDataFormatadaParaISOBr, formatarDateTimeISOToDataBr,
  isData1EhAntesOuIgualData2, isData1EhDepoisOuIgualData2 
} from '../../../../common/DataUtils';
import {
  DATA_REFERENCIA_EMPREGADO_DOMESTICO,
  ID_FILIACAO_EMPREGADO_DOMESTICO, SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE, SIGLA_CAMPO_PERICIA_PRESENCIAL, IDS_TIPOS_FILIACAO_NEXO_TECNICO
} from '../../constants';

const IDS_TIPOS_FILIACAO_NEXO_TECNICO_EPIDEMIOLOGICO = [1, 2, 3];//1=Empregado, 2=Trabalhador Avulso, 3=Empregado Doméstico
export const JUSTIFICATIVA_CARACTERIZACAO_AUTOMATICA_NTEP = "Nexo técnico epidemiológico caracterizado automaticamente conforme batimento sistêmico entre CID e CNAE,"
    +" em conformidade com o disposto na Lista C do Anexo II do Decreto n. 3.048, de 6 de maio de 1999."

export const isDataNoPeriodoVinculo = (data, vinculo) => {
  const dataInicioVinculo = converterDataFormatadaParaISOBr(vinculo.dataInicio);
  const dataFimVinculo = vinculo.dataFim && converterDataFormatadaParaISOBr(vinculo.dataFim);
  return data
    && isData1EhDepoisOuIgualData2(data, dataInicioVinculo) // data igual ou apos inicio do vinculo E
    && (!dataFimVinculo // vinculo aberto (nao tem data fim) 
      || isData1EhAntesOuIgualData2(data, dataFimVinculo) // OU data igual ou antes fim vinculo
    );
}

const isDIINoPeriodoDeGracaDoVinculo = (v, dii) => {
  if (v.dataFim && dii) {
    const dataFimVinculo = converterDataFormatadaParaISOBr(v.dataFim);
    let dataFimPeriodoGraca = new Date(dataFimVinculo.getTime());
    dataFimPeriodoGraca = new Date(dataFimPeriodoGraca.setFullYear(dataFimVinculo.getFullYear() + 1));
    return ((compararDatasSemHoras(dataFimVinculo, dii) < 1 && compararDatasSemHoras(dii, dataFimPeriodoGraca) < 1));
  }
  return false;
}

export const obterVinculosParaRelacaoNexoEpidemiologico = (vinculos, batimentoCidCnae, der, dii) => {
  const cnaesComBatimento = batimentoCidCnae.map(b => b.ntepId.codigoCNAE20);

  //regra de negocio: filtrando vinculos atendendo a regras de filiacao e batimento CID CNAE
  const vinculosFiltradosPorFiliacaoEBatimento = vinculos
    .filter(v =>
      IDS_TIPOS_FILIACAO_NEXO_TECNICO_EPIDEMIOLOGICO.includes(v.codigoTipoFiliado) //filiacao empregado ou empregado domestico
      && (v.codigoCnae && cnaesComBatimento.includes(v.codigoCnae)) //CNAE com batimento
    );

  //regra de negocio: filtrando vinculos atendendo a regra de data (aberto da DER)
  let vinculosEpidemiologico = vinculosFiltradosPorFiliacaoEBatimento
    .filter(v => compararDatasSemHoras(converterDataFormatadaParaISOBr(v.dataInicio), der) < 1 // data inicio menor ou igual a DER
      && (!v.dataFim || compararDatasSemHoras(der, converterDataFormatadaParaISOBr(v.dataFim)) < 1) // data fim nula ou DER menor que data fim
    );

  //regra de negocio: caso nao existam vinculos em aberto, selecionar vinculos fechados com DII na vigencia, se existir
  if (vinculosEpidemiologico?.length === 0) {
    vinculosEpidemiologico = vinculosFiltradosPorFiliacaoEBatimento.filter(v => v.dataFim && v.isDataNoPeriodoVinculo(dii, v));
  }

  //regra de negocio: caso nao existam vinculos em aberto na DER nem com DII na vigencia, selecionar ultimo vinculo fechado em periodo de graca, se existir
  if (vinculosEpidemiologico?.length === 0) {
    const vinculosPeriodoGraca = vinculosFiltradosPorFiliacaoEBatimento
      .filter(v => v.dataFim && isDIINoPeriodoDeGracaDoVinculo(v, dii))
      .sort((a, b) => compararDatasSemHoras(converterDataFormatadaParaISOBr(a.dataFim), converterDataFormatadaParaISOBr(b.dataFim)) > 0);

    vinculosEpidemiologico = vinculosPeriodoGraca && vinculosPeriodoGraca.length > 0 ? [vinculosPeriodoGraca[0]] : [];
  }

  return vinculosEpidemiologico;
}

export const isRespostaCamposGenericosNaoPermiteNexo = valoresCamposGenericos => {
  const campoIndicacaoPericiaPresencial = valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_PRESENCIAL);
  const campoComprovacaoIncapacidade = valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE);

  return (campoIndicacaoPericiaPresencial && campoIndicacaoPericiaPresencial.valorTextoCampoGenerico === '1') //indicacao pericia presencial
    || (campoComprovacaoIncapacidade && campoComprovacaoIncapacidade.valorTextoCampoGenerico === '0');//nao comprovou incapacidade
}


export const obterVinculosFiltradosPorTipoFiliacao = (vinculos, dii) => {
  //regra de negocio: filtrando vinculos nexo por tipo de filiacao
  let vinculosFiltrados = vinculos?.filter(v => v.codigoTipoFiliado && IDS_TIPOS_FILIACAO_NEXO_TECNICO.includes(v.codigoTipoFiliado));
  if (dii && compararDatasSemHoras(DATA_REFERENCIA_EMPREGADO_DOMESTICO, dii) === 1) {
    //DII anterior a 02/06/2015, deve desconsiderar vinculos com filiacao Empregado Domestico
    vinculosFiltrados = vinculosFiltrados.filter(v => v.codigoTipoFiliado !== ID_FILIACAO_EMPREGADO_DOMESTICO);
  }
  return vinculosFiltrados;
}

export const obterListaCnaesDeVinculos = vinculosPericia => {
  let listaCnaes = [];
  vinculosPericia && vinculosPericia.forEach(vinculo => {
    vinculo.codigoCnae && listaCnaes.push(vinculo.codigoCnae);
  });
  return listaCnaes;
}

export const obterLabelVinculoNexo = vinculo => {
  return `${vinculo.nomeVinculo !== undefined ? `${vinculo.nomeVinculo} -` : ''} Filiação: ${vinculo.descricaoTipoFiliado} - Início: ${formatarDateTimeISOToDataBr(vinculo.dataInicio)}` +
  ` - Fim: ${vinculo.dataFim ? formatarDateTimeISOToDataBr(vinculo.dataFim) : 'sem data'}`
  + (vinculo.dataFim 
    ? '' //se existe data fim, nao exibe ultima remuneracao
     : (vinculo.ultimaRemuneracao ? ` - Última Remuneração: ${vinculo.ultimaRemuneracao}` : '')); //so exibe ultima remuneracao se o valor estiver preenchido
}