import React from 'react'
import { calcularCaracteresRestantes } from '../../../../common/Utils'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import BrTextarea from '@govbr/react-components/lib/BrTextarea'
import { Grid } from '@material-ui/core';

import { alterarJustificativaAtividadeParticipacaoComValorZero } from '../../action'

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

const QTD_MAXIMA_CARACTERES = 4000;

const erroHadler = (validacao) =>{
  let erro = null;
  if (validacao) {
    erro = validacao.erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima ?  validacao.erroTamanhoRespostaDaJustificativaMenorQueQuantidadeMinima : erro;
    erro = validacao.erroJustificativaObrigatoriaGruposComValorDominioZerado ? validacao.erroJustificativaObrigatoriaGruposComValorDominioZerado : erro;
  }
  return erro;

}

const JustificativaDominiosZerados = (props) => {
  const label = `Não foram observadas dificuldades, ou as dificuldades no domínio como um todo são mínimas (entre 0 e 4%). Justifique:`;
  const isCampoTextareaDesabilitado = props.isSomenteLeitura;

  return (
    <Grid container alignItems="center">
      
        <Grid item xs>
          <BrTextarea id="justificativaAusenciaElementosAtividadeParticipacao"
          label={label}
          value={props.justificativaDominiosAtividadeParticipacaoValorZero}
          maxLength={QTD_MAXIMA_CARACTERES}
          onChange={props.alterarJustificativaAtividadeParticipacaoComValorZero}
          help={`Caracteres restantes: ${calcularCaracteresRestantes(props.justificativaDominiosAtividadeParticipacaoValorZero, QTD_MAXIMA_CARACTERES)}`}
          erro={erroHadler(props.validacao)} 
          disabled={isCampoTextareaDesabilitado}/>
        </Grid>
        {
          !isCampoTextareaDesabilitado &&
          <Grid item >
            <ButtonSelecionarItem
              id="selecionarItemDicionarioJustAusenciaElementosAtividadeParticipacao"
              onSelect={props.alterarJustificativaAtividadeParticipacaoComValorZero}
              maxLength={calcularCaracteresRestantes(props.justificativaDominiosAtividadeParticipacaoValorZero, QTD_MAXIMA_CARACTERES)}
              valorAtual={props.justificativaDominiosAtividadeParticipacaoValorZero}/>
          </Grid>
        }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  justificativaDominiosAtividadeParticipacaoValorZero: state.pericia.resultadoParcialBPC.justificativaDominiosAtividadeParticipacaoValorZero,
  validacao: state.principal.validacao.atividadesEParticipacao, 
  isSomenteLeitura: state.principal.isSomenteLeitura
})

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarJustificativaAtividadeParticipacaoComValorZero
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(JustificativaDominiosZerados)
