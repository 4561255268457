
import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const ID_OUTRA_CAUSA_DEFICIENCIA = 10;

export const verificarSeCamposPreenchidos = pericia => {

  const moduloImpedRiscoSocial = pericia?.moduloImpedRiscoSocial;

  const deveValidarImpedimentosERiscoSocial = moduloImpedRiscoSocial
    && (moduloImpedRiscoSocial.existeRiscoSocial
      || (moduloImpedRiscoSocial.causasDeficiencia && moduloImpedRiscoSocial.causasDeficiencia.length > 0));

  return deveValidarImpedimentosERiscoSocial;
}

export const validar = pericia => {

  const moduloImpedRiscoSocial = pericia?.moduloImpedRiscoSocial;

  const { causasDeficiencia, outraCausaDeficiencia, existeRiscoSocial, riscoSocial } = moduloImpedRiscoSocial;

  const erroCausaDeficiencia = !causasDeficiencia || causasDeficiencia.length === 0 ? "É obrigatório selecionar causa(s) da deficiência" : null;

  const isOutraCausaDeficiencia = causasDeficiencia && causasDeficiencia.filter(e => { return e.idTipoCausaDeficiencia === ID_OUTRA_CAUSA_DEFICIENCIA }).length > 0;
  const isOutraCausaPreenchido = outraCausaDeficiencia != null && outraCausaDeficiencia.trim().length > 0;

  const erroOutraCausaDeficiencia = isOutraCausaDeficiencia && !isOutraCausaPreenchido ? MSG_CAMPO_OBRIGATORIO : null;

  const isDescricaoRiscoPreenchido = riscoSocial != null && riscoSocial.trim().length > 0;
  const isDescricaoRiscoObrigatoria = existeRiscoSocial === 1;

  const erroRiscoSocial = isDescricaoRiscoObrigatoria && !isDescricaoRiscoPreenchido ? MSG_CAMPO_OBRIGATORIO : null;

  return {
    isValida: !erroCausaDeficiencia && !erroOutraCausaDeficiencia && !erroRiscoSocial,
    erroCausaDeficiencia,
    erroOutraCausaDeficiencia,
    erroRiscoSocial
  };
}