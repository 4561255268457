import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const styleButton = {
  backgroundColor: '#E3E8E3',
  borderRadius: 'unset'
}

const styleButtonSelected = {
  backgroundColor: '#EFCB84'
}

const styleButtonDisabled = {
  cursor: 'not-allowed',
  opacity: '0.8'

}

const ModuloFormularioResposta = (props) => {
  const { idPergunta, valor, respostas, handlerSelecionarResposta, disabled, permiteRespostaAutomatica } = props;

  const [selectedValue, setSelectedValue] = React.useState(valor != null ? valor.toString() : '');

  React.useEffect(() => {
    if (valor === undefined && !disabled && permiteRespostaAutomatica) {
      handlerSelecionarResposta(idPergunta, '0')
    }
  }, [valor, handlerSelecionarResposta, idPergunta, disabled, permiteRespostaAutomatica])

  React.useEffect(() => {
    setSelectedValue(valor != null ? valor.toString() : '')
  }, [valor])


  const handleChange = (value) => {
    setSelectedValue(value);
    handlerSelecionarResposta(idPergunta, value)
  };

  return (
    <ButtonGroup
      style={{ height: '5.5em' }}
      disableElevation
      fullWidth
      aria-label="outlined primary button group"
      disabled={disabled}>
      {
        respostas.map((item) => (
          <Button
            key={item}
            style={{
              ...styleButton,
              ...(disabled) ? styleButtonDisabled : {},
              ...(selectedValue === item) ? styleButtonSelected : {}
            }}
            title={item}
            onClick={() => handleChange(item)}

          >
            {(selectedValue === item) ?
              <Icon style={{ color: '#fff' }} className="fa fa-check" /> :
              <span style={{ opacity: '0.4' }}>{item}</span>
            }
          </Button>
        ))
      }
    </ButtonGroup>
  )
}


export default ModuloFormularioResposta;

