import { compararDatasSemHoras, formatarDateTimeISOToDataBr, validarData } from '../../../../common/DataUtils';
import { SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO, SIGLA_CAMPO_RESPOSTA_CONFORMACAO, TIPO_IDENT_EMITENTE_ATESTMED_CRM, TIPO_IDENT_EMITENTE_ATESTMED_RMS, tiposParametrosModuloEstatico, tiposPericia } from '../../constants';
import { obterValorParametroModuloEstatico } from '../../templatePericiaUtil';

const MSG_CAMPO_OBRIGATORIO = "Campo obrigatório";

export const verificarSeCamposPreenchidos = (pericia, getState)=> {
  if(pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo || pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SIBE.tipo) {
    return verificarSeCamposPreenchidosPericiaAtesmed(pericia, getState);
  }
  return verificarSeCamposPreenchidosPericiasInicialProrrogacoes(pericia);
}

const verificarSeCamposPreenchidosPericiasInicialProrrogacoes = pericia => {
  const moduloExameLaudo = pericia?.moduloExameLaudo;
  const deveValidarExamesLaudos = moduloExameLaudo
    && (moduloExameLaudo.naoExisteExameLaudo != null
      || (moduloExameLaudo.examesLaudo && moduloExameLaudo.examesLaudo.length > 0));

  return deveValidarExamesLaudos
}

export const verificaSeDocumentoAnexoObrigatorioInexistente = (pericia, template) => {
  const motivosNaoConformacaoSABI = obterValorParametroModuloEstatico(template, 
    tiposParametrosModuloEstatico.MOTIVO_NAO_CONFORMACAO_SEM_DOCUMENTO_ATESTMED_SABI.id);
  const motivosNaoConformacaoSIBE = obterValorParametroModuloEstatico(template, 
    tiposParametrosModuloEstatico.MOTIVO_NAO_CONFORMACAO_SEM_DOCUMENTO_ATESTMED_SIBE.id);
  const motivoNaoConformacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_MOTIVO_NAO_CONFORMACAO)?.valorTextoCampoGenerico;
  const conformacao = pericia.valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_RESPOSTA_CONFORMACAO)?.valorTextoCampoGenerico;
  const possuiExameLaudoAnexado = pericia.moduloExameLaudo?.examesLaudo?.length > 0
  const isPericiaAtestmedSABI = pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo;
  
  let documentoAnexoObrigatorioInexistente = false;
  let necessidadeDeAnexo = true

  if (motivosNaoConformacaoSABI || motivosNaoConformacaoSIBE) {
    let motivoNaoConformacaoSelecionadoObrigaAnexo = true
    if (isPericiaAtestmedSABI && motivosNaoConformacaoSABI) {
      motivoNaoConformacaoSelecionadoObrigaAnexo = !motivosNaoConformacaoSABI.trim().split(';').some(m => motivoNaoConformacao === m)
    } else if (motivosNaoConformacaoSIBE) {
      motivoNaoConformacaoSelecionadoObrigaAnexo = !motivosNaoConformacaoSIBE.trim().split(';').some(m => motivoNaoConformacao === m)
    }
    necessidadeDeAnexo = conformacao === undefined ? false : (conformacao !== '0' || motivoNaoConformacaoSelecionadoObrigaAnexo);

    documentoAnexoObrigatorioInexistente = !possuiExameLaudoAnexado && necessidadeDeAnexo

    return documentoAnexoObrigatorioInexistente;
  }
  
  return false;
}

const verificarSeCamposPreenchidosPericiaAtesmed = (pericia, getState) => {
  // TODO necessidade de manter o botão "validar e prosseguir" vinculado com esta função sem haver validação de fato (demandado por Eduardo)
  return verificaSeDocumentoAnexoObrigatorioInexistente(pericia, getState().principal?.template);
}

export const validar = (pericia, getState) => {
  if(pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo || pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SIBE.tipo) {
    return validarPericiaAtestmed(pericia, getState);
  }
  return validarPericiasInicialProrrogacoes(pericia);
}

const validarPericiasInicialProrrogacoes = pericia => {

  const moduloExameLaudo = pericia?.moduloExameLaudo;

  const { naoExisteExameLaudo, examesLaudo } = moduloExameLaudo;

  const erroRespostaObrigatoria = (!naoExisteExameLaudo && (!examesLaudo || examesLaudo.length === 0))
    ? "É obrigatório marcar opção se não existe(m) documento(s) apresentado(s)"
    : null;

  const erroOpcaoIncompativel = (naoExisteExameLaudo === 1 && examesLaudo && examesLaudo.length > 0)
    ? `Opção incompatível com ${examesLaudo.length} documento(s) apresentado(s). Desmarque a opção ou exclua o(s) documento(s) apresentado(s).`
    : null;

  return {
    isValida: !erroRespostaObrigatoria && !erroOpcaoIncompativel,
    erroNaoApresentouExames: erroRespostaObrigatoria ? erroRespostaObrigatoria : erroOpcaoIncompativel
  };
}

const validarPericiaAtestmed = (pericia, getState) => {
  return {
    isValida: !verificaSeDocumentoAnexoObrigatorioInexistente(pericia, getState().principal?.template)
  };
}

export const obterDadosValidacao = (documMedOdont, tiposIdentEmitentePericiaBI) => {
  const { dataDocumento, nomeProfissional, 
    idTipoIdentEmitente, numeroDocumento, idUFDocumento, dadosDoMedico } = documMedOdont;

  const erroDataObrigatoria = !dataDocumento ? MSG_CAMPO_OBRIGATORIO : null;
  const erroDataInvalida = dataDocumento && !validarData(formatarDateTimeISOToDataBr(dataDocumento))
    ? "Data inválida"
    : null;

  const erroDataMaiorQueAtual = dataDocumento && compararDatasSemHoras(new Date(dataDocumento), new Date()) > 0
    ? "A Data do Documento deve ser anterior ou igual à data atual."
    : null;

  const erroTipoEmitente = (idTipoIdentEmitente === undefined || idTipoIdentEmitente === null
    || !tiposIdentEmitentePericiaBI.find(t => t.id === idTipoIdentEmitente)) ? 
    MSG_CAMPO_OBRIGATORIO : null;

  const erroNumeroDocumentoObrigatorio = !numeroDocumento ? MSG_CAMPO_OBRIGATORIO : null;
  const erroNomeProfissional = (!nomeProfissional || nomeProfissional.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;

  const erroUf = idTipoIdentEmitente && idTipoIdentEmitente !== TIPO_IDENT_EMITENTE_ATESTMED_RMS && (idUFDocumento === undefined || idUFDocumento === null) 
    ? MSG_CAMPO_OBRIGATORIO 
    : null;

  const erroDadosDoMedicoNaoEncontrado = 
      idTipoIdentEmitente === TIPO_IDENT_EMITENTE_ATESTMED_CRM 
      && numeroDocumento 
      && idUFDocumento 
      && !dadosDoMedico ? 
    "Não é possível incluir o documento, pois os dados do médico não foram encontrados na base do CFM" 
    : null;

  return {
    isValida: !erroDataObrigatoria && !erroDataInvalida && !erroDataMaiorQueAtual && !erroTipoEmitente &&
      !erroNumeroDocumentoObrigatorio && !erroUf && !erroNomeProfissional && !erroDadosDoMedicoNaoEncontrado,
    erroDataDocumento: erroDataObrigatoria
      || (erroDataInvalida || erroDataMaiorQueAtual),
    erroTipoEmitente,
    erroNomeProfissional,
    erroNumeroDocumento: erroNumeroDocumentoObrigatorio,
    erroUf,
    erroDadosDoMedicoNaoEncontrado
  };
}