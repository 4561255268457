import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import HeaderSecao from '../../components/HeaderSecao';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrTable from '@govbr/react-components/lib/BrTable';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { format } from "date-fns";
import { tiposPericia } from '../pericia/constants';
import { MASCARA_CPF, MASCARA_NB } from '../../common/formatadores';

import { alterarCpfTitular, alterarNb, alterarProtocoloInss, alterarProtocoloPmf, limpar, pesquisar } from './action';
import { limparMensagem, limparTemplate } from '../principal/action';
import { limparDadosPericia } from '../pericia/action';

const ConsultaPericias = (props) => {
  const { filtro, listaFiltrada } = props.dados;

  const cpfTitularClicadoNoPainelComunicacoes = localStorage.getItem('cpfTitular');
  const protocoloPmfClicadoNoPainelComunicacoes = localStorage.getItem('protocoloPmf');

  useEffect(() => {
    //remover qualquer mensagem de erro/sucesso que possa existir
    props.limparMensagem();
  
    
    //remover dados pericia, retirando mensagens de alerta da tela
    props.limparDadosPericia();
    props.limparTemplate();

    
    if (cpfTitularClicadoNoPainelComunicacoes){
      props.alterarCpfTitular(cpfTitularClicadoNoPainelComunicacoes);
      const filtroComCpf = {cpfTitular : cpfTitularClicadoNoPainelComunicacoes};
      props.pesquisar(filtroComCpf); 
      localStorage.removeItem('cpfTitular');
    }
    
    if (protocoloPmfClicadoNoPainelComunicacoes){
      props.alterarProtocoloPmf(protocoloPmfClicadoNoPainelComunicacoes);
      const filtroComPmf = {protocoloPmf : protocoloPmfClicadoNoPainelComunicacoes};
      props.pesquisar(filtroComPmf);
      localStorage.removeItem('protocoloPmf');
    }
    
    // eslint-disable-next-line
  }, []);//deve executar apenas a primeira vez que o componente for renderizado

  return (
    <div id="tela-consulta-pericias" className="col table_header_zIndex_0">

      <HeaderSecao texto="Consulta  Gerencial Pericias" />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <BrInput id="cpfTitular" label="CPF" mode="number" mask={MASCARA_CPF} value={filtro.cpfTitular} onChange={props.alterarCpfTitular} />
        </Grid>
        <Grid item xs={3}>
          <BrInput id="protocoloInss" label="Protocolo PAT" mode="number" value={filtro.protocoloInss} onChange={props.alterarProtocoloInss} />
        </Grid>
        <Grid item xs={3}>
          <BrInput id="protocoloPmf" label="Protocolo PMF" mode="number" value={filtro.protocoloPmf} onChange={props.alterarProtocoloPmf} />
        </Grid>
        <Grid item xs={3}>
          <BrInput id="nb" label="NB" mode="number" mask={MASCARA_NB} value={filtro.nb} onChange={props.alterarNb} />
        </Grid>
      </Grid>

      <Box display="flex" p={2} position="sticky" top={0} bgcolor="#fff" mt={1} justifyContent="flex-end">
        <BrButton id="btnLimpar" secondary className="mr-2" onClick={props.limpar}>
          <i className="fas fa-eraser"></i>
          Limpar Campos
        </BrButton>
        <BrButton id="btnPesquisar" primary onClick={() => props.pesquisar(filtro)}>
          <i className={"fas fa-search"} />
          Pesquisar
        </BrButton>
      </Box>

      {listaFiltrada && listaFiltrada.length > 0 &&
        < BrTable
          id="tabelaPericias"
          title="Perícias"
          layout="fixed" 
          columns={[{ Header: "Tipo", accessor: "tipo" }, { Header: "CPF", accessor: "cpf" },
          { Header: "DER", accessor: "der" }, { Header: "Protocolo PAT", accessor: "protocoloInss" },
          { Header: "Protocolo PMF", accessor: "protocoloPmf" }, { Header: "Situação", accessor: "situacao" },
          { Header: "NB", accessor: "nb" }, { Header: "Data Criação", accessor: "dataCriacao" },
          { Header: "Data Última alteração", accessor: "dataUltimaAlteracao" }, { Header: "Ação", accessor: "acao" }]}
          pagination={true}
          data={listaFiltrada.map(item => ({
            tipo: item.tipoPericia ? tiposPericia[item.tipoPericia].fase : "--",
            der: item.dataReferencia ? `${format(Date.parse(item.dataReferencia), "dd/MM/yyyy HH:mm:ss")}` : "--",
            cpf: item.avaliado.cpf,
            protocoloPmf: item.protocoloPMF || "--",
            protocoloInss: item.protocoloINSS || "--",
            dataCriacao: item.dataCriacao ? `${format(Date.parse(item.dataCriacao), "dd/MM/yyyy HH:mm:ss")}` : "--",
            dataUltimaAlteracao: item.dataAlteracao ? `${format(Date.parse(item.dataAlteracao), "dd/MM/yyyy HH:mm:ss")}` : "--",
            situacao: item.situacao?.descricao,
            nb: item.numeroBeneficio || "--",
            acao:
              <>
                <Link id="consultarPericia" to={`/consulta/pericia/detalhe?id=${item.id}`} target="_blank">
                  <i className={`fas fa-eye`} title='Consultar' />
                </Link>
                {/* TODO: incluir qdo for definido quais campos podem ser alterados
              <BrButton id="alterarDados" circle>
                <i className={`fas fa-hammer`} title='Alterar Dados processo' />
              </BrButton> */}
              </>
          }))}
          headerProps={{
            show: true,
            showDensityOptions: false,
            searchProp: {
              show: false
            }
          }}
          footerProps={{ show: false }}

        />
      }

      {
        listaFiltrada && listaFiltrada.length === 0
        && (
          <center className="texto-informativo red">
            Nenhuma perícia encontrada.
          </center>
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  dados: state.painelGerencial
})

const mapDispatchToProps = {
  alterarProtocoloPmf,
  alterarProtocoloInss,
  alterarCpfTitular,
  alterarNb,
  limpar,
  pesquisar,
  limparMensagem,
  limparDadosPericia,
  limparTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaPericias);