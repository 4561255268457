import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {
  const moduloEstruturaPrognostico = pericia?.moduloEstruturaPrognostico;

  const deveValidarEstruturaEPrognostico = moduloEstruturaPrognostico
    && (moduloEstruturaPrognostico.alteracaoEstruturaCorpo != null || moduloEstruturaPrognostico.tipoProgDesfavoravel != null
      || moduloEstruturaPrognostico.idTipoDuracaoAltConstatada != null);

  return deveValidarEstruturaEPrognostico;
}

export const validar = pericia => {

  const moduloEstruturaPrognostico = pericia?.moduloEstruturaPrognostico;

  const { alteracaoEstruturaCorpo, estruturasCorpo, tipoProgDesfavoravel,
    idTipoDuracaoAltConstatada, descricaoAltConstada } = moduloEstruturaPrognostico;

  const erroAlteracaoEstruturaCorpo = alteracaoEstruturaCorpo == null ? MSG_CAMPO_OBRIGATORIO : null;

  const existeAlteracaoEstruturas = alteracaoEstruturaCorpo === 1;
  const existeEstruturaSelecionada = existeAlteracaoEstruturas && estruturasCorpo && estruturasCorpo.length > 0;

  const erroEstruturasCorpo = existeAlteracaoEstruturas && !existeEstruturaSelecionada ? "É obrigatório selecionar estrutura(s) alterada(s)" : null;
  const erroPrognostico = tipoProgDesfavoravel == null ? MSG_CAMPO_OBRIGATORIO : null;
  const erroDuracaoAlteracao = idTipoDuracaoAltConstatada == null ? MSG_CAMPO_OBRIGATORIO : null;

  const isRespostaExigeJustificativa = idTipoDuracaoAltConstatada === 2;
  const isJustificativaDuracaoPreenchido = descricaoAltConstada != null && descricaoAltConstada.trim().length > 0;

  const erroJustificativaDuracao = isRespostaExigeJustificativa && !isJustificativaDuracaoPreenchido ? MSG_CAMPO_OBRIGATORIO : null;

  return {
    isValida: !erroAlteracaoEstruturaCorpo && !erroEstruturasCorpo && !erroPrognostico
      && !erroDuracaoAlteracao && !erroJustificativaDuracao,
    erroAlteracaoEstruturaCorpo,
    erroEstruturasCorpo,
    erroPrognostico,
    erroDuracaoAlteracao,
    erroJustificativaDuracao
  };
}