import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#D7DED7',
    height: '100%',
    fontWeight: 'bold',
    borderRadius: 'unset'
  },
  textPrimary: {
    backgroundColor: '#7BAE94',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#7BAE94'
    }
  }
})


const ModuloFormularioRespostaGrupo = (props) => {
  const { idGrupo, valor, respostas, disabled, handlerSelecionarResposta, permiteRespostaAutomatica } = props;

 
  React.useEffect(() => {
    if (valor === undefined && !disabled && permiteRespostaAutomatica) {
      handlerSelecionarResposta(idGrupo, '0')
    }
  }, [valor, handlerSelecionarResposta, idGrupo, disabled, permiteRespostaAutomatica])


  const [selectedValue, setSelectedValue] = React.useState(valor != null ? valor.toString() : '');

  const handleChange = (value) => {
    let valor = value;
    if (selectedValue === valor) {
      valor = undefined;
    }
    setSelectedValue(valor);
    handlerSelecionarResposta(idGrupo, valor)
  };

  const classes = useStyles();

  useEffect(() => {
    setSelectedValue(valor != null ? valor.toString() : '')
  }, [valor])

  return (
      <ButtonGroup 
        style={{ height: '5.5em'}} 
        disableElevation 
        fullWidth 
        aria-label="outlined primary button group"
        disabled={disabled}>
        {
          respostas.map((item) => (
            <Button key={item}
              className={clsx(classes.root, selectedValue === item && classes.textPrimary)}
              onClick={() => handleChange(item)}
            >
              {item}
            </Button>
          ))
        }
      </ButtonGroup>
  )
}


export default ModuloFormularioRespostaGrupo;

