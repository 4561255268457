import { obterValorCampoEstaticoRegraExibicao } from "../../../../common/CampoGenericoUtils";
import { tipoCampoExibicaoPericia } from "../../constants";

export const TIPOS_GRAU_INCAPACIDADE_RBI = Object.freeze({
  TEMPORARIA_COM_RECUPERACAO: 1,
  TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL: 3,
  DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL: 4
});

export const TIPOS_GRAU_INVALIDEZ_RBI = Object.freeze({
  MANTEM_INVALIDEZ: 5,
  ELEGIVEL_REABILITACAO_PROFISSIONAL: 6
});

export const tipoFluxoRBI = Object.freeze({
  FLUXO_BENEFICIOS_INCAPACIDADE: ['31', '91'],
  FLUXO_BENEFICIOS_INVALIDEZ: ['32', '92']
});

export const obtemFluxoRBI = (pericia) => {
  const numeroEspecieBeneficioTemplate = obterValorCampoEstaticoRegraExibicao(tipoCampoExibicaoPericia.NUMERO_ESPECIE_BENEFICIO, pericia);
  return tipoFluxoRBI.FLUXO_BENEFICIOS_INCAPACIDADE.includes(numeroEspecieBeneficioTemplate)
    ? tipoFluxoRBI.FLUXO_BENEFICIOS_INCAPACIDADE
    : tipoFluxoRBI.FLUXO_BENEFICIOS_INVALIDEZ.includes(numeroEspecieBeneficioTemplate)
      ? tipoFluxoRBI.FLUXO_BENEFICIOS_INVALIDEZ
      : undefined
}