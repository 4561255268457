import { obterTextoResultadoCapacidadeLaborativa, obterlabelVinculo } from "../common/PericiaBiUtil";
import { obterLabelVinculoNexo } from "../features/pericia/modulos/NexoTecnico/utils";
import { formatarSimNaoBooleano } from "../common/formatadores";
import {
  SIGLA_CAMPO_CID_ALTERADO,
  SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS,
  SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS_2,
  SIGLA_CAMPO_DID, SIGLA_CAMPO_DII,
  SIGLA_CAMPO_ECTOSCOPIA,
  SIGLA_CAMPO_GENERICO_PRORROGA,
  SIGLA_CAMPO_HISTORIA_CLINICA,
  SIGLA_CAMPO_JUSTIFICATIVA_DID,
  SIGLA_CAMPO_JUSTIFICATIVA_DII,
  SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE,
  tiposNexoTecnico
} from "../features/pericia/constants";
import { convertStringISOToDateWithTimezonePT_BR, formatarDateTimeISOToDataBr } from "./DataUtils";

import {
  obterNumeroComUf,
  obterTipoExameLaudo,
  obterTipoIdentificacao
} from "../features/pericia/modulos/ExamesELaudos/utils";

export const checarAlteracoesRevisao = (pericia, dominios) => {

  const periciaOrigem = pericia.periciaOrigemRevisao;
  const periciaRevisao = pericia;
  const { tiposExameLaudo, tiposIdentificacaoEmitente, ufs, tiposGrauIncapacidade, dominioSistemasCorpo, tiposDoencaIsencao,
    listaEnquadAuxAcid, listaSitEnquadAuxAcid, listaMotivosIsencaoIr, listaMotivosMajoracao25,
    tiposDoencaNexoProfissional, tiposNexoIndividual, fatoresRiscoCid, motivosNaoFixacaoNtep } = dominios;
  const informacoesAlteradas = [];

  /// Historia Clinica
  const campoGenericoHistoriaOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_HISTORIA_CLINICA);
  const campoGenericoHistoriaRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_HISTORIA_CLINICA);

  if (isValorStringAlterado(campoGenericoHistoriaOrigem[0]?.valorTextoCampoGenerico, campoGenericoHistoriaRevisao[0]?.valorTextoCampoGenerico)) {
    informacoesAlteradas.push({
      nomeCampo: 'História Clínica',
      valorOrigem: campoGenericoHistoriaOrigem[0]?.valorTextoCampoGenerico || "---",
      valorAtual: campoGenericoHistoriaRevisao[0]?.valorTextoCampoGenerico || "---",
      ordem: 1,
      tabela: false
    });
  }

  // exames e laudos
  const examesLaudosOrigem = periciaOrigem.moduloExameLaudo?.examesLaudo;
  const examesLaudosRevisao = periciaRevisao.moduloExameLaudo?.examesLaudo;

  const examesLaudosOrigemFormatados = examesLaudosOrigem?.map(exame => {
    exame.tipoExameFormatado = obterTipoExameLaudo(exame.idTipoExameLaudo, tiposExameLaudo);
    exame.tipoEmitenteFormatado = obterTipoIdentificacao(exame.idTipoIdentEmitente, tiposIdentificacaoEmitente);
    exame.ufFormatada = obterNumeroComUf(exame, ufs);
    exame.dataDocumento = convertStringISOToDateWithTimezonePT_BR(exame.dataDocumento)
    return exame;
  });

  const examesLaudosRevisaoFormatados = examesLaudosRevisao?.map(exame => {
    exame.tipoExameFormatado = obterTipoExameLaudo(exame.idTipoExameLaudo, tiposExameLaudo);
    exame.tipoEmitenteFormatado = obterTipoIdentificacao(exame.idTipoIdentEmitente, tiposIdentificacaoEmitente);
    exame.ufFormatada = obterNumeroComUf(exame, ufs);
    exame.tipoIdentEmitente = exame.idTipoIdentEmitente;
    exame.dataDocumento = convertStringISOToDateWithTimezonePT_BR(exame.dataDocumento)
    return exame;
  });

  const examesLaudosOrigemSomenteTipoExame = examesLaudosOrigem?.filter(e => e.idTipoExameLaudo === 4);
  const examesLaudosOrigemSemTipoExame = examesLaudosOrigem?.filter(e => e.idTipoExameLaudo !== 4);
  const examesLaudosRevisaoSomenteTipoExame = examesLaudosRevisao?.filter(e => e.idTipoExameLaudo === 4);
  const examesLaudosRevisaoSemTipoExame = examesLaudosRevisao?.filter(e => e.idTipoExameLaudo !== 4);

  let isExisteDiferencaListaSomenteExame = false;
  let isExisteDiferencaListaSemExame = false;

  if (isListaAlterada(examesLaudosOrigemSomenteTipoExame?.map(e => ({ ...e, idExameLaudo: '', idModuloExameLaudo: '' })), //limpando ids, para nao ser usados na comparacao
    examesLaudosRevisaoSomenteTipoExame?.map(e => ({ ...e, idExameLaudo: '', idModuloExameLaudo: '' })), //limpando ids, para nao ser usados na comparacao
    null, (itemAtual, itemAnterior) => itemAtual['descricaoLaudo'] === itemAnterior['descricaoLaudo']
      && !(itemAtual['dataDocumento'] > itemAnterior['dataDocumento'])
      && !(itemAtual['dataDocumento'] < itemAnterior['dataDocumento']))) {
    isExisteDiferencaListaSomenteExame = true;
  }

  if (isListaAlterada(examesLaudosOrigemSemTipoExame?.map(e => ({ ...e, idExameLaudo: '', idModuloExameLaudo: '' })), //limpando ids, para nao ser usados na comparacao
    examesLaudosRevisaoSemTipoExame?.map(e => ({ ...e, idExameLaudo: '', idModuloExameLaudo: '' })), //limpando ids, para nao ser usados na comparacao
    null, (itemAtual, itemAnterior) => itemAtual['numeroDocumento'] === itemAnterior['numeroDocumento']
      && !(itemAtual['dataDocumento'] > itemAnterior['dataDocumento'])
      && !(itemAtual['dataDocumento'] < itemAnterior['dataDocumento']))) {
    isExisteDiferencaListaSemExame = true;
  }

  if (isExisteDiferencaListaSomenteExame || isExisteDiferencaListaSemExame) {
    informacoesAlteradas.push({
      nomeCampo: 'Exames/Laudos',
      valorOrigem: examesLaudosOrigemFormatados,
      valorAtual: examesLaudosRevisaoFormatados,
      ordem: 2,
      tabela: true
    });
  }

  ///Exame Fisico

  // ectoscopia
  const ectoscopiaOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_ECTOSCOPIA);
  const ectoscopiaRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_ECTOSCOPIA);

  if (isValorStringAlterado(ectoscopiaOrigem[0]?.valorTextoCampoGenerico, ectoscopiaRevisao[0]?.valorTextoCampoGenerico)) {

    informacoesAlteradas.push({
      nomeCampo: 'Ectoscopia',
      valorOrigem: ectoscopiaOrigem[0]?.valorTextoCampoGenerico || "---",
      valorAtual: ectoscopiaRevisao[0]?.valorTextoCampoGenerico || "---",
      ordem: 3
    });
  }

  //sistemas/segmentos do corpo examinados
  const examesSistemaOrigem = periciaOrigem.examesSistemaCorpo;
  const examesSistemaRevisao = periciaRevisao.examesSistemaCorpo;

  if (isListaAlterada(examesSistemaOrigem?.map(e => ({ ...e, id: '' })), //limpando id, para nao ser usados na comparacao
    examesSistemaRevisao?.map(e => ({ ...e, id: '' })), //limpando id, para nao ser usados na comparacao
    'idSistema')) {

    informacoesAlteradas.push({
      nomeCampo: 'Exame',
      valorOrigem: examesSistemaOrigem?.map(e => ({ ...e, sistema: dominioSistemasCorpo?.find(s => s.id === e.idSistema)?.nomeDoSistema })),
      valorAtual: examesSistemaRevisao?.map(e => ({ ...e, sistema: dominioSistemasCorpo?.find(s => s.id === e.idSistema)?.nomeDoSistema })),
      ordem: 4,
      tabela: true
    });
  }

  ///Diagnostico

  //campo alteracao CID
  const campoAlteracaoCidOrigem = periciaOrigem.valoresCamposGenericos?.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO)?.valorTextoCampoGenerico;
  const campoAlteracaoCidRevisao = periciaRevisao.valoresCamposGenericos?.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO)?.valorTextoCampoGenerico;

  const isCidAlteradoOrigem = campoAlteracaoCidOrigem && campoAlteracaoCidOrigem === "1";
  const isCidAlteradoRevisao = campoAlteracaoCidRevisao && campoAlteracaoCidRevisao === "1";

  if (isValorAlterado(campoAlteracaoCidOrigem, campoAlteracaoCidRevisao)) {

    informacoesAlteradas.push({
      nomeCampo: 'CID Principal Alterado',
      valorOrigem: formatarSimNaoBooleano(parseInt(campoAlteracaoCidOrigem)) || "---",
      valorAtual: formatarSimNaoBooleano(parseInt(campoAlteracaoCidRevisao)) || "---",
      ordem: 5
    });
  }

  // CID
  const cidOrigemMantido = campoAlteracaoCidOrigem && campoAlteracaoCidOrigem === "0";
  const campoCIDOrigem = cidOrigemMantido ? periciaOrigem.periciaAnterior?.codigoCID : periciaOrigem.moduloCid?.cidPrincipal;
  const campoNomeCIDOrigem = cidOrigemMantido ? periciaOrigem.periciaAnterior?.descricaoCID : periciaOrigem.moduloCid?.nomeCidPrincipal;

  const cidRevisaoMantido = campoAlteracaoCidRevisao && campoAlteracaoCidRevisao === "0";
  const campoCIDRevisao = cidRevisaoMantido ? periciaRevisao.periciaAnterior?.codigoCID : periciaRevisao.moduloCid?.cidPrincipal;
  const campoNomeCIDRevisao = cidRevisaoMantido ? periciaRevisao.periciaAnterior?.descricaoCID : periciaRevisao.moduloCid?.nomeCidPrincipal;

  if (isValorStringAlterado(campoCIDOrigem, campoCIDRevisao)) {

    informacoesAlteradas.push({
      nomeCampo: 'CID Principal',
      valorOrigem: campoCIDOrigem ? (campoCIDOrigem + ' - ' + campoNomeCIDOrigem) : "---",
      valorAtual: campoCIDRevisao ? (campoCIDRevisao + ' - ' + campoNomeCIDRevisao) : "---",
      ordem: 6
    });
  }

  //cids secundarios
  const cidsSecundariosOrigem = periciaOrigem.moduloCid?.cidsSecundarios;
  const cidsSecundariosRevisao = periciaRevisao.moduloCid?.cidsSecundarios;

  if (isListaAlterada(cidsSecundariosOrigem?.map(c => ({ ...c, idCidSecundario: '', idModuloCID: '' })), //limpando ids, para nao ser usados na comparacao
    cidsSecundariosRevisao?.map(c => ({ ...c, idCidSecundario: '', idModuloCID: '' })), //limpando ids, para nao ser usados na comparacao
    'cidSecundario')) {

    informacoesAlteradas.push({
      nomeCampo: 'CIDs Secundários',
      valorOrigem: cidsSecundariosOrigem,
      valorAtual: cidsSecundariosRevisao,
      ordem: 7,
      tabela: true
    });
  }

  // isenção de carencia por gestação de alto risco
  const campoIsencaoGestacaoRiscoOrigem = periciaOrigem.moduloIsencaoCarencia?.isentaCarenciaPorGestacaoRisco;
  const campoIsencaoGestacaoRiscoRevisao = periciaRevisao.moduloIsencaoCarencia?.isentaCarenciaPorGestacaoRisco;

  if (isValorAlterado(campoIsencaoGestacaoRiscoOrigem, campoIsencaoGestacaoRiscoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Isenção de Carência por Gestação de Alto Risco',
      valorOrigem: formatarSimNaoBooleano(campoIsencaoGestacaoRiscoOrigem) || "---",
      valorAtual: formatarSimNaoBooleano(campoIsencaoGestacaoRiscoRevisao) || "---",
      ordem: 8
    });
  }

  // justificativa isenção de carencia por gestação de alto risco
  const campoJustIsencaoGestacaoRiscoOrigem = periciaOrigem.moduloIsencaoCarencia?.justificativaGestacaoAltoRisco;
  const campoJustIsencaoGestacaoRiscoRevisao = periciaRevisao.moduloIsencaoCarencia?.justificativaGestacaoAltoRisco;

  if (isValorAlterado(campoJustIsencaoGestacaoRiscoOrigem, campoJustIsencaoGestacaoRiscoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Justificativa de Isenção de Carência por Gestação de Alto Risco',
      valorOrigem: campoJustIsencaoGestacaoRiscoOrigem || "---",
      valorAtual: campoJustIsencaoGestacaoRiscoRevisao || "---",
      ordem: 9
    });
  }

  // isenção de carencia
  const campoIsencaoOrigem = periciaOrigem.moduloIsencaoCarencia?.isentaCarencia;
  const campoIsencaoRevisao = periciaRevisao.moduloIsencaoCarencia?.isentaCarencia;

  if (isValorAlterado(campoIsencaoOrigem, campoIsencaoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Isenção de Carência',
      valorOrigem: formatarSimNaoBooleano(campoIsencaoOrigem) || "---",
      valorAtual: formatarSimNaoBooleano(campoIsencaoRevisao) || "---",
      ordem: 10
    });
  }

  // justificativa isenção de carencia
  const campoJustIsencaoOrigem = periciaOrigem.moduloIsencaoCarencia?.tipoDoencaIsencao;
  const campoJustIsencaoRevisao = periciaRevisao.moduloIsencaoCarencia?.tipoDoencaIsencao;
  
  const isCampoJustificativaIsencaoVisivel = periciaRevisao.moduloIsencaoCarencia?.isentaCarencia === 1;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCampoJustificativaIsencaoVisivel && isValorAlterado(campoJustIsencaoOrigem, campoJustIsencaoRevisao))
        || (campoJustIsencaoOrigem !== undefined && campoJustIsencaoOrigem !== null && !isCampoJustificativaIsencaoVisivel)) {
    informacoesAlteradas.push({
      nomeCampo: 'Condição Legal de Isenção de Carência',
      valorOrigem: tiposDoencaIsencao?.find(o => o.id === campoJustIsencaoOrigem)?.descricao || "---",
      valorAtual: isCampoJustificativaIsencaoVisivel ? 
        tiposDoencaIsencao?.find(o => o.id === campoJustIsencaoRevisao)?.descricao || "---" //valor preenchido atualmente na pericia
        : "---",//campo esta oculto, deve exibir valor nulo
      ordem: 11
    });
  }

  /// Capacidade Laborativa 

  //Houve/nao houve comprovacao da incapacidade
  const campoGenericoCOMPINCOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE);
  const campoGenericoCOMPINCRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE);

  const valorCampoGenericoCOMPINCOrigem = campoGenericoCOMPINCOrigem[0]?.valorTextoCampoGenerico;
  const valorCampoGenericoCOMPINCRevisao = campoGenericoCOMPINCRevisao[0]?.valorTextoCampoGenerico;

  const prorrogacaoOrigem = periciaOrigem.valoresCamposGenericos?.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_GENERICO_PRORROGA)?.valorTextoCampoGenerico;
  const prorrogacaoRevisao = periciaRevisao.valoresCamposGenericos?.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_GENERICO_PRORROGA)?.valorTextoCampoGenerico;

  const houveComprovacaoIncapacidadeRevisao = valorCampoGenericoCOMPINCRevisao && valorCampoGenericoCOMPINCRevisao === "1";
  const houveProrrogacaoRevisao = prorrogacaoRevisao && prorrogacaoRevisao === "1";

  const capacidadeLaborativaOrigem = obterTextoResultadoCapacidadeLaborativa(valorCampoGenericoCOMPINCOrigem, campoAlteracaoCidOrigem, prorrogacaoOrigem);
  const capacidadeLaborativaRevisao = obterTextoResultadoCapacidadeLaborativa(valorCampoGenericoCOMPINCRevisao, campoAlteracaoCidRevisao, prorrogacaoRevisao);

  if (isValorAlterado(capacidadeLaborativaOrigem, capacidadeLaborativaRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Capacidade Laborativa',
      valorOrigem: capacidadeLaborativaOrigem || "---",
      valorAtual: capacidadeLaborativaRevisao || "---",
      ordem: 12
    });
  }

  // Considerações Médico Periciais
  const campoCMPOrigem = isCidAlteradoOrigem
    ? periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS_2)
    : periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS);

  const campoCMPRevisao = isCidAlteradoRevisao
    ? periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS_2)
    : periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_CONSIDERACOES_MEDICO_PERICIAIS);

  if (isValorStringAlterado(campoCMPOrigem[0]?.valorTextoCampoGenerico, campoCMPRevisao[0]?.valorTextoCampoGenerico)) {

    informacoesAlteradas.push({
      nomeCampo: 'Considerações Médico Periciais',
      valorOrigem: campoCMPOrigem[0]?.valorTextoCampoGenerico || "---",
      valorAtual: campoCMPRevisao[0]?.valorTextoCampoGenerico || "---",
      ordem: 13
    });
  }

  // Agravamento de Lesão

  const agravamentoLesaoOrigem = periciaOrigem.moduloRestabelecimentoBeneficio?.existeAgravamento;
  const agravamentoLesaoRevisao = periciaRevisao.moduloRestabelecimentoBeneficio?.existeAgravamento;

  if (isValorAlterado(agravamentoLesaoOrigem, agravamentoLesaoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Agravamento de Lesão',
      valorOrigem: formatarSimNaoBooleano(agravamentoLesaoOrigem) || "---",
      valorAtual: formatarSimNaoBooleano(agravamentoLesaoRevisao) || "---",
      ordem: 14
    });
  }
  // Restabelecimento de Benefício

  const restabelecimentoOrigem = periciaOrigem.moduloRestabelecimentoBeneficio?.existeRestabelecimento;
  const restabelecimentoRevisao = periciaRevisao.moduloRestabelecimentoBeneficio?.existeRestabelecimento;

  if (isValorAlterado(restabelecimentoOrigem, restabelecimentoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Restabelecimento de Benefício',
      valorOrigem: formatarSimNaoBooleano(restabelecimentoOrigem) || "---",
      valorAtual: formatarSimNaoBooleano(restabelecimentoRevisao) || "---",
      ordem: 15
    });
  }

  // DID
  const campoGenericoDIDOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_DID);
  const campoGenericoDIDRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_DID);

  const valorCampoGenericoDIDOrigem = campoGenericoDIDOrigem[0]?.valorTextoCampoGenerico;
  const valorCampoGenericoDIDRevisao = campoGenericoDIDRevisao[0]?.valorTextoCampoGenerico;

  const isCamposDIDDIIVinculosENexoVisiveis = houveComprovacaoIncapacidadeRevisao || isCidAlteradoRevisao;

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposDIDDIIVinculosENexoVisiveis && isValorStringAlterado(valorCampoGenericoDIDOrigem, valorCampoGenericoDIDRevisao))
      || (valorCampoGenericoDIDOrigem?.trim().length > 0 && !isCamposDIDDIIVinculosENexoVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'DID',
      valorOrigem: valorCampoGenericoDIDOrigem || "---",
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? valorCampoGenericoDIDRevisao || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor nulo
      ordem: 16
    });
  }

  // Justificativa DID
  const campoJDIDOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_JUSTIFICATIVA_DID);
  const campoJDIDRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_JUSTIFICATIVA_DID);

  const valorCampoJDIDOrigem = campoJDIDOrigem[0]?.valorTextoCampoGenerico;
  const valorCampoJDIDRevisao = campoJDIDRevisao[0]?.valorTextoCampoGenerico;

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposDIDDIIVinculosENexoVisiveis && isValorStringAlterado(valorCampoJDIDOrigem, valorCampoJDIDRevisao))
      || (valorCampoJDIDOrigem?.trim().length > 0 && !isCamposDIDDIIVinculosENexoVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Justificativa DID',
      valorOrigem: valorCampoJDIDOrigem || "---",
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? valorCampoJDIDRevisao || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor nulo
      ordem: 17
    });
  }

  // DII
  const campoGenericoDIIOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_DII);
  const campoGenericoDIIRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_DII);

  const valorCampoGenericoDIIOrigem = campoGenericoDIIOrigem[0]?.valorTextoCampoGenerico;
  const valorCampoGenericoDIIRevisao = campoGenericoDIIRevisao[0]?.valorTextoCampoGenerico;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDIDDIIVinculosENexoVisiveis && isValorStringAlterado(valorCampoGenericoDIIOrigem, valorCampoGenericoDIIRevisao))
    || (valorCampoGenericoDIIOrigem?.trim().length > 0 && !isCamposDIDDIIVinculosENexoVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'DII',
      valorOrigem: valorCampoGenericoDIIOrigem || "---",
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? valorCampoGenericoDIIRevisao || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor nulo
      ordem: 18
    });
  }
  // Justificativa DII 
  const campoJDIIOrigem = periciaOrigem.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_JUSTIFICATIVA_DII);
  const campoJDIIRevisao = periciaRevisao.valoresCamposGenericos?.filter(cg => cg.siglaCampoGenerico === SIGLA_CAMPO_JUSTIFICATIVA_DII);

  const valorCampoJDIIOrigem = campoJDIIOrigem[0]?.valorTextoCampoGenerico;
  const valorCampoJDIIRevisao = campoJDIIRevisao[0]?.valorTextoCampoGenerico;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDIDDIIVinculosENexoVisiveis && isValorStringAlterado(valorCampoJDIIOrigem, valorCampoJDIIRevisao))
    || (valorCampoJDIIOrigem?.trim().length > 0 && !isCamposDIDDIIVinculosENexoVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Justificativa DII',
      valorOrigem: valorCampoJDIIOrigem || "---",
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? valorCampoJDIIRevisao || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor nulo
      ordem: 19
    });
  }

  //Vinculos incapacidade
  const vinculosIncapacidadeOrigem = periciaOrigem.vinculosPericia?.filter(v => v.possuiIncapacidade === 1)?.map(v => ({ valorTexto: obterlabelVinculo(v) }));
  const vinculosIncapacidadeRevisao = periciaRevisao.vinculosPericia?.filter(v => v.possuiIncapacidade === 1)?.map(v => ({ valorTexto: obterlabelVinculo(v) }));

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDIDDIIVinculosENexoVisiveis && isListaAlterada(vinculosIncapacidadeOrigem, vinculosIncapacidadeRevisao, 'valorTexto'))
    || (vinculosIncapacidadeOrigem?.length > 0 && !isCamposDIDDIIVinculosENexoVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Vínculos com Incapacidade',
      valorOrigem: vinculosIncapacidadeOrigem,
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? vinculosIncapacidadeRevisao //valor preenchido atualmente na pericia
                  : undefined,//campo esta oculto, deve exibir valor vazio
      ordem: 20,
      lista: true
    });
  }


  //Classificacao da Incapacidade

  const tipoGrauIncapacidadeOrigem = periciaOrigem.moduloGrauIncapacidade?.tipoGrauIncapacidade;
  const tipoGrauIncapacidadeRevisao = periciaRevisao.moduloGrauIncapacidade?.tipoGrauIncapacidade;

  const grauIncapacidadeOrigem = tipoGrauIncapacidadeOrigem && tiposGrauIncapacidade
    && tiposGrauIncapacidade.find(t => t.id === tipoGrauIncapacidadeOrigem)?.descricao;
  const grauIncapacidadeRevisao = tipoGrauIncapacidadeRevisao && tiposGrauIncapacidade
    && tiposGrauIncapacidade.find(t => t.id === tipoGrauIncapacidadeRevisao)?.descricao;

  const isCampoGrauIncapacidadeVisivel = houveComprovacaoIncapacidadeRevisao || isCidAlteradoRevisao || houveProrrogacaoRevisao;

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCampoGrauIncapacidadeVisivel && isValorAlterado(grauIncapacidadeRevisao, grauIncapacidadeOrigem))
   || (grauIncapacidadeOrigem !== null && grauIncapacidadeOrigem !== undefined && !isCampoGrauIncapacidadeVisivel)) {

    informacoesAlteradas.push({
      nomeCampo: 'Classificação da Incapacidade',
      valorOrigem: grauIncapacidadeOrigem || "---",
      valorAtual: isCampoGrauIncapacidadeVisivel
                  ? grauIncapacidadeRevisao || "---" //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 21
    });
  }

  //DCB

  const dcbOrigem = periciaOrigem.moduloGrauIncapacidade?.dataCessacaoBeneficio;
  const dcbRevisao = periciaRevisao.moduloGrauIncapacidade?.dataCessacaoBeneficio;
  
  const isDadosAuxAcidenteVisiveis = !isCidAlteradoRevisao && prorrogacaoRevisao && prorrogacaoRevisao === '0';
  const isCampoDCBVisivel = (isCampoGrauIncapacidadeVisivel && (tipoGrauIncapacidadeRevisao === 1 || tipoGrauIncapacidadeRevisao === 2))
  || (isDadosAuxAcidenteVisiveis && periciaRevisao.moduloGrauIncapacidade?.indAuxilioAcidente === 1);
  
  const dcbOrigemFormatada = dcbOrigem ? formatarDateTimeISOToDataBr(dcbOrigem) : "---";
  const dcbRevisaoFormatada = dcbRevisao ? formatarDateTimeISOToDataBr(dcbRevisao) : "---";
                              
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCampoDCBVisivel && isValorAlterado(dcbOrigemFormatada, dcbRevisaoFormatada))
    || (dcbOrigem !== null && dcbOrigem !== undefined && !isCampoDCBVisivel)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'DCB',
      valorOrigem: dcbOrigemFormatada,
      valorAtual: isCampoDCBVisivel
                  ? dcbRevisaoFormatada //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 22
    });
  }

  //Enquadramento de Auxílio Acidente 
  const enquadramentoAAOrigem = periciaOrigem.moduloGrauIncapacidade?.enquadramentoAuxilioAcidente;
  const enquadramentoAARevisao = periciaRevisao.moduloGrauIncapacidade?.enquadramentoAuxilioAcidente;

  const isCamposAAVisiveis = (isDadosAuxAcidenteVisiveis && periciaRevisao.moduloGrauIncapacidade?.indAuxilioAcidente === 1)
      || (isCampoGrauIncapacidadeVisivel && tipoGrauIncapacidadeRevisao === 2);

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposAAVisiveis && isValorAlterado(enquadramentoAAOrigem, enquadramentoAARevisao))
    || (enquadramentoAAOrigem !== null && enquadramentoAAOrigem !== undefined && !isCamposAAVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Enquadramento Auxílio Acidente',
      valorOrigem: enquadramentoAAOrigem ? listaEnquadAuxAcid?.find(tipo => tipo.id === enquadramentoAAOrigem)?.tipo : "---",
      valorAtual: isCamposAAVisiveis
                  ? (enquadramentoAARevisao ? listaEnquadAuxAcid?.find(tipo => tipo.id === enquadramentoAARevisao)?.tipo : "---") //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 23
    });
  }

  //Situação Enquadramento de Auxílio Acidente 
  const situacaoAAOrigem = periciaOrigem.moduloGrauIncapacidade?.situacaoEnquadramentoAuxilioAcidente;
  const situacaoAARevisao = periciaRevisao.moduloGrauIncapacidade?.situacaoEnquadramentoAuxilioAcidente;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAAVisiveis && isValorAlterado(situacaoAAOrigem, situacaoAARevisao))
    || (situacaoAAOrigem !== null && situacaoAAOrigem !== undefined && !isCamposAAVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Situação Enquadramento Auxílio Acidente',
      valorOrigem: situacaoAAOrigem ? listaSitEnquadAuxAcid?.find(tipo => tipo.id === situacaoAAOrigem)?.situacao : "---",
      valorAtual:  isCamposAAVisiveis
                  ? (situacaoAARevisao ? listaSitEnquadAuxAcid?.find(tipo => tipo.id === situacaoAARevisao)?.situacao : "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 24
    });
  }

  //Justificativa Enquadramento Auxílio Acidente 
  const justificativaAAOrigem = periciaOrigem.moduloGrauIncapacidade?.justParcialComIndicAuxilioAcidente;
  const justificativaAARevisao = periciaRevisao.moduloGrauIncapacidade?.justParcialComIndicAuxilioAcidente;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAAVisiveis && isValorStringAlterado(justificativaAAOrigem, justificativaAARevisao))
    || (justificativaAAOrigem?.trim().length > 0 && !isCamposAAVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Justificativa para Classificação da Incapacidade Parcial e permanente com indicação de Auxílio-acidente',
      valorOrigem: justificativaAAOrigem || "---",
      valorAtual: isCamposAAVisiveis
                  ? (justificativaAARevisao || "---") //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 25
    });
  }

  // DCI

  const dciOrigem = periciaOrigem.moduloGrauIncapacidade?.dataComprovacaoIncapacidade;
  const dciRevisao = periciaRevisao.moduloGrauIncapacidade?.dataComprovacaoIncapacidade;

  const isCamposDCIVisiveis = isCampoGrauIncapacidadeVisivel && tipoGrauIncapacidadeRevisao === 3;

  const dciOrigemFormatada = dciOrigem ? formatarDateTimeISOToDataBr(dciOrigem) : "---";
  const dciRevisaoFormatada = dciRevisao ? formatarDateTimeISOToDataBr(dciRevisao) : "---";
                              
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDCIVisiveis && isValorAlterado(dciOrigemFormatada, dciRevisaoFormatada))
    || (dciOrigem !== null && dciOrigem !== undefined && !isCamposDCIVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'DCI',
      valorOrigem: dciOrigemFormatada,
      valorAtual: isCamposDCIVisiveis
                  ? dciRevisaoFormatada//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 26
    });
  }

  // Justificativa Reabilitação Profissional
  const justificativaDCIOrigem = periciaOrigem.moduloGrauIncapacidade?.justTotalComIndicReabProfissional;
  const justificativaDCIRevisao = periciaRevisao.moduloGrauIncapacidade?.justTotalComIndicReabProfissional;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDCIVisiveis && isValorStringAlterado(justificativaDCIOrigem, justificativaDCIRevisao))
    || (justificativaDCIOrigem?.trim().length > 0 && !isCamposDCIVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Justificativa para Classificação da Incapacidade Total e permanente, elegível para Reabilitação Profissional',
      valorOrigem: justificativaDCIOrigem || "---",
      valorAtual: isCamposDCIVisiveis
                  ? (justificativaDCIRevisao || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 27
    });
  }

  //isencao IR
  const isencaoIROrigem = periciaOrigem.moduloGrauIncapacidade?.doencaIsentaImpostoRenda;
  const isencaoIRRevisao = periciaRevisao.moduloGrauIncapacidade?.doencaIsentaImpostoRenda;

  const isCamposAposentadoriaVisiveis = isCampoGrauIncapacidadeVisivel && tipoGrauIncapacidadeRevisao === 4;
                              
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAposentadoriaVisiveis && isValorAlterado(isencaoIROrigem, isencaoIRRevisao))
    || (isencaoIROrigem !== null && isencaoIROrigem !== undefined && !isCamposAposentadoriaVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Isenção de IR',
      valorOrigem: formatarSimNaoBooleano(isencaoIROrigem) || "---",
      valorAtual: isCamposAposentadoriaVisiveis 
                  ? (formatarSimNaoBooleano(isencaoIRRevisao) || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 28
    });
  }

  // Justificativa isencao IR
  const justIsencaoIROrigem = periciaOrigem.moduloGrauIncapacidade?.tipoIsencaoImpostoRenda;
  const justIsencaoIRRevisao = periciaRevisao.moduloGrauIncapacidade?.tipoIsencaoImpostoRenda;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAposentadoriaVisiveis && isValorAlterado(justIsencaoIROrigem, justIsencaoIRRevisao))
    || (justIsencaoIROrigem !== null && justIsencaoIROrigem !== undefined && !isCamposAposentadoriaVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Motivo Isenção de IR',
      valorOrigem: listaMotivosIsencaoIr?.find(tipo => tipo.id === justIsencaoIROrigem)?.doenca || "---",
      valorAtual: isCamposAposentadoriaVisiveis
                  ? (listaMotivosIsencaoIr?.find(tipo => tipo.id === justIsencaoIRRevisao)?.doenca || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 29
    });
  }

  // majoracao
  const majoracaoOrigem = periciaOrigem.moduloGrauIncapacidade?.majoracao25;
  const majoracaoRevisao = periciaRevisao.moduloGrauIncapacidade?.majoracao25;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAposentadoriaVisiveis && isValorAlterado(majoracaoOrigem, majoracaoRevisao))
    || (majoracaoOrigem !== null && majoracaoOrigem !== undefined && !isCamposAposentadoriaVisiveis)) {
  // if (isValorAlterado(majoracaoOrigem, majoracaoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Majoração 25%',
      valorOrigem: formatarSimNaoBooleano(majoracaoOrigem) || "---",
      valorAtual: isCamposAposentadoriaVisiveis
                  ? (formatarSimNaoBooleano(majoracaoRevisao) || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 30
    });
  }

  // Justificativa majoracao
  const justMajoracaoOrigem = periciaOrigem.moduloGrauIncapacidade?.tipoMajoracao25;
  const justMajoracaoRevisao = periciaRevisao.moduloGrauIncapacidade?.tipoMajoracao25;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAposentadoriaVisiveis && isValorAlterado(justMajoracaoOrigem, justMajoracaoRevisao))
    || (justMajoracaoOrigem !== null && justMajoracaoOrigem !== undefined && !isCamposAposentadoriaVisiveis)) {
  // if (isValorAlterado(justMajoracaoOrigem, justMajoracaoRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Motivo Majoração 25%',
      valorOrigem: listaMotivosMajoracao25?.find(tipo => tipo.id === justMajoracaoOrigem)?.motivo || "---",
      valorAtual: isCamposAposentadoriaVisiveis
                  ? (listaMotivosMajoracao25?.find(tipo => tipo.id === justMajoracaoRevisao)?.motivo || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 31
    });
  }

  //Justificativa Limite Indefinido / Aposentadoria
  const justificativaLIOrigem = periciaOrigem.moduloGrauIncapacidade?.justTotalSemIndicReabProfissional;
  const justificativaLIRevisao = periciaRevisao.moduloGrauIncapacidade?.justTotalSemIndicReabProfissional;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposAposentadoriaVisiveis && isValorStringAlterado(justificativaLIOrigem, justificativaLIRevisao))
    || (justificativaLIOrigem?.trim().length > 0 && !isCamposAposentadoriaVisiveis)) {
  // if (isValorStringAlterado(justificativaLIOrigem, justificativaLIRevisao)) {
    informacoesAlteradas.push({
      nomeCampo: 'Justificativa para Classificação da Incapacidade Total e Permanente/Prazo Indeterminado',
      valorOrigem: justificativaLIOrigem || "---",
      valorAtual: isCamposAposentadoriaVisiveis
                  ? (justificativaLIRevisao || "---")//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 32
    });
  }

  ///Resposta nexo profissional
  const existeNexoProfissionalOrigem = periciaOrigem.moduloNexoTecnico?.existeNexoProfissional;
  const existeNexoProfissionalRevisao = periciaRevisao.moduloNexoTecnico?.existeNexoProfissional;

  const isCamposNexoProfissionalRespostaSIMVisiveis = isCamposDIDDIIVinculosENexoVisiveis && periciaRevisao.moduloNexoTecnico?.existeNexoProfissional === 1;
  const isCampoNexoProfissionalRespostaNAOVisivel = isCamposDIDDIIVinculosENexoVisiveis && periciaRevisao.moduloNexoTecnico?.existeNexoProfissional === 0;
  
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposDIDDIIVinculosENexoVisiveis && isValorAlterado(existeNexoProfissionalOrigem, existeNexoProfissionalRevisao))
      || (existeNexoProfissionalOrigem !== null && existeNexoProfissionalOrigem !== undefined && !isCamposDIDDIIVinculosENexoVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Caracterização do Nexo Técnico Profissional',
      valorOrigem: formatarSimNaoBooleano(existeNexoProfissionalOrigem) || "---",
      valorAtual: isCamposDIDDIIVinculosENexoVisiveis
                  ? formatarSimNaoBooleano(existeNexoProfissionalRevisao) || "---"//valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 33
    });
  }
  
  //Fatores de Risco Nexo Profissional
  const fatoresRiscoOrigem = periciaOrigem.moduloNexoTecnico?.fatoresRiscoProfissional
  ?.map(f => ({ valorTexto: f.descricaoFatorRisco }));
  const fatoresRiscoRevisao = periciaRevisao.moduloNexoTecnico?.fatoresRiscoProfissional
  ?.map(f => ({ valorTexto: fatoresRiscoCid?.find(r => r.idFatorRiscoOcupacional === f.idFatorRiscoOcupacional)?.nomeFatorRisco }));
  
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposNexoProfissionalRespostaSIMVisiveis && isListaAlterada(fatoresRiscoOrigem, fatoresRiscoRevisao, 'valorTexto'))
        || (fatoresRiscoOrigem?.length > 0 && !isCamposNexoProfissionalRespostaSIMVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Fatores de risco / Agentes patogênicos identificados',
      valorOrigem: fatoresRiscoOrigem,
      valorAtual: isCamposNexoProfissionalRespostaSIMVisiveis 
        ? fatoresRiscoRevisao //valor preenchido atualmente na pericia
        : undefined, //campo esta oculto, deve exibir valor vazio
      ordem: 34,
      lista: true
    });
  }

  //Tipo de Doença Nexo Profissional
  const tipoDoencaNexoProfissionalOrigem = periciaOrigem.moduloNexoTecnico?.tipoDoencaNexoProfissional;
  const tipoDoencaNexoProfissionalRevisao = periciaRevisao.moduloNexoTecnico?.tipoDoencaNexoProfissional;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposNexoProfissionalRespostaSIMVisiveis && isValorAlterado(tipoDoencaNexoProfissionalOrigem, tipoDoencaNexoProfissionalRevisao))
      || (tipoDoencaNexoProfissionalOrigem !== null && tipoDoencaNexoProfissionalOrigem !== undefined && !isCamposNexoProfissionalRespostaSIMVisiveis)) {
    informacoesAlteradas.push({
      nomeCampo: 'Tipo de Doença Nexo Técnico Profissional',
      valorOrigem: tiposDoencaNexoProfissional?.find(tipo => tipo.id === tipoDoencaNexoProfissionalOrigem)?.descricao || "---",
      valorAtual: isCamposNexoProfissionalRespostaSIMVisiveis
                    ? tiposDoencaNexoProfissional?.find(tipo => tipo.id === tipoDoencaNexoProfissionalRevisao)?.descricao || "---" //valor preenchido atualmente na pericia
                    : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 35
    });
  }

  // Vínculos Relacionados  Nexo Profissional
  const vinculosNexoProfissionalOrigem = periciaOrigem.vinculosPericia?.filter(v => v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.PROFISSIONAL.id)
    ?.map(v => ({ valorTexto: obterLabelVinculoNexo(v) }));
  const vinculosNexoProfissionalRevisao = periciaRevisao.vinculosPericia?.filter(v => v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.PROFISSIONAL.id)
    ?.map(v => ({ valorTexto: obterLabelVinculoNexo(v) }));

     //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposNexoProfissionalRespostaSIMVisiveis && isListaAlterada(vinculosNexoProfissionalOrigem, vinculosNexoProfissionalRevisao, 'valorTexto'))
        || (vinculosNexoProfissionalOrigem?.length > 0 && !isCamposNexoProfissionalRespostaSIMVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Vínculos Relacionados Nexo Técnico Profissional',
      valorOrigem: vinculosNexoProfissionalOrigem,
      valorAtual: isCamposNexoProfissionalRespostaSIMVisiveis 
                  ? vinculosNexoProfissionalRevisao //valor preenchido atualmente na pericia
                  : undefined, //campo esta oculto, deve exibir valor vazio
      ordem: 36,
      lista: true
    });
  }


  //Fundamentação Técnica Nexo Profissional
  const fundTecnicaNexoProfissionalOrigem = periciaOrigem.moduloNexoTecnico?.fundamentacaoNexoProfissional;
  const fundTecnicaNexoProfissionalRevisao = periciaRevisao.moduloNexoTecnico?.fundamentacaoNexoProfissional;

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposNexoProfissionalRespostaSIMVisiveis && isValorStringAlterado(fundTecnicaNexoProfissionalOrigem, fundTecnicaNexoProfissionalRevisao))
      || (fundTecnicaNexoProfissionalOrigem?.trim().length > 0 && !isCamposNexoProfissionalRespostaSIMVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Fundamentação Técnica Nexo Técnico Profissional',
      valorOrigem: fundTecnicaNexoProfissionalOrigem || "---",
      valorAtual: isCamposNexoProfissionalRespostaSIMVisiveis 
                  ? fundTecnicaNexoProfissionalRevisao || "---" //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 37
    });
  }

  //Justificativa resposta negativa Nexo Profissional
  const justNegativaNexoProfissionalOrigem = periciaOrigem.moduloNexoTecnico?.justifNegacaoNexoProfissional;
  const justNegativaNexoProfissionalRevisao = periciaRevisao.moduloNexoTecnico?.justifNegacaoNexoProfissional;
  
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCampoNexoProfissionalRespostaNAOVisivel && isValorStringAlterado(justNegativaNexoProfissionalOrigem, justNegativaNexoProfissionalRevisao))
      || (justNegativaNexoProfissionalOrigem?.trim().length > 0 && !isCampoNexoProfissionalRespostaNAOVisivel)) {

    informacoesAlteradas.push({
      nomeCampo: 'Justificativa de Resposta Negativa para Nexo Técnico Profissional',
      valorOrigem: justNegativaNexoProfissionalOrigem || "---",
      valorAtual: isCampoNexoProfissionalRespostaNAOVisivel
                  ? justNegativaNexoProfissionalRevisao || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor vazio
      ordem: 38
    });
  }

  ///Resposta nexo individual
  const existeNexoIndividualOrigem = periciaOrigem.moduloNexoTecnico?.existeNexoIndividual;
  const existeNexoIndividualRevisao = periciaRevisao.moduloNexoTecnico?.existeNexoIndividual;

  const isCampoNexoIndividualVisivel = isCamposDIDDIIVinculosENexoVisiveis && !periciaRevisao.moduloNexoTecnico?.existeNexoProfissional;
  const isCamposNexoIndividualRespostaSIMVisiveis = isCamposDIDDIIVinculosENexoVisiveis && isCampoNexoIndividualVisivel 
                && periciaRevisao.moduloNexoTecnico?.existeNexoIndividual === 1;
  
  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCampoNexoIndividualVisivel && isValorAlterado(existeNexoIndividualOrigem, existeNexoIndividualRevisao))
      || (existeNexoIndividualOrigem !== undefined && existeNexoIndividualOrigem !== null && !isCampoNexoIndividualVisivel)) {

    informacoesAlteradas.push({
      nomeCampo: 'Caracterização do Nexo Técnico Individual',
      valorOrigem: formatarSimNaoBooleano(existeNexoIndividualOrigem) || "---",
      valorAtual: isCampoNexoIndividualVisivel
                  ? formatarSimNaoBooleano(existeNexoIndividualRevisao) || "---" //valor preenchido atualmente na pericia
                  : "---",//campo esta oculto, deve exibir valor vazio
      ordem: 39
    });
  }

  //Tipo de Nexo Técnico Individual
  const tipoNexoIndividualOrigem = periciaOrigem.moduloNexoTecnico?.tipoNexoIndividual;
  const tipoNexoIndividualRevisao = periciaRevisao.moduloNexoTecnico?.tipoNexoIndividual;

  //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
  if ((isCamposNexoIndividualRespostaSIMVisiveis && isValorAlterado(tipoNexoIndividualOrigem, tipoNexoIndividualRevisao))
      || (tipoNexoIndividualOrigem !== undefined && tipoNexoIndividualOrigem !== null && !isCamposNexoIndividualRespostaSIMVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Tipo de Nexo Técnico Individual',
      valorOrigem: tiposNexoIndividual.find(tipo => tipo.id === parseInt(tipoNexoIndividualOrigem))?.descricao || "---",
      valorAtual: isCamposNexoIndividualRespostaSIMVisiveis
                ? tiposNexoIndividual.find(tipo => tipo.id === parseInt(tipoNexoIndividualRevisao))?.descricao || "---"//valor preenchido atualmente na pericia
                : "---",//campo esta oculto, deve exibir valor vazio
      ordem: 40
    });
  }

  //Vínculos Relacionados Nexo Individual
  const vinculosNexoIndividualOrigem = periciaOrigem.vinculosPericia?.filter(v => v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.INDIVIDUAL.id)
    ?.map(v => ({ valorTexto: obterLabelVinculoNexo(v) }));
  const vinculosNexoIndividualRevisao = periciaRevisao.vinculosPericia?.filter(v => v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.INDIVIDUAL.id)
    ?.map(v => ({ valorTexto: obterLabelVinculoNexo(v) }));

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposNexoIndividualRespostaSIMVisiveis && isListaAlterada(vinculosNexoIndividualOrigem, vinculosNexoIndividualRevisao, 'valorTexto'))
       || (vinculosNexoIndividualOrigem?.length > 0 && !isCamposNexoIndividualRespostaSIMVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Vínculos Relacionados Nexo Técnico Individual',
      valorOrigem: vinculosNexoIndividualOrigem,
      valorAtual: isCamposNexoIndividualRespostaSIMVisiveis 
                  ? vinculosNexoIndividualRevisao //valor preenchido atualmente na pericia
                  : undefined, //campo esta oculto, deve exibir valor vazio
      ordem: 41,
      lista: true
    });
  }
  //Fundamentação Técnica Nexo Individual
  const fundTecnicaNexoIndividualOrigem = periciaOrigem.moduloNexoTecnico?.fundamentacaoNexoIndividual;
  const fundTecnicaNexoIndividualRevisao = periciaRevisao.moduloNexoTecnico?.fundamentacaoNexoIndividual;

   //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposNexoIndividualRespostaSIMVisiveis && isValorStringAlterado(fundTecnicaNexoIndividualOrigem, fundTecnicaNexoIndividualRevisao))
       || (fundTecnicaNexoIndividualOrigem?.trim().length > 0 && !isCamposNexoIndividualRespostaSIMVisiveis)) {
  
    informacoesAlteradas.push({
      nomeCampo: 'Fundamentação Técnica Nexo Técnico Individual',
      valorOrigem: fundTecnicaNexoIndividualOrigem || "---",
      valorAtual: isCamposNexoIndividualRespostaSIMVisiveis
                  ? fundTecnicaNexoIndividualRevisao || "---" //valor preenchido atualmente na pericia
                  : "---", //campo esta oculto, deve exibir valor vazio
      ordem: 42
    });
  }

  //Caracterização do Nexo Técnico Epidemiológico
  const nexosEpidemiologicosOrigem = periciaOrigem.vinculosPericia?.filter(v => (v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.EPIDEMIOLOGICO.id)
    || v.possuiNexoTecnico === 0) //NTEP negado
    ?.map(v => ({
      nomeVinculo: obterLabelVinculoNexo(v), possuiNexo: v.possuiNexoTecnico,
      justDescaracterizacao: motivosNaoFixacaoNtep?.find(m => m.id === v.descaracterizacaoNtep?.motivoNaoFixacaoNtep)?.descricao,
      fundamentacao: v.descaracterizacaoNtep?.fundamentacaoNaoFixacaoNtep
    }));
  const nexosEpidemiologicosRevisao = periciaRevisao.vinculosPericia?.filter(v => (v.possuiNexoTecnico === 1 && v.tipoNexoTecnico === tiposNexoTecnico.EPIDEMIOLOGICO.id)
    || v.possuiNexoTecnico === 0) //NTEP negado
    ?.map(v => ({
      nomeVinculo: obterLabelVinculoNexo(v), possuiNexo: v.possuiNexoTecnico,
      justDescaracterizacao: motivosNaoFixacaoNtep?.find(m => m.id === v.descaracterizacaoNtep?.motivoNaoFixacaoNtep)?.descricao,
      fundamentacao: v.descaracterizacaoNtep?.fundamentacaoNaoFixacaoNtep
    }));

    const isCamposNTEPVisiveis = isCamposDIDDIIVinculosENexoVisiveis && !periciaRevisao.moduloNexoTecnico?.existeNexoProfissional 
                    && !periciaRevisao.moduloNexoTecnico?.existeNexoIndividual;

     //deve exibir alteracao apenas se campo visivel na revisao e houve alteracao, OU se existia valor e campo foi ocultado
   if ((isCamposNTEPVisiveis && isListaAlterada(nexosEpidemiologicosOrigem, nexosEpidemiologicosRevisao, 'nomeVinculo'))
        || (nexosEpidemiologicosOrigem?.length > 0 && !isCamposNTEPVisiveis)) {

    informacoesAlteradas.push({
      nomeCampo: 'Caracterização do Nexo Técnico Epidemiológico',
      valorOrigem: nexosEpidemiologicosOrigem,
      valorAtual: isCamposNTEPVisiveis
                  ? nexosEpidemiologicosRevisao //valor preenchido atualmente na pericia
                  : undefined, //campo esta oculto, deve exibir valor vazio
      ordem: 43,
      tabela: true
    });
  }

  const dadosConclusaoRevisao = { informacoesAlteradas: informacoesAlteradas };

  return dadosConclusaoRevisao;
}

const isValorStringAlterado = (valorAnterior, valorAtual) => {
  const valorAnteriorTratado = valorAnterior ? valorAnterior.trim() : '';
  const valorAtualTratado = valorAtual ? valorAtual.trim() : '';

  return valorAnteriorTratado !== valorAtualTratado;
}
const isValorAlterado = (valorAnterior, valorAtual) => {
  //tratando null e undefined como mesmo valor
  const valorAnteriorTratado = valorAnterior !== null && valorAnterior !== undefined ? valorAnterior : undefined;
  const valorAtualTratado = valorAtual !== null && valorAtual !== undefined ? valorAtual : undefined;

  return valorAnteriorTratado !== valorAtualTratado;
}

const isListaAlterada = (listaAnterior, listaAtual, nomeCampoReferencia, funcaoComparacao) => {
  return (listaAnterior && listaAtual && listaAnterior.length !== listaAtual.length)
    || isValorDeListaAlterada(listaAnterior, listaAtual, nomeCampoReferencia, funcaoComparacao);
}

//tratando cenario onde dados foram alterados mas o tamanho continua igual 
const isValorDeListaAlterada = (listaAnterior, listaAtual, nomeCampoReferencia, funcaoComparacao) => {
  let existeAlteracao = false;

  if (listaAnterior && listaAnterior.length > 0 && listaAtual && listaAtual.length > 0) {

    //iterando por lista de valores anteriores
    listaAnterior.forEach(itemAnterior => {

      //obtendo item correspondente na lista atual, se houver
      let itemAtual = null;
      if (nomeCampoReferencia) {
        itemAtual = listaAtual.find(i => i[nomeCampoReferencia] === itemAnterior[nomeCampoReferencia]);
      } else if (funcaoComparacao) {
        itemAtual = listaAtual.find(i => funcaoComparacao(i, itemAnterior));
      }

      //verificando se item anterior esta na lista atual
      if (!itemAtual || !isObjetoComMesmosValores(itemAnterior, itemAtual)) {
        existeAlteracao = true;
        return;
      }
    });
  }

  return existeAlteracao;
}

const isObjetoComMesmosValores = (obj1, obj2) => {
  let valoresSaoIguais = true;
  for (var prop in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
      //comparando valores de cada atributo do objeto
      if ((obj1[prop])?.toString().trim() !== (obj2[prop])?.toString().trim()) {
        valoresSaoIguais = false;
      }
    }
  }

  return valoresSaoIguais;
}