import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import Concluir from "../../../../components/Concluir";
import DadosTecnicos from "./DadosTecnicos";
import HeaderSecao from '../../../../components/HeaderSecao';

import { downloadPericiaGenerico, validacaoCompletaPericia } from "../../action";

const ConclusaoPericiaBI = (props) => {

  useEffect(() => {
    props.validacaoCompletaPericia(props.pericia, true);
  });

  return (
    <>
      <div>
        {
          props.respostaConcluir && props.respostaConcluir.erros
          && (
            props.respostaConcluir.erros.map((e, index) =>
            (
              <BrMessage key={`erro_${index}`} error>
                {e}
              </BrMessage>)
            )
          )
        }
      </div>


      <HeaderSecao texto="Dados Técnicos" />
      <DadosTecnicos pericia={props.pericia} tiposGrauIncapacidade={props.tiposGrauIncapacidade} />

      <Concluir />
    </>
  );
}

const mapStateToProps = state => ({
  pericia: state.pericia,
  respostaConcluir: state.pericia.respostaConcluir,
  tiposGrauIncapacidade: state.principal.dominioPericia.tiposGrausIncapacidade
});

const mapDispatchToProps = dispatch => bindActionCreators({
  validacaoCompletaPericia, downloadPericiaGenerico
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoPericiaBI);