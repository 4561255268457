import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrButton from '@govbr/react-components/lib/BrButton';

import { formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import { alterarValorCampoGenericoData, ocultarDialogAlterarDIDOuDII } from '../../action';

const DialogConfirmarAlteracaoDIDDII = props => {

  const [didOuDii, setDidOuDii] = useState(null);
  const [didOuDiiAnterior, setDidOuDiiAnterior] = useState(null);
  const [sigla, setSigla] = useState(null);

  useEffect(() => {
    const { valoresCamposGenericos, validacaoDIDOuDIINexo } = props.pericia;
    let valorCampo = validacaoDIDOuDIINexo && validacaoDIDOuDIINexo.valor ? validacaoDIDOuDIINexo.valor : undefined;
    if (valorCampo === undefined || valorCampo.toString() === 'Invalid Date') {
      valorCampo = '';
    }
    setSigla(validacaoDIDOuDIINexo && validacaoDIDOuDIINexo.siglaCampo ? validacaoDIDOuDIINexo.siglaCampo : undefined);
    setDidOuDii(valorCampo);
    setDidOuDiiAnterior(valoresCamposGenericos && validacaoDIDOuDIINexo
      ? valoresCamposGenericos.find(v => v.idCampoGenerico === validacaoDIDOuDIINexo.idCampoGenerico)?.valorTextoCampoGenerico
      : undefined)
  }, [props.pericia]);

  return (
    <BrModal id="dialogConfirmarAlteracaoDIDOuDII" visible={props.pericia.deveConfirmarAlteracaoDIDOuDII}
      title={`Alterar ${sigla}`} closable={false} medium>
      <p>
        <b>​O Nexo Técnico será apagado</b> e deverá ser analisado conforme a nova '{sigla}' informada..
      </p>
      Confirma alteração da '{sigla}'
      {
        didOuDii && (
          <>
            &nbsp;de
            <b> '{didOuDiiAnterior ? didOuDiiAnterior.toString() : ''}' </b>
            para
            <b> '{formatarDateTimeISOToDataBr(didOuDii)}' </b>
          </>
        )
      }
      ?

      <BrModalFooter>
        <BrButton secondary className="m-2" onClick={() => props.ocultarDialogAlterarDIDOuDII(true)}>Cancelar</BrButton>
        <BrButton primary className="m-2" onClick={() =>
          props.alterarValorCampoGenericoData(props.pericia.validacaoDIDOuDIINexo.idCampoGenerico,
            props.pericia.validacaoDIDOuDIINexo.siglaCampo,
            props.pericia.validacaoDIDOuDIINexo.valor,
            props.pericia.validacaoDIDOuDIINexo.campo,
            props.pericia.validacaoDIDOuDIINexo.nomeModulo)}>Confirmar</BrButton>
      </BrModalFooter>
    </BrModal >
  );
}

const mapStateToProps = (state) => ({
  pericia: state.pericia
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarValorCampoGenericoData,
  ocultarDialogAlterarDIDOuDII
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarAlteracaoDIDDII);
