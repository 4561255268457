import React, { useCallback, useState } from "react";

import logo from "../../img/armasnacionais.png";
import BrHeader from '@govbr/react-components/lib/BrHeader';
import BrMenu, { MENU_TYPES } from "@govbr/react-components/lib/BrMenu";

import Popper from '@material-ui/core/Popper';
import BrButton from '@govbr/react-components/lib/BrButton';
import Paper from '@material-ui/core/Paper';

import history from '../../common/history';
import { possuiPermissoes } from '../../common/AuthManager';
import * as permissoes from '../../common/permissoes';

import './principal.css';

export default function Header(props) {
  let user = props.user;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMainMenu, setOpenMainMenu] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const { firstName, document, gerid } = props.user;

  const obterOpcoesMenu = () => {
    let opcoes = [];

    if (possuiPermissoes(permissoes.COEXPER)) {
      opcoes.push({
        id: "folderComunicacoes",
        label: "Painel de Comunicações Externas",
        url: "/comunicacao"
      });
    }

    if (possuiPermissoes(permissoes.CONGERPER)) {
      opcoes.push({
        id: "folderConsultaPericia",
        label: "Painel Gerencial",
        url: "/consulta/pericia"
      });
    }

    opcoes.push({
      id: "folderDicionario",
      label: "Dicionário de texto padrão",
      url: "/dicionario"
    });

    if (possuiPermissoes(permissoes.CONGERPER)) {
      opcoes.push({
        id: "folderPainelApis",
        label: "Consulta de APIs",
        url: "/consultasAPI"
      });
    }
    return opcoes;
  }

  const handleNavigate = useCallback(
    route => {
      history.push(route);
      if (openMainMenu) {
        setOpenMainMenu(false);
      }
    },
    [openMainMenu]
  );

  const menuFolders = obterOpcoesMenu();

  const menuGerencial = (
    <BrMenu
      id="menu-gerencial"
      show={openMainMenu}
      type={MENU_TYPES.scrim}
      onClose={() => setOpenMainMenu(false)}
      label="Menu gerencial"
      style={{ minWidth: "20vw" }}
      folders={menuFolders.length > 0 && menuFolders.map((folder, index) => {
        const { id, items, url, ...rest } = folder;

        return (
          <BrMenu.Folder
            onToggle={handleNavigate}
            key={index}
            url={url}
            {...rest}
          />
        );
      })}
    />
  );

  return (<>
    <BrHeader logo={logo}
      sign={props.info.cabecalho}
      title={props.info.titulo}
      subTitle={props.info.subtitulo}
      noFunctions
      noSearch
      links={[{
        label: firstName
      }]}
      menu={menuFolders.length > 0 ? menuGerencial : undefined}
      handleOpenMenu={() => setOpenMainMenu(!openMainMenu)}
      handleOpenUserMenu={(e) => handleClick(e)}
      loginWidget={{
        show: true,
        label: "Login",
        user,
        onClick: props.handleLogout
      }} />

    <Popper style={{ zIndex: 2 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
    >
      <Paper elevation={8}>
        <div className="notification">
          <div className="notification-header">
            <div className="user-email">CPF: <span className="texto-informativo">{document.number}</span></div>
            {
              user.gerid &&
              <div>
                <div className="user-email">Papel: <span className="texto-informativo">{gerid.papel}</span></div>
                <div className="user-email">Abrangência: <span className="texto-informativo">{gerid.abrangencia}</span></div>
              </div>
            }
          </div>
          <div className="notification-footer">
            <BrButton small secondary onClick={() => props.handleLogout()}>Sair</BrButton>
          </div>
        </div>
      </Paper>
    </Popper>
  </>
  );
}
