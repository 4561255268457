import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import HeaderSecao from '../../components/HeaderSecao';
import BrTable from '@govbr/react-components/lib/BrTable';
import BrButton from '@govbr/react-components/lib/BrButton';
import Grid from "@material-ui/core/Grid";
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import Box from "@material-ui/core/Box";
import DialogDetalhesResultadoTesteAPI from './DialogDetalhesResultadoTesteAPI';
import DialogTemplate from './DialogTemplate';

import { displayLoader, errorMessage, successMessage, limparMensagem, limparTemplate} from '../principal/action';
import { limparDadosPericia } from '../pericia/action';
import { getSeguroPromise } from '../../common/requests';
import { limparCacheDominiosBI, limparCacheTemplates, limparCacheSdc, limparCamposPesquisa,
  atualizarTipoPesquisaTemplate, atualizarIdPericiaPesquisaTemplate, atualizarIdTemplatePesquisaTemplate, atualizarIdTemplateOrigemPesquisaTemplate,
  atualizarTipoPericiaPesquisaTemplate, atualizarDataReferenciaPesquisaTemplate, pesquisar, exibirDialogTemplate
 } from './action';

import { format } from 'date-fns';
import { tiposPericia } from '../pericia/constants';

const ConsultasAPI = (props) => {
  /* variaveis teste de APIs*/

  const [resultadoTestes, setResultadoTestes] = useState([]);
  const [consultaExecutou, setConsultaExecutou] = useState(undefined);
  const [isDetalharTeste, setIsDetalharTeste] = useState(false);
  const [testeSelecionado, setTesteSelecionado] = useState(undefined);

  /* variaveis consulta templates*/

  const {filtro, validacao, template} = props.dados;

  const OPCAO_VAZIA = { label: '', value: null };

  const tiposConsulta = [{label: "ID Perícia", value:1},{label: "Tipo Perícia (vigente)", value:2},{label: "ID Template", value:3}];
  tiposConsulta.unshift(OPCAO_VAZIA);
  const opcoesTipoPericia = tiposPericia ? Object.values(tiposPericia)?.filter(t => t.isAtiva)?.map(t => ({ label: `${t.tipo} - ${t.descricao}`, value: t.id })) : [];
  opcoesTipoPericia.unshift(OPCAO_VAZIA);

  useEffect(() => {
    //remover qualquer mensagem de erro/sucesso que possa existir
    props.limparMensagem();
    //remover dados pericia, retirando mensagens de alerta da tela
    props.limparDadosPericia();
    props.limparTemplate(); 
    //consultar apis
    buscarResultadoTesteSanidadeAPIs();
    // eslint-disable-next-line
  }, []);//deve executar apenas a primeira vez que o componente for renderizado

  const buscarResultadoTesteSanidadeAPIs = () => {
    props.displayLoader(true)

    getSeguroPromise(`pmf-realizar-pericias-service/consultaAPIs/testeSanidade`)
      .then((response) => {
        props.displayLoader(false);
        
        const resultadoTesteSanidadeAPIs = response.data && response.data.length > 0 ? response.data : [];
        
        setConsultaExecutou(true);
        setResultadoTestes(resultadoTesteSanidadeAPIs);
      })
      .catch((falha) => {
        setConsultaExecutou(false);
        props.errorMessage(falha, 'Teste de sanidade das APIs (Gateway)');
      });
  }

  const exibirDialogDetalheTeste = (resultadoTeste) => {
    setIsDetalharTeste(true); 
    setTesteSelecionado(resultadoTeste);
  }
  
  const ocultarDialogDetalheTeste = () => {
    setIsDetalharTeste(false); 
  };

  return (
    <div id="tela-consultas-apis" className="col">
      <HeaderSecao texto="Consultas de APIs" />
      {
        resultadoTestes && resultadoTestes.length > 0 && 
        <BrTable
          id="tabelaResultadoTestesApi"
          layout="fixed"
          pagination={false}
          columns={[{ Header: "API", accessor: "nomeAPI" },{ Header: "Serviço", accessor: "servicoExecutado" },
            { Header: "Código HTTP", accessor: "codigoHTTP" },{ Header: "Data do Teste", accessor: "dataTeste" },
            { Header: "Ação", accessor: "acao" }]}
          data={resultadoTestes.map(item => ({
            ...item,
            dataTeste: `${format(Date.parse(item.dataTeste), "dd/MM/yyyy HH:mm:ss")}`,
            codigoHTTP: item.codigoHTTP === 200 ? <span style={{color: 'green'}}>{item.codigoHTTP}</span> : <span style={{color: 'red'}}>{item.codigoHTTP}</span>,
            acao: 
              <BrButton id="detalharResultado" circle
                onClick={() => exibirDialogDetalheTeste(item)}>
                <i className={`fas fa-info`} title='Detalhar Resultado' />
              </BrButton>
          }))}
          checkbox={false}
          headerProps={{ show: false }}
          footerProps={{ show: false }}
        />   
      }
      {
        consultaExecutou !== undefined && !consultaExecutou &&
          <center className="texto-informativo red">
            Falha na comunicação com Gateway.
          </center>
      }

      {props.userData?.isUsuarioInternoDataprev &&
        <>
        <HeaderSecao texto="Menu de limpeza de cache" />

          <BrButton id="limparCacheSDC" secondary className="mr-2"
            onClick={() => props.limparCacheSdc()}>
            <i className={`fas fa-recycle`} title='Limpar cache SDC' />
            Cache SDC
          </BrButton>

          <BrButton id="limparCacheTemplates" secondary className="mr-2"
            onClick={() => props.limparCacheTemplates()}>
            <i className={`fas fa-dumpster-fire`} title='Limpar cache templates' />
            Cache templates
          </BrButton>
          
          <BrButton id="limparCacheDominios" secondary
            onClick={() => props.limparCacheDominiosBI()}>
            <i className={`fas fa-broom`} title='Limpar cache domínios BI' />
            Cache domínios BI
          </BrButton>
        {/* </Box> */}

      
        <HeaderSecao texto="Menu de consulta de template" />

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BrSelect id="tipoConsulta" label="Tipo de consulta"
                  items={tiposConsulta}
                  value={filtro.tipoConsulta && tiposConsulta
                    ? tiposConsulta.find(s => s.value === filtro.tipoConsulta)
                    : OPCAO_VAZIA}
                  onChange={props.atualizarTipoPesquisaTemplate}
                  erro={validacao && validacao.erroTipoConsulta} />
            </Grid>
            {
              filtro.tipoConsulta === 1 &&
              <Grid item xs={3}>
                <BrInput id="idPericia" label="ID Perícia"
                    mode="number" 
                    maxLength={10}
                    value={filtro.idPericia}
                    onChange={props.atualizarIdPericiaPesquisaTemplate}
                    erro={validacao && validacao.erroIdPericia} />
              </Grid>
            }
            {
              filtro.tipoConsulta === 3 &&
              <Grid item xs={3}>
                  <BrInput id="idTemplate" label="ID Template"
                        mode="number" 
                        maxLength={9}
                        value={filtro.idTemplate}
                        onChange={props.atualizarIdTemplatePesquisaTemplate}
                        erro={validacao && validacao.erroIdTemplate}/> 
                </Grid>
            }
            {
              filtro.tipoConsulta === 2 &&
                <>
                  <Grid item xs={4}>
                    <BrSelect id="tipoPericia" label="Tipo de Perícia"
                        items={opcoesTipoPericia}
                        value={filtro.tipoPericia && opcoesTipoPericia
                          ? opcoesTipoPericia.find(s => s.value === filtro.tipoPericia)
                          : OPCAO_VAZIA} 
                        onChange={props.atualizarTipoPericiaPesquisaTemplate}
                        erro={validacao && validacao.erroTipoPericia}/>
                  </Grid>
                  <Grid item xs={2}>
                    <BrDatepicker id="dataReferencia" label="Data Referência (opcional)"
                      disableFuture
                      allowInput 
                      value={filtro.dataReferencia || null}
                      onChange={props.atualizarDataReferenciaPesquisaTemplate}/>
                  </Grid>
                </>
            }
            {filtro.tipoConsulta > 1 &&
              <Grid item xs={3}>
                <BrInput id="idTemplateOrigem" label="ID Template origem (opcional)"
                      mode="number"
                      maxLength={9}
                      value={filtro.idTemplateOrigem}
                      onChange={props.atualizarIdTemplateOrigemPesquisaTemplate}/> 
              </Grid>
            }
          </Grid>

          <Box display="flex" p={2} position="sticky" top={0} bgcolor="#fff" mt={1} justifyContent="flex-end">
                
            <BrButton id="btnLimpar" secondary className="mr-2 ml-2"
              onClick={props.limparCamposPesquisa}>
              <i className="fas fa-eraser"></i>
              Limpar Campos
            </BrButton>
            <BrButton id="btnPesquisar" primary
              onClick={() => props.pesquisar(filtro)}>
              <i className={"fas fa-search"} />
              Pesquisar
            </BrButton>          
          </Box>

          {
            template && Object.keys(template).length > 0 && 
            <BrButton id="btnExibirTemplate" secondary
              onClick={() => props.exibirDialogTemplate()}>
              <i className={"fas fa-eye"} />
              Clique aqui para visualizar template consultado
            </BrButton>  
          }

          <DialogTemplate />
        </>
      }

      <DialogDetalhesResultadoTesteAPI 
        isDetalharTeste={isDetalharTeste}
        testeSelecionado={testeSelecionado}
        funcaoOcultar={ocultarDialogDetalheTeste} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  userData: state.principal.userData,
  dados: state.consultasAPI
});

const mapDispatchToProps = {
  limparMensagem,
  limparDadosPericia,
  displayLoader,
  errorMessage,
  successMessage,
  limparTemplate,
  limparCacheDominiosBI,
  limparCacheTemplates, 
  limparCacheSdc,
  limparCamposPesquisa,
  atualizarTipoPesquisaTemplate,
  atualizarIdPericiaPesquisaTemplate,
  atualizarIdTemplatePesquisaTemplate, 
  atualizarIdTemplateOrigemPesquisaTemplate,
  atualizarTipoPericiaPesquisaTemplate, 
  atualizarDataReferenciaPesquisaTemplate,
  pesquisar,
  exibirDialogTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultasAPI);