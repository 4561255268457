import React from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import HeaderSecao from '../../../components/HeaderSecao';
import { formatarCPF, formatarNIT, formatarTelefone, format } from '../../../common/formatadores';

class DadosCadastrais extends React.Component {

  render() {
    const { dados } = this.props;
    const endereco = dados.endereco;

    return (
      <div id="abaDadosCadastrais">

        <HeaderSecao texto="Dados do avaliado" />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <span htmlFor="nome">Nome: </span>
            <span id="nome" className="texto-informativo">{dados.nome}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="nomeSocial">Nome Social: </span>
            <span id="nomeSocial" className="texto-informativo">{dados.nomeSocial ? dados.nomeSocial : '---'}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="nomeMae">Nome da Mãe: </span>
            <span id="nomeMae" className="texto-informativo">{dados.nomeMae}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="idade">Idade: </span>
            <span id="idade" className="texto-informativo">{dados.idadePorExtenso}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="nit">Nit: </span>
            <span id="nit" className="texto-informativo">{formatarNIT(dados.nit)}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="cpf">CPF: </span>
            <span id="cpf" className="texto-informativo">{formatarCPF(dados.cpf)}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="dataNascimento">Data de Nascimento: </span>
            <span id="dataNascimento" className="texto-informativo">{dados.dataNascimentoFormatada}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="sexo">Gênero: </span>
            <span id="sexo" className="texto-informativo">{dados.descricaoSexo}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="rg">RG: </span>
            <span id="rg" className="texto-informativo">{dados.identidade}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="orgaoEmissor"> Órgão Emissor: </span>
            <span id="orgaoEmissor" className="texto-informativo">{dados.descricaoOrgaoEmissorIdentidade}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="estadoCivil">Estado Civil: </span>
            <span id="estadoCivil" className="texto-informativo">{dados.descricaoEstadoCivil}</span>
          </Grid>
          <Grid item xs={6}>
            <span htmlFor="escolaridade"> Escolaridade: </span>
            <span id="escolaridade" className="texto-informativo">{dados.descricaoGrauInstrucao}</span>
          </Grid>
        </Grid>

        <HeaderSecao texto="Dados de endereço" />
        {
          endereco ?
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <span htmlFor="tipoLogradouro"> Tipo Logradouro: </span>
                  <span id="tipoLogradouro" className="texto-informativo">{endereco.descricaoTipoLogradouro}</span>
                </Grid>
                <Grid item xs={6}>
                  <span htmlFor="logradouro"> Logradouro: </span>
                  <span id="logradouro" className="texto-informativo">{endereco.logradouro}</span>
                </Grid>
                <Grid item xs={12}>
                  <span htmlFor="bairro"> Bairro: </span>
                  <span id="bairro" className="texto-informativo">{endereco.bairro}</span>
                </Grid>
                <Grid item xs={6}>
                  <span htmlFor="numero"> Número: </span>
                  <span id="numero" className="texto-informativo">{endereco.numero}</span>
                </Grid>
                <Grid item xs={6}>
                  <span htmlFor="complemento"> Complemento: </span>
                  <span id="complemento" className="texto-informativo">{endereco.complemento}</span>
                </Grid>
                <Grid item xs={12}>
                  <span htmlFor="cep"> CEP: </span>
                  <span id="cep" className="texto-informativo">{format('#####-###', endereco.numeroCep)}</span>
                </Grid>
                <Grid item xs={12}>
                  <span htmlFor="municipio"> Município: </span>
                  <span id="municipio" className="texto-informativo">{endereco.municipio}</span>
                </Grid>
                <Grid item xs={12}>
                  <span htmlFor="uf"> UF: </span>
                  <span id="uf" className="texto-informativo">{endereco.uf}</span>
                </Grid>
                <Grid item xs={12}>
                  <span htmlFor="telefone"> Telefone: </span>
                  <span id="telefone" className="texto-informativo">{formatarTelefone(endereco.numeroTelefone)}</span>
                </Grid>
              </Grid>
            </> :
            <p>Não há endereço cadastrado na base da receita federal</p>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dados: state.pericia.avaliado,
  idAbaSelecionada: state.principal.idAbaSelecionada
});

export default connect(mapStateToProps)(DadosCadastrais);