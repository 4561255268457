import { formatarDateTimeISOToDataBr, validarData, compararDatasSemHoras } from '../../../../common/DataUtils';

const MSG_CAMPO_OBRIGATORIO = "Campo obrigatório";

export const ID_TIPO_OUTRO_IDENT_EMITENTE = 0;
export const IDS_TIPOS_EMITENTE_COM_UF = [1, 3, 5, 6, 7];
export const ID_TIPO_EMITENTE_CNPJ = 2;
export const ID_TIPO_EMITENTE_CEI = 4;
export const ID_TIPO_EXAME_EXAME = 4;

const TAMANHO_CNPJ = 14;
const TAMANHO_CEI = 12;

export const verificarSeCamposPreenchidos = pericia => {
  const moduloExameLaudo = pericia?.moduloExameLaudo;
  const deveValidarExamesLaudos = moduloExameLaudo
    && (moduloExameLaudo.naoExisteExameLaudo != null
      || (moduloExameLaudo.examesLaudo && moduloExameLaudo.examesLaudo.length > 0));

  return deveValidarExamesLaudos
}

export const validar = pericia => {

  const moduloExameLaudo = pericia?.moduloExameLaudo;

  const { naoExisteExameLaudo, examesLaudo } = moduloExameLaudo;

  const erroRespostaObrigatoria = (!naoExisteExameLaudo && (!examesLaudo || examesLaudo.length === 0))
    ? "É obrigatório marcar opção se não existe(m) exame/laudo(s) apresentado(s)"
    : null;

  const erroOpcaoIncompativel = (naoExisteExameLaudo === 1 && examesLaudo && examesLaudo.length > 0)
    ? `Opção incompatível com ${examesLaudo.length} exame/laudo(s) apresentado(s). Desmarque a opção ou exclua o(s) exame/laudo(s) apresentado(s).`
    : null;

  return {
    isValida: !erroRespostaObrigatoria && !erroOpcaoIncompativel,
    erroNaoApresentouExames: erroRespostaObrigatoria ? erroRespostaObrigatoria : erroOpcaoIncompativel
  };
}

export const obterDadosValidacaoExameLaudo = exameLaudo => {
  const { dataDocumento, outroTipoIdentEmitente, nomeProfissional, idTipoExameLaudo,
    idTipoIdentEmitente, numeroDocumento, idUFDocumento, descricaoLaudo } = exameLaudo;

  const erroDataObrigatoria = !dataDocumento ? MSG_CAMPO_OBRIGATORIO : null;
  const erroDataInvalida = dataDocumento && !validarData(formatarDateTimeISOToDataBr(dataDocumento))
    ? "Data inválida"
    : null;

  const erroDataMaiorQueAtual = dataDocumento && compararDatasSemHoras(new Date(dataDocumento), new Date()) > 0
    ? "A Data do Documento deve ser anterior ou igual à data atual."
    : null;

  const isTipoExame = idTipoExameLaudo !== null && idTipoExameLaudo === ID_TIPO_EXAME_EXAME;
  const isOutroTipoIdentEmitente = idTipoIdentEmitente !== null && idTipoIdentEmitente === ID_TIPO_OUTRO_IDENT_EMITENTE;

  const erroTipoExame = (idTipoExameLaudo === undefined || idTipoExameLaudo === null) ? MSG_CAMPO_OBRIGATORIO : null;
  const erroTipoEmitente = !isTipoExame && (idTipoIdentEmitente === undefined || idTipoIdentEmitente === null) ? MSG_CAMPO_OBRIGATORIO : null;

  const erroOutroTipoIdentEmitente = !isTipoExame && isOutroTipoIdentEmitente && (!outroTipoIdentEmitente || outroTipoIdentEmitente.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;
  const erroNumeroDocumentoObrigatorio = !isTipoExame && !numeroDocumento ? MSG_CAMPO_OBRIGATORIO : null;
  const erroNomeProfissional = !isTipoExame && (!nomeProfissional || nomeProfissional.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;

  const isCnpj = idTipoIdentEmitente && idTipoIdentEmitente === ID_TIPO_EMITENTE_CNPJ;
  const erroTamanhoCNPJ = (numeroDocumento && isCnpj && numeroDocumento.length !== TAMANHO_CNPJ)
    ? `CNPJ teve ter ${TAMANHO_CNPJ} dígitos`
    : null;

  const isCei = idTipoIdentEmitente && idTipoIdentEmitente === ID_TIPO_EMITENTE_CEI;
  const erroTamanhoCei = (numeroDocumento && isCei && numeroDocumento.length !== TAMANHO_CEI)
    ? `CEI teve ter ${TAMANHO_CEI} dígitos`
    : null;
  const erroTamanhoNumeroDocumento = erroTamanhoCNPJ ? erroTamanhoCNPJ : erroTamanhoCei;

  const isUfObrigatoria = !isTipoExame && IDS_TIPOS_EMITENTE_COM_UF.find(i => i === idTipoIdentEmitente);
  const erroUf = isUfObrigatoria && !idUFDocumento ? MSG_CAMPO_OBRIGATORIO : null;

  const erroDescricaoLaudo = (!descricaoLaudo || descricaoLaudo.trim().length === 0) ? MSG_CAMPO_OBRIGATORIO : null;

  return {
    isValida: !erroDataObrigatoria && !erroDataInvalida && !erroDataMaiorQueAtual && !erroTipoEmitente && !erroOutroTipoIdentEmitente &&
      !erroNumeroDocumentoObrigatorio && !erroTamanhoNumeroDocumento && !erroUf && !erroNomeProfissional && !erroDescricaoLaudo && !erroTipoExame,
    erroDataDocumento: erroDataObrigatoria
      ? erroDataObrigatoria
      : (erroDataInvalida ? erroDataInvalida : erroDataMaiorQueAtual),
    erroTipoExame,
    erroTipoEmitente,
    erroOutroTipoIdentEmitente,
    erroNomeProfissional,
    erroNumeroDocumento: erroNumeroDocumentoObrigatorio ? erroNumeroDocumentoObrigatorio : erroTamanhoNumeroDocumento,
    erroUf,
    erroDescricaoLaudo
  };
}