import React from "react";

import BrTable from "@govbr/react-components/lib/BrTable";

const TabelaCidsSecundarios = ({ cids, isValorAtual }) => {
  const infoClass = isValorAtual ? "valor-atual-revisao":"valor-anterior-revisao";

  return (
    <BrTable
      id="detalheTabelaCidsSecundarios"
      layout="fixed"
      columns={[
        { Header: "Código - Descrição", accessor: "cid" }
      ]}
      data={cids.map((item) => ({
        cid: <span className={`texto-informativo ${infoClass}`}>{(`${item.cidSecundario} - ${item.nomeCidSecundario}`) || "---"}</span>
      }))}
      headerProps={{
        show: false,
        showDensityOptions: false,
        searchProp: {
          show: false,
        },
      }}
      footerProps={{ show: false }}
    />);
}

export default TabelaCidsSecundarios;