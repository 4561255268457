import { compararDatasSemHoras, formatarDateTimeISOToDataBr, validarData } from '../../common/DataUtils';
import { validarCampoObrigatorio } from '../../common/ValidationUtil';
import { modulosEstaticos, tiposPericia, tiposSolicitacaoExigencia } from './constants';

import * as FormularioGenerico from './modulos/FormularioGenerico/validacao';
import * as ModuloGenerico from './modulos/Generico/validacao';

const MSG_CAMPO_OBRIGATORIO = "Campo obrigatório";

export const obterDadosValidacaoCampos = (abaSelecionada, pericia, getState) => {
  const template = getState().principal.template;
  const moduloTemplate = Object.values(modulosEstaticos).find(m => m.codigo === abaSelecionada) ?
    template.modulosTemplatePericia.find(m => m.modulo.tipoModuloEstatico === abaSelecionada) :
    template.modulosTemplatePericia.find(t => t.id === abaSelecionada);

  let urlProximaAba = obterUrlProximaAba(moduloTemplate, template);

  if (moduloTemplate.modulosTemplateFilhos.length > 0) {
    let dados = undefined;
    let retornoFilho = undefined;
    let nome = undefined;
    let deveProsseguir = true;
    let isValida = true;
    moduloTemplate.modulosTemplateFilhos.forEach(moduloTemplateFilho => {
      if (moduloTemplateFilho.modulo.tipoModuloEstatico) { //modulo estatico
        retornoFilho = obterDadosValidacaoModuloEstatico(moduloTemplateFilho, pericia, urlProximaAba, getState);
      } else {
        retornoFilho = obterDadosValidacaoModuloGenerico(moduloTemplateFilho, pericia, urlProximaAba);
      }
      if(retornoFilho?.dados) {
        nome = Object.values(modulosEstaticos).find(m => m.codigo === (moduloTemplateFilho.modulo.tipoModuloEstatico)) ?
          Object.values(modulosEstaticos).find(m => m.codigo === (moduloTemplateFilho.modulo.tipoModuloEstatico)).nome :
          `modulo_generico_${moduloTemplateFilho.moduloTemplatePai}`
        dados = { ...dados, ...retornoFilho.dados };
        deveProsseguir = deveProsseguir && retornoFilho.deveProsseguir;
        isValida = isValida && retornoFilho.dados[nome].isValida;
      }
    });
    return {
      dados: {
        ...dados,
        [`modulo_generico_${moduloTemplate.id}`]: { isValida: isValida }

      },
      deveProsseguir: deveProsseguir,
      url: urlProximaAba
    };
  } else {
    if (moduloTemplate && moduloTemplate.modulo.tipoModuloEstatico) { //modulo estatico
      return obterDadosValidacaoModuloEstatico(moduloTemplate, pericia, urlProximaAba, getState);
    } else if (moduloTemplate && moduloTemplate.modulo.formulario) { //modulo formulario generico
      return obterDadosValidacaoModuloFormularioGenerico(moduloTemplate, pericia, urlProximaAba);
    } else {
      return obterDadosValidacaoModuloGenerico(moduloTemplate, pericia, urlProximaAba, getState);
    }
  }
};

const obterDadosValidacaoModuloFormularioGenerico = (moduloTemplateGenerico, pericia, urlProximaAba) => {
  const dados = FormularioGenerico.validar(moduloTemplateGenerico, pericia);
  const nomeModulo = FormularioGenerico.obterNomeModulo(moduloTemplateGenerico);

  return {
    dados: { [nomeModulo]: dados },
    deveProsseguir: dados.isValida,
    url: urlProximaAba
  }
}

const obterDadosValidacaoModuloGenerico = (moduloTemplateGenerico, pericia, urlProximaAba, getState) => {
  const dados = ModuloGenerico.validar(moduloTemplateGenerico, pericia, getState);
  const nomeModulo = ModuloGenerico.obterNomeModulo(moduloTemplateGenerico);

  let dadosRetorno = { [nomeModulo]: dados };

  dadosRetorno = { ...dadosRetorno, ...dados.dadosModuloEstatico }


  return {
    dados: { ...dadosRetorno },
    deveProsseguir: dados.isValida,
    url: urlProximaAba
  }
}

const obterDadosValidacaoModuloEstatico = (moduloTemplateEstatico, pericia, urlProximaAba, getState) => {

  const moduloEstatico = Object.values(modulosEstaticos)
    .find(moduloEstatico => moduloEstatico.codigo === moduloTemplateEstatico.modulo.tipoModuloEstatico);

  if (moduloEstatico && moduloEstatico.hasOwnProperty('validar')) {
    const dados = moduloEstatico.validar(pericia, getState);
    return {
      dados: { [moduloEstatico.nome]: dados },
      deveProsseguir: dados.isValida,
      url: urlProximaAba
    }
  } else {
    return {
      dados: null,
      deveProsseguir: true,
      url: urlProximaAba
    }
  }

}

export const obterUrlProximaAba = (moduloAtual, template) => {
  let ultimaAba = undefined;
  switch (template.tipoPericia) {
    case tiposPericia.INSS_BPC_PI: {
      ultimaAba = template.modulosTemplatePericia.find(m => m.modulo.tipoModuloEstatico === modulosEstaticos.RESULTADO_PARCIAL_BPC.codigo)
      break;
    }
    case tiposPericia.INSS_BI_PI_DOCMED: {
      ultimaAba = template.modulosTemplatePericia.find(m => m.modulo.tipoModuloEstatico === modulosEstaticos.CONCLUSAO_BI_DOCMED.codigo)
      break;
    }
    default:
      ultimaAba = undefined;
      break;
  }

  const proximaAba = template.modulosTemplatePericia.find(m => m.ordemExibicao > moduloAtual.ordemExibicao)
  let urlProximaAba = undefined;

  if (!proximaAba) return ultimaAba;

  if (proximaAba.modulo.tipoModuloEstatico) {
    urlProximaAba = Object.values(modulosEstaticos).find(m => m.codigo === proximaAba.modulo.tipoModuloEstatico).nome;
  } else {
    urlProximaAba = proximaAba.modulo?.formulario
      ? FormularioGenerico.obterNomeModulo(proximaAba)
      : ModuloGenerico.obterNomeModulo(proximaAba);
  }

  return urlProximaAba;
}

export const obterDadosValidacaoCompleta = (pericia, getState, isValidacaoFinal) => {
  const template = getState().principal?.template;
  const modulosTemplate = template ? Object.values(template?.modulosTemplatePericia) : [];
  let retorno;

  modulosTemplate.forEach(moduloTemplate => {
    if (moduloTemplate.modulosTemplateFilhos && moduloTemplate.modulosTemplateFilhos.length > 0) {
      let isValida = true;
      let existeFilhoComValidacao = false;

      moduloTemplate.modulosTemplateFilhos.forEach(filho => {
        const validacaoFilho = obterDadosValidacaoModulo(filho, pericia, getState);
        if (validacaoFilho) {
          retorno = { ...retorno, ...validacaoFilho };
          existeFilhoComValidacao = existeFilhoComValidacao || validacaoFilho !== null;
  
          const nomeModuloFilho = filho.modulo?.tipoModuloEstatico
            ? Object.values(modulosEstaticos).find(moduloEstatico => moduloEstatico.codigo === filho.modulo.tipoModuloEstatico).nome
            : ModuloGenerico.obterNomeModulo(filho);
          isValida = isValida && validacaoFilho !== null && validacaoFilho[nomeModuloFilho].isValida;
        }
      });
      if (existeFilhoComValidacao || isValidacaoFinal) {
        retorno = { ...retorno, [ModuloGenerico.obterNomeModulo(moduloTemplate)]: { isValida: isValida } };
      }
    } else {
      retorno = { ...retorno, ...obterDadosValidacaoModulo(moduloTemplate, pericia, getState, isValidacaoFinal) };
    }
  });

  return retorno;
}

const obterDadosValidacaoModulo = (moduloTemplate, pericia, getState, isValidacaoFinal) => {
  const tipoModuloEstatico = moduloTemplate.modulo?.tipoModuloEstatico;
  if (tipoModuloEstatico) {

    const moduloEstatico = Object.values(modulosEstaticos)
      .find(moduloEstatico => moduloEstatico.codigo === tipoModuloEstatico);
    const deveValidarModuloEstatico = moduloEstatico && moduloEstatico.hasOwnProperty('validar')
      && (moduloEstatico.verificarSeCamposPreenchidos(pericia, getState)
        || isValidacaoFinal);

    if (deveValidarModuloEstatico) {

      const resultadoValidacao = moduloEstatico.validar(pericia, getState);
      if (resultadoValidacao) {
        return { [moduloEstatico.nome]: resultadoValidacao }
      }
    }
  } else if (moduloTemplate.modulo?.formulario) { //modulo formulario generico
    const deveValidarFormularioGenerico = FormularioGenerico.verificarSeCamposPreenchidos(moduloTemplate, pericia)
      || isValidacaoFinal;

    if (deveValidarFormularioGenerico) {

      const resultadoValidacao = FormularioGenerico.validar(moduloTemplate, pericia);
      const nomeModulo = FormularioGenerico.obterNomeModulo(moduloTemplate);

      if (resultadoValidacao)
        return { [nomeModulo]: resultadoValidacao }
    }
  } else {
    const deveValidarModuloGenerico = ModuloGenerico.verificarSeCamposPreenchidos(moduloTemplate, pericia, getState)
      || isValidacaoFinal;

    if (deveValidarModuloGenerico) {
      const dados = ModuloGenerico.validar(moduloTemplate, pericia, getState);
      const nomeModulo = ModuloGenerico.obterNomeModulo(moduloTemplate);

      let dadosRetorno = { [nomeModulo]: dados };
      if (dados.dadosModuloEstatico) {
        dadosRetorno = { ...dadosRetorno, ...dados.dadosModuloEstatico }
      }
      return { ...dadosRetorno };
    }
  }

  return null;
}

export const obterDadosValidacaoSima = (justificativa, tipoSolicitacaoExigencia) => {
  const isJustificativaPreenchida = justificativa != null && justificativa.trim().length > 0;
  const mensagemErroJustificativa = !isJustificativaPreenchida ? MSG_CAMPO_OBRIGATORIO : null;
  const mensagemErroTipoSolicitacao = tipoSolicitacaoExigencia ? null: MSG_CAMPO_OBRIGATORIO;

  return {
    isValida: !mensagemErroJustificativa && !mensagemErroTipoSolicitacao,
    mensagemErroJustificativa,
    mensagemErroTipoSolicitacao
  };
}

export const validarRetornoSima = (dadosRetorno, tipoSolicitacao) => {
  const {resultadoSima, nomeProfissional, crmProfissional, idUFCrmProfissional, dataDocumento } = dadosRetorno;

  let erros;
  const valResultado = validarCampoObrigatorio(resultadoSima);

  if(tipoSolicitacao === tiposSolicitacaoExigencia.OUTRAS.id){
    //validacoes outras exigencias
    erros = {
    ...(!valResultado.valido && { erroResultado: valResultado.msg })
    };
  }else{
    //validacoes SIMA
    const valNomeProfissional = validarCampoObrigatorio(nomeProfissional);
    const valCrmProfissional = validarCampoObrigatorio(crmProfissional);
    const valUf = validarCampoObrigatorio(idUFCrmProfissional);

    let valDataDocumento = validarCampoObrigatorio(dataDocumento);
    if (valDataDocumento.valido) {
      if (compararDatasSemHoras(dataDocumento, new Date()) > 0) {
        valDataDocumento = { valido: false, msg: 'A Data do Documento deve ser anterior ou igual à data atual.' }
      } else if (!validarData(formatarDateTimeISOToDataBr(dataDocumento))) {
        valDataDocumento = { valido: false, msg: 'Data inválida' }
      }
    }

    erros = {
      ...(!valNomeProfissional.valido && { erroNomeProfissional: valNomeProfissional.msg }),
      ...(!valCrmProfissional.valido && { erroCrmProfissional: valCrmProfissional.msg }),
      ...(!valUf.valido && { erroUf: valUf.msg }),
      ...(!valDataDocumento.valido && { erroDataDocumento: valDataDocumento.msg }),
      ...(!valResultado.valido && { erroResultado: valResultado.msg })
    };
  }

  return {
    isValida: Object.keys(erros).length === 0,
    ...erros
  };
}