import { compararDatasSemHoras, formatarDateTimeISOToDataBr, validarData } from '../../common/DataUtils';
import { postSeguroPromise } from "../../common/requests";
import { displayLoader, errorMessage, successMessage, consultaMessage, limparMensagem } from '../principal/action';

export const alterarStatus = value => ({
  type: 'ALTERAR_STATUS_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarTipo = value => ({
  type: 'ALTERAR_TIPO_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarProtocoloPmf = value => ({
  type: 'ALTERAR_PROTOCOLO_PMF_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarDataInicio = value => ({
  type: 'ALTERAR_DATA_INICIO_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarDataFim = value => ({
  type: 'ALTERAR_DATA_FIM_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarErro = value => ({
  type: 'ALTERAR_ERRO_FILTRO_COMUNICACAO',
  payload: value
});

export const alterarTipoPericia = value => ({
  type: 'ALTERAR_TIPO_PERICIA_FILTRO_COMUNICACAO',
  payload: value
}); 

export const alterarCpf = value => ({
  type: 'ALTERAR_CPF_FILTRO_COMUNICACAO',
  payload: value
}); 

export const alterarTipoFiltroMensagemErro = value => ({
  type: 'ALTERAR_FILTRO_MENSAGEM_ERRO',
  payload: value
});

export const alterarFiltroMaxTentativas = value => ({
  type: 'ALTERAR_FILTRO_MAX_TENTATIVAS',
  payload: value
});

export const pesquisar = (filtro,tipoFiltroErro) => {
  const dados = validarCamposPesquisa(filtro);
  return (dispatch) => {
    dispatch(displayLoader(true));
    dispatch(limparMensagem())
    dispatch(atualizarDadosValidacaoFiltro(dados));
    if (dados.isValida) {
      dispatch(consultarComunicacoesPorFiltro(filtro,tipoFiltroErro));
      dispatch(atualizarFiltroCsv(filtro));
    } else {
      dispatch(atualizarDadosConsulta(null));

      dispatch(displayLoader(false));
    }
  }
}

export const pesquisarTodosItens = (filtro) => {
  const dados = validarCamposPesquisa(filtro);
  return (dispatch) => {
    dispatch(displayLoader(true));
    dispatch(atualizarDadosValidacaoFiltro(dados));
    if (dados.isValida) {
      dispatch(consultarComunicacoesPorFiltroCheio(filtro));
    } else {
      dispatch(atualizarDadosCsv(null));
      dispatch(displayLoader(false));
    }
  }
}

const consultarComunicacoesPorFiltro = (filtro) => {
  return async dispatch => {
    return await postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/`, filtro)
      .then((response) => {
        dispatch(atualizarDadosConsulta(response.data));
        dispatch(displayLoader(false));
        if (response.data.quantidadeItens > 0){
          dispatch(consultaMessage(`A busca retornou <strong>${response.data.quantidadeItens}</strong> registros.`))
        }
        return 1;
      })
      .catch((falha) => {
        dispatch(atualizarDadosConsulta(null));
        dispatch(errorMessage(falha, 'Consulta comunicação externa'));
        return 0;
      });
  };
}

const consultarComunicacoesPorFiltroCheio = (filtro) => {
  return async dispatch => {
    return await postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/`, filtro)
      .then((response) => {
        dispatch(atualizarDadosCsv(response.data));
        dispatch(displayLoader(false));
        return 1;
      })
      .catch((falha) => {
        dispatch(atualizarDadosCsv(null));
        dispatch(errorMessage(falha, 'Consulta comunicação externa CSV'));
        return 0;
      });
  };
}

export const reenviarComunicacao = (idComunicacao, filtroAtual) => {
  return async dispatch => {
    dispatch(displayLoader(true));
    return await postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/${idComunicacao}/reenviar/`)
      .then((response) => {
        postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/`, filtroAtual)
          .then((responseConsulta) => {
            dispatch(atualizarDadosConsulta(responseConsulta.data));
          })
          .catch((falha) => {
            dispatch(atualizarDadosConsulta(null));
            dispatch(errorMessage(falha, 'Consulta comunicação externa após reenvio'));
            return 0;
          });
        dispatch(successMessage(`Reenvio comunicação externa id=${idComunicacao} realizado com sucesso. Nova comunicação id=${response.data.id}.`));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Reenvio comunicação externa'));
        return 0;
      });
  };
}

export const atualizarDadosConsulta = dados => ({
  type: 'CONSULTA_COMUNICACAO_EXTERNA',
  payload: dados
});

export const atualizarDadosCsv = dados => ({
  type: 'CONSULTA_COMUNICACAO_EXTERNA_CSV',
  payload: dados
});

export const atualizarFiltroCsv = dados =>({
  type: 'ATUALIZAR_FILTRO_CONSULTA_CSV',
  payload: dados
});

export const atualizarDadosValidacaoFiltro = dados => ({
  type: 'ATUALIZAR_DADOS_VALIDACAO_COMUNICACAO',
  payload: dados
});

export const limpar = () => ({
  type: 'LIMPAR_CAMPOS_COMUNICACAO'
});

export const reenviarEmLote = (listaIdsComunicacao, filtroAtual) => {
  return async dispatch => {
    dispatch(displayLoader(true));
    return await postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/reenviar-lote/`, listaIdsComunicacao)
      .then((response) => {
        postSeguroPromise(`pmf-realizar-pericias-service/comunicacoes/`, filtroAtual)
          .then((responseConsulta) => {
            dispatch(atualizarDadosConsulta(responseConsulta.data));
          })
          .catch((falha) => {
            dispatch(atualizarDadosConsulta(null));
            dispatch(errorMessage(falha, 'Consulta comunicação externa após reenvio em lote'));
            return 0;
          });
        dispatch(successMessage(`Reenvio em lote concluído com sucesso. Refaça a consulta para verificar processamento.`));
        return 1;
      })
      .catch((falha) => {
        dispatch(errorMessage(falha, 'Reenvio em lote'));
        return 0;
      });
  };
};

export const exibirDialogDetalheComunicacao = (comunicacao) => ({
  type: 'EXIBIR_DIALOG_DETALHE_COMUNICACAO',
  payload: comunicacao
});

export const ocultarDialogDetalheComunicacao = () => ({
  type: 'OCULTAR_DIALOG_DETALHE_COMUNICACAO'
});

const validarCamposPesquisa = filtro => {
  const { dataInicio, dataFim } = filtro;

  const erroDataInicioInvalida = dataInicio && !validarData(formatarDateTimeISOToDataBr(dataInicio))
    ? "Data inválida"
    : null;

  const erroDataInicioMaiorQueAtual = dataInicio && compararDatasSemHoras(new Date(dataInicio), new Date()) > 0
    ? "A data inicio deve ser anterior ou igual à data atual."
    : null;

  const erroDataFimInvalida = dataFim && !validarData(formatarDateTimeISOToDataBr(dataFim))
    ? "Data inválida"
    : null;

  const erroDataFimMaiorQueAtual = dataFim && compararDatasSemHoras(new Date(dataFim), new Date()) > 0
    ? "A data fim deve ser anterior ou igual à data atual."
    : null;

  return {
    isValida: !erroDataInicioInvalida && !erroDataInicioMaiorQueAtual && !erroDataFimInvalida && !erroDataFimMaiorQueAtual,
    erroDataInicio: erroDataInicioInvalida ? erroDataInicioInvalida : erroDataInicioMaiorQueAtual,
    erroDataFim: erroDataFimInvalida ? erroDataFimInvalida : erroDataFimMaiorQueAtual
  }
}
