import { compararDatasSemHoras, converterDataFormatadaParaISOBr } from '../../../../common/DataUtils';
import { obterDataParametroPeriodo } from '../../templatePericiaUtil';

const ESPECIES_BENEFICIO_AA = [36, 94];
const ESPECIES_BENEFICIO_AD = [31, 91];

export const obterBeneficiosParaAgravamentoLesao = listaBeneficios => {
  return listaBeneficios?.filter(b => ESPECIES_BENEFICIO_AA.includes(b.numeroEspecieBeneficio) && b.situacao === "ATIVO" && b.dataInicioBeneficio);

}

export const obterUltimoBeneficioParaAgravamento = listaAA => {
  const listaFinal = listaAA
    .sort((a, b) => compararDatasSemHoras(converterDataFormatadaParaISOBr(a.dataInicioBeneficio), converterDataFormatadaParaISOBr(b.dataInicioBeneficio)) > 0);

  return [listaFinal[0]];
}

export const obterBeneficiosParaRestabelecimento = (listaBeneficios, derAtual, valorParametroPeriodoRestabelecimento, numeroBeneficioAtual) => {
  return listaBeneficios?.filter(b => ESPECIES_BENEFICIO_AD.includes(b.numeroEspecieBeneficio)
    && (!numeroBeneficioAtual 
        || (numeroBeneficioAtual !== undefined && b.numeroBeneficio !== numeroBeneficioAtual))
    && (b.dataCessacaoBeneficio && isDcbBeneficioNoPeriodoParaRestabelecer(b.dataCessacaoBeneficio, derAtual, valorParametroPeriodoRestabelecimento)))
}

export const obterUltimoBeneficioParaRestabelecimento = listaAD => {
  const listaFinal = listaAD
    .sort((a, b) => compararDatasSemHoras(converterDataFormatadaParaISOBr(a.dataCessacaoBeneficio), converterDataFormatadaParaISOBr(b.dataCessacaoBeneficio)) > 0);

  return [listaFinal[0]];
}

const isDcbBeneficioNoPeriodoParaRestabelecer = (dcbBeneficio, derAtual, valorParametroPeriodoRestabelecimento) => {
  const dataEntradaRequerimentoAtual = converterDataFormatadaParaISOBr(derAtual);
  const dataCessacaoBeneficio = converterDataFormatadaParaISOBr(dcbBeneficio);
  const dataInicioPeriodoRestabelecimento = obterDataParametroPeriodo(valorParametroPeriodoRestabelecimento, dataEntradaRequerimentoAtual, true);

  return compararDatasSemHoras(dataInicioPeriodoRestabelecimento, dataCessacaoBeneficio) < 1
    && compararDatasSemHoras(dataCessacaoBeneficio, dataEntradaRequerimentoAtual) < 0;
}