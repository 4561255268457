import React from 'react'
import { connect } from "react-redux";
import BrCard from '@govbr/react-components/lib/BrCard';
import { tipoSimNao } from '../../constants';

class ManutencaoDataCessacao extends React.Component {

  render() {
    const { dataCessacao, indicaDCA } = this.props;
    return (
      <div id="idManutencaoDataCessacao">
        {(!indicaDCA || tipoSimNao.NAO.descricao === indicaDCA)
            ? <BrCard>
                <span htmlFor="dataCessacao">A atual Data de Cessação do Benefício (DCB) em: <b>{dataCessacao || "data não informada"}</b></span>
              </BrCard>
            : <BrCard>
                <span htmlFor="dataCessacao">A atual Data de Cessação Administrativa (DCA) em: <b>{dataCessacao || "data não informada"}</b></span>
              </BrCard>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataCessacao: state.pericia.periciaAnterior?.dataCessacaoBeneficio,
  indicaDCA: state.pericia.periciaAnterior?.indicaDCA,
});

export default connect(mapStateToProps)(ManutencaoDataCessacao);