import { verificarRegraValidacaoCampoGenerico } from '../../../../common/CampoGenericoUtils';
import { convertStringToDate } from '../../../../common/DataUtils';
import { validarCampoObrigatorio, validarValorMinimo } from '../../../../common/ValidationUtil';
import { modulosEstaticos, tipoMensagemValidacaoCampoGenerico, tiposCampoGenerico, tipoSimNao } from '../../constants';

//TODO: renomear modulo usando hash, nao expor id BD
export const obterNomeModulo = moduloTemplateGenerico => `modulo_generico_${moduloTemplateGenerico?.id}`;

export const verificarSeCamposPreenchidos = (moduloTemplateGenerico, pericia, getState) => {
  const existemValoresCamposGenericos = pericia.valoresCamposGenericos && pericia.valoresCamposGenericos.length > 0;

  let existemCamposPreenchidosNoModulo = false;
  if (moduloTemplateGenerico.modulo.camposGenericos && moduloTemplateGenerico.modulo.camposGenericos.length > 0) {
    moduloTemplateGenerico.modulo.camposGenericos.every(campoGenerico => {
      const valorCampoGenerico = pericia.valoresCamposGenericos.find(valor => valor.siglaCampoGenerico === campoGenerico.campoGenerico.sigla);
      if(valorCampoGenerico && valorCampoGenerico.valorTextoCampoGenerico !== campoGenerico.campoGenerico.valorPadrao) {
        existemCamposPreenchidosNoModulo = true;
        return false;
      }
      return true;
    })
  }

  const existemSecoesComCamposPreenchidosNoModulo = moduloTemplateGenerico.modulo.secoes
    && (moduloTemplateGenerico.modulo.secoes.find(s => {

      let secaoComModuloEstaticoFilho = false;
      if (s.moduloEstaticoFilho) {
        const moduloEstatico = Object.values(modulosEstaticos)
          .find(moduloEstatico => moduloEstatico.codigo === s.moduloEstaticoFilho.tipoModuloEstatico);
        secaoComModuloEstaticoFilho = moduloEstatico && moduloEstatico.hasOwnProperty('validar')
          && moduloEstatico.verificarSeCamposPreenchidos(pericia, getState);
      }

      let secaoComCamposGenericosPreenchidos = false;
      if (s.secoesModulosCamposGenericos && s.secoesModulosCamposGenericos.length > 0 && existemValoresCamposGenericos) {
        const siglasCamposGenericosDaSecao = s.secoesModulosCamposGenericos.map(sc => sc.campoGenerico.sigla);
        secaoComCamposGenericosPreenchidos = pericia.valoresCamposGenericos.find(v => siglasCamposGenericosDaSecao.indexOf(v.siglaCampoGenerico) > -1);
      }

      return secaoComModuloEstaticoFilho || secaoComCamposGenericosPreenchidos;
    }
    ));

  return existemCamposPreenchidosNoModulo || existemSecoesComCamposPreenchidosNoModulo;
}


const validarCampoGenerico = (campo, pericia, erro) => {
  const idCampo = campo.campoGenerico.id;
  const tipoCampo = campo.campoGenerico.tipo;
  const nomeCampo = campo.campoGenerico.nome;
  let valorCampo = pericia.valoresCamposGenericos
    ? pericia.valoresCamposGenericos.find(v => v.idCampoGenerico === idCampo)?.valorTextoCampoGenerico
    : undefined;

  if (campo.campoGenerico.campoObrigatorio === tipoSimNao.SIM.descricao) { //regra campo obrigatório       
    const resultado = validarCampoObrigatorio(valorCampo);
    if (!resultado.valido) {
      erro = { ...erro, [`erro_${idCampo}`]: resultado.msg };
    } else if(campo.campoGenerico.qtMinimaCaracteres){
      const qtMinCaracteres = campo.campoGenerico.qtMinimaCaracteres;
      const valorCampoTratado = valorCampo.trim(); //removendo espacos vazios
      const resultado = validarValorMinimo(valorCampoTratado.length, parseInt(qtMinCaracteres));

      if (!resultado) {
        erro = { ...erro, [`erro_${idCampo}`]: `O campo deve ser preenchido com no mínimo ${qtMinCaracteres} caracteres` };
      }
    }
  }

  switch (tipoCampo) {
    case tiposCampoGenerico.DATA:
      valorCampo = valorCampo ? convertStringToDate(valorCampo) : valorCampo;
      if (valorCampo && valorCampo.toString() === 'Invalid Date') {
        erro = { ...erro, [`erro_${idCampo}`]: "Data Inválida." };
        return erro;
      }
      break;
    default:
  }

  if (campo.campoGenerico.regrasValidacoes && campo.campoGenerico.regrasValidacoes.length > 0) {
    campo.campoGenerico.regrasValidacoes.forEach(regra => {
      const resultadoRegra = verificarRegraValidacaoCampoGenerico(nomeCampo, valorCampo, pericia, regra);
      if (!resultadoRegra.valido) {
        if (resultadoRegra.tipo === tipoMensagemValidacaoCampoGenerico.ERRO) {
          erro = { ...erro, [`erro_${idCampo}`]: resultadoRegra.msg };
        } else if (resultadoRegra.tipo === tipoMensagemValidacaoCampoGenerico.ALERTA) {
          erro = { ...erro, [`alerta_${idCampo}`]: resultadoRegra.msg };
        }
      }
    });
  }
  return erro;
}

export const validar = (moduloTemplateGenerico, pericia, getState) => {
  let errosCamposGenericos = {};
  let errosCamposSecoes = {};
  let isValidaModulosEstaticos = true;
  let dadosModuloEstatico = {};
  const existemCamposNoModulo = moduloTemplateGenerico.modulo.camposGenericos;
  const existemSecoesNoModulo = moduloTemplateGenerico.modulo.secoes;

  if (existemCamposNoModulo) { //se aba tem campos genericos e existem valores preenchidos
    errosCamposGenericos = moduloTemplateGenerico.modulo.camposGenericos.reduce((result, c) => { //validando campos genericos da aba
      if (c.visivel === tipoSimNao.SIM) {
        return validarCampoGenerico(c, pericia, result);
      }
      return result;
    }, {});
  }

  if (existemSecoesNoModulo) { //se aba tem campos genericos e existem valores preenchidos
    errosCamposSecoes = moduloTemplateGenerico.modulo.secoes.reduce((resultSecoes, s) => { //validando secoes da aba
      if (!s.moduloEstaticoFilho) {  //validando campos genericos das seções
        resultSecoes = {
          ...resultSecoes, ...s.secoesModulosCamposGenericos.reduce((resultCampos, c) => {
            if (c.campoGenerico.visivel === tipoSimNao.SIM) {
              return validarCampoGenerico(c, pericia, resultCampos);
            }
            return resultCampos;
          }, {})
        }
      } else { //validando modulo estatico da secao
        const moduloEstatico = Object.values(modulosEstaticos)
          .find(moduloEstatico => moduloEstatico.codigo === s.moduloEstaticoFilho.tipoModuloEstatico);

        if (s.moduloEstaticoFilho.visivel === tipoSimNao.SIM) {
          if (moduloEstatico && moduloEstatico.hasOwnProperty('validar')) {
            const dados = moduloEstatico.validar(pericia, getState);
            isValidaModulosEstaticos = isValidaModulosEstaticos && dados.isValida;

            const validacaoModuloEstatico = { [moduloEstatico.nome]: dados };
            dadosModuloEstatico = { ...dadosModuloEstatico, ...validacaoModuloEstatico };

            return { ...resultSecoes, dadosModuloEstatico };
          }
        } else {
          if (moduloEstatico && moduloEstatico.hasOwnProperty('limparValidacao')) {
            const dados = moduloEstatico.limparValidacao(pericia, getState);
            const validacaoModuloEstatico = { [moduloEstatico.nome]: dados };
            dadosModuloEstatico = { ...dadosModuloEstatico, ...validacaoModuloEstatico };

            return { ...resultSecoes, dadosModuloEstatico };
          }
        }
      }
      return resultSecoes;
    }, {});
  }

  let erros = { ...errosCamposGenericos, ...errosCamposSecoes };
  let existeErro = Object.keys(erros).some(e => e.search("erro") >= 0);

  return {
    isValida: !existeErro && isValidaModulosEstaticos,
    ...erros,
    ...dadosModuloEstatico
  };
}