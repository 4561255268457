import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import HeaderSecao from '../../../../components/HeaderSecao';

import BrCard from '@govbr/react-components/lib/BrCard';
import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import { Grid } from "@material-ui/core";
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import BrMessage from '@govbr/react-components/lib/BrMessage';
import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { obterMapRespostasGrupos, obterQualificadorFinalFuncoesCorpo, obterValoresPorDominios } from '../../../../components/ModuloFormulario/utils';
import { alteraImpedimentoLongoPrazo, alterarDescricaoDuracao, alterarDescricaoEstruturaCorpo, alterarDescricaoPrognostico, alterarDuracao, alterarEstruturasSelecionadas, alterarPrognostico, alterarRespostaEstrutura } from '../../action';
import { obterFomularioFuncoesDoCorpo } from '../../templatePericiaUtil';
import { DOMINIO_DURACAO_ALTERACAO_V1, DOMINIO_DURACAO_ALTERACAO_V2 } from './constants';

class EstruturaEPrognostico extends React.Component {

  QTD_MAXIMA_CARACTERES = 2000;

  tratarSelecaoEstrutura = (item, checked) => {
    const { estruturasCorpo, idModEstProg } = this.props.dados;
    let estruturasCorpoAtualizadas = estruturasCorpo ? estruturasCorpo : [];
    const value = parseInt(item);

    if (checked) { //inserir

      estruturasCorpoAtualizadas.push({ idTipoEstruturaCorpo: value, idModEstProg });
    } else { //remover
      estruturasCorpoAtualizadas = estruturasCorpoAtualizadas.filter(e => { return e.idTipoEstruturaCorpo !== value });
    }
    this.props.alterarEstruturasSelecionadas(estruturasCorpoAtualizadas);
  }

  tratarSelecaoDuracaoAlteracao = value => {
    const tipoDominio = this.props.dominioDuracaoAlteracao;
    const { alterarDuracao, alteraImpedimentoLongoPrazo } = this.props;

    if (tipoDominio === DOMINIO_DURACAO_ALTERACAO_V1) {
      //deve salvar em campo duracaoAlteracao
      alterarDuracao(parseInt(value));
    } else if (tipoDominio === DOMINIO_DURACAO_ALTERACAO_V2) {
      //deve salvar em campo impedimentoLongoPrazo
      alteraImpedimentoLongoPrazo(parseInt(value));
    }
  }

  obterValorDuracaoAlteracao = () => {
    const tipoDominio = this.props.dominioDuracaoAlteracao;
    const { idTipoDuracaoAltConstatada, idTipoImpedimentoLongoPrazo } = this.props.dados;

    if (tipoDominio === DOMINIO_DURACAO_ALTERACAO_V1) {
      return idTipoDuracaoAltConstatada;
    } else if (tipoDominio === DOMINIO_DURACAO_ALTERACAO_V2) {
      return idTipoImpedimentoLongoPrazo;
    }
  }

  isQualificadorGraveOuCompleto = () => {
    const { template, respostasGrupoForm } = this.props;
    const formularioFuncoesCorpo = obterFomularioFuncoesDoCorpo(template);

    if (formularioFuncoesCorpo) {

      const mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupoForm);
      const valoresPorDominio = obterValoresPorDominios(formularioFuncoesCorpo.gruposFormulario, mapRespostasGrupos);
      const qualificadorFinalFuncoesDoCorpo = obterQualificadorFinalFuncoesCorpo(valoresPorDominio);

      return qualificadorFinalFuncoesDoCorpo.sigla === 'G' || qualificadorFinalFuncoesDoCorpo.sigla === 'C';
    }

    return false;
  }

  render() {
    const { alteracaoEstruturaCorpo, estruturasCorpo, descricaoAltEstCorpo,
      tipoProgDesfavoravel, descricaoProgDesfavoravel, descricaoAltConstada } = this.props.dados;
    const { alterarRespostaEstrutura, alterarDescricaoEstruturaCorpo, alterarPrognostico, alterarDescricaoPrognostico,
      alterarDescricaoDuracao, validacao, isSomenteLeitura } = this.props;
    const { tiposSimNao, tiposEstruturaCorpo, tiposProgDesfavoravel } = this.props.dominioPericia;

    //parametros de versionamento do modulo
    const { textoPerguntaDuracaoAlteracoes, dominioDuracaoAlteracao } = this.props;
    const tiposDuracaoAlteracao = this.props.dominioPericia[`${dominioDuracaoAlteracao}`];
    const idTipoDuracaoAlteracao = this.obterValorDuracaoAlteracao();

    const isFuncoesCorpoGraveOuCompleto = this.isQualificadorGraveOuCompleto();
    const isPrognosticoDesfavoravel = tipoProgDesfavoravel === 2;
    const deveExibirMensagensAlerta = isFuncoesCorpoGraveOuCompleto || isPrognosticoDesfavoravel;

    const caracteresRestantesDuracao = calcularCaracteresRestantes(descricaoAltConstada, this.QTD_MAXIMA_CARACTERES);
    const caracteresRestantesEstCorpo = calcularCaracteresRestantes(descricaoAltEstCorpo, this.QTD_MAXIMA_CARACTERES);
    const caracteresRestantesPrognostico = calcularCaracteresRestantes(descricaoProgDesfavoravel, this.QTD_MAXIMA_CARACTERES);

    const isCampoTextareaDescricaoEstruturaCorpoDesabilitado = isSomenteLeitura;
    const isCampoTextareaDescricaoProgDesfavoravelDesabilitado = isSomenteLeitura;
    const isCampoTextareaDescricaoDuracaoDesabilitado = isSomenteLeitura;

    return (
      <div id="abaEstruturaEPrognostico">
        <HeaderSecao texto="Estruturas do Corpo" />
        {tiposSimNao &&
          <BrRadio id="alteracaoEstruturaCorpo" orientation='horizontal'
            label="* Existem alterações na estrutura do corpo que configuram maiores limitações e restrições ao avaliado 
            do que as alterações observadas em Funções do Corpo?"
            value={alteracaoEstruturaCorpo} items={tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
            onChange={value => alterarRespostaEstrutura(parseInt(value))} erro={validacao && validacao.erroAlteracaoEstruturaCorpo}
            disabled={isSomenteLeitura} />
        }

        {alteracaoEstruturaCorpo === 1 &&
          (<>
            <BrCard>
              <span>A resposta afirmativa a este quesito implicará a elevação do qualificador final de Funções do Corpo em um nível
                (de <u>N para L</u>, de <u>L para M</u>, de <u>M para G</u>, de <u>G para C</u> e <u>C permanece como C</u>).
              </span>
            </BrCard>
            <p className="label">
              * Assinale abaixo a(s) estrutura(s) que atendem ao quesito
            </p>

            {tiposEstruturaCorpo &&
              (<BrCheckbox id="estruturasCorpo" columns={2}
                items={tiposEstruturaCorpo.map(c => ({ label: c.descricao, value: c.id }))}
                values={estruturasCorpo ? estruturasCorpo.map(c => c.idTipoEstruturaCorpo) : []}
                onChange={this.tratarSelecaoEstrutura}
                erro={validacao && validacao.erroEstruturasCorpo}
                disabled={isSomenteLeitura}
              />)
            }

            <div className="mt-3">
              <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea label="Descreva, caso já não o tenha feito na história clínica ou no exame físico"
                    onChange={alterarDescricaoEstruturaCorpo}
                    value={descricaoAltEstCorpo}
                    maxLength={this.QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresRestantesEstCorpo}`}
                    disabled={isCampoTextareaDescricaoEstruturaCorpoDesabilitado} />
                </Grid>
                  {
                    !isCampoTextareaDescricaoEstruturaCorpoDesabilitado &&
                    <Grid item >
                      <ButtonSelecionarItem
                        id="selecionarItemDicionarioDescricaoEstruturaCorpo"
                        onSelect={alterarDescricaoEstruturaCorpo}
                        maxLength={caracteresRestantesEstCorpo}
                        valorAtual={descricaoAltEstCorpo}/>
                    </Grid>
                  }
              </Grid>
            </div>

          </>)
        }

        < HeaderSecao texto="* Prognóstico" />
        {tiposProgDesfavoravel &&
          <BrRadio id="prognostico" orientation='horizontal'
            label="As alterações observadas em Funções e/ou Estruturas do Corpo configuram prognóstico desfavorável?"
            value={tipoProgDesfavoravel} items={tiposProgDesfavoravel.map(t => ({ label: t.descricao, value: t.id }))}
            onChange={value => alterarPrognostico(parseInt(value))} erro={validacao && validacao.erroPrognostico}
            disabled={isSomenteLeitura} />
        }

        {isPrognosticoDesfavoravel &&
          (<>
            <BrCard>
              <span>A resposta afirmativa a este quesito implicará a elevacao do qualificador final de Funções do Corpo em um nível
                (de <u>N para L</u>, de <u>L para M</u>, de <u>M para G</u>, de <u>G para C</u> e <u>C permanece como C</u>),
                de forma <u>não cumulativa</u>, caso ja tenha havido elevação pelo quesito anterior.
              </span>
            </BrCard>
            <div className="mt-3">
              <Grid container alignItems="center">
                  <Grid item xs>
                    <BrTextarea label="Descreva, caso já não o tenha feito na história clínica ou no exame físico"
                      onChange={alterarDescricaoPrognostico}
                      value={descricaoProgDesfavoravel}
                      maxLength={this.QTD_MAXIMA_CARACTERES}
                      help={`Caracteres restantes: ${caracteresRestantesPrognostico}`}
                      disabled={isCampoTextareaDescricaoProgDesfavoravelDesabilitado} />
                  </Grid>
                  {
                    !isCampoTextareaDescricaoProgDesfavoravelDesabilitado &&
                    <Grid item >
                      <ButtonSelecionarItem
                        id="selecionarItemDicionarioDescricaoProgDesfavoravel"
                        onSelect={alterarDescricaoPrognostico}
                        maxLength={caracteresRestantesPrognostico}
                        valorAtual={descricaoProgDesfavoravel}/>
                    </Grid>
                  }
              </Grid>
            </div>
          </>)
        }

        <HeaderSecao texto="Duração das Alterações Constatadas" />

        {textoPerguntaDuracaoAlteracoes}

        <Grid container>
          {idTipoDuracaoAlteracao === 1 && 
            (<Grid item xs={12}>
              <BrMessage warning style={{marginBottom: 0}}>
                <span>
                  Ao confirmar que NÃO se trata de impedimento de longo prazo, ou seja,
                  quando as alterações de função e/ou estruturas do corpo puderem ser resolvidas em menos de 2 (dois) anos, 
                  o requerimento será INDEFERIDO 
                  conforme inciso III do art. 8º da Portaria Conjunta MDS/INSS nº 2, de 30 de março de 2015.
                </span>
              </BrMessage>
            </Grid>)
          }
          <Grid item xs={deveExibirMensagensAlerta ? 8 : 12}>
            {tiposDuracaoAlteracao &&
              <BrRadio id="radioDuracaoAlteracao"
                value={idTipoDuracaoAlteracao}
                items={tiposDuracaoAlteracao.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={value => this.tratarSelecaoDuracaoAlteracao(value)}
                erro={validacao && validacao.erroDuracaoAlteracao}
                disabled={isSomenteLeitura} />
            }
          </Grid>
          {deveExibirMensagensAlerta &&
            (<Grid item xs={4}>
              {isFuncoesCorpoGraveOuCompleto &&
                (
                  <BrCard>
                    <span> <b>ATENÇÃO</b> <br />
                      O qualificador final de Funções do Corpo foi considerado
                      <span className="texto-informativo red"> Grave ou Completo</span> pela presente avaliação.
                    </span>
                  </BrCard>
                )
              }
              {isPrognosticoDesfavoravel &&
                (
                  <BrCard>
                    <span> <b>ATENÇÃO</b> <br />
                      O caso foi considerado com <span className="texto-informativo red">prognóstico desfavorável</span> em quesito anterior.
                    </span>
                  </BrCard>
                )
              }
            </Grid>)
          }
        </Grid>

        {idTipoDuracaoAlteracao === 2 &&
          (<>
            <div className="mt-3">
              <Grid container alignItems="center">
                <Grid item xs>
                  <BrTextarea label="* Neste caso, justifique:"
                    onChange={alterarDescricaoDuracao}
                    value={descricaoAltConstada}
                    maxLength={this.QTD_MAXIMA_CARACTERES}
                    help={`Caracteres restantes: ${caracteresRestantesDuracao}`}
                    erro={validacao ? validacao.erroJustificativaDuracao : null}
                    disabled={isCampoTextareaDescricaoDuracaoDesabilitado} />
                </Grid>
                  {
                    !isCampoTextareaDescricaoDuracaoDesabilitado &&
                    <Grid item >
                      <ButtonSelecionarItem
                        id="selecionarItemDicionarioDescricaoDuracao"
                        onSelect={alterarDescricaoDuracao}
                        maxLength={caracteresRestantesDuracao}
                        valorAtual={descricaoAltConstada}/>
                    </Grid>
                  }
              </Grid>
            </div>
          </>)
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dominioPericia, validacao, template, isSomenteLeitura } = state.principal;
  const { moduloEstruturaPrognostico, respostasGrupoForm } = state.pericia;
  return ({
    dados: moduloEstruturaPrognostico,
    validacao: validacao.estruturaEPrognostico,
    dominioPericia,
    respostasGrupoForm,
    template,
    isSomenteLeitura
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarRespostaEstrutura,
  alterarEstruturasSelecionadas,
  alterarDescricaoEstruturaCorpo,
  alterarPrognostico,
  alterarDescricaoPrognostico,
  alterarDuracao,
  alterarDescricaoDuracao,
  alteraImpedimentoLongoPrazo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EstruturaEPrognostico);