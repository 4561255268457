import React from "react";

import BrTable from "@govbr/react-components/lib/BrTable";

import { formatarSimNaoBooleano } from "../../../../common/formatadores";

const TabelaSistemasCorpo = ({ exames, isValorAtual }) => {
  const infoClass = isValorAtual ? "valor-atual-revisao":"valor-anterior-revisao";

  return (
    <BrTable
      id="detalheTabelaSistemasCorpo"
      layout="fixed"
      columns={[
        { Header: "Sistemas/Segmentos", accessor: "sistema" },
        { Header: "Examinado",accessor: "examinado" },
        { Header: "Descrição",accessor: "descricao"}
      ]}
      data={exames.map((item) => ({
        sistema: <span className={`texto-informativo ${infoClass}`}>{item.sistema}</span>,
        examinado: <span className={`texto-informativo ${infoClass}`}>{formatarSimNaoBooleano(item.examinado) || "---"}</span>,
        descricao: <span className={`texto-informativo ${infoClass}`}>{item.examinado === 1 && item.descricao ? item.descricao : "---"}</span>,
      }))}
      headerProps={{
        show: false,
        showDensityOptions: false,
        searchProp: {
          show: false,
        },
      }}
      footerProps={{ show: false }}
    />);
}

export default TabelaSistemasCorpo;