import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import { Grid } from '@material-ui/core';
import { adicionarDiasNaData } from '../../../../common/DataUtils';
import { calcularCaracteresRestantes } from '../../../../common/Utils';

import {
  alterarDataComprovIncapac, alterarDCBGrauIncapacidade, alterarJustDCIGrauIncapacidade, alterarJustLIGrauIncapacidade, 
  alterarMajoracaoGrauIncapacidade, alterarTipoGrauIncapacidade, alterarTipoMajoracao25, alterarPassivelReavaliacao2anos
} from '../../action';

import { TIPOS_GRAU_INCAPACIDADE_RBI, TIPOS_GRAU_INVALIDEZ_RBI, obtemFluxoRBI, tipoFluxoRBI } from './utils';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';
import { obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE, obtemSituacaoBeneficioAnteriorNaoIndicaDCA } from '../GrauIncapacidade/validacao';

const GrauIncapacidadeRBI = props => {

  const { alterarDCBGrauIncapacidade, template, pericia } = props;

  const tipoGrauIncapacidade = props.dados.tipoGrauIncapacidade;
  const tiposGrauIncapacidade = props.tiposGrauIncapacidade;
  const tipoPericiaTemplate = template.tipoPericia;
  const alterarTipoGrauIncapacidade = props.alterarTipoGrauIncapacidade;
  const alterouDataCessacaoBeneficioAutomaticamente = props.dados.alterouDataCessacaoBeneficioAutomaticamente;
  const isNaoPermiteDataAnteriorDREEmDCBeDCI = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia)
    || obtemSituacaoBeneficioAnteriorNaoIndicaDCA(props.pericia);
  const beneficioAnterior31_91 = obtemFluxoRBI(pericia) === tipoFluxoRBI.FLUXO_BENEFICIOS_INCAPACIDADE;

  useEffect(() => {
    const tipoGrauSelecionadoPreviamente = tiposGrauIncapacidade.find(tipoGrau => tipoGrau.id === tipoGrauIncapacidade);
    if (tipoGrauSelecionadoPreviamente) {
      const tipoPericiaPossuiTipoGrau = tipoGrauSelecionadoPreviamente.tiposPericia.find(tipo => tipo === tipoPericiaTemplate)
      if (!tipoPericiaPossuiTipoGrau) {
        alterarTipoGrauIncapacidade(null);
      }
      if (alterouDataCessacaoBeneficioAutomaticamente
        && TIPOS_GRAU_INCAPACIDADE_RBI.TEMPORARIA_COM_RECUPERACAO === tipoGrauSelecionadoPreviamente.id) {
          alterarDCBGrauIncapacidade(undefined)
      }
    }
  }, [tipoGrauIncapacidade, tiposGrauIncapacidade, tipoPericiaTemplate, alterarTipoGrauIncapacidade, 
    alterouDataCessacaoBeneficioAutomaticamente, alterarDCBGrauIncapacidade]);

  const handleChangeAlterouTipoGrauIncapacidade = value => {
    if (alterouDataCessacaoBeneficioAutomaticamente
      && TIPOS_GRAU_INCAPACIDADE_RBI.TEMPORARIA_COM_RECUPERACAO === value) {
        alterarDCBGrauIncapacidade(undefined)
    }
    alterarTipoGrauIncapacidade(value)
  }
  
  const obtemOpcoesGrauIncapacidadeOuInvalidez = (tiposGrauIncapacidadeOuInvalidez) => {
    const tipoPericiaTemplate = template.tipoPericia;

    const isOpcaoVazia = (tipo) => !tipo.descricao;

    const opcoes = [{ descricao: '', id: null, ordem: 0 }, ...tiposGrauIncapacidadeOuInvalidez]
      .filter(tipo =>
        isOpcaoVazia(tipo)
        || (tipo.tiposPericia
            && tipo.tiposPericia.find(tipoPericia => tipoPericia.includes(tipoPericiaTemplate))
          ))
      .sort((a, b) => {
        if (a.ordem < b.ordem) return -1;
        if (a.ordem > b.ordem) return 1;
        return 0;
      })
      .map(tipo => ({ label: tipo.descricao, value: tipo.id }));

    return opcoes;
  }

  const opcoesGrauIncapacidade = obtemOpcoesGrauIncapacidadeOuInvalidez(beneficioAnterior31_91 ? props.tiposGrauIncapacidade : props.tiposGrauInvalidez);

  return (
    <>
      <Grid id="moduloGrauIncapacidade" container spacing={2}>
        <Grid item sm={12} style={{ paddingBottom: '1rem' }}>
          <BrSelect
            id="tpGrauIncapacidade"
            label={"* Selecione a classificação da " + (beneficioAnterior31_91 ? "incapacidade:" : "invalidez:")}
            items={opcoesGrauIncapacidade}
            value={opcoesGrauIncapacidade.find(tipo => props.dados.tipoGrauIncapacidade && tipo.value === props.dados.tipoGrauIncapacidade) || opcoesGrauIncapacidade[0]}
            onChange={handleChangeAlterouTipoGrauIncapacidade}
            erro={props.validacao?.erroTipoGrauIncapacidade}
            disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <ComponentesTempComRecup {...props} handleChange={alterarDCBGrauIncapacidade} 
          isNaoPermiteDataAnteriorDREEmDCBeDCI={isNaoPermiteDataAnteriorDREEmDCBeDCI} />
        <ComponentesTotalReabProf {...props} 
          isNaoPermiteDataAnteriorDREEmDCBeDCI={isNaoPermiteDataAnteriorDREEmDCBeDCI} />
        <ComponentesDefinitivaSemReabProf {...props} beneficioAnterior31_91={beneficioAnterior31_91} />
      </Grid>
    </>
  );
}

const ComponentesTempComRecup = props => {

  return (
    <>
      {
        props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE_RBI.TEMPORARIA_COM_RECUPERACAO &&
        <Grid item sm={6}>
          {props.validacao?.alertaDcbTempComRecupe
            && (
              <BrMessage warning>
                {props.validacao?.alertaDcbTempComRecupe}
              </BrMessage>
            )}
          <BrDatepicker
            id='dataCessacaoBenefTempRecup'
            label="* Data de Cessação do Benefício (DCB)"
            date={props.dados.dataCessacaoBeneficio || null}
            minDate={props.isNaoPermiteDataAnteriorDREEmDCBeDCI ? adicionarDiasNaData(new Date(), 1) : undefined}
            onChange={props.handleChange}
            allowInput
            erro={props.validacao?.erroDcbTempComRecupe}
            disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
        </Grid>
      }
    </>
  );
}

const ComponentesTotalReabProf = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justTotalComIndicReabProfissional;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || !props.permiteAlteracaoCritica;

  return (
    <>
      {
        (props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE_RBI.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL
          || props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INVALIDEZ_RBI.ELEGIVEL_REABILITACAO_PROFISSIONAL) &&
        <>
          <Grid container spacing={1} item sm={12} alignItems="flex-end">
            <Grid item sm={6}>
              <BrDatepicker
                id='dataComprovIncapac'
                label="* Data Limite da Elegibilidade:"
                date={props.dados.dataComprovacaoIncapacidade || null}
                onChange={props.alterarDataComprovIncapac}
                minDate={props.isNaoPermiteDataAnteriorDREEmDCBeDCI ? adicionarDiasNaData(new Date(), 0) : undefined}
                initialFocusedDate={adicionarDiasNaData(new Date(), 0)}
                allowInput
                erro={props.validacao?.erroDataComprovacaoIncapacidade}
                disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
            </Grid>
            <Grid item sm={1}>
              <BrButton
                id="btnAdicionar01Ano"
                primary
                title='DCI para um ano'
                onClick={() => props.alterarDataComprovIncapac(adicionarDiasNaData(new Date(), 365))}
                disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica}>
                Adicionar 01 Ano
              </BrButton>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea
                  id="justifTotalReabProf"
                  label="* Justifique"
                  value={justificativa}
                  onChange={props.alterarJustDCIGrauIncapacidade}
                  maxLength={QT_MAX_CARACT}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  erro={props.validacao?.erroJustTotalComIndicReabProfissional}
                  disabled={isCampoTextareaDesabilitado} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustReabProfissional"
                    onSelect={props.alterarJustDCIGrauIncapacidade} 
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

const ComponentesDefinitivaSemReabProf = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justTotalSemIndicReabProfissional;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);

  const TIPO_SIM = 1;

  const OPCAO_VAZIA = { label: '', value: undefined };
  const listaMotivosMajoracao25 = [OPCAO_VAZIA, ...props.listaMotivosMajoracao25].map(tipo => ({ label: tipo.motivo, value: tipo.id }));

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || !props.permiteAlteracaoCritica;

  return (
    <>
      {
        (props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE_RBI.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL
          || props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INVALIDEZ_RBI.MANTEM_INVALIDEZ) &&
        <>
          {!props.beneficioAnterior31_91 &&
            <Grid item sm={12}>
              <BrRadio
                id="isReavaliacao2Anos"
                label="* É passível de reavaliação em 2 anos?"
                value={props.dados.passivelReavaliacao2anos}
                items={props.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
                onChange={props.alterarPassivelReavaliacao2anos}
                erro={props.validacao?.erroPassivelReavaliacao2anos}
                disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
            </Grid>
          }
          <Grid item sm={4}>
            <BrRadio
              id="isMajoracao25"
              label="* Faz jus à majoração de 25%?"
              value={props.dados.majoracao25}
              items={props.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
              onChange={props.alterarMajoracaoGrauIncapacidade}
              erro={props.validacao?.erroMajoracao25}
              disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={8}>
            <BrSelect
              id="tpMajoracao25"
              label="* Majoração de 25%"
              items={listaMotivosMajoracao25}
              value={props.dados.tipoMajoracao25 ? listaMotivosMajoracao25.find(tipo => tipo.value === props.dados.tipoMajoracao25) : OPCAO_VAZIA}
              onChange={props.alterarTipoMajoracao25}
              erro={props.validacao?.erroTipoMajoracao25}
              disabled={props.isSomenteLeitura || props.dados.majoracao25 !== TIPO_SIM || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea
                  id="justifTotalSemReabProf"
                  label="* Justificativa"
                  value={justificativa}
                  onChange={props.alterarJustLIGrauIncapacidade}
                  maxLength={QT_MAX_CARACT}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  disabled={isCampoTextareaDesabilitado}
                  erro={props.validacao?.erroJustTotalSemIndicReabProfissional} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustReabProfissional"
                    onSelect={props.alterarJustLIGrauIncapacidade} 
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { dominioPericia, template, dominioPericiaBI, validacao, isSomenteLeitura } = state.principal;
  return ({
    pericia: state.pericia,
    tiposGrauIncapacidade: dominioPericia.tiposGrausIncapacidade,
    tiposGrauInvalidez: dominioPericia.tiposGrausInvalidez,
    isSomenteLeitura: isSomenteLeitura,
    tiposSimNao: dominioPericia.tiposSimNao,
    template: template,
    dados: state.pericia.moduloGrauIncapacidade,
    permiteAlteracaoCritica: state.pericia.permiteAlteracaoCritica,
    validacao: validacao.grauIncapacidadeRBI,
    listaMotivosMajoracao25: dominioPericiaBI?.tiposMotivoMajoracao || []
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarTipoGrauIncapacidade,
  alterarDCBGrauIncapacidade,
  alterarJustDCIGrauIncapacidade,
  alterarJustLIGrauIncapacidade,
  alterarMajoracaoGrauIncapacidade,
  alterarPassivelReavaliacao2anos,
  alterarDataComprovIncapac,
  alterarTipoMajoracao25
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(GrauIncapacidadeRBI);
