import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { alterarHistoriaClinica } from '../../action';

import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import { Grid } from '@material-ui/core';
import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';
import MensagemTelemedicina from '../MensagemTelemedicina';

class HistoriaClinica extends React.Component {

  QTD_MAXIMA_CARACTERES = 4000;

  render() {
    const { historiaClinica, alterarHistoriaClinica, validacao, isSomenteLeitura, realizadaPorTelemedicina } = this.props;
    const caracteresRestantes = calcularCaracteresRestantes(historiaClinica, this.QTD_MAXIMA_CARACTERES);

    const isCampoTextareaDesabilitado = isSomenteLeitura;
    return (
      <div id="abaHistoriaClinica" className="conteudoAbaSemHeaderSecao">
        <p>
           * Considerar todos os elementos relevantes da história clínica atual e pregressa, que darão subsídios para a avaliação 
            e qualificação dos domínios abaixo relacionados, incluindo relatórios e laudos técnicos, prontuários e resultados de exames complementares, quando houver.
        </p>
        {realizadaPorTelemedicina && <MensagemTelemedicina />}
        <Grid container alignItems="center">
            <Grid item xs>
              <BrTextarea id="historiaClinica"
                onChange={alterarHistoriaClinica}
                value={historiaClinica}
                maxLength={this.QTD_MAXIMA_CARACTERES}
                help={`Caracteres restantes: ${caracteresRestantes}`}
                erro={validacao ? validacao.mensagemErro : null}
                disabled={isCampoTextareaDesabilitado} />
            </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioHistoriaClinica"
                    onSelect={alterarHistoriaClinica}
                    maxLength={caracteresRestantes}
                    valorAtual={historiaClinica}/>
                </Grid>
              }
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  historiaClinica: state.pericia.moduloExameLaudo.historiaClinica,
  validacao: state.principal.validacao.historiaClinica,
  isSomenteLeitura: state.principal.isSomenteLeitura,
  realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarHistoriaClinica
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoriaClinica);