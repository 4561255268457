import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrTextareaAutosize from '../../../../components/BrTextareaAutosize';

import ButtonCheck from './ButtonCheck';

import { Grid } from "@material-ui/core";

import { alterarDescricaoSistemaExaminado, registrarSistemaExaminado } from '../../action';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

import { calcularCaracteresRestantes } from '../../../../common/Utils';

const AvaliacaoFisica = (props) => {
  const QTD_MAXIMA_CARACTERES = 4000;

  const { examesSistemas, isSomenteLeitura, validacao, dominioSistemasCorpo, registrarSistemaExaminado } = props;

  useEffect(() => {
    if (!examesSistemas || examesSistemas.length <= 0) {
      //setar valores default: sistema nao foi examinado
      dominioSistemasCorpo.forEach(s => registrarSistemaExaminado(s.id, 0));
    }
    // eslint-disable-next-line
  }, []);//deve executar apenas a primeira vez que o componente for renderizado

  const isCampoTextareaDesabilitado = isSomenteLeitura;

  return (
    <div id="moduloAvaliacaoFisica" >
      <div id="tabelaExamesSistemas" style={{ marginBottom: '10px' }}>
        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Sistemas/Segmentos</th>
              <th style={{ width: '5%' }}>Sem Alterações Dignas de Nota</th>
              <th style={{ width: '5%' }}>Alterações Dignas de Nota</th>
              <th>*Descrição</th>
            </tr>
          </thead>
          <tbody>
            {
              dominioSistemasCorpo?.map(s =>
                <tr key={s.id}>
                  <td style={{ verticalAlign: 'top' }}>
                    {s.nomeDoSistema}
                  </td>
                  <td style={{ padding: '0px', paddingTop: '8px', verticalAlign: 'top' }}>
                    <ButtonCheck
                      selected={examesSistemas.find(e => e.idSistema === s.id)?.examinado === 0}
                      handleChange={() => registrarSistemaExaminado(s.id, 0)}
                      disabled={isSomenteLeitura} />
                  </td>
                  <td style={{ padding: '0px', paddingTop: '8px', verticalAlign: 'top' }}>
                    <ButtonCheck
                      selected={examesSistemas.find(e => e.idSistema === s.id)?.examinado === 1}
                      handleChange={() => registrarSistemaExaminado(s.id, 1, s.descricaoPadrao)}
                      disabled={isSomenteLeitura} />
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    {
                      examesSistemas.find(e => e.idSistema === s.id)?.examinado === 1 &&
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <BrTextareaAutosize id={`descricao_exame_sistema_${s.id}`}
                            onChange={(value) => props.alterarDescricaoSistemaExaminado(s.id, value)}
                            rows={3}
                            value={examesSistemas.find(e => e.idSistema === s.id)?.descricao}
                            maxLength={QTD_MAXIMA_CARACTERES}
                            erro={validacao ? validacao[`erroDescricaoObrigatoria_sistema_${s.id}`] : null}
                            disabled={isCampoTextareaDesabilitado} />
                      </Grid>
                      {
                        !isCampoTextareaDesabilitado &&
                        <Grid item >
                          <ButtonSelecionarItem
                            id={`selecionarItemDicionarioExameSistema_${s.id}`}
                            onSelect={props.alterarDescricaoSistemaExaminado}
                            onSelectProps={{id: s.id}}
                            maxLength={calcularCaracteresRestantes(examesSistemas.find(e => e.idSistema === s.id)?.descricao, QTD_MAXIMA_CARACTERES)}
                            valorAtual={examesSistemas.find(e => e.idSistema === s.id)?.descricao}
                            />
                        </Grid>
                      }
                    </Grid>
                    }
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div >

  );
}

const mapStateToProps = (state) => ({
  examesSistemas: state.pericia.examesSistemaCorpo,
  isSomenteLeitura: state.principal.isSomenteLeitura,
  dominioSistemasCorpo: state.principal.dominioPericiaBI.sistemasCorpoExameFisico,
  validacao: state.principal.validacao.avaliacaoFisica
});

const mapDispatchToProps = dispatch => bindActionCreators({
  registrarSistemaExaminado,
  alterarDescricaoSistemaExaminado
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AvaliacaoFisica);