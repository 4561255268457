import React from 'react';
import MenuOpcoes from '../../components/MenuOpcoes';
import TituloAba from "../../components/TituloAba";
import ValidarEProsseguir from '../../components/ValidarEProsseguir';

import FormularioGenerico from '../pericia/modulos/FormularioGenerico';
import Generico from '../pericia/modulos/Generico';

import { MSG_CAMPOS_OBRIGATORIO } from '../../common/Messages';
import { modulosEstaticos } from '../pericia/constants';
import { decodeHtml } from '../../common/StringUtils';

export default function AbaTemplate(props) {
  const { moduloEstatico, nomeModuloGenerico, moduloTemplate } = props;
  const modulosEstaticosComValidacao = Object.values(modulosEstaticos).filter(m => m.deveValidar).map(m => m.codigo);
  const modulosEstaticosComMenuOpcoes = Object.values(modulosEstaticos).filter(m => m.exibeMenuOpcoes).map(m => m.codigo);

  const isModuloGenericoComFilhos = moduloTemplate && moduloTemplate.modulosTemplateFilhos.length > 0;
  const isModuloGenericoComCamposGenericos = moduloTemplate
    && (moduloTemplate.modulo.camposGenericos.length > 0
      || (moduloTemplate.modulo.secoes.length > 0 && moduloTemplate.modulo.secoes.find(secao => secao.secoesModulosCamposGenericos.length > 0)));
  const isModuloGenericoComSecaoEstatica = moduloTemplate && moduloTemplate.modulo.secoes.length > 0
    && moduloTemplate.modulo.secoes.find(s => s.moduloEstaticoFilho && s.moduloEstaticoFilho.tipoModuloEstatico);
  const isModuloGenerico = nomeModuloGenerico && !moduloTemplate.modulo.formulario;
  const isModuloGenericoFormulario = nomeModuloGenerico && moduloTemplate.modulo.formulario;

  const isModuloGenericoComMenuOpcoes = isModuloGenericoComCamposGenericos
    || (isModuloGenericoComFilhos && moduloTemplate.modulosTemplateFilhos.find(filho => modulosEstaticosComMenuOpcoes.includes(filho.modulo.tipoModuloEstatico)))
    || (isModuloGenericoComSecaoEstatica && moduloTemplate.modulo.secoes.find(s => s.moduloEstaticoFilho && modulosEstaticosComMenuOpcoes.includes(s.moduloEstaticoFilho.tipoModuloEstatico)))
    || isModuloGenericoFormulario;
  const isModuloGenericoComValidacao = isModuloGenericoComCamposGenericos
    || (isModuloGenericoComFilhos && moduloTemplate.modulosTemplateFilhos.find(filho => modulosEstaticosComValidacao.includes(filho.modulo.tipoModuloEstatico)))
    || (isModuloGenericoComSecaoEstatica && moduloTemplate.modulo.secoes.find(s => s.moduloEstaticoFilho && modulosEstaticosComValidacao.includes(s.moduloEstaticoFilho.tipoModuloEstatico)))
    || isModuloGenericoFormulario;

  const deveValidarEProsseguir = (moduloEstatico && moduloEstatico.deveValidar)
    || isModuloGenericoComValidacao;
  const deveExibirMenuOpcoes = (moduloEstatico && moduloEstatico.exibeMenuOpcoes)
    || isModuloGenericoComMenuOpcoes;
  const conteudoAba = (moduloEstatico && props.moduloEstatico.implementacao)
    || (isModuloGenerico && <Generico nomeModulo={nomeModuloGenerico} moduloTemplate={moduloTemplate} />)
    || (isModuloGenericoFormulario && <FormularioGenerico nomeModulo={nomeModuloGenerico} moduloTemplate={moduloTemplate} />);

  const isPossuiCamposObrigatorios = deveValidarEProsseguir;

  return (
    <>
      {deveExibirMenuOpcoes
        && (<MenuOpcoes />)
      }

      <TituloAba label={props.label} />

      {
        moduloTemplate?.descricao &&
        <div id={`descricao_aba_${moduloTemplate.id}`} dangerouslySetInnerHTML={{ __html: decodeHtml(moduloTemplate.descricao) }} />
      }
      {conteudoAba}

      {isPossuiCamposObrigatorios &&
        <p className="label" style={{ marginTop: "var(--spacing-scale-2x)" }}>{MSG_CAMPOS_OBRIGATORIO}</p>
      }

      {deveValidarEProsseguir
        && <ValidarEProsseguir />}
    </>
  )
}