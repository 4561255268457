
export const exibirDialogIncluirExame = () => ({
  type: 'EXIBIR_DIALOG_INCLUIR'
});

export const ocultarDialogExameLaudo = () => ({
  type: 'OCULTAR_DIALOG'
});

export const exibirDialogDetalharExame = exameLaudo => ({
  type: 'EXIBIR_DIALOG_DETALHAR',
  payload: exameLaudo
});

export const exibirDialogAlterarExame = exameLaudo => ({
  type: 'EXIBIR_DIALOG_ALTERAR',
  payload: exameLaudo
});

export const exibirDialogExcluirExame = exameLaudo => ({
  type: 'EXIBIR_DIALOG_EXCLUIR',
  payload: exameLaudo
});

export const ocultarDialogExcluirExame = () => ({
  type: 'OCULTAR_DIALOG_EXCLUIR'
});

export const atualizarDadosValidacaoExameLaudo = dados => ({
  type: 'ATUALIZAR_DADOS_VALIDACAO_EXAME_LAUDO',
  payload: dados
});

export const alterarDataDocumento = value => ({
  type: 'ALTERAR_DATA_DOCUMENTO',
  payload: value
});

export const alterarTipoEmitente = value => ({
  type: 'ALTERAR_TIPO_EMITENTE',
  payload: value
});


export const alterarOutroTipoEmitente = value => ({
  type: 'ALTERAR_OUTRO_TIPO_EMITENTE',
  payload: value
});


export const alterarUf = value => ({
  type: 'ALTERAR_UF',
  payload: parseInt(value)
});

export const alterarNumeroDocumento = value => ({
  type: 'ALTERAR_NUMERO_DOCUMENTO',
  payload: value
});

export const alterarNomeProfissional = value => ({
  type: 'ALTERAR_NOME_PROFISSIONAL',
  payload: value
});

export const alterarDescricaoLaudo = value => ({
  type: 'ALTERAR_DESCRICAO_LAUDO',
  payload: value
});

export const alterarTipoExameLaudo = (value, isTipoExame) => ({
  type: 'ALTERAR_TIPO_EXAME_LAUDO',
  payload: {
    value,
    isTipoExame
  }
});