import { MSG_CAMPO_OBRIGATORIO } from "../../../../common/Messages";

export const verificarSeCamposPreenchidos = pericia => {
  return pericia?.moduloConclusaoPericia ? true : false;
}

export const validar = pericia => {
  const justificativaConclusao = pericia?.moduloConclusaoPericia?.justificativaConclusao;
  const isPreenchido = justificativaConclusao != null && justificativaConclusao.trim().length > 0;
  return {
    isValida: isPreenchido,
    mensagemErro: isPreenchido ? null : MSG_CAMPO_OBRIGATORIO
  };
};