import { formatarDateTimeISOToDataBr } from './DataUtils';

export const obterTextoResultadoCapacidadeLaborativa = (comprovacaoIncapacidade, campoAlteracaoCid, prorrogacao) => {
  const houveComprovacaoIncapacidade = comprovacaoIncapacidade && comprovacaoIncapacidade ==="1";
  const houveAlteracaoCid = campoAlteracaoCid && campoAlteracaoCid === "1";
  const houveProrrogacao = prorrogacao && prorrogacao === "1";
  
  return (campoAlteracaoCid && !houveAlteracaoCid) //cenario mantem CID (PMC/PMRES)
  ? (houveProrrogacao
    ? "Houve comprovação da incapacidade que justifique a prorrogação do benefício" //Prorroga (SIM)
    : (prorrogacao
      ? "Não houve comprovação da incapacidade que justifique a prorrogação do benefício" //Nao Prorroga (NAO)
      : "")) // pergunta "Prorroga?" ainda nao foi respondida
      : ((houveAlteracaoCid || houveComprovacaoIncapacidade) //cenarios altera CID (PMC/PMRES) ou concessao (PI)
      ? "Houve comprovação da incapacidade" //Existe Incapacidade (SIM)
      : (comprovacaoIncapacidade 
        ? "Não houve comprovação da incapacidade" //Nao Existe Incapacidade (NAO)
        : ""));
        }
        
export const obterTextoResultadoCapacidadeLaborativaRBI = (respostaPerguntaIncapacidade, respostaPerguntaInvalidez, isFluxo31_91) => {
    const existeIncapacidade = respostaPerguntaIncapacidade === '1';
    const existeInvalidez = respostaPerguntaInvalidez === '1';

    if(existeIncapacidade){
      return "Houve comprovação da incapacidade que justifique a manutenção do benefício";
    } else if (existeInvalidez){
      return "Houve comprovação da invalidez que justifique a manutenção do benefício";
    } else if(respostaPerguntaIncapacidade || respostaPerguntaInvalidez){
      return `Não houve comprovação da ${isFluxo31_91 ? "incapacidade" : "invalidez"}`;
    }

    return "";
}

 export const obterTextoResultadoNexoTecnico = (moduloNexoTecnico) => (
    moduloNexoTecnico?.existeNexoProfissional
    ? "Sim - Nexo Técnico Profissional"
    : (moduloNexoTecnico?.existeNexoIndividual
      ? "Sim - Nexo Técnico Individual"
      : (moduloNexoTecnico?.existeNtep
        ? "Sim - Nexo Técnico Epidemiológico"
        : "Não")
    )
);

export const obterlabelVinculo = vinculoAtivo => {
  const nomeVinculo = vinculoAtivo.nomeVinculo !== undefined
    ? vinculoAtivo.nomeVinculo
    : vinculoAtivo.descricaoTipoFiliado ? vinculoAtivo.descricaoTipoFiliado : 'DADOS NÃO ENCONTRADOS';
    
  return `${nomeVinculo} - Início: ${formatarDateTimeISOToDataBr(vinculoAtivo.dataInicio)}`
                      + ` - Fim: ${vinculoAtivo.dataFim ? formatarDateTimeISOToDataBr(vinculoAtivo.dataFim) : 'sem data'}`
                      + (vinculoAtivo.dataFim 
                            ? '' //se existe data fim, nao exibe ultima remuneracao
                             : (vinculoAtivo.ultimaRemuneracao ? ` - Última Remuneração: ${vinculoAtivo.ultimaRemuneracao}` : '')); //so exibe ultima remuneracao se o valor estiver preenchido
}