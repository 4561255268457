const INITIAL_STATE = {
  filtro: {
    excluirResultadoPesquisaComMensagemErro : false,
    filtrarComunicacoesComMaxTentativas : false
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ALTERAR_STATUS_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, status: action.payload } }
    case 'ALTERAR_TIPO_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, tipo: action.payload } }
    case 'ALTERAR_PROTOCOLO_PMF_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, protocoloPmf: action.payload } }
    case 'ALTERAR_DATA_INICIO_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, dataInicio: action.payload } }
    case 'ALTERAR_DATA_FIM_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, dataFim: action.payload } }
    case 'ALTERAR_CPF_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, cpf: action.payload } }
    case 'ALTERAR_ERRO_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, erro: action.payload } }
    case 'ALTERAR_TIPO_PERICIA_FILTRO_COMUNICACAO':
      return { ...state, filtro: { ...state.filtro, tipoPericia: action.payload } }
    case 'ALTERAR_FILTRO_MENSAGEM_ERRO': 
      return { ...state, filtro: { ...state.filtro, excluirResultadoPesquisaComMensagemErro: action.payload } }
    case 'ALTERAR_FILTRO_MAX_TENTATIVAS': 
      return { ...state, filtro: { ...state.filtro, filtrarComunicacoesComMaxTentativas: action.payload } }
    case 'LIMPAR_CAMPOS_COMUNICACAO':
      return INITIAL_STATE
    case 'CONSULTA_COMUNICACAO_EXTERNA': 
      return { ...state, listaFiltrada: action.payload }
    case 'CONSULTA_COMUNICACAO_EXTERNA_CSV': 
      return { ...state, listaCsv: action.payload }
    case 'ATUALIZAR_FILTRO_CONSULTA_CSV':
      return {...state, filtroCsv: action.payload }
    case 'EXIBIR_DIALOG_DETALHE_COMUNICACAO': 
      return { ...state, isDetalharComunicacao: true, comunicacaoSelecionada: action.payload }
    case 'OCULTAR_DIALOG_DETALHE_COMUNICACAO': 
      return { ...state, isDetalharComunicacao: false }
    default:
      return state;
  }
}