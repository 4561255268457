
import { obterBatimentoCidCnae, obterFatoresRiscoCidPorCodigo } from '../../../principal/action';

export const consultarFatoresDeRiscoCid = cid => obterFatoresRiscoCidPorCodigo(cid, atualizarFatoresRiscoCid, erroConsultaFatoresRiscoCid);


export const atualizarFatoresRiscoCid = data => ({
  type: 'ATUALIZAR_FATORES_RISCO_CID',
  payload: data
});

const erroConsultaFatoresRiscoCid = data => ({
  type: 'ERRO_CONSULTA_FATORES_RISCO_CID',
  payload: data
});

export const consultarBatimentoCidCnae = (cid, listaCnaes) => obterBatimentoCidCnae(cid, listaCnaes, atualizarBatimentoCidCnae, erroConsultaBatimentoCidCnae);

export const atualizarBatimentoCidCnae = data => ({
  type: 'ATUALIZAR_BATIMENTO_CID_CNAE',
  payload: data
});

const erroConsultaBatimentoCidCnae = data => ({
  type: 'ERRO_CONSULTA_BATIMENTO_CID_CNAE',
  payload: data
});