import React from 'react';
import { connect } from 'react-redux';

import HeaderSecao from '../../components/HeaderSecao';
import BrButton from '@govbr/react-components/lib/BrButton';
import Box from "@material-ui/core/Box";
import BrInput from '@govbr/react-components/lib/BrInput';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import DialogConfirmarExclusao from './DialogConfirmarExclusao';
import DialogAlteracao from './DialogAlteracao';

 import {alterarTituloInclusaoGestor, alterarConteudoInclusaoGestor, incluirItemDicionarioGestor, exibirDialogExcluirItem, exibirDialogAlterarItem} from "./action";

import { MSG_CAMPOS_OBRIGATORIO } from '../../common/Messages';
import { calcularCaracteresRestantes } from '../../common/Utils';
import { possuiPermissoes } from '../../common/AuthManager';
import {GERDIC } from '../../common/permissoes';

const DicionarioPerito = (props) => {

  const QTD_MAXIMA_CARACTERES = 4000;

  const {tituloItemGestorInclusao, conteudoItemGestorInclusao, itensDicionarioGestor, itemSelecionado} = props.dados;
  const validacao = props.dados.validacaoItemGestor;

  const caracteresRestantes = calcularCaracteresRestantes(conteudoItemGestorInclusao, QTD_MAXIMA_CARACTERES);

  const temPermissaoGestor = possuiPermissoes(GERDIC); //TODO: verificar se tem permissao de gestor (GERDIC)

  const itensDicionarioOrdenadoPorNome =  itensDicionarioGestor?.sort((a, b) => a.chaveCampo.localeCompare(b.chaveCampo));

  return (
    <div id="dados-dicionario-gestor">
      {temPermissaoGestor && 
        <>
          <HeaderSecao texto="Incluir no Dicionário" />

          <BrInput id="tituloItemGestor" label="* Título"
            value={tituloItemGestorInclusao}
            onChange={props.alterarTituloInclusaoGestor}
            erro={validacao && validacao.erroTitulo}
            maxLength={100} />

          <BrTextarea id="conteudoItemGestor" label="* Conteúdo"
            value={conteudoItemGestorInclusao}
            onChange={props.alterarConteudoInclusaoGestor}
            maxLength={QTD_MAXIMA_CARACTERES}
            help={`Caracteres restantes: ${caracteresRestantes}`}
            erro={validacao && validacao.erroConteudo} 
            />

          <p>{MSG_CAMPOS_OBRIGATORIO}</p>


          <Box display="flex" position="sticky" bgcolor="#fff" justifyContent="flex-end">
            
            <BrButton id="btnIncluir" primary onClick={() => props.incluirItemDicionarioGestor(tituloItemGestorInclusao,conteudoItemGestorInclusao)}>
              <i className={"fas fa-plus"} />
              Incluir
            </BrButton>
          </Box>
        </>
      }

      <HeaderSecao texto="Itens do Dicionário" />
        <p>
        Conteúdo pré-definido pela PMF para auxiliar na realização das pericias médicas. Estas informações estão disponíveis para todos os peritos, 
        oferecendo um conjunto padronizado de termos e referências úteis durante a análise médica.
        </p>

        {itensDicionarioOrdenadoPorNome && itensDicionarioOrdenadoPorNome.length > 0 &&
        <div id="tabelaDicionarioGestor">
          <table>
            <thead>
              <tr>
                <th>Título</th>
                <th>Conteúdo</th>
                <th style={{ width: '50px' }}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {
                itensDicionarioOrdenadoPorNome?.map(i =>
                  <tr key={i.id}>
                    <td>
                      <b>{i.chaveCampo}</b>
                    </td>
                    <td >
                      {i.valorCampo}
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                    { temPermissaoGestor && 
                      <Box display="flex">
                          <BrButton id="alterarDados" circle
                            onClick={() => props.exibirDialogAlterarItem(i)}>
                            <i className={`fas fa-pen`} title='Alterar Item' />
                          </BrButton>
                          
                          <BrButton id="excluirDados" circle
                            onClick={() => props.exibirDialogExcluirItem(i)}>
                            <i className={`fas fa-trash-alt`} title='Excluir Item' />
                          </BrButton>
                        </Box>
                      }
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      }

      {
        itensDicionarioOrdenadoPorNome && itensDicionarioOrdenadoPorNome.length === 0
        && (
          <center className="texto-informativo red">
            Ainda não foram incluídos itens neste dicionário.
          </center>
        )
      }

      <DialogConfirmarExclusao
        item={itemSelecionado}
        isGestor={true}
      />

      <DialogAlteracao isGestor={true}/>

    </div>
  )
}

const mapStateToProps = (state) => ({
  dados: state.dicionario
})

const mapDispatchToProps = {
  alterarTituloInclusaoGestor, 
  alterarConteudoInclusaoGestor, 
  incluirItemDicionarioGestor,
  exibirDialogExcluirItem,
  exibirDialogAlterarItem
}

export default connect(mapStateToProps, mapDispatchToProps)(DicionarioPerito);