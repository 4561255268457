import React from 'react'

export const PaginaNaoAutorizado = () => {
  return (
    <div style={{minHeight: 200}} >
       <h6 className="color-secondary-06">Usuário não autorizado.</h6> 
    </div>
  )
}

export default PaginaNaoAutorizado;
