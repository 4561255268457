import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Box from "@material-ui/core/Box";

import BrButton from '@govbr/react-components/lib/BrButton';

import { validarCamposAbaSelecionada } from '../../features/principal/action';

class ValidarEProsseguir extends React.Component {

  render = () => {
    const { abaSelecionada, pericia, abas, isSomenteLeitura } = this.props;
    return (
      <Box display="flex" justifyContent="flex-end" p={2}>
        <BrButton id="btnValidarEProsseguir" primary
          onClick={() => this.props.validarCamposAbaSelecionada(abaSelecionada, pericia, abas)}
          disabled={isSomenteLeitura}>
          <i className={`fas fa-step-forward`} />
          Validar e prosseguir
        </BrButton>
      </Box>
    );
  };
}

const mapStateToProps = (state) => {
  const { idAbaSelecionada, abas, isSomenteLeitura } = state.principal;
  return ({
    pericia: state.pericia,
    abaSelecionada: idAbaSelecionada,
    abas,
    isSomenteLeitura
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  validarCamposAbaSelecionada
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValidarEProsseguir);