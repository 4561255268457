import React from 'react'

import BrModal from '@govbr/react-components/lib/BrModal'

import { exibirJSONFormatado } from '../../common/JSONUtils';

const DialogDetalhesResultadoTesteAPI = (props) => {

    const { isDetalharTeste, testeSelecionado, funcaoOcultar } = props;

    return (
      <BrModal id="dialogDetalhesTesteAPI" visible={isDetalharTeste} 
        large style={{ width: "90%", maxHeight: '90%', overflowY: 'auto' }}
        title={`Detalhes do Teste da API: ${testeSelecionado?.nomeAPI}`} 
        onClose={funcaoOcultar}>
        <div> 
          <pre>
            {testeSelecionado?.resultado 
              ? exibirJSONFormatado(JSON.parse(testeSelecionado?.resultado))
              : 'Sem dados'}
          </pre>
        </div>

      </BrModal>
    );
}

export default DialogDetalhesResultadoTesteAPI;
