import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import BrMessage from '@govbr/react-components/lib/BrMessage';

import ModuloFormularioResposta from '../ModuloFormulario/ModuloFormularioResposta';

import '../ModuloFormulario/css/styles.css';
import { useStyles, CelulaValorDominio, CelulaCabecalhoDominio, CelulaResposta } from '../ModuloFormulario/ModuloFormularioGrupo';
import { obterMapRespostasGrupos } from '../ModuloFormulario/utils.js';

import { decodeHtml } from '../../common/StringUtils';
import { alterarRespostaGrupoFormulario } from '../../features/pericia/action';

const obterValorRespostaGrupo = respostaGrupo => respostaGrupo ? respostaGrupo.qualificador : undefined;

const obterErroValidacaoGrupo = (validacao, idGrupo) => {
  const validacaoGrupoDominio = validacao &&
    validacao.gruposComErroDominio &&
    validacao.gruposComErroDominio.find((grupo) => grupo.id === idGrupo);
  return validacaoGrupoDominio && validacaoGrupoDominio.erro;
}

const ModuloFormularioSimplificado = props => {
  const classes = useStyles();

  const { formulario, respostasGrupoForm, validacao, nomeModulo } = props;
  const mapRespostasGrupos = obterMapRespostasGrupos(respostasGrupoForm);
  const errosValidacao = validacao[nomeModulo];

  return (
    <>
      {
        formulario.descricao &&
        <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: decodeHtml(formulario.descricao) }} />
      }
      {
        formulario.gruposFormulario &&
        formulario.gruposFormulario.map((grupoFormulario) => {
          const dominio = grupoFormulario.valoresDominio.split(',');
          const style_coluna_dominio = dominio && dominio.length > 0 ? (5 * dominio.length) : 25;
          const texto_qualificador = dominio && dominio.length > 0 ? `de ${dominio[0]} a ${dominio[dominio.length - 1]}` : '';
          const infoSelecaoQualificador = `Qualifique ${texto_qualificador} as unidades de classificação consideradas para o domínio`;
          const erroValidacaoGrupo = obterErroValidacaoGrupo(errosValidacao, grupoFormulario.id);

          return (
            <div key={grupoFormulario.id}>
              <div>
                <div className="descricao-grupo-formulario">
                  <span className="texto-informativo">{grupoFormulario.nome}: </span>
                  <span dangerouslySetInnerHTML={{ __html: decodeHtml(grupoFormulario.descricao) }} />
                </div>
                {
                  grupoFormulario.indicador &&
                  <div dangerouslySetInnerHTML={{ __html: decodeHtml(grupoFormulario.indicador) }} />
                }
              </div>
              <TableContainer style={{ marginBottom: '20px' }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <CelulaCabecalhoDominio>{infoSelecaoQualificador}</CelulaCabecalhoDominio>
                      <CelulaValorDominio style={{ width: `${style_coluna_dominio}%` }}>
                        <div className="valores-dominio">
                          {dominio.map((resp) => <span key={resp}>{resp}</span>)}
                        </div>
                      </CelulaValorDominio>
                    </TableRow>
                  </TableHead>
                  <TableFooter>
                    <TableRow>
                      <CelulaCabecalhoDominio>
                        * Qualificador do dominio {grupoFormulario.nome}
                      </CelulaCabecalhoDominio>
                      <CelulaResposta>
                        <ModuloFormularioResposta
                          idPergunta={grupoFormulario.id}
                          valor={mapRespostasGrupos && obterValorRespostaGrupo(mapRespostasGrupos.get(grupoFormulario.id))}
                          respostas={dominio}
                          handlerSelecionarResposta={props.alterarRespostaGrupoFormulario}
                          disabled={props.isSomenteLeitura} />
                      </CelulaResposta>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              {
                erroValidacaoGrupo &&
                <BrMessage error><div dangerouslySetInnerHTML={{ __html: erroValidacaoGrupo }} /></BrMessage>
              }
            </div>

          )
        }
        )
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  respostasGrupoForm: state.pericia.respostasGrupoForm,
  validacao: state.principal.validacao,
  isSomenteLeitura: state.principal.isSomenteLeitura
})

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarRespostaGrupoFormulario
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModuloFormularioSimplificado);