import { getSeguroPromise } from "../../common/requests";
import { displayLoader, errorMessage } from '../principal/action';

export const alterarProtocoloPmf = value => ({
  type: 'ALTERAR_PROTOCOLO_PMF_FILTRO_PERICIA',
  payload: value
});
export const alterarProtocoloInss = value => ({
  type: 'ALTERAR_PROTOCOLO_INSS_FILTRO_PERICIA',
  payload: value
});
export const alterarCpfTitular = value => ({
  type: 'ALTERAR_CPF_TITULAR_FILTRO_PERICIA',
  payload: value
});
export const alterarNb = value => ({
  type: 'ALTERAR_NB_FILTRO_PERICIA',
  payload: value
});

export const limpar = () => ({
  type: 'LIMPAR_CAMPOS_FILTRO_PERICIA'
});

export const pesquisar = filtro => {
  const params = (filtro.nb ? `&nb=${filtro.nb}` : "")
    + (filtro.protocoloPmf ? `&protocoloPmf=${filtro.protocoloPmf}` : "")
    + (filtro.protocoloInss ? `&protocoloInss=${filtro.protocoloInss}` : "")
    + (filtro.cpfTitular ? `&cpfTitular=${filtro.cpfTitular}` : "");

  return async dispatch => {
    dispatch(displayLoader(true));
    return await getSeguroPromise(`pmf-realizar-pericias-service/gerencial/pericias?${params.substr(1)}`)
      .then((response) => {
        dispatch(atualizarDadosConsulta(response.data));
        dispatch(displayLoader(false));
        return 1;
      })
      .catch((falha) => {
        dispatch(atualizarDadosConsulta(null));
        dispatch(errorMessage(falha, 'Consulta gerencial perícias'));
        return 0;
      });
  };
}

export const atualizarDadosConsulta = dados => ({
  type: 'CONSULTA_GERENCIAL_PERICIA',
  payload: dados
});
