

import { displayLoader, errorMessage, successMessage } from '../principal/action';
import { postSeguroPromise, getSeguroPromise } from '../../common/requests';
import { MSG_CAMPO_OBRIGATORIO } from "../../common/Messages";
import { formatarDataISOParaURLParam } from '../../common/DataUtils';

export const limparCacheDominiosBI = () => {
    return async dispatch => {
        dispatch(displayLoader(true));

        return postSeguroPromise(`pmf-realizar-pericias-service/dominio/periciaBI/limpar-cache`)
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(successMessage('Limpeza de cache domínios BI realizada com sucesso'));
            })
            .catch((falha) => {
                dispatch(errorMessage(falha, 'Limpeza de cache domínios BI'));
            });
    }
  }

 export const limparCacheSdc = () => {
    return async dispatch => {
        dispatch(displayLoader(true));

        return postSeguroPromise(`pmf-realizar-pericias-service/dominio/sdc/limpar-cache`)
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(successMessage('Limpeza de cache SDC realizada com sucesso'));
            })
            .catch((falha) => {
                dispatch(errorMessage(falha, 'Limpeza de cache SDC'));
            });
    }
}

export const limparCacheTemplates = () => {
    return async dispatch => {
        dispatch(displayLoader(true));

        return postSeguroPromise(`pmf-realizar-pericias-service/pericia/limpar-cache-templates`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(successMessage('Limpeza de cache templates realizada com sucesso'));
        })
        .catch((falha) => {
            dispatch(errorMessage(falha, 'Limpeza de cache templates'));
        });
    }
}

const consultarTemplatePorIdPericia = (idPericia) => {
    return async dispatch => 
        getSeguroPromise(`pmf-realizar-pericias-service/pericia/${idPericia}/template`)
        .then((response) => {
            dispatch(displayLoader(false));            
            dispatch(atualizarTemplateConsulta(response.data));
            dispatch(successMessage('Consulta template por id perícia realizada com sucesso'));
        })
        .catch((falha) => {
            dispatch(errorMessage(falha, 'Consulta template por id perícia'));
        });
}

const consultarTemplatePorIdTemplate = (filtro) => {
    const parametroIdTemplateOrigem = filtro.idTemplateOrigem ? `&idTemplateOrigem=${filtro.idTemplateOrigem}` : '';
    return async dispatch => 
        getSeguroPromise(`pmf-realizar-pericias-service/template/?id=${filtro.idTemplate}${parametroIdTemplateOrigem}`)
        .then((response) => {
            dispatch(displayLoader(false));            
            dispatch(atualizarTemplateConsulta(response.data));
            dispatch(successMessage('Consulta template por id realizada com sucesso'));
        })
        .catch((falha) => {
            dispatch(errorMessage(falha, 'Consulta template por id'));
        });
}

const consultarTemplatePorTipoPericia = (filtro) => {
    const parametroIdTemplateOrigem = filtro.idTemplateOrigem ? `&idTemplateOrigem=${filtro.idTemplateOrigem}` : '';

    const valorData = filtro.dataReferencia 
        ? formatarDataISOParaURLParam(filtro.dataReferencia) 
        : formatarDataISOParaURLParam(new Date());

    return async dispatch => 
        getSeguroPromise(`pmf-realizar-pericias-service/template/tipo?tipoPericia=${filtro.tipoPericia}&dataReferencia=${valorData}${parametroIdTemplateOrigem}`)
        .then((response) => {
            dispatch(displayLoader(false));            
            dispatch(atualizarTemplateConsulta(response.data));
            dispatch(successMessage('Consulta template por tipo perícia realizada com sucesso'));
        })
        .catch((falha) => {
            dispatch(errorMessage(falha, 'Consulta template por tipo perícia'));
        });
  }

export const pesquisar = (filtro) => {
    const dados = validarCamposPesquisa(filtro);
    return (dispatch) => {
      dispatch(displayLoader(true));
      dispatch(atualizarTemplateConsulta(undefined));
      dispatch(atualizarDadosValidacaoFiltro(dados));
      if (dados.isValida) {
          // consultar template por filtro
        switch(filtro.tipoConsulta){
        case 1:
            dispatch(consultarTemplatePorIdPericia(filtro.idPericia));
            break;
        case 2:
            dispatch(consultarTemplatePorTipoPericia(filtro));
            break;
        case 3:
            dispatch(consultarTemplatePorIdTemplate(filtro));
            break;
            default:
                break;
        }
      } else {  
        dispatch(displayLoader(false));
      }
    }
}

const atualizarDadosValidacaoFiltro = dados => ({
    type: 'ATUALIZAR_DADOS_VALIDACAO_CONSULTA_TEMPLATE',
    payload: dados
});

const atualizarTemplateConsulta = dados => ({
    type: 'ATUALIZAR_DADOS_CONSULTA_TEMPLATE',
    payload: dados
});

export const exibirDialogTemplate = () => ({
    type: 'EXIBIR_DIALOG_TEMPLATE'
  });
  
  export const ocultarDialogTemplate = () => ({
    type: 'OCULTAR_DIALOG_TEMPLATE'
  });
  

const validarCamposPesquisa = filtro => {
    const { tipoConsulta, tipoPericia, idTemplate, idPericia } = filtro;
  
    const erroTipoConsulta = !tipoConsulta ? MSG_CAMPO_OBRIGATORIO : null;
    const erroTipoPericia = (tipoConsulta === 2 && !tipoPericia) ? MSG_CAMPO_OBRIGATORIO : null;
    const erroIdPericia = (tipoConsulta === 1 && !idPericia) ? MSG_CAMPO_OBRIGATORIO : null;
    const erroIdTemplate = (tipoConsulta === 3 && !idTemplate) ? MSG_CAMPO_OBRIGATORIO : null;
  
    return {
      isValida: !erroTipoConsulta && !erroTipoPericia && !erroIdPericia && !erroIdTemplate,
      erroTipoConsulta: erroTipoConsulta,
      erroTipoPericia: erroTipoPericia,
      erroIdPericia: erroIdPericia,
      erroIdTemplate: erroIdTemplate
    }
  }

export const limparCamposPesquisa = () => ({
    type: 'LIMPAR_CAMPOS_CONSULTA_TEMPLATE'
});

export const atualizarTipoPesquisaTemplate = (value) => ({
    type: 'ALTERAR_TIPO_CONSULTA_TEMPLATE',
    payload: value
});

export const atualizarIdPericiaPesquisaTemplate = (value) => ({
    type: 'ALTERAR_ID_PERICIA_CONSULTA_TEMPLATE',
    payload: value
});

export const atualizarIdTemplatePesquisaTemplate = (value) => ({
    type: 'ALTERAR_ID_TEMPLATE_CONSULTA_TEMPLATE',
    payload: value
});

export const atualizarIdTemplateOrigemPesquisaTemplate = (value) => ({
    type: 'ALTERAR_ID_TEMPLATE_ORIGEM_CONSULTA_TEMPLATE',
    payload: value
});

export const atualizarTipoPericiaPesquisaTemplate = (value) => ({
    type: 'ALTERAR_TIPO_PERICIA_CONSULTA_TEMPLATE',
    payload: value
});

export const atualizarDataReferenciaPesquisaTemplate = (value) => ({
    type: 'ALTERAR_DATA_REFERENCIA_CONSULTA_TEMPLATE',
    payload: value
});
  