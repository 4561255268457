import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import Grid from "@material-ui/core/Grid";

import BrInput from '@govbr/react-components/lib/BrInput';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';
import MensagemTelemedicinaExameFisico from '../MensagemTelemedicinaExameFisico';

import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { validarValorMaximo } from '../../../../common/ValidationUtil';

import { alterarAltura, alterarDescricaoExameFisico, alterarPeso, alterarPressaoMaxima, alterarPressaoMinima } from '../../action';


class ExameFisico extends React.Component {

  calcularIMC = (peso, altura) => {
    if (peso && altura) {
      return (peso / (altura * altura)).toFixed(2);
    }
  }

  QTD_MAXIMA_CARACTERES = 4000;

  render() {
    const { exameFisico, validacao, isSomenteLeitura, realizadaPorTelemedicina } = this.props;

    const caracteresRestantes = calcularCaracteresRestantes(exameFisico.descricaoExameFisico, this.QTD_MAXIMA_CARACTERES);
    const imcValue = this.calcularIMC(exameFisico.peso, exameFisico.altura);

    const isCampoTextareaDesabilitado = isSomenteLeitura;

    return (
      <div id="abaExameFisico" className="conteudoAbaSemHeaderSecao">
        <p>
          Considerar as alterações relevantes observadas ao exame físico, que darão subsídios para a avaliação e qualificação dos domínios abaixo relacionados.
        </p>
        {realizadaPorTelemedicina &&
          <>
            <p>
              Registrar aspectos da inspeção detalhada e dos exames complementares.
            </p>
            <MensagemTelemedicinaExameFisico />
          </>
        }
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <fieldset>
              <legend style={{ fontSize: '0.9em', padding: '0 5px' }}>Pressão Arterial (mmHg)</legend>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <BrInput id="pressaoMaxima" label="Máxima" mode="number" maxLength={3}
                    value={exameFisico.pressaoMaxima}
                    onChange={this.props.alterarPressaoMaxima}
                    erro={validacao ? validacao.erroPressaoMaxima : null}
                    disabled={isSomenteLeitura} />
                </Grid>
                <Grid item xs={6}>
                  <BrInput id="pressaoMinima" label="Mínima" mode="number" maxLength={3}
                    value={exameFisico.pressaoMinima}
                    onChange={this.props.alterarPressaoMinima}
                    erro={validacao ? validacao.erroPressaoMinima : null}
                    disabled={isSomenteLeitura} />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
          <Grid container item xs={8} spacing={4}>
            <Grid item xs={4}>
              <BrInput id="peso" label="Peso (kg)" mode="number"
                decimalScale={2} decimalSeparator=","
                value={exameFisico.peso}
                fixedDecimalScale
                isAllowed={(input) => validarValorMaximo(input.value, 999)}
                onChange={this.props.alterarPeso}
                disabled={isSomenteLeitura} />
            </Grid>
            <Grid item xs={4}>
              <BrInput id="altura" label="Altura (m)" mode="number"
                decimalScale={2} decimalSeparator=","
                value={exameFisico.altura}
                fixedDecimalScale
                isAllowed={(input) => validarValorMaximo(input.value, 3)}
                onChange={this.props.alterarAltura}
                erro={validacao ? validacao.erroAltura : null}
                disabled={isSomenteLeitura} />
            </Grid>
            <Grid item xs={4}>
              <BrInput id="imc" label="IMC" value={imcValue} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea id="descricao" label="* Descrição" rows="10"
                  value={exameFisico.descricaoExameFisico}
                  maxLength={this.QTD_MAXIMA_CARACTERES}
                  onChange={this.props.alterarDescricaoExameFisico}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  erro={validacao ? validacao.erroDescricao : null}
                  disabled={isCampoTextareaDesabilitado} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioDescricaoExameFisico"
                    onSelect={this.props.alterarDescricaoExameFisico}
                    maxLength={caracteresRestantes}
                    valorAtual={exameFisico.descricaoExameFisico}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  exameFisico: state.pericia.moduloExameFisico,
  realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina,
  validacao: state.principal.validacao.exameFisico,
  isSomenteLeitura: state.principal.isSomenteLeitura
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarPressaoMaxima,
  alterarPressaoMinima,
  alterarPeso,
  alterarAltura,
  alterarDescricaoExameFisico
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExameFisico);