import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import BrTabs from '@govbr/react-components/lib/BrTabs';
import BrButton from '@govbr/react-components/lib/BrButton';
import BrTable from '@govbr/react-components/lib/BrTable';
import BrModal from '@govbr/react-components/lib/BrModal';
import BrMessage from '@govbr/react-components/lib/BrMessage';

import Grid from "@material-ui/core/Grid";

import { buscarValoresBibliotecaPerito, ocultarDialogSelecionarItem } from './action';

const DialogSelecaoItemDicionario = (props) => {

  const { dados, valoresCamposGenericos, pericia } = props;
  const { itensDicionarioGestor, itensDicionarioPerito, isSelecaoDicionario, onSelectParams, onSelect, maxLength, valorAtual } = dados;
  const [existeItemDesabilitado, setExisteItemDesabilitado] = useState(false);
  
  const verificarSeHabilitaItem = (item) => {
    /* O -1 no maxLength é devido ao espaço adicionado na concatenação.*/
    return item.valorCampo?.length > maxLength

  }

  const tratarMensagemItemDesabilitado = () => {
    setExisteItemDesabilitado(false);

    if (itensDicionarioGestor && itensDicionarioGestor.length > 0 && itensDicionarioGestor.some(verificarSeHabilitaItem)){
      setExisteItemDesabilitado(true);
    }
    if (itensDicionarioPerito && itensDicionarioPerito.length > 0 && itensDicionarioPerito.some(verificarSeHabilitaItem)){
      setExisteItemDesabilitado(true);
    }
  }

  useEffect(() => {
    if(isSelecaoDicionario && !itensDicionarioGestor &&!itensDicionarioPerito){
      props.buscarValoresBibliotecaPerito(props.cpfPerito);
    }
    
    tratarMensagemItemDesabilitado();
    // eslint-disable-next-line
  }, [isSelecaoDicionario]);//deve executar apenas quando o dialog for exibido/ocultado

  useEffect(() => {

    tratarMensagemItemDesabilitado();
    // eslint-disable-next-line
  }, [itensDicionarioGestor,itensDicionarioPerito]);

  const [selectedTab, setSelectTab] = useState("dicionarioPerito");
  const itensDicionarioGestorOrdenadoPorNome =  itensDicionarioGestor?.sort((a, b) => a.chaveCampo.localeCompare(b.chaveCampo));
  const itensDicionarioPeritoOrdenadoPorNome =  itensDicionarioPerito?.sort((a, b) => a.chaveCampo.localeCompare(b.chaveCampo));

  const concatenarString = (textoAtual, novoTexto) =>{
    const textoConcatenado = textoAtual === undefined || textoAtual === null || textoAtual === "" ? novoTexto : textoAtual + novoTexto;
    return textoConcatenado;
  }

  const preencherComValorItemDicionario = (value) => {
    const novoTexto = concatenarString(valorAtual, value);
    if(onSelectParams){
      if(onSelectParams.sigla){
        //campo generico
        onSelect(onSelectParams.id, onSelectParams.sigla, novoTexto, valoresCamposGenericos, pericia);
      } else {
        //campo em lista de valores (ex: exame fisico, ...?)
        onSelect(parseInt(onSelectParams.id), novoTexto);
      }
    }else{
      //campo de modulo estatico
      onSelect(novoTexto);
    }
    props.ocultarDialogSelecionarItem();
  }

  return (
    <BrModal id={`dialogSelecaoItemDicionario`} visible={isSelecaoDicionario} onClose={props.ocultarDialogSelecionarItem}
      title={"Selecionar texto do dicionário"} style={{ width: "70%", maxHeight: '80%', overflowY: 'scroll' }}>

      <div id="tela-selecao-dicionario" className="col">

          {existeItemDesabilitado && <BrMessage warning>
            <Grid container alignItems="center">
              <Grid item>
                <span className="mr-2">
                Textos maiores que os caracteres restantes do campo a ser preenchido não podem ser copiados e estão desabilitados.
                </span>
              </Grid>
            </Grid>
          </BrMessage>}

          <BrTabs
            value={selectedTab}
            onChange={value => setSelectTab(value)}
            label="Dicionário de Dados"
          >
            <BrTabs.Tabs label="Tabs">
              <BrTabs.Tab id="dicionarioPerito" label="Meu Dicionário" />
              <BrTabs.Tab id="dicionarioGestor" label="Dicionário PMF" />
            </BrTabs.Tabs>

            <BrTabs.Content>
              <BrTabs.Panel contentFor="dicionarioPerito">

              { itensDicionarioPeritoOrdenadoPorNome?.length > 0 &&
                <BrTable
                      id="tabelaItensDicionarioPerito"
                      layout="fixed"
                      columns={[{ Header: "Título", accessor: "titulo" }, { Header: "Conteúdo", accessor: "conteudo" },
                              { Header: "Ação", accessor: "acao" }]}
                      data={itensDicionarioPeritoOrdenadoPorNome.map(item => ({
                        titulo: <b>{item.chaveCampo}</b>,
                        conteudo: item.valorCampo,
                        acao:
                        <BrButton circle onClick={() => preencherComValorItemDicionario(item.valorCampo)}
                          disabled={verificarSeHabilitaItem(item)}>
                           <i className={`fas fa-copy`} title='Copiar texto' />
                        </BrButton>
                      }))}
                      headerProps={{
                        show: true,
                        showDensityOptions: false,
                        searchProp: {
                          show: true
                        }
                      }}
                      footerProps={{ show: false }}
                    />
              }

              {
                itensDicionarioPeritoOrdenadoPorNome && itensDicionarioPeritoOrdenadoPorNome.length === 0
                && (
                  <center className="texto-informativo red">
                    Ainda não foram incluídos itens neste dicionário.
                  </center>
                )
              }
              </BrTabs.Panel>
              <BrTabs.Panel contentFor="dicionarioGestor">

              { itensDicionarioGestorOrdenadoPorNome?.length > 0 &&
                <BrTable
                      id="tabelaItensDicionarioPerito"
                      layout="fixed"
                      columns={[{ Header: "Título", accessor: "titulo" }, { Header: "Conteúdo", accessor: "conteudo" },
                              { Header: "Ação", accessor: "acao" }]}
                      data={itensDicionarioGestorOrdenadoPorNome.map(item => ({
                        titulo: <b>{item.chaveCampo}</b>,
                        conteudo: item.valorCampo,
                        acao:
                        <BrButton circle onClick={() => preencherComValorItemDicionario(item.valorCampo)}
                          disabled={verificarSeHabilitaItem(item)}>
                           <i className={`fas fa-copy`} title='Copiar texto' />
                        </BrButton>
                      }))}
                      headerProps={{
                        show: true,
                        showDensityOptions: false,
                        searchProp: {
                          show: true
                        }
                      }}
                      footerProps={{ show: false }}
                    />
              }

              {
                itensDicionarioGestorOrdenadoPorNome && itensDicionarioGestorOrdenadoPorNome.length === 0
                && (
                  <center className="texto-informativo red">
                    Ainda não foram incluídos itens neste dicionário.
                  </center>
                )
              }
              </BrTabs.Panel>
            </BrTabs.Content>
          </BrTabs>
        </div>
    </BrModal>
  );
    
}

const mapStateToProps = (state) => ({
  pericia: state.pericia,
  dados: state.dicionario,
  cpfPerito: state.principal.userData.document.number,
  valoresCamposGenericos: state.pericia.valoresCamposGenericos
})

const mapDispatchToProps = {
  buscarValoresBibliotecaPerito,
  ocultarDialogSelecionarItem
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogSelecaoItemDicionario);