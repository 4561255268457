import React from 'react';
import {
  Route, Switch
} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import MenuContextual from "./MenuContextual";
import ScrollToTop from '../../components/ScrollToTop';

import { consultarPericia, consultarPericiaPorToken } from '../pericia/action';


class Principal extends React.Component {

  componentDidMount() {
    if (!this.props.pericia.id) {
      let id = window.sessionStorage.getItem('idPericia');
      let hash = window.sessionStorage.getItem('hash');
      let token = window.sessionStorage.getItem('token');
      if (hash && token) {
        this.props.consultarPericiaPorToken(token, hash, this.props.somenteLeitura);
      } else if (id) {
        this.props.consultarPericia(id, this.props.somenteLeitura);
      }
    }
  }

  render() {
    const { pericia, template, abas } = this.props;

    if (!pericia || !pericia.id || !template || !template.id) {
      return null;
    }

    return (
      <>
        <MenuContextual />
        <div className="col col-sm-8 col-md-9" id="container-content">
          <div className="main-content" id="main-content">
            <ScrollToTop>
              <Switch>
                {
                  abas.map(aba => (
                    this.props.somenteLeitura ? 
                      <Route key={aba.id} exact path={`/consulta/pericia/detalhe/${aba.path}`} >
                        {aba.conteudo}
                      </Route>
                      :
                      <Route key={aba.id} exact path={`/pericia/${aba.path}`} >
                        {aba.conteudo}
                      </Route>
                  ))
                }
              </Switch>
              </ScrollToTop>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  pericia: state.pericia,
  template: state.principal.template,
  abas: state.principal.abas
});

const mapDispatchToProps = dispatch => bindActionCreators({
  consultarPericia,
  consultarPericiaPorToken
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
