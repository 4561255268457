import React from 'react';

import { Grid } from '@material-ui/core';
import HeaderSecao from '../../../../components/HeaderSecao';

import { formatarDateTimeISOToDataBr } from '../../../../common/DataUtils';
import { convertDoubleToCurrentReal } from '../../../../common/StringUtils';

export const PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS = '---';

const getMotivoCessacao = beneficio => {
  if (!beneficio) {
    return PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS;
  }
  let retorno = "";
  if (beneficio.codigoMotivoCessacao) {
    retorno += `${beneficio.codigoMotivoCessacao}`
  }
  if (beneficio.descricaoMotivoCessacao) {
    retorno += ` - ${beneficio.descricaoMotivoCessacao}`;
  }
  return retorno;
}

export const obterDataBeneficioCollapsed = beneficio => [
  {
    sectionTitle: "Dados Gerais",
    sectionData: [
      {
        label: "CID",
        value: beneficio.codigoCID10 || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data de Início da Doença (DID)",
        value: formatarDateTimeISOToDataBr(beneficio.dataInicioDoenca) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data de Início da Incapacidade (DII)",
        value: formatarDateTimeISOToDataBr(beneficio.dataInicioIncapacidade) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Situação",
        value: beneficio.situacao
      },
      {
        label: "Data de Início do Benefício (DIB)",
        value: formatarDateTimeISOToDataBr(beneficio.dataInicioBeneficio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data de Cessação do Benefício (DCB)",
        value: formatarDateTimeISOToDataBr(beneficio.dataCessacaoBeneficio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Motivo da Cessação",
        value: getMotivoCessacao(beneficio)
      },
      {
        label: "Motivo do Indeferimento",
        value: beneficio.codigoMotivoIndeferimento ? `${beneficio.codigoMotivoIndeferimento} - ${beneficio.descricaoMotivoIndeferimento}` : PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data de Entrada do Requerimento (DER)",
        value: formatarDateTimeISOToDataBr(beneficio.dataEntradaRequerimento) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data de Início do Pagamento (DIP)",
        value: formatarDateTimeISOToDataBr(beneficio.dataInicioPagamento) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Despacho",
        value: beneficio.descricaoDespacho || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Data do Despacho (DDB)",
        value: formatarDateTimeISOToDataBr(beneficio.dataDespacho) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Valor da Renda Mensal Inicial (RMI)",
        value: convertDoubleToCurrentReal(beneficio.rendaMensalInicialCalculada) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Ramo da Atividade",
        value: beneficio.descricaoRamoAtividade || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Forma de Filiação",
        value: beneficio.descricaoFormaFiliacao || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      }
    ]
  }
];

export const obterDataAtividadeCollapsed = atividade => [
  {
    sectionTitle: "Dados Gerais",
    sectionData: [
      {
        label: "Tipo Filiação",
        value: atividade.descricaoTipoFiliado || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      },
      {
        label: "Natureza",
        value: atividade.naturezaOcupacao || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
      }
    ]
  }
];


export const obterDescricaoVinculo = vinculo => {
  let descricao = vinculo.nomeVinculo;

  if (!descricao) {
    descricao = vinculo.descricaoTipoFiliado;
  }
  return descricao || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS;
}

export const obterDataVinculoCollapsed = vinculo =>
  [
    {
      sectionTitle: "Empregador",
      sectionData: [
        {
          label: "Razão Social",
          value: vinculo.nomeVinculo || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        },
        {
          label: "Início Atividade",
          value: formatarDateTimeISOToDataBr(vinculo.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        }
      ]
    },
    {
      sectionTitle: "Atividade Exercida pela Empresa - CNAE",
      sectionData: formatarCnae(vinculo.descricaoCnae)
    },
    {
      sectionTitle: "Vínculo",
      sectionData: [
        {
          label: "Tipo de Filiação",
          value: vinculo.descricaoTipoFiliado || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        },
        {
          label: "Admissão",
          value: formatarDateTimeISOToDataBr(vinculo.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        },
        {
          label: "Demissão",
          value: formatarDateTimeISOToDataBr(vinculo.dataFim) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        }
      ]
    },
    {
      sectionTitle: "Ocupações - CBO",
      sectionData: listOcupacoes(vinculo.listaOcupacoesVinculo)
    },
    {
      sectionTitle: "Afastamentos",
      sectionData: listAfastamentos(ordernarAfastamentos(vinculo.listaAfastamentosVinculo))
    }
  ];

function ordernarAfastamentos(afastamentos) {
  const afastamentosOrdenados = afastamentos 
    ? [...afastamentos].sort((v1,v2) => new Date(v2.dataInicio) - new Date(v1.dataInicio)) 
    : [];
  return afastamentosOrdenados;
}

const formatarCnae = cnae => {
  let retorno = [];
  cnae && retorno.push({
    label: null,
    value: cnae
  });
  return retorno;
}

const listAfastamentos = list => {
  let retorno = [];
  list && list.forEach(value => {
    retorno.push({
      label: "Motivo do Afastamento",
      value: `${value.idMotivoAfastamento} - ${value.motivoAfastamento}` || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
    retorno.push({
      label: "Data de Início",
      value: formatarDateTimeISOToDataBr(value.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
    retorno.push({
      label: "Data Fim",
      value: formatarDateTimeISOToDataBr(value.dataFim) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
  });
  return retorno;
}

const listOcupacoes = list => {
  let retorno = [];
  list.forEach(value => {
    retorno.push({
      label: "Descrição",
      value: value.cbo || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
    retorno.push({
      label: "Data de Início",
      value: formatarDateTimeISOToDataBr(value.dataInicio) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
    retorno.push({
      label: "Data Fim",
      value: formatarDateTimeISOToDataBr(value.dataFim) || PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
    });
  });
  return retorno;
}

export const createRecordVinculoCollapsed = item => createRecordsCollapsed(obterDataVinculoCollapsed(item));
export const createRecordBeneficioCollapsed = item => createRecordsCollapsed(obterDataBeneficioCollapsed(item));
export const createRecordAtividadeCollapsed = item => createRecordsCollapsed(obterDataAtividadeCollapsed(item));


const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
}

const createRecordsCollapsed = (dataCollapsed) => {
  return (
    dataCollapsed.map((value, index) =>
      <React.Fragment key={generateKey(`collapse-${index}-`)}>
        <Grid item>
          <HeaderSecao texto={value.sectionTitle} />
        </Grid>
        <Grid container spacing={4} style={{ width: 'auto', paddingBottom: '1.1em' }}>
          {value.sectionData[0]?.value ?
            createRecordsCollapsedFields(value.sectionData)
            :
            <Grid item>
              <p className="texto-informativo">Nenhum registro encontrado</p>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    )
  )
}

const createRecordsCollapsedFields = (sectionData) => {
  const sectionLengthForXsParam = sectionData.length;
  return (
    sectionData.map((value, index) => {
      if (sectionLengthForXsParam === 1) {
        return (
          <Grid item xs={12} key={generateKey(`collapse-cell-${index}-`)}>
            {gridContentSectionData(value)}
          </Grid>
        )
      } else if (sectionLengthForXsParam === 2) {
        return (
          <Grid item xs={6} key={generateKey(`collapse-cell-${index}-`)}>
            {gridContentSectionData(value)}
          </Grid>
        )
      }
      return (
        <Grid item xs={4} key={generateKey(`collapse-cell-${index}-`)}>
          {gridContentSectionData(value)}
        </Grid>
      )
    })
  )
}

const gridContentSectionData = (sectionDataMapValue) => {
  return (
    <>
      {sectionDataMapValue.label &&
        <p>
          {sectionDataMapValue.label}:
        </p>
      }
      <p id="nome" className="texto-informativo">
        {
          sectionDataMapValue.value ?
            sectionDataMapValue.value :
            PREENCHIMENTO_EM_TELA_CAMPOS_SEM_DADOS_ENCONTRADOS
        }
      </p>
    </>
  )
}