import React from "react";
import { Grid } from '@material-ui/core';

import ListaValoresTexto from "./listaValoresTexto";
import BrCard from '@govbr/react-components/lib/BrCard';

const DetalheListaValoresTexto = ({ lista }) => {

  return (
    <>
      <Grid item xs={12} >
        <BrCard>
          <Grid container spacing={1}>
              <Grid item xs={12}>
                <span className="texto-informativo">{lista.nomeCampo}</span>
              </Grid>
              <Grid item xs={12}>
                <p className="ml-10">
                  Antes da revisão: 
                  {(!lista.valorOrigem ||
                    lista.valorOrigem.length === 0) &&
                      <span className="texto-informativo ml-10 valor-anterior-revisao">---</span>
                  }
                </p>

                {(lista.valorOrigem &&
                  lista.valorOrigem.length > 0) &&
                  <ListaValoresTexto lista={lista.valorOrigem} isValorAtual={false}/>
                }

              </Grid>

              <Grid item xs={12} >
                <p className="ml-10">
                  Após revisão: 
                  {(!lista.valorAtual ||
                    lista.valorAtual.length === 0) &&
                      <span className="texto-informativo ml-10 valor-atual-revisao">---</span>
                  }
                </p>

                {(lista.valorAtual &&
                  lista.valorAtual.length > 0) &&
                  <ListaValoresTexto lista={lista.valorAtual} isValorAtual={true}/>
                }

              </Grid>
          </Grid>
        </BrCard>
      </Grid>

    </>);
};


export default DetalheListaValoresTexto;

