export const padLeft = (value, padString, length) => {
  if (!value) {
    return '';
  }
  let result = String(value);
  if (result) {
    while (result.length < length) { result = padString + result; }
  }
  return result;
};

export const trunc = (value, length) => {
  return value.substring(0, length);
}

export function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function convertDoubleToCurrentReal(value){
  return value ? value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null;
}

