export default (state = {}, action) => {
  switch (action.type) {
    case 'ATUALIZAR_FATORES_RISCO_CID':
      return { ...state, fatoresRiscoCid: action.payload, erroConsultaFatoresRiscoCid: false }
    case 'ERRO_CONSULTA_FATORES_RISCO_CID':
      return { ...state, erroConsultaFatoresRiscoCid: true }
    case 'ATUALIZAR_BATIMENTO_CID_CNAE':
      return { ...state, batimentoCidCnae: action.payload, erroConsultaBatimentoCidCnae: false }
    case 'ERRO_CONSULTA_BATIMENTO_CID_CNAE':
      return { ...state, erroConsultaBatimentoCidCnae: true }
    default:
      return state;
  }
};