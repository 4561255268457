import React, { useRef, useState } from 'react';

import BrTooltip from '@govbr/react-components/lib/BrTooltip';
import Box from "@material-ui/core/Box";

const HeaderSecao = props => {

  const headerInfoRef = useRef();

  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <Box display="flex">
      <Box mr={1}>
        <h6 className="color-secondary-06">{props.texto}</h6>
      </Box>
      {
        props.info && (
          <Box mr={1} pt={3}>
            <BrTooltip
              id="infoHeaderSecao"
              anchorEl={headerInfoRef.current}
              open={openTooltip}
              onClose={() => setOpenTooltip(false)}
              top
              info
            >
              {props.info}
            </BrTooltip>
            <i className="fas fa-info-circle" aria-hidden="true"
              ref={headerInfoRef}
              onClick={() => setOpenTooltip(true)}
            />
          </Box>
        )
      }
      <Box flexGrow={1} pt={3}>
        <hr />
      </Box>
    </Box>
  );
}

export default HeaderSecao;