import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BrButton from "@govbr/react-components/lib/BrButton";
import BrCheckbox from "@govbr/react-components/lib/BrCheckbox";
import BrTable from "@govbr/react-components/lib/BrTable";

import { Grid } from "@material-ui/core";

import './styles.css';

import {
  exibirDialogAlterarDocumMedOdont,
  exibirDialogDetalharDocumMedOdont,
  exibirDialogExcluirDocumMedOdont,
  exibirDialogIncluirDocumMedOdont
} from "./action";

import { alterarNaoExisteExameLaudo } from "../../action";

import {
  obterNumeroComUf,
  obterTipoIdentificacao
} from "./utils";

import DialogConfirmarExclusao from "./DialogConfirmarExclusao";
import DialogDadosDocumMedOdont from "./DialogDadosDocumMedOdont";

import { formatarDateTimeISOToDataBr } from "../../../../common/DataUtils";
import { tiposPericia } from "../../constants";

class DocumMedOdont extends React.Component {
  render() {
    const {
      naoExisteExameLaudo,
      examesLaudo,
      documentoSelecionado,
      validacao,
      isFormVisible,
      isSomenteLeitura,
      isTipoPericiaAtestmed,
      tiposIdentificacaoEmitenteDocumMedOdont,
      realizadaPorTelemedicina
    } = this.props;
    const {
      alterarNaoExisteExameLaudo,
      exibirDialogIncluirDocumMedOdont,
      exibirDialogDetalharDocumMedOdont,
      exibirDialogAlterarDocumMedOdont,
      exibirDialogExcluirDocumMedOdont
    } = this.props;

    return (
      <div id="abaDocumMedOdont" className="conteudoAbaSemHeaderSecao">
        
        <Grid container spacing={2} direction="row" alignItems="center" style={{paddingBottom: "10px"}}>
          <Grid item sm={4}>
            <BrButton
              onClick={exibirDialogIncluirDocumMedOdont}
              disabled={
                isFormVisible || naoExisteExameLaudo === 1 || isSomenteLeitura
              }
            >
              <i className={`fas fa-plus`} />
              Incluir Documento
            </BrButton>
          </Grid>
          {
            !isTipoPericiaAtestmed && 
            <Grid item sm={8}>
              <BrCheckbox
                id="naoApresentouDocumentos"
                items={[
                  {
                    label: `* Não apresentou documentos ${realizadaPorTelemedicina ? "ou os documentos estão anexados ao processo administrativo" : ""}`,
                    value: "naoExisteExameLaudo"
                  }
                ]}
                values={naoExisteExameLaudo === 1 ? ["naoExisteExameLaudo"] : []}
                onChange={(item, checked) => {
                  checked
                    ? alterarNaoExisteExameLaudo(1)
                    : alterarNaoExisteExameLaudo(0);
                }}
                erro={validacao && validacao.erroNaoApresentouExames}
                disabled={
                  isSomenteLeitura || (examesLaudo && examesLaudo.length > 0)
                }
              />
            </Grid>
          }
        </Grid>

        {examesLaudo && examesLaudo.length > 0 && (
          <BrTable
            id="tabelaDocumentos"
            title="Documentos Médicos Odontológicos"
            layout="fixed"
            columns={[
              { Header: "Data do documento", accessor: "data" },
              {
                Header: "Tipo de Identificação",
                accessor: "tipoIdentificacao"
              },
              { Header: "Número", accessor: "numero" },
              { Header: "Ações", accessor: "acao" }
            ]}
            data={examesLaudo.map((item) => ({
              data: formatarDateTimeISOToDataBr(item.dataDocumento),
              tipoIdentificacao: obterTipoIdentificacao(
                item.idTipoIdentEmitente,
                tiposIdentificacaoEmitenteDocumMedOdont
              ),
              numero: obterNumeroComUf(item, this.props.ufs),
              acao: (
                <>
                  <BrButton
                    id="detalharDocumMedOdont"
                    circle
                    onClick={() => exibirDialogDetalharDocumMedOdont(item)}
                    disabled={isFormVisible}
                  >
                    <i className={`fas fa-eye`} title="Detalhar" />
                  </BrButton>
                  <BrButton
                    id="alterarDocumMedOdont"
                    circle
                    onClick={() => exibirDialogAlterarDocumMedOdont(item)}
                    disabled={isFormVisible || isSomenteLeitura}
                  >
                    <i className={`fas fa-pen`} title="Alterar" />
                  </BrButton>
                  <BrButton
                    id="excluirDocumMedOdont"
                    circle
                    onClick={() => exibirDialogExcluirDocumMedOdont(item)}
                    disabled={isFormVisible || isSomenteLeitura}
                  >
                    <i className={`fas fa-trash-alt`} title="Excluir" />
                  </BrButton>
                </>
              )
            }))}
            headerProps={{
              show: false,
              showDensityOptions: false,
              searchProp: {
                show: false
              }
            }}
            footerProps={{ show: false }}
          />
        )}

        {isFormVisible && <DialogDadosDocumMedOdont />}

        <DialogConfirmarExclusao
          documento={documentoSelecionado}
          texto={`Confirma exclusão do documento selecionado ?`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { naoExisteExameLaudo, examesLaudo } = state.pericia.moduloExameLaudo;
  const { documentoSelecionado, isFormVisible } =
    state.modulos.moduloDocumMedOdont;
  const { tiposIdentEmitentePericiaBI } = state.principal.dominioPericia;

  return {
    naoExisteExameLaudo,
    examesLaudo,
    documentoSelecionado,
    isFormVisible,
    tiposIdentificacaoEmitenteDocumMedOdont: tiposIdentEmitentePericiaBI,
    ufs: state.principal.dominioSdc.ufs,
    validacao: state.principal.validacao.documentacaoMedicaOdontologica,
    isSomenteLeitura: state.principal.isSomenteLeitura,
    isTipoPericiaAtestmed: (state.pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SIBE.tipo) || (state.pericia.tipoPericia === tiposPericia.INSS_BI_ATESTMED_SABI.tipo),
    realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alterarNaoExisteExameLaudo,
      exibirDialogIncluirDocumMedOdont,
      exibirDialogDetalharDocumMedOdont,
      exibirDialogAlterarDocumMedOdont,
      exibirDialogExcluirDocumMedOdont
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumMedOdont);
