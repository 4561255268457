import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { consultarCidsPrincipais, selecionarCidPrincipal, setTimeoutConsultaCidPrincipal } from '../CIDs/action';

import BrSelect from '@govbr/react-components/lib/BrSelect';

const CidPrincipal = (props) => {
  
  const OPCAO_VAZIA = { label: '', value: null };

  const {moduloCid, validacao, isSomenteLeitura, config} = props;
  const {listaCidsPrincipais, isLoadingCidPrincipal} = config;

  const obterOpcaoSelectCidPrincipalSelecionado = () => {
    const { cidPrincipal, nomeCidPrincipal } = moduloCid;
    return cidPrincipal
      ? { label: `${cidPrincipal} - ${nomeCidPrincipal}`, value: cidPrincipal }
      : null;
  };

  const obterOpcaoInputCidPrincipal = () => {
    const { inputCidPrincipal } = props.config;

    return inputCidPrincipal
      ? { label: inputCidPrincipal, value: inputCidPrincipal }
      : null;
  };

  const handleInputCidPrincipal = newInputValue => {
    const { consultarCidsPrincipais, setTimeoutConsultaCidPrincipal } = props;
    const { timeoutConsultaCidPrincipal } = props.config;
    const opcaoCidPrincipalSelecionado = obterOpcaoSelectCidPrincipalSelecionado();

    const isInputValidoParaFiltro = newInputValue && newInputValue.length > 2 // 3 ou mais caracteres
      && newInputValue !== OPCAO_VAZIA.label //diferente de opcao com valor nulo
      && (!opcaoCidPrincipalSelecionado || newInputValue !== opcaoCidPrincipalSelecionado.label); //diferente de valor atual cid

    if (isInputValidoParaFiltro) {

      if (timeoutConsultaCidPrincipal) { //cancela consulta alterior existente
        clearTimeout(timeoutConsultaCidPrincipal);
      }

      const novoTimeout = setTimeout(function () {
        consultarCidsPrincipais(newInputValue)
      }, 2000); //esperar 2 segundos de inatividade para iniciar consulta

      setTimeoutConsultaCidPrincipal(novoTimeout, newInputValue);
    }
  };

  const handleChangeCidPrincipal = (newValue) => {
    const { timeoutConsultaCidPrincipal } = props.config;
    const { selecionarCidPrincipal} = props;

    if (timeoutConsultaCidPrincipal) {
      //cancela consulta disparada pela selecao de opcao (evento onInputChange é disparado antes do onChange)
      //TODO: verificar pq timeout consulta nao esta atualizado quando evento eh disparado
      clearTimeout(timeoutConsultaCidPrincipal + 1);
    }

    let cidSelecionado = listaCidsPrincipais ? listaCidsPrincipais.find(c => c.codigo === newValue) : null;
    cidSelecionado = cidSelecionado ? cidSelecionado : OPCAO_VAZIA;

    selecionarCidPrincipal(cidSelecionado, false, false, props.existeModuloIsencaoNoTemplate, false, false, false, undefined);
  };

   /** CID PRINCIPAL */
   const opcaoCidPrincipalSelecionado = obterOpcaoSelectCidPrincipalSelecionado();
   const opcaoInputCidPrincipal = obterOpcaoInputCidPrincipal();
   const opcoesCidPrincipal = listaCidsPrincipais
     ? listaCidsPrincipais.map(t => ({ label: `${t.codigo} - ${t.nome}`, value: t.codigo })) : [];

   opcoesCidPrincipal.unshift(OPCAO_VAZIA);

  return (
      <div id="moduloCidPrincipal" className="conteudoAbaSemHeaderSecao">

        <BrSelect id="cidPrincipal" label="* CID Principal" placeholder="Digite uma CID Principal"
          items={opcoesCidPrincipal}
          value={opcaoInputCidPrincipal
            ? opcaoInputCidPrincipal //seleciona opcao que representa string de consulta
            : (opcaoCidPrincipalSelecionado ? opcaoCidPrincipalSelecionado : OPCAO_VAZIA)}
          onChange={handleChangeCidPrincipal}
          onInputChange={(event, newInputValue) => handleInputCidPrincipal(newInputValue)}
          disabled={isLoadingCidPrincipal || isSomenteLeitura}
          erro={validacao && validacao.erroCidPrincipal} />

      </div>
  );
}

const mapStateToProps = (state) => {
  const { isSomenteLeitura, validacao } = state.principal;
  return ({
    moduloCid: state.pericia.moduloCid,
    validacao: validacao.cidPrincipal,
    config: state.modulos.moduloCids,
    isSomenteLeitura,
    existeModuloIsencaoNoTemplate: state.principal.existeModuloIsencaoNoTemplate
  });

};

const mapDispatchToProps = dispatch => bindActionCreators({
  consultarCidsPrincipais, 
  selecionarCidPrincipal,
  setTimeoutConsultaCidPrincipal
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CidPrincipal);
