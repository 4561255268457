import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal'
import BrButton from '@govbr/react-components/lib/BrButton'

import { ocultarDialogExcluirExame } from './action';

import { excluirExame } from '../../action'

class DialogConfirmarExclusao extends React.Component {

  render() {
    const { ocultarDialogExcluirExame, excluirExame, isConfirmarExclusao, texto, exameLaudo } = this.props;
    return (
      <BrModal id="dialogConfirmarExclusao" visible={isConfirmarExclusao}
        title={`Excluir Exame/Laudo apresentado`} closable={false} medium>

        {texto}

        <BrModalFooter>
          <BrButton secondary className="m-2" onClick={ocultarDialogExcluirExame}>Cancelar</BrButton>
          <BrButton primary className="m-2" onClick={() => excluirExame(exameLaudo)}>Confirmar</BrButton>
        </BrModalFooter>
      </BrModal>
    );
  }
}

const mapStateToProps = (state) => ({
  isConfirmarExclusao: state.modulos.moduloExameLaudo.isConfirmarExclusao
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogExcluirExame,
  excluirExame
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmarExclusao);
