import {
  SIGLA_CAMPO_CID_ALTERADO, SIGLA_CAMPO_GENERICO_PRORROGA, SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE,
  SIGLA_CAMPO_DID, SIGLA_CAMPO_JDID, SIGLA_CAMPO_JUSTIFICATIVA_REVISAO,
  tipoSimNao, tiposPericia
} from '../../constants';


const verificarRegrasCidAlterado = (pericia) => {
  return (pericia?.tipoPericia === tiposPericia.INSS_BI_PMC.tipo &&
    !pericia?.periciaAnterior?.codigoCID) || !pericia.permiteAlteracaoCritica ? true : false;
}

const verificarIndicacaoRestabelecimentoOuAgravamento = (pericia, possuiDIDRestabelecimentoAgravamento) => {
  const valorRestabelecimento = pericia?.moduloRestabelecimentoBeneficio?.existeRestabelecimento;
  const valorAgravamento = pericia?.moduloRestabelecimentoBeneficio?.existeAgravamento;

  const simParaRestabelecimento = valorRestabelecimento && tipoSimNao.SIM.id === parseInt(valorRestabelecimento);
  const simParaAgravamento = valorAgravamento && tipoSimNao.SIM.id === parseInt(valorAgravamento);

  return (simParaRestabelecimento || simParaAgravamento) && possuiDIDRestabelecimentoAgravamento ? true : false;
}

export const verificarRegrasInativacao = (pericia, sigla, possuiDIDRestabelecimentoAgravamento) => {

  switch (sigla) {
    case SIGLA_CAMPO_CID_ALTERADO:
      return verificarRegrasCidAlterado(pericia);
    case SIGLA_CAMPO_DID:
      return verificarIndicacaoRestabelecimentoOuAgravamento(pericia, possuiDIDRestabelecimentoAgravamento);
    case SIGLA_CAMPO_JDID:
      return verificarIndicacaoRestabelecimentoOuAgravamento(pericia, possuiDIDRestabelecimentoAgravamento);
    case SIGLA_CAMPO_JUSTIFICATIVA_REVISAO:
      return true;
    case SIGLA_CAMPO_PERICIA_COMPROVACAO_INCAPACIDADE:
    case SIGLA_CAMPO_GENERICO_PRORROGA:
      return !pericia.permiteAlteracaoCritica;
    default:
  }

  return false;
}