import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import useMask from "@ryuuji3/react-mask-hook";

const BrInput = props => {
    const {
      id,
      label,
      mode,
      value,
      decimalSeparator,
      decimalScale,
      disabled,
      isFilter,
      onChange,
      icon,
      iconHandler,
      iconTitle,
      help,
      sucesso,
      erro,
      mask,
      filter,
      ...rest
    } = props;
  
    const [selectedValue, setSelectedValue] = useState(value);
  
    useEffect(() => {
      setSelectedValue(value);
    }, [value]);
  
    const handleMaskedChange = inputValue => {
      setSelectedValue(inputValue);
      if (onChange) {
        onChange(inputValue);
      }
    };
  
    const handleChange = event => {
      const input = event.target.value;
      setSelectedValue(input);
      if (onChange) {
        onChange(input);
      }
    };
  
    const handleChangeNumber = values => {
      const input = values.value;
      setSelectedValue(input);
      if (onChange) {
        onChange(input);
      }
    };
  
    const isDisabled = disabled;
    const invalidProp = { invalid: "true" };
    const validProp = { valid: "true" };
    const filterProp = { filter: "true" };
  
    const maskProps = useMask({
      value: String(selectedValue) || "",
      onChange: handleMaskedChange,
      mask: mask || "",
      placeholder: "_"
    });
  
    return (
      <>
        <div
          className="br-input"
          {...(erro && invalidProp)}
          {...(sucesso && validProp)}
          {...(isFilter && filterProp)}
        >
          <label htmlFor={id}>{label}</label>
          {mode === "text" && (
            <input
              id={id}
              name={id}
              disabled={isDisabled}
              {...(mask && maskProps)}
              {...(!mask && {
                onChange: handleChange,
                value: filter ? filter(selectedValue) : selectedValue
              })}
              {...rest}
              {...(erro && invalidProp)}
              {...(sucesso && validProp)}
            />
          )}
          {mode === "number" && (
            <NumberFormat
              id={id}
              inputMode="numeric"
              disabled={isDisabled}
              value={selectedValue}
              onValueChange={handleChangeNumber}
              decimalScale={decimalScale}
              decimalSeparator={decimalSeparator}
              format={mask}
              isNumericString
              {...rest}
              {...(erro && invalidProp)}
              {...(sucesso && validProp)}
            />
          )}
          {icon && (
            <button className="icon" title={iconTitle ? iconTitle : ""} type="button" onClick={iconHandler ? iconHandler : null}>
              <span className="sr-only">Mostrar senha</span>
              <i className={`fas ${icon}`} aria-hidden="true" />
            </button>
          )}
        </div>
  
        {erro && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{erro}</span>
          </div>
        )}
  
        {sucesso && (
          <div className="feedback color-success mt-1">
            <i className="fas fa-check-circle" />
            <span>{sucesso}</span>
          </div>
        )}
  
        {help && <p className="help" dangerouslySetInnerHTML={{ __html: help }}/>}
      </>
    );
  };
  
  BrInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    mode: PropTypes.oneOf(["text", "number"]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    decimalScale: PropTypes.number,
    decimalSeparator: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    iconHandler: PropTypes.func,
    help: PropTypes.string,
    erro: PropTypes.string,
    sucesso: PropTypes.string,
    mask: PropTypes.string,
    filter: PropTypes.func
  };
  
  BrInput.defaultProps = {
    label: "",
    mode: "text",
    value: "",
    onChange: null,
    disabled: false,
    decimalScale: 0,
    decimalSeparator: ".",
    icon: "",
    iconHandler: null,
    help: "",
    erro: "",
    sucesso: "",
    mask: null,
    filter: null
  };
  
  export default BrInput;