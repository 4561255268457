import React from "react";
import BrCard from '@govbr/react-components/lib/BrCard';

const PERGUNTA_DURACAO_ALTERACOES_V1 = <BrCard>
  <span>* <b>Considerando as barreiras apontadas na avaliação social e os aspectos clínicos avaliados,
    é possível afirmar que as alterações em Funções e/ou Estruturas do Corpo serão resolvidas em
    menos de 2 anos? </b> (Considerar também o tempo pregresso já vivenciado com tal quadro, as
    possibilidades de acesso ao tratamento necessário e a participação plena e efetiva na sociedade
    em igualdade de condições com as demais pessoas).
  </span>
</BrCard>;

const PERGUNTA_DURACAO_ALTERACOES_V2 = <>
  <BrCard>
    <span>* <b>CONSIDERANDO OS ASPECTOS AVALIADOS, TRATA-SE DE IMPEDIMENTO DE LONGO PRAZO? </b> <br />
      Considera-se impedimento de longo prazo aquele no qual as alterações em Funções e/ou Estruturas do Corpo
      produzam efeitos pelo prazo mínimo de 2 (dois) anos.
    </span>
  </BrCard>
  <BrCard>
    <span>Considerar também o tempo pregresso já vivenciado com tal quadro, as
      possibilidades de acesso ao tratamento necessário e a participação plena e efetiva na sociedade
      em igualdade de condições com as demais pessoas.
    </span>
  </BrCard>
</>;

const DOMINIO_DURACAO_ALTERACAO_V1 = "tiposDuracaoAltConstatada";
const DOMINIO_DURACAO_ALTERACAO_V2 = "tiposImpedimentoLongoPrazo";

export { PERGUNTA_DURACAO_ALTERACOES_V1, PERGUNTA_DURACAO_ALTERACOES_V2, DOMINIO_DURACAO_ALTERACAO_V1, DOMINIO_DURACAO_ALTERACAO_V2 };