import React from 'react';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import { connect } from "react-redux";

const MensagemTelemedicina = (props) => {

  return (
    <>
    {
      props.realizadaPorTelemedicina &&
        <div style={{marginTop: '16px'}}>
          <BrMessage warning>
            Perícia Médica por Telemedicina conforme Lei n° 14.724/2023 e Portaria MPS n° 674/2024
          </BrMessage>
        </div>
    }
    </>
  );
}

const mapStateToProps = (state) => ({
  realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina
});


export default connect(mapStateToProps)(MensagemTelemedicina);
