import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BrButton from "@govbr/react-components/lib/BrButton";
import BrCheckbox from "@govbr/react-components/lib/BrCheckbox";
import BrTable from "@govbr/react-components/lib/BrTable";

import { Grid } from "@material-ui/core";

import { alterarNaoExisteExameLaudo } from "../../action";
import {
  exibirDialogAlterarExame,
  exibirDialogDetalharExame,
  exibirDialogExcluirExame,
  exibirDialogIncluirExame
} from "./action";

import {
  obterNumeroComUf,
  obterTipoExameLaudo,
  obterTipoIdentificacao
} from "./utils";

import DialogConfirmarExclusao from "./DialogConfirmarExclusao";
import ModalExameLaudo from "./DialogDadosExameLaudo";

import { formatarDateTimeISOToDataBr } from "../../../../common/DataUtils";

class ExamesELaudos extends React.Component {
  render() {
    const {
      naoExisteExameLaudo,
      examesLaudo,
      exameLaudoSelecionado,
      validacao,
      isFormVisible,
      isSomenteLeitura,
      realizadaPorTelemedicina
    } = this.props;
    const {
      alterarNaoExisteExameLaudo,
      exibirDialogIncluirExame,
      exibirDialogDetalharExame,
      exibirDialogAlterarExame,
      exibirDialogExcluirExame
    } = this.props;

    return (
      <div id="abaExamesELaudos" className="conteudoAbaSemHeaderSecao">
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item sm={3}>
            <BrButton
              onClick={exibirDialogIncluirExame}
              disabled={
                isFormVisible || naoExisteExameLaudo === 1 || isSomenteLeitura
              }
            >
              <i className={`fas fa-plus`} />
              Incluir Exame
            </BrButton>
          </Grid>
          <Grid item sm={9}>
            <BrCheckbox
              id="naoApresentouExamesELaudos"
              items={[
                {
                  label: `* Não apresentou exames e laudos ${realizadaPorTelemedicina ? "ou os exames/laudos estão anexados ao processo administrativo" : ""}`,
                  value: "naoExisteExameLaudo"
                }
              ]}
              values={naoExisteExameLaudo === 1 ? ["naoExisteExameLaudo"] : []}
              onChange={(item, checked) => {
                checked
                  ? alterarNaoExisteExameLaudo(1)
                  : alterarNaoExisteExameLaudo(0);
              }}
              erro={validacao && validacao.erroNaoApresentouExames}
              disabled={
                isSomenteLeitura || (examesLaudo && examesLaudo.length > 0)
              }
            />
          </Grid>
        </Grid>

        {examesLaudo && examesLaudo.length > 0 && (
          <BrTable
            id="tabelaExamesLaudos"
            layout="fixed"
            columns={[
              { Header: "Data do documento", accessor: "data" },
              {
                Header: "Tipo",
                accessor: "tipoExameLaudo",
                alignItems: "flex-end"
              },
              {
                Header: "Tipo de Identificação",
                accessor: "tipoIdentificacao"
              },
              { Header: "Número", accessor: "numero" },
              { Header: "Ações", accessor: "acao" }
            ]}
            data={examesLaudo.map((item) => ({
              data: formatarDateTimeISOToDataBr(item.dataDocumento),
              tipoExameLaudo: obterTipoExameLaudo(
                item.idTipoExameLaudo,
                this.props.tiposExameLaudo
              ),
              tipoIdentificacao: obterTipoIdentificacao(
                item.idTipoIdentEmitente,
                this.props.tiposIdentificacaoEmitente
              ),
              numero: obterNumeroComUf(item, this.props.ufs),
              acao: (
                <>
                  <BrButton
                    id="detalharExameLaudo"
                    circle
                    onClick={() => exibirDialogDetalharExame(item)}
                    disabled={isFormVisible}
                  >
                    <i className={`fas fa-info`} title="Detalhar" />
                  </BrButton>
                  <BrButton
                    id="alterarExameLaudo"
                    circle
                    onClick={() => exibirDialogAlterarExame(item)}
                    disabled={isFormVisible || isSomenteLeitura}
                  >
                    <i className={`fas fa-pen`} title="Alterar" />
                  </BrButton>
                  <BrButton
                    id="excluirExameLaudo"
                    circle
                    onClick={() => exibirDialogExcluirExame(item)}
                    disabled={isFormVisible || isSomenteLeitura}
                  >
                    <i className={`fas fa-trash-alt`} title="Excluir" />
                  </BrButton>
                </>
              )
            }))}
            headerProps={{
              show: true,
              showDensityOptions: false,
              searchProp: {
                show: false
              }
            }}
            footerProps={{ show: false }}
          />
        )}

        {isFormVisible && <ModalExameLaudo />}

        <DialogConfirmarExclusao
          exameLaudo={exameLaudoSelecionado}
          texto={`Confirma exclusao do exame/laudo selecionado ?`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { naoExisteExameLaudo, examesLaudo } = state.pericia.moduloExameLaudo;
  const { exameLaudoSelecionado, isFormVisible } =
    state.modulos.moduloExameLaudo;
  const { tiposTituloExame, tiposIdentificacaoEmitente, tiposExameLaudo } =
    state.principal.dominioPericia;

  return {
    naoExisteExameLaudo,
    examesLaudo,
    exameLaudoSelecionado,
    isFormVisible,
    tiposTituloExame,
    tiposIdentificacaoEmitente,
    tiposExameLaudo,
    ufs: state.principal.dominioSdc.ufs,
    validacao: state.principal.validacao.examesLaudos,
    isSomenteLeitura: state.principal.isSomenteLeitura,
    realizadaPorTelemedicina: state.pericia.realizadaPorTelemedicina
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alterarNaoExisteExameLaudo,
      exibirDialogIncluirExame,
      exibirDialogDetalharExame,
      exibirDialogAlterarExame,
      exibirDialogExcluirExame
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExamesELaudos);
