import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrButton from '@govbr/react-components/lib/BrButton';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import { ocultarDialogAlterarItem, alterarItem, alterarTituloItemAlteracao, alterarConteudoItemAlteracao } from './action';

import { MSG_CAMPOS_OBRIGATORIO } from '../../common/Messages';
 import { calcularCaracteresRestantes } from '../../common/Utils';

const DialogAlteracao = (props) => {
  const QTD_MAXIMA_CARACTERES = 4000;

  const { ocultarDialogAlterarItem, alterarItem, dados, isGestor} = props;
  const { isAlteracao, itemSelecionado} = dados;

  const caracteresRestantes = calcularCaracteresRestantes(itemSelecionado?.valorCampo, QTD_MAXIMA_CARACTERES);
  const validacao = itemSelecionado?.validacao;

  return (
    <BrModal id="dialogAlteracaoItemDicionario" visible={isAlteracao}
      title={"Alterar item do dicionário"} closable={false} style={{ width: "50%", maxHeight: '80%', overflowY: 'scroll' }}>

      <BrInput id="tituloItemDicionario" label="* Título"
        value={itemSelecionado?.chaveCampo}
        onChange={(value) =>  props.alterarTituloItemAlteracao(value)}
        erro={validacao && validacao.erroTitulo}
        maxLength={100} />

      <BrTextarea id="conteudoItemDicionario" label="* Conteúdo"
        value={itemSelecionado?.valorCampo}
        onChange={(value) => props.alterarConteudoItemAlteracao(value)}
        maxLength={QTD_MAXIMA_CARACTERES}
        help={`Caracteres restantes: ${caracteresRestantes}`}
        erro={validacao && validacao.erroConteudo} 
        />

      <p>{MSG_CAMPOS_OBRIGATORIO}</p>

      <BrModalFooter>
        <BrButton secondary className="m-2" onClick={ocultarDialogAlterarItem}>Cancelar</BrButton>
        <BrButton primary className="m-2" onClick={() => alterarItem(itemSelecionado, isGestor)}>Alterar</BrButton>
      </BrModalFooter>
    </BrModal>
  );
}

const mapStateToProps = (state) => ({
  dados: state.dicionario
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ocultarDialogAlterarItem,
  alterarItem,
  alterarTituloItemAlteracao,
  alterarConteudoItemAlteracao
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogAlteracao);
