import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrInput from '@govbr/react-components/lib/BrInput';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrModal, { BrModalFooter } from '@govbr/react-components/lib/BrModal';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';

import { Grid } from "@material-ui/core";

import { MASCARA_CEI, MASCARA_CNPJ } from '../../../../common/formatadores';
import { calcularCaracteresRestantes } from '../../../../common/Utils';
import { IDS_TIPOS_EMITENTE_COM_UF, ID_TIPO_EMITENTE_CEI, ID_TIPO_EMITENTE_CNPJ, ID_TIPO_EXAME_EXAME } from './validacao';

import {
  alterarDataDocumento, alterarDescricaoLaudo, alterarNomeProfissional, alterarNumeroDocumento, alterarOutroTipoEmitente, alterarTipoEmitente, alterarTipoExameLaudo, alterarUf, ocultarDialogExameLaudo
} from './action';

import { MSG_CAMPOS_OBRIGATORIO } from '../../../../common/Messages';
import { alterarExame, incluirExame } from '../../action';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

class IncluirExameLaudo extends React.Component {
  QTD_MAXIMA_CARACTERES = 4000;

  OPCAO_VAZIA = { label: '', value: null };

  render() {
    const { alterarTipoEmitente, alterarOutroTipoEmitente, alterarNumeroDocumento, alterarDataDocumento, alterarUf,
      alterarNomeProfissional, alterarDescricaoLaudo, alterarTipoExameLaudo,
      incluirExame, alterarExame, ocultarDialogExameLaudo, ufs } = this.props;

    const { tiposIdentificacaoEmitente } = this.props.dominioPericia;
    const { tiposExameLaudoUsadosEmTela } = this.props.dominioPericia;
    const { isFormVisible, isFormDisabled, isEdit, validacao, exameLaudoSelecionado } = this.props.config;
    const { mensagem, tipoMensagem } = this.props.principal;

    const { dataDocumento, idTipoIdentEmitente, outroTipoIdentEmitente, numeroDocumento, idUFDocumento, nomeProfissional,
      descricaoLaudo, idTipoExameLaudo } = exameLaudoSelecionado;

    //preenchendo combos de selecao com lista de opcoes    

    /** TIPOS EXAME LAUDO */
    const tiposExameLaudoOrdemAlfabetica = tiposExameLaudoUsadosEmTela
      ? tiposExameLaudoUsadosEmTela.sort((a, b) => a.descricao.localeCompare(b.descricao)).map(t => ({ label: t.descricao, value: t.id }))
      : [];
    tiposExameLaudoOrdemAlfabetica.unshift(this.OPCAO_VAZIA);


    /** UF */
    const ufsOrdemAlfabetica = ufs ? ufs.sort((a, b) => a.siglaUf.localeCompare(b.siglaUf)).map(t => ({ label: t.siglaUf, value: t.codigoUfIbge })) : [];
    ufsOrdemAlfabetica.unshift(this.OPCAO_VAZIA);

    const opcoesIdentificacaoEmitente = tiposIdentificacaoEmitente ? tiposIdentificacaoEmitente.map(t => ({ label: t.descricao, value: t.id })) : [];
    opcoesIdentificacaoEmitente.unshift(this.OPCAO_VAZIA);

    //variaveis auxiliares de tela
    const tipoDocumentoSelecionado = tiposIdentificacaoEmitente && idTipoIdentEmitente
      ? tiposIdentificacaoEmitente.find(t => t.id === idTipoIdentEmitente).descricao
      : "Documento";

    const exibeCampoUf = IDS_TIPOS_EMITENTE_COM_UF.find(i => i === idTipoIdentEmitente);
    const tipoEmitenteDocumento = (idTipoIdentEmitente != null && idTipoIdentEmitente > 0)
      ? (exibeCampoUf ? " Profissional" : " Pessoa Jurídica")
      : '';
    const acao = isEdit ? 'Alterar' : (isFormDisabled ? 'Detalhar' : 'Incluir');

    const isTipoEmitenteCNPJ = idTipoIdentEmitente && idTipoIdentEmitente === ID_TIPO_EMITENTE_CNPJ;
    const isTipoEmitenteCEI = idTipoIdentEmitente && idTipoIdentEmitente === ID_TIPO_EMITENTE_CEI;
    const mascaraNumeroDocumento = isTipoEmitenteCNPJ
      ? MASCARA_CNPJ
      : (isTipoEmitenteCEI ? MASCARA_CEI : null);

    const caracteresRestantes = calcularCaracteresRestantes(descricaoLaudo, this.QTD_MAXIMA_CARACTERES);

    const isTipoExameExame = idTipoExameLaudo && idTipoExameLaudo === ID_TIPO_EXAME_EXAME;
    const isCampoTextareaDesabilitado = isFormDisabled;

    return (
      <BrModal id="dialogIncluirExameLaudo" visible={isFormVisible} style={{ width: "90%", maxHeight: '90%', overflowY: 'scroll' }}
        title={`${acao} Exame/Laudo apresentado`} closable={isFormDisabled} onClose={ocultarDialogExameLaudo}>
        {
          mensagem && tipoMensagem.error
          &&
          (<BrMessage error>
            <p>{mensagem}</p>
          </BrMessage>
          )
        }
        <Grid container spacing={1}>
          <Grid item sm={2}>
            <BrDatepicker id="dataDocumento" label="* Data do documento"
              date={dataDocumento ? dataDocumento : null} onChange={alterarDataDocumento}
              disableFuture
              allowInput
              disabled={isFormDisabled}
              erro={validacao && validacao.erroDataDocumento} />
          </Grid>

          <Grid item sm={2}>
            <BrSelect id="tipoExameLaudo" label="* Tipo"
              items={tiposExameLaudoOrdemAlfabetica}
              value={idTipoExameLaudo !== null && idTipoExameLaudo >= 0 && tiposExameLaudoOrdemAlfabetica
                ? tiposExameLaudoOrdemAlfabetica.find(t => t.value === idTipoExameLaudo)
                : this.OPCAO_VAZIA}
              onChange={(value) => alterarTipoExameLaudo(value, value === ID_TIPO_EXAME_EXAME)}
              disabled={isFormDisabled}
              erro={validacao && validacao.erroTipoExame} />
          </Grid>
          {
            !isTipoExameExame &&
              <Grid item sm={3}>
                <BrSelect id="emitente_exame_laudo" label="* Tipo de Ident. do Emitente"
                  items={opcoesIdentificacaoEmitente}
                  value={idTipoIdentEmitente !== null && idTipoIdentEmitente >= 0 && opcoesIdentificacaoEmitente
                    ? opcoesIdentificacaoEmitente.find(o => o.value === idTipoIdentEmitente)
                    : this.OPCAO_VAZIA}
                  onChange={alterarTipoEmitente}
                  disabled={isFormDisabled || isTipoExameExame}
                  erro={validacao && validacao.erroTipoEmitente} />
              </Grid>
          }
          {
            idTipoIdentEmitente === 0 &&
            <Grid item sm={2}>
              <BrInput id="outroDocumento" label={`* Outro Tipo Ident. Emitente`}
                value={outroTipoIdentEmitente}
                onChange={alterarOutroTipoEmitente}
                disabled={isFormDisabled || isTipoExameExame}
                erro={validacao && validacao.erroOutroTipoIdentEmitente}
                maxLength={100} />
            </Grid>
          }
          {
            !isTipoExameExame &&
              <Grid item sm={3}>
                <BrInput id="numeroDocumento" label={`* ${tipoDocumentoSelecionado} ${tipoEmitenteDocumento}`} mode="number"
                  value={numeroDocumento}
                  onChange={alterarNumeroDocumento}
                  disabled={isFormDisabled || isTipoExameExame}
                  mask={mascaraNumeroDocumento}
                  erro={validacao && validacao.erroNumeroDocumento}
                  maxLength={mascaraNumeroDocumento ? 100 : 15} />
              </Grid>
          }
          {
            exibeCampoUf
            &&
            <Grid item sm={2}>
              <BrSelect id="uf_exame_laudo" label="* UF"
                items={ufsOrdemAlfabetica}
                value={idUFDocumento && ufsOrdemAlfabetica
                  ? ufsOrdemAlfabetica.find(u => u.value === idUFDocumento)
                  : this.OPCAO_VAZIA}
                onChange={alterarUf}
                disabled={isFormDisabled || isTipoExameExame}
                erro={validacao && validacao.erroUf} />
            </Grid>
          }
          {
            !isTipoExameExame &&
              <Grid item sm={12}>
              <BrInput id="nomeProfissional" label={`* Nome  ${tipoEmitenteDocumento}`}
                value={nomeProfissional}
                onChange={alterarNomeProfissional}
                disabled={isFormDisabled || isTipoExameExame}
                erro={validacao && validacao.erroNomeProfissional}
                maxLength={200} />
            </Grid>
          }
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea id="descricaoLaudo" label="* Exame / Laudo"
                  onChange={alterarDescricaoLaudo}
                  value={descricaoLaudo}
                  maxLength={this.QTD_MAXIMA_CARACTERES}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  erro={validacao && validacao.erroDescricaoLaudo}
                  disabled={isCampoTextareaDesabilitado} />
               </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioDescricaoExameLaudo"
                    onSelect={alterarDescricaoLaudo}
                    maxLength={caracteresRestantes}
                    valorAtual={descricaoLaudo}/>
                </Grid>
              }
          </Grid>

          </Grid>
        </Grid>
        <p>{MSG_CAMPOS_OBRIGATORIO}</p>
        {
          !isFormDisabled &&
          <BrModalFooter>
            <BrButton secondary className="m-2" onClick={ocultarDialogExameLaudo}>Cancelar</BrButton>
            <BrButton primary className="m-2"
              onClick={() => isEdit ? alterarExame(exameLaudoSelecionado) : incluirExame(exameLaudoSelecionado)}>
              Confirmar
            </BrButton>
          </BrModalFooter>
        }
      </BrModal >
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.modulos.moduloExameLaudo,
  dominioPericia: state.principal.dominioPericia,
  ufs: state.principal.dominioSdc.ufs,
  principal: state.principal
});

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarDataDocumento,
  alterarTipoEmitente,
  alterarOutroTipoEmitente,
  alterarUf,
  alterarNumeroDocumento,
  alterarNomeProfissional,
  alterarDescricaoLaudo,
  alterarTipoExameLaudo,
  incluirExame,
  alterarExame,
  ocultarDialogExameLaudo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IncluirExameLaudo);
