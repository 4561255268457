import {
  createStore, applyMiddleware, compose
} from 'redux';

import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import ReduxMulti from 'redux-multi';

import reducers from './rootReducer'

// const composeMiddlewares = compose(applyMiddleware(ReduxPromise, ReduxThunk, ReduxMulti),
//  window.devToolsExtension ? window.devToolsExtension() : f => f);

// const createStoreWithMiddleware = composeMiddlewares(createStore);

// const store = createStoreWithMiddleware(reducers);

const devTools = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = compose(applyMiddleware(ReduxThunk, ReduxPromise, ReduxMulti))(createStore)(reducers,devTools ? devTools : f => f);

export default store;