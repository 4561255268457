import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const BrTextarea = props => {
  
  const {
    id,
    label,
    value,
    disabled,
    onChange,
    help,
    sucesso,
    erro,
    ...rest
  } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  const textareaRef = React.useRef(null);

  useEffect(() => { 
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 2 + "px";
    setSelectedValue(value); 
  }, [value]);

  const handleChange = event => {
    const input = event.target.value;

    setSelectedValue(input);
    if (onChange) {
      onChange(input);
    }
  };

  const isDisabled = disabled;
  const invalidProp = { invalid: "true" };
  const validProp = { valid: "true" };

  return (
    <>
      <div className="br-textarea" {...erro && invalidProp} {...sucesso && validProp}>
        {label && (
          <label htmlFor={id}>
            {label}
          </label>
        )}
        <textarea
          id={id}
          ref={textareaRef}
          rows={5}
          cols={5}
          disabled={isDisabled}
          onChange={handleChange}
          value={selectedValue}
          {...rest}
          {...erro && invalidProp}
          {...sucesso && validProp}
        />
      </div>

      {erro
        && (
          <div className="feedback color-danger mt-1">
            <i className="fas fa-times-circle" />
            <span>{erro}</span>
          </div>
        )}

      {sucesso
        && (
          <div className="feedback color-success mt-1">
            <i className="fas fa-check-circle" />
            <span>{sucesso}</span>
          </div>
        )}

      {help
        && (<p className="help">{help}</p>
        )}
    </>
  );
};

BrTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  help: PropTypes.string,
  erro: PropTypes.string,
  sucesso: PropTypes.string
};

BrTextarea.defaultProps = {
  label: '',
  value: '',
  onChange: null,
  disabled: false,
  help: '',
  erro: '',
  sucesso: ''
};

export default BrTextarea;
