import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrDatepicker from '@govbr/react-components/lib/BrDatepicker';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import BrRadio from '@govbr/react-components/lib/BrRadio';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTextarea from '@govbr/react-components/lib/BrTextarea';
import { Grid } from '@material-ui/core';
import { adicionarDiasNaData, converterDataFormatadaParaISOBr } from '../../../../common/DataUtils';
import { calcularCaracteresRestantes } from '../../../../common/Utils';

import { atualizarDadoValidacaoModulo } from '../../../principal/action';
import {
  alterarDataComprovIncapac, alterarDCBGrauIncapacidade, alterarEnquadramentoAuxilioAcidente, alterarIsencaoIRGrauIncapacidade,
  alterarJustAuxAcidGrauIncapacidade, alterarJustDCIGrauIncapacidade, alterarJustLIGrauIncapacidade, alterarMajoracaoGrauIncapacidade,
  alterarSituacaoEnquadramentoAuxilioAcidente, alterarTipoGrauIncapacidade, alterarTipoIsencaoImpostoRenda, alterarTipoMajoracao25
} from '../../action';

import { modulosEstaticos, tipoSimNao, tiposPericia } from '../../constants';
import { TIPOS_GRAU_INCAPACIDADE } from './utils';
import { obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE, obtemSituacaoBeneficioAnteriorNaoIndicaDCA, obterErroValidacaoDataMaximaRetroacaoDCB } from './validacao';

import ButtonSelecionarItem from '../../../dicionario/ButtonSelecionarItem';

const GrauIncapacidade = props => {

  const CAMPO_GENERICO_HOUVE_ALTERACAO_CID = "DCIDALT";
  const CAMPO_GENERICO_HOUVE_ALTERACAO_CID_RESP_SIM = "1";

  const nomeModulo = modulosEstaticos.GRAU_INCAPACIDADE.nome;
  const { pericia, alterarDCBGrauIncapacidade, template, atualizarDadoValidacaoModulo, dados, listaSitEnquadAuxAcid, alterarSituacaoEnquadramentoAuxilioAcidente, tipoPericiaOrigemRevisao } = props;

  const tipoGrauIncapacidade = props.dados.tipoGrauIncapacidade;
  const tiposGrauIncapacidade = props.tiposGrauIncapacidade;
  const tipoPericiaTemplate = template.templateComposto === tipoSimNao.SIM.descricao ? tipoPericiaOrigemRevisao : template.tipoPericia;
  const alterarTipoGrauIncapacidade = props.alterarTipoGrauIncapacidade;
  const valorCampoGenericoAlteracaoCid = props.camposGenericos
    .find(campo => campo.siglaCampoGenerico.includes(CAMPO_GENERICO_HOUVE_ALTERACAO_CID));
  const isEhPericiaIncial = tiposPericia.INSS_BI_PI.tipo === tipoPericiaTemplate;
  const isBenefAnteriorIndicaDCAIgualOuMaiorDRE = obtemSituacaoBeneficioAnteriorIndicaDCAEIgualMaiorDRE(pericia);

  const dcbMenorQueDRESemSelecaoIndicAuxAcid = props.dados.dataCessacaoBeneficio
    && new Date(props.dados.dataCessacaoBeneficio) <= new Date()
    && tiposGrauIncapacidade[0].id === tipoGrauIncapacidade;

  useEffect(() => {
    const isHouveAlteracaoCid = valorCampoGenericoAlteracaoCid?.valorTextoCampoGenerico === CAMPO_GENERICO_HOUVE_ALTERACAO_CID_RESP_SIM;
    const tipoGrauSelecionadoPreviamente = tiposGrauIncapacidade.find(tipoGrau => tipoGrau.id === tipoGrauIncapacidade);
    if (tipoGrauSelecionadoPreviamente) {
      const tipoPericiaPossuiTipoGrau = tipoGrauSelecionadoPreviamente.tiposPericia.find(tipo => tipo === tipoPericiaTemplate)
      if (!tipoPericiaPossuiTipoGrau && !isHouveAlteracaoCid) {
        alterarTipoGrauIncapacidade(null);
      }
    }

    const { enquadramentoAuxilioAcidente, situacaoEnquadramentoAuxilioAcidente } = dados;
    const listaSituacoesEnquadramentoSelecionado = listaSitEnquadAuxAcid
      .filter((tipo) => +tipo.tipoEnquadramento === enquadramentoAuxilioAcidente);

    //selecao automatica se enquadramento de AA quando existe apenas uma opcao
    if (enquadramentoAuxilioAcidente && !situacaoEnquadramentoAuxilioAcidente && listaSituacoesEnquadramentoSelecionado?.length === 1) {
      alterarSituacaoEnquadramentoAuxilioAcidente(listaSituacoesEnquadramentoSelecionado[0].id);
    }
  }, [valorCampoGenericoAlteracaoCid, tipoGrauIncapacidade, tiposGrauIncapacidade, tipoPericiaTemplate, alterarTipoGrauIncapacidade,
    dados, listaSitEnquadAuxAcid, alterarSituacaoEnquadramentoAuxilioAcidente]);

  const opcoesGrauIncapacidade = (() => {

    const isHouveAlteracaoCid = valorCampoGenericoAlteracaoCid?.valorTextoCampoGenerico === CAMPO_GENERICO_HOUVE_ALTERACAO_CID_RESP_SIM;

    const tipoPericiaTemplate = props.template.templateComposto === tipoSimNao.SIM.descricao ? props.tipoPericiaOrigemRevisao : props.template.tipoPericia;

    const isOpcaoVazia = (tipo) => !tipo.descricao;

    const opcoesGrauIncapacidade = [{ descricao: '', id: null, ordem: 0 }, ...props.tiposGrauIncapacidade]
      .filter(tipo =>
        isOpcaoVazia(tipo)
        || (isEhPericiaIncial
          || isHouveAlteracaoCid
          || (tipo.tiposPericia
            && tipo.tiposPericia.find(tipoPericia => tipoPericia.includes(tipoPericiaTemplate))
          )))
      .sort((a, b) => {
        if (a.ordem < b.ordem) return -1;
        if (a.ordem > b.ordem) return 1;
        return 0;
      })
      .map(tipo => ({ label: tipo.descricao, value: tipo.id }));

    return opcoesGrauIncapacidade;
  })();

  const handleChangeDCBComRecuperacao = value => {
    let msgAlertaDCB = undefined;

    if (value && value.toString() !== 'Invalid Date' && template) {
      msgAlertaDCB = obterErroValidacaoDataMaximaRetroacaoDCB(value, template);
    }
    alterarDCBGrauIncapacidade(value);

    atualizarDadoValidacaoModulo(nomeModulo, { alertaDcbTempComRecupe: msgAlertaDCB });
  }

  const handleChangeDCBParcialComAA = value => {
    let msgAlertaDCB = undefined;

    if (value && value.toString() !== 'Invalid Date' && template) {
      msgAlertaDCB = obterErroValidacaoDataMaximaRetroacaoDCB(value, template);
    }
    alterarDCBGrauIncapacidade(value);

    atualizarDadoValidacaoModulo(nomeModulo, { alertaDcbParcialIndicAuxAcid: msgAlertaDCB });
  }

  const handleChangeGrauIncapacidade = value => {
    if (value) {
      //deve atualizar mensagem alerta, se houver
      const dcb = converterDataFormatadaParaISOBr(props.dados.dataCessacaoBeneficio);
      const msgAlertaDCB = dcb ? obterErroValidacaoDataMaximaRetroacaoDCB(dcb, template) : undefined;

      if (value === TIPOS_GRAU_INCAPACIDADE.TEMPORARIA_COM_RECUPERACAO) {
        atualizarDadoValidacaoModulo(nomeModulo, { alertaDcbTempComRecupe: msgAlertaDCB, alertaDcbParcialIndicAuxAcid: undefined });
      } else if (value === TIPOS_GRAU_INCAPACIDADE.PARCIAL_COM_INDICACAO_AUXILIO_ACIDENTE) {
        atualizarDadoValidacaoModulo(nomeModulo, { alertaDcbTempComRecupe: undefined, alertaDcbParcialIndicAuxAcid: msgAlertaDCB });
      }
    }

    props.alterarTipoGrauIncapacidade(value);
  }

  return (
    <>
      {
        dcbMenorQueDRESemSelecaoIndicAuxAcid &&
        (
          <BrMessage warning>
            Avaliar a indicação de Auxílio Acidente.<br />
            {isEhPericiaIncial 
              ? 'Em caso positivo, deve-se alterar a opção para "Parcial e permanente com indicação de Auxílio-acidente".'
              : 'Em caso positivo, deve-se alterar a opção para "Não", referente a comprovação da incapacidade, e selecionar "Sim" na opção "Indicação de Auxílio Acidente - AA".'}
          </BrMessage>
        )
      }
      <Grid id="moduloGrauIncapacidade" container spacing={2}>
        <Grid item sm={12} style={{ paddingBottom: '1rem' }}>
          <BrSelect
            id="tpGrauIncapacidade"
            label="* Selecione a classificação da incapacidade:"
            items={opcoesGrauIncapacidade}
            value={opcoesGrauIncapacidade.find(tipo => props.dados.tipoGrauIncapacidade && tipo.value === props.dados.tipoGrauIncapacidade) || opcoesGrauIncapacidade[0]}
            onChange={handleChangeGrauIncapacidade}
            erro={props.validacao?.erroTipoGrauIncapacidade}
            disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <ComponentesTempComRecup {...props} handleChange={handleChangeDCBComRecuperacao} 
          isBenefAnteriorIndicaDCAIgualOuMaiorDRE={isBenefAnteriorIndicaDCAIgualOuMaiorDRE} />
        <ComponentesParcialIndicAuxAcid {...props} handleChange={handleChangeDCBParcialComAA} />
        <ComponentesTotalReabProf {...props} 
          isBenefAnteriorIndicaDCAIgualOuMaiorDRE={isBenefAnteriorIndicaDCAIgualOuMaiorDRE} />
        <ComponentesDefinitivaSemReabProf {...props} />
      </Grid>
    </>
  );
}

const ComponentesTempComRecup = props => {

  const { isBenefAnteriorIndicaDCAIgualOuMaiorDRE } = props

  return (
    <>
      {
        props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE.TEMPORARIA_COM_RECUPERACAO &&
        <Grid item sm={6}>
          {props.validacao?.alertaDcbTempComRecupe
            && (
              <BrMessage warning>
                {props.validacao?.alertaDcbTempComRecupe}
              </BrMessage>
            )}
          <BrDatepicker
            id='dataCessacaoBenefTempRecup'
            label="* Data de Cessação do Benefício (DCB)"
            date={props.dados.dataCessacaoBeneficio || null}
            onChange={props.handleChange}
            allowInput
            minDate={isBenefAnteriorIndicaDCAIgualOuMaiorDRE ? adicionarDiasNaData(new Date(), 0) : undefined}
            erro={props.validacao?.erroDcbTempComRecupe}
            disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
        </Grid>
      }
    </>
  );
}

const ComponentesParcialIndicAuxAcid = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justParcialComIndicAuxilioAcidente;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);

  const OPCAO_VAZIA = { label: '', value: undefined };
  const listaEnquadAuxAcid = [OPCAO_VAZIA, ...props.listaEnquadAuxAcid].map(tipo => ({ label: tipo.tipo, value: tipo.id }));
  const listaSitEnquadAuxAcid = [
    OPCAO_VAZIA,
    ...(props.listaSitEnquadAuxAcid.filter(tipo => +tipo.tipoEnquadramento === props.dados.enquadramentoAuxilioAcidente))
  ].map(tipo => ({ label: tipo.situacao, value: tipo.id }));

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || !props.permiteAlteracaoCritica;

  return (
    <>
      {
        props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE.PARCIAL_COM_INDICACAO_AUXILIO_ACIDENTE &&
        <>
          <Grid item sm={6}>
            {props.validacao?.alertaDcbParcialIndicAuxAcid
              && (
                <BrMessage warning>
                  {props.validacao?.alertaDcbParcialIndicAuxAcid}
                </BrMessage>
              )}
            <BrDatepicker
              id='dataCessacaoBenefTempRecup'
              label="* Data de Cessação do Benefício (DCB)"
              date={props.dados.dataCessacaoBeneficio || null}
              onChange={props.handleChange}
              maxDate={adicionarDiasNaData(new Date(), 0)}
              initialFocusedDate={adicionarDiasNaData(new Date(), 0)}
              allowInput
              erro={props.validacao?.erroDcbParcialIndicAuxAcid}
              disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={12}>
            <BrSelect
              id="tpEnquadAuxAcid"
              label="* Enquadramento de Auxílio Acidente - AA"
              items={listaEnquadAuxAcid}
              value={props.dados.enquadramentoAuxilioAcidente && props.listaEnquadAuxAcid?.length > 0 ? listaEnquadAuxAcid.find(tipo => tipo.value === props.dados.enquadramentoAuxilioAcidente) : OPCAO_VAZIA}
              onChange={props.alterarEnquadramentoAuxilioAcidente}
              erro={props.validacao?.erroEnquadramentoAuxilioAcidente}
              disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={12}>
            <BrSelect
              id="tpSitEnquadAuxAcid"
              label="* Situação"
              items={listaSitEnquadAuxAcid}
              value={props.dados.situacaoEnquadramentoAuxilioAcidente && props.listaSitEnquadAuxAcid?.length > 0 ? listaSitEnquadAuxAcid.find(tipo => tipo.value === props.dados.situacaoEnquadramentoAuxilioAcidente) : OPCAO_VAZIA}
              onChange={props.alterarSituacaoEnquadramentoAuxilioAcidente}
              erro={props.validacao?.erroSituacaoEnquadramentoAuxilioAcidente}
              disabled={props.isSomenteLeitura || !props.dados.enquadramentoAuxilioAcidente || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea
                  id="justifParcialIndicAuxAcid"
                  label="* Justifique"
                  value={justificativa}
                  onChange={props.alterarJustAuxAcidGrauIncapacidade}
                  maxLength={QT_MAX_CARACT}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  disabled={isCampoTextareaDesabilitado}
                  erro={props.validacao?.erroJustParcialComIndicAuxilioAcidente} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustAuxAcidente"
                    onSelect={props.alterarJustAuxAcidGrauIncapacidade} 
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

const ComponentesTotalReabProf = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justTotalComIndicReabProfissional;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || !props.permiteAlteracaoCritica;
  const { isBenefAnteriorIndicaDCAIgualOuMaiorDRE } = props
  const isBenefAnteriorNaoIndicaDCA = obtemSituacaoBeneficioAnteriorNaoIndicaDCA(props.pericia);

  return (
    <>
      {
        props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE.TOTAL_COM_INDICACAO_REABILITACAO_PROFISSIONAL &&
        <>
          <Grid container spacing={1} item sm={12} alignItems="flex-end">
            <Grid item sm={6}>
              <BrDatepicker
                id='dataComprovIncapac'
                label="* Data Limite da Elegibilidade:"
                date={props.dados.dataComprovacaoIncapacidade || null}
                onChange={props.alterarDataComprovIncapac}
                minDate={isBenefAnteriorIndicaDCAIgualOuMaiorDRE || isBenefAnteriorNaoIndicaDCA ? adicionarDiasNaData(new Date(), 0) : undefined}
                initialFocusedDate={adicionarDiasNaData(new Date(), 0)}
                allowInput
                erro={props.validacao?.erroDataComprovacaoIncapacidade}
                disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
            </Grid>
            <Grid item sm={1}>
              <BrButton
                id="btnAdicionar01Ano"
                primary
                title='DCI para um ano'
                onClick={() => props.alterarDataComprovIncapac(adicionarDiasNaData(new Date(), 365))}
                disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica}>
                Adicionar 01 Ano
              </BrButton>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea
                  id="justifTotalReabProf"
                  label="* Justifique"
                  value={justificativa}
                  onChange={props.alterarJustDCIGrauIncapacidade}
                  maxLength={QT_MAX_CARACT}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  erro={props.validacao?.erroJustTotalComIndicReabProfissional}
                  disabled={isCampoTextareaDesabilitado} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustReabProfissional"
                    onSelect={props.alterarJustDCIGrauIncapacidade} 
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

const ComponentesDefinitivaSemReabProf = props => {

  const QT_MAX_CARACT = 4000;
  const justificativa = props.dados.justTotalSemIndicReabProfissional;
  const caracteresRestantes = calcularCaracteresRestantes(justificativa, QT_MAX_CARACT);

  const TIPO_SIM = 1;

  const OPCAO_VAZIA = { label: '', value: undefined };
  const listaMotivosIsencaoIr = [OPCAO_VAZIA, ...props.listaMotivosIsencaoIr].map(tipo => ({ label: tipo.doenca, value: tipo.id }));
  const listaMotivosMajoracao25 = [OPCAO_VAZIA, ...props.listaMotivosMajoracao25].map(tipo => ({ label: tipo.motivo, value: tipo.id }));

  const isCampoTextareaDesabilitado = props.isSomenteLeitura || !props.permiteAlteracaoCritica;

  return (
    <>
      {
        props.dados.tipoGrauIncapacidade === TIPOS_GRAU_INCAPACIDADE.DEFITNITIVA_SEM_POSSIBILIDADE_REABILITACAO_PROFISSIONAL &&
        <>
          <Grid item sm={4}>
            <BrRadio
              id="isDoencaIsentaIR"
              label="* Trata-se de doença que isenta de imposto de renda?"
              value={props.dados.doencaIsentaImpostoRenda}
              items={props.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
              onChange={props.alterarIsencaoIRGrauIncapacidade}
              erro={props.validacao?.erroDoencaIsentaImpostoRenda}
              disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={8}>
            <BrSelect
              id="tpDoencaIsencaoIR"
              label="* Isenção de imposto de renda"
              items={listaMotivosIsencaoIr}
              value={props.dados.tipoIsencaoImpostoRenda ? listaMotivosIsencaoIr.find(tipo => tipo.value === props.dados.tipoIsencaoImpostoRenda) : OPCAO_VAZIA}
              onChange={props.alterarTipoIsencaoImpostoRenda}
              erro={props.validacao?.erroTipoIsencaoImpostoRenda}
              disabled={props.isSomenteLeitura || props.dados.doencaIsentaImpostoRenda !== TIPO_SIM || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={4}>
            <BrRadio
              id="isMajoracao25"
              label="* Faz jus à majoração de 25%?"
              value={props.dados.majoracao25}
              items={props.tiposSimNao.map(t => ({ label: t.descricao, value: t.id }))}
              onChange={props.alterarMajoracaoGrauIncapacidade}
              erro={props.validacao?.erroMajoracao25}
              disabled={props.isSomenteLeitura || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={8}>
            <BrSelect
              id="tpMajoracao25"
              label="* Majoração de 25%"
              items={listaMotivosMajoracao25}
              value={props.dados.tipoMajoracao25 ? listaMotivosMajoracao25.find(tipo => tipo.value === props.dados.tipoMajoracao25) : OPCAO_VAZIA}
              onChange={props.alterarTipoMajoracao25}
              erro={props.validacao?.erroTipoMajoracao25}
              disabled={props.isSomenteLeitura || props.dados.majoracao25 !== TIPO_SIM || !props.permiteAlteracaoCritica} />
          </Grid>
          <Grid item sm={12}>
            <Grid container alignItems="center">
              <Grid item xs>
                <BrTextarea
                  id="justifTotalSemReabProf"
                  label="* Justificativa"
                  value={justificativa}
                  onChange={props.alterarJustLIGrauIncapacidade}
                  maxLength={QT_MAX_CARACT}
                  help={`Caracteres restantes: ${caracteresRestantes}`}
                  disabled={isCampoTextareaDesabilitado}
                  erro={props.validacao?.erroJustTotalSemIndicReabProfissional} />
              </Grid>
              {
                !isCampoTextareaDesabilitado &&
                <Grid item >
                  <ButtonSelecionarItem
                    id="selecionarItemDicionarioJustReabProfissional"
                    onSelect={props.alterarJustLIGrauIncapacidade} 
                    maxLength={caracteresRestantes}
                    valorAtual={justificativa}/>
                </Grid>
              }
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { dominioPericia, template, dominioPericiaBI, validacao, isSomenteLeitura } = state.principal;
  return ({
    pericia: state.pericia,
    tiposGrauIncapacidade: dominioPericia.tiposGrausIncapacidade,
    isSomenteLeitura: isSomenteLeitura,
    tiposSimNao: dominioPericia.tiposSimNao,
    template: template,
    tipoPericiaOrigemRevisao: state.pericia.tipoPericiaRaizDaCadeiaDeRevisoes,
    dados: state.pericia.moduloGrauIncapacidade,
    dadosOrigem: state.pericia.periciaOrigemRevisao?.moduloGrauIncapacidade,
    camposGenericos: state.pericia.valoresCamposGenericos,
    permiteAlteracaoCritica: state.pericia.permiteAlteracaoCritica,
    validacao: validacao.grauIncapacidade,
    listaEnquadAuxAcid: dominioPericiaBI?.tiposEnquadramentoAuxAcidente || [],
    listaSitEnquadAuxAcid: dominioPericiaBI?.tiposSituacaoEnquadramentoAuxAcidente || [],
    listaMotivosIsencaoIr: dominioPericiaBI?.tiposDoencaIsencaoIR || [],
    listaMotivosMajoracao25: dominioPericiaBI?.tiposMotivoMajoracao || []
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  alterarTipoGrauIncapacidade,
  alterarDCBGrauIncapacidade,
  alterarJustAuxAcidGrauIncapacidade,
  alterarJustDCIGrauIncapacidade,
  alterarJustLIGrauIncapacidade,
  alterarIsencaoIRGrauIncapacidade,
  alterarMajoracaoGrauIncapacidade,
  alterarDataComprovIncapac,
  alterarEnquadramentoAuxilioAcidente,
  alterarSituacaoEnquadramentoAuxilioAcidente,
  alterarTipoIsencaoImpostoRenda,
  alterarTipoMajoracao25,
  atualizarDadoValidacaoModulo
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(GrauIncapacidade);
