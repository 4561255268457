
import React from 'react';

import { Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const styleButton = {
  backgroundColor: '#E3E8E3',
  borderRadius: 'unset',
  minHeight: '57px',
  padding: '0px'
}

const styleButtonSelected = {
  backgroundColor: '#7BAE94'
}

const styleButtonDisabled = {
  cursor: 'not-allowed',
  opacity: '0.8'
}

const ButtonCheck = (props) => (
  <Button
    style={{
      ...styleButton,
      ...(props.disabled) ? styleButtonDisabled : {},
      ...(props.selected) ? styleButtonSelected : {}
    }}
    disableElevation
    fullWidth
    disabled={props.disabled}
    onClick={() => props.handleChange()}
  >
    {props.selected ?
      <Icon style={{ color: '#fff' }} className="fa fa-check" /> :
      <span style={{ opacity: '0.4' }}></span>
    }
  </Button>
);

export default ButtonCheck;

