import React from "react";

import BrTable from '@govbr/react-components/lib/BrTable';
import { Grid } from '@material-ui/core';
import { preencherCamposVazios } from ".";
import { formatarDateTimeISOToDataBr } from "../../../../common/DataUtils";
import { formatarNumeroComunicAcidTrab } from "../../../../common/formatadores";
import HeaderSecao from "../../../../components/HeaderSecao";

const ComunicAcidTrab = ({ comunicadosAcidTrab }) => {

  return (
    <>
      {
        comunicadosAcidTrab &&
        (
          <BrTable
            id="tabelaPericiasAnteriores"
            layout="fixed"
            columns={
              [
                { Header: "Número", accessor: "numero" }, 
                { Header: "Tipo", accessor: "tipo" },
                { Header: "Emitente", accessor: "emitente" }, 
                { Header: "Data do Acidente", accessor: "dataAcidente" }
              ]
            }
            data={comunicadosAcidTrab.map(item => ({
              numero: preencherCamposVazios(formatarNumeroComunicAcidTrab(item.numeroComunAcidTrab)),
              tipo: preencherCamposVazios(item.descricaoTipoAcidente),
              emitente: preencherCamposVazios(item.descricaoTipoEmitenteComunAcid),
              dataAcidente: preencherCamposVazios(formatarDateTimeISOToDataBr(item.dataAcidente)),
              collapse: (
                <>
                  <Grid item>
                    <HeaderSecao texto="Empregador" />
                  </Grid>
                  <Grid container spacing={4} style={{ width: 'auto', paddingBottom: '1.1em' }}>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Razão Social: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.razaoSocialEmpregador)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">CNAE: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.cnaeEmpregador)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Último Dia de Trabalho: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(formatarDateTimeISOToDataBr(item.ultimoDiaTrabalhado))}</p>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <HeaderSecao texto="Acidente" />
                  </Grid>
                  <Grid container spacing={4} style={{ width: 'auto', paddingBottom: '1.1em' }}>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Tipo de Acidente: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoTipoAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Situação Geradora do Acidente: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoSituacaoGeradoraAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Agente Causador: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoAgenteCausadorAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Parte do Corpo Atingida: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoParteCorpoAtingidaAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Local do Acidente: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoLocalAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Especificação do Local do Acidente: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.especificacaoLocalAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Município: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoMunicipioAcidente)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">UF: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.ufCrmMedicoEmitAtest)}</p>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <HeaderSecao texto="Atestado" />
                  </Grid>
                  <Grid container spacing={4} style={{ width: 'auto', paddingBottom: '1.1em' }}>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Nome do Médico: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.nomeMedicoEmitenteAtestado)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">CRM: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.numeroCrmMedicoEmitAtest)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Diagnóstico: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.codigoCidAtestado)} - {preencherCamposVazios(item.descricaoCidAtestado)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Data: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(formatarDateTimeISOToDataBr(item.dataAtendimentoAtestado))}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Natureza da Lesão: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.descricaoNaturezaLesaoAtest)}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p htmlFor="nome">Observações: </p>
                      <p id="nome" className="texto-informativo">{preencherCamposVazios(item.observacaoDiagnosticoAtest)}</p>
                    </Grid>
                  </Grid>
                </>
              )
            }))}
            collapse={true}
            headerProps={{
              show: false,
              showDensityOptions: false,
              searchProp: {
                show: false
              }
            }}
            footerProps={{ show: false }}
          />
        )
      }
      {
        !comunicadosAcidTrab &&
        <p>Nenhum comunicado de acidente de trabalho foi encontrado.</p>
      }
    </>
  );
};

export default ComunicAcidTrab;