import React from "react";
import { Grid } from '@material-ui/core';
import BrCard from '@govbr/react-components/lib/BrCard';

import DetalheExameLaudo from "./detalheExameLaudo";
import DetalheSistemasCorpo from "./detalheSistemasCorpo";
import DetalheCidsSecundarios from "./detalheCidsSecundarios";
import DetalheListaValoresTexto from "./detalheListaValoresTexto";
import DetalheNTEP from "./detalheNTEP";


const DetalheAlteracao = ({ alteracao }) => {

  return (
    <>
      {!alteracao.tabela && !alteracao.lista &&
        <Grid item xs={12}>
          <BrCard>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <span className="texto-informativo">{alteracao.nomeCampo}</span>
              </Grid>
              <Grid item xs={12}>
                <span className="ml-10">Antes da revisão: </span>
                <span className="texto-informativo valor-anterior-revisao">{alteracao.valorOrigem}</span>
              </Grid>
              <Grid item xs={12}>
                <span className="ml-10">Após revisão:  </span>
                <span className="texto-informativo valor-atual-revisao" style={{ marginLeft: '25px' }}>{alteracao.valorAtual}</span>
              </Grid>
            </Grid >
          </BrCard>
        </Grid >
      }
      {alteracao.lista &&
         <DetalheListaValoresTexto lista={alteracao} />
      }
      {alteracao.tabela && alteracao.nomeCampo === "Exames/Laudos" &&
        <DetalheExameLaudo examesLaudos={alteracao} />
      }
      {alteracao.tabela && alteracao.nomeCampo === "Exame" &&
        <DetalheSistemasCorpo exames={alteracao} />
      }
      {alteracao.tabela && alteracao.nomeCampo === "CIDs Secundários" &&
        <DetalheCidsSecundarios cidsSecundarios={alteracao} />
      }
      {alteracao.tabela && alteracao.nomeCampo === "Caracterização do Nexo Técnico Epidemiológico" &&
        <DetalheNTEP vinculos={alteracao} />
      }

    </>);
};


export default DetalheAlteracao;

