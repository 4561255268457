import { padLeft } from './StringUtils';

export const pad = (str, length) => padLeft(String(str), '0', length);

export const convertStringToDate = string => {
  if (!string || string.length < 8 || !string.includes('/')) return null;

  let dataSplit = string.split('/');
  return new Date(Number(dataSplit[2]), Number(dataSplit[1]) - 1, Number(dataSplit[0]));
}

export const convertStringToStringIsoWithTimezonePT_BR = string => {
  if (!string || string.length < 8 || !string.includes('/')) return null;

  let dataSplit = string.split('/');
  return `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}T00:00:00.000-03:00`;
}

export const convertStringToDateWithTimezonePT_BR = string => {
  return new Date(convertStringToStringIsoWithTimezonePT_BR(string));
}

export const convertStringISOToDateWithTimezonePT_BR = string => {
  if (!string || !(typeof string === "string") || string.length < 10 || !string.includes('-')) return string;

  let dataSplit = string.substring(0,10).split('-');
  return new Date(`${dataSplit[0]}-${dataSplit[1]}-${dataSplit[2]}T00:00:00.000-03:00`);
}

export const tratarHorarioVerao = (dataISO) => {
  let dataTratadaHorarioVerao = null;
  if (dataISO) {
    dataTratadaHorarioVerao = dataISO.replace('T02', 'T03');
  }
  return dataTratadaHorarioVerao;
}

export const convertLongToDataBr = (data) => {
  let dateTime = new Date(data);
  const dia = pad(dateTime.getDate(), 2);
  const mes = pad(dateTime.getMonth() + 1, 2);
  const ano = pad(dateTime.getFullYear())
  return `${dia}/${mes}/${ano}`;
}

export const convertLongToData = (data) => {
  let dateTime = new Date(data);
  if (data.length > 10) {
    const dia = pad(dateTime.getDate(), 2);
    const mes = pad(dateTime.getMonth() + 1, 2);
    const ano = pad(dateTime.getFullYear())
    return `${dia}/${mes}/${ano}`;
  } else {
    return data;
  }
}

export const formatarDateTimeISOToDataBrTratandoHorarioVerao = (dataISO) => {
  let dataISONormalizadoHorarioVerao = tratarHorarioVerao(dataISO)
  return formatarDateTimeISOToDataBr(dataISONormalizadoHorarioVerao);
}

export const formatarDateTimeISOToDataBr = (dataISO) => {
  if (!dataISO) {
    return null;
  }
  let dateTime = new Date(dataISO)
  const dia = pad(dateTime.getDate(), 2);
  const mes = pad(dateTime.getMonth() + 1, 2);

  const year = dateTime.getFullYear();
  const ano = pad(year < 1901 ? 1901 : year) //protecao para menor ano possivel
  return `${dia}/${mes}/${ano}`;
}

export const formatarDateTimeISOToDataEUA = (dataISO) => {
  let dateTime = new Date(dataISO)
  const dia = pad(dateTime.getDate(), 2);
  const mes = pad(dateTime.getMonth() + 1, 2);
  const ano = pad(dateTime.getFullYear())
  return `${ano}-${mes}-${dia}`;
}

export const formatarDataParaMesAnoTratandoHorarioVerao = (dataISO) => {
  let dataISONormalizadoHorarioVerao = typeof dataISO === 'string' ? tratarHorarioVerao(dataISO) : dataISO;
  return formatarDataParaMesAno(dataISONormalizadoHorarioVerao);
}

export const formatarDataParaMesAno = (dataISO) => {
  let dateTime = new Date(dataISO)
  const mes = pad(dateTime.getMonth() + 1, 2);
  const ano = pad(dateTime.getFullYear())
  return `${mes}/${ano}`;
}

export const formatarDataISOParaURLParam = (dateTime) => {
  const dia = pad(dateTime.getDate(), 2);
  const mes = pad(dateTime.getMonth() + 1, 2);
  const ano = dateTime.getFullYear();
  return `${dia}%2F${mes}%2F${ano}`;
}


export const now = () => {
  let date = new Date();
  let ano = date.getFullYear();
  let dia = date.getDate();
  let mes = (date.getMonth() + 1);

  if (dia < 10)
    dia = "0" + dia;

  if (mes < 10)
    mes = "0" + mes;

  let horas = date.getHours()
  let minutos = date.getMinutes()

  if (horas < 10)
    horas = "0" + horas;

  if (minutos < 10)
    minutos = "0" + minutos;

  return `${dia}/${mes}/${ano} - ${horas}:${minutos}`
}

/**
 * Converte a quantidade de segundos em minutos no formato MM:ss.
 * 280 -> 04:40
 *
 * @param {Number} segundos
 */
export const converterSegundosEmMinutos = (segundos) => {
  const minutos = pad(Math.floor(segundos / 60), 2);
  const segundosFormatados = pad((segundos % 60), 2);
  return `${minutos}:${segundosFormatados}`;
}

/**
 * Converte a string strData no formata  MM/YYYY  para um objeto Date.
 * @param {*} strData 
 */
export const converterMesAnoParaData = (strData) => {
  let partsDate = strData.split('/');
  return new Date(partsDate[1], partsDate[0] - 1);
}

export const validarData = (valor) => {

  let date = valor;
  let ardt = [];
  let ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
  let dataValida = true;

  ardt = date.split("/");

  if (date.search(ExpReg) === -1) {
    dataValida = false;
  }
  else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30))
    dataValida = false;
  else if (ardt[1] === 2) {
    if ((ardt[0] > 28) && ((ardt[2] % 4) !== 0))
      dataValida = false;
    if ((ardt[0] > 29) && ((ardt[2] % 4) === 0))
      dataValida = false;
  }

  return dataValida;

}

/*
    - Compara dois objetos dates 
    - Retorna -1 se a data início for menor que a data fim
    - Retorna 0 se a data início for igual a data fim
    - Retorna 1 se a data início for maior que a data fim
*/
export const compararDatasStringSemHoras = (dataInicio, dataFim) => {
  if (!dataInicio && !dataFim) {
    return 0;
  }
  if (!dataInicio && dataFim) {
    return -1;
  }
  if (dataInicio && !dataFim) {
    return 1;
  }

  const dataInicioConvertida = convertStringToDate(dataInicio);
  const dataFimConvertida = convertStringToDate(dataFim);

  return compararDatasSemHoras(dataInicioConvertida, dataFimConvertida);
}

/*
    - Compara dois objetos dates 
    - Retorna -1 se a data início for menor que a data fim
    - Retorna 0 se a data início for igual a data fim
    - Retorna 1 se a data início for maior que a data fim
*/
export const compararDatasSemHoras = (dataInicio, dataFim) => {
  let firstDate = new Date(dataInicio.getTime());
  firstDate.setHours(0, 0, 0, 0);

  let secondDate = new Date(dataFim.getTime());
  secondDate.setHours(0, 0, 0, 0)

  if (firstDate < secondDate) {
    return -1;
  } else if (firstDate > secondDate) {
    return 1;
  } else {
    return 0;
  }
}

export const compararDatasStringISOFormatSemHoras = (dataInicioString, dataFimString) => {
  const dataInicio = new Date(dataInicioString);
  const dataFim = new Date(dataFimString);

  return compararDatasSemHoras(dataInicio, dataFim);
}

export const nowDateTimeSringIsoSettedToTimezoneGmtMinus3 = () => {
  const date = new Date();
  const timeMsLocal = date.getTime();
  const timeMsLocalTimezoneOffset = (date.getTimezoneOffset() * 60 * 1000);
  const timeMsDesiredTimezoneOffset = -3 * 60 * 60 * 1000;
  const timeMsFinal = timeMsLocal + timeMsLocalTimezoneOffset + timeMsDesiredTimezoneOffset;

  const dateFinal = new Date(timeMsFinal)

  return convertStringToStringIsoWithTimezonePT_BR(convertLongToDataBr(dateFinal.getTime()));
}

export const zerarHorasDeData = (data) => {
  let dataComHorasZeradas = new Date(data.getTime());
  dataComHorasZeradas.setHours(0, 0, 0, 0);
  return dataComHorasZeradas;
}

export const converterStringIsoToDateZerandoHoras = (dataString) => {
  let dataComHorasZeradas = new Date(dataString);
  dataComHorasZeradas.setHours(0, 0, 0, 0);
  return dataComHorasZeradas;
}

export const isData1EhDepoisData2 = (dataInicio, dataFim) => compararDatasSemHoras(dataInicio, dataFim) > 0;
export const isData1EhDepoisOuIgualData2 = (dataInicio, dataFim) => compararDatasSemHoras(dataInicio, dataFim) >= 0;
export const isData1EhAntesOuIgualData2 = (dataInicio, dataFim) => compararDatasSemHoras(dataInicio, dataFim) <= 0;
export const isData1EhAntesData2 = (dataInicio, dataFim) => compararDatasSemHoras(dataInicio, dataFim) < 0;
export const isData1EhIgualData2 = (dataInicio, dataFim) => compararDatasSemHoras(dataInicio, dataFim) === 0;


export const converterDataFormatadaParaISOBr = (data) => {
  return convertStringToDate(formatarDateTimeISOToDataBr(data));
}

export const adicionarDiasNaData = (dataReferencia, diasParaAdicionar) => {
  if(!dataReferencia || !(dataReferencia instanceof Date)){
    throw Error("Parâmetro de Data de referência não é um Date.");
  }
  if(!diasParaAdicionar || typeof diasParaAdicionar !== 'number'){
    return dataReferencia;
  }
  let dataCalculada = dataReferencia;
  dataCalculada.setDate(dataReferencia.getDate() + diasParaAdicionar);
  return dataCalculada;
}