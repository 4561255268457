import React from "react";

import BrTable from "@govbr/react-components/lib/BrTable";

import {JUSTIFICATIVA_CARACTERIZACAO_AUTOMATICA_NTEP} from '../NexoTecnico/utils'

const TabelaVinculosNTEP = ({ vinculos, isValorAtual }) => {
  const infoClass = isValorAtual ? "valor-atual-revisao":"valor-anterior-revisao";

  return (
    <BrTable
      id="detalheTabelaVinculosNTEP"
      layout="fixed"
      columns={[
        { Header: "Vínculo", accessor: "nomeVinculo" },
        { Header: "Há elementos que DESCARACTERIZAM o NTEP?", accessor: "descaracterizacao" },
        { Header: "Justificativa", accessor: "justificativa" }
      ]}
      data={vinculos.map((item) => ({
        nomeVinculo: 
          <span className={`texto-informativo ${infoClass}`}>
            {item.nomeVinculo || "---"}
          </span>,
        descaracterizacao: 
          <span className={`texto-informativo ${infoClass}`}>
            {parseInt(item.possuiNexo) === 1 ? "Não" : (parseInt(item.possuiNexo) === 0 ? "Sim" : "---")}
          </span>,
        justificativa: 
        <>
          {
            item.possuiNexo === 1 && 
            (
              <span className={`texto-informativo ${infoClass}`}>{JUSTIFICATIVA_CARACTERIZACAO_AUTOMATICA_NTEP}</span>
            )

          }
          {
            item.possuiNexo === 0 && 
            (
              <div>
                <p className={`texto-informativo ${infoClass}`}>{item.justDescaracterizacao}</p>
                <p className={`texto-informativo ${infoClass}`}>{item.fundamentacao}</p>
              </div>
            )

          }
          
        </>,
      }))}
      headerProps={{
        show: false,
        showDensityOptions: false,
        searchProp: {
          show: false,
        },
      }}
      footerProps={{ show: false }}
    />);
}

export default TabelaVinculosNTEP;