import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import { alterarNaoExisteCidSecundario } from '../../action';
import { SIGLA_CAMPO_CID_ALTERADO } from '../../constants';
import {
  carregarCidPrincipalMesmoGrupoCidPericiaAnterior, consultarCidsPrincipais, consultarCidsSecundarios, exibirDialogAlterarCidPrincipal, exibirDialogExcluirCid,
  incluirCidSecundario, selecionarCidPrincipal, selecionarCidSecundario,
  setTimeoutConsultaCidPrincipal, setTimeoutConsultaCidSecundario
} from './action';

import { Grid } from "@material-ui/core";
import DialogConfirmarAlteracao from './DialogConfirmarAlteracao';
import DialogExclusao from './DialogConfirmarExclusao';

import BrButton from '@govbr/react-components/lib/BrButton';
import BrCheckbox from '@govbr/react-components/lib/BrCheckbox';
import BrSelect from '@govbr/react-components/lib/BrSelect';
import BrTable from '@govbr/react-components/lib/BrTable';
import BrMessage from '@govbr/react-components/lib/BrMessage';
import { verificaCidEmGrupo } from '../../../../common/Utils';

class CIDs extends React.Component {

  constructor(props) {
    super(props);
    this.props.config.inputCidPrincipal = null;
    this.props.config.inputCidSecundario = null;
  }

  OPCAO_VAZIA_CID_PRIMARIO = { label: '', value: null };
  OPCAO_VAZIA_CID_SECUNDARIO = { label: '', value: null };

  verificaSeExisteNexoTecnicoPreenchido = (moduloNexo) => {
    return (moduloNexo.existeNexoProfissional !== undefined
    || moduloNexo.existeNexoIndividual !== undefined
    || moduloNexo.existeNtep !== undefined);
  }

  obterOpcaoSelectCidPrincipalSelecionado = () => {
    const { cidPrincipal, nomeCidPrincipal } = this.props.moduloCid;
    return cidPrincipal
      ? { label: `${cidPrincipal} - ${nomeCidPrincipal}`, value: cidPrincipal }
      : null;
  };

  obterOpcaoInputCidPrincipal = () => {
    const { inputCidPrincipal } = this.props.config;

    return inputCidPrincipal
      ? { label: inputCidPrincipal, value: inputCidPrincipal }
      : null;
  };

  obterOpcaoSelectCidSecundarioSelecionado = () => {
    const { cidSecundarioSelecionado, nomeCidSecundarioSelecionado } = this.props.config;
    return cidSecundarioSelecionado
      ? { label: `${cidSecundarioSelecionado} - ${nomeCidSecundarioSelecionado}`, value: cidSecundarioSelecionado }
      : null;
  };

  obterOpcaoInputCidSecundario = () => {
    const { inputCidSecundario } = this.props.config;
    return inputCidSecundario
      ? { label: inputCidSecundario, value: inputCidSecundario }
      : null;
  };

  handleInputCidPrincipal = newInputValue => {
    const { consultarCidsPrincipais, setTimeoutConsultaCidPrincipal } = this.props;
    const { timeoutConsultaCidPrincipal } = this.props.config;
    const opcaoCidPrincipalSelecionado = this.obterOpcaoSelectCidPrincipalSelecionado();

    const isInputValidoParaFiltro = newInputValue && newInputValue.length > 2 // 3 ou mais caracteres
      && newInputValue !== this.OPCAO_VAZIA_CID_PRIMARIO.label //diferente de opcao com valor nulo
      && (!opcaoCidPrincipalSelecionado || newInputValue !== opcaoCidPrincipalSelecionado.label); //diferente de valor atual cid

    if (isInputValidoParaFiltro) {

      if (timeoutConsultaCidPrincipal) { //cancela consulta alterior existente
        clearTimeout(timeoutConsultaCidPrincipal);
      }

      const novoTimeout = setTimeout(function () {
        consultarCidsPrincipais(newInputValue)
      }, 2000); //esperar 2 segundos de inatividade para iniciar consulta

      setTimeoutConsultaCidPrincipal(novoTimeout, newInputValue);
    }
  };

  handleInputCidSecundario = newInputValue => {
    const { consultarCidsSecundarios, setTimeoutConsultaCidSecundario } = this.props;
    const { timeoutConsultaCidSecundario } = this.props.config;
    const opcaoCidSecundarioSelecionado = this.obterOpcaoSelectCidSecundarioSelecionado();

    const isInputValidoParaFiltro = newInputValue && newInputValue.length > 2 // 3 ou mais caracteres
      && newInputValue !== this.OPCAO_VAZIA_CID_SECUNDARIO.label //diferente de opcao com valor nulo
      && (!opcaoCidSecundarioSelecionado || newInputValue !== opcaoCidSecundarioSelecionado.label); //diferente de valor atual cid

    if (isInputValidoParaFiltro) {

      if (timeoutConsultaCidSecundario) { //cancela consulta anterior existente
        clearTimeout(timeoutConsultaCidSecundario);
      }

      const novoTimeout = setTimeout(function () {
        consultarCidsSecundarios(newInputValue)
      }, 2000); //esperar 2 segundos de inatividade para iniciar consulta

      setTimeoutConsultaCidSecundario(novoTimeout, newInputValue);
    }
  };

  handleChangeCidPrincipal = (newValue) => {
    const { timeoutConsultaCidPrincipal, listaCidsPrincipais } = this.props.config;
    const { selecionarCidPrincipal, moduloNexo, existeModuloNexoNoTemplate,
      moduloRestabelecimentoBeneficio, existeModuloRestabelecimentoNoTemplate, 
      valoresCamposGenericos, periciaAnterior, existeModuloIsencaoNoTemplate } = this.props;

    if (timeoutConsultaCidPrincipal) {
      //cancela consulta disparada pela selecao de opcao (evento onInputChange é disparado antes do onChange)
      //TODO: verificar pq timeout consulta nao esta atualizado quando evento eh disparado
      clearTimeout(timeoutConsultaCidPrincipal + 1);
    }

    let cidSelecionado = listaCidsPrincipais ? listaCidsPrincipais.find(c => c.codigo === newValue) : null;
    cidSelecionado = cidSelecionado ? cidSelecionado : this.OPCAO_VAZIA_CID_PRIMARIO;

    const existeNexoTecnicoPreenchido = this.verificaSeExisteNexoTecnicoPreenchido(moduloNexo);
    const existeDadosRestabelecimento = moduloRestabelecimentoBeneficio.existeRestabelecimento !== undefined
      || moduloRestabelecimentoBeneficio.existeAgravamento !== undefined;

    const valorCampoCidAlterado = valoresCamposGenericos?.find(c => c.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO);
    const cidAlterado = valorCampoCidAlterado && valorCampoCidAlterado.valorTextoCampoGenerico === '1';
    const deveCarregarBatimentoGrupoPericiaAnterior = cidAlterado && cidSelecionado.codigo !== null && periciaAnterior && periciaAnterior.codigoCID;

    selecionarCidPrincipal(cidSelecionado, existeModuloNexoNoTemplate, existeNexoTecnicoPreenchido,
      existeModuloRestabelecimentoNoTemplate, existeModuloIsencaoNoTemplate, existeDadosRestabelecimento, deveCarregarBatimentoGrupoPericiaAnterior, 
      periciaAnterior?.codigoCID);
  };

  handleChangeCidSecundario = (newValue) => {
    const { timeoutConsultaCidSecundario, listaCidsSecundarios } = this.props.config;
    const { selecionarCidSecundario } = this.props;

    if (timeoutConsultaCidSecundario) {
      //cancela consulta disparada pela selecao de opcao (evento onInputChange é disparado antes do onChange)
      //TODO: verificar pq timeout consulta nao esta atualizado quando evento eh disparado
      clearTimeout(timeoutConsultaCidSecundario + 1);
    }

    let cidSelecionado = listaCidsSecundarios ? listaCidsSecundarios.find(c => c.codigo === newValue) : null;
    cidSelecionado = cidSelecionado ? cidSelecionado : this.OPCAO_VAZIA_CID_SECUNDARIO;

    selecionarCidSecundario(cidSelecionado);
  };

  render() {
    const { moduloCid, validacao, validacaoDiagnosticoAnterior, config, isSomenteLeitura, existeModuloIsencaoNoTemplate,
      alterarNaoExisteCidSecundario, incluirCidSecundario, exibirDialogExcluirCid, permiteAlteracaoCritica, moduloNexo, valoresCamposGenericos } = this.props;
    const { naoExisteCidSecundario, cidsSecundarios } = moduloCid;
    const { listaCidsPrincipais, listaCidsSecundarios, cidSecundarioSelecionado, nomeCidSecundarioSelecionado,
      isLoadingCidPrincipal, isLoadingCidSecundario } = config;

    const campoAlteracaoCid = valoresCamposGenericos.find(v => v.siglaCampoGenerico === SIGLA_CAMPO_CID_ALTERADO)?.valorTextoCampoGenerico;
    const isCidMantido = campoAlteracaoCid && campoAlteracaoCid === "0";

    /** CID PRINCIPAL */
    const opcaoCidPrincipalSelecionado = this.obterOpcaoSelectCidPrincipalSelecionado();
    const opcaoInputCidPrincipal = this.obterOpcaoInputCidPrincipal();
    const opcoesCidPrincipal = listaCidsPrincipais
      ? listaCidsPrincipais.map(t => ({ label: `${t.codigo} - ${t.nome}`, value: t.codigo })) : [];

    opcoesCidPrincipal.unshift(this.OPCAO_VAZIA_CID_PRIMARIO);

    /** CID SECUNDARIO */

    const opcaoCidSecundarioSelecionado = this.obterOpcaoSelectCidSecundarioSelecionado();
    const opcaoInputCidSecundario = this.obterOpcaoInputCidSecundario();
    const cidsInvalidos = cidsSecundarios ? cidsSecundarios.map(c => c.cidSecundario) : [];

    const cidEmGrupoO = this.props.moduloCid && verificaCidEmGrupo(this.props.moduloCid.cidPrincipal, "O")

    if (opcaoCidPrincipalSelecionado) { //incluindo cid principal da lista de cids secundarios invalidos
      cidsInvalidos.push(opcaoCidPrincipalSelecionado.value);
    }

    let opcoesCidSecundario = listaCidsSecundarios
      ? listaCidsSecundarios.map(t => ({ label: `${t.codigo} - ${t.nome}`, value: t.codigo }))
      : [];
    if (cidsInvalidos) {
      opcoesCidSecundario = opcoesCidSecundario.filter(o => !cidsInvalidos.includes(o.value));
    }

    opcoesCidSecundario.unshift(this.OPCAO_VAZIA_CID_SECUNDARIO);
    const existeNexoTecnicoPreenchido = this.verificaSeExisteNexoTecnicoPreenchido(moduloNexo);

    return (
      <div id="moduloCids" className="conteudoAbaSemHeaderSecao">

        { existeModuloIsencaoNoTemplate && !isCidMantido && cidEmGrupoO && !existeNexoTecnicoPreenchido &&
          <BrMessage warning>
            É necessário avaliar se trata-se de gestação de risco.
          </BrMessage>
        }

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BrSelect id="cidPrincipal" label="* CID Principal" placeholder="Digite uma CID Principal"
              items={opcoesCidPrincipal}
              value={opcaoInputCidPrincipal
                ? opcaoInputCidPrincipal //seleciona opcao que representa string de consulta
                : (opcaoCidPrincipalSelecionado ? opcaoCidPrincipalSelecionado : this.OPCAO_VAZIA_CID_PRIMARIO)}
              onChange={this.handleChangeCidPrincipal}
              onInputChange={(event, newInputValue) => this.handleInputCidPrincipal(newInputValue)}
              disabled={isLoadingCidPrincipal || isSomenteLeitura || !permiteAlteracaoCritica}
              erro={(validacao && validacao.erroCidPrincipal) || (validacaoDiagnosticoAnterior && validacaoDiagnosticoAnterior.erroCidPrincipal)} />
          </Grid>

          <Grid item xs={12}>
            <BrCheckbox id="naoExisteCidSecundario"
              items={[{ label: "* Não existe CID Secundário", value: "naoExisteCidSecundario" }]}
              values={naoExisteCidSecundario === 1 ? ["naoExisteCidSecundario"] : []}
              onChange={(item, checked) => {
                checked
                  ? alterarNaoExisteCidSecundario(1)
                  : alterarNaoExisteCidSecundario(0); this.handleChangeCidSecundario(null);
              }}
              erro={(validacao && validacao.erroNaoExisteCidSecundario) || (validacaoDiagnosticoAnterior && validacaoDiagnosticoAnterior.erroNaoExisteCidSecundario)}
              disabled={isSomenteLeitura || (cidsSecundarios && cidsSecundarios.length > 0) || !permiteAlteracaoCritica} />
          </Grid>

          {naoExisteCidSecundario !== 1 &&
            <Grid container spacing={1} item xs={12} alignItems="flex-end">
              <Grid item xs={6}>
                <BrSelect id="cidSecundaria" label="** CID Secundário" placeholder="Digite um CID Secundário"
                  items={opcoesCidSecundario} style={{ maxWidth: '90%' }}
                  value={opcaoInputCidSecundario //seleciona opcao que representa string de consulta
                    ? opcaoInputCidSecundario
                    : (opcaoCidSecundarioSelecionado ? opcaoCidSecundarioSelecionado : this.OPCAO_VAZIA_CID_SECUNDARIO)}
                  onChange={this.handleChangeCidSecundario}
                  onInputChange={(event, newInputValue) => this.handleInputCidSecundario(newInputValue)}
                  disabled={naoExisteCidSecundario === 1 || isLoadingCidSecundario || isSomenteLeitura || !permiteAlteracaoCritica} />
              </Grid>
              <Grid item xs={1}>
                <BrButton id="btnIncluirCidSecundaria" primary
                  title='Adicionar CID Secundário'
                  onClick={() => incluirCidSecundario(cidSecundarioSelecionado, nomeCidSecundarioSelecionado)}
                  disabled={cidSecundarioSelecionado == null || naoExisteCidSecundario === 1 || isLoadingCidSecundario || isSomenteLeitura || !permiteAlteracaoCritica}>
                  Adicionar
                </BrButton>
              </Grid>
            </Grid>
          }

        </Grid>

        {cidsSecundarios && cidsSecundarios.length > 0
          &&
          (
            <BrTable
              id="tabelaCidsSecundarios"
              title="CIDs Secundários"
              columns={[{ Header: "Código - Descrição", accessor: "cid" }, { Header: "Ações", accessor: "acao" }]}
              data={cidsSecundarios.map(item => ({
                cid: `${item.cidSecundario} - ${item.nomeCidSecundario}`,
                acao: (
                  <BrButton id="btnExcluirCidSecundario" circle
                    onClick={() => exibirDialogExcluirCid(item)}
                    disabled={isSomenteLeitura}>
                    <i className={`fas fa-trash-alt`}
                      title='Excluir' />
                  </BrButton>
                )
              }))}
              headerProps={{
                show: true,
                showDensityOptions: false,
                searchProp: {
                  show: false
                }
              }}
              footerProps={{ show: false }} />
          )
        }
        {
          naoExisteCidSecundario !== 1 &&
          <p className="label" style={{ marginTop: "var(--spacing-scale-2x)" }}>(**) O CID Secundário não pode ser igual ao CID Principal</p>
        }


        <DialogExclusao />

        <DialogConfirmarAlteracao />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isSomenteLeitura, validacao, existeModuloNexoNoTemplate, existeModuloRestabelecimentoNoTemplate, existeModuloIsencaoNoTemplate } = state.principal;
  const { moduloCid, moduloNexoTecnico, moduloRestabelecimentoBeneficio, valoresCamposGenericos, periciaAnterior, permiteAlteracaoCritica } = state.pericia;
  return ({
    moduloCid: moduloCid,
    moduloNexo: moduloNexoTecnico,
    moduloRestabelecimentoBeneficio: moduloRestabelecimentoBeneficio,
    valoresCamposGenericos: valoresCamposGenericos,
    periciaAnterior: periciaAnterior,
    validacao: validacao.cids,
    validacaoDiagnosticoAnterior: validacao.dadosDiagnosticoPericiaAnterior,
    config: state.modulos.moduloCids,
    existeModuloNexoNoTemplate,
    existeModuloRestabelecimentoNoTemplate,
    existeModuloIsencaoNoTemplate,
    isSomenteLeitura,
    permiteAlteracaoCritica
  });
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selecionarCidPrincipal,
  selecionarCidSecundario,
  alterarNaoExisteCidSecundario,
  incluirCidSecundario,
  consultarCidsPrincipais,
  exibirDialogExcluirCid,
  exibirDialogAlterarCidPrincipal,
  setTimeoutConsultaCidPrincipal,
  consultarCidsSecundarios,
  setTimeoutConsultaCidSecundario,
  carregarCidPrincipalMesmoGrupoCidPericiaAnterior
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CIDs);