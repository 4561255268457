const INITIAL_STATE = {
  filtro: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ALTERAR_PROTOCOLO_PMF_FILTRO_PERICIA':
      return { ...state, filtro: { ...state.filtro, protocoloPmf: action.payload } }
    case 'ALTERAR_PROTOCOLO_INSS_FILTRO_PERICIA':
      return { ...state, filtro: { ...state.filtro, protocoloInss: action.payload } }
    case 'ALTERAR_CPF_TITULAR_FILTRO_PERICIA':
      return { ...state, filtro: { ...state.filtro, cpfTitular: action.payload } }
    case 'ALTERAR_NB_FILTRO_PERICIA':
      return { ...state, filtro: { ...state.filtro, nb: action.payload } }
    case 'CONSULTA_GERENCIAL_PERICIA':
      return { ...state, listaFiltrada: action.payload }
    case 'LIMPAR_CAMPOS_FILTRO_PERICIA':
      return INITIAL_STATE
    default:
      return state;
  }
}