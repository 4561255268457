import React, { useState } from "react";

import BrButton from '@govbr/react-components/lib/BrButton';
import BrModal from '@govbr/react-components/lib/BrModal';
import BrTable from '@govbr/react-components/lib/BrTable';
import { Grid } from '@material-ui/core';
import { connect } from "react-redux";
import { preencherCamposVazios } from ".";
import { formatarNB } from '../../../../common/formatadores';
import DetalheLaudoPMF from './DetalheLaudoPMF';
import DetalheLaudo from './DetalheLaudoSABI';

import { bindActionCreators } from 'redux';
import { situacaoPericia } from '../../constants';
import { carregarHtmlRelatorioPericia } from './action';
import { compararDatasStringSemHoras } from "../../../../common/DataUtils";

const PericiasAnteriores = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [laudoSelecionado, setLaudoSelecionado] = useState();
  const { periciasAnteriores, avaliado, carregarHtmlRelatorioPericia } = props;

  const periciasAnterioresOrdenado = periciasAnteriores?.sort((periciaA, periciaB) => {
    if (periciaA.dataRealizacao === periciaB.dataRealizacao) {
      if(periciaA.ordemLaudo === periciaB.ordemLaudo) {
        return periciaB.subordemLaudo - periciaA.subordemLaudo;
      }
      return periciaB.ordemLaudo - periciaA.ordemLaudo;
    }
    return compararDatasStringSemHoras(periciaB.dataRealizacao, periciaA.dataRealizacao)
  });

  const selecionarLaudo = (laudo) => {
    setLaudoSelecionado(laudo);
    if (laudo.tipoOrigemPericiaAnterior === "P") {
      carregarHtmlRelatorioPericia(laudo.id);
    }
    setModalVisible(true);
  }

  return (
    <>
      {
        periciasAnterioresOrdenado && periciasAnterioresOrdenado.length > 0 &&
        (
          <BrTable
            id="tabelaPericiasAnteriores"
            layout="fixed"
            columns={[{ Header: "Tipo", accessor: "tipo" }, { Header: "DRE", accessor: "dre" },
            { Header: "DID", accessor: "did" }, { Header: "DII", accessor: "dii" },
            { Header: "DCB", accessor: "dcb" }, { Header: "DCI", accessor: "dci" },
            { Header: "Ação", accessor: "acao" }]}
            data={periciasAnterioresOrdenado.map(item => ({
              tipo: (<>
                <span>
                  Tipo:  {item.descricaoTipoPericia}
                </span>
                <br />
                <span>NB: {formatarNB(item.numeroBeneficio)}</span>
              </>),
              dre: preencherCamposVazios(item.dataRealizacao),
              did: preencherCamposVazios(item.dataInicioDoenca),
              dii: preencherCamposVazios(item.dataInicioIncapacidade),
              dcb: preencherCamposVazios(item.dataCessacaoBeneficio),
              dci: preencherCamposVazios(item.dataComprovacaoIncapacidade),
              collapse: ((item.situacao.id !== situacaoPericia.REVISADA.id) &&
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <div>CID Principal</div>
                    <div className="texto-informativo">{preencherCamposVazios(item.codigoCID)}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>CID Secundário</div>
                    <div className="texto-informativo">{preencherCamposVazios(item.codigoCIDSecundario)}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>Conclusão</div>
                    <div className="texto-informativo">{preencherCamposVazios(item.resultadoIncapacidade)}</div>
                  </Grid>
                </Grid>
              ),
              acao: ((item.situacao.id !== situacaoPericia.REVISADA.id) ?
                <BrButton BrButton id="detalharLaudo" circle
                  onClick={() => selecionarLaudo(item)}>
                  <i className={`fas fa-eye`} title='Detalhar' />
                </BrButton > :
                <BrButton BrButton id="naoDetalharLaudo" circle disabled>
                  <i className={`fas fa-eye-slash`} title='Detalhamento não permitido' />
                </BrButton >)
            }))}
            collapse={true}
            headerProps={{
              show: false,
              showDensityOptions: false,
              searchProp: {
                show: false
              }
            }}
            footerProps={{ show: false }}
          />
        )
      }
      {
        (!periciasAnterioresOrdenado || periciasAnterioresOrdenado.length === 0) &&
        <p>Nenhuma perícia foi encontrada.</p>
      }
      {
        laudoSelecionado &&
        (laudoSelecionado.tipoOrigemPericiaAnterior === "S" ?
          <BrModal id="modalPericiaSABI" large={true}
            visible={modalVisible}
            title="Laudo Pericia Anterior SABI"
            onClose={() => setModalVisible(false)}>
            <DetalheLaudo laudo={laudoSelecionado} avaliado={avaliado} />
          </BrModal>
          :
          <BrModal id="modalPericiaPMF" large={true}
            visible={props.html && modalVisible}
            title="Laudo Pericia Anterior PMF"
            onClose={() => setModalVisible(false)}>
            <DetalheLaudoPMF />
          </BrModal>)
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  html: state.modulos.moduloProntuario?.html
});

const mapDispatchToProps = dispatch => bindActionCreators({
  carregarHtmlRelatorioPericia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PericiasAnteriores);

